import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import ClientComponentItem from "./ClientComponentItem";

const ClientComponent = ({ searchClients, clientsCount }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <StyledSearchClientsWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Osoby</StyledTitle>
        <StyledCountWrapper>{clientsCount}</StyledCountWrapper>
      </StyledTitleWrapper>

      <StyledSearchItemWrapper>
        {searchClients?.length > 0 && (
          <>
            {showAll
              ? searchClients.map((client) => (
                  <ClientComponentItem client={client} key={client.id} />
                ))
              : searchClients
                  .slice(0, 5)
                  .map((client) => (
                    <ClientComponentItem client={client} key={client.id} />
                  ))}
          </>
        )}
      </StyledSearchItemWrapper>

      {searchClients?.length > 5 && (
        <StyledShowMoreWrapper>
          <StyledShowMoreButton onClick={() => setShowAll((prev) => !prev)}>
            {showAll ? `Pokaż mniej ` : `Pokaż więcej `}
            <StyledAngleDownIcon
              icon={showAll ? faAngleDoubleUp : faAngleDoubleDown}
            />
          </StyledShowMoreButton>
        </StyledShowMoreWrapper>
      )}
    </StyledSearchClientsWrapper>
  );
};

const StyledShowMoreButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  color: #134771;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledAngleDownIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-left: 5px;
`;

const StyledShowMoreWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledSearchItemWrapper = styled.div``;
const StyledCountWrapper = styled.div`
  background: #134771;
  margin-left: 5px;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  color: white;
`;
const StyledTitle = styled.p`
  color: #134771;
  font-size: 18px;
`;
const StyledTitleWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;
const StyledSearchClientsWrapper = styled.div`
  background: white;
  padding: 10px;
  margin: 0 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  margin-bottom: 10px;
`;

export default ClientComponent;
