import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import Switch from "../../../../../../../components/Switch";
import DeleteAlert from "./DeleteAlert";

const TaskStatusItem = ({
  element,
  fetchDataTaskStatus,
  handleOpenEditStatusTask,
  setEditObject,
}) => {
  const { id, name, color, value } = element;
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleOnEdit = () => {
    setEditObject(element);
    handleOpenEditStatusTask();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/taskstatus/${id}`);
    if (status === 200) {
      fetchDataTaskStatus();
      setIsAlertOpen(false);
    }
  };

  const handleOnSwitchChange = async () => {
    const { status } = await request.patch(`/api/task-status-setting/${id}`, {
      value: !value,
    });
    if (status === 200) {
      fetchDataTaskStatus();
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledTaskStatusItem>
      <StyledDescription>
        <Switch handleOnChange={handleOnSwitchChange} checked={value} />
        <StyledTaskStatusSpanContainer>
          <StyledSpan style={{ background: color }}></StyledSpan>
        </StyledTaskStatusSpanContainer>
        <p>{name}</p>
      </StyledDescription>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>

      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnDelete={handleOnDelete}
        handleOnClose={handleOnCloseAlert}
      />
    </StyledTaskStatusItem>
  );
};

const StyledTaskStatusItem = styled.li`
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
`;

const StyledSpan = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledTaskStatusSpanContainer = styled.div`
  width: 25px;
  text-align: center;
  margin-right: 10px;
`;
const StyledDescription = styled.div`
  display: flex;
  flex: 1;
`;

const StyledButtonGroup = styled.div`
  display: flex;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
export default TaskStatusItem;
