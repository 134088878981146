import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { PowerCalculatorContext } from "../../../store/PowerCalculatorProvider";
import zones from "../../../img/Insolation-zones.png";
const SideDegree = [
  { id: 1, degree: 270, name: "Zachód" },
  { id: 2, degree: 240, name: "Południowy zachód" },
  { id: 3, degree: 210, name: "Południowy zachód" },
  { id: 4, degree: 180, name: "Południe" },
  { id: 5, degree: 150, name: "Południowy wschód" },
  { id: 6, degree: 120, name: "Południowy wschód" },
  { id: 7, degree: 90, name: "Wschód" },
];

const YieldDegreeObject = [
  {
    id: 1,
    degree: 15,
    270: 86,
    240: 91,
    210: 95,
    180: 97,
    150: 96,
    120: 92,
    90: 86,
  },
  {
    id: 2,
    degree: 20,
    270: 85,
    240: 93,
    210: 97,
    180: 99,
    150: 97,
    120: 93,
    90: 86,
  },
  {
    id: 3,
    degree: 30,
    270: 83,
    240: 93,
    210: 99,
    180: 100,
    150: 100,
    120: 93,
    90: 84,
  },

  {
    id: 4,
    degree: 40,
    270: 80,
    240: 92,
    210: 99,
    180: 100,
    150: 99,
    120: 92,
    90: 81,
  },

  {
    id: 5,
    degree: 45,
    270: 78,
    240: 90,
    210: 98,
    180: 99,
    150: 98,
    120: 90,
    90: 79,
  },
];

const YieldZoneObject = [
  {
    id: 1,
    color: "#D93B33",
    name: "Strefa I",
    kWh: 1200,
  },
  {
    id: 2,
    color: "#E98235",
    name: "Strefa II",
    kWh: 1100,
  },
  {
    id: 3,
    color: "#EB9F41",
    name: "Strefa III",
    kWh: 1000,
  },
  {
    id: 4,
    color: "#F4C245",
    name: "Strefa IV",
    kWh: 900,
  },
];

const PowerCalculator = () => {
  const {
    installationPower,
    setInstallationPower,
    roofAngle,
    setRoofAngle,
    sideDegree,
    setSideDegree,
    yieldZone,
    setYieldZone,
    autoConsumption,
    setAutoConsumption,
    saleRate,
    setSaleRate,
    buyRate,
    setBuyRate,
  } = useContext(PowerCalculatorContext);

  const [energeticBillPeriod, setEnergeticBillPeriod] = useState(1);
  const [electricEnergyUsage, setElectricEnergyUsage] = useState(0);
  const [annualEnergyConsumption, setAnnualEnergyConsumption] = useState(0);
  const [electricEnergyUsageError, setElectricEnergyUsageError] =
    useState(false);
  const [saleRateError, setSaleRateError] = useState(false);
  const [buyRateError, setBuyRateError] = useState(false);

  const handleOnChangeRoofAngle = (e) => {
    setRoofAngle(Number(e.target.value));
  };

  const handleOnChangeSideDegree = (e) => {
    setSideDegree(Number(e.target.value));
  };

  const handleonChangeYieldZone = (e) => {
    setYieldZone(Number(e.target.value));
  };

  const handleOnChangeEnergeticBillPeriod = (e) => {
    setEnergeticBillPeriod(e.target.value);
  };

  const handleOnChangeSaleRate = (e) => {
    if (e.target.value < 0) {
      setSaleRate(0);
    } else {
      setSaleRate(e.target.value);
    }
  };

  const handleOnChangeBuyRate = (e) => {
    if (e.target.value < 0) {
      setBuyRate(0);
    } else {
      setBuyRate(e.target.value);
    }
  };

  const handleOnChangeAutoConsumption = (e) => {
    if (e.target.value < 0) {
      setAutoConsumption(0);
    } else if (e.target.value > 100) {
      setAutoConsumption(100);
    } else {
      setAutoConsumption(e.target.value);
    }
  };

  const handleOnChangeElectricEnergyUsage = (e) => {
    if (e.target.value < 0) {
      setElectricEnergyUsage(0);
    } else {
      setElectricEnergyUsage(e.target.value);
    }
  };

  useEffect(() => {
    if (electricEnergyUsage === 0) {
      setElectricEnergyUsageError(true);
    } else {
      setElectricEnergyUsageError(false);
    }

    if (buyRate <= 0) {
      setBuyRateError(true);
    } else {
      setBuyRateError(false);
    }

    if (saleRate <= 0) {
      setSaleRateError(true);
    } else {
      setSaleRateError(false);
    }

    if (saleRate > 0 && buyRate > 0 && electricEnergyUsage > 0) {
      const chooseYieldZone = YieldZoneObject.find(
        (zone) => zone.id === yieldZone
      );
      const chooseRoofAngle = YieldDegreeObject.find(
        (angle) => angle.id === roofAngle
      );
      const chooseSideDegree = SideDegree.find(
        (side) => side.id === sideDegree
      );

      let annualConsumption = 0;

      if (Number(energeticBillPeriod) === 1) {
        annualConsumption = Number(electricEnergyUsage) * 12;
      } else if (Number(energeticBillPeriod) === 2) {
        annualConsumption = Number(electricEnergyUsage) * 6;
      } else if (Number(energeticBillPeriod) === 3) {
        annualConsumption = Number(electricEnergyUsage);
      } else {
        annualConsumption = 0;
      }

      setAnnualEnergyConsumption(annualConsumption);

      const productionPercentage = chooseRoofAngle[chooseSideDegree.degree];

      const referencePower = Math.ceil(
        (annualConsumption * 100) / (productionPercentage - 8)
      );

      const autoConsumptionPower = (referencePower * autoConsumption) / 100;
      const storageReferencePower =
        (referencePower * (100 - Number(autoConsumption))) / 100;

      const requiredProsumentDepoist = storageReferencePower * buyRate;
      const storagePower = (requiredProsumentDepoist / saleRate) * 1000;
      const instalationPower =
        Number(storagePower + autoConsumptionPower) / chooseYieldZone.kWh;

      setInstallationPower(Number(instalationPower).toFixed(3));
    } else {
      setInstallationPower(0);
    }
  }, [
    sideDegree,
    roofAngle,
    yieldZone,
    saleRate,
    buyRate,
    autoConsumption,
    energeticBillPeriod,
    electricEnergyUsage,
  ]);

  return (
    <StyledWrapper>
      <StyledSecondWrapper>
        <StyledNameWrapper>
          <StyledName>Kalkulator mocy</StyledName>
        </StyledNameWrapper>
        <StyledCalculatorWrapper>
          <StyledInsolationZonesWrapper>
            <StyledInsolationZenesTitle>
              Strefy nasłonecznienia
            </StyledInsolationZenesTitle>
            <StyledInsolationZonesImage
              src={zones}
              alt="strefy nasłonecznienia"
            />
          </StyledInsolationZonesWrapper>

          <StyledTitleWrapper>
            <StyledTitle>Zużycie energii</StyledTitle>
          </StyledTitleWrapper>

          <StyledSelectWrapper>
            <StyledSelectLabel>
              Okres jaki pokrywa rachunek za energię:
            </StyledSelectLabel>
            <StyledSelect
              value={energeticBillPeriod}
              onChange={handleOnChangeEnergeticBillPeriod}
            >
              <option value={1} key={1}>
                Miesiąc
              </option>
              <option value={2} key={2}>
                Dwa miesiące
              </option>
              <option value={3} key={3}>
                Rok
              </option>
            </StyledSelect>
          </StyledSelectWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={
                electricEnergyUsageError
                  ? { color: "rgb(244, 118, 49)" }
                  : { color: "#134771" }
              }
            >
              Zużycie energii elektrycznej w wybranym okresie (kWh):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={electricEnergyUsage}
              onChange={handleOnChangeElectricEnergyUsage}
              style={
                electricEnergyUsageError
                  ? {
                      borderColor: "rgb(244, 118, 49)",
                      color: "rgb(244, 118, 49)",
                    }
                  : { borderColor: "#134771", color: "#134771" }
              }
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>
              Roczne zużycie energii:
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="text"
              value={annualEnergyConsumption && annualEnergyConsumption + "kWh"}
              readOnly
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={
                buyRateError
                  ? { color: "rgb(244, 118, 49)" }
                  : { color: "#134771" }
              }
            >
              Zakup energii czynnej za 1 kWh (zł):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={buyRate}
              onChange={handleOnChangeBuyRate}
              style={
                buyRateError
                  ? {
                      borderColor: "rgb(244, 118, 49)",
                      color: "rgb(244, 118, 49)",
                    }
                  : { borderColor: "#134771", color: "#134771" }
              }
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={
                saleRateError
                  ? { color: "rgb(244, 118, 49)" }
                  : { color: "#134771" }
              }
            >
              Średnia stawka na TGE za 1MWh (zł):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={saleRate}
              step="0.01"
              onChange={handleOnChangeSaleRate}
              style={
                saleRateError
                  ? {
                      borderColor: "rgb(244, 118, 49)",
                      color: "rgb(244, 118, 49)",
                    }
                  : { borderColor: "#134771", color: "#134771" }
              }
            />
          </StyledNumberInputWrapper>
          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>
              Prognozowana autokonsumpcja energii (%):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={autoConsumption}
              onChange={handleOnChangeAutoConsumption}
            />
          </StyledNumberInputWrapper>

          <StyledTitleWrapper>
            <StyledTitle>Miejsce inwestycji</StyledTitle>
          </StyledTitleWrapper>

          <StyledSelectWrapper>
            <StyledSelectLabel>Kąt nachylenia dachu:</StyledSelectLabel>
            <StyledSelect value={roofAngle} onChange={handleOnChangeRoofAngle}>
              {YieldDegreeObject &&
                YieldDegreeObject.map((degree) => (
                  <option value={degree.id} key={degree.id}>
                    {degree.degree}&deg;
                  </option>
                ))}
            </StyledSelect>
          </StyledSelectWrapper>

          <StyledSelectWrapper>
            <StyledSelectLabel>Kierunek śwata(%):</StyledSelectLabel>
            <StyledSelect
              value={sideDegree}
              onChange={handleOnChangeSideDegree}
            >
              {SideDegree &&
                SideDegree.map((side) => (
                  <option value={side.id} key={side.id}>
                    {side.degree}&deg; - {side.name}
                  </option>
                ))}
            </StyledSelect>
          </StyledSelectWrapper>

          <StyledSelectWrapper>
            <StyledSelectLabel>Strefa nasłonecznienia:</StyledSelectLabel>
            <StyledSelect value={yieldZone} onChange={handleonChangeYieldZone}>
              {YieldZoneObject &&
                YieldZoneObject.map((zone) => (
                  <option key={zone.id} value={zone.id}>
                    {zone.kWh} kWh/kWp
                  </option>
                ))}
            </StyledSelect>
          </StyledSelectWrapper>

          <StyledTitleWrapper>
            <StyledTitle>Podsumowanie</StyledTitle>
          </StyledTitleWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>Moc instalacji:</StyledNumberInputLabel>
            <StyledNumberInput
              type="text"
              value={installationPower && installationPower + " kWp"}
              readOnly
            />
          </StyledNumberInputWrapper>
        </StyledCalculatorWrapper>
      </StyledSecondWrapper>
    </StyledWrapper>
  );
};

const StyledInsolationZonesWrapper = styled.div``;
const StyledInsolationZenesTitle = styled.p`
  padding: 5px;
  text-align: center;
  color: #134771;
  font-weight: bold;
  font-size: 16px;
`;
const StyledInsolationZonesImage = styled.img`
  max-width: 360px;
`;

const StyledTitle = styled.p`
  color: #134771;
  font-size: 16px;
  font-weight: bold;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSelect = styled.select`
  width: 100%;
  color: #134771;
  border: 1px solid #134771;
  border-radius: 4px;
`;

const StyledSelectLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
  color: #134771;
`;

const StyledSelectWrapper = styled.div`
  margin-top: 25px;
  position: relative;
`;

const StyledNumberInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #134771;
  color: #134771;
`;

const StyledNumberInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
  color: #134771;
`;

const StyledNumberInputWrapper = styled.div`
  margin-top: 25px;
  position: relative;
`;

const StyledCalculatorWrapper = styled.div``;

const StyledName = styled.p`
  color: #134771;
  font-size: 16px;
`;

const StyledNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const StyledSecondWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  min-width: 400px;
`;

const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default PowerCalculator;
