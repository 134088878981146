import React from "react";
import styled from "styled-components";
import TodoSideBar from "./Components/TodoSideBar/TodoSideBar";

const HomeAside = () => {
  return (
    <StyledAside>
      <TodoSideBar />

      <StyledRocketChatWrapper>
        <iframe
          frameBorder="0"
          style={{ width: "100%", height: "100%" }}
          src="https://chat.syntezaoze.pl/channel/general"
          title="myframe"
        ></iframe>
      </StyledRocketChatWrapper>
    </StyledAside>
  );
};

const StyledAside = styled.div`
  height: calc(100vh - 100px);
  min-width: 200px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const StyledRocketChatWrapper = styled.div`
  width: 100%;
  height: 500px;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  background: white;
`;

export default HomeAside;
