import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import styled from "styled-components";
import TopNav from "../../components/TopNav/TopNav";
import AsideMenu from "./Component/AsideMenu/AsideMenu";
import OfferSettings from "./Component/OfferSettings/OfferSettings";

const ProfileSettings = () => {
  let { path } = useRouteMatch();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(true);

  const handleChangeSize = () => {
    if (window.innerWidth > 1200) {
      setIsMobileNavOpen(true);
    } else {
      setIsMobileNavOpen(false);
    }
  };

  useEffect(() => {
    handleChangeSize();
    window.addEventListener("resize", handleChangeSize);

    return () => {
      window.removeEventListener("resize", handleChangeSize);
    };
  }, []);

  return (
    <StyledWrapper>
      <StyledNavContainer>
        <TopNav
          isMobileNavOpen={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <AsideMenu isMobileNavOpen={isMobileNavOpen} />
      </StyledNavContainer>
      <StyledContentContainer>
        <Switch>
          <Route exact path={`${path}/`}>
            <Redirect to={"/user-settings/offer"} />
          </Route>
          <Route path={`${path}/offer`}>
            <OfferSettings />
          </Route>
        </Switch>
      </StyledContentContainer>
    </StyledWrapper>
  );
};

const StyledContentContainer = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;
  padding-left: 240px;

  @media screen and (max-width: 1200px) {
    padding-left: 0;
  }
`;

const StyledNavContainer = styled.div``;

const StyledWrapper = styled.div`
  display: flex;
`;

export default ProfileSettings;
