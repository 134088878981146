import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import EditOpportunities from "./Components/EditOpportunities";
import OpportunitiesItem from "./OpportunitiesItem/OpportunitiesItem";
import loading from "../../../img/48x48.gif";
import OpportunitiesFilter from "./OpportunitiesFilter/OpportunitiesFilter";

const OpportunitiesList = ({
  typeFilter,
  statusFilter,
  departmentFilter,
  paymentFilter,
  opportunityStatuses,
  orderProductFilter,
  completeFilter,
  searchValue,
  setSearchValue,
  setSearchInput,
  setPageNumber,
  fetchOpportunity,
  isLoading,
  searchInput,
  opportunities,
  pageNumber,
  pageCount,
  searchStartDate,
  setSearchStartDate,
  searchEndDate,
  setSearchEndDate,
  setDateFilter,
  userFilter,
  teamFilter,
  osdFilter,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(null);
  const [elementPerPage, setElementPerPage] = useState(10);
  const [editObject, setEditObject] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setSearchInput(searchValue || "");
      setPageNumber(1);
      fetchOpportunity(1);
    }
  }, [
    statusFilter,
    departmentFilter,
    paymentFilter,
    orderProductFilter,
    searchValue,
    completeFilter,
    searchEndDate,
    searchStartDate,
    typeFilter,
    userFilter,
    teamFilter,
    osdFilter,
  ]);

  const handleOnClickEdit = (opportunity) => {
    setEditObject(opportunity);
    setIsEditModalOpen(true);
  };
  const handleOnCloseEdit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
    setEditObject(null);
  };

  const handleOnChangePage = (event) => {
    window.scrollTo(0, 0);
    setPageNumber(event.selected + 1);
    fetchOpportunity(event.selected + 1);
  };

  // const handleOnClose = (e) => {
  //   e.preventDefault();
  //   setIsModalOpen(false);
  // };

  // const handleOnClick = () => {
  //   setIsModalOpen(true);
  // };

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <OpportunitiesFilter
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          searchStartDate={searchStartDate}
          setSearchStartDate={setSearchStartDate}
          searchEndDate={searchEndDate}
          setSearchEndDate={setSearchEndDate}
          setDateFilter={setDateFilter}
        />

        {isLoading ? (
          <StyledLoadingWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledLoadingWrapper>
        ) : (
          <>
            {opportunities.length > 0 ? (
              <>
                {opportunities.map((opportunity) => {
                  return (
                    <OpportunitiesItem
                      key={opportunity.id}
                      setPageNumber={setPageNumber}
                      opportunity={opportunity}
                      fetchData={fetchOpportunity}
                      handleOnClickEdit={handleOnClickEdit}
                      setEditObject={setEditObject}
                      opportunityStatuses={opportunityStatuses}
                    />
                  );
                })}
              </>
            ) : (
              <>
                <StyledEmptySearchRecords>
                  <StyledEmptyInfo>
                    <StyledEmptyText>Brak wyników</StyledEmptyText>
                  </StyledEmptyInfo>
                </StyledEmptySearchRecords>
              </>
            )}
          </>
        )}
      </StyledContentWrapper>
      <StyledPaginateWrapper>
        <StyledReactPaginated
          breakLabel="..."
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
          pageClassName="paginate_button"
          disableInitialCallback={isLoading}
          onPageChange={(event) => handleOnChangePage(event)}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          renderOnZeroPageCount={null}
          forcePage={pageNumber - 1}
        />
      </StyledPaginateWrapper>

      <EditOpportunities
        isModalOpen={isEditModalOpen}
        handleOnClose={handleOnCloseEdit}
        opportunities={editObject}
      />
    </StyledWrapper>
  );
};

const StyledEmptySearchRecords = styled.div`
  background: white;
  border-radius: 10px;
  padding: 10px;
  height: calc(100vh - 260px);
`;
const StyledEmptyInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledEmptyText = styled.p`
  color: #134771;
`;

const StyledLoadingImage = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledLoadingWrapper = styled.div`
  background: white;
  height: calc(100vh - 260px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const StyledReactPaginated = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .paginate_button {
    list-style: none;
    color: #134771;
    &.selected {
      color: rgb(244, 118, 49);
    }
  }
  & .paginate_button a {
    padding: 10px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .break {
    list-style: none;
  }
  & .break a {
    padding: 10px;
    border: none;
    font-size: 18px;
    color: #134771;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .previous {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }

  & .previous a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
  & .next {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }
  & .next a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
`;

const StyledPaginateWrapper = styled.div``;

const StyledContentWrapper = styled.div`
  margin: 0 10px;
  padding-bottom: 10px;
`;

const StyledWrapper = styled.div`
  flex: 1;

  min-width: 0;
  .header {
    display: flex;
    border-radius: 10px 10px 0 0;
    justify-content: space-between;
    align-items: center;
    position: relative;
    p {
      padding: 10px;
    }
  }
`;
export default OpportunitiesList;
