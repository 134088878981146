import React, { useEffect, useState } from "react";
import TopNav from "../../components/TopNav/TopNav";
import styled from "styled-components";
import {
  useHistory,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import Home from "../Home/Home";
import Contact from "../Contact/Contact";
import Calendar from "../Calendar/Calendar";
import ToDo from "../ToDo/ToDo";
import OpportunitiesSwitch from "../Opportunities/OpportunitiesSwitch";
import Assembly from "../Assembly/Assembly";
import AsideNav from "../../components/AsideNav/AsideNav";
import { useContext } from "react";
import { StoreContext } from "../../store/StoreProvider";
import SearchFullSide from "../SearchFullSide/SearchFullSide";
import OfferApprove from "../OfferApprove/OfferApprove";

import LeadSwitch from "../Lead/LeadSwitch";
import RocketChat from "../RocketChat/RocketChat";
import request from "../../helpers/request";
import Statistics from "../Statistics/Statistics";
import QuickCalculation from "../QuickCalculation/QuickCalculation";
import ContractApprove from "../ContractApprove/ContractApprove";
import OfferPricing from "../OfferPricing/OfferPricing";
import AssemblyFaults from "../AssemblyFaults/AssemblyFaults";

const Dashboard = () => {
  let { path } = useRouteMatch();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const {
    isAsideNavOpen,
    setIsAsideNavOpen,
    user,
    setUser,
    setPanels,
    panels,
    constructionType,
    setConstructionType,
    setInverters,
    inverters,
    sources,
    setSources,
    setHeatingTypes,
    heatingTypes,
    installationType,
    setInstallationType,
  } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const fetchSources = async () => {
    try {
      const { data, status } = await request.get("api/source");
      if (status === 200) {
        setSources(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchHeatingTypes = async () => {
    try {
      const { status, data } = await request.get("/api/heating-types");
      if (status === 200) {
        await setHeatingTypes(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchUser = async () => {
    try {
      const { status, data } = await request.get("/api/me");
      if (status === 200) {
        setUser(data.user);
        setInstallationType(data.user?.offer_form);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchPanels = async () => {
    try {
      const { status, data } = await request.get("/api/panels");
      if (status === 200) {
        setPanels(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchInverters = async () => {
    try {
      const { status, data } = await request.get("/api/inverters");
      if (status === 200) {
        setInverters(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchConstructions = async () => {
    try {
      const { status, data } = await request.get("/api/constructions-type");
      if (status === 200) {
        setConstructionType(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (user === undefined || user.length === 0) {
      fetchUser();
    }
    if (installationType === undefined || installationType.length === 0) {
      fetchUser();
    }
    if (sources === undefined || sources.length === 0) {
      fetchSources();
    }
    if (heatingTypes === undefined || heatingTypes.length === 0) {
      fetchHeatingTypes();
    }
    if (panels === undefined || panels.length === 0) {
      fetchPanels();
    }
    if (inverters === undefined || inverters.length === 0) {
      fetchInverters();
    }
    if (constructionType === undefined || constructionType.length === 0) {
      fetchConstructions();
    }
  }, []);
  const handleChangeSize = () => {
    if (window.innerWidth > 1200) {
      setIsMobileNavOpen(true);
    } else {
      setIsMobileNavOpen(false);
    }
  };

  useEffect(() => {
    handleChangeSize();
    window.addEventListener("resize", handleChangeSize);

    return () => {
      window.removeEventListener("resize", handleChangeSize);
    };
  }, []);

  return (
    <StyledSiteContainer>
      <TopNav
        isMobileNavOpen={isMobileNavOpen}
        setIsMobileNavOpen={setIsMobileNavOpen}
      />
      <AsideNav
        isNavOpen={isAsideNavOpen}
        setIsNavOpen={setIsAsideNavOpen}
        isMobileNavOpen={isMobileNavOpen}
        setIsMobileNavOpen={setIsMobileNavOpen}
      />
      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/home`}>
            <Home />
          </Route>
          <Route path={`${path}/contacts`}>
            <Contact />
          </Route>
          <Route path={`${path}/contacts`}>
            <Contact />
          </Route>
          <Route path={`${path}/calendar`}>
            <Calendar />
          </Route>
          {(user?.role?.id === 1 || user?.role?.id === 3) && (
            <Route path={`${path}/assembly`}>
              <Assembly />
            </Route>
          )}
          <Route path={`${path}/todo`}>
            <ToDo />
          </Route>
          <Route path={`${path}/opportunities`}>
            <OpportunitiesSwitch />
          </Route>
          <Route path={`${path}/search`}>
            <SearchFullSide />
          </Route>
          <Route path={`${path}/lead`}>
            <LeadSwitch />
          </Route>
          <Route path={`${path}/chat`}>
            <RocketChat />
          </Route>
          {(user?.role?.id === 1 || user?.role?.id === 2) && (
            <Route path={`${path}/offer-approve`}>
              <OfferApprove />
            </Route>
          )}
          {user?.role?.id === 1 && (
            <Route path={`${path}/contract-approve`}>
              <ContractApprove />
            </Route>
          )}
          {(user?.role?.id === 1 || user?.role?.id === 3) && (
            <Route path={`${path}/offer-pricing`}>
              <OfferPricing />
            </Route>
          )}

          {(user?.role?.id === 1 || user?.role?.id === 3) && (
            <Route path={`${path}/assembly-faults`}>
              <AssemblyFaults />
            </Route>
          )}

          {(user?.role?.id === 1 ||
            user?.role?.id === 2 ||
            user?.role?.id === 3) && (
            <Route path={`${path}/statistic`}>
              <Statistics />
            </Route>
          )}
          <Route path={`${path}/quick-calculation`}>
            <QuickCalculation />
          </Route>
        </Switch>
      </ContentContainer>
    </StyledSiteContainer>
  );
};

const StyledSiteContainer = styled.div``;

const ContentContainer = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;

  padding-left: 240px;

  @media screen and (max-width: 1200px) {
    padding-left: 0;
  }
`;

export default Dashboard;
