import {
  faChevronDown,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

const ItemDropDown = ({ title, users, handleOnChooseUser }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpenDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  };

  const handleSetUser = (user) => {
    handleOnChooseUser(user);
  };

  return (
    <StyledWrapper>
      <StyledButton onClick={handleOpenDropdown}>
        <StyledUserIcon icon={faUsers} />
        {title}
        <StyledAngleIcon icon={faChevronDown} />
      </StyledButton>
      <StyledItemWrapper
        style={isDropdownOpen ? { dispal: "block" } : { display: "none" }}
      >
        {users &&
          users.map((user) => {
            return (
              <StyledUserButton
                key={user.id}
                onClick={(e) => {
                  e.preventDefault();
                  handleSetUser(user);
                }}
              >
                <StyledUserIcon icon={faUser} /> {user.name}
              </StyledUserButton>
            );
          })}
      </StyledItemWrapper>
    </StyledWrapper>
  );
};
const StyledUserButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 4px;
  position: relative;
  background: white;
  border: none;
  cursor: pointer;
  padding-left: 20px;
  &:hover {
    background: lightgray;
  }
`;
const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledWrapper = styled.div``;

const StyledUserIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledAngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px;
`;

const StyledButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 4px;
  position: relative;
  background: white;
  border: none;
  border: none;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background: lightgray;
  }
`;
export default ItemDropDown;
