import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import request from "../../../../../helpers/request";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../components/Modal";
import { StoreContext } from "../../../../../store/StoreProvider";

const validationSchema = yup.object({
  discount: yup.string().nullable(),
});
const ChangeDiscount = ({
  offer,
  isModalOpen,
  handleOnClose,
  fetchData,
  setIsSended,
  isSended,
  setPageNumber,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const { user } = useContext(StoreContext);

  const [specialDiscount, setSpecialDiscount] = useState("");

  const resetInputValue = () => {
    reset({
      special_discount: "",
      price_netto: "",
      price_brutto: "",
      price_vat: "",
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/offer-change-discount-accepted/${offer.id}`,
        {
          ...data,
        }
      );
      if (status === 200) {
        fetchData(1);
        setPageNumber(1);
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data, e) => {
    e.preventDefault();
    sendData(data);
  };

  useEffect(() => {
    if (offer) {
      if (user.role.id === 1) {
        setValue("discount", offer.special_discount_management || 0);
        setSpecialDiscount(offer.special_discount_management || 0);
      }
      if (user.role.id === 2) {
        setValue("discount", offer.special_discount_manager || 0);
        setSpecialDiscount(offer.special_discount_manager || 0);
      }
    }

    setPriceWithoutDiscount();
  }, [offer]);

  const setPriceWithoutDiscount = () => {
    const netto = Number(offer.price_netto);

    const vat = ((Number(netto) * offer.vat) / 100).toFixed(2);
    const brutto = Number(netto) + Number(vat);

    setValue("price_netto", Number(netto).toFixed(2));
    setValue("price_brutto", Number(brutto).toFixed(2));
    setValue("price_vat", Number(vat).toFixed(2));
  };

  const handleOnChangeDiscount = (e) => {
    setSpecialDiscount(e.target.value);

    if (user.role.id === 1) {
      const netto =
        Number(offer.price_netto) +
        Number(offer.special_discount_management) -
        Number(e.target.value);

      const vat = ((Number(netto) * offer.vat) / 100).toFixed(2);
      const brutto = Number(netto) + Number(vat);

      setValue("price_netto", Number(netto).toFixed(2));
      setValue("price_brutto", Number(brutto).toFixed(2));
      setValue("price_vat", Number(vat).toFixed(2));
      setValue("discount", e.target.value);
    }
    if (user.role.id === 2) {
      const netto =
        Number(offer.price_netto) +
        Number(offer.special_discount_manager) -
        Number(e.target.value);

      const vat = ((Number(netto) * offer.vat) / 100).toFixed(2);
      const brutto = Number(netto) + Number(vat);

      setValue("price_netto", Number(netto).toFixed(2));
      setValue("price_brutto", Number(brutto).toFixed(2));
      setValue("price_vat", Number(vat).toFixed(2));
      setValue("discount", e.target.value);
    }
  };
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>

        <StyledInput>
          <div className="input-group">
            <label>Cena netto</label>
            <input type="text" {...register("price_netto")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Cena vat</label>
            <input type="text" {...register("price_vat")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Cena brutto</label>
            <input type="text" {...register("price_brutto")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Rabat</label>
            <input
              type="text"
              value={specialDiscount}
              onChange={handleOnChangeDiscount}
            />
          </div>
          <input type="hidden" {...register("discount")} />
        </StyledInput>
        {errors?.discount && (
          <span className="error">{errors.discount.message}</span>
        )}

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Zmień i akceptuj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default ChangeDiscount;
