import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const DropDownMenu = ({
  isOpen,
  setIsOpenOptions,
  handleOnOpenAlert,
  handleOnOpenConfirm,
  openEditModal,
}) => {
  const dropRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpenOptions(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  return (
    <Dropdown ref={dropRef} className={isOpen ? " active" : ""}>
      <ul>
        <li>
          <button onClick={handleOnOpenConfirm}>Zmiana Statusu</button>
        </li>
        <li>
          <button onClick={openEditModal}>Edytuj</button>
        </li>
        <li>
          <button onClick={handleOnOpenAlert}>Usuń</button>
        </li>
      </ul>
    </Dropdown>
  );
};

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 15px;
  z-index: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  display: none;
  min-width: 160px;

  ul {
    list-style: none;
  }
  a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px 5px;
  }
  button {
    display: block;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    text-decoration: none;
    color: black;
    padding: 5px 5px;
    cursor: pointer;
  }
  &.active {
    display: block;
  }
`;
export default DropDownMenu;
