import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ClientInfo = ({ client, handleOnClick }) => {
  const [adressString, setAdressString] = useState(null);

  useEffect(() => {
    if (
      client.city &&
      client.building_number &&
      client.postCode &&
      client.post &&
      client.street
    ) {
      setAdressString(
        `ul. ${client?.street} ${client?.building_number}, ${client?.city} ${client?.postCode} ${client?.post}`
      );
    } else if (
      client?.city &&
      client?.building_number &&
      client?.post &&
      client?.postCode
    ) {
      setAdressString(
        `${client?.city} ${client?.building_number}, ${client?.postCode} ${client?.post} `
      );
    } else if (
      client?.city &&
      client?.street &&
      client?.post &&
      client?.postCode
    ) {
      setAdressString(
        `ul. ${client?.street} ${client?.city}, ${client?.postCode} ${client?.post}`
      );
    } else if (
      client.city &&
      client.building_number &&
      client.postCode &&
      client.street
    ) {
      setAdressString(
        `ul. ${client?.street} ${client?.building_number}, ${client?.postCode} ${client?.city}`
      );
    } else if (client?.city && client?.building_number && client?.postCode) {
      setAdressString(
        `${client?.building_number}, ${client?.postCode} ${client?.city} `
      );
    } else if (client?.city && client?.street && client?.postCode) {
      setAdressString(
        `ul. ${client?.street}, ${client?.postCode} ${client?.city}`
      );
    } else {
      setAdressString(" Brak adresu / Adres nieprawidłowy");
    }
  }, [
    client,
    client?.postCode,
    client?.street,
    client?.city,
    client?.building_number,
  ]);

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledClientInfo>
          <StyledNameWrapper>
            <StyledClientName>
              {client.first_name} {client.last_name}
            </StyledClientName>
            {/* <StyledEditButton>Edytuj</StyledEditButton> */}
          </StyledNameWrapper>
        </StyledClientInfo>
        <StyledClientDescription>
          {client.phone && (
            <StyledPhoneWrapper>
              <FontAwesomeIcon icon={faPhoneAlt} />
              <StyledPhoneLink href={`tel:${client.phone}`}>
                {client.phone}
              </StyledPhoneLink>
            </StyledPhoneWrapper>
          )}
          {client.email && (
            <StyledEmailWrapper>
              <FontAwesomeIcon icon={faEnvelope} />
              <StyledEmailLink href={`email:${client.email}`}>
                {client.email}
              </StyledEmailLink>
            </StyledEmailWrapper>
          )}

          {adressString && (
            <StyledAdressWrapper>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <StyledAdressP>{adressString}</StyledAdressP>
            </StyledAdressWrapper>
          )}
          {client.voivodeship && (
            <StyledAdressWrapper>
              <FontAwesomeIcon icon={faBuilding} />
              <StyledAdressP>
                {" "}
                Województwo: {client.voivodeship.name}
              </StyledAdressP>
            </StyledAdressWrapper>
          )}

          {client.maintainer?.department ? (
            <StyledDepartmentWrapper>
              <FontAwesomeIcon icon={faUser} />{" "}
              {client.maintainer?.department.name}
            </StyledDepartmentWrapper>
          ) : (
            <StyledDepartmentWrapper>
              <FontAwesomeIcon icon={faUser} />{" "}
              {client.maintainer?.role?.id === 1 &&
                `Zarząd - ${client.maintainer?.name}`}
              {client.maintainer?.role?.id === 3 &&
                `Biuro - ${client.maintainer?.name}`}
            </StyledDepartmentWrapper>
          )}

          {client.source && (
            <StyledSourceWrapper>
              <StyledSourcaLabel>Źródło pozyskania:</StyledSourcaLabel>
              <StyledSourceP>{client.source?.name}</StyledSourceP>
            </StyledSourceWrapper>
          )}

          {client.heating_type && (
            <StyledSourceWrapper>
              <StyledSourcaLabel>System ogrzewania:</StyledSourcaLabel>
              <StyledSourceP>{client.heating_type?.name}</StyledSourceP>
            </StyledSourceWrapper>
          )}
          {client.description && (
            <StyledSourceWrapper>
              <StyledSourcaLabel>Opis:</StyledSourcaLabel>
              <StyledSourceP>{client.description}</StyledSourceP>
            </StyledSourceWrapper>
          )}
        </StyledClientDescription>
        <StyledButtonWrapper>
          <StyledEditButton onClick={handleOnClick}>Edytuj</StyledEditButton>
        </StyledButtonWrapper>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledAdressP = styled.p`
  margin-left: 5px;
`;

const StyledAdressWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;
const StyledContentWrapper = styled.div`
  background: white;
  border-radius: 10px;
`;

const StyledSourcaLabel = styled.p`
  font-size: 12px;
`;
const StyledSourceP = styled.p`
  color: black;
`;

const StyledSourceWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledPhoneWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledEmailWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledDepartmentWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledPhoneLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: #777;
    text-decoration: underline;
  }
`;
const StyledEmailLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: #777;
    text-decoration: underline;
  }
`;

const StyledClientInfo = styled.div`
  padding: 20px 10px;
`;
const StyledClientDescription = styled.div`
  padding: 0 10px;
`;
// const StyledEditButton = styled.button`
//   cursor: pointer;
//   background: transparent;
//   padding: 5px 10px;
//   border: none;
//   outline: none;
//   font-size: 14px;
//   color: #2d96db;
//   &:hover {
//     color: #0167a7;
//     text-decoration: underline;
//   }
// `;

const StyledNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div`
  grid-area: info;
`;

const StyledClientName = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export default ClientInfo;
