import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import DeleteAlert from "./DeleteAlert";

const InverterItem = ({
  inverter,
  fetchInverters,
  handleOpenEditTypeTask,
  setEditObject,
}) => {
  const { id, name, warranty, phases, power, price, margin, inverter_type } =
    inverter;

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/inverters/${id}`);
    if (status === 201) {
      fetchInverters();
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    setEditObject(inverter);
    handleOpenEditTypeTask();
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };
  return (
    <StyledTaskTypeItem>
      <StyledDescription>
        <p>{name}</p>
      </StyledDescription>
      <StyledWarranty>
        <p>{warranty} lat</p>
      </StyledWarranty>
      <StyledWarranty>
        {Number(inverter_type) === 1 && <p>stringowy</p>}
        {Number(inverter_type) === 2 && <p>hybrydowy</p>}
        {Number(inverter_type) === 3 && <p>RWB</p>}
      </StyledWarranty>
      <StyledWarranty>
        <p>{phases}</p>
      </StyledWarranty>
      <StyledWarranty>
        <p>{power ? `${power} kWp` : ""}</p>
      </StyledWarranty>
      <StyledWarranty>
        <p>{margin ? `${margin} %` : "0%"}</p>
      </StyledWarranty>
      <StyledWarranty>
        <p>{price ? `${price} zł` : ""}</p>
      </StyledWarranty>

      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.tr``;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.td``;
const StyledWarranty = styled.td`
  text-align: right;
`;

const StyledButtonGroup = styled.td`
  text-align: right;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export default InverterItem;
