import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddTaskStatus from "./AddTaskStatus";
import TaskStatusItem from "./TaskStatusItem/TaskStatusItem";

const TaskStatus = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState();

  const location = useLocation();
  const history = useHistory();

  const { taskStatusSetting, setTaskStatusSetting } = useContext(StoreContext);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenEditStatusTask = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchDataTaskStatus = async () => {
    try {
      const { status, data } = await request.get("/api/task-status-setting");
      if (status === 200) {
        await setTaskStatusSetting(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (taskStatusSetting.length === 0) {
      fetchDataTaskStatus();
    }
  }, []);

  return (
    <StyledTaskStatusWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>
          Dodaj status zadania
        </StyledButton>
      </StyledButtonWrapper>
      <StyledTaskStatusList>
        {taskStatusSetting.map((element) => {
          return (
            <TaskStatusItem
              handleOpenEditStatusTask={handleOpenEditStatusTask}
              fetchDataTaskStatus={fetchDataTaskStatus}
              setEditObject={setEditObject}
              element={element}
              key={element.id}
            />
          );
        })}
      </StyledTaskStatusList>
      <AddTaskStatus
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataTaskStatus}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledTaskStatusWrapper>
  );
};

const StyledTaskStatusWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledTaskStatusList = styled.ul`
  padding: 40px 0;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default TaskStatus;
