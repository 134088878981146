import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import EditUserPassword from "./EditUserPassword";
import UserListItem from "./UserListItem/UserListItem";

const UserList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] = useState(false);
  const [editObject, setEditObject] = useState();

  const { users, setUsers, departments, setDepartments } =
    useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setIsEditPasswordModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const fetchDataUser = async () => {
    try {
      const { status, data } = await request.get("/api/user");
      if (status === 200) {
        await setUsers(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchDataDepartments = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (users === undefined || users.length === 0) {
      fetchDataUser();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartments();
    }
  }, []);

  const handleOpenEditUser = () => {
    setIsEditModalOpen(true);
  };

  const handleOpenEditPassword = () => {
    setIsEditPasswordModalOpen(true);
  };

  return (
    <StyledUserWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj użytkownika</StyledButton>
      </StyledButtonWrapper>

      <StyledUserTable>
        <StyledUserTableHead>
          <tr>
            <StyledUserTableHeadItem></StyledUserTableHeadItem>
            <StyledUserTableHeadItem>Imie i nazwisko</StyledUserTableHeadItem>
            <StyledUserTableHeadItem>Oddział</StyledUserTableHeadItem>
            <StyledUserTableHeadItem>Zespół</StyledUserTableHeadItem>
            <StyledUserTableHeadItem>Typ użytkownika</StyledUserTableHeadItem>
            <StyledUserTableHeadItem>Akcje</StyledUserTableHeadItem>
          </tr>
        </StyledUserTableHead>
        <tbody>
          {users.map((user) => {
            return (
              <UserListItem
                handleOpenEditUser={handleOpenEditUser}
                handleOpenEditPassword={handleOpenEditPassword}
                fetchDataUser={fetchDataUser}
                setEditObject={setEditObject}
                user={user}
                key={user.id}
              />
            );
          })}
        </tbody>
      </StyledUserTable>
      <AddUser
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataUser}
      />
      <EditUser
        isModalOpen={isEditModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataUser}
        editObject={editObject}
      />
      <EditUserPassword
        isModalOpen={isEditPasswordModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataUser}
        editObject={editObject}
      />
    </StyledUserWrapper>
  );
};

const StyledUserWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledUserTable = styled.table`
  width: 100%;
  padding: 40px 0;
`;

const StyledUserTableHead = styled.thead`
  font-size: 18px;
`;

const StyledUserTableHeadItem = styled.th`
  text-align: start;
  font-size: 16px;
`;
const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default UserList;
