import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import request from "../../../helpers/request";
import styled from "styled-components";
import LeadLeftAside from "./Components/LeadLeftAside/LeadLeftAside";
import LeadAddNote from "./Components/LeadAddNote/LeadAddNote";
import LeadNoteList from "./Components/LeadNoteList/LeadNoteList";
import LeadSideBar from "./Components/LeadSideBar/LeadSideBar";
import EditLead from "./Components/EditLead/EditLead";

const LeadDetails = () => {
  const { id } = useParams();

  const [lead, setLead] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();
  // const handleOnAssemblyModalClose = () => {
  //   setIsAssemblyModalOpen(false);
  // };

  const handleOnEdit = () => {
    setIsModalOpen(true);
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const fetchLead = async () => {
    try {
      const { data, status } = await request.get(`api/lead/${id}`);
      if (status === 200) {
        setLead(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      } else {
        history.push("/dashboard/lead");
      }
    }
  };

  useEffect(() => {
    setLead([]);
    fetchLead();
  }, []);

  return (
    <>
      {lead && Object.keys(lead).length > 0 && lead.constructor === Object ? (
        <StyledWrapper>
          <LeadLeftAside
            lead={lead}
            // fetchOpportunities={() => fetchOpportunityItem(id)}
            handleOnEdit={handleOnEdit}
          />

          <StyledMainWall>
            {lead.wrong_lead === 0 && (
              <LeadAddNote fetchData={fetchLead} leadId={lead?.id} />
            )}
            <LeadNoteList note={lead?.notes} fetchData={fetchLead} />
          </StyledMainWall>

          <LeadSideBar fetchData={fetchLead} lead={lead} />
        </StyledWrapper>
      ) : (
        <StyledWrapper>Ładowanie leada ...</StyledWrapper>
      )}

      <EditLead
        lead={lead}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchLead}
      />
    </>
  );
};

const StyledMainWall = styled.div`
  flex-grow: 1;
  width: 100%;
  grid-area: note;

  @media screen and (max-width: 1600px) {
    margin: 0;
    flex: 1;
  }
`;

const StyledWrapper = styled.div`
  display: grid;
  padding: 10px;
  max-width: 1400px;
  margin: 0 auto;
  flex: 1;
  grid-template-columns: minmax(320px, 340px) 1fr minmax(360px, 380px);
  grid-auto-rows: fit-content(1em);
  gap: 10px;
  grid-template-areas: "info note sidebar";

  @media screen and (max-width: 1600px) {
    grid-template-columns: 1fr minmax(360px, 380px);
    grid-template-areas:
      "info sidebar"
      "note sidebar"
      "none sidebar";
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "info"
      "sidebar"
      "note";
  }
`;

export default LeadDetails;
