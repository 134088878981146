import { faDollarSign, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OpportunitiesPopOverCopmonent = ({
  opportunity,
  hover,
  setHover,
  hoverRef,
}) => {
  const handleSetHover = () => {
    setTimeout(() => setHover(false), 500);
  };

  return (
    <StyledWrapper
      onMouseLeave={handleSetHover}
      style={hover ? { display: "flex" } : { display: "none" }}
      ref={hoverRef}
    >
      {" "}
      <StyledDetailsWrapper>
        <StyledNameWrapper title={opportunity.name}>
          {opportunity.name}
        </StyledNameWrapper>

        {opportunity.company ? (
          <>
            {opportunity.company && (
              <StyledCompanyWrapper>
                Firma:{" "}
                <StyledCompanyLink
                  title={opportunity.company.name}
                  to={`/dashboard/contacts/companies/details/${opportunity.company.id}`}
                >
                  {opportunity.company.name}
                </StyledCompanyLink>
              </StyledCompanyWrapper>
            )}
          </>
        ) : (
          <>
            {opportunity.client && (
              <StyledClientWrapper>
                {" "}
                Osoba kontaktowa:{" "}
                <StyledClientLink
                  to={`/dashboard/contacts/clients/details/${opportunity.client.id}`}
                >
                  {opportunity.client.first_name} {opportunity.client.last_name}
                </StyledClientLink>
              </StyledClientWrapper>
            )}
          </>
        )}
        {opportunity.price ? (
          <StyledPriceWrapper>
            <StyledDollarSign icon={faDollarSign} /> Prognoza:{" "}
            {opportunity?.price
              ?.toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}{" "}
            PLN
          </StyledPriceWrapper>
        ) : (
          <StyledPriceWrapper>
            {" "}
            <StyledDollarSign icon={faDollarSign} /> Brak Prognozy
          </StyledPriceWrapper>
        )}
        {opportunity.maintainer?.department ? (
          <StyledDepartmentWrapper>
            <StyledDepartmentIcon icon={faUser} />{" "}
            {opportunity.maintainer?.department.name}
          </StyledDepartmentWrapper>
        ) : (
          <StyledDepartmentWrapper>
            <StyledDepartmentIcon icon={faUser} />{" "}
            {opportunity.maintainer?.role?.id === 1 &&
              `Zarząd - ${opportunity.maintainer?.name}`}
            {opportunity.maintainer?.role?.id === 3 &&
              `Biuro - ${opportunity.maintainer?.name}`}
          </StyledDepartmentWrapper>
        )}
      </StyledDetailsWrapper>
      <StyledPopOverBottom>
        <StyledProfileLink
          to={`/dashboard/opportunities/details/${opportunity.id}`}
        >
          Zobacz Profil
        </StyledProfileLink>
      </StyledPopOverBottom>
    </StyledWrapper>
  );
};

const StyledDollarSign = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledPriceWrapper = styled.div`
  font-size: 14px;
`;
const StyledClientLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #134771;
  text-decoration: none;
  margin-left: 5px;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledClientWrapper = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
`;

const StyledCompanyLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #134771;
  text-decoration: none;
  margin-left: 5px;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledCompanyWrapper = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
`;

const StyledDetailsWrapper = styled.div`
  padding: 10px;
`;

const StyledProfileLink = styled(Link)`
  color: #134771;
  text-decoration: none;

  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledPopOverBottom = styled.div`
  background: #cadeee;
  padding: 10px;
  border-radius: 0 0 10px 10px;
`;

const StyledDepartmentLink = styled.p``;
const StyledDepartmentIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
const StyledDepartmentWrapper = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
`;

const StyledMailLink = styled.a`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledMailIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const StyledMailWrapper = styled.div``;
const StyledPhoneLink = styled.a`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledPhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
const StyledPhoneWrapper = styled.div``;

const StyledNameWrapper = styled.div`
  font-size: 18px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 100;
  min-width: 320px;
  max-width: 360px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`;
export default OpportunitiesPopOverCopmonent;
