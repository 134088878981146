import React, { useContext, useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import request from "../../helpers/request";

import styled from "styled-components";
import { StoreContext } from "../../store/StoreProvider";
import ClientDetails from "./ClientDetails/ClientDetails";
import Clients from "./Clients/Clients";
import Companies from "./Companies/Companies";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import UserDetails from "./UserDetails/UserDetails";

const Contact = () => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { setVoivodeships, voivodeships } = useContext(StoreContext);

  useEffect(() => {
    if (history.location.pathname === "/dashboard/contacts") {
      history.push("/dashboard/contacts/clients");
    }
  }, []);

  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
  }, []);

  return (
    <StyledContactsNav>
      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/clients`} component={Clients} />

          <Route exact path={`${path}/companies`} component={Companies} />
          <Route
            path={`${path}/companies/details/:id`}
            component={CompanyDetails}
          />
          <Route
            path={`${path}/clients/details/:id`}
            component={ClientDetails}
          />
          <Route path={`${path}/user/details/:id`} component={UserDetails} />
        </Switch>
      </ContentContainer>
    </StyledContactsNav>
  );
};

const StyledContactsNav = styled.nav`
  flex: 1;
`;

const ContentContainer = styled.div`
  width: 100%;
`;

export default Contact;
