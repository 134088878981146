import React, { useState } from "react";
import styled from "styled-components";
import AddInstalation from "./AddInstalation/AddInstalation";
import InstalationItem from "./InstalationItem/InstalationItem";

const InstalationTypeItem = ({ instalationType, fetchInstalation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(false);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(false);
    setIsModalOpen(true);
  };

  const handleOpenEditInstalation = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };
  return (
    <StyledInstalationTypeWrapper>
      <StyledTitleWrapper>
        <StyledTitle>{instalationType.name}</StyledTitle>
      </StyledTitleWrapper>
      <StyledAddButtonWrapper>
        <StyledAddButton onClick={handleOpenModal}>
          Dodaj Instalacje
        </StyledAddButton>
      </StyledAddButtonWrapper>
      {instalationType.instalation?.length > 0 && (
        <StyledOfferTable>
          <StyledOfferTableHead>
            <tr>
              <StyledSwitchTable>Aktywna</StyledSwitchTable>
              <StyledOfferTableHeadItem>
                Nazwa Instalacji
              </StyledOfferTableHeadItem>
              <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
                Akcje
              </StyledOfferTableHeadItem>
            </tr>
          </StyledOfferTableHead>
          <tbody>
            {instalationType.instalation.map((instalation) => {
              return (
                <InstalationItem
                  key={instalation.id}
                  handleOpenEditInstalation={handleOpenEditInstalation}
                  fetchInstalation={fetchInstalation}
                  setEditObject={setEditObject}
                  instalation={instalation}
                />
              );
            })}
          </tbody>
        </StyledOfferTable>
      )}
      <AddInstalation
        instalationTypeId={instalationType.id}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchInstalation}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledInstalationTypeWrapper>
  );
};
const StyledSwitchTable = styled.th`
  text-align: left;
`;
const StyledAddButton = styled.button`
  padding: 10px 15px;
  color: #cadeee;
  background: #134771;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledAddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledTitle = styled.p`
  font-size: 20px;
  color: #134771;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInstalationTypeWrapper = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledOfferTable = styled.table`
  width: 100%;
  padding: 40px 0;
`;

const StyledOfferTableHead = styled.thead`
  font-size: 18px;
`;

const StyledOfferTableHeadItem = styled.th`
  text-align: start;
`;
export default InstalationTypeItem;
