import React from "react";
import styled from "styled-components";

const Switch = ({ checked, handleOnChange }) => {
  return (
    <StyledSwitchWrapper>
      <label className="toggle-switch-label">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          checked={checked}
          onChange={handleOnChange}
        />

        <span className="toggle-switch-control" />
      </label>
    </StyledSwitchWrapper>
  );
};

const StyledSwitchWrapper = styled.div`
  width: 40px;
  height: 20px;
  margin-right: 20px;
  .toggle-switch-label {
    display: block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }
  .toggle-switch-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .toggle-switch-checkbox:checked ~ .toggle-switch-control {
    background-color: blue;

    &:after {
      left: 20px;
    }
  }
  .toggle-switch-control {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 40px;
    border-radius: 10px;
    background-color: gray;
    transition: background-color 0.2s linear;
    &:after {
      content: "";
      position: absolute;
      left: 1px;
      top: 1px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: white;
      transition: left 0.2s linear;
    }
  }
`;
export default Switch;
