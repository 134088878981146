import React from "react";
import styled from "styled-components";

const CommentItem = ({ comment }) => {
  return (
    <StyledWrapper>
      <StyledUserInitialsWrapper>
        <StyledUserInitials>{comment.user.initials}</StyledUserInitials>
      </StyledUserInitialsWrapper>
      <ContentWrapper>
        <UserName>{comment.user.name}</UserName>
        <Content>{comment.comment}</Content>
      </ContentWrapper>
    </StyledWrapper>
  );
};

const Content = styled.p`
  font-size: 15px;
`;
const UserName = styled.div`
  font-size: 13px;
  font-weight: bold;
`;
const ContentWrapper = styled.div`
  padding: 8px 12px;
  border-radius: 10px;
  background: #cadeee;
  color: #134771;
`;
const StyledUserInitialsWrapper = styled.div`
  width: 42px;
  height: 36px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
const StyledUserInitials = styled.div`
  width: 32px;
  height: 32px;
  background: #cadeee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
`;
export default CommentItem;
