import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../helpers/request";
import { AssemblyFaultsContext } from "../../store/AssemblyFaultsProvider";
import { StoreContext } from "../../store/StoreProvider";
import AddAssembluFault from "./Components/AddAssemblyFault/AddAssemblyFault";
import AssemblyFaultsAside from "./Components/AssemblyFaultsAside/AssemblyFaultsAside";
import AssemblyFaultsList from "./Components/AssemblyFaultsList/AssemblyFaultsList";

const AssemblyFaults = () => {
  const [assemblyFaults, setAssemblyFaults] = useState([]);

  const {
    voivodeshipFilter,
    setVoivodeshipFilter,
    searchStartDate,
    setSearchStartDate,
    searchEndDate,
    setSearchEndDate,
    dateFilter,
    setDateFilter,
    searchValue,
    setSearchValue,
    pageNumber,
    setPageNumber,
    doneFilter,
    setDoneFilter,
    priorityFilter,
    setPriorityFilter,
    activeBuses,
    setActiveBuses,
  } = useContext(AssemblyFaultsContext);
  const { voivodeships, setVoivodeships, teams, setTeams } =
    useContext(StoreContext);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const location = useLocation();
  const history = useHistory();

  const fetchAssemblyFaults = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(
        `/api/assembly-faults?page=${number}`,
        {
          params: {
            voivodeship: voivodeshipFilter,
            search: searchValue,
            start_date: searchStartDate,
            end_date: searchEndDate,
            done: doneFilter,
            priority: priorityFilter,
          },
        }
      );
      if (status === 200) {
        setAssemblyFaults(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };

  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchActiveBuses = async () => {
    try {
      const { status, data } = await request.get("/api/assembly-bus-active");
      if (status === 200) {
        await setActiveBuses(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
    if (teams === undefined || teams.length === 0) {
      fetchTeams();
    }
    if (activeBuses === undefined || activeBuses.length === 0) {
      fetchActiveBuses();
    }
    if (assemblyFaults.length === 0) {
      fetchAssemblyFaults(1);
    }
  }, []);

  useEffect(() => {
    if (dateFilter) {
      if (dateFilter === 1) {
        setSearchStartDate(moment().startOf("week").toDate());
        setSearchEndDate(moment().endOf("week").toDate());
      } else if (dateFilter === 2) {
        setSearchStartDate(moment().startOf("month").toDate());
        setSearchEndDate(moment().endOf("month").toDate());
      } else if (dateFilter === 3) {
        setSearchStartDate(
          moment().subtract(1, "month").startOf("month").toDate()
        );
        setSearchEndDate(moment().subtract(1, "month").endOf("month").toDate());
      } else if (dateFilter === 4) {
        setSearchStartDate(moment().subtract(7, "day").toDate());
        setSearchEndDate(moment().toDate());
      } else if (dateFilter === 5) {
        setSearchStartDate(moment().subtract(30, "day").toDate());
        setSearchEndDate(moment().toDate());
      }
    } else {
      setSearchStartDate(null);
      setSearchEndDate(null);
    }
  }, [dateFilter]);

  const handleOnClick = () => {
    setIsAddModalOpen(true);
  };
  const handleOnCloseAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAddModalOpen(false);
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Usterki montażowe</StyledTitle>
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnClick} title="Dodaj usterkę">
            <FontAwesomeIcon icon={faPlus} />{" "}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledTitleWrapper>
      <ContentWrapper>
        <AssemblyFaultsAside
          voivodeshipFilter={voivodeshipFilter}
          setVoivodeshipFilter={setVoivodeshipFilter}
          setDateFilters={setDateFilter}
          dateFilters={dateFilter}
          doneFilter={doneFilter}
          setDoneFilter={setDoneFilter}
          priorityFilter={priorityFilter}
          setPriorityFilter={setPriorityFilter}
        />
        <AssemblyFaultsList
          assemblyFaults={assemblyFaults}
          voivodeshipFilter={voivodeshipFilter}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setSearchInput={setSearchInput}
          setPageNumber={setPageNumber}
          fetchAssemblyFaults={fetchAssemblyFaults}
          isLoading={isLoading}
          searchInput={searchInput}
          pageNumber={pageNumber}
          pageCount={pageCount}
          searchStartDate={searchStartDate}
          setSearchStartDate={setSearchStartDate}
          searchEndDate={searchEndDate}
          setSearchEndDate={setSearchEndDate}
          setDateFilter={setDateFilter}
          doneFilter={doneFilter}
          priorityFilter={priorityFilter}
        />
      </ContentWrapper>
      <AddAssembluFault
        isModalOpen={isAddModalOpen}
        handleOnClose={handleOnCloseAddModal}
        fetchData={fetchAssemblyFaults}
      />
    </StyledWrapper>
  );
};

const StyledButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: transparent;
  color: black;
  font-size: 28px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  &:hover {
    color: rgb(244, 118, 49);
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  min-width: 0;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export default AssemblyFaults;
