import React, { useContext } from "react";
import styled from "styled-components";
import Switch from "../../../../../../components/Switch";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";

const YourTasks = () => {
  const { yourTaskSetting, setYourTaskSetting } = useContext(StoreContext);

  const fetchData = async () => {
    const { data, status } = await request.get("/api/your-task");
    if (status === 200) {
      setYourTaskSetting(data);
    }
  };

  if (yourTaskSetting.length === 0) {
    fetchData();
  }

  const handleOnSwitchChange = async (id, data) => {
    const { status } = await request.patch(`/api/your-task/${id}`, {
      is_active: data,
    });
    if (status === 200) {
      fetchData();
    }
  };

  return (
    <StyledYourTasksWrapper>
      <StyledYourTaskList>
        {yourTaskSetting.map((task) => (
          <StyledYourTaskListItem key={task.id}>
            <Switch
              checked={task.is_active}
              name={task.name}
              id={task.id}
              handleOnChange={handleOnSwitchChange}
            />
            <p> {task.name}</p>
          </StyledYourTaskListItem>
        ))}
      </StyledYourTaskList>
    </StyledYourTasksWrapper>
  );
};

const StyledYourTasksWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledYourTaskList = styled.ul`
  padding: 40px 0;
`;

const StyledYourTaskListItem = styled.li`
  list-style: none;
  padding: 5px 0px;
  display: flex;
`;

export default YourTasks;
