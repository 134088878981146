import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../../../../components/Modal";

import request from "../../../../../../../../../../helpers/request";
import { StoreContext } from "../../../../../../../../../../store/StoreProvider";

const validationSchema = yup.object({});
const AddJoinDetails = ({
  opportunities,
  isModalOpen,
  handleOnClose,
  fetchData,
  ownerDetails,
}) => {
  const { setUncompleteSaleOpportunities } = useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      people_count: 1,
    },
  });

  const [energeticJoinChange, setEnergeticJoinChange] = useState(0);
  const [electricitySuppilerOther, setElectricitySuppilerOther] =
    useState(false);
  const [electricitySuppiler, setElectricitySuppiler] = useState(1);
  const [meterInstallationPlace, setMeterInstallationPlace] = useState(0);
  const [chooseProxyPeople, setChooseProxyPeople] = useState(1);
  const [isSended, setIsSended] = useState(false);

  const resetInputValue = () => {
    reset({});
  };
  const sendData = async (sendData) => {
    setIsSended(true);
    try {
      const { data, status } = await request.post(
        `api/energetic-join-details`,
        {
          ...sendData,
        }
      );
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
        setUncompleteSaleOpportunities(data.uncomplete_sale_opportunities);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (opportunities) {
      setValue("opportunity_id", opportunities.id);
    }
  }, [opportunities]);

  const handleOnEnergeticJoinChange = (e) => {
    setEnergeticJoinChange(Number(e.target.value));
    setValue("energetic_join_details_change", Number(e.target.value));
  };

  const handleOnElectricitySuppiler = (e) => {
    setValue("electricity_supplier", Number(e.target.value));
    setElectricitySuppiler(Number(e.target.value));
    if (Number(e.target.value) === 6) {
      setElectricitySuppilerOther(true);
    } else {
      setElectricitySuppilerOther(false);
    }
  };
  useEffect(() => {
    setValue("energetic_join_details_change", 0);
    setValue("electricity_supplier", 1);
    setEnergeticJoinChange(0);
    setElectricitySuppilerOther(false);
  }, []);

  const handleOnChooseProxyPeople = (e) => {
    setChooseProxyPeople(Number(e.target.value));
    setValue("people_count", e.target.value);
  };

  const handleChangeMeterInstallationPlace = (e) => {
    setMeterInstallationPlace(Number(e.target.value));
    setValue("meter_installation_place", Number(e.target.value));
  };

  useEffect(() => {
    if (ownerDetails && chooseProxyPeople === 1 && !opportunities.company) {
      setValue("proxy_id_card_1", ownerDetails.investor_id_card_1);
      setValue("proxy_name_1", ownerDetails.investor_name_1);
      setValue("proxy_pesel_1", ownerDetails.investor_pesel_1);
      setValue("street", ownerDetails.street);
      setValue("building_number", ownerDetails.building_number);
      setValue("city", ownerDetails.city);
      setValue("post_code", ownerDetails.post_code);
      setValue("post", ownerDetails.post);
    } else if (
      ownerDetails &&
      chooseProxyPeople === 2 &&
      !opportunities.company
    ) {
      setValue("proxy_id_card_1", ownerDetails.investor_id_card_1);
      setValue("proxy_id_card_2", ownerDetails.investor_id_card_2);
      setValue("proxy_name_1", ownerDetails.investor_name_1);
      setValue("proxy_name_2", ownerDetails.investor_name_2);
      setValue("proxy_pesel_1", ownerDetails.investor_pesel_1);
      setValue("proxy_pesel_2", ownerDetails.investor_pesel_2);
      setValue("street", ownerDetails.street);
      setValue("building_number", ownerDetails.building_number);
      setValue("city", ownerDetails.city);
      setValue("post_code", ownerDetails.post_code);
      setValue("post", ownerDetails.post);
    } else if (
      ownerDetails &&
      chooseProxyPeople === 1 &&
      opportunities.company
    ) {
      setValue("proxy_id_card_1", ownerDetails.investor_id_card_1);
      setValue("proxy_name_1", ownerDetails.investor_name_1);
      setValue("proxy_pesel_1", ownerDetails.investor_pesel_1);
    } else if (opportunities.client) {
      setValue(
        "proxy_name_1",
        `${opportunities.client.first_name} ${opportunities.client.last_name}`
      );
      setValue("post", opportunities.client.post);
      setValue("post_code", opportunities.client.postCode);
      setValue("post", opportunities.client.post);
      setValue("street", opportunities.client.street);
      setValue("building_number", opportunities.client.building_number);
      setValue("city", opportunities.client.city);
    }
  }, [
    opportunities.client,
    opportunities.company,
    opportunities,
    ownerDetails,
    chooseProxyPeople,
  ]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <input type="hidden" {...register("opportunity_id")} />

        <StyledTitleWrapper>Dane przyłącza</StyledTitleWrapper>

        <StyledInput>
          <div className="input-group">
            <label>Przyłącze energetyczne</label>
            <select {...register("power_connection")}>
              <option value={1}>Napowietrzne</option>
              <option value={2}>Kablowe</option>
            </select>
          </div>
        </StyledInput>
        {errors?.power_connection && (
          <span className="error">{errors.power_connection.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Miejsce zainstalowania licznika</label>
            <select
              onChange={handleChangeMeterInstallationPlace}
              value={meterInstallationPlace}
            >
              <option value={0}>Wybierz... </option>
              <option value={1}>w ogrodzeniu</option>
              <option value={2}>w budynku</option>
              <option value={3}>na elewacji budynku</option>
            </select>
          </div>
          <input type="hidden" {...register("meter_installation_place")} />
        </StyledInput>
        {errors?.meter_installation_place && (
          <span className="error">
            {errors.meter_installation_place.message}
          </span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Dostawca energii elektrycznej</label>
            <select
              onChange={handleOnElectricitySuppiler}
              value={electricitySuppiler}
            >
              <option value={1}>PGE</option>
              <option value={2}>Tauron</option>
              <option value={3}>Enea</option>
              <option value={4}>Energa</option>
              <option value={5}>Innogy</option>
              <option value={6}>Inne</option>
            </select>
          </div>
          <input type="hidden" {...register("electricity_supplier")} />
        </StyledInput>
        {errors?.electricity_supplier && (
          <span className="error">{errors.electricity_supplier.message}</span>
        )}
        {electricitySuppilerOther && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Dostawca energii elektrycznej inny </label>
                <input
                  type="text"
                  {...register("electricity_suppiler_other")}
                />
              </div>
            </StyledInput>
            {errors?.electricity_suppiler_other && (
              <span className="error">
                {errors.electricity_suppiler_other.message}
              </span>
            )}
          </>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Wartość zabezpieczenia przedlicznikowego (A)</label>
            <input type="text" {...register("metering_protection_value")} />
          </div>
        </StyledInput>
        {errors?.metering_protection_value && (
          <span className="error">
            {errors.metering_protection_value.message}
          </span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Moc przyłączeniowa (kW)</label>
            <input type="text" {...register("power_connection_power")} />
          </div>
        </StyledInput>
        {errors?.power_connection_power && (
          <span className="error">{errors.power_connection_power.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Moc umowna (kW)</label>
            <input type="text" {...register("contracted_power")} />
          </div>
        </StyledInput>
        {errors?.contracted_power && (
          <span className="error">{errors.contracted_power.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Nr PPE (Punkt Poboru Energii)</label>
            <input type="text" {...register("energy_consumption_point")} />
          </div>
        </StyledInput>
        {errors?.energy_consumption_point && (
          <span className="error">
            {errors.energy_consumption_point.message}
          </span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Nr Licznika</label>
            <input type="text" {...register("counter_number")} />
          </div>
        </StyledInput>
        {errors?.counter_number && (
          <span className="error">{errors.counter_number.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Typ instalacji</label>
            <select {...register("instalation_type")}>
              <option value={1}>Instalacja 1-fazowa</option>
              <option value={2}>Instalacja 3-fazowa</option>
            </select>
          </div>
        </StyledInput>
        {errors?.instalation_type && (
          <span className="error">{errors.instalation_type.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Dane przyłącza energetycznego</label>
            <select
              value={energeticJoinChange}
              onChange={handleOnEnergeticJoinChange}
            >
              <option value={0}>Nie ulegną zmianie</option>
              <option value={1}>Wymagane zmiany</option>
            </select>

            <input
              type="hidden"
              {...register("energetic_join_details_change")}
            />
          </div>
        </StyledInput>
        {errors?.energetic_join_details_change && (
          <span className="error">
            {errors.energetic_join_details_change.message}
          </span>
        )}
        {energeticJoinChange !== 0 && (
          <>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="data_update"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Aktualizacja danych
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="rewriting_the_counter"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przepisanie licznika
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="connection_capacity_change"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Zmiana mocy przyłączeniowej
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="merge_counters"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Scalenie liczników
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="phaze_change"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Zmiana faz
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>{" "}
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="energetic_join_details_other"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Inne
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledInput>
              <div className="input-group">
                <label>Opis:</label>
                <input type="text" {...register("energetic_join_details")} />
              </div>
            </StyledInput>
            {errors?.energetic_join_details && (
              <span className="error">
                {errors.energetic_join_details.message}
              </span>
            )}
          </>
        )}

        <StyledTitleWrapper>Dane do pełnomocnictwa</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Ilość osób do pełnomocnictwa</label>
            <select
              onChange={handleOnChooseProxyPeople}
              value={chooseProxyPeople}
              disabled={opportunities.company ? true : false}
            >
              <option value={1}>Jedna</option>
              <option value={2}>Dwie</option>
            </select>
          </div>
          <input type="hidden" {...register("people_count")} />
        </StyledInput>

        {chooseProxyPeople === 1 && opportunities.company && (
          <>
            <StyledTitleWrapper>Osoba reprezentująca</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>Imię i nazwisko</label>
                <input
                  type="text"
                  {...register("proxy_name_1")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.proxy_name_1 && (
              <span className="error">{errors.proxy_name_1.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Seria i numer dowodu osobistego</label>
                <input
                  type="text"
                  {...register("proxy_id_card_1")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.proxy_id_card_1 && (
              <span className="error">{errors.proxy_id_card_1.message}</span>
            )}

            <StyledInput>
              <div className="input-group">
                <label>Pesel</label>
                <input
                  type="text"
                  {...register("proxy_pesel_1")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.proxy_pesel_1 && (
              <span className="error">{errors.proxy_pesel_1.message}</span>
            )}
          </>
        )}

        {(chooseProxyPeople === 1 || chooseProxyPeople === 2) &&
          !opportunities.company && (
            <>
              <StyledTitleWrapper>
                Pierwszy nabywca z faktury
              </StyledTitleWrapper>
              <StyledInput>
                <div className="input-group">
                  <label>Imię i nazwisko</label>
                  <input
                    type="text"
                    {...register("proxy_name_1")}
                    autoComplete="off"
                  />
                </div>
              </StyledInput>
              {errors?.proxy_name_1 && (
                <span className="error">{errors.proxy_name_1.message}</span>
              )}
              <StyledInput>
                <div className="input-group">
                  <label>Seria i numer dowodu osobistego</label>
                  <input
                    type="text"
                    {...register("proxy_id_card_1")}
                    autoComplete="off"
                  />
                </div>
              </StyledInput>
              {errors?.proxy_id_card_1 && (
                <span className="error">{errors.proxy_id_card_1.message}</span>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Pesel</label>
                  <input
                    type="text"
                    {...register("proxy_pesel_1")}
                    autoComplete="off"
                  />
                </div>
              </StyledInput>
              {errors?.proxy_pesel_1 && (
                <span className="error">{errors.proxy_pesel_1.message}</span>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Ulica</label>
                  <input
                    type="text"
                    {...register("street")}
                    autoComplete="off"
                  />
                </div>
              </StyledInput>
              {errors?.street && (
                <span className="error">{errors.street.message}</span>
              )}
              <StyledInput>
                <div className="input-group">
                  <label>Numer budynku</label>
                  <input
                    type="text"
                    {...register("building_number")}
                    autoComplete="off"
                  />
                </div>
              </StyledInput>
              {errors?.building_number && (
                <span className="error">{errors.building_number.message}</span>
              )}
              <StyledInput>
                <div className="input-group">
                  <label>Kod pocztowy</label>
                  <input
                    type="text"
                    {...register("post_code")}
                    autoComplete="off"
                  />
                </div>
              </StyledInput>
              {errors?.post_code && (
                <span className="error">{errors.post_code.message}</span>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Poczta</label>
                  <input type="text" {...register("post")} autoComplete="off" />
                </div>
              </StyledInput>
              {errors?.post && (
                <span className="error">{errors.post.message}</span>
              )}
              <StyledInput>
                <div className="input-group">
                  <label>Miejscowość</label>
                  <input type="text" {...register("city")} autoComplete="off" />
                </div>
              </StyledInput>
              {errors?.city && (
                <span className="error">{errors.city.message}</span>
              )}
            </>
          )}

        {chooseProxyPeople === 2 && (
          <>
            <StyledTitleWrapper>Drugi nabywca z faktury</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>Imię i nazwisko</label>
                <input
                  type="text"
                  {...register("proxy_name_2")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.proxy_name_2 && (
              <span className="error">{errors.proxy_name_2.message}</span>
            )}

            <StyledInput>
              <div className="input-group">
                <label>Seria i numer dowodu osobistego</label>
                <input
                  type="text"
                  {...register("proxy_id_card_2")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.proxy_id_card_2 && (
              <span className="error">{errors.proxy_id_card_2.message}</span>
            )}

            <StyledInput>
              <div className="input-group">
                <label>Pesel</label>
                <input
                  type="text"
                  {...register("proxy_pesel_2")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.proxy_pesel_2 && (
              <span className="error">{errors.proxy_pesel_2.message}</span>
            )}
          </>
        )}

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Dodaj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 320px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddJoinDetails;
