export default function buildCalendarMonth(value) {
  const startDay = value.clone().startOf("month").startOf("isoweek");
  const endDay = value.clone().endOf("month").endOf("isoweek");

  const day = startDay.clone().subtract(1, "day");

  const calendar = [];
  while (day.isBefore(endDay, "day")) {
    calendar.push(day.add(1, "day").clone());
  }

  return calendar;
}
