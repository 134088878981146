import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../store/StoreProvider";
import ResponseInformation from "./ResponseInformation";
const InformationItem = ({
  information,
  handleOnOpenAlert,
  fetchOpportunities,
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMaintainer, setIsMaintainer] = useState(false);
  const { user } = useContext(StoreContext);

  useEffect(() => {
    if (information && user) {
      setIsAdmin(user.role?.id === 1);
      setIsMaintainer(user.id === information.user.id);
    }
  }, [information, user]);

  const openResponseModal = () => {
    setResponseModalOpen(true);
  };
  const closeResponseModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setResponseModalOpen(false);
  };
  return (
    <StyledWrapper>
      {information.user && (
        <StyledUserInfoWrapper>
          <StyledUserInfo>
            Dodane przez: {information.user.name}.
          </StyledUserInfo>
          <StyledUserInfo>
            Dnia: {moment(information.created_at).format(`DD-MM-YY g:HH:mm`)}
          </StyledUserInfo>
        </StyledUserInfoWrapper>
      )}
      <StyledText>{information.text}</StyledText>
      {information.is_response && (
        <ResponseWrapper>
          <StyledResponseTitle>Odpowiedź:</StyledResponseTitle>
          <StyledResponseInfoWrapper>
            {information.response_user && (
              <p>Dodane przez: {information.response_user.name} </p>
            )}
            {information.response_date && (
              <p>
                Dnia : {moment(information.date).format(`DD-MM-YY g:HH:mm`)}{" "}
              </p>
            )}
          </StyledResponseInfoWrapper>

          <StyledText>{information.response_text}</StyledText>
        </ResponseWrapper>
      )}
      {(isAdmin || isMaintainer) && (
        <StyledDeleteButton onClick={() => handleOnOpenAlert(information)}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledDeleteButton>
      )}
      {user.id !== information.user.id &&
        isMaintainer &&
        !information.is_responese && (
          <StyledResponseButton onClick={openResponseModal}>
            Odpowiedz
          </StyledResponseButton>
        )}
      <ResponseInformation
        isModalOpen={responseModalOpen}
        information={information}
        handleOnClose={closeResponseModal}
        fetchOpportunities={fetchOpportunities}
      />
    </StyledWrapper>
  );
};

const StyledText = styled.p`
  white-space: pre-line;
  padding: 5px;
`;

const ResponseWrapper = styled.div`
  margin-left: 10px;
  & ${StyledText} {
    padding: 5px 0;
  }
`;

const StyledResponseInfoWrapper = styled.div`
  font-size: 12px;
  display: flex;
`;

const StyledResponseTitle = styled.h4`
  color: #134771;
`;
const StyledResponseButton = styled.button`
  padding: 2px 5px;
  background: #134771;
  color: white;
  border: none;
  width: 100%;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #053257;
  }
`;

const StyledUserInfoWrapper = styled.div`
  padding-right: 30px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledDeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: 2px solid #134771;
  outline: none;
  border-radius: 50%;
  background: white;
  color: #134771;
  cursor: pointer;
`;

const StyledUserInfo = styled.div`
  font-size: 14px;
  margin-right: 5px;
`;

const StyledWrapper = styled.div`
  padding: 10px 0;
  position: relative;
`;

export default InformationItem;
