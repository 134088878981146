import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const CollapseTypItem = ({
  id,
  name,
  prefix,
  icon,
  setTaskTypeFilter,
  taskTypeFilter,
}) => {
  const handleOnClick = () => {
    if (taskTypeFilter === id) {
      setTaskTypeFilter(null);
    } else {
      setTaskTypeFilter(id);
    }
  };
  const activeClassChecker = () => {
    if (id === taskTypeFilter) return "active";
    return "";
  };

  return (
    <StyledCollapseItemButton
      onClick={handleOnClick}
      className={activeClassChecker()}
    >
      <StyledSVGWrapper>
        {prefix && icon && <FontAwesomeIcon icon={[prefix, icon]} />}
      </StyledSVGWrapper>
      <p>{name}</p>
    </StyledCollapseItemButton>
  );
};

const StyledSVGWrapper = styled.div`
  width: 20px;
  padding-right: 10px;
  height: 20px;
  margin: 0 10px;
  text-align: center;
`;

const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 3px 5px;
  height: 20px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  &:hover {
    background: lightgray;
  }
  &.active {
    background: lightgray;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
  }
`;
export default CollapseTypItem;
