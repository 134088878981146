import React, { useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import request from "../../helpers/request";
import { Cookies } from "react-cookie";
import { useContext } from "react";
import { StoreContext } from "../../store/StoreProvider";

const TopNavOptionDropDown = ({ isOpen, handleIsOpen }) => {
  let location = useLocation();
  const history = useHistory();
  const dropRef = useRef(null);

  const { user } = useContext(StoreContext);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      handleIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  const handleOnLogOut = async () => {
    try {
      const { status } = await request.post("api/logout");
      if (status === 200) {
        const cookie = new Cookies();
        cookie.remove("access-token");
        localStorage.clear();
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    } catch (e) {
      if (e.rsponse?.status === 401) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  return (
    <Dropdown ref={dropRef} className={isOpen ? " active" : ""}>
      <ul>
        {/* <li>
          <a href="#">Mój profil</a>
        </li> */}
        {/* <li>
          <Link to="/user-settings">Ustawiena profilu</Link>
        </li> */}

        {user?.role?.id === 1 && (
          <li>
            <Link to="/settings">Ustawienia Konta</Link>
          </li>
        )}

        {/* <li>
          <a href="#">Osoby i zespoł</a>
        </li>
        <li>
          <a href="#">Pomoc</a>
        </li> */}
        <li>
          <button onClick={handleOnLogOut} className="logout-btn">
            Wyloguj
          </button>
        </li>
      </ul>
    </Dropdown>
  );
};

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  min-width: 200px;
  right: 15px;
  z-index: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;

  display: none;

  ul {
    list-style: none;
  }
  button.logout-btn {
    margin: 0;
    color: black;
    padding: 5px 5px;
    font-size: 16px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
  a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px 5px;
  }
  &.active {
    display: block;
  }
`;

export default TopNavOptionDropDown;
