import React, { useEffect } from "react";
import styled from "styled-components";
import ShowContract from "./Components/ShowContract/ShowContract";
import HeatPumpContractInfo from "./Components/HeatPumpContractInfo/HeatPumpContractInfo";
import HeatPumpMakeOffer from "./Components/HeatPumpMakeOffer/HeatPumpMakeOffer";
import { useRef } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const HeatPumpSaleProcess = ({ opportunities, fetchData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  useEffect(() => {
    if (content && isOpen) {
      setHeight(`${content.current.scrollHeight}px`);
    }
  }, [opportunities]);
  return (
    <StyledStatusWrapper>
      <StyledItemWrapper>
        <StyledTitleWrapper onClick={handleOnClick}>
          <StyledTitle>
            Pompy Ciepła
            {isOpen ? (
              <FontAwesomeWrapper>
                <StyledFontAwesomeIcon icon={faChevronDown} />
              </FontAwesomeWrapper>
            ) : (
              <FontAwesomeWrapper>
                <StyledFontAwesomeIcon icon={faChevronUp} />
              </FontAwesomeWrapper>
            )}
          </StyledTitle>
        </StyledTitleWrapper>
        <StyledContentWrapper ref={content} style={{ maxHeight: `${height}` }}>
          <HeatPumpContractInfo
            opportunities={opportunities}
            fetchData={fetchData}
          />
          <HeatPumpMakeOffer
            opportunities={opportunities}
            fetchData={fetchData}
          />
          <ShowContract opportunities={opportunities} fetchData={fetchData} />
        </StyledContentWrapper>
      </StyledItemWrapper>
    </StyledStatusWrapper>
  );
};

const FontAwesomeWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #134771;
`;

const StyledContentWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  cursor: pointer;
`;

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledStatusWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
`;

export default HeatPumpSaleProcess;
