import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import * as yup from "yup";
import request from "../../../../../../../../../helpers/request";

const validationSchema = yup.object({
  first_name: yup
    .string()
    .required("Imię jest wymagana")
    .min(3, "Imię musi posiadać co najmniej 3 znaków")
    .max(255, "Imię nie może być dłuższa niż 255 znaków"),
  last_name: yup
    .string()
    .required("Nazwisko jest wymagana")
    .min(3, "Nazwisko musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwisko nie może być dłuższa niż 255 znaków"),

  city: yup.string().nullable(),
  post_code: yup.string().nullable(),
  street: yup.string().nullable(),
  building_number: yup.string().nullable(),
});

const ClientForm = ({
  client,
  fetchData,
  showClientInfo,
  setShowClientInfo,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },

    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (client) {
      setValue("first_name", client.first_name);
      setValue("last_name", client.last_name);
      setValue("street", client.street);
      setValue("building_number", client.building_number);
      setValue("post_code", client.postCode);
      setValue("post", client.post);
      setValue("city", client.city);
    }
  }, [
    client?.first_name,
    client?.last_name,
    client?.postCode,
    client?.street,
    client?.building_number,
    client?.city,
    client,
  ]);

  const handleUpdateClient = async (data) => {
    try {
      if (client?.id) {
        const { status } = await request.patch(
          `/api/clients/update-offer/${client.id}`,
          {
            name: data.name,
            street: data.street,
            building_number: data.building_number,
            postCode: data.post_code,
            post: data.post,
            city: data.city,
          }
        );
        if (status === 201) {
          fetchData();
          setShowClientInfo(false);
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowClientInfo(false);
  };

  return (
    <StyledClientInformationWrapper
      style={showClientInfo ? { display: "flex" } : { display: "none" }}
    >
      <StyledClientTitleWrapper>Dane Klienta:</StyledClientTitleWrapper>
      <StyledInput>
        <div className="input-group">
          <label>Imię:</label>
          <input type="text" {...register("first_name")} />
        </div>

        {errors?.first_name && (
          <span className="error">{errors.first_name.message}</span>
        )}
      </StyledInput>

      <StyledInput>
        <div className="input-group">
          <label>Nazwisko:</label>
          <input type="text" {...register("last_name")} />
        </div>

        {errors?.last_name && (
          <span className="error">{errors.last_name.message}</span>
        )}
      </StyledInput>

      <StyledInput>
        <div className="input-group">
          <label>Ulica</label>
          <input type="text" {...register("street")} />
        </div>
        {errors?.street && (
          <span className="error">{errors.street.message}</span>
        )}
      </StyledInput>

      <StyledInput>
        <div className="input-group">
          <label>Numer budynku</label>
          <input type="text" {...register("building_number")} />
        </div>
        {errors?.building_number && (
          <span className="error">{errors.building_number.message}</span>
        )}
      </StyledInput>
      <StyledInput>
        <div className="input-group">
          <label>Miejscowość</label>
          <input type="text" {...register("city")} />
        </div>
        {errors?.city && <span className="error">{errors.city.message}</span>}
      </StyledInput>
      <StyledInput>
        <div className="input-group">
          <label>Kod pocztowy:</label>
          <input type="text" {...register("post_code")} />
        </div>
        {errors?.post_code && (
          <span className="error">{errors.post_code.message}</span>
        )}
      </StyledInput>
      <StyledInput>
        <div className="input-group">
          <label>Poczta:</label>
          <input type="text" {...register("post")} />
        </div>
        {errors?.post && <span className="error">{errors.post.message}</span>}
      </StyledInput>

      <StyledUpdateButtonWrapper>
        <StyledUpdateButton onClick={handleSubmit(handleUpdateClient)}>
          Zapisz
        </StyledUpdateButton>
        <StyledCancelButton onClick={handleOnClose}>Anuluj</StyledCancelButton>
      </StyledUpdateButtonWrapper>
    </StyledClientInformationWrapper>
  );
};

const StyledCancelButton = styled.button`
  width: 100%;
  background: #134771;
  color: #cadeee;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

const StyledUpdateButton = styled.button`
  width: 100%;
  background: #134771;
  color: #cadeee;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledUpdateButtonWrapper = styled.div`
  margin-top: auto;
  padding: 10px;
  display: flex;
  gap: 10px;
`;

const StyledClientTitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #134771;
  padding: 10px;
`;
const StyledClientInformationWrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export default ClientForm;
