import React from "react";
import styled from "styled-components";
import ClientCompany from "./Components/ClientCompany";
import ClientOpportunity from "./Components/ClientOpportunity/ClientOpportunity";

const ClientSideBar = ({ client, fetchData }) => {
  return (
    <StyledClientlist>
      {client?.company && <ClientCompany company={client.company} />}
      {client?.opportunities && (
        <ClientOpportunity client={client} fetchClient={fetchData} />
      )}
    </StyledClientlist>
  );
};

const StyledClientlist = styled.div`
  grid-area: sidebar;
`;

export default ClientSideBar;
