import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddClientHPOpportunities from "../../../AddClientOpportunities/AddClientHPOpportunities";
import AddClientPVOpportunities from "../../../AddClientOpportunities/AddClientPVOpportunities";
import ClientChooseOpportunity from "./ClientChooseOpportunity";
import ClientHPOpportunityAlert from "./ClientHPOpportunityAlert";
import ClientPVOpportunityAlert from "./ClientPVOpportunityAlert";
import ClientOpportunityItem from "./ClientOpportunityItem";
import ClientChooseOfferOpportunity from "./ClientChooseOfferOpportunity";
import OfferAlertHP from "./MakeOffer/OfferAlertHP";
import OfferAlertPV from "./MakeOffer/OfferAlertPV";
import ChooseOfferType from "./MakeOffer/ChooseOfferType";
import MakeIndividualOffer from "./MakeOffer/MakeIndividualOffer";
import MakeIndividualFarmerOffer from "./MakeOffer/MakeIndividualFarmerOffer";
import MakeHPOffer from "./MakeOffer/MakeHPOffer";

const ClientOpportunity = ({ client, fetchClient }) => {
  const { user } = useContext(StoreContext);
  const [isAlertHPOpen, setIsAlertHPOpen] = useState(false);
  const [isAlertPVOpen, setIsAlertPVOpen] = useState(false);
  const [isOfferAlertHPOpen, setIsOfferAlertHPOpen] = useState(false);
  const [isOfferAlertPVOpen, setIsOfferAlertPVOpen] = useState(false);
  const [isChooseModalOpen, setIsChooseModalOpen] = useState(false);
  const [isHPModalOpen, setIsHPModalOpen] = useState(false);
  const [isPVModalOpen, setIsPVModalOpen] = useState(false);
  // Choose Offer States
  const [isChooseOfferModalOpen, setIsChooseOfferModalOpen] = useState(false);
  const [isChoosePhotovoltaicOfferOpen, setIsChoosePhotovoltaicOfferOpen] =
    useState(false);
  const [isHeatPumpOfferOpen, setIsHeatPumpOfferOpen] = useState(false);
  const [isIndividualOfferModalOpen, setIsIndividualOfferModalOpen] =
    useState(false);
  const [isFarmerOfferModalOpen, setIsFarmerOfferModalOpen] = useState(false);
  const history = useHistory();

  const handleOnCloseAlertHP = () => {
    setIsAlertHPOpen(false);
  };
  const handleOnCloseAlertPV = () => {
    setIsAlertPVOpen(false);
  };

  const handleClickYesPV = () => {
    setIsAlertPVOpen(false);
    setIsPVModalOpen(true);
  };
  const handleClickYesHP = () => {
    setIsAlertHPOpen(false);
    setIsHPModalOpen(true);
  };

  const handleOnRedirectHP = () => {
    setIsAlertHPOpen(false);
    setIsOfferAlertHPOpen(false);
    const heatPumpOpportunities = client?.opportunities.filter(
      (opportunity) => opportunity.is_heat_pump === 1
    );
    if (heatPumpOpportunities.length > 0) {
      history.push(
        `/dashboard/opportunities/details/${
          heatPumpOpportunities[heatPumpOpportunities.length - 1].id
        }`
      );
    }
  };
  const handleOnRedirectPV = () => {
    const photovoltaicOpportunities = client?.opportunities.filter(
      (opportunity) => opportunity.is_photovoltaic === 1
    );

    setIsAlertPVOpen(false);
    setIsOfferAlertPVOpen(false);
    if (photovoltaicOpportunities.length > 0) {
      history.push(
        `/dashboard/opportunities/details/${
          photovoltaicOpportunities[photovoltaicOpportunities.length - 1].id
        }`
      );
    }
  };

  const handleChooseOpportunityType = () => {
    setIsChooseModalOpen(true);
  };

  const handleOnCloseChooseModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsChooseModalOpen(false);
  };

  const handleOnChooseHP = () => {
    if (user?.offer_form) {
      const heatPumpOpportunities = client?.opportunities.filter(
        (opportunity) => opportunity.is_heat_pump === 1
      );

      if (heatPumpOpportunities.length > 0) {
        setIsChooseModalOpen(false);
        setIsAlertHPOpen(true);
      } else {
        setIsChooseModalOpen(false);
        setIsHPModalOpen(true);
      }
    } else if (user.offer_form?.instalation.length === 0) {
      alert("Brak instalacji w wybranej formie ofertowania");
    } else {
      alert("Brak uprawnień do generowania oferty");
    }
  };
  const handleOnChoosePV = () => {
    if (user?.offer_form) {
      const photovoltaicOpportunities = client?.opportunities.filter(
        (opportunity) => opportunity.is_photovoltaic === 1
      );
      if (photovoltaicOpportunities.length > 0) {
        setIsChooseModalOpen(false);
        setIsAlertPVOpen(true);
      } else {
        setIsChooseModalOpen(false);
        setIsPVModalOpen(true);
      }
    } else if (user.offer_form?.instalation.length === 0) {
      alert("Brak instalacji w wybranej formie ofertowania");
    } else {
      alert("Brak uprawnień do generowania oferty");
    }
  };

  const handleOnCloseHPModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsHPModalOpen(false);
  };

  const handleOnClosePVModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsPVModalOpen(false);
  };

  // Make Sale Opportunity with  offer

  const handleMakeOffer = () => {
    setIsChooseOfferModalOpen(true);
  };
  const handleCloseChooseOfferModalOpen = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsChooseOfferModalOpen(false);
  };

  const handleOnChooseHPOffer = () => {
    if (user?.offer_form) {
      const heatPumpOpportunities = client?.opportunities.filter(
        (opportunity) => opportunity.is_heat_pump === 1
      );

      if (heatPumpOpportunities.length > 0) {
        setIsChooseOfferModalOpen(false);
        setIsOfferAlertHPOpen(true);
      } else {
        setIsChooseOfferModalOpen(false);
        setIsHeatPumpOfferOpen(true);
      }
    } else if (user.offer_form?.instalation.length === 0) {
      alert("Brak instalacji w wybranej formie ofertowania");
    } else {
      alert("Brak uprawnień do generowania oferty");
    }
  };

  const handleOnChoosePVOffer = () => {
    if (user?.offer_form) {
      const photovoltaicOpportunities = client?.opportunities.filter(
        (opportunity) => opportunity.is_photovoltaic === 1
      );
      if (photovoltaicOpportunities.length > 0) {
        setIsChooseOfferModalOpen(false);
        setIsOfferAlertPVOpen(true);
      } else {
        setIsChooseOfferModalOpen(false);
        setIsChoosePhotovoltaicOfferOpen(true);
      }
    } else if (user.offer_form?.instalation.length === 0) {
      alert("Brak instalacji w wybranej formie ofertowania");
    } else {
      alert("Brak uprawnień do generowania oferty");
    }
  };

  const handleOnNextHP = () => {
    setIsOfferAlertHPOpen(false);
    setIsHeatPumpOfferOpen(true);
  };
  const handleOnNextPV = () => {
    setIsOfferAlertPVOpen(false);
    setIsChoosePhotovoltaicOfferOpen(true);
  };

  const handleOnCloseHPOfferAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOfferAlertHPOpen(false);
  };
  const handleOnClosePVOfferAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOfferAlertPVOpen(false);
  };

  const handleOnClosePVOffer = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsChoosePhotovoltaicOfferOpen(false);
  };

  const handleIndividualMakeOffer = () => {
    setIsChoosePhotovoltaicOfferOpen(false);
    setIsIndividualOfferModalOpen(true);
  };

  const handleFarmerMakeOffer = () => {
    setIsChoosePhotovoltaicOfferOpen(false);
    setIsFarmerOfferModalOpen(true);
  };

  const handleCloseIndividualOfferModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsIndividualOfferModalOpen(false);
  };

  const handleCloseFarmerOfferModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsFarmerOfferModalOpen(false);
  };

  const handleOnCloseHeatPumpOffer = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsHeatPumpOfferOpen(false);
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>Powiązane szanse sprzedaży</StyledTitleWrapper>

      <StyledOpportunitiesWrapper>
        {client?.opportunities?.length > 0 ? (
          client?.opportunities.map((opportunity) => (
            <ClientOpportunityItem
              opportunity={opportunity}
              key={opportunity.id}
            />
          ))
        ) : (
          <p>Brak powiązanych szans sprzedaży</p>
        )}
      </StyledOpportunitiesWrapper>

      <StyledButtonWrapper>
        <StyledButton onClick={handleChooseOpportunityType}>
          Stwórz szansę sprzedaży
        </StyledButton>
        <StyledButton onClick={handleMakeOffer}>
          Stwórz ofertę i szansę
        </StyledButton>
      </StyledButtonWrapper>

      <ClientHPOpportunityAlert
        isAlertOpen={isAlertHPOpen}
        handleOnClose={handleOnCloseAlertHP}
        handleClickYes={handleClickYesHP}
        handleOnRedirect={handleOnRedirectHP}
      />
      <ClientPVOpportunityAlert
        isAlertOpen={isAlertPVOpen}
        handleOnClose={handleOnCloseAlertPV}
        handleClickYes={handleClickYesPV}
        handleOnRedirect={handleOnRedirectPV}
      />
      <AddClientHPOpportunities
        isModalOpen={isHPModalOpen}
        handleOnClose={handleOnCloseHPModal}
        fetchData={fetchClient}
        client={client}
      />
      <AddClientPVOpportunities
        isModalOpen={isPVModalOpen}
        handleOnClose={handleOnClosePVModal}
        fetchData={fetchClient}
        client={client}
      />

      <ChooseOfferType
        isChooseModalOpen={isChoosePhotovoltaicOfferOpen}
        handleOnClose={handleOnClosePVOffer}
        handleChooseIndividual={handleIndividualMakeOffer}
        handleChooseFarmer={handleFarmerMakeOffer}
      />

      <OfferAlertHP
        isAlertOpen={isOfferAlertHPOpen}
        handleOnClose={handleOnCloseHPOfferAlert}
        handleClickNext={handleOnNextHP}
        handleOnRedirect={handleOnRedirectHP}
      />

      <OfferAlertPV
        isAlertOpen={isOfferAlertPVOpen}
        handleOnClose={handleOnClosePVOfferAlert}
        handleClickNext={handleOnNextPV}
        handleOnRedirect={handleOnRedirectPV}
      />

      <MakeHPOffer
        isModalOpen={isHeatPumpOfferOpen}
        handleOnClose={handleOnCloseHeatPumpOffer}
        fetchData={fetchClient}
        client={client}
      />

      <MakeIndividualOffer
        isModalOpen={isIndividualOfferModalOpen}
        handleOnClose={handleCloseIndividualOfferModal}
        fetchData={fetchClient}
        client={client}
      />
      <MakeIndividualFarmerOffer
        isModalOpen={isFarmerOfferModalOpen}
        handleOnClose={handleCloseFarmerOfferModal}
        fetchData={fetchClient}
        client={client}
      />

      <ClientChooseOfferOpportunity
        isChooseModalOpen={isChooseOfferModalOpen}
        handleOnClose={handleCloseChooseOfferModalOpen}
        handleOnChooseHP={handleOnChooseHPOffer}
        handleOnChoosePV={handleOnChoosePVOffer}
      />

      <ClientChooseOpportunity
        isChooseModalOpen={isChooseModalOpen}
        handleOnClose={handleOnCloseChooseModal}
        handleOnChooseHP={handleOnChooseHP}
        handleOnChoosePV={handleOnChoosePV}
      />
    </StyledWrapper>
  );
};

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;
const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
`;
const StyledOpportunitiesWrapper = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.4);
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid rgba(160, 160, 160, 0.4);
`;

const StyledWrapper = styled.div`
  /* margin-top: 10px; */
  background: white;
  border-radius: 10px;
`;

export default ClientOpportunity;
