import React from "react";
import styled from "styled-components";
import UserSelectPopOver from "./components/UserSelectPopOver";

const ChooseUserFilter = ({
  departments,
  teams,
  name,
  setName,
  setUserId,
  isPopOverOpen,
  setIsPopOverOpen,
  handleOnChooseUser,
}) => {
  return (
    <StyledWrapper>
      <UserSelectPopOver
        departments={departments}
        teams={teams}
        isPopOverOpen={isPopOverOpen}
        setIsPopOverOpen={setIsPopOverOpen}
        handleOnChooseUser={handleOnChooseUser}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  margin-right: 10px;
  position: relative;
`;

export default ChooseUserFilter;
