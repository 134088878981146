import React, { useRef, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

const DayComponent = ({ day, bus, handleOnDragEnter, setWidth }) => {
  const divWidthRef = useRef(null);

  const setLocalWidth = () => {
    setWidth(divWidthRef.current.offsetWidth);
  };
  useEffect(() => {
    setWidth(divWidthRef.current.offsetWidth);

    window.addEventListener("resize", setLocalWidth);
    return () => {
      window.removeEventListener("resize", setLocalWidth);
    };
  }, []);
  return (
    <StyledDayWrapper
      ref={divWidthRef}
      onDragEnter={() => handleOnDragEnter(day, bus)}
      data-bus={bus.id}
      data-day={day.toLocaleString()}
    ></StyledDayWrapper>
  );
};

const StyledDayWrapper = styled.div`
  position: relative;
  width: calc(100% / 8);
  border: 1px solid lightgray;
  border-radius: 5px;
  min-height: 60px;
  &:hover {
    background: lightgray;
  }
`;

export default DayComponent;
