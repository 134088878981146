import {
  faBullseye,
  faCheck,
  faMapMarkerAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Modal from "../../../../../../components/Modal";
import PrioritySelect from "../PrioritySelect/PrioritySelect";
import NoteList from "./Components/NoteList/NoteList";

const ShowAssemblyFaultModal = ({
  isShowModalOpen,
  handleOnClose,
  assemblyFault,
  fetchData,
  changeStatus,
  changePriority,
}) => {
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (assemblyFault) {
      if (
        assemblyFault.city &&
        assemblyFault.building_number &&
        assemblyFault.post_code &&
        assemblyFault.post &&
        assemblyFault.street
      ) {
        setAddress(
          `ul. ${assemblyFault?.street} ${assemblyFault?.building_number}, ${assemblyFault?.city} ${assemblyFault?.post_code} ${assemblyFault?.post}`
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.building_number &&
        assemblyFault?.post &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `${assemblyFault?.city} ${assemblyFault?.building_number}, ${assemblyFault?.post_code} ${assemblyFault?.post} `
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.street &&
        assemblyFault?.post &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `ul. ${assemblyFault?.street} ${assemblyFault?.city}, ${assemblyFault?.post_code} ${assemblyFault?.post}`
        );
      } else if (
        assemblyFault.city &&
        assemblyFault.building_number &&
        assemblyFault.post_code &&
        assemblyFault.street
      ) {
        setAddress(
          `ul. ${assemblyFault?.street} ${assemblyFault?.building_number}, ${assemblyFault?.post_code} ${assemblyFault?.city}`
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.building_number &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `${assemblyFault?.building_number}, ${assemblyFault?.post_code} ${assemblyFault?.city} `
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.street &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `ul. ${assemblyFault?.street}, ${assemblyFault?.post_code} ${assemblyFault?.city}`
        );
      } else {
        setAddress(
          `${assemblyFault?.street ? assemblyFault?.street : ""} ${
            assemblyFault?.building_number ? assemblyFault?.building_number : ""
          }, ${assemblyFault?.city ? assemblyFault?.city : ""} ${
            assemblyFault?.post_code ? assemblyFault?.post_code : ""
          } ${assemblyFault?.post ? assemblyFault?.post : ""}`
        );
      }
    }
  }, [assemblyFault]);

  return (
    <Modal
      isModalOpen={isShowModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <ShowModalContent>
        <StyledStatusWrapper>
          <StatusTitle>Status:</StatusTitle>
          {assemblyFault.status === 0 && (
            <StyledStatus>
              <StyledSpan>
                <StyledSpanIcon icon={faTimes} />
              </StyledSpan>
              <StatusSpanTitle>Niewykonano</StatusSpanTitle>
            </StyledStatus>
          )}
          {assemblyFault.status === 1 && (
            <StyledStatus>
              <StyledSpan inProgress>
                <StyledSpanIcon icon={faBullseye} />
              </StyledSpan>
              <StatusSpanTitle>W trakcie</StatusSpanTitle>
            </StyledStatus>
          )}
          {assemblyFault.status === 2 && (
            <StyledStatus>
              <StyledSpan done>
                <StyledSpanIcon icon={faCheck} />
              </StyledSpan>
              <StatusSpanTitle>Wykonane</StatusSpanTitle>
            </StyledStatus>
          )}

          <StyledButtonChange onClick={changeStatus}>
            Zmień status
          </StyledButtonChange>
        </StyledStatusWrapper>
        <PriorityWrapper>
          <PrioritySelect
            priority={assemblyFault.priority}
            changePriority={changePriority}
          />
        </PriorityWrapper>

        <ContentWrapper>
          <FaultWrapper>
            <FaultTitle>Opis usterki:</FaultTitle>
            <FaultContent>{assemblyFault.fault}</FaultContent>
          </FaultWrapper>
          {assemblyFault.opportunity && (
            <OpportunityWrapper>
              <OpportunityLabel>Powiązana szansa sprzedaży:</OpportunityLabel>
              <OpportunityLink
                to={`/dashboard/opportunities/details/${assemblyFault.opportunity.id}`}
              >
                {assemblyFault.opportunity.name}
              </OpportunityLink>
            </OpportunityWrapper>
          )}
          <ClientWrapper>
            {" "}
            <FontAwesomeIcon icon={faUser} /> {assemblyFault.client_name}
          </ClientWrapper>
          {address && (
            <AddressWrapper>
              {" "}
              <FontAwesomeIcon icon={faMapMarkerAlt} /> {address}
            </AddressWrapper>
          )}
          {assemblyFault.filing_date && (
            <AddressWrapper>
              Usterka zgłoszona:{" "}
              {moment(assemblyFault.filing_date).format("DD MMM YY")}
            </AddressWrapper>
          )}
          {assemblyFault.reporting_person && (
            <AddressWrapper>
              Osoba zgłaszająca: {assemblyFault.reporting_person}
            </AddressWrapper>
          )}

          {assemblyFault.maintainer && (
            <AddressWrapper>
              Osoba odpowiedzialna: {assemblyFault.maintainer.name}
            </AddressWrapper>
          )}
          {assemblyFault.bus && (
            <AddressWrapper>
              Przypisany bus: {assemblyFault.bus.name}
            </AddressWrapper>
          )}

          <NoteWrapper>
            <NoteList note={assemblyFault.notes} fetchData={fetchData} />
          </NoteWrapper>
        </ContentWrapper>
      </ShowModalContent>
    </Modal>
  );
};

const OpportunityLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const OpportunityLabel = styled.label`
  font-size: 14px;
`;

const OpportunityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin: 5px 0;
`;

const PriorityWrapper = styled.div`
  padding: 10px 0;
`;

const StyledButtonChange = styled.button`
  color: #cadeee;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0px 5px;
  background: #134771;
  cursor: pointer;
`;

const StatusSpanTitle = styled.p`
  color: #134771;
`;

const StyledStatus = styled.div`
  display: flex;
`;

const NoteWrapper = styled.div``;

const StatusTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #134771;
`;

const FaultWrapper = styled.div`
  padding-right: 40px;
  margin-bottom: 10px;
`;
const FaultTitle = styled.h4`
  font-size: 14px;
  color: #134771;
`;
const FaultContent = styled.p``;

const ClientWrapper = styled.p`
  font-size: 16px;
  color: #134771;
`;
const AddressWrapper = styled.p`
  font-size: 16px;
  color: #134771;
`;

const ContentWrapper = styled.div``;

const StyledSpanIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const StyledSpan = styled.span`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.done
      ? "rgb(102, 188, 70)"
      : props.inProgress
      ? "rgb(255, 238, 127)"
      : "rgb(244,118,49)"};

  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 5px;
`;

const StyledStatusWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ShowModalContent = styled.div`
  min-width: 340px;
  max-width: 360px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
  @media screen and (max-width: 768px) {
    top: 0;
    right: 0;
  }
`;

export default ShowAssemblyFaultModal;
