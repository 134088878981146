import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../components/Modal";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Nazwa jest wymagana")
    .min(3, "Nazwa musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwa nie może być dłuższa niż 255 znaków"),
  voivodeship_id: yup.string().required("Proszę wybrać województwo"),
});

const AddDepartmentSetting = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const { voivodeships } = useContext(StoreContext);
  const [isSended, setIsSended] = useState(false);

  const { handleSubmit, register, errors, reset, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: { name: "" },
  });

  const resetInputValue = () => {
    reset({
      name: "",
      voivodeship_id: "",
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    if (isEditMode) {
      await request.patch(`/api/department/${editObject.id}`, { ...data });
    } else {
      await request.post("/api/department", { data });
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
    fetchData();
    resetInputValue();
    handleOnClose();
  };

  useEffect(() => {
    if (editObject) {
      setValue("name", editObject.name);
      setValue("voivodeship_id", editObject.voivodeship?.id);
    } else {
      setValue("name", "");
      setValue("voivodeship_id", "");
    }
  }, [editObject]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="form-header">
          <button
            onClick={handleOnClose}
            className="btn-form-times"
            type="button"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <StyledInputWrapper>
          <div className="input-group">
            <label htmlFor="NameInput">Nazwa oddziału</label>

            <input {...register("name")} type="text" />
          </div>
        </StyledInputWrapper>
        {errors?.name && <span className="error">{errors.name.message}</span>}

        <StyledInputWrapper>
          <div className="input-group">
            <label>Województwo</label>
            <select {...register("voivodeship_id")}>
              <option value="">Wybierz...</option>
              {voivodeships &&
                voivodeships.map((voivodeship) => (
                  <option value={voivodeship.id} key={voivodeship.id}>
                    {voivodeship.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInputWrapper>
        {errors?.voivodeship_id && (
          <span className="error">{errors.voivodeship_id.message}</span>
        )}

        {isEditMode ? (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Zapisz
            </button>
          </div>
        ) : (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Dodaj
            </button>
          </div>
        )}
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  max-width: 360px;
  .form-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .btn-form-times {
    border: none;
    outline: none;
    background: transparent;
    padding: 1rem;
    cursor: pointer;
  }
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #2d96db;
        background: transparent;
      }
      &.close:hover {
        color: black;
      }
      &.add {
        background: #134771;
        color: white;
      }
      &.add:hover {
        background: #005e9c;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInputWrapper = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddDepartmentSetting;
