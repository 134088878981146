import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import ImageModal from "../ImageModal";
import request from "../../helpers/request";
import loading from "../../img/48x48.gif";
import ReactToPrint from "react-to-print";

const ShowImageModal = ({
  isModalOpen,
  handleOnClose,
  imageIndex,
  setImageIndex,
  imageObject,
  allImageCount,
}) => {
  const [imagePath, setImagePath] = useState(null);
  const [imageDownloadLink, setImageDownloadLink] = useState(null);
  const [imageRedirectLink, setImageRedirectLink] = useState(null);
  const [isSended, setIsSended] = useState(false);

  let componentRef = useRef();

  const history = useHistory();
  const location = useLocation();

  const handleOnchangeImage = (forward) => {
    if (forward) {
      if (imageIndex + 1 === allImageCount) {
        setImageIndex(0);
      } else {
        setImageIndex((prev) => prev + 1);
      }
    } else {
      if (imageIndex === 0) {
        setImageIndex(allImageCount - 1);
      } else {
        setImageIndex((prev) => prev - 1);
      }
    }
  };

  const handleOnDownload = async (id, name) => {
    setIsSended(true);
    try {
      const { status, data } = await request.get(`api/file/download/${id}`, {
        responseType: "blob",
      });
      if (status === 200) {
        const href = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", name); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      if (imageObject.length > 0 && imageIndex >= 0) {
        setImagePath(
          <StyledImage
            ref={(el) => (componentRef.current = el)}
            src={`${imageObject[imageIndex].path_name}`}
          />
        );
        setImageDownloadLink(
          <StyledDownloadPictureButton
            onClick={() =>
              handleOnDownload(
                imageObject[imageIndex].id,
                imageObject[imageIndex].name
              )
            }
          >
            Pobierz zdjęcie
          </StyledDownloadPictureButton>
        );
        setImageRedirectLink(
          <StyledRedirectLink
            href={`${imageObject[imageIndex].path_name}`}
            target="_blank"
            rel="noreferrer"
          >
            Otwórz w nowym oknie
          </StyledRedirectLink>
        );
      } else {
        setImagePath(null);
        setImageDownloadLink(null);
        setImageRedirectLink(null);
        // handleClose();
      }
    }
  }, [imageIndex, imageObject, isModalOpen]);

  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 39) {
        if (imageIndex + 1 === allImageCount) {
          setImageIndex(0);
        } else {
          setImageIndex((prev) => prev + 1);
        }
      } else if (e.keyCode === 37) {
        if (Number(imageIndex) === 0) {
          setImageIndex(allImageCount - 1);
        } else {
          setImageIndex((prev) => prev - 1);
        }
      } else if (e.keyCode === 27) {
        handleClose();
      }
    },
    [imageIndex]
  );

  const handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    handleOnClose();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleOnKeyDown);
    return () => {
      document.removeEventListener("keydown", handleOnKeyDown);
    };
  }, [handleOnKeyDown]);

  return (
    <ImageModal isModalOpen={isModalOpen}>
      <StyledCloseButton onClick={handleClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCloseButton>
      <StyledModalImageWrapper>
        <StyledBackButton onClick={() => handleOnchangeImage(false)}>
          <div>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </StyledBackButton>
        {imagePath}
        <StyledForwardButton onClick={() => handleOnchangeImage(true)}>
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </StyledForwardButton>
      </StyledModalImageWrapper>
      <StyledImageInfoWrapper>
        <StyledImageCount>
          Zdjęcie {imageIndex + 1} z {allImageCount}
        </StyledImageCount>
        <StyledDownloadPicture>{imageDownloadLink}</StyledDownloadPicture>
        <StyledShowBiggerPicture>{imageRedirectLink}</StyledShowBiggerPicture>
        <ReactToPrint
          trigger={() => (
            <StyledDownloadPictureButton>Wydrukuj</StyledDownloadPictureButton>
          )}
          content={() => componentRef.current}
        />
      </StyledImageInfoWrapper>
      {isSended && (
        <StyledSendWrapper>
          <StyledSendNoteInnerWrapper>
            <StyledSendImg src={loading} alt="loading..." />
          </StyledSendNoteInnerWrapper>
        </StyledSendWrapper>
      )}
    </ImageModal>
  );
};

const StyledSendImg = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;
const StyledSendWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDownloadPictureButton = styled.button`
  font-size: 16px;
  color: #134771;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-left: 5 px;
  display: block;
  white-space: nowrap;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  min-width: 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const StyledRedirectLink = styled.a`
  font-size: 16px;
  color: #134771;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-left: 5 px;
  display: block;
  white-space: nowrap;
  min-width: 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const StyledDownloadPicture = styled.div``;
const StyledShowBiggerPicture = styled.div``;
const StyledImageCount = styled.div``;
const StyledImageInfoWrapper = styled.div`
  padding: 5px;
  display: flex;
  gap: 20px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: #134771;
  font-size: 18px;
  &:hover {
    color: #053257;
  }
`;

const StyledForwardButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  font-size: 20px;
  display: flex;
  color: #134771;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  & > div {
    width: 40px;
    height: 40px;
    padding-left: 3px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover > div {
    background: #cadeee70;
  }
`;

const StyledBackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  color: #134771;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  & > div {
    width: 40px;
    height: 40px;
    padding-right: 3px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover > div {
    background: #cadeee70;
  }
`;

const StyledModalImageWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledImage = styled.img`
  display: block;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;

  object-fit: contain;
`;

export default ShowImageModal;
