import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import DeleteAlert from "./DeleteAlert";

const SourceItem = ({
  source,
  fetchSources,
  handleOpenEditSource,
  setEditObject,
}) => {
  const { id, name } = source;

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOnEdit = () => {
    setEditObject(source);
    handleOpenEditSource();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/source/${id}`);
    if (status === 200) {
      fetchSources();
      setIsAlertOpen(false);
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledSourceItem>
      <StyledDescription>
        <p>{name}</p>
      </StyledDescription>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnDelete={handleOnDelete}
        handleOnClose={handleOnCloseAlert}
      />
    </StyledSourceItem>
  );
};

const StyledSourceItem = styled.tr``;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.td``;

const StyledButtonGroup = styled.td`
  text-align: right;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
export default SourceItem;
