import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../../../../components/Modal";

import request from "../../../../../../../../../../helpers/request";

const validationSchema = yup.object({});
const EditPropertyDetails = ({
  propertyDetails,
  isModalOpen,
  handleOnClose,
  fetchData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({});
  };
  const sendData = async (data) => {
    try {
      const { status } = await request.patch(
        `api/property-details/${propertyDetails.id}`,
        {
          ...data,
        }
      );
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (propertyDetails) {
      setValue("street", propertyDetails.street);
      setValue("building_number", propertyDetails.building_number);
      setValue("post_code", propertyDetails.post_code);
      setValue("post", propertyDetails.post);
      setValue("city", propertyDetails.city);
      setValue("community", propertyDetails.community);
      setValue("district", propertyDetails.district);
      setValue("property_status", propertyDetails.property_status);
      setValue(
        "plot_registration_number",
        propertyDetails.plot_registration_number
      );
    }
  }, [propertyDetails]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>

        <StyledTitleWrapper>Dane nieruchomości</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input type="text" {...register("street")} />
          </div>
        </StyledInput>
        {errors?.street && (
          <span className="error">{errors.street.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input type="text" {...register("building_number")} />
          </div>
        </StyledInput>
        {errors?.building_number && (
          <span className="error">{errors.building_number.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input type="text" {...register("post_code")} />
          </div>
        </StyledInput>
        {errors?.post_code && (
          <span className="error">{errors.post_code.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input type="text" {...register("post")} />
          </div>
        </StyledInput>
        {errors?.post && <span className="error">{errors.post.message}</span>}
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input type="text" {...register("city")} />
          </div>
        </StyledInput>
        {errors?.city && <span className="error">{errors.city.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Gmina</label>
            <input type="text" {...register("community")} />
          </div>
        </StyledInput>
        {errors?.community && (
          <span className="error">{errors.community.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Powiat</label>
            <input type="text" {...register("district")} />
          </div>
        </StyledInput>
        {errors?.district && (
          <span className="error">{errors.district.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Status nieruchomości</label>
            <select {...register("property_status")}>
              <option value={1}>Własność</option>
              <option value={2}>Współwłasność</option>
              <option value={3}>Inne</option>
            </select>
          </div>
        </StyledInput>
        {errors?.property_status && (
          <span className="error">{errors.property_status.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Numer ewidencyjny działki</label>
            <input {...register("plot_registration_number")} type="text" />
          </div>
        </StyledInput>
        {errors?.plot_registration_number && (
          <span className="error">
            {errors.plot_registration_number.message}
          </span>
        )}
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Zapisz
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 320px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default EditPropertyDetails;
