import {
  faCheck,
  faEllipsisH,
  faMinus,
  faSolarPanel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../helpers/request";
import DeleteAlert from "./DeleteAlert";
import DropDownMenu from "./DropDownMenu/DropDownMenu";
import { ReactComponent as HeatPump } from "../../../../img/heat_pump.svg";
import { useContext } from "react";
import { StoreContext } from "../../../../store/StoreProvider";

const OpportunitiesItem = ({
  opportunity,
  fetchData,
  handleOnClickEdit,
  opportunityStatuses,
  setPageNumber,
}) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [showCount, setShowCount] = useState("0");
  const [spanObject, setSpanObject] = useState([]);
  const [paymentContext, setPaymentContext] = useState(null);
  const [isUncomplete, setIsUncomplete] = useState(false);

  const { user } = useContext(StoreContext);
  useEffect(() => {
    if (opportunity.price) {
      setShowCount(
        Number(opportunity.price)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setShowCount("");
    }
  }, [opportunity]);

  const handleDropDownOptions = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOpenOptions(!isOpenOptions);
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(
      `/api/opportunities/${opportunity.id}`
    );
    if (status === 201) {
      setPageNumber(1);
      fetchData();
      setIsAlertOpen(false);
    }
  };

  useEffect(() => {
    opportunityStatuses.forEach((statusItem) => {
      if (statusItem.id === opportunity.status) {
        setSpanObject(statusItem);
      }
    });
  });

  const handleOnEdit = () => {
    handleOnClickEdit(opportunity);
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  useEffect(() => {
    if (
      opportunity.payment_monitoring_first_tranche === 1 &&
      opportunity.payment_monitoring_second_tranche === 0 &&
      opportunity.payment_monitoring_third_tranche === 0 &&
      opportunity.full_payment === 0
    ) {
      setPaymentContext(
        <StyledSaleFunellItem>Wpłata: 1 transza</StyledSaleFunellItem>
      );
    } else if (
      opportunity.payment_monitoring_first_tranche === 1 &&
      opportunity.payment_monitoring_second_tranche === 1 &&
      opportunity.payment_monitoring_third_tranche === 0 &&
      opportunity.full_payment === 0
    ) {
      setPaymentContext(
        <StyledSaleFunellItem>Wpłata: 2 transza</StyledSaleFunellItem>
      );
    } else if (
      opportunity.loan_running === 1 &&
      opportunity.full_payment === 0
    ) {
      setPaymentContext(
        <StyledSaleFunellItem>Wpłata: kredyt podpisany</StyledSaleFunellItem>
      );
    } else if (opportunity.full_payment === 1) {
      setPaymentContext(
        <StyledSaleFunellItem>Wpłata: całość</StyledSaleFunellItem>
      );
    } else {
      setPaymentContext(
        <StyledSaleFunellItem>Wpłata: brak</StyledSaleFunellItem>
      );
    }

    if (opportunity.energetic_join_details && opportunity.status === 2) {
      if (
        opportunity.energetic_join_details.metering_protection_value === null ||
        opportunity.energetic_join_details.power_connection_power === null ||
        opportunity.energetic_join_details.contracted_power === null ||
        opportunity.energetic_join_details.energy_consumption_point === null ||
        opportunity.energetic_join_details.counter_number === null
      ) {
        setIsUncomplete(true);
      }
    } else if (
      opportunity.energetic_join_details === null &&
      opportunity.is_photovoltaic === 1 &&
      opportunity.status === 2
    ) {
      setIsUncomplete(true);
    } else {
      setIsUncomplete(false);
    }
  }, []);

  return (
    <StyledWrapper>
      <StyledOpportunityWrapper>
        <StyledStatusWrapper>
          {isUncomplete === true ? (
            <>
              <StyledSpan style={{ background: "#f47631" }}>
                <FontAwesomeIcon style={{ color: "white" }} icon={faCheck} />
              </StyledSpan>
            </>
          ) : (
            <>
              {spanObject?.prefix && spanObject?.icon && (
                <StyledSpan style={{ background: spanObject.color }}>
                  <FontAwesomeIcon
                    style={{ color: spanObject.icon_color }}
                    icon={[spanObject.prefix, spanObject.icon]}
                  />
                </StyledSpan>
              )}
            </>
          )}
        </StyledStatusWrapper>
        <StyledInfoPriceWrapper>
          <StyledInfoWrapper>
            {opportunity.is_photovoltaic === 1 && (
              <StyledPanelIcon icon={faSolarPanel} />
            )}
            {opportunity.is_heat_pump === 1 && <StyledHeatPump />}
            <StyledNameLink
              to={`/dashboard/opportunities/details/${opportunity.id}`}
            >
              {opportunity.name}
            </StyledNameLink>

            {opportunity.company && (
              <StyledOpportunityCompanyWrapper>
                <StyledLabel> Firma:</StyledLabel>

                <StyledOpportunityCompanyLink
                  title={opportunity.company.name}
                  to={`/dashboard/contacts/companies/details/${opportunity.company.id}`}
                >
                  {opportunity.company.name}
                </StyledOpportunityCompanyLink>
              </StyledOpportunityCompanyWrapper>
            )}

            {opportunity.client && (
              <StyledOpportunityClientWrapper>
                <StyledLabel> Klient:</StyledLabel>

                <StyledOpportunityClientLink
                  title={`${opportunity.client.first_name} ${opportunity.client.last_name}`}
                  to={`/dashboard/contacts/clients/details/${opportunity.client.id}`}
                >
                  {opportunity.client.first_name} {opportunity.client.last_name}
                </StyledOpportunityClientLink>
              </StyledOpportunityClientWrapper>
            )}
            {opportunity.maintainer && (
              <StyledOpportunityClientWrapper>
                <StyledLabel> przypisany handlowiec:</StyledLabel>

                <StyledOpportunityMaintainerLink
                  title={`${opportunity.maintainer.name}`}
                >
                  {opportunity.maintainer.name}
                </StyledOpportunityMaintainerLink>
              </StyledOpportunityClientWrapper>
            )}
            {opportunity.energetic_join_details !== null && (
              <ElectricitySupiller>
                <label>Operator sieci dystrybucyjnej:</label>
                {Number(
                  opportunity.energetic_join_details.electricity_supplier
                ) === 1 && <>PGE</>}
                {Number(
                  opportunity.energetic_join_details.electricity_supplier
                ) === 2 && <>Tauron</>}
                {Number(
                  opportunity.energetic_join_details.electricity_supplier
                ) === 3 && <>Enea</>}
                {Number(
                  opportunity.energetic_join_details.electricity_supplier
                ) === 4 && <>Energa</>}
                {Number(
                  opportunity.energetic_join_details.electricity_supplier
                ) === 5 && <>Innogy</>}
                {Number(
                  opportunity.energetic_join_details.electricity_supplier
                ) === 6 && (
                  <>
                    Inne:{" "}
                    {
                      opportunity.energetic_join_details
                        .electricity_supplier_other
                    }
                  </>
                )}
              </ElectricitySupiller>
            )}
          </StyledInfoWrapper>
          <StyledPriceWrapper>
            <StyledPrice>{showCount}</StyledPrice>
            <StyledkWp>
              {opportunity.kWp ? `${opportunity.kWp} kWp` : ""}
            </StyledkWp>
          </StyledPriceWrapper>
        </StyledInfoPriceWrapper>
      </StyledOpportunityWrapper>
      <StyledActionWrapper>
        <StyledActions>
          {opportunity.is_photovoltaic === 1 && (
            <>
              <StyledSaleFunellItem>
                Utworzone oferty : {opportunity.offer}
              </StyledSaleFunellItem>

              <StyledSaleFunellItem>
                Utworzone umowy : {opportunity.contracts}
              </StyledSaleFunellItem>
            </>
          )}

          {opportunity.is_heat_pump === 1 && (
            <>
              <StyledSaleFunellItem>
                Utworzone oferty : {opportunity.heat_pump_offer}
              </StyledSaleFunellItem>
              <StyledSaleFunellItem>
                Utworzone umowy: {opportunity.heat_pump_contracts}
              </StyledSaleFunellItem>
            </>
          )}

          {paymentContext}

          {opportunity.assembly_planning === 1 ? (
            <StyledSaleFunellItem>Montaż: zaplanowany</StyledSaleFunellItem>
          ) : (
            <StyledSaleFunellItem>Montaż: niezaplanowany</StyledSaleFunellItem>
          )}
        </StyledActions>

        <StyledActions>
          {opportunity.product_order &&
          opportunity.product_order.is_order === 1 ? (
            <StyledSaleFunellItem>
              Zamówienie towaru: <StyledYesFontAwesome icon={faCheck} />
            </StyledSaleFunellItem>
          ) : (
            <StyledSaleFunellItem>
              Zamówienie towaru: <StyledNoFontAwesome icon={faMinus} />
            </StyledSaleFunellItem>
          )}
          {opportunity.project !== null ? (
            <StyledSaleFunellItem>Projekt: wykonany</StyledSaleFunellItem>
          ) : (
            <StyledSaleFunellItem>Projekt: niewykonany</StyledSaleFunellItem>
          )}

          {opportunity.registration === 1 ? (
            <StyledSaleFunellItem>
              Zgłoszenie OSD: <StyledYesFontAwesome icon={faCheck} />
            </StyledSaleFunellItem>
          ) : (
            <StyledSaleFunellItem>
              Zgłoszenie OSD: <StyledNoFontAwesome icon={faMinus} />
            </StyledSaleFunellItem>
          )}

          {opportunity.assembly_execution === 1 ? (
            <StyledSaleFunellItem>
              Montaż <StyledYesFontAwesome icon={faCheck} />
            </StyledSaleFunellItem>
          ) : (
            <StyledSaleFunellItem>
              Montaż <StyledNoFontAwesome icon={faMinus} />
            </StyledSaleFunellItem>
          )}
        </StyledActions>
      </StyledActionWrapper>
      <StyledCollapseMenuButtonWrapper>
        <button onClick={handleDropDownOptions} type="button">
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </StyledCollapseMenuButtonWrapper>
      <DropDownMenu
        isOpen={isOpenOptions}
        setIsOpenOptions={setIsOpenOptions}
        handleOnOpenAlert={handleOnOpenAlert}
        handleOnClick={handleOnEdit}
        opportunity={opportunity}
      />
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledWrapper>
  );
};

const ElectricitySupiller = styled.div`
  margin: 2px 0;
  & label {
    margin-right: 5px;
  }
`;
const StyledNoFontAwesome = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: rgb(244, 118, 49);
`;

const StyledYesFontAwesome = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: #66bc46;
`;

const StyledPanelIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledHeatPump = styled(HeatPump)`
  font-size: 14px;
  height: 16px;
  margin-right: 5px;
`;

const StyledActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledInfoPriceWrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-right: 30px;
  }
`;

const StyledSaleFunellItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledOpportunityMaintainerLink = styled.p`
  display: inline-block;
`;

const StyledPrice = styled.p`
  color: #66bc46;
`;
const StyledkWp = styled.p`
  color: rgb(244, 118, 49);
`;
const StyledLabel = styled.label`
  margin-right: 5px;
`;

const StyledOpportunityClientLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledOpportunityClientWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    white-space: normal;
  }
`;
const StyledOpportunityCompanyLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledOpportunityCompanyWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const StyledSpan = styled.span`
  display: block;
  color: black;
  width: 30px;
  height: 30px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPriceWrapper = styled.div`
  flex-basis: 120px;
  height: 100%;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-basis: 0;
    justify-content: flex-start;
    margin-top: 10px;
  }
`;
const StyledStatusWrapper = styled.div`
  flex-basis: 50px;
  @media screen and (max-width: 768px) {
    flex-basis: 0;
  }
`;

const StyledInfoWrapper = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledOpportunityWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  min-width: 0;
`;

const StyledNameLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;

  min-width: 0;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

const StyledActions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledCollapseMenuButtonWrapper = styled.div`
  button {
    padding: 1rem;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 10px;
  right: 15px;
  @media screen and (max-width: 768px) {
    top: 5px;
    right: 10px;
    button {
      font-size: 16px;
      padding: 10px;
    }
  }
`;

const StyledWrapper = styled.div`
  background: white;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
`;

export default OpportunitiesItem;
