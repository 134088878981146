import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddDepartmentSetting from "./AddDepartmentSetting";
import DepartmentSettingItem from "./DepartmentSettingItem/DepartmentSettingItem";

const DepartmentSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState();

  const location = useLocation();
  const history = useHistory();

  const { departments, setDepartments, voivodeships, setVoivodeships } =
    useContext(StoreContext);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject();
    setIsModalOpen(true);
  };

  const handleOpenEditDepartment = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchDataDepartment = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartment();
    }
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
  }, []);

  return (
    <StyledDepartmentWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj oddział</StyledButton>
      </StyledButtonWrapper>
      <StyledDepartmentTable>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Liczba użytkowników</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {departments.map((department) => {
            return (
              <DepartmentSettingItem
                handleOpenEditDepartment={handleOpenEditDepartment}
                fetchDataDepartment={fetchDataDepartment}
                setEditObject={setEditObject}
                department={department}
                key={department.id}
              />
            );
          })}
        </tbody>
      </StyledDepartmentTable>
      <AddDepartmentSetting
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataDepartment}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledDepartmentWrapper>
  );
};

const StyledDepartmentWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledDepartmentTable = styled.table`
  width: 100%;
  padding: 40px 0;
  th {
    text-align: start;
    padding: 5px;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default DepartmentSettings;
