import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import HeatPump from "./Pages/HeatPump/HeatPump";
import HotWaterTank from "./Pages/HotWaterTank/HotWaterTank";
import Main from "./Pages/Main/Main";

const HeatPumpOfferSettings = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <div>
        <StyledOfferSettingsNav>
          <ul>
            <StyledOfferSettingsNavItem>
              <NavLink
                exact
                to="/settings/heat-pump-offer/"
                activeClassName="active"
              >
                Ogólne
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink
                to="/settings/heat-pump-offer/heat-pump"
                activeClassName="active"
              >
                Pompy Ciepła
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink
                to="/settings/heat-pump-offer/hot-water-tank"
                activeClassName="active"
              >
                Zbiorniki na CWU
              </NavLink>
            </StyledOfferSettingsNavItem>
          </ul>
        </StyledOfferSettingsNav>
      </div>

      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/`}>
            <Main />
          </Route>
          <Route path={`${path}/heat-pump`}>
            <HeatPump />
          </Route>
          <Route path={`${path}/hot-water-tank`}>
            <HotWaterTank />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  );
};

const StyledOfferSettingsNav = styled.nav`
  padding: 20px 40px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledOfferSettingsNavItem = styled.li`
  list-style: none;
  a {
    display: block;
    padding: 10px 15px;
    font-size: 20px;
    text-decoration: none;
    color: #444;
  }
  a.active {
    border-bottom: 4px solid #2d96db;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
`;
export default HeatPumpOfferSettings;
