import React, { useContext, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../helpers/request";
import { StoreContext } from "../../../../store/StoreProvider";
import RoleSettings from "./pages/RoleSettings/RoleSettings";
import DepartmentSettings from "./pages/DepartmentSettings/DepartmentSettings";
import UserList from "./pages/UserList/UserList";
import TeamsSettings from "./pages/TeamsSettings/TeamsSettings";

const UserSettings = () => {
  let { path } = useRouteMatch();

  const {
    users,
    setUsers,
    departments,
    setDepartments,
    roles,
    setRoles,
    setTeams,
    instalationsType,
    setInstalationsType,
  } = useContext(StoreContext);

  const history = useHistory();

  const fetchDataUser = async () => {
    try {
      const { status, data } = await request.get("/api/user");
      if (status === 200) {
        await setUsers(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push("login");
      }
    }
  };

  const fetchDataDepartments = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push("login");
      }
    }
  };
  const fetchDataRoles = async () => {
    try {
      const { status, data } = await request.get("/api/role");
      if (status === 200) {
        await setRoles(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push("login");
      }
    }
  };

  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push("login");
      }
    }
  };

  const fetchDataInstalationType = async () => {
    try {
      const { status, data } = await request.get("/api/instalations-type");
      if (status === 200) {
        await setInstalationsType(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push("login");
      }
    }
  };

  useEffect(() => {
    if (users === undefined || users.length === 0) {
      fetchDataUser();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartments();
    }
    if (roles === undefined || roles.length === 0) {
      fetchDataRoles();
    }
    if (TeamsSettings === undefined || TeamsSettings.length === 0) {
      fetchDataTeams();
    }
    if (instalationsType === undefined || instalationsType.length === 0) {
      fetchDataInstalationType();
    }
  }, []);
  return (
    <>
      <div>
        <StyledUserSettingNav>
          <ul>
            <StyledUserSettingNavItem>
              <NavLink exact to="/settings/users/" activeClassName="active">
                Użytkownicy
              </NavLink>
            </StyledUserSettingNavItem>
            <StyledUserSettingNavItem>
              <NavLink to="/settings/users/role" activeClassName="active">
                Role
              </NavLink>
            </StyledUserSettingNavItem>
            <StyledUserSettingNavItem>
              <NavLink to="/settings/users/department" activeClassName="active">
                Oddziały
              </NavLink>
            </StyledUserSettingNavItem>
            <StyledUserSettingNavItem>
              <NavLink to="/settings/users/teams" activeClassName="active">
                Zespoły
              </NavLink>
            </StyledUserSettingNavItem>
          </ul>
        </StyledUserSettingNav>
      </div>

      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/`}>
            <UserList />
          </Route>
          <Route path={`${path}/role`}>
            <RoleSettings />
          </Route>
          <Route path={`${path}/department`}>
            <DepartmentSettings />
          </Route>
          <Route path={`${path}/teams`}>
            <TeamsSettings />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  );
};

const StyledUserSettingNav = styled.nav`
  padding: 20px 40px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledUserSettingNavItem = styled.li`
  list-style: none;
  a {
    display: block;
    padding: 10px 15px;
    font-size: 20px;
    text-decoration: none;
  }
  a.active {
    border-bottom: 4px solid #2d96db;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

export default UserSettings;
