import {
  faEnvelope,
  faIdCard,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const LeadLeftAside = ({ lead, handleOnEdit }) => {
  return (
    <StyledContentContainer>
      <StyledInfoContainer>
        {lead?.name && (
          <StyledInfoItem>
            <StyledFontAwesomeIcon icon={faIdCard} />
            {lead.name}
          </StyledInfoItem>
        )}
        {lead?.phone && (
          <StyledInfoItem>
            <a href={`tel:${lead.phone}`}>
              <StyledFontAwesomeIcon icon={faPhone} />
              {lead.phone}
            </a>
          </StyledInfoItem>
        )}
        {lead?.email && (
          <StyledInfoItem>
            <a href={`mailto:${lead.email}`}>
              <StyledFontAwesomeIcon icon={faEnvelope} />
              {lead.email}
            </a>
            {lead.email}
          </StyledInfoItem>
        )}
        {lead.source && (
          <StyledInfoItem>
            Źródło pozyskania leada: {lead.source.name}
          </StyledInfoItem>
        )}
        {lead.add_user && (
          <StyledInfoItem>
            Lead dodany przez: {lead.add_user.name}
          </StyledInfoItem>
        )}
        {lead.voivodeship?.name && (
          <StyledInfoItem>Województwo: {lead.voivodeship.name}</StyledInfoItem>
        )}
        {lead.description && (
          <StyledInfoItem>Opis: {lead.description}</StyledInfoItem>
        )}
        {lead.wrong_lead === 0 && (
          <StyledButtonWrapper>
            <StyledEditButton onClick={handleOnEdit}>Edytuj</StyledEditButton>
          </StyledButtonWrapper>
        )}
      </StyledInfoContainer>
    </StyledContentContainer>
  );
};

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-end;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
const StyledInfoItem = styled.div`
  padding: 5px;
  & a {
    color: black;
    text-decoration: none;
  }
  & a:hover {
    text-decoration: underline;
  }
`;

const StyledInfoContainer = styled.div`
  background: white;
  border-radius: 10px;

  padding: 10px;
`;
const StyledContentContainer = styled.div`
  grid-area: info;
`;
export default LeadLeftAside;
