import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../store/StoreProvider";

const OpportunitiesChangeStatus = ({ isOpen, setIsOpen, handleChange }) => {
  const handleChangeStatus = (id) => {
    handleChange(id);
    setIsOpen(false);
  };

  const { leadTypes } = useContext(StoreContext);

  return (
    <StyledStatusesWrapper
      style={isOpen ? { display: "flex" } : { display: "none" }}
    >
      {leadTypes &&
        leadTypes.map((Lead_type) => (
          <StyledStatusWrapper
            key={Lead_type.id}
            onClick={() => handleChangeStatus(Lead_type.id)}
          >
            {" "}
            <StyledStatusSpan
              style={{ background: Lead_type.color }}
            ></StyledStatusSpan>
            <StyledStatusText>{Lead_type.name}</StyledStatusText>
          </StyledStatusWrapper>
        ))}
    </StyledStatusesWrapper>
  );
};

const StyledStatusesWrapper = styled.div`
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid rgba(160, 160, 160, 0.4);
`;
const StyledStatusWrapper = styled.button`
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background: #eee;
  }
`;

const StyledStatusText = styled.p`
  font-size: 18px;
`;

const StyledStatusSpan = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  svg {
    font-size: 20px;
  }
`;
export default OpportunitiesChangeStatus;
