import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import request from "../../helpers/request";
import styled from "styled-components";
import { StoreContext } from "../../store/StoreProvider";
import LeadAside from "./Components/LeadAside/LeadAside";
import LeadsList from "./Components/LeadsList/LeadsList";
import { useLocation, useHistory } from "react-router";
import { LeadContext } from "../../store/LeadProvider";

const Lead = ({ leads, fetchData, setIsAddModalOpen }) => {
  const { setLeads, teams, setTeams, departments, setDepartments } =
    useContext(StoreContext);
  const {
    teamFilter,
    setTeamFilter,
    departmentFilter,
    setDepartmentFilter,
    complaintFilter,
    setComplaintFilter,
    wrongLeadFilter,
    setWrongLeadFilter,
    contactCreateFilter,
    setContactCreateFilter,
    voivodeshipFilter,
    setVoivodeshipFilter,
    makeContactFilter,
    setMakeContactFilter,
    acquisitionSourceFilter,
    setAcquisitionSourceFilter,
    userFilter,
    setUserFilter,
    isLoading,
    setIsloading,
    pageCount,
    setPageCount,
    pageNumber,
    setPageNumber,
  } = useContext(LeadContext);
  const location = useLocation();
  const history = useHistory();

  const handleOnAddLead = () => {
    setIsAddModalOpen(true);
  };

  const fetchLeads = async (number = 1) => {
    setIsloading(true);
    try {
      const { status, data } = await request.get(`/api/lead?page=${number}`, {
        params: {
          wrong_lead: Number(wrongLeadFilter),
          contact_create: Number(contactCreateFilter),
          voivodeship: voivodeshipFilter,
          user: userFilter,
          team: teamFilter,
          department: departmentFilter,
          make_contact: makeContactFilter,
          source: acquisitionSourceFilter,
          complaint: complaintFilter,
        },
      });

      if (status === 200) {
        setLeads(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsloading(false);
  };
  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchDataDepartments = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (teams === undefined || teams.length === 0) {
      fetchDataTeams();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartments();
    }
  }, []);

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Lead</StyledTitle>
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnAddLead} title="Dodaj leada">
            <FontAwesomeIcon icon={faPlus} />{" "}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledTitleWrapper>
      <ContentWrapper>
        <LeadAside
          departmentFilter={departmentFilter}
          setDepartmentFilter={setDepartmentFilter}
          teamFilter={teamFilter}
          setTeamFilter={setTeamFilter}
          wrongLeadFilter={wrongLeadFilter}
          setWrongLeadFilter={setWrongLeadFilter}
          contactCreateFilter={contactCreateFilter}
          setContactCreateFilter={setContactCreateFilter}
          voivodeshipFilter={voivodeshipFilter}
          setVoivodeshipFilter={setVoivodeshipFilter}
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          makeContactFilter={makeContactFilter}
          setMakeContactFilter={setMakeContactFilter}
          acquisitionSourceFilter={acquisitionSourceFilter}
          setAcquisitionSourceFilter={setAcquisitionSourceFilter}
          complaintFilter={complaintFilter}
          setComplaintFilter={setComplaintFilter}
        />

        <LeadsList
          leads={leads}
          fetchLeads={fetchLeads}
          wrongLeadFilter={wrongLeadFilter}
          contactCreateFilter={contactCreateFilter}
          voivodeshipFilter={voivodeshipFilter}
          teamFilter={teamFilter}
          departmentFilter={departmentFilter}
          userFilter={userFilter}
          isLoading={isLoading}
          pageCount={pageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          makeContactFilter={makeContactFilter}
          acquisitionSourceFilter={acquisitionSourceFilter}
          complaintFilter={complaintFilter}
        />
      </ContentWrapper>
    </StyledWrapper>
  );
};

const StyledButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: transparent;
  color: black;
  font-size: 28px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  &:hover {
    color: rgb(244, 118, 49);
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  min-width: 0;
  margin: 0 auto;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    h3 {
      font-weight: normal;
      font-size: 2rem;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export default Lead;
