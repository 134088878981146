import React from "react";
import styled from "styled-components";
import AllCompanyDropdown from "./AllCompanyDropdown";

const UserSelectPopOver = ({
  departments,
  teams,
  handleOnChooseUser,
  isPopOverOpen,
}) => {
  return (
    <StyledSelectWrapper
      style={isPopOverOpen ? { display: "block" } : { display: "none" }}
    >
      <AllCompanyDropdown
        departments={departments}
        teams={teams}
        handleOnChooseUser={handleOnChooseUser}
      />
    </StyledSelectWrapper>
  );
};

const StyledSelectWrapper = styled.div`
  z-index: 1;
  width: 220px;
  position: absolute;
  top: -20px;
  left: 20px;
  padding: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`;

export default UserSelectPopOver;
