import React, { useEffect } from "react";
import styled from "styled-components";
import CompanyItem from "./CompanyItem/CompanyItem";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import loading from "../../../../img/48x48.gif";

const CompanyList = ({
  departmentFilter,
  handleOnClickEdit,
  setEditObject,
  isLoading,
  setPageNumber,
  fetchCompanies,
  companies,
  pageNumber,
  pageCount,
}) => {
  useEffect(() => {
    if (!isLoading) {
      setPageNumber(1);
      fetchCompanies(1);
    }
  }, [departmentFilter]);

  useEffect(() => {
    if (companies.length === 0) {
      fetchCompanies(1);
    }
  }, []);

  const handleOnChangePage = (event) => {
    window.scrollTo(0, 0);
    setPageNumber(event.selected + 1);
    fetchCompanies(event.selected + 1);
  };

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        {isLoading ? (
          <StyledLoadingWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledLoadingWrapper>
        ) : (
          <>
            {companies.map((company) => {
              return (
                <CompanyItem
                  key={company.id}
                  company={company}
                  handleOnClickEdit={handleOnClickEdit}
                  setEditObject={setEditObject}
                  fetchData={() => {
                    fetchCompanies(pageNumber);
                  }}
                />
              );
            })}
          </>
        )}
      </StyledContentWrapper>
      <StyledPaginateWrapper>
        <StyledReactPaginated
          breakLabel="..."
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
          pageClassName="paginate_button"
          disableInitialCallback={isLoading}
          onPageChange={(event) => handleOnChangePage(event)}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          renderOnZeroPageCount={null}
          forcePage={pageNumber - 1}
        />
      </StyledPaginateWrapper>
    </StyledWrapper>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledLoadingWrapper = styled.div`
  background: white;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const StyledReactPaginated = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .paginate_button {
    list-style: none;
    color: #134771;
    &.selected {
      color: rgb(244, 118, 49);
    }
  }
  & .paginate_button a {
    padding: 10px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .break {
    list-style: none;
  }
  & .break a {
    padding: 10px;
    border: none;
    font-size: 18px;
    color: #134771;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .previous {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }

  & .previous a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
  & .next {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }
  & .next a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
`;

const StyledPaginateWrapper = styled.div``;

const StyledContentWrapper = styled.div`
  margin: 0 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const StyledWrapper = styled.div`
  flex: 1;
`;

export default CompanyList;
