import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import request from "../../../../../helpers/request";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../components/Modal";
import { StoreContext } from "../../../../../store/StoreProvider";

const validationSchema = yup.object().shape(
  {
    name: yup.string().when(["phone", "email"], {
      is: (phone, email) => !phone && !email,
      then: yup.string().required("Wpisz jedno z trzech pól"),
      otherwise: yup.string().nullable(),
    }),

    phone: yup.string().when(["name", "email"], {
      is: (name, email) => !name && !email,
      then: yup
        .string()
        .matches(/^[0-9]+$/, {
          message: "Numer telefonu musi składać się wyłącznie z cyfr 1-9",
          excludeEmptyString: true,
        })
        .required("Wpisz jedno z trzech pól")
        .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków"),
      otherwise: yup.string().nullable(),
    }),
    email: yup
      .string()
      .email("proszę podać prawidłowy adres email")
      .when(["phone", "name"], {
        is: (phone, name) => !phone && !name,
        then: yup.string().required("Wpisz jedno z trzech pól"),
        otherwise: yup.string().nullable(),
      }),
    voivodeship: yup
      .string()
      .required("Proszę wybrać województwo")
      .typeError("Proszę wybrać województwo"),
    source_id: yup
      .string()
      .required("Proszę wybrać źródło pozyzkania Leada")
      .typeError("Proszę wybrać źródło pozyskania Leada"),
    description: yup.string().nullable(),
  },
  [
    ["email", "phone"],
    ["name", "phone"],
    ["email", "name"],
  ]
);

const EditLead = ({ isModalOpen, handleOnClose, fetchData, lead }) => {
  const { sources, voivodeships, leadTypes } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      name: lead.name,
      phone: lead.phone,
      email: lead.email,
      voivodeship: lead.voivodeship?.id,
      source_id: lead.source?.id,
      lead_type: lead.lead_type?.id,
      description: lead.description,
      is_buisness: false,
    });
  };
  const sendData = async (data) => {
    try {
      const { status } = await request.patch(`/api/lead/${lead.id}`, {
        ...data,
      });
      if (status === 201) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (lead) {
      setValue("name", lead.name);
      setValue("phone", lead.phone);
      setValue("email", lead.email);
      setValue("voivodeship", lead.voivodeship?.id);
      setValue("description", lead.description);
      setValue("source_id", lead.source?.id);
      setValue("lead_type", lead.lead_type?.id);
      setValue("is_buisness", Boolean(lead.is_buisness));
    }
  }, [lead, sources, voivodeships, leadTypes]);
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledInput>
          <div className="input-group">
            <label>Nazwa</label>
            <input type="text" {...register("name")} />
          </div>
        </StyledInput>
        {errors?.name && <span className="error">{errors.name.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input type="number" {...register("phone")} />
          </div>
        </StyledInput>
        {errors?.phone && <span className="error">{errors.phone.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Adres e-mail</label>
            <input type="text" {...register("email")} />
          </div>
        </StyledInput>
        {errors?.email && <span className="error">{errors.email.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Źródło pozyskania:</label>
            <select {...register("source_id")}>
              <option value="">Wybierz...</option>
              {sources.map((source) => {
                return (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.source_id && (
          <span className="error">{errors.source_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Typ Leada:</label>
            <select {...register("lead_type")}>
              {leadTypes.map((lead_type) => {
                return (
                  <option key={lead_type.id} value={lead_type.id}>
                    {lead_type.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.source_id && (
          <span className="error">{errors.source_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Województwo</label>
            <select {...register("voivodeship")}>
              <option value="">Wybierz...</option>
              {voivodeships &&
                voivodeships.map((voivodeship) => (
                  <option value={voivodeship.id} key={voivodeship.id}>
                    {voivodeship.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.voivodeship && (
          <span className="error">{errors.voivodeship.message}</span>
        )}

        <StyledCheckBox>
          <StyledInputGroup>
            <StyledCheckBoxLabel>
              <Controller
                name="is_buisness"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <StyledCheckBoxInput
                    type="checkbox"
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
              Klient biznesowy
            </StyledCheckBoxLabel>
          </StyledInputGroup>
        </StyledCheckBox>

        <StyledInput>
          <div className="input-group">
            <label>Opis</label>
            <input type="text" {...register("description")} />
          </div>
        </StyledInput>
        {errors?.description && (
          <span className="error">{errors.country.message}</span>
        )}
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Zapisz
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default EditLead;
