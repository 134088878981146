import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../.../../../../../../../../../helpers/request";
import styled from "styled-components";
import DeleteAlert from "./DeleteAlert";
import Switch from "../../../../../../../../components/Switch";
import { useLocation, useHistory } from "react-router";

const InstalationItem = ({
  instalation,
  handleOpenEditInstalation,
  setEditObject,
  fetchInstalation,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const handleOnDelete = async () => {
    const { status } = await request.delete(
      `/api/instalations-type/${instalation.id}`
    );
    if (status === 200) {
      fetchInstalation();
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    setEditObject(instalation);
    handleOpenEditInstalation();
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const handleOnSwitchChange = async (e, data) => {
    try {
      const { status } = await request.patch(
        `/api/instalations/update-active/${instalation.id}`,
        {
          value: data,
        }
      );
      if (status === 200) {
        fetchInstalation();
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  return (
    <StyledTaskTypeItem>
      <Switch
        checked={instalation.is_active}
        name={instalation.name}
        id={instalation.id}
        handleOnChange={handleOnSwitchChange}
      />
      <StyledDescription>
        <p>
          {instalation.solar_panel?.name} + {instalation.inverter?.name}
        </p>
      </StyledDescription>
      {/* <StyledWarranty>
        <p>{warranty} lat</p>
      </StyledWarranty> */}
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnDelete={handleOnDelete}
        handleOnClose={handleOnCloseAlert}
      />
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.tr``;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.td``;
const StyledWarranty = styled.td`
  text-align: right;
`;

const StyledButtonGroup = styled.td`
  text-align: right;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export default InstalationItem;
