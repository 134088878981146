import React, { createContext, useState } from "react";

export const PvCalculatorContext = createContext(null);

const PvCalculatorProvider = ({ children }) => {
  const [panelCount, setPanelCount] = useState(0);
  const [installation, setInstallation] = useState([]);
  const [installationId, setInstallationId] = useState("");
  const [construction, setConstruction] = useState(null);
  const [constructionId, setConstructionId] = useState("");
  const [optimizer, setOptimizer] = useState(null);
  const [optimizerId, setOptimizerId] = useState("");
  const [optimizerCount, setOptimizerCount] = useState("");
  const [inverterCount, setInverterCount] = useState(1);
  const [secure, setSecure] = useState("");
  const [services, setServices] = useState([]);
  const [vatRate, setVatRate] = useState(8);
  const [margin, setMargin] = useState(0);
  const [antiFireCost, setAntiFireCost] = useState(0);
  const [instalationType, setInstaltaionType] = useState([]);
  const [specialDiscountManagement, setSpecialDiscountManagement] = useState(0);
  const [specialDiscountManager, setSpecialDiscountManager] = useState(0);
  const [cutLength, setCutLength] = useState(0);
  const [wireLength, setWireLength] = useState(0);
  const [cutLengthPrice, setCutLengthPrice] = useState(0);
  const [wireLengthPrice, setWireLengthPrice] = useState(0);
  const [optimizers, setOptimizers] = useState([]);
  const [minModules, setMinModules] = useState(0);
  const [minModulesRWB, setMinModulesRWB] = useState(0);
  const [cutIndividualPricing, setCutIndividualPricing] = useState(false);
  const [wireIndividualPricing, setWireIndividualPricing] = useState(false);
  const [installationPower, setInstallationPower] = useState(0);
  const [priceBrutto, setPriceBrutto] = useState(0);
  const [priceNetto, setPriceNetto] = useState(0);
  const [constructionCount, setConstructionCount] = useState(1);
  const [minPower, setMinPower] = useState(null);
  const [maxPower, setMaxPower] = useState(null);

  return (
    <PvCalculatorContext.Provider
      value={{
        installation,
        setInstallation,
        installationId,
        setInstallationId,
        construction,
        setConstruction,
        constructionId,
        setConstructionId,
        panelCount,
        setPanelCount,
        optimizer,
        setOptimizer,
        optimizerId,
        setOptimizerId,
        optimizerCount,
        setOptimizerCount,
        inverterCount,
        setInverterCount,
        secure,
        setSecure,
        services,
        setServices,
        vatRate,
        setVatRate,
        margin,
        setMargin,
        antiFireCost,
        setAntiFireCost,
        instalationType,
        setInstaltaionType,
        specialDiscountManagement,
        setSpecialDiscountManagement,
        specialDiscountManager,
        setSpecialDiscountManager,
        cutLength,
        setCutLength,
        wireLength,
        setWireLength,
        optimizers,
        setOptimizers,
        minModules,
        setMinModules,
        minModulesRWB,
        setMinModulesRWB,
        cutIndividualPricing,
        setCutIndividualPricing,
        wireIndividualPricing,
        setWireIndividualPricing,
        installationPower,
        setInstallationPower,
        priceBrutto,
        setPriceBrutto,
        priceNetto,
        setPriceNetto,
        cutLengthPrice,
        setCutLengthPrice,
        wireLengthPrice,
        setWireLengthPrice,
        constructionCount,
        setConstructionCount,
        minPower,
        setMinPower,
        maxPower,
        setMaxPower,
      }}
    >
      {children}
    </PvCalculatorContext.Provider>
  );
};

export default PvCalculatorProvider;
