import { faEllipsisH, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import NoteDropDownOptions from "./NoteDropDownOption";
import ShowImage from "../../../../../components/ShowImage/ShowImage";
import ItemFile from "../../../../../components/Note/ItemFile";
import { useContext } from "react";
import { StoreContext } from "../../../../../store/StoreProvider";
import moment from "moment";
import DeleteAlert from "./DeleteAlert";
import LeadEditNote from "../LeadEditNote/LeadEditNote";
import loading from "../../../../../img/48x48.gif";
import { useHistory, useLocation } from "react-router-dom";

const LeadNoteItem = ({ note, fetchData, handleOnClickImage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [file, setFile] = useState([]);
  const [time, setTime] = useState(null);
  const [isSended, setIsSended] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { user } = useContext(StoreContext);
  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/lead-note/${note.id}`);
    if (status === 201) {
      fetchData();
      setIsOpenOptions(false);
    }
  };

  const handleDropDownOptions = (e) => {
    e.preventDefault();
    setIsOpenOptions((prev) => !prev);
  };

  useEffect(() => {
    setFile([]);
    setImageFile([]);
    if (note.files.length > 0) {
      [...note.files].forEach((file) => {
        if (
          file.mime_type === "image/jpg" ||
          file.mime_type === "image/png" ||
          file.mime_type === "image/jpeg"
        ) {
          setImageFile((prev) => [...prev, file]);
        } else {
          setFile((prev) => [...prev, file]);
        }
      });
    }
  }, [note.files]);
  useEffect(() => {
    if (note.created_at) {
      if (moment(note.created_at).isSame(new Date(), "year")) {
        setTime(moment(note.created_at).format("DD MMM HH:mm"));
      } else {
        setTime(moment(note.created_at).format("lll"));
      }
    }
  }, [note]);

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };
  const handleOnClickEdit = () => {
    setIsModalOpen(true);
  };

  const handleOnDownloadAll = async () => {
    setIsSended(true);
    try {
      const { status, data } = await request.get(
        `api/file/download-all/${note.id}`,
        {
          responseType: "blob",
        }
      );
      if (status === 200) {
        const href = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = href;
        if (note.opportunities?.name) {
          link.setAttribute(
            "download",
            `${note.opportunities.name.replaceAll(" ", "_")}.zip`
          ); //or any other extension
        } else {
          link.setAttribute("download", `AllFiles.zip`); //or any other extension
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  return (
    <StyledNoteItem>
      <StyledHeadWrapper>
        <StyledUserAvatar>
          <FontAwesomeIcon icon={faUser} />
        </StyledUserAvatar>
        <StyledHeadContent>
          <p>{note.user.name}</p>
          <StyledTimeWrapper>{time}</StyledTimeWrapper>
        </StyledHeadContent>
        <StyledCollapseMenu>
          <button onClick={handleDropDownOptions} type="button">
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        </StyledCollapseMenu>
        <NoteDropDownOptions
          isOpen={isOpenOptions}
          setIsOpenOptions={setIsOpenOptions}
          handleOnOpenAlert={handleOnOpenAlert}
          handleOnClick={handleOnClickEdit}
          handleOnDownloadAll={handleOnDownloadAll}
          user={user}
          note={note}
        />
      </StyledHeadWrapper>
      <StyledContentWrapper>
        <StyledNoteP>
          {note.text ? note.text : "Załączył(a) plik(i)"}
        </StyledNoteP>
        <StyledItemContentWrapper>
          {imageFile.length > 0 && (
            <ShowImage
              imageFile={imageFile}
              handleOnClickImage={handleOnClickImage}
            />
          )}
          {file.length > 0 &&
            file.map((item) => (
              <ItemFile key={item.id} file={item} setIsSended={setIsSended} />
            ))}
        </StyledItemContentWrapper>
      </StyledContentWrapper>
      {isSended && (
        <StyledSendNoteWrapper>
          <StyledSendNoteInnerWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledSendNoteInnerWrapper>
        </StyledSendNoteWrapper>
      )}
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
      <LeadEditNote
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
        note={note}
      />
    </StyledNoteItem>
  );
};
const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledTimeWrapper = styled.div`
  color: gray;
  font-size: 14px;
`;

const StyledNoteP = styled.p`
  white-space: pre-line;
`;

const StyledCollapseMenu = styled.div`
  button {
    padding: 1rem;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 10px;
  right: 15px;
`;
const StyledHeadContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledContentWrapper = styled.div`
  margin-top: 10px;
`;
const StyledItemContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledUserAvatar = styled.div`
  width: 40px;
  height: 40px;
  background: #cadeee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #134771;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const StyledHeadWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledNoteItem = styled.div`
  background-color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  position: relative;
`;

export default LeadNoteItem;
