import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import UserSidebarListItem from "./UserSidebarListItem";
import UserSideBarNav from "./UserSideBarNav";

const UserSideBarList = ({
  todoList,
  fetchData,
  filterOption,
  setFilterOption,
  setHideOld,
  hideOld,
}) => {
  let history = useHistory();

  const handleOnRedirect = () => {
    history.push("/dashboard/todo");
  };

  const handleHideOldTodo = () => {
    setHideOld(!hideOld);
  };
  return (
    <StyledTodoList>
      <UserSideBarNav
        filterOption={filterOption}
        setFilterOption={setFilterOption}
      />
      <div className="list">
        {todoList.map((todo) => (
          <UserSidebarListItem
            todo={todo}
            key={todo.id}
            fetchData={fetchData}
          />
        ))}
      </div>
      <div className="options">
        <StyledButton onClick={handleHideOldTodo}>
          {hideOld ? "Pokaż spóźnione" : "Ukryj spóźnione"}
        </StyledButton>
        <StyledButton onClick={handleOnRedirect}>Przejdź do zadań</StyledButton>
      </div>
    </StyledTodoList>
  );
};

const StyledTodoList = styled.div`
  background: #dfdede;
  width: 100%;
  .list {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
  .options {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledButton = styled.button`
  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: #5a5a5a;
  }
`;
export default UserSideBarList;
