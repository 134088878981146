import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Modal from "../../../../../../../../../components/Modal";
const ShowContractTermsDetails = ({
  contractTermsDetails,
  isModalOpen,
  handleOnClose,
  handleOnEdit,
  opportunities,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledWrapper>
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledSectionTitle>Szczegóły płatności</StyledSectionTitle>
        {!opportunities.company && (
          <StyledItemWrapper>
            <StyledItemTitle>
              Inwestor oświadcza, że montaż Instalacji ma miejsce na:
            </StyledItemTitle>
            {Number(contractTermsDetails?.vat_rate_type) === 1 && (
              <StyledItemValue>
                {" "}
                budynku mieszkalnym o powierzchni użytkowej do 300 m2 — stawka
                należnego podatku VAT wynosi 8%.
              </StyledItemValue>
            )}
            {Number(contractTermsDetails?.vat_rate_type) === 2 && (
              <StyledItemValue>
                {" "}
                budynku mieszkalnym o powierzchni użytkowej pow. 300 m2 — stawka
                należnego podatku VAT wynosi 23%.
              </StyledItemValue>
            )}
            {Number(contractTermsDetails?.vat_rate_type) === 3 && (
              <StyledItemValue>
                {" "}
                innym typie budynku lub gruncie — dla klienta indywidualnego —
                stawka należnego podatku VAT wynosi 8%.
              </StyledItemValue>
            )}
          </StyledItemWrapper>
        )}
        {Number(contractTermsDetails?.vat_rate_type) === 2 && (
          <StyledItemWrapper>
            <StyledItemTitle>
              {" "}
              Powierzchnia użytkowa budynku (m<sup>2</sup>):{" "}
            </StyledItemTitle>
            <StyledItemValue>
              {contractTermsDetails?.usable_area}
            </StyledItemValue>
          </StyledItemWrapper>
        )}

        <StyledItemWrapper>
          <StyledItemTitle>Rodzaj płatności:</StyledItemTitle>
          {Number(contractTermsDetails.payment_type) === 1 && (
            <StyledItemValue>Płatność kredytem</StyledItemValue>
          )}
          {Number(contractTermsDetails.payment_type) === 2 && (
            <StyledItemValue>
              Płatność kredytem z wkładem własnym
            </StyledItemValue>
          )}
          {Number(contractTermsDetails.payment_type) === 3 && (
            <StyledItemValue>Płatność leasing</StyledItemValue>
          )}
          {Number(contractTermsDetails.payment_type) === 4 && (
            <StyledItemValue>Płatność ze środków własnych</StyledItemValue>
          )}
        </StyledItemWrapper>

        {contractTermsDetails.payment_type === 2 && (
          <StyledItemWrapper>
            <StyledItemTitle>Wpłata własna</StyledItemTitle>
            <StyledItemValue>
              {contractTermsDetails?.loan_self_deposit}
            </StyledItemValue>
          </StyledItemWrapper>
        )}
        {contractTermsDetails.payment_type === 4 && (
          <>
            <StyledItemPaymentWrapper>
              <StyledItemTitle>Pierwsza transza - </StyledItemTitle>
              <StyledItemPaymentValue>
                {contractTermsDetails?.first_tranche_percentage} %
              </StyledItemPaymentValue>
            </StyledItemPaymentWrapper>
            <StyledItemPaymentWrapper>
              <StyledItemTitle>Druga transza - </StyledItemTitle>
              <StyledItemPaymentValue>
                {contractTermsDetails?.second_tranche_percentage} %
              </StyledItemPaymentValue>
            </StyledItemPaymentWrapper>
            <StyledItemPaymentWrapper>
              <StyledItemTitle>Trzecia transza - </StyledItemTitle>
              <StyledItemPaymentValue>
                {contractTermsDetails?.third_tranche_percentage} %
              </StyledItemPaymentValue>
            </StyledItemPaymentWrapper>
          </>
        )}
        <StyledItemDateWrapper>
          <StyledItemTitle>Termin realizacji</StyledItemTitle>
          <StyledItemDateValue>
            {contractTermsDetails?.realization_date &&
              `${contractTermsDetails.realization_date} .tyg`}
          </StyledItemDateValue>
        </StyledItemDateWrapper>
      </StyledWrapper>
      <StyledButtonWrapper>
        <StyledEditButton onClick={handleOnEdit}>Edytuj</StyledEditButton>
      </StyledButtonWrapper>
    </Modal>
  );
};

const StyledItemDateWrapper = styled.div`
  padding: 5px 0;
  display: flex;
`;

const StyledItemDateValue = styled.div`
  font-size: 16px;
`;
const StyledItemPaymentWrapper = styled.div`
  padding: 5px 0;
  display: flex;
`;

const StyledItemPaymentValue = styled.div`
  font-size: 16px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  padding: 10px 0;

  align-items: center;
  justify-content: flex-start;
`;

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0;
`;

const StyledItemTitle = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;
const StyledItemValue = styled.div`
  font-size: 14px;
`;
const StyledWrapper = styled.div`
  min-width: 450px;
`;
const StyledItemWrapper = styled.div`
  padding: 5px 0;
  display: flex;
  flex-direction: column;
`;

export default ShowContractTermsDetails;
