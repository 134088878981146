import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import dayStyles from "./dayStyles";
import TodoItem from "./TodoItem";

const CalendarMonthDayItem = ({
  day,
  value,
  setValue,
  todoList,
  fetchData,
  handleOnClickEdit,
  setEditObject,
  handleOnDayOpen,
  handleOnClickAdd,
}) => {
  const [todoItems, setTodoItems] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [howManyMore, setHowManyMore] = useState(0);
  useEffect(() => {
    setTodoItems(
      todoList.filter((todo) => {
        return day.isSame(new Date(todo.start_date), "day");
      })
    );
  }, [todoList]);

  useEffect(() => {
    if (todoItems.length > 4) {
      setIsMore(true);
      setHowManyMore(todoItems.length - 4);
    } else {
      setIsMore(false);
      setHowManyMore(0);
    }
  }, [todoItems, todoList]);

  const handleChooseDay = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setValue(day);
    handleOnDayOpen();
  };

  const handleOnAddTask = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setEditObject(null);
    handleOnClickAdd(day);
  };

  return (
    <StyledDayContainer
      onClick={handleOnAddTask}
      className={dayStyles(day, value)}
    >
      <StyledDay>{day.format("D")}</StyledDay>
      <StyledTodoItemContainer>
        {todoItems.slice(0, 4).map((todo) => (
          <TodoItem
            key={todo.id}
            todo={todo}
            fetchData={fetchData}
            handleOnClickEdit={handleOnClickEdit}
            setEditObject={setEditObject}
          />
        ))}
      </StyledTodoItemContainer>
      {isMore && (
        <StyledIsMoreButton onClick={handleChooseDay}>
          {" "}
          + {howManyMore} więcej{" "}
        </StyledIsMoreButton>
      )}
    </StyledDayContainer>
  );
};
const StyledIsMoreButton = styled.button`
  color: #2d96db;
  text-decoration: none;
  border: none;
  width: 100%;
  background: transparent;
  outline: none;
  padding: 0px 5px;
  cursor: pointer;

  &:hover {
    color: #0167a7;
  }
`;
const StyledTodoItemContainer = styled.div`
  padding: 2px;
`;

const StyledDay = styled.div`
  padding: 2px 5px;
  text-align: right;
`;

const StyledDayContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  width: calc(100% / 7);
  height: 140px;

  cursor: pointer;
  &.today {
    background: #cadeee;
  }
  &.selected {
    color: black;
    background: lightgreen;
  }
`;

export default CalendarMonthDayItem;
