import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SummarykWpItem from "./Components/SummarykWpItem";
import SummaryOpportunitiesItem from "./Components/SummaryOpportunitiesItem";
import SummaryPriceItem from "./Components/SummaryPriceItem";
import loading from "../../../../../img/48x48.gif";
const Summary = ({ statistic, isLoading }) => {
  const [dataTotalkWp, setDataChartTotalkWp] = useState(0);
  const [dataTotalPrice, setDataTotalPrice] = useState(0);
  const [dataTotalOpportunities, setDataTotalOpportunities] = useState(0);

  useEffect(() => {
    setDataChartTotalkWp(
      statistic.reduce((value, user) => {
        return (value += user.opportunities_kWp);
      }, 0)
    );

    setDataTotalPrice(
      statistic.reduce((value, user) => {
        return (value += user.opportunities_price);
      }, 0)
    );

    setDataTotalOpportunities(
      statistic.reduce((value, user) => {
        return (value += user.opportunities_count);
      }, 0)
    );
  }, [statistic]);

  return (
    <StyledWrapper>
      <StyledTitleWrapper>Podsumowanie</StyledTitleWrapper>
      {isLoading ? (
        <StyledLoadingWrapper>
          <StyledLoadingImg src={loading} alt="Loading..." />
        </StyledLoadingWrapper>
      ) : (
        <StyledSummaryWrapper>
          <SummaryPriceItem price={dataTotalPrice} />
          <SummarykWpItem kWp={dataTotalkWp} />
          <SummaryOpportunitiesItem opportunities={dataTotalOpportunities} />
        </StyledSummaryWrapper>
      )}
    </StyledWrapper>
  );
};

const StyledLoadingImg = styled.img``;

const StyledLoadingWrapper = styled.div`
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;

const StyledWrapper = styled.div`
  min-width: 0;
  padding: 10px;
  flex: 1;
`;

export default Summary;
