import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faHome,
  faBriefcase,
  faCoffee,
  faFlask,
  faHeart,
  faInfoCircle,
  faPhone,
  faThumbsUp,
  faTruck,
  faExclamation,
  faCalendar,
  faMugHot,
  faCheck,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { faGem } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import request from "../../../../helpers/request";
import ToDoDropdownOptions from "./DropDownMenu/ToDoDropdownOptions";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { StoreContext } from "../../../../store/StoreProvider";
import moment from "moment";
import DeleteAlert from "./DeleteAlert";
import AddContactNote from "./AddContactNote";
import AddContactLeadNote from "./AddContactLeadNote";
import ChangeAssigment from "./ChangeAssigment";

library.add(
  faEnvelope,
  faHome,
  faTruck,
  faThumbsUp,
  faHeart,
  faFlask,
  faPhone,
  faInfoCircle,
  faCoffee,
  faBriefcase,
  faGem,
  faExclamation,
  faCalendar,
  faMugHot,
  faGem
);
const ToDoItem = ({ todo, fetchData, handleOnClickEdit, setEditObject }) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [dateString, setDateString] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpportunityModalOpen, setIsOpportunityModalOpen] = useState(false);
  const [isAssigmentModalOpen, setIsAssigmentModalOpen] = useState(false);
  const [isMaintainer, setIsMaintainer] = useState(false);
  const { user } = useContext(StoreContext);

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const changeDate = moment(todo.start_date).format("DD.MM.YYYY");

  useEffect(() => {
    if (Boolean(todo.start_date) && Boolean(todo.end_date)) {
      if (
        moment(todo.start_date).hour() === 0 &&
        moment(todo.start_date).minute() === 0 &&
        moment(todo.start_date).second() === 0 &&
        moment(todo.end_date).hour() === 23 &&
        moment(todo.end_date).minute() === 59 &&
        moment(todo.end_date).second() === 59
      ) {
        if (moment(todo.start_date).isSame(today, "day")) {
          setDateString("dzisiaj");
        } else if (moment(todo.start_date).isSame(tomorrow, "day")) {
          setDateString("jutro");
        } else if (moment(todo.start_date).isBefore(today, "day")) {
          setDateString(<div style={{ color: "#f47631" }}>{changeDate}</div>);
        } else {
          setDateString(changeDate);
        }
      } else {
        if (moment(todo.end_date).isBefore(moment(), "minute")) {
          if (moment(todo.start_date).isSame(todo.end_date, "day")) {
            setDateString(
              <div style={{ color: "#f47631" }}>{`${moment(
                todo.start_date
              ).format("DD.MM.YYYY HH:mm")} - ${moment(todo.end_date).format(
                "HH:mm"
              )}`}</div>
            );
          }
        } else {
          if (moment(todo.start_date).isSame(todo.end_date, "day")) {
            setDateString(
              `${moment(todo.start_date).format("DD.MM.YYYY HH:mm")} - ${moment(
                todo.end_date
              ).format("HH:mm")}`
            );
          }
        }
      }
    } else {
      setDateString(
        `Zadanie dodane: ${moment(todo.created_at).format("DD.MM.YY HH:mm")}`
      );
    }
  }, [todo.start_date, todo.end_date, todo]);

  const handleDropDownOptions = (e) => {
    e.preventDefault();
    setIsOpenOptions(!isOpenOptions);
  };

  useEffect(() => {
    if (todo.responsible.id === user.id || user.role?.id === 1) {
      setIsMaintainer(true);
    } else {
      setIsMaintainer(false);
    }
  }, [todo, todo?.responsible, user]);

  const handleChangeComplete = async () => {
    if (isMaintainer) {
      if (todo.lead) {
        setIsModalOpen(true);
      } else if (todo.opportunity) {
        setIsOpportunityModalOpen(true);
      } else {
        const { status } = await request.patch(`api/todo/${todo.id}`, {
          done: !todo.done,
        });

        if (status === 201) {
          fetchData();
        }
      }
    } else if (user.role?.id === 2 || user.role?.id === 4) {
      const { status } = await request.patch(`api/todo/${todo.id}`, {
        done: !todo.done,
      });

      if (status === 201) {
        fetchData();
      }
    }
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOnCloseOpportunityModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOpportunityModalOpen(false);
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/todo/${todo.id}`);
    if (status === 200) {
      fetchData();
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    setEditObject(todo);
    handleOnClickEdit();
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };

  const handleOnCloseAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  const handleOnOpenAssigment = () => {
    setIsAssigmentModalOpen(true);
  };

  const handleOnCloseAssigment = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAssigmentModalOpen(false);
  };

  return (
    <StyledWrapper>
      <StyledInfoWrapper>
        <StyledSpanWrapper>
          {isMaintainer ? (
            <>
              {todo.done ? (
                <StyledCompleteSpan
                  onClick={handleChangeComplete}
                  title="oznacz jako wykonane"
                >
                  {" "}
                  <FontAwesomeIcon icon={faCheck} />
                </StyledCompleteSpan>
              ) : (
                <StyledUncompleteSpan
                  onClick={handleChangeComplete}
                  title="oznacz jako wykonane"
                ></StyledUncompleteSpan>
              )}
            </>
          ) : (
            <>
              {" "}
              {todo.done ? (
                <StyledCompleteSpanDisabled>
                  <FontAwesomeIcon icon={faCheck} />
                </StyledCompleteSpanDisabled>
              ) : (
                <StyledUncompleteSpanDisabled></StyledUncompleteSpanDisabled>
              )}
            </>
          )}
        </StyledSpanWrapper>

        <StyledDescriptionWrapper>
          <StyledTitleWrapper>
            <StyledFontAwesomeIcon
              icon={[todo.taskType?.prefix, todo.taskType?.icon]}
            />
            <h4>{todo.name}</h4>
          </StyledTitleWrapper>

          <StyledLinkWrapper>
            <StyledRelatedLink>
              {todo.opportunity?.name && (
                <>
                  <StyledLink
                    title={todo.opportunity.name}
                    to={`/dashboard/opportunities/details/${todo.opportunity.id}`}
                  >
                    {todo.opportunity.name} {","}
                  </StyledLink>
                  {todo.opportunity.client?.phone && (
                    <StyledLinkItemWrapper>
                      <StyledFontAwesomeLinkIcon icon={faPhone} />
                      <StyledPhoneLink
                        href={`tel:${todo.opportunity.client?.phone}`}
                      >
                        {todo.opportunity.client?.phone}
                      </StyledPhoneLink>
                    </StyledLinkItemWrapper>
                  )}
                  {todo.opportunity.client?.email && (
                    <StyledLinkItemWrapper>
                      <StyledFontAwesomeLinkIcon icon={faEnvelope} />
                      <StyledPhoneLink
                        href={`mailto:${todo.opportunity.client?.email}`}
                      >
                        {todo.opportunity.client?.email}
                      </StyledPhoneLink>
                    </StyledLinkItemWrapper>
                  )}
                  {todo.opportunity.company?.phone && (
                    <StyledLinkItemWrapper>
                      <StyledFontAwesomeLinkIcon icon={faPhone} />
                      <StyledPhoneLink
                        href={`tel:${todo.opportunity.company?.phone}`}
                      >
                        {todo.opportunity.company?.phone}
                      </StyledPhoneLink>
                    </StyledLinkItemWrapper>
                  )}
                  {todo.opportunity.company?.email && (
                    <StyledLinkItemWrapper>
                      <StyledFontAwesomeLinkIcon icon={faEnvelope} />
                      <StyledPhoneLink
                        href={`mailto:${todo.opportunity.company?.email}`}
                      >
                        {todo.opportunity.company?.email}
                      </StyledPhoneLink>
                    </StyledLinkItemWrapper>
                  )}
                </>
              )}{" "}
              {(todo.lead?.name || todo.lead?.phone || todo.lead?.email) && (
                <>
                  <StyledLink
                    title={`${todo.lead?.name} ${todo.lead?.phone} ${todo.lead?.email}`}
                    to={`/dashboard/lead/details/${todo.lead.id}`}
                  >
                    {todo.lead.name} {todo.lead.phone} {todo.lead.email}
                    {""}
                  </StyledLink>
                  {todo.lead.phone && (
                    <StyledLinkItemWrapper>
                      <StyledFontAwesomeLinkIcon icon={faPhone} />
                      <StyledPhoneLink href={`tel:${todo.lead.phone}`}>
                        {todo.lead.phone}
                      </StyledPhoneLink>
                    </StyledLinkItemWrapper>
                  )}
                  {todo.lead.email && (
                    <StyledLinkItemWrapper>
                      <StyledFontAwesomeLinkIcon icon={faEnvelope} />
                      <StyledPhoneLink href={`mailto:${todo.lead.email}`}>
                        {todo.lead.email}
                      </StyledPhoneLink>
                    </StyledLinkItemWrapper>
                  )}
                </>
              )}
            </StyledRelatedLink>
            <StyledUserLink>
              Odpowiedzialny:{" "}
              <StyledUserP
              // to={`/dashboard/contacts/user/details/${todo.responsible?.id}`}
              >
                {todo.responsible?.name}
              </StyledUserP>
            </StyledUserLink>
          </StyledLinkWrapper>
          {todo.description && (
            <StyledDescription>Opis: {todo.description}</StyledDescription>
          )}
        </StyledDescriptionWrapper>
      </StyledInfoWrapper>
      <StyledDateWrapper>
        <div>{dateString}</div>
      </StyledDateWrapper>
      {!todo.done && (
        <StyledCollapseButtonWrapper>
          <StyledCollapseButton onClick={handleDropDownOptions} type="button">
            <FontAwesomeIcon icon={faEllipsisH} />
          </StyledCollapseButton>
        </StyledCollapseButtonWrapper>
      )}

      {!todo.done && (
        <ToDoDropdownOptions
          isOpen={isOpenOptions}
          setIsOpenOptions={setIsOpenOptions}
          handleOnOpenAlert={handleOnOpenAlert}
          handleOnClick={handleOnEdit}
          handleOnOpenAssigment={handleOnOpenAssigment}
          todoUser={todo.user}
          user={user}
        />
      )}
      <ChangeAssigment
        isModalOpen={isAssigmentModalOpen}
        handleOnClose={handleOnCloseAssigment}
        todo={todo}
      />
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
      <AddContactLeadNote
        todo={todo}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
      />
      <AddContactNote
        todo={todo}
        isModalOpen={isOpportunityModalOpen}
        handleOnClose={handleOnCloseOpportunityModal}
        fetchData={fetchData}
      />
    </StyledWrapper>
  );
};
const StyledUserP = styled.span``;

const StyledFontAwesomeLinkIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledPhoneLink = styled.a`
  color: #134771;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #053257;
  }
`;
const StyledLinkItemWrapper = styled.div`
  padding: 2px 0;
`;
const StyledDescription = styled.div`
  margin-right: 110px;
`;

const StyledCollapseButton = styled.button`
  padding: 1rem;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

const StyledCollapseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
`;

const StyledUncompleteSpanDisabled = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #134771;
`;
const StyledCompleteSpanDisabled = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #66bc46;
  background: #66bc46;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
`;

const StyledUncompleteSpan = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #134771;
  cursor: pointer;
`;
const StyledCompleteSpan = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #66bc46;
  background: #66bc46;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
`;

const StyledDateWrapper = styled.div`
  text-align: right;
`;
const StyledUserLink = styled.div`
  margin-top: 5px;
`;

const StyledRelatedLink = styled.div``;

const StyledLinkWrapper = styled.div``;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 0 10px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
`;

const StyledDescriptionWrapper = styled.div``;

const StyledSpanWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #053257;
  }
`;
const StyledWrapper = styled.div`
  background: white;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
`;

export default ToDoItem;
