import React from "react";
import styled from "styled-components";
import UserItem from "./Components/UserItem";
import loading from "../../../../../img/48x48.gif";
const OpenOpportunities = ({ statistic, isLoading }) => {
  return (
    <>
      {" "}
      {isLoading ? (
        <StyledLoadingWrapper>
          <StyledLoadingImg src={loading} alt="Loading..." />
        </StyledLoadingWrapper>
      ) : (
        <StyledOpenOpportunitiesWrapper>
          {statistic
            .filter((user) => user.opportunities.length > 0)
            .map((user) => (
              <UserItem user={user} key={user.id} />
            ))}
        </StyledOpenOpportunitiesWrapper>
      )}
    </>
  );
};

const StyledLoadingImg = styled.img``;

const StyledLoadingWrapper = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOpenOpportunitiesWrapper = styled.div`
  min-width: 0;
  padding: 10px;
  flex: 1;
  h4 {
    margin-bottom: 20px;
  }
`;

export default OpenOpportunities;
