import {
  faCheck,
  faCircle,
  faPaperPlane,
  faPen,
  faTimes,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../../helpers/request";
import loading from "../../../../../../../img/48x48.gif";
import AnnexModal from "./Components/AneexModal/AnnexModal";
import DataUpdateAnnex from "./Components/DataUpdateAnnex/DataUpdateAnnex";
import LoseOpportunityAnnex from "./Components/LoseOpportunityAnnex/LoseOpportunityAnnex";
import ShowContractDetails from "./ShowContractDetails";

const ContractItem = ({ opportunities, contract, fetchData }) => {
  const [spanObject, setSpanObject] = useState(null);
  const [sendObject, setSendObject] = useState(null);
  const [isSended, setIsSended] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [dataUpdateModalOpen, setDataUpdateModalOpen] = useState(false);
  const [annexModalOpen, setAnnexModalOpen] = useState(false);
  const [lostAnnexModalOpen, setLostAnnexModalOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleOnOpenModal = () => {
    setShowModalOpen(true);
  };

  const handleSignedContract = async () => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/contract/signed-contract/${contract.id}`,
        {
          signed: true,
        }
      );

      if (status === 200) {
        fetchData();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleWinOpportunity = async () => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/contract/win-opportunity/${contract.id}`,
        {
          status: 2,
        }
      );

      if (status === 200) {
        fetchData();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleSendToSigned = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSignedContract();
  };
  const handleSendToWin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleWinOpportunity();
  };

  useEffect(() => {
    if (contract) {
      if (contract.lost_reason) {
        setSpanObject(
          <StyledSpan style={{ background: `#ff6961` }}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </StyledSpan>
        );
      } else if (
        contract.is_accepted &&
        !contract.is_rejected &&
        contract.is_signed &&
        contract.is_win
      ) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(102, 188, 70)` }}>
            <FontAwesomeIcon icon={faCheck} />{" "}
          </StyledSpan>
        );
      } else if (
        contract.is_accepted &&
        !contract.is_rejected &&
        (!contract.is_signed || !contract.is_win)
      ) {
        setSpanObject(
          <StyledSpan style={{ background: `#f5f249` }}>
            <FontAwesomeIcon icon={faCheck} />{" "}
          </StyledSpan>
        );
      } else if (contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `#f47631` }}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </StyledSpan>
        );
      } else if (!contract.is_accepted && !contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `lightgray` }}>
            <FontAwesomeIcon icon={faCircle} />{" "}
          </StyledSpan>
        );
        if (contract.send_for_approval === 1) {
          setSendObject(
            <StyledSendFontAwesome
              style={{ color: "#66bc46" }}
              icon={faPaperPlane}
            />
          );
        } else {
          setSendObject(
            <StyledSendFontAwesome
              style={{ color: "rgb(244, 118, 49)" }}
              icon={faPaperPlane}
            />
          );
        }
      }
    }
  }, [contract]);

  const handleOnOpenAnnexModal = (e) => {
    e.stopPropagation();
    setAnnexModalOpen(true);
  };

  const handleOnCloseAnnexModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setAnnexModalOpen(false);
  };

  const handleOnDataUpdate = (e) => {
    e.stopPropagation();
    setAnnexModalOpen(false);
    setDataUpdateModalOpen(true);
  };

  const handleOnLostOpportunity = (e) => {
    e.stopPropagation();
    setAnnexModalOpen(false);
    setLostAnnexModalOpen(true);
  };

  const handleOnCloseLostAnnexModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setLostAnnexModalOpen(false);
  };

  const closeDataUpdate = (e) => {
    if (e) {
      e.preventDefault();
    }
    setDataUpdateModalOpen(false);
  };

  const closeShowModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowModalOpen(false);
  };
  useEffect(() => {}, [showModalOpen]);

  return (
    <StyledWrapper>
      <StyledClicableWrapper onClick={handleOnOpenModal}>
        <StyledSpanWrapper>{spanObject}</StyledSpanWrapper>
        Umowa z dnia {moment(contract.created_at).format("DD.MM.YY HH.mm")}
        {sendObject}
        {opportunities.status === 2 ? (
          <>
            {contract.is_win === 1 && (
              <>
                <StyledSignedButton disabled={true}>
                  <StyledSignedFontAwesomeIcon icon={faPen} />
                </StyledSignedButton>

                <StyledSignedButton disabled={true}>
                  <StyledSignedFontAwesomeIcon icon={faTrophy} />
                </StyledSignedButton>
              </>
            )}
          </>
        ) : (
          <>
            {contract.is_accepted === 1 && contract.is_rejected === 0 && (
              <>
                {contract.is_signed === 1 ? (
                  <StyledSignedButton disabled={true}>
                    <StyledSignedFontAwesomeIcon icon={faPen} />
                  </StyledSignedButton>
                ) : (
                  <StyledSendSignedButton onClick={handleSendToSigned}>
                    <StyledSignedFontAwesomeIcon icon={faPen} />
                  </StyledSendSignedButton>
                )}
                {contract.is_win === 1 ? (
                  <StyledSignedButton disabled={true}>
                    <StyledSignedFontAwesomeIcon icon={faTrophy} />
                  </StyledSignedButton>
                ) : (
                  <>
                    {contract.is_signed === 1 ? (
                      <StyledSendSignedButton onClick={handleSendToWin}>
                        <StyledSignedFontAwesomeIcon icon={faTrophy} />
                      </StyledSendSignedButton>
                    ) : (
                      <StyledSendSignedButton disabled={true}>
                        <StyledSignedFontAwesomeIcon icon={faTrophy} />
                      </StyledSendSignedButton>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {isSended && (
          <StyledSendedWrapper>
            <StyledSendedImage src={loading} />
          </StyledSendedWrapper>
        )}
      </StyledClicableWrapper>
      <ShowContractDetails
        fetchData={fetchData}
        contract={contract}
        showModalOpen={showModalOpen}
        handleOnClose={closeShowModal}
        opportunities={opportunities}
        handleOnOpenAnnexModal={handleOnOpenAnnexModal}
      />
      <AnnexModal
        isModalOpen={annexModalOpen}
        handleOnClose={handleOnCloseAnnexModal}
        handleOnLostOpportunity={handleOnLostOpportunity}
      />
      <LoseOpportunityAnnex
        isModalOpen={lostAnnexModalOpen}
        handleOnClose={handleOnCloseLostAnnexModal}
        opportunity={opportunities}
        contract={contract}
        fetchData={fetchData}
      />
      <DataUpdateAnnex
        isModalOpen={dataUpdateModalOpen}
        handleOnClose={closeDataUpdate}
        fetchData={fetchData}
        opportunities={opportunities}
        contract={contract}
      />
    </StyledWrapper>
  );
};

const StyledClicableWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  text-align: left;
  margin-bottom: 4px;
  font-size: 14px;
  border: none;
  background: white;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
`;

const StyledSendedImage = styled.div``;

const StyledSendedWrapper = styled.div``;

const StyledSignedButton = styled.button`
  outline: none;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  color: rgb(102, 188, 70);
`;

const StyledSendSignedButton = styled.button`
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  color: #134771;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
  &:hover:not([disabled]) {
    background: #cadeee;
  }
`;

const StyledSignedFontAwesomeIcon = styled(FontAwesomeIcon)``;

const StyledSendFontAwesome = styled(FontAwesomeIcon)`
  margin-left: 5px;
`;

const StyledSpan = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

const StyledSpanWrapper = styled.div`
  width: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div``;

export default ContractItem;
