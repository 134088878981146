import React from "react";
import styled from "styled-components";
import Source from "./Pages/Source/Source";

const SourceSettings = () => {
  return (
    <>
      <ContentContainer>
        <Source />
      </ContentContainer>
    </>
  );
};

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
`;
export default SourceSettings;
