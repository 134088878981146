import moment from "moment";
import React from "react";
import styled from "styled-components";

const SaleOpportunityContact = ({ opportunities }) => {
  return (
    <StyledStepWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Notatki do zadań</StyledTitle>
      </StyledTitleWrapper>
      <StyledConfirmationWrapper>
        {/* {opportunities.make_contact === 0 ? "Kontakt nie nawiązany" : ""} */}
      </StyledConfirmationWrapper>
      {opportunities.contact_note?.length > 0 && (
        <StyledNoteWrapper>
          {opportunities.contact_note.map((note) => (
            <StyledNoteItem key={note.id}>
              <StyledNoteP>{note.note}</StyledNoteP>
              <StyledNoteInfo>
                Wykonany przez: {note.user.name} dnia:{" "}
                {moment(note.date).format("DD.MM.YY HH.mm")}
              </StyledNoteInfo>
            </StyledNoteItem>
          ))}
        </StyledNoteWrapper>
      )}
    </StyledStepWrapper>
  );
};

const StyledNoteInfo = styled.p`
  font-size: 14px;
`;
const StyledNoteP = styled.p`
  font-size: 16px;
`;

const StyledNoteItem = styled.div`
  padding: 5px 0;
`;

const StyledNoteWrapper = styled.div``;

const StyledConfirmationWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledStepWrapper = styled.div`
  padding: 10px;
  width: 100%;
  background: white;
  border-radius: 10px;
`;

export default SaleOpportunityContact;
