import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import DayComponent from "./DayComponent";
import moment from "moment";
import OpportunityItem from "./DayComponents/OpportunityItem";
import { useContext } from "react";
import { AssemblyContext } from "../../../../../store/AssemblyProvider";

const BusComponent = ({
  calendar,
  bus,
  handleOnDragEnter,
  handleOnDragStart,
  handleStretchAssemblyItem,
  handleStretchAssemblyItemTouch,
  setDuration,
  setBusInfo,
  setDayInfo,
  handleOnTouchEnd,
  handleOnTouchStart,
}) => {
  const { assemblyOpportunities } = useContext(AssemblyContext);
  const [opportunities, setOpportunities] = useState([]);
  const [opportunitiesFirstGroup, setOpportunitiesFirstGroup] = useState([]);
  const [opportunitiesSecondGroup, setOpportunitiesSecondGroup] = useState([]);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setOpportunities(
      assemblyOpportunities
        .filter((opportunity) => opportunity.bus_id === bus.id)
        .filter((opportunity) => {
          return (
            (moment(opportunity.assembly_start).isBetween(
              calendar[0],
              calendar[6],
              "day",
              "[]"
            ) &&
              moment(opportunity.assembly_end).isBetween(
                calendar[0],
                calendar[6],
                "day",
                "[]"
              )) ||
            (moment(opportunity.assembly_start).isBefore(calendar[0], "day") &&
              moment(opportunity.assembly_end).isBetween(
                calendar[0],
                calendar[6],
                "day",
                "[]"
              )) ||
            (moment(opportunity.assembly_start).isBetween(
              calendar[0],
              calendar[6],
              "day",
              "[]"
            ) &&
              moment(opportunity.assembly_end).isAfter(calendar[6], "day")) ||
            (moment(opportunity.assembly_start).isBefore(
              calendar[0],

              "day"
            ) &&
              moment(opportunity.assembly_end).isAfter(calendar[6], "day"))
          );
        })
    );
  }, [assemblyOpportunities, calendar]);

  useEffect(() => {
    let firstArray = opportunities;
    let secondArray = [];

    setOpportunitiesFirstGroup(firstArray);
    setOpportunitiesSecondGroup(secondArray);
  }, [opportunities]);

  return (
    <StyledBusWrapper>
      <StyledBusName>{bus.name}</StyledBusName>
      {calendar.map((day) => {
        return (
          <DayComponent
            key={day}
            day={day}
            bus={bus}
            setWidth={setWidth}
            handleOnDragEnter={handleOnDragEnter}
            handleOnDragStart={handleOnDragStart}
          />
        );
      })}

      {opportunitiesFirstGroup &&
        opportunitiesFirstGroup.map((opportunity, index) => (
          <OpportunityItem
            width={width}
            calendar={calendar}
            key={opportunity.id}
            index={0}
            setBusInfo={setBusInfo}
            setDayInfo={setDayInfo}
            opportunity={opportunity}
            handleOnDragStart={handleOnDragStart}
            handleOnTouchStart={handleOnTouchStart}
            handleStretchAssemblyItem={handleStretchAssemblyItem}
            handleStretchAssemblyItemTouch={handleStretchAssemblyItemTouch}
            setDuration={setDuration}
            handleOnTouchEnd={handleOnTouchEnd}
          ></OpportunityItem>
        ))}
      {opportunitiesSecondGroup &&
        opportunitiesSecondGroup.map((opportunity, index) => (
          <OpportunityItem
            width={width}
            calendar={calendar}
            key={opportunity.id}
            index={1}
            setBusInfo={setBusInfo}
            setDayInfo={setDayInfo}
            opportunity={opportunity}
            handleOnDragStart={handleOnDragStart}
            handleOnTouchStart={handleOnTouchStart}
            handleStretchAssemblyItem={handleStretchAssemblyItem}
            handleStretchAssemblyItemTouch={handleStretchAssemblyItemTouch}
            setDuration={setDuration}
            handleOnTouchEnd={handleOnTouchEnd}
          ></OpportunityItem>
        ))}
    </StyledBusWrapper>
  );
};

const StyledBusName = styled.div`
  padding: 10px;
  min-height: 40px;
  width: calc(100% / 8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBusWrapper = styled.div`
  display: flex;
  position: relative;
`;

export default BusComponent;
