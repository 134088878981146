import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../../helpers/request";
import HeatPumpApproveAside from "./Components/HeatPumpApproveAside/HeatPumpApproveAside";
import HeatPumpApproveList from "./Components/HeatPumpApproveList";

const HeatPumpContractApprove = () => {
  const [heatPumpContract, setHeatPumpContract] = useState([]);
  const [voivodeshipFilter, setVoivodeshipFilter] = useState(null);
  const [showApproveFilter, setShowApproveFilter] = useState(false);
  const [showRejectedFilter, setShowRejectedFilter] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const fetchContract = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(
        `/api/heat-pump-contract/contract-approve?page=${number}`,
        {
          params: {
            voivodeship: voivodeshipFilter,
            rejected: Number(showRejectedFilter),
            approve: Number(showApproveFilter),
          },
        }
      );
      if (status === 200) {
        setHeatPumpContract(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };

  const handleOnChangePage = (event) => {
    window.scrollTo(0, 0);
    setPageNumber(event.selected + 1);
    fetchContract(event.selected + 1);
  };

  return (
    <ContentWrapper>
      <HeatPumpApproveAside
        setVoivodeshipFilter={setVoivodeshipFilter}
        voivodeshipFilter={voivodeshipFilter}
        showApproveFilter={showApproveFilter}
        setShowApproveFilter={setShowApproveFilter}
        showRejectedFilter={showRejectedFilter}
        setShowRejectedFilter={setShowRejectedFilter}
      />

      <HeatPumpApproveList
        voivodeshipFilter={voivodeshipFilter}
        showApproveFilter={showApproveFilter}
        showRejectedFilter={showRejectedFilter}
        heatPumpContract={heatPumpContract}
        fetchContract={fetchContract}
        isLoading={isLoading}
        setPageNumber={setPageNumber}
        handleOnChangePage={handleOnChangePage}
        pageCount={pageCount}
        pageNumber={pageNumber}
      />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export default HeatPumpContractApprove;
