import React, { useState, useEffect, useContext } from "react";
import {
  faCheckCircle,
  faExclamation,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ToDoTag from "./Components/ToDoTag";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from "react-router";

import ChooseUser from "./Components/ChooseUser/ChooseUser";
import pl from "date-fns/locale/pl";
import moment from "moment";
import ChooseTime from "./Components/ChooseTime/ChooseTime";
import SameTimeAlert from "./Components/SameTimeAlert";
import { StoreContext } from "../../../../../../store/StoreProvider";
import request from "../../../../../../helpers/request";
import Modal from "../../../../../../components/Modal";
import TodoItem from "./Components/TodoItem";
registerLocale("pl", pl);
const validationSchema = yup.object({
  name: yup
    .string()
    .required("Nazwa jest wymagana")
    .min(3, "Nazwa musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwa nie może być dłuższa niż 255 znaków"),
  start_date: yup.date().nullable(),
  end_date: yup.date().nullable(),
  responsible: yup
    .string()
    .required("Osoba Odpowiedzialna za zadanie jest wymagana"),
});

const AddToDoItem = ({
  setIsModalOpen,
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
  opportunities_id,
}) => {
  const location = useLocation();
  const history = useHistory();

  const today = moment();
  const minutes = moment().minutes();
  const hour = moment().hour();
  const tomorrow = moment().clone().add(1, "day");

  const [tagsContent, setTagContent] = useState(false);
  const [taskType, setTaskType] = useState(null);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().endOf("day").toDate());
  const [chooseDayValue, setChooseDayValue] = useState("noDate");
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [todoItem, setTodoItem] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isSended, setIsSended] = useState(false);

  const {
    tags,
    setTags,
    users,
    setUsers,
    departments,
    setDepartments,
    teams,
    setTeams,
    user,
    todoList,
    setTodoList,
  } = useContext(StoreContext);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: {
      tasktype_id: 16,
      some_day: false,
      start_date: null,
      end_date: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      name: "",
      tasktype_id: 16,
      important: false,
      done: false,
      start_date: null,
      end_date: null,
      some_day: false,
      responsible: user.id,
      description: "",
    });
    setTaskType(16);
    setStartDate(null);
    setEndDate(null);
    setChooseDayValue("noDate");
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      if (isEditMode) {
        const { status } = await request.patch(`/api/todo/${editObject.id}`, {
          ...data,
        });

        if (status === 201) {
          fetchData();
          fetchTodo();
          resetInputValue();
          handleOnClose();
        }
      } else {
        const { status } = await request.post("/api/todo", { ...data });
        if (status === 200) {
          fetchData();
          fetchTodo();
          resetInputValue();
          handleOnClose();
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
      if (e.response?.status === 409) {
        handleOnClose();
        setIsAlertOpen(true);
      }
    }
    setIsSended(false);
  };

  const fetchTodo = async () => {
    try {
      const { status, data } = await request.get("/api/todo");
      if (status === 200) {
        await setTodoList(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmit = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (minutes <= 15) {
      setStartDate(today.hour(hour).minute(15).toDate());
      setEndDate(
        today.clone().hour(hour).minute(15).add(15, "minutes").toDate()
      );
    } else if (minutes <= 30) {
      setStartDate(today.clone().minute(30).toDate());
      setEndDate(
        today.clone().hour(hour).minute(30).add(15, "minutes").toDate()
      );
    } else if (minutes <= 45) {
      setStartDate(today.clone().hour(hour).minute(45).toDate());
      setEndDate(
        today.clone().hour(hour).minute(45).add(15, "minutes").toDate()
      );
    } else if (minutes <= 60) {
      setStartDate(today.clone().hour(hour).minute(0).add(1, "hour").toDate());
      setEndDate(
        today
          .clone()
          .hour(hour)
          .minute(0)
          .add(1, "hour")
          .add(15, "minutes")
          .toDate()
      );
    } else if (minutes === 0) {
      setStartDate(today.clone().hour(hour).minute(0).toDate());
      setEndDate(today.clone().hour(hour).minute(15).toDate());
    }
    setValue("some_day", false);
  }, []);

  useEffect(() => {
    if (editObject) {
      setValue("name", editObject.name);
      setValue("tasktype_id", editObject.taskType?.id);
      setTaskType(editObject.taskType?.id);
      setValue("start_date", new Date(editObject.start_date));
      setValue("some_day", editObject.some_day);

      setValue("done", editObject.done);
      setValue("important", editObject.important);
      setValue("opportunity_id", editObject.opportunity?.id);

      if (editObject.start_date && editObject.end_date) {
        if (
          moment(editObject.start_date).isSame(today, "day") &&
          moment(editObject.end_date).isSame(today, "day")
        ) {
          setChooseDayValue("today");
        } else if (
          moment(editObject.start_date).isSame(tomorrow, "day") &&
          moment(editObject.end_date).isSame(tomorrow, "day")
        ) {
          setChooseDayValue("tomorrow");
        } else {
          setChooseDayValue("chooseDay");
          setStartDate(moment(editObject.start_date).toDate());
          setEndDate(moment(editObject.end_date).toDate());
        }
      } else if (
        !editObject.start_date &&
        !editObject.end_date &&
        !editObject.some_day
      ) {
        setChooseDayValue("someDay");
      } else {
        setChooseDayValue("noDate");
      }
    }
  }, [editObject]);

  useEffect(() => {
    if (!isEditMode) {
      setValue("name", "");
      setValue("tasktype_id", 16);
      setValue("start_date", null);
      setValue("end_date", null);
      setValue("some_day", false);
      setStartDate(null);
      setEndDate(null);
      setValue("done", false);
      setValue("important", false);
      setValue("opportunity_id", opportunities_id);
      setTaskType(16);
    }
  }, [isEditMode, opportunities_id]);

  const fetchTag = async () => {
    try {
      const { data, status } = await request.get("/api/tasktype");
      if (status === 200) {
        setTags(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchUsers = async () => {
    try {
      const { data, status } = await request.get("/api/user");
      if (status === 200) {
        setUsers(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchDepartments = async () => {
    try {
      const { data, status } = await request.get("/api/department");
      if (status === 200) {
        setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchTeams = async () => {
    try {
      const { data, status } = await request.get("/api/team");
      if (status === 200) {
        setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (tags === undefined || tags.length === 0) {
      fetchTag();
    }
    if (users === undefined || users.length === 0) {
      fetchUsers();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDepartments();
    }
    if (teams === undefined || teams.length === 0) {
      fetchTeams();
    }
  }, []);

  useEffect(() => {
    if (editObject) {
      setTagContent(
        tags.map((tag, index) => (
          <li key={index}>
            <ToDoTag
              {...tag}
              setValue={setValue}
              tasktype_id={editObject.taskType?.id}
              taskType={taskType}
              setTaskType={setTaskType}
            />
          </li>
        ))
      );
    } else {
      setTagContent(
        tags.map((tag, index) => (
          <li key={index}>
            <ToDoTag
              {...tag}
              setValue={setValue}
              taskType={taskType}
              setTaskType={setTaskType}
            />
          </li>
        ))
      );
    }
  }, [tags, editObject, taskType]);
  useEffect(() => {
    setValue("start_date", startDate);
    setValue("end_date", endDate);
  }, [startDate, endDate]);

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (todoList && userId && startDate) {
      setTodoItem(
        todoList
          .filter(
            (todo) =>
              todo.responsible.id === userId &&
              moment(todo.start_date).isSame(startDate, "day")
          )
          .sort(
            (a, b) =>
              moment(a.start_date).valueOf() - moment(b.start_date).valueOf()
          )
      );
    } else if (todoList && userId && !startDate) {
      setTodoItem(
        todoList
          .filter(
            (todo) =>
              todo.responsible.id === userId &&
              moment(todo.start_date).isSame(moment(), "day")
          )
          .sort(
            (a, b) =>
              moment(a.start_date).valueOf() - moment(b.start_date).valueOf()
          )
      );
    }
  }, [todoList, userId, startDate]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledFormWrapper>
        <StyledForm
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        >
          <StyledAddToDoHeader>
            <StyledTimesButton onClick={handleOnClose} type="button">
              <FontAwesomeIcon icon={faTimes} />
            </StyledTimesButton>
          </StyledAddToDoHeader>
          <StyledName>
            <div className="todo-input-group">
              <label htmlFor="todoNameInput">Nazwa zadania</label>
              <input type="text" id="todoNameInput" {...register("name")} />
            </div>

            <input type="hidden" {...register("tasktype_id")} />

            <label htmlFor="important" className="checkbox-label">
              <Controller
                name="important"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <input
                    type="checkbox"
                    {...field}
                    className="important"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />

              <FontAwesomeIcon icon={faExclamation} />
            </label>
            <label htmlFor="todo-done" className="checkbox-label">
              <Controller
                name="done"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <input
                    type="checkbox"
                    className="todo-done"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />

              <FontAwesomeIcon icon={faCheckCircle} />
            </label>
          </StyledName>
          {errors?.name && <span className="error">{errors.name.message}</span>}
          <StyledTags>
            <StyledTagsList>{tagsContent}</StyledTagsList>
          </StyledTags>
          <StyledInputGroupWrapper>
            <input
              type="hidden"
              {...register("some_day")}
              defaultValue={false}
            />
            <ChooseUser
              departments={departments}
              teams={teams}
              setUserId={setUserId}
              setValue={setValue}
              setValueTitle="responsible"
              user={user}
              isEditMode={isEditMode}
              editUser={editObject?.responsible}
            />
            <input type="hidden" {...register("responsible")} />
            {errors?.responsible && (
              <span className="error">{errors.responsible.message}</span>
            )}
          </StyledInputGroupWrapper>
          <StyledChooseDayWrapper>
            <ChooseTime
              today={today}
              errors={errors}
              startDate={startDate}
              setStartDate={setStartDate}
              register={register}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </StyledChooseDayWrapper>
          <input type="hidden" {...register("opportunity_id")} />
          <StyledDescription>
            <div className="input-group">
              <label htmlFor="description">Dodaj opis:</label>
              <input type="text" {...register("description")} />
            </div>
          </StyledDescription>
          {isEditMode ? (
            <div className="buttons">
              <button type="button" className="close" onClick={handleOnClose}>
                Anuluj
              </button>
              <button
                type="button"
                className="add"
                onClick={handleSubmit(handleOnSubmit)}
                disabled={isSended}
              >
                Zapisz
              </button>
            </div>
          ) : (
            <div className="buttons">
              <button type="button" className="close" onClick={handleOnClose}>
                Anuluj
              </button>

              <button
                type="button"
                className="add"
                onClick={handleSubmit(handleOnSubmit)}
                disabled={isSended}
              >
                Dodaj
              </button>
            </div>
          )}
        </StyledForm>
        <StyledDayWrapper>
          {todoItem.map((todo) => (
            <TodoItem todo={todo} key={todo.id} />
          ))}
        </StyledDayWrapper>
      </StyledFormWrapper>
      <SameTimeAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
      />
    </Modal>
  );
};

const StyledDayWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;
const StyledFormWrapper = styled.div`
  display: flex;
`;

const StyledChooseDayWrapper = styled.div`
  margin: 10px 0;
`;

const StyledInputGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const StyledTimesButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 1rem;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledAddToDoHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px 10px 0 0;
`;

const StyledForm = styled.form`
  max-width: 400px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.addNext {
        background: transparent;
        color: #134771;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }
      &.addNext:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledTagsList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const StyledTags = styled.div`
  margin-top: 20px;
`;

const StyledName = styled.div`
  margin-top: 25px;
  display: flex;
  & .todo-input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 12px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: calc(100% - 40px);
      outline: none;
      font-size: 14px;
    }
  }
  label.checkbox-label {
    position: relative;
    height: 20px;
    width: 20px;
  }
  & input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    padding: 10px;
    transition: 0.3s all ease;
    cursor: pointer;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  & input.important:checked + svg {
    color: #f47631;
  }
  & input.todo-done:checked + svg {
    color: #66bc46;
  }
`;

const StyledDescription = styled.div`
  margin-top: 20px;
  width: 100%;
  .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 12px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
  }
`;
export default AddToDoItem;
