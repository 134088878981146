import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const UserButton = ({ user, chooseUser, setChooseUser }) => {
  const [isChoose, setIsChoose] = useState(false);

  useEffect(() => {
    if (user.id === chooseUser) {
      setIsChoose(true);
    } else {
      setIsChoose(false);
    }
  }, [user, chooseUser]);

  const handleOnChoose = (e) => {
    e.preventDefault();
    if (chooseUser === user.id) {
      setChooseUser(null);
    } else {
      setChooseUser(user.id);
    }
  };

  return (
    <StyledUserButton
      style={
        isChoose ? { background: "#cadeee" } : { background: "transparent" }
      }
      key={user.id}
      onClick={handleOnChoose}
    >
      <StyledUserIcon icon={faUser} /> {user.name}
    </StyledUserButton>
  );
};

const StyledUserIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledUserButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 4px 10px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export default UserButton;
