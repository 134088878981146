import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CompanyInfo = ({ company, handleOnClick }) => {
  const [adressString, setAdressString] = useState(null);

  useEffect(() => {
    if (
      company.city &&
      company.building_number &&
      company.post_code &&
      company.post &&
      company.street
    ) {
      setAdressString(
        `ul. ${company?.street} ${company?.building_number}, ${company?.city} ${company?.post_code} ${company?.post}`
      );
    } else if (
      company?.city &&
      company?.building_number &&
      company?.post &&
      company?.post_code
    ) {
      setAdressString(
        `${company?.city} ${company?.building_number}, ${company?.post_code} ${company?.post} `
      );
    } else if (
      company?.city &&
      company?.street &&
      company?.post &&
      company?.post_code
    ) {
      setAdressString(
        `ul. ${company?.street} ${company?.city}, ${company?.post_code} ${company?.post}`
      );
    } else if (
      company.city &&
      company.building_number &&
      company.post_code &&
      company.street
    ) {
      setAdressString(
        `ul. ${company?.street} ${company?.building_number}, ${company?.post_code} ${company?.city}`
      );
    } else if (
      company?.city &&
      company?.building_number &&
      company?.post_code
    ) {
      setAdressString(
        `${company?.building_number}, ${company?.post_code} ${company?.city} `
      );
    } else if (company?.city && company?.street && company?.post_code) {
      setAdressString(
        `ul. ${company?.street}, ${company?.post_code} ${company?.city}`
      );
    } else {
      setAdressString(" Brak adresu / Adres nieprawidłowy");
    }
  }, [
    company,
    company?.post_code,
    company?.street,
    company?.city,
    company?.building_number,
  ]);
  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledCompanyInfo>
          <StyledNameWrapper>
            <StyledCompanyName>{company.name}</StyledCompanyName>
            {/* <StyledEditButton>Edytuj</StyledEditButton> */}
          </StyledNameWrapper>
          <StyledCompanyUsers>
            {company.workers?.length > 0 && (
              <>
                <FontAwesomeIcon icon={faUsers} />
                {company.workers.map((worker) => {
                  return (
                    <p key={worker.id}>
                      <Link
                        to={`/dashboard/contacts/clients/details/${worker.id}`}
                      >
                        {worker.name}
                      </Link>
                    </p>
                  );
                })}
              </>
            )}
            {/* <StyledAddClientButton>Dodaj Osobę</StyledAddClientButton> */}
          </StyledCompanyUsers>
        </StyledCompanyInfo>
        <StyledCompanyDescription>
          {company.phone && (
            <StyledPhoneWrapper>
              <FontAwesomeIcon icon={faPhoneAlt} />
              <StyledPhoneLink href={`tel:${company.phone}`}>
                {company.phone}
              </StyledPhoneLink>
            </StyledPhoneWrapper>
          )}
          {company.email && (
            <StyledEmailWrapper>
              <FontAwesomeIcon icon={faEnvelope} />
              <StyledEmailLink href={`email:${company.email}`}>
                {company.email}
              </StyledEmailLink>
            </StyledEmailWrapper>
          )}

          {adressString && (
            <StyledAdressWrapper>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <StyledAdressP> {adressString}</StyledAdressP>
            </StyledAdressWrapper>
          )}

          {company.voivodeship && (
            <StyledAdressWrapper>
              <FontAwesomeIcon icon={faBuilding} />
              <StyledAdressP>
                Województwo: {company.voivodeship.name}
              </StyledAdressP>
            </StyledAdressWrapper>
          )}
          {company.maintainer?.department ? (
            <StyledDepartmentWrapper>
              <FontAwesomeIcon icon={faUser} />{" "}
              {company.maintainer?.department.name}
            </StyledDepartmentWrapper>
          ) : (
            <StyledDepartmentWrapper>
              <FontAwesomeIcon icon={faUser} />{" "}
              {company.maintainer?.role?.id === 1 &&
                `Zarząd - ${company.maintainer?.name}`}
              {company.maintainer?.role?.id === 3 &&
                `Biuro - ${company.maintainer?.name}`}
            </StyledDepartmentWrapper>
          )}
          {company.source && (
            <StyledSourceWrapper>
              <StyledSourcaLabel>Źródło pozyskania:</StyledSourcaLabel>
              <StyledSourceP>{company.source?.name}</StyledSourceP>
            </StyledSourceWrapper>
          )}
          {company.heating_type && (
            <StyledSourceWrapper>
              <StyledSourcaLabel>System ogrzewania:</StyledSourcaLabel>
              <StyledSourceP>{company.heating_type?.name}</StyledSourceP>
            </StyledSourceWrapper>
          )}
          {company.description && (
            <StyledSourceWrapper>
              <StyledSourcaLabel>Opis:</StyledSourcaLabel>
              <StyledSourceP>{company.description}</StyledSourceP>
            </StyledSourceWrapper>
          )}
        </StyledCompanyDescription>
        <StyledButtonWrapper>
          <StyledEditButton onClick={handleOnClick}>Edytuj</StyledEditButton>
        </StyledButtonWrapper>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledAdressP = styled.p`
  margin-left: 5px;
`;

const StyledAdressWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;
const StyledContentWrapper = styled.div`
  background: white;
  border-radius: 10px;
`;
const StyledSourcaLabel = styled.p`
  font-size: 14px;
`;
const StyledSourceP = styled.p`
  color: black;
`;

const StyledSourceWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;

const StyledPhoneWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledEmailWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledDepartmentWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledPhoneLink = styled.a`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledEmailLink = styled.a`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledCompanyUsers = styled.div`
  color: #134771;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  svg {
    margin-right: 5px;
  }
  a {
    color: #134771;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
const StyledCompanyInfo = styled.div`
  padding: 20px 10px;
`;
const StyledCompanyDescription = styled.div`
  padding: 0 10px;
`;
// const StyledEditButton = styled.button`
//   cursor: pointer;
//   background: transparent;
//   padding: 5px 10px;
//   border: none;
//   outline: none;
//   font-size: 14px;
//   color: #2d96db;
//   &:hover {
//     color: #0167a7;
//     text-decoration: underline;
//   }
// `;

const StyledNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledWrapper = styled.div`
  grid-area: info;
`;

const StyledCompanyName = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export default CompanyInfo;
