import React, { useState } from "react";
import styled from "styled-components";
import OfferList from "./Components/OfferList";
import HeatPumpOfferDetails from "./Components/HeatPumpOfferDetails";

import OfferHeatPump from "./MakeOffer/OfferHeatPump/OfferHeatPump";

const HeatPumpMakeOffer = ({ opportunities, fetchData }) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [isHeatPumpModalOpen, setIsHeatPumpModalOpen] = useState(false);
  const [offerObject, setOfferObject] = useState(null);
  const [isSimilarOfferOpen, setIsSimilarOfferOpen] = useState(false);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOpenHeatPumpModal = () => {
    setIsHeatPumpModalOpen(true);
  };

  const handleCreateSimilarOffer = () => {
    setIsShowModalOpen(false);
    setIsSimilarOfferOpen(true);
  };
  const handleCloseHeatPumpModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsHeatPumpModalOpen(false);
  };

  return (
    <StyledOfferWrapper>
      <StyledHeaderText>Oferty</StyledHeaderText>
      <OfferList
        offers={opportunities?.heat_pump_offer}
        setIsShowModalOpen={setIsShowModalOpen}
        setOfferObject={setOfferObject}
      />

      {opportunities?.status === 1 && (
        <StyledMakeOfferButtonWrapper>
          <StyledMakeOfferButton onClick={handleOpenHeatPumpModal}>
            Generuj oferte
          </StyledMakeOfferButton>
        </StyledMakeOfferButtonWrapper>
      )}

      <HeatPumpOfferDetails
        opportunities={opportunities}
        fetchData={fetchData}
        isShowModalOpen={isShowModalOpen}
        offerObject={offerObject}
        handleOnClose={handleOnClose}
        handleCreateSimilarOffer={handleCreateSimilarOffer}
      />
      <OfferHeatPump
        isModalOpen={isHeatPumpModalOpen}
        handleOnClose={handleCloseHeatPumpModal}
        fetchData={fetchData}
        opportunities={opportunities}
      />
    </StyledOfferWrapper>
  );
};
const StyledMakeOfferButton = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;
const StyledMakeOfferButtonWrapper = styled.div`
  padding: 10px;
`;
const StyledOfferWrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #13477140;
`;
const StyledHeaderText = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgb(244, 118, 49);
  font-weight: bold;
`;

export default HeatPumpMakeOffer;
