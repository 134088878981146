import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ClientPopOverCopmonent from "./ClientPopOverCopmonent";

const ClientComponentItem = ({ client }) => {
  const [hover, setHover] = useState(false);

  const hoverRef = useRef();
  let timer;
  const timerOuter = useRef(null);

  const handleOnHover = (e) => {
    timer = setTimeout(() => {
      setHover(true);
    }, 500);

    window.addEventListener("mousemove", handleOnMouseMove, true);
  };

  const handleOnSetTimeOut = () => {
    setHover(false);
    window.removeEventListener("mousemove", handleOnMouseMove, true);
  };
  const handleOnMouseLeave = (e) => {
    clearTimeout(timer);
    timerOuter.current = setTimeout(handleOnSetTimeOut, 500);
  };

  const handleOnMouseMove = (e) => {
    if (hoverRef?.current?.contains(e.target)) {
      clearTimeout(timerOuter.current);
      window.removeEventListener("mousemove", handleOnMouseMove, true);
    }
  };

  return (
    <StyledWrapper>
      <StyledUserIconWrapper>
        <FontAwesomeIcon icon={faUser} />
      </StyledUserIconWrapper>
      <StyledDetailsWrapper>
        <StyledNameLink
          onMouseEnter={handleOnHover}
          onMouseLeave={handleOnMouseLeave}
          to={`/dashboard/contacts/clients/details/${client.id}`}
        >
          {client.first_name} {client.last_name}
        </StyledNameLink>
        {client.company && (
          <StyledCompanyWrapper>
            <CompanyTitle>Firma:</CompanyTitle>
            <StyledCompanyName title={client.company.name}>
              {client.company.name}
            </StyledCompanyName>
          </StyledCompanyWrapper>
        )}
      </StyledDetailsWrapper>
      <ClientPopOverCopmonent
        client={client}
        hover={hover}
        setHover={setHover}
        hoverRef={hoverRef}
      />
    </StyledWrapper>
  );
};
const CompanyTitle = styled.p`
  margin-right: 5px;
`;
const StyledCompanyName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledDetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
`;
const StyledCompanyWrapper = styled.div`
  display: flex;
  min-width: 0;
`;
const StyledUserIconWrapper = styled.div`
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cadeee;
  color: #134771;
  font-size: 24px;
  border-radius: 50%;
  flex-shrink: 0;
`;
const StyledNameLink = styled(Link)`
  color: #134771;
  font-size: 18px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledWrapper = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
  min-width: 0;
`;

export default ClientComponentItem;
