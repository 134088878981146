import React from "react";
import styled from "styled-components";

const SummaryPriceItem = ({ price }) => {
  return (
    <StyledWrapper>
      <p>
        {" "}
        {new Intl.NumberFormat("pl-PL", {
          style: "currency",
          currency: "pln",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(price)}
      </p>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;

export default SummaryPriceItem;
