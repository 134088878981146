import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import AddToDoItem from "../AddToDo/AddToDoItem";

import ListItem from "./ListItem";
import TodoSidebarNav from "./TodoSidebarNav";
import moment from "moment";

const TodoList = ({ todos, fetchData, lead_id, lead }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const [sortedTodos, setSortedTodos] = useState([]);

  let history = useHistory();

  const dateFormatter = (arg, ascending) => {
    const date = moment(arg);

    if (!date.isValid()) {
      return ascending ? Infinity : -Infinity;
    }
    return parseInt(date.format("x"), 10);
  };

  useEffect(() => {
    setSortedTodos(
      todos.sort((a, b) => {
        a = dateFormatter(a.start_date, true);
        b = dateFormatter(b.start_date, true);
        return a < b ? -1 : a > b ? 1 : 0;
      })
    );
  }, [todos]);

  const handleOnRedirect = () => {
    history.push("/dashboard/todo");
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(null);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleOnClickEdit = (editObject) => {
    setEditObject(editObject);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  return (
    <StyledTodoList>
      <TodoSidebarNav />
      <div className="list">
        {sortedTodos.map((todo) => (
          <ListItem
            todo={todo}
            key={todo.id}
            lead={lead}
            fetchData={fetchData}
            handleOnClickEdit={handleOnClickEdit}
          />
        ))}
      </div>
      <div className="options">
        <StyledButton onClick={handleOpenModal}>Dodaj</StyledButton>
        <StyledButton onClick={handleOnRedirect}>Przejdź do zadań</StyledButton>
      </div>
      <AddToDoItem
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isEditMode={isEditMode}
        fetchData={fetchData}
        handleOnClose={handleOnClose}
        editObject={editObject}
        lead_id={lead_id}
      />
    </StyledTodoList>
  );
};

const StyledTodoList = styled.div`
  background: white;
  width: 100%;
  border-radius: 10px;

  .list {
    min-height: 200px;
    padding: 10px 0;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .options {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
`;

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;
export default TodoList;
