import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../store/StoreProvider";
import CheckDone from "./CheckDone/CheckDone";
import CollapseTyp from "./CollapseTyp/CollapseTyp";
import CollapseUser from "./CollapseUser/CollapseUser";
import CollapseYourWork from "./CollapseYourWork/CollapseYourWork";

const TodoAside = ({
  setTaskTypeFilter,
  setYourTaskFilter,
  setUserFilter,
  userFilter,
  checkDoneFilter,
  setCheckDoneFilter,
  taskTypeFilter,
  yourTaskFilter,
}) => {
  const { user } = useContext(StoreContext);
  return (
    <StyledAside>
      <CheckDone
        checkDoneFilter={checkDoneFilter}
        setCheckDoneFilter={setCheckDoneFilter}
      />

      {(user?.role?.id === 1 ||
        user?.role?.id === 2 ||
        user.role?.id === 3) && (
        <CollapseUser
          title="Osoby"
          userFilter={userFilter}
          setUserFilter={setUserFilter}
        />
      )}

      <CollapseYourWork
        title="Okres czasu"
        setYourTaskFilter={setYourTaskFilter}
        yourTaskFilter={yourTaskFilter}
      />
      <CollapseTyp
        title="Typ"
        setTaskTypeFilter={setTaskTypeFilter}
        taskTypeFilter={taskTypeFilter}
      />
    </StyledAside>
  );
};

const StyledAside = styled.div`
  width: 220px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default TodoAside;
