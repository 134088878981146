import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import styled from "styled-components";
import Modal from "../../../../components/Modal";
import request from "../../../../helpers/request";
import EditFileItem from "./EditFileItem";
import loading from "../../../../img/48x48.gif";
import AddFileModal from "./Components/AddFileModal/AddFileModal";

const OpportunitiesEditNote = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  note,
  setIsModalOpen,
}) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [isSended, setIsSended] = useState(false);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const TextAreaRef = useRef(null);
  const { ref, ...rest } = register("text");

  const resetInputValue = () => {
    reset({
      text: "",
    });
  };

  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.patch(`/api/note/${note.id}`, {
        ...data,
      });
      if (status === 201) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      console.warn(e);
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (note && Object.keys(note).length > 0) {
      setValue("text", note.text);
    }
  }, []);
  useEffect(() => {
    if (TextAreaRef) {
      handleSetHeight(TextAreaRef);
    }
  }, [TextAreaRef, isModalOpen]);

  const handleSetHeight = (e) => {
    if (e?.current) {
      e.current.style.height = "inherit";

      // Get the computed styles for the element
      const computed = window.getComputedStyle(e.current);

      // Calculate the height
      const height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        e.current.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom"), 10) +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      e.current.style.height = `${height}px`;
    } else {
      e.target.style.height = "inherit";

      // Get the computed styles for the element
      const computed = window.getComputedStyle(e.target);

      // Calculate the height
      const height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        e.target.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom"), 10) +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      e.target.style.height = `${height}px`;
    }
  };

  const handleOnCloseFileAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAddFileModalOpen(false);
    setIsModalOpen(true);
  };

  const handleOnOpenAddFileModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setIsAddFileModalOpen(true);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledTextArea
          id="TextArea"
          {...rest}
          name="text"
          ref={(e) => {
            ref(e);
            TextAreaRef.current = e; // you can still assign to ref
          }}
          onKeyUp={handleSetHeight}
        ></StyledTextArea>

        {note?.files && (
          <StyledFileWrapper>
            {note.files.map((file) => (
              <EditFileItem
                key={file.id}
                fileItem={file}
                fetchData={fetchData}
                setIsModalOpen={setIsModalOpen}
              />
            ))}
          </StyledFileWrapper>
        )}

        <StyledButtonWrapper onClick={handleOnOpenAddFileModal}>
          <StyledAddButton> Dodaj zdjęcie</StyledAddButton>
        </StyledButtonWrapper>
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Zapisz
          </button>
        </div>
        {isSended && (
          <StyledSendNoteWrapper>
            <StyledSendNoteInnerWrapper>
              Przesyłanie...
              <StyledLoadingImage src={loading} alt="loading" />
            </StyledSendNoteInnerWrapper>
          </StyledSendNoteWrapper>
        )}
      </StyledForm>
      <AddFileModal
        isModalOpen={isAddFileModalOpen}
        setIsModalOpen={setIsAddFileModalOpen}
        setIsEditModalOpen={setIsModalOpen}
        handleOnClose={handleOnCloseFileAddModal}
        fetchData={fetchData}
        note={note}
        isSended={isSended}
        setIsSended={setIsSended}
      />
    </Modal>
  );
};

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
`;

const StyledAddButton = styled.button`
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledFileWrapper = styled.div`
  display: grid;
  padding-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 120px));
`;
const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 16px;
  padding: 10px;
  overflow-y: hidden;
  border-radius: 10px;
  outline: none;
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

const StyledForm = styled.form`
  max-width: 400px;
  min-width: 360px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
        text-decoration: underline;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

export default OpportunitiesEditNote;
