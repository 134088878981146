import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const SettingAside = () => {
  return (
    <StyledAsideNav>
      <ul>
        {/* <StyledAsideNavItem>
          <NavLink exact to="/settings/" activeClassName="active">
            Dane Konta
          </NavLink>
        </StyledAsideNavItem> */}
        <StyledAsideNavItem>
          <NavLink to="/settings/users" activeClassName="active">
            Użytkownicy
          </NavLink>
        </StyledAsideNavItem>
        {/* <StyledAsideNavItem>
          <NavLink to="/settings/permission" activeClassName="active">
            Uprawnienia
          </NavLink>
        </StyledAsideNavItem> */}
        {/* <StyledAsideNavItem>
          <NavLink to="/settings/contact" activeClassName="active">
            Kontakty
          </NavLink>
        </StyledAsideNavItem> */}
        <StyledAsideNavItem>
          <NavLink to="/settings/deal" activeClassName="active">
            Szanse sprzedaży
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/calendar" activeClassName="active">
            Kalendarz i zadania
          </NavLink>
        </StyledAsideNavItem>
        {/* <StyledAsideNavItem>
          <NavLink to="/settings/forms" activeClassName="active">
            Formularze
          </NavLink>
        </StyledAsideNavItem> */}
        {/* <StyledAsideNavItem>
          <NavLink to="/settings/relation" activeClassName="active">
            Powiązania
          </NavLink>
        </StyledAsideNavItem> */}
        {/* <StyledAsideNavItem>
          <NavLink to="/settings/tags" activeClassName="active">
            Tagi
          </NavLink>
        </StyledAsideNavItem> */}
        {/* <StyledAsideNavItem>
          <NavLink to="/settings/group" activeClassName="active">
            Grupy
          </NavLink>
        </StyledAsideNavItem> */}
        <StyledAsideNavItem>
          <NavLink to="/settings/offer" activeClassName="active">
            Oferty PV
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/heat-pump-offer" activeClassName="active">
            Oferty PC
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/source" activeClassName="active">
            Źródła pozyskania
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/assembly" activeClassName="active">
            Proces montażu
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/charger" activeClassName="active">
            Ładowarki
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/energy-storage" activeClassName="active">
            Magazyny energii
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/labour" activeClassName="active">
            Robocizna
          </NavLink>
        </StyledAsideNavItem>
        <StyledAsideNavItem>
          <NavLink to="/settings/transport" activeClassName="active">
            Transport
          </NavLink>
        </StyledAsideNavItem>
      </ul>
    </StyledAsideNav>
  );
};

const StyledAsideNav = styled.div`
  width: 100%;
  padding: 20px 0;
  background: white;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const StyledAsideNavItem = styled.li`
  list-style: none;
  a {
    display: block;
    padding: 10px 15px;
    color: #333;
    text-decoration: none;
  }
  a:hover {
    color: #222;
    font-weight: 800;
    background: #ddd;
  }
  a.active {
    color: #222;
    font-weight: 800;
    background: #ddd;
  }
`;
export default SettingAside;
