import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Header = ({
  value,
  setValue,
  handleOnDayOpen,
  handleOnYearOpen,
  handleOnMonthOpen,
  isDayOpen,
  isMonthOpen,
  isYearOpen,
}) => {
  const currentMonthName = () => {
    return value.format("MMMM");
  };

  const currentYear = () => {
    return value.format("YYYY");
  };

  const currentDay = () => {
    return value.format("DD");
  };

  const prevMonth = () => {
    return value.clone().subtract(1, "month");
  };
  const nextMonth = () => {
    return value.clone().add(1, "month");
  };

  const prevDay = () => {
    return value.clone().subtract(1, "day");
  };
  const nextDay = () => {
    return value.clone().add(1, "day");
  };

  const prevYear = () => {
    return value.clone().subtract(1, "year");
  };
  const nextYear = () => {
    return value.clone().add(1, "year");
  };

  return (
    <StyledCalendarHeader>
      {isYearOpen && (
        <StyledHeaderNavigationDiv>
          <StyledLeftButton onClick={() => setValue(prevYear())}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </StyledLeftButton>
          <StyledMonthName>{currentYear()}</StyledMonthName>
          <StyledRightButton onClick={() => setValue(nextYear())}>
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledRightButton>
        </StyledHeaderNavigationDiv>
      )}

      {isMonthOpen && (
        <StyledHeaderNavigationDiv>
          <StyledLeftButton onClick={() => setValue(prevMonth())}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </StyledLeftButton>
          <StyledMonthName>
            {currentMonthName()} {currentYear()}
          </StyledMonthName>
          <StyledRightButton onClick={() => setValue(nextMonth())}>
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledRightButton>
        </StyledHeaderNavigationDiv>
      )}

      {isDayOpen && (
        <StyledHeaderNavigationDiv>
          <StyledLeftButton onClick={() => setValue(prevDay())}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </StyledLeftButton>
          <StyledMonthName>
            {currentDay()} {currentMonthName()} {currentYear()}
          </StyledMonthName>
          <StyledRightButton onClick={() => setValue(nextDay())}>
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledRightButton>
        </StyledHeaderNavigationDiv>
      )}

      <StyledButtonNavigationWrapper>
        <StyledYearButton onClick={handleOnYearOpen}>Rok</StyledYearButton>
        <StyledMonthButton onClick={handleOnMonthOpen}>
          Miesiąc
        </StyledMonthButton>
        <StyledDayButton onClick={handleOnDayOpen}>Dzień</StyledDayButton>
      </StyledButtonNavigationWrapper>
    </StyledCalendarHeader>
  );
};

const StyledYearButton = styled.button`
  border: none;
  border-right: 1px solid rgba(188, 188, 188, 0.4);

  background: white;
  outline: none;
  padding: 10px 10px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;
const StyledMonthButton = styled.button`
  border: none;

  background: white;
  outline: none;
  padding: 10px 10px;
  cursor: pointer;
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;
const StyledDayButton = styled.button`
  background: white;
  border: none;
  border-left: 1px solid rgba(188, 188, 188, 0.4);
  outline: none;
  padding: 10px 10px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

const StyledButtonNavigationWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  border-radius: 10px;
`;
const StyledMonthName = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: white;
`;
const StyledRightButton = styled.button`
  padding: 10px;
  background: white;
  border-radius: 10px;
  border: none;
  margin-left: 1px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;
const StyledLeftButton = styled.button`
  padding: 10px;
  background: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 1px;

  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;
const StyledHeaderNavigationDiv = styled.div`
  display: flex;
`;
const StyledCalendarHeader = styled.div`
  padding: 10px;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Header;
