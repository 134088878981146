import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import styled from "styled-components";
import DateRangeFilter from "./Components/DateRangeFilter";
const OpportunitiesFilter = ({
  searchInput,
  setSearchInput,
  setSearchValue,
  searchStartDate,
  setSearchStartDate,
  searchEndDate,
  setSearchEndDate,
  setDateFilter,
}) => {
  const inputRef = useRef(null);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleOnClickClear = () => {
    setSearchValue(null);
    setSearchInput("");
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      if (searchInput.trim().length > 0) {
        setSearchValue(searchInput);
      } else {
        setSearchValue(null);
      }
    }
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <StyledFilter>
      <StyledFilterInfo>
        <StyledSearchInputWrapper>
          <StyledSearchIconWrapper onClick={handleFocus}>
            <StyledSearchIcon icon={faSearch} />
          </StyledSearchIconWrapper>
          <StyledSearchInput
            ref={inputRef}
            type="text"
            onInput={handleSearchInput}
            value={searchInput}
            onKeyPress={handleOnKeyPress}
          />
          <StyledDeleteButtonWrapper>
            {searchInput?.trim().length > 0 ? (
              <StyledDeleteButton onClick={handleOnClickClear}>
                <StyledTimesIcon icon={faTimes} />
              </StyledDeleteButton>
            ) : (
              <StyledDeleteButton
                onClick={handleOnClickClear}
                disabled
                style={{ cursor: "initial" }}
              >
                <StyledTimesIcon
                  icon={faTimes}
                  style={{ color: "transparent" }}
                />
              </StyledDeleteButton>
            )}
          </StyledDeleteButtonWrapper>
        </StyledSearchInputWrapper>

        <StyledDataRangeWrapper>
          <DateRangeFilter
            searchStartDate={searchStartDate}
            setSearchStartDate={setSearchStartDate}
            searchEndDate={searchEndDate}
            setSearchEndDate={setSearchEndDate}
            setDateFilter={setDateFilter}
          />
        </StyledDataRangeWrapper>

        {/* <StyledFilterButtonWrapper>

        <StyledFilterButton onClick={handleOnShowFilters}>
          <StyledFontAwesomeIcon icon={faSortAmountDown} />
          Filtruj
        </StyledFilterButton>
</StyledFilterButtonWrapper> */}
      </StyledFilterInfo>
    </StyledFilter>
  );
};

const StyledDataRangeWrapper = styled.div``;

// const StyledFilterButtonWrapper = styled.div``

const StyledDeleteButtonWrapper = styled.div`
  border: 1px solid #134771;
  border-left: none;
  border-radius: 0 10px 10px 0;
`;

const StyledTimesIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
`;

const StyledDeleteButton = styled.button`
  border: none;
  outline: none;
  color: #134771;
  padding: 10px;
  border-radius: 0 10px 10px 0;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #053257;
  }
`;

const StyledSearchIconWrapper = styled.button`
  background: white;
  border: 1px solid #134771;
  border-right: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px 0 0 10px;
`;

const StyledSearchIcon = styled(FontAwesomeIcon)`
  color: #134771;
`;

const StyledSearchInput = styled.input`
  padding: 10px 0 10px 10px;
  border: 1px solid #134771;
  color: #134771;
  font-family: "ubuntu", sans-serif;
  font-size: 14px;
  border-right: none;
  border-left: none;
  outline: none;
`;

const StyledSearchInputWrapper = styled.div`
  display: flex;
`;

const StyledFilter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #134771;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px; ;
`;
const StyledFilterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 644px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;
export default OpportunitiesFilter;
