import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import PaymentMonitoringModal from "./PaymentMonitoringModal";
import SelfDepositModal from "./Components/SeflDepositModal";
import FirstTrancheModal from "./Components/FirstTrancheModal";
import SecondTrancheModal from "./Components/SecondTrancheModal";
import ThirdTrancheModal from "./Components/ThirdTrancheModal";
import CreditSettledModal from "./Components/CreditSettledModal";
import LoanRunningModal from "./Components/LoanRunningModal";
import FullPaymentModal from "./Components/FullPaymentModal";
import CreditSettledWithDepositModal from "./Components/CreditSettledWithDepositModal";

const PaymentMonitoring = ({ opportunities, fetchData }) => {
  const { user } = useContext(StoreContext);
  const [isSended, setIsSended] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [isFirstTrancheModalOpen, setIsFirstTrancheModalOpen] = useState(false);
  const [isSecondTrancheModalOpen, setIsSecondTrancheModalOpen] =
    useState(false);
  const [isThirdTrancheModalOpen, setIsThirdTrancheModalOpen] = useState(false);
  const [isCreditSettledModalOpen, setIsCreditSettledModalOpen] =
    useState(false);
  const [isCreditSettledDepositModalOpen, setIsCreditSettledDepositModalOpen] =
    useState(false);
  const [isLoanRunningModalOpen, setIsLoanRunningModalOpen] = useState(false);
  const [isFullPaymentModalOpen, setIsFullPaymentModalOpen] = useState(false);
  const [isSelfDepositModalOpen, setIsSelfDepositModalOpen] = useState(false);

  const handleOpenPaymentModal = (e) => {
    setIsPaymentModalOpen(true);
  };

  const handleOnClosePaymentModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsPaymentModalOpen(false);
  };

  // Close Modal Function
  const handleOnCloseFirstTrancheModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsFirstTrancheModalOpen(false);
  };
  const handleOnCloseSecondTrancheModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSecondTrancheModalOpen(false);
  };
  const handleOnCloseThirdTrancheModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsThirdTrancheModalOpen(false);
  };
  const handleOnCloseCreditSettledModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsCreditSettledModalOpen(false);
  };
  const handleOnCloseCreditSettledDepositModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsCreditSettledDepositModalOpen(false);
  };
  const handleOnCloseLoanRunningModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoanRunningModalOpen(false);
  };
  const handleOnCloseFullPaymentModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsFullPaymentModalOpen(false);
  };
  const handleOnCloseSelfDepositModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSelfDepositModalOpen(false);
  };

  // Open Modal Function

  const handleOnOpenFirstTrancheModal = () => {
    setIsPaymentModalOpen(false);
    setIsFirstTrancheModalOpen(true);
  };
  const handleOnOpenSecondTrancheModal = () => {
    setIsPaymentModalOpen(false);
    setIsSecondTrancheModalOpen(true);
  };
  const handleOnOpenThirdTrancheModal = () => {
    setIsPaymentModalOpen(false);
    setIsThirdTrancheModalOpen(true);
  };
  const handleOnOpenCreditSettledModal = () => {
    setIsPaymentModalOpen(false);
    setIsCreditSettledModalOpen(true);
  };

  const handleOnOpenCreditSettledDepositModal = () => {
    setIsPaymentModalOpen(false);
    setIsCreditSettledDepositModalOpen(true);
  };
  const handleOnOpenLoanRunningModal = () => {
    setIsPaymentModalOpen(false);
    setIsLoanRunningModalOpen(true);
  };
  const handleOnOpenFullPaymentModal = () => {
    setIsPaymentModalOpen(false);
    setIsFullPaymentModalOpen(true);
  };
  const handleOnOpenSelfDepositModal = () => {
    setIsPaymentModalOpen(false);
    setIsSelfDepositModalOpen(true);
  };

  // Other staff

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Płatności</StyledTitle>
      </StyledTitleWrapper>

      {(opportunities.contract_terms_details?.payment_type === 1 ||
        opportunities.heat_pump_terms_details?.payment_type === 1) && (
        <>
          {opportunities.loan_running === 0 &&
            opportunities.credit_settled === 0 &&
            opportunities.full_payment === 0 && (
              <StyledFalseInfo>Brak wpłaty</StyledFalseInfo>
            )}
          {opportunities.loan_running === 1 &&
            opportunities.credit_settled === 0 &&
            opportunities.full_payment === 0 && (
              <StyledTrueInfo>Kredyt podpisany</StyledTrueInfo>
            )}
          {(opportunities.credit_settled === 1 ||
            opportunities.full_payment === 1) && (
            <StyledTrueInfo>Wpłata całości</StyledTrueInfo>
          )}
        </>
      )}

      {(opportunities.contract_terms_details?.payment_type === 2 ||
        opportunities.heat_pump_terms_details?.payment_type === 2) && (
        <>
          {opportunities.self_deposit === 0 &&
            opportunities.loan_running === 0 &&
            opportunities.credit_settled === 0 &&
            opportunities.full_payment === 0 && (
              <StyledFalseInfo>Brak wpłaty</StyledFalseInfo>
            )}
          {opportunities.self_deposit === 1 &&
            opportunities.loan_running === 0 &&
            opportunities.full_payment === 0 && (
              <StyledTrueInfo>Wpłata własna</StyledTrueInfo>
            )}
          {((opportunities.loan_running === 1 &&
            opportunities.credit_settled === 0 &&
            opportunities.self_deposit === 0 &&
            opportunities.full_payment === 0) ||
            (opportunities.loan_running === 1 &&
              opportunities.credit_settled === 0 &&
              opportunities.self_deposit === 1 &&
              opportunities.full_payment === 0)) && (
            <StyledTrueInfo>Kredyt podpisany</StyledTrueInfo>
          )}
          {opportunities.loan_running === 1 &&
            opportunities.credit_settled === 1 &&
            opportunities.self_deposit === 0 &&
            opportunities.full_payment === 0 && (
              <StyledTrueInfo>Kredyt rozliczony</StyledTrueInfo>
            )}
          {opportunities.full_payment === 1 && (
            <StyledTrueInfo>Wpłata całości</StyledTrueInfo>
          )}
        </>
      )}

      {(opportunities.contract_terms_details?.payment_type === 3 ||
        opportunities.heat_pump_terms_details?.payment_type === 3) && (
        <>
          {opportunities.full_payment === 1 ? (
            <StyledTrueInfo>Wpłata całości</StyledTrueInfo>
          ) : (
            <StyledFalseInfo>Brak wpłaty</StyledFalseInfo>
          )}
        </>
      )}
      {(opportunities.contract_terms_details?.payment_type === 4 ||
        opportunities.heat_pump_terms_details?.payment_type === 4) && (
        <>
          {opportunities.payment_monitoring_first_tranche === 0 &&
            opportunities.payment_monitoring_second_tranche === 0 &&
            opportunities.payment_monitoring_third_tranche === 0 &&
            opportunities.full_payment === 0 && (
              <StyledFalseInfo>Brak wpłaty</StyledFalseInfo>
            )}

          {opportunities.payment_monitoring_first_tranche === 1 &&
            opportunities.payment_monitoring_second_tranche === 0 &&
            opportunities.payment_monitoring_third_tranche === 0 &&
            opportunities.full_payment === 0 && (
              <StyledTrueInfo>Wpłata pierwszej transzy</StyledTrueInfo>
            )}

          {opportunities.payment_monitoring_first_tranche === 1 &&
            opportunities.payment_monitoring_second_tranche === 1 &&
            opportunities.payment_monitoring_third_tranche === 0 &&
            opportunities.full_payment === 0 && (
              <StyledTrueInfo>Wpłata drugiej transzy</StyledTrueInfo>
            )}

          {((opportunities.payment_monitoring_first_tranche === 1 &&
            opportunities.payment_monitoring_second_tranche === 1 &&
            opportunities.payment_monitoring_third_tranche === 1) ||
            opportunities.full_payment === 1) && (
            <StyledTrueInfo>Wpłata całości</StyledTrueInfo>
          )}
        </>
      )}

      {!opportunities.contract_terms_details?.payment_type &&
        !opportunities.heat_pump_terms_details?.payment_type && (
          <>
            {opportunities.full_payment === 1 ? (
              <StyledTrueInfo>Wpłata całości</StyledTrueInfo>
            ) : (
              <StyledFalseInfo>Brak wpłaty</StyledFalseInfo>
            )}
          </>
        )}

      {(user?.role?.id === 1 ||
        (user.role?.id === 3 && user.team?.id === 3)) && (
        <StyledButtonWrapper>
          <StyledButton onClick={handleOpenPaymentModal}>
            Dodaj wpłatę
          </StyledButton>
        </StyledButtonWrapper>
      )}
      <PaymentMonitoringModal
        isModalOpen={isPaymentModalOpen}
        handleOnClose={handleOnClosePaymentModal}
        opportunities={opportunities}
        handleOnOpenFirstTrancheModal={handleOnOpenFirstTrancheModal}
        handleOnOpenSecondTrancheModal={handleOnOpenSecondTrancheModal}
        handleOnOpenThirdTrancheModal={handleOnOpenThirdTrancheModal}
        handleOnOpenCreditSettledModal={handleOnOpenCreditSettledModal}
        handleOnOpenCreditSettledDepositModal={
          handleOnOpenCreditSettledDepositModal
        }
        handleOnOpenLoanRunningModal={handleOnOpenLoanRunningModal}
        handleOnOpenFullPaymentModal={handleOnOpenFullPaymentModal}
        handleOnOpenSelfDepositModal={handleOnOpenSelfDepositModal}
      />
      <FirstTrancheModal
        isModalOpen={isFirstTrancheModalOpen}
        handleOnClose={handleOnCloseFirstTrancheModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
      <SecondTrancheModal
        isModalOpen={isSecondTrancheModalOpen}
        handleOnClose={handleOnCloseSecondTrancheModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
      <ThirdTrancheModal
        isModalOpen={isThirdTrancheModalOpen}
        handleOnClose={handleOnCloseThirdTrancheModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
      <CreditSettledModal
        isModalOpen={isCreditSettledModalOpen}
        handleOnClose={handleOnCloseCreditSettledModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
      <CreditSettledWithDepositModal
        isModalOpen={isCreditSettledDepositModalOpen}
        handleOnClose={handleOnCloseCreditSettledDepositModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
      <LoanRunningModal
        isModalOpen={isLoanRunningModalOpen}
        handleOnClose={handleOnCloseLoanRunningModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
      <FullPaymentModal
        isModalOpen={isFullPaymentModalOpen}
        handleOnClose={handleOnCloseFullPaymentModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
      <SelfDepositModal
        isModalOpen={isSelfDepositModalOpen}
        handleOnClose={handleOnCloseSelfDepositModal}
        opportunities={opportunities}
        setIsSended={setIsSended}
        fetchData={fetchData}
        isSended={isSended}
      />
    </StyledWrapper>
  );
};

const StyledFalseInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(244, 118, 49);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledTrueInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(102, 188, 70);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div``;

export default PaymentMonitoring;
