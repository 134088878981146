import React from "react";
import styled from "styled-components";

const PricingFilter = ({ pricingFilter, setPricingFilter }) => {
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledComponentTitle>Oferty wycenione</StyledComponentTitle>
      </StyledTitleWrapper>
      <StyledSwitchItemWrapper>
        <StyledSwitchWrapper>
          <label className="toggle-switch-label" htmlFor="showApprove">
            <input
              type="checkbox"
              className="toggle-switch-checkbox"
              name="showApprove"
              id="showApprove"
              checked={pricingFilter}
              onChange={() => setPricingFilter((prev) => !prev)}
            />

            <span className="toggle-switch-control" />
          </label>
        </StyledSwitchWrapper>
        <StyledTitle>
          <StyledTitleName>
            {pricingFilter ? "Pokazane" : "Ukryte"}
          </StyledTitleName>
        </StyledTitle>
      </StyledSwitchItemWrapper>
    </StyledWrapper>
  );
};

const StyledComponentTitle = styled.p`
  font-weight: bold;
`;

const StyledSwitchItemWrapper = styled.div`
  display: flex;
`;

const StyledTitleWrapper = styled.div``;

const StyledWrapper = styled.div`
  background: white;
  padding: 10px;
  border-radius: 10px;

  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const StyledTitleName = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;
const StyledTitle = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  background: white;
  display: flex;
`;

const StyledSwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .toggle-switch-label {
    display: block;
    position: relative;
    padding-left: 10px;
    height: 20px;
    width: 40px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }
  .toggle-switch-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .toggle-switch-checkbox:checked ~ .toggle-switch-control {
    background-color: #66bc46;

    &:after {
      left: 1px;
    }
  }
  .toggle-switch-control {
    position: absolute;
    top: 0;
    left: 0px;
    height: 20px;
    width: 40px;
    border-radius: 10px;
    background-color: #134771;
    transition: background-color 0.2s linear;
    &:after {
      content: "";
      position: absolute;
      left: 20px;
      top: 1px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: white;
      transition: left 0.2s linear;
    }
  }
`;

export default PricingFilter;
