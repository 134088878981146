import React from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Calendar from "./Pages/Calendar/Calendar";
import Main from "./Pages/Main/Main";
import TaskStatus from "./Pages/TaskStatus/TaskStatus";
import TaskType from "./Pages/TaskType/TaskType";
import YourTasks from "./Pages/YourTasks/YourTasks";

const CalendarSettings = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <div>
        <StyledCalendarSettingsNav>
          <ul>
            <StyledCalendarSettingsNavItem>
              <NavLink exact to="/settings/calendar/" activeClassName="active">
                Ogólne
              </NavLink>
            </StyledCalendarSettingsNavItem>
            <StyledCalendarSettingsNavItem>
              <NavLink
                to="/settings/calendar/your-task"
                activeClassName="active"
              >
                Twoje zadania
              </NavLink>
            </StyledCalendarSettingsNavItem>
            <StyledCalendarSettingsNavItem>
              <NavLink
                to="/settings/calendar/task-type"
                activeClassName="active"
              >
                Typy Zadań
              </NavLink>
            </StyledCalendarSettingsNavItem>
            <StyledCalendarSettingsNavItem>
              <NavLink
                to="/settings/calendar/task-status"
                activeClassName="active"
              >
                Statusy Zadań
              </NavLink>
            </StyledCalendarSettingsNavItem>
            <StyledCalendarSettingsNavItem>
              <NavLink
                to="/settings/calendar/calendar"
                activeClassName="active"
              >
                Kalendarz
              </NavLink>
            </StyledCalendarSettingsNavItem>
          </ul>
        </StyledCalendarSettingsNav>
      </div>

      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/`}>
            <Main />
          </Route>
          <Route path={`${path}/your-task`}>
            <YourTasks />
          </Route>
          <Route path={`${path}/task-type`}>
            <TaskType />
          </Route>
          <Route path={`${path}/task-status`}>
            <TaskStatus />
          </Route>
          <Route path={`${path}/calendar`}>
            <Calendar />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  );
};

const StyledCalendarSettingsNav = styled.nav`
  padding: 20px 40px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledCalendarSettingsNavItem = styled.li`
  list-style: none;
  a {
    display: block;
    padding: 10px 15px;
    font-size: 20px;
    text-decoration: none;
  }
  a.active {
    border-bottom: 4px solid #2d96db;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
`;
export default CalendarSettings;
