import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import Modal from "../../../../components/Modal";
import request from "../../../../helpers/request";
import { StoreContext } from "../../../../store/StoreProvider";
import loading from "../../../../img/48x48.gif";
import AddFileModal from "./Components/AddFileModal/AddFileModal";

const UserEditNote = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  editObject,
  setIsModalOpen,
  note,
}) => {
  const { clients, setClients, opportunities, setOpportunities } =
    useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, reset, setValue } = useForm();
  const [isSended, setIsSended] = useState(false);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);

  const resetInputValue = () => {
    reset({
      text: "",
      client_id: null,
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.patch(`/api/note/${note.id}`, {
        ...data,
      });
      if (status === 201) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      console.warn(e);
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (editObject) {
      setValue("text", editObject.text);
      setValue("client_id", editObject.client_id);
    }
  }, [editObject]);

  const fetchClient = async () => {
    try {
      const { data, status } = await request.get(`api/clients`);
      if (status === 200) {
        await setClients(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchOpportunities = async () => {
    try {
      const { data, status } = await request.get(`api/opportunities`);
      if (status === 200) {
        await setOpportunities(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (clients === undefined && clients.length === 0) {
      fetchClient();
    }
  }, []);
  useEffect(() => {
    if (opportunities === undefined && opportunities.length === 0) {
      fetchOpportunities();
    }
  }, []);

  const handleOnCloseFileAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAddFileModalOpen(false);
    setIsModalOpen(true);
  };

  const handleOnOpenAddFileModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setIsAddFileModalOpen(true);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledInput>
          <div className="input-group">
            <label>Tekst</label>
            <input type="text" {...register("text")} />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label htmlFor="client_id">Przypisz do osoby:</label>
            <select id="client_id" {...register("client_id")}>
              <option value={null}> Wybierz Osobę </option>
              {clients &&
                clients.map((client) => {
                  return (
                    <option key={client.id} value={client.id}>
                      {client.first_name} {client.last_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label htmlFor="opportunities_id">
              Przypisz do szansy sprzedarzy:
            </label>
            <select id="opportunities_id" {...register("opportunities_id")}>
              <option value={null}> Wybierz szansę sprzedarzy</option>
              {opportunities &&
                opportunities.map((opportunity) => {
                  return (
                    <option key={opportunity.id} value={opportunity.id}>
                      {opportunity.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </StyledInput>

        <StyledButtonWrapper onClick={handleOnOpenAddFileModal}>
          <StyledAddButton> Dodaj zdjęcie</StyledAddButton>
        </StyledButtonWrapper>

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Zapisz
          </button>
        </div>
        {isSended && (
          <StyledSendNoteWrapper>
            <StyledSendNoteInnerWrapper>
              Przesyłanie...
              <StyledLoadingImage src={loading} alt="loading" />
            </StyledSendNoteInnerWrapper>
          </StyledSendNoteWrapper>
        )}
      </StyledForm>
      <AddFileModal
        isModalOpen={isAddFileModalOpen}
        setIsModalOpen={setIsAddFileModalOpen}
        setIsEditModalOpen={setIsModalOpen}
        handleOnClose={handleOnCloseFileAddModal}
        fetchData={fetchData}
        note={note}
        isSended={isSended}
        setIsSended={setIsSended}
      />
    </Modal>
  );
};

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
`;

const StyledAddButton = styled.button`
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledFileWrapper = styled.div`
  display: grid;
  padding-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 120px));
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
        text-decoration: underline;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default UserEditNote;
