import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import TodoSidebarListItem from "./TodoSidebarListItem";
import TodoSideBarNav from "./TodoSideBarNav";

const TodoSideBarList = ({
  todoList,
  fetchData,
  filterOption,
  setFilterOption,
  setHideOld,
  hideOld,
}) => {
  let history = useHistory();

  const handleOnRedirect = () => {
    history.push("/dashboard/todo");
  };

  // const handleHideOldTodo = () => {
  //   setHideOld(!hideOld);
  // };
  return (
    <StyledTodoList>
      <TodoSideBarNav
        filterOption={filterOption}
        setFilterOption={setFilterOption}
      />
      <StyledTodoListWeapper>
        {todoList?.length > 0 ? (
          <>
            {todoList.map((todo) => (
              <TodoSidebarListItem
                todo={todo}
                key={todo.id}
                fetchData={fetchData}
              />
            ))}
          </>
        ) : (
          <StyledNoneToDo>Brak zadań</StyledNoneToDo>
        )}
      </StyledTodoListWeapper>
      <StyledOptionWrapper>
        {/* <StyledButton onClick={handleHideOldTodo}>
          {hideOld ? "Pokaż spóźnione" : "Ukryj spóźnione"}
        </StyledButton> */}
        <StyledButton onClick={handleOnRedirect}>Przejdź do zadań</StyledButton>
      </StyledOptionWrapper>
    </StyledTodoList>
  );
};

const StyledNoneToDo = styled.div`
  padding: 5px;
  font-size: 14px;
  color: #134771;
`;

const StyledOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 5px;
  border-radius: 0 0 10px 10px;
`;

const StyledTodoListWeapper = styled.div`
  max-height: calc(50vh - 250px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0 5px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTodoList = styled.div`
  width: 100%;
`;

const StyledButton = styled.button`
  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: #134771;

  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
export default TodoSideBarList;
