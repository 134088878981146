import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import styled from "styled-components";
import Modal from "../../../../../../../components/Modal";

const PaymentMonitoringModal = ({
  isModalOpen,
  handleOnClose,
  opportunities,
  handleOnOpenFirstTrancheModal,
  handleOnOpenSecondTrancheModal,
  handleOnOpenThirdTrancheModal,
  handleOnOpenCreditSettledModal,
  handleOnOpenCreditSettledDepositModal,
  handleOnOpenLoanRunningModal,
  handleOnOpenFullPaymentModal,
  handleOnOpenSelfDepositModal,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledModalWrapper>
        <StyledButtonWrapper>
          {(opportunities.contract_terms_details?.payment_type === 1 ||
            opportunities.heat_pump_terms_details?.payment_type === 1) && (
            <>
              {opportunities.loan_running === 1 ||
              opportunities.full_payment === 1 ? (
                <StyledAddButton disabled={true}>
                  Kredyt podpisany
                </StyledAddButton>
              ) : (
                <StyledAddButton onClick={handleOnOpenLoanRunningModal}>
                  Kredyt podpisany
                </StyledAddButton>
              )}
              {opportunities.credit_settled === 1 ||
              opportunities.full_payment === 1 ? (
                <StyledAddButton disabled={true}>
                  Kredyt rozliczony
                </StyledAddButton>
              ) : (
                <StyledAddButton onClick={handleOnOpenCreditSettledModal}>
                  Kredyt rozliczony
                </StyledAddButton>
              )}
            </>
          )}
          {(opportunities.contract_terms_details?.payment_type === 2 ||
            opportunities.heat_pump_terms_details?.payment_type === 2) && (
            <>
              {opportunities.self_deposit === 1 ||
              opportunities.full_payment === 1 ? (
                <StyledAddButton disabled={true}>Wpłata własna</StyledAddButton>
              ) : (
                <StyledAddButton onClick={handleOnOpenSelfDepositModal}>
                  Wpłata własna
                </StyledAddButton>
              )}
              {opportunities.loan_running === 1 ||
              opportunities.full_payment === 1 ? (
                <StyledAddButton disabled={true}>
                  Kredyt podpisany
                </StyledAddButton>
              ) : (
                <StyledAddButton onClick={handleOnOpenLoanRunningModal}>
                  Kredyt podpisany
                </StyledAddButton>
              )}
              {opportunities.credit_settled === 1 ||
              opportunities.full_payment === 1 ||
              opportunities.loan_running === 0 ? (
                <StyledAddButton disabled={true}>
                  Kredyt rozliczony
                </StyledAddButton>
              ) : (
                <StyledAddButton
                  onClick={handleOnOpenCreditSettledDepositModal}
                >
                  kredyt rozliczony
                </StyledAddButton>
              )}
            </>
          )}
          {(opportunities.contract_terms_details?.payment_type === 3 ||
            opportunities.heat_pump_terms_details?.payment_type === 3) && (
            <>
              {opportunities.full_payment === 1 ? (
                <StyledAddButton disabled={true}>
                  Wpłata całości
                </StyledAddButton>
              ) : (
                <StyledAddButton onClick={handleOnOpenFullPaymentModal}>
                  Wpłata całości
                </StyledAddButton>
              )}
            </>
          )}
          {(opportunities.contract_terms_details?.payment_type === 4 ||
            opportunities.heat_pump_terms_details?.payment_type === 4) && (
            <>
              {(opportunities.contract_terms_details?.first_tranche_percentage >
                0 ||
                opportunities.heat_pump_terms_details
                  ?.first_tranche_percentage > 0) && (
                <>
                  {opportunities.payment_monitoring_first_tranche === 1 ||
                  opportunities.full_payment === 1 ? (
                    <StyledAddButton disabled={true}>
                      Wpłata pierwszej transzy
                    </StyledAddButton>
                  ) : (
                    <StyledAddButton onClick={handleOnOpenFirstTrancheModal}>
                      Wpłata pierwszej transzy
                    </StyledAddButton>
                  )}
                </>
              )}
              {(opportunities.contract_terms_details
                ?.second_tranche_percentage > 0 ||
                opportunities.heat_pump_terms_details
                  ?.second_tranche_percentage > 0) && (
                <>
                  {opportunities.payment_monitoring_second_tranche === 1 ||
                  opportunities.payment_monitoring_first_tranche === 0 ||
                  opportunities.full_payment === 1 ? (
                    <StyledAddButton disabled={true}>
                      Wpłata drugiej transzy
                    </StyledAddButton>
                  ) : (
                    <StyledAddButton onClick={handleOnOpenSecondTrancheModal}>
                      Wpłata drugiej transzy
                    </StyledAddButton>
                  )}
                </>
              )}
              {(opportunities.contract_terms_details?.third_tranche_percentage >
                0 ||
                opportunities.heat_pump_terms_details
                  ?.third_tranche_percentage > 0) && (
                <>
                  {opportunities.payment_monitoring_third_tranche === 1 ||
                  (opportunities.heat_pump_terms_details
                    ?.second_tranche_percentage === 0 &&
                    opportunities.payment_monitoring_second_tranche === 0) ||
                  opportunities.payment_monitoring_first_tranche === 0 ||
                  opportunities.full_payment === 1 ? (
                    <StyledAddButton disabled={true}>
                      Wpłata trzeciej transzy
                    </StyledAddButton>
                  ) : (
                    <StyledAddButton onClick={handleOnOpenThirdTrancheModal}>
                      Wpłata trzeciej transzy
                    </StyledAddButton>
                  )}
                </>
              )}
            </>
          )}

          {!opportunities.contract_terms_details?.payment_type && (
            <>
              {opportunities.full_payment === 1 ? (
                <StyledAddButton disabled={true}>
                  Wpłata całości
                </StyledAddButton>
              ) : (
                <StyledAddButton onClick={handleOnOpenFullPaymentModal}>
                  Wpłata całości
                </StyledAddButton>
              )}
            </>
          )}
        </StyledButtonWrapper>
      </StyledModalWrapper>
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
    </Modal>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledAddButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  min-width: 280px;
  &:hover {
    background: #053257;
  }
  &:disabled {
    cursor: inherit;
    background: #cadeee;
    color: #134771;
  }
`;

const StyledModalWrapper = styled.div`
  padding: 10px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;
export default PaymentMonitoringModal;
