import {
  faCheckCircle,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import DeleteAlert from "./DeleteAlert";
import TodoPopOver from "./TodoPopOver";
// import TodoPopOver from "./TodoPopOver";

const TodoItem = ({ todo, fetchData, handleOnClickEdit, setEditObject }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOpenPopover = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/todo/${todo.id}`);
    if (status === 200) {
      fetchData();
      setIsOpen(false);
    }
  };

  const handleChangeComplete = async () => {
    await request.put(`api/todo/${todo.id}`, { done: !todo.done });
    fetchData();
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsOpen(true);
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
    setIsOpen(false);
  };

  return (
    <StyledTodoItemWrapper>
      <StyledTodoButton onClick={handleOpenPopover}>
        {todo.taskType && (
          <StyledTypeIcon icon={[todo.taskType.prefix, todo.taskType.icon]} />
        )}
        {Boolean(todo.done) === true && (
          <StyledDoneIcon style={{ color: "#66bc46" }} icon={faCheckCircle} />
        )}
        {Boolean(todo.important) === true && (
          <StyledImportantIcon
            style={{ color: "#f47631" }}
            icon={faExclamation}
          />
        )}
        <StyledNameWrapper>
          <StyledName title={todo.name}>{todo.name}</StyledName>
          {todo.opportunity && (
            <StyledOpportunityWrapper title={todo.opportunity?.name}>
              {todo.opportunity?.name}
            </StyledOpportunityWrapper>
          )}
        </StyledNameWrapper>
      </StyledTodoButton>

      <TodoPopOver
        todo={todo}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleOnOpenAlert={handleOnOpenAlert}
        handleOnClickEdit={handleOnClickEdit}
        setEditObject={setEditObject}
        handleChangeComplete={handleChangeComplete}
      />
      <DeleteAlert
        handleOnDelete={handleOnDelete}
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
      />
    </StyledTodoItemWrapper>
  );
};

const StyledCompanyWrapper = styled.div`
  margin: 0 10px;
  color: #cadeee;
  min-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledClientWrapper = styled.div`
  margin: 0 10px;
  color: #cadeee;
  min-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
const StyledOpportunityWrapper = styled.div`
  margin: 0 10px;
  color: #cadeee;
  min-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
const StyledNameWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
const StyledTodoItemWrapper = styled.div`
  min-width: 0;
  position: relative;
`;

const StyledTypeIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
const StyledDoneIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
const StyledImportantIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledTodoButton = styled.button`
  background: #134771;
  color: white;
  border: none;
  border-radius: 2px;
  outline: none;
  width: 100%;
  padding: 2px 5px;
  margin-bottom: 2px;
  display: flex;
  cursor: pointer;
  min-width: 0;
`;

const StyledName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
`;
export default TodoItem;
