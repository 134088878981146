import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import DeleteAlert from "./DeleteAlert";
import Switch from "../../../../../../../components/Switch";
import { useHistory, useLocation } from "react-router-dom";

const HotWaterTankItem = ({
  hotWaterTank,
  fetchHotWaterTanks,
  handleOpenEditTypeTask,
  setEditObject,
}) => {
  const { id, name, capacity, warranty, is_active } = hotWaterTank;
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleOnEdit = () => {
    setEditObject(hotWaterTank);
    handleOpenEditTypeTask();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/hot-water-tank/${id}`);
    if (status === 200) {
      fetchHotWaterTanks();
      setIsAlertOpen(false);
    }
  };

  const handleOnSwitchChange = async () => {
    try {
      const { status } = await request.patch(`/api/hot-water-tank/${id}`, {
        is_active: !is_active,
      });
      if (status === 200) {
        fetchHotWaterTanks();
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledTaskTypeItem>
      <Switch checked={is_active} handleOnChange={handleOnSwitchChange} />
      <StyledDescription>
        <p>{name}</p>
      </StyledDescription>

      <StyledDescription>
        {capacity ? <p>{capacity} L</p> : <p>0 L</p>}
      </StyledDescription>
      <StyledWarranty>
        <p>{warranty} lat</p>
      </StyledWarranty>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.tr``;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.td``;
const StyledWarranty = styled.td`
  text-align: right;
`;

const StyledButtonGroup = styled.td`
  text-align: right;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export default HotWaterTankItem;
