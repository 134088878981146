import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddConstruction from "./AddConstruction";
import ConstructionItem from "./ConstructionItem/ConstructionItem";

const Construction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(false);

  const {
    constructions,
    setConstructions,
    constructionType,
    setConstructionType,
    panels,
    setPanels,
    transports,
    setTransports,
  } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(false);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleOpenEditTypeTask = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchConstructions = async () => {
    try {
      const { status, data } = await request.get("/api/constructions");
      if (status === 200) {
        setConstructions(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchConstructionType = async () => {
    try {
      const { status, data } = await request.get("/api/constructions-type");
      if (status === 200) {
        setConstructionType(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchPanels = async () => {
    try {
      const { status, data } = await request.get("/api/panels");
      if (status === 200) {
        await setPanels(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchTransport = async () => {
    try {
      const { status, data } = await request.get("/api/transports");
      if (status === 200) {
        setTransports(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (constructions === undefined || constructions.length === 0) {
      fetchConstructions();
    }
    if (constructionType === undefined || constructionType.length === 0) {
      fetchConstructionType();
    }
    if (panels === undefined || panels.length === 0) {
      fetchPanels();
    }
    if (transports === undefined || transports.length === 0) {
      fetchTransport();
    }
  }, []);

  return (
    <StyledTaskTypeWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj Konstrukcje</StyledButton>
      </StyledButtonWrapper>
      <StyledTableWrapper>
        <StyledOfferTable>
          <StyledOfferTableHead>
            <tr>
              <StyledOfferTableHeadItem>
                Nazwa Konstrukcji
              </StyledOfferTableHeadItem>
              <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
                Typ konstrukcji
              </StyledOfferTableHeadItem>
              <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
                Cena za 1kWp
              </StyledOfferTableHeadItem>
              <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
                Cena dodatkowa
              </StyledOfferTableHeadItem>
              <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
                Transport
              </StyledOfferTableHeadItem>
              <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
                Marża
              </StyledOfferTableHeadItem>
              <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
                Akcje
              </StyledOfferTableHeadItem>
            </tr>
          </StyledOfferTableHead>
          <tbody>
            {constructions.map((construction) => {
              return (
                <ConstructionItem
                  handleOpenEditTypeTask={handleOpenEditTypeTask}
                  fetchConstructions={fetchConstructions}
                  setEditObject={setEditObject}
                  construction={construction}
                  key={construction.id}
                />
              );
            })}
          </tbody>
        </StyledOfferTable>
      </StyledTableWrapper>
      <AddConstruction
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchConstructions}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledTaskTypeWrapper>
  );
};

const StyledTaskTypeWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledTableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const StyledOfferTable = styled.table`
  min-width: 0;
  padding: 40px 0;
  & th,
  & td {
    padding: 0px 5px;
    white-space: nowrap;
  }
  & tbody {
    min-width: 0;
  }
`;

const StyledOfferTableHead = styled.thead`
  font-size: 18px;
`;

const StyledOfferTableHeadItem = styled.th`
  text-align: start;
  max-width: 340px;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;
export default Construction;
