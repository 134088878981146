import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../components/Modal";
import request from "../../../../helpers/request";
import { StoreContext } from "../../../../store/StoreProvider";
import { useLocation, useHistory } from "react-router";
import ChooseUser from "../../../../components/Form/ChooseUser/ChooseUser";

const validationSchema = yup.object().shape(
  {
    source_id: yup.string().required("Proszę wybrać źródło pozyskania"),
    first_name: yup
      .string()
      .required("Imię jest wymagana")
      .min(3, "Imię musi posiadać co najmniej 3 znaków")
      .max(255, "Imię nie może być dłuższa niż 255 znaków"),
    last_name: yup
      .string()
      .required("Nazwisko jest wymagana")
      .min(3, "Nazwisko musi posiadać co najmniej 3 znaków")
      .max(255, "Nazwisko nie może być dłuższa niż 255 znaków"),
    phone: yup.string().when("email", {
      is: (email) => !email,
      then: yup
        .string()
        .matches(/^[0-9]+$/, {
          message: "Numer telefonu musi składać się wyłącznie z cyfr 1-9",
          excludeEmptyString: true,
        })
        .required("Wpisz email lub telefon")
        .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków"),
    }),
    email: yup
      .string()
      .email("proszę podać prawidłowy adres email")
      .when("phone", {
        is: (phone) => !phone,
        then: yup.string().required("Wpisz email lub telefon"),
        otherwise: yup.string().nullable(),
      }),
  },
  [["phone", "email"]]
);

const AddClientPVOpportunities = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  client,
}) => {
  const { departments, sources, teams, user, setOpportunities } =
    useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();
  const [isSended, setIsSended] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      maintainer_id: user?.id,
      client_id: "",
      name: "",
      phone: "",
      email: "",
      source_id: 0,
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.post("/api/opportunities", {
        ...data,
      });

      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
        fetchOpportunity();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const fetchOpportunity = async () => {
    try {
      const { status, data } = await request.get("/api/opportunities");
      if (status === 200) {
        await setOpportunities(data.data);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (client) {
      setValue("client_id", client.id);
      setValue("first_name", client.first_name);
      setValue("last_name", client.last_name);
      setValue("phone", client.phone);
      setValue("email", client.email);
      setValue("source_id", client.source?.id);
    } else {
      setValue("client_id", "");
      setValue("first_name", "");
      setValue("last_name", "");
      setValue("phone", "");
      setValue("email", "");
      setValue("source_id", 0);
    }
  }, [client, client?.first_name, client?.last_name]);

  useEffect(() => {
    setValue("maintainer_id", user.id);
    setValue("chooseClient", false);
  }, []);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledTitleWrapper>
          <StyledTitle>Szansa sprzedaży fotowoltaika</StyledTitle>
        </StyledTitleWrapper>
        <StyledContactWrapper>
          <StyledContactPersonInputWrapper>
            <input type="hidden" {...register("client_id")} />
            <StyledInput>
              <div className="input-group">
                <label>Imię</label>
                <input type="text" {...register("first_name")} readOnly />
              </div>
            </StyledInput>
            {errors?.first_name && (
              <span className="error">{errors.first_name.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Nazwisko</label>
                <input type="text" {...register("last_name")} readOnly />
              </div>
            </StyledInput>
            {errors?.last_name && (
              <span className="error">{errors.last_name.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Telefon</label>
                <input type="number" {...register("phone")} readOnly />
              </div>
            </StyledInput>
            {errors?.phone && (
              <span className="error">{errors.phone.message}</span>
            )}

            <StyledInput>
              <div className="input-group">
                <label>Adres e-mail</label>
                <input type="text" {...register("email")} readOnly />
              </div>
            </StyledInput>
            {errors?.email && (
              <span className="error">{errors.email.message}</span>
            )}
          </StyledContactPersonInputWrapper>
        </StyledContactWrapper>
        <ChooseUser
          departments={departments}
          teams={teams}
          setValue={setValue}
          setValueTitle="maintainer_id"
          user={user}
          isEditMode={false}
        />
        {errors?.maintainer_id && (
          <span className="error">{errors.maintainer_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Źródło pozyskania:</label>
            <select {...register("source_id")} defaultValue={0}>
              <option value={0}>Wybierz...</option>
              {sources.map((source) => {
                return (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.source_id && (
          <span className="error">{errors.source_id.message}</span>
        )}
        <input type="hidden" {...register("is_heat_pump")} value={0} />
        <input type="hidden" {...register("is_photovoltaic")} value={1} />

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Dodaj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledTitle = styled.p`
  color: #134771;
  font-size: 18px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledContactWrapper = styled.div`
  display: flex;
`;
const StyledContactPersonInputWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledForm = styled.form`
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
        text-decoration: underline;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddClientPVOpportunities;
