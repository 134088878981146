import React, { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../store/StoreProvider";
import UserFilter from "./UserFilter/UserFilter";
import CollapseStatus from "./CollapseStatus/CollapseStatus";
import CompleteFilter from "./CompleteFilter/CompleteFilter";
import DateFilters from "./DateFilters/DateFilters";
import OrderProductFilter from "./OrderProductFilter/OrderProductFilter";
import PaymentFilter from "./PaymentFilter/PaymentFilter";
import TypeFilter from "./TypeFilter/TypeFilter";
import UserFilterManager from "./UserFilterManager/UserFilterManager";
import OSDFilter from "./OSDFilter.jsx/OSDFilter";

const OpportunitiesAside = ({
  setStatusFilter,
  setDepartmentFilter,
  opportunityStatuses,
  statusFilter,
  departmentFilter,
  paymentFilter,
  setPaymentFilter,
  completeFilter,
  setCompleteFilter,
  orderProductFilter,
  setOrderProductFilter,
  dateFilter,
  setDateFilter,
  typeFilter,
  setTypeFilter,
  userFilter,
  setUserFilter,
  teamFilter,
  setTeamFilter,
  osdFilter,
  setOsdFilter,
}) => {
  const { user } = useContext(StoreContext);
  return (
    <StyledAside>
      <CollapseStatus
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        opportunityStatuses={opportunityStatuses}
      />
      <DateFilters setDateFilters={setDateFilter} dateFilters={dateFilter} />
      <TypeFilter setTypeFilter={setTypeFilter} typeFilter={typeFilter} />
      {(user?.role?.id === 1 || user.role?.id === 3) && (
        <>
          <PaymentFilter
            paymentFilter={paymentFilter}
            setPaymentFilter={setPaymentFilter}
          />
          <CompleteFilter
            completeFilter={completeFilter}
            setCompleteFilter={setCompleteFilter}
          />
          <OrderProductFilter
            orderProductFilter={orderProductFilter}
            setOrderProductFilter={setOrderProductFilter}
          />
          <OSDFilter osdFilter={osdFilter} setOsdFilter={setOsdFilter} />
        </>
      )}
      {(user?.role?.id === 1 || user.role?.id === 3) && (
        <UserFilter
          departmentFilter={departmentFilter}
          setDepartmentFilter={setDepartmentFilter}
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          teamFilter={teamFilter}
          setTeamFilter={setTeamFilter}
        />
      )}
      {user?.role?.id === 2 && (
        <UserFilterManager
          userFilter={userFilter}
          setUserFilter={setUserFilter}
        />
      )}
    </StyledAside>
  );
};

const StyledAside = styled.div`
  min-width: 200px;
  max-width: 220px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export default OpportunitiesAside;
