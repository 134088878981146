import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Switch from "../../../../../../../components/Switch";
import request from "../../../../../../../helpers/request";

const TaskTypeItem = ({ element, fetchDataTaskType }) => {
  const { prefix, icon, id, name, value } = element;

  const handleOnSwitchChange = async () => {
    const { status } = await request.patch(`/api/task-type-setting/${id}`, {
      value: !value,
    });
    if (status === 200) {
      fetchDataTaskType();
    }
  };

  return (
    <StyledTaskTypeItem>
      <StyledDescription>
        <Switch handleOnChange={handleOnSwitchChange} checked={value} />
        <StyledTaskTypeIconContainer>
          <StyledTaskTypeIcon icon={[prefix, icon]} />
        </StyledTaskTypeIconContainer>
        <p>{name}</p>
      </StyledDescription>
      <StyledButtonGroup></StyledButtonGroup>
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.li`
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
`;

const StyledTaskTypeIcon = styled(FontAwesomeIcon)``;
const StyledTaskTypeIconContainer = styled.div`
  width: 25px;
  text-align: center;
  margin-right: 10px;
`;
const StyledDescription = styled.div`
  display: flex;
  flex: 1;
`;

const StyledButtonGroup = styled.div`
  display: flex;
`;

export default TaskTypeItem;
