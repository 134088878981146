import React, { createContext, useState } from "react";

export const PvCalculatorAdvancedContext = createContext(null);

const PvCalculatorAdvancedProvider = ({ children }) => {
  const [vatRate, setVatRate] = useState(8);
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [margin, setMargin] = useState(0);
  const [specialDiscountManagement, setSpecialDiscountManagement] = useState(0);
  const [specialDiscountManager, setSpecialDiscountManager] = useState(0);
  const [priceBrutto, setPriceBrutto] = useState(0);
  const [priceNetto, setPriceNetto] = useState(0);
  const [priceVat, setPriceVat] = useState(0);
  const [isExpansion, setIsExpansion] = useState(false);
  const [currentGeneratorPower, setCurrentGeneratorPower] = useState(0);
  const [generators, setGenerators] = useState([
    {
      id: 1,
      panelId: "",
      panelCount: 0,
      inverterId: "",
      inverterCount: 1,
      optimizerId: "",
      optimizerCount: "",
      construction: null,
      constructions: [],
      constructionId: "",
      constructionTypeId: "",
      optimizer: null,
      inverters: [],
      secure: "",
      services: [],
      antiFireCost: 0,
      cutLength: 0,
      wireLength: 0,
      optimizers: [],
      minModules: 0,
      minModulesRWB: 0,
      minPanels: 1,
      minOptimizers: 0,
      cutIndividualPricing: false,
      wireIndividualPricing: false,
      installationPower: 0,
      cutPrice: 0,
      wirePrice: 0,
      panels: [],
      panel: null,
      inverter: null,
    },
  ]);

  return (
    <PvCalculatorAdvancedContext.Provider
      value={{
        vatRate,
        setVatRate,
        specialDiscount,
        setSpecialDiscount,
        margin,
        setMargin,
        specialDiscountManagement,
        setSpecialDiscountManagement,
        specialDiscountManager,
        setSpecialDiscountManager,
        priceBrutto,
        setPriceBrutto,
        priceNetto,
        setPriceNetto,
        priceVat,
        setPriceVat,
        isExpansion,
        setIsExpansion,
        currentGeneratorPower,
        setCurrentGeneratorPower,
        generators,
        setGenerators,
      }}
    >
      {children}
    </PvCalculatorAdvancedContext.Provider>
  );
};

export default PvCalculatorAdvancedProvider;
