import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import request from "../../../../helpers/request";
import { ToDoContext } from "../../../../store/ToDoProvider";
import CollapseYourWorkItem from "./CollapseYourWorkItem";

const CollapseYourWork = ({ title, setYourTaskFilter, yourTaskFilter }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState(true);
  const { yourTask, setYourTask } = useContext(ToDoContext);

  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  const fetchData = async () => {
    const { data, status } = await request.get("/api/your-task-active");
    if (status === 200) {
      setYourTask(data);
    }
  };

  useEffect(() => {
    if (yourTask.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <StyledCollapse>
      <StyledCollapseButton onClick={handleOnClick}>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
        <StyledCollapseTitle>{title}</StyledCollapseTitle>
      </StyledCollapseButton>
      <StyledCollapseItemWrapper
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        {yourTask.map((element) => {
          return (
            <CollapseYourWorkItem
              yourTaskFilter={yourTaskFilter}
              setYourTaskFilter={setYourTaskFilter}
              name={element.name}
              count={1}
              key={element.id}
              id={element.id}
            />
          );
        })}
      </StyledCollapseItemWrapper>
    </StyledCollapse>
  );
};

const StyledCollapseItemWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const StyledCollapseTitle = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledCollapseButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  background: white;
  display: flex;
`;

const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

export default CollapseYourWork;
