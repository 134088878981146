import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import UserButton from "./UserButton";

const ItemDropDownDepartment = ({
  department,
  handleOnChooseUser,
  searchUserName,
  handleOnChooseDepartment,
  chooseDepartment,
  chooseTeam,
  chooseUser,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpenDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  };

  const handleSetUser = (user) => {
    handleOnChooseUser(user);
  };

  useEffect(() => {
    if (searchUserName.trim().length > 0) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  }, [searchUserName]);

  const handleChooseDepartment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (department.id === chooseDepartment) {
      handleOnChooseDepartment(null);
      setIsDropdownOpen(false);
    } else {
      handleOnChooseDepartment(department);
      setIsDropdownOpen(true);
    }
  };

  return (
    <StyledWrapper>
      <StyledDropDown onClick={handleOpenDropdown}>
        <StyledCheckButton onClick={handleChooseDepartment}>
          {department.id === chooseDepartment && (
            <StyledCheckIcon icon={faCheck} />
          )}
        </StyledCheckButton>
        <StyledDepartmentName>{department.name}</StyledDepartmentName>
        {isDropdownOpen ? (
          <StyledAngleIcon icon={faChevronUp} />
        ) : (
          <StyledAngleIcon icon={faChevronDown} />
        )}
      </StyledDropDown>
      <StyledItemWrapper
        style={isDropdownOpen ? { dispal: "block" } : { display: "none" }}
      >
        {department.users &&
          department.users.map((user) => (
            <UserButton
              key={user.id}
              user={user}
              chooseUser={chooseUser}
              handleSetUser={handleSetUser}
            />
          ))}
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

const StyledDepartmentName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledDropDown = styled.div`
  width: 100%;
  text-align: left;
  padding: 4px;
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  padding-right: 20px;
`;

const StyledCheckIcon = styled(FontAwesomeIcon)`
  color: #134771;
  font-size: 12px;
`;

const StyledCheckButton = styled.button`
  padding: 2px;
  background: white;
  border: 1px solid #134771;
  border-radius: 3px;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    background: #cadeee;
  }
`;

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledWrapper = styled.div`
  padding: 0 10px;
  border-radius: 5px;
`;

const StyledAngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px;
`;

export default ItemDropDownDepartment;
