import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";

import Modal from "../../../../../../../../components/Modal";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as PdfDownload } from "../../../../../../../../img/pdf-download-2617.svg";
import request from "../../../../../../../../helpers/request";
import { StoreContext } from "../../../../../../../../store/StoreProvider";
import GenerateContract from "./GenerateContract/GenerateContract";

const HeatPumpOfferDetails = ({
  opportunities,
  isShowModalOpen,
  handleOnClose,
  offerObject,
  handleCreateSimilarOffer,
  fetchData,
}) => {
  const { setOfferApproveList } = useContext(StoreContext);

  const [endDate, setEndDate] = useState(null);
  const [nettoPrice, setNettoPrice] = useState(null);
  const [vatPrice, setVatPrice] = useState(null);
  const [bruttoPrice, setBruttoPrice] = useState(null);
  const [margin, setMargin] = useState(null);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [buttonContext, setButtonContext] = useState(null);
  const [isTimeOut, setIsTimeOut] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (offerObject && Object.keys(offerObject).length > 0) {
      const date = moment(offerObject.created_at)
        .clone()
        .add(Number(offerObject.date), "day")
        .format("DD.MM.YY");
      setEndDate(date);
    }
  }, [offerObject?.date, offerObject?.created_at]);

  useEffect(() => {
    if (endDate) {
      if (moment(endDate, "DD.MM.YY").isBefore(moment(), "day")) {
        setIsTimeOut(true);
      } else {
        setIsTimeOut(false);
      }
    }
  }, [endDate]);

  useEffect(() => {
    if (offerObject?.price_netto) {
      setNettoPrice(
        Number(offerObject.price_netto)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setNettoPrice(null);
    }

    if (offerObject?.price_vat) {
      setVatPrice(
        Number(offerObject.price_vat)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setVatPrice(null);
    }

    if (offerObject?.price_brutto) {
      setBruttoPrice(
        Number(offerObject.price_brutto)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setBruttoPrice(null);
    }

    if (offerObject?.margin) {
      setMargin(
        Number(offerObject.margin)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setMargin(null);
    }
  }, [
    offerObject?.price_netto,
    offerObject?.brutto_price,
    offerObject?.vat_price,
    offerObject?.margin,
  ]);

  const handleSendForApproval = async () => {
    try {
      const { status, data } = await request.patch(
        `api/heat-pump-offer-approve/${offerObject.id}`,
        {
          approve: true,
        }
      );

      if (status === 200) {
        setOfferApproveList(data.data);
        fetchData();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleGenerateOffer = () => {
    setIsGenerateModalOpen(true);
    handleOnClose();
  };

  const handleOnCloseGenrateModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsGenerateModalOpen(false);
  };

  useEffect(() => {
    if (isTimeOut === false) {
      if (
        Boolean(offerObject?.accepted_management) &&
        Boolean(offerObject?.accepted_manager)
      ) {
        setButtonContext(
          <StyledAcceptanceButton onClick={handleGenerateOffer}>
            Wygeneruj umowę
          </StyledAcceptanceButton>
        );
      } else if (
        (Boolean(offerObject?.rejected_management) ||
          Boolean(offerObject?.rejected_manager)) &&
        Boolean(offerObject?.send_for_approval)
      ) {
        setButtonContext(
          <StyledAcceptanceButtonNotClickable disabled={true}>
            Oferta odrzucona
          </StyledAcceptanceButtonNotClickable>
        );
      } else if (Boolean(offerObject?.send_for_approval)) {
        setButtonContext(
          <StyledAcceptanceButtonNotClickable disabled={true}>
            Wyślij do akceptacji
          </StyledAcceptanceButtonNotClickable>
        );
      } else {
        setButtonContext(
          <StyledAcceptanceButton onClick={handleSendForApproval}>
            Wyślij do akceptacji
          </StyledAcceptanceButton>
        );
      }
    }
  }, [offerObject, isTimeOut]);

  return (
    <Modal
      isModalOpen={isShowModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      {offerObject && (
        <StyledModal>
          <StyledDateWrapper>
            Data utworzenia: {moment(offerObject.created_at).format("DD.MM.YY")}{" "}
            godz.{moment(offerObject.created_at).format("gg:mm")}
          </StyledDateWrapper>
          {endDate && (
            <StyledEndDateWrapper>
              Data wygaśnięcia oferty: {endDate}
            </StyledEndDateWrapper>
          )}

          <StyledPhotovoltaicInstalationDetails>
            <StyledPanelWrapper>
              <StyledTitle>Pompa ciepła:</StyledTitle>
              <StyledPanelName>
                <div>
                  {offerObject.heat_pump.brand} {offerObject.heat_pump.model}{" "}
                  {offerObject.heat_pump.power} kW
                </div>
                <div>{offerObject.heat_pump_count}</div>
              </StyledPanelName>
            </StyledPanelWrapper>
            {offerObject.hot_water_tank ? (
              <StyledInverterWrapper>
                <StyledTitle>Zasobkin CWU:</StyledTitle>
                <StyledInverterName>
                  <div>
                    {offerObject.hot_water_tank.name}{" "}
                    {offerObject.hot_water_tank.capacity} L
                  </div>
                  <div>{offerObject.hot_water_tank_count}</div>
                </StyledInverterName>
              </StyledInverterWrapper>
            ) : (
              <StyledInverterWrapper>
                <StyledTitle>Zasobkin CWU:</StyledTitle>
                <StyledInverterName>
                  <div>Nie wybrano</div>
                  <div></div>
                </StyledInverterName>
              </StyledInverterWrapper>
            )}

            <StyledPowerWrapper>
              <StyledTitle>Moc:</StyledTitle>
              {offerObject.heat_pump_count * offerObject.heat_pump_power} kW
            </StyledPowerWrapper>

            <StyledPriceWrapper>
              <StyledTitle>Cena:</StyledTitle>

              {nettoPrice && (
                <StyledPrice>
                  <div>Netto:</div>
                  <div>{nettoPrice}</div>
                </StyledPrice>
              )}
              {vatPrice && (
                <StyledPrice>
                  <div>
                    Vat {offerObject.vat ? <>({offerObject.vat}%)</> : ""}:{" "}
                  </div>
                  <div>{vatPrice}</div>
                </StyledPrice>
              )}
              {bruttoPrice && (
                <StyledPrice>
                  <div>Brutto: </div>
                  <div> {bruttoPrice}</div>
                </StyledPrice>
              )}
              {margin && (
                <StyledPrice style={{ marginTop: "10px" }}>
                  <div>Marża:</div>
                  <div>{margin}</div>
                </StyledPrice>
              )}
            </StyledPriceWrapper>

            {/* {offerObject?.secure ? (
              <StyledSecureWrapper>
                <StyledTitle>Zabezpieczenia:</StyledTitle>
                <StyledSecureInfo>
                  {" "}
                  AC/DC, {offerObject.secure}
                </StyledSecureInfo>
              </StyledSecureWrapper>
            ) : (
              <StyledSecureWrapper>
                <StyledTitle>Zabezpieczenia:</StyledTitle>
                <StyledSecureInfo> AC/DC </StyledSecureInfo>
              </StyledSecureWrapper>
            )} */}

            {offerObject?.services.length > 0 ||
            (offerObject.cut_length && offerObject.cut_length_price) ||
            (offerObject.wire_length && offerObject.wire_length_price) ? (
              <StyledServicesWrapper>
                <StyledTitle>Usługi dodatkowe:</StyledTitle>
                {Number(offerObject.cut_length) !== 0 &&
                  Number(offerObject.cut_length_price) !== 0 && (
                    <StyledSecureInfo>
                      Przekop {offerObject.cut_length}m -{" "}
                      {offerObject.cut_length_price} PLN
                    </StyledSecureInfo>
                  )}
                {Number(offerObject.wire_length) !== 0 &&
                  Number(offerObject.wire_length_price) !== 0 && (
                    <StyledSecureInfo>
                      Kabel {offerObject.wire_length}m -{" "}
                      {offerObject.wire_length_price} PLN
                    </StyledSecureInfo>
                  )}
                {offerObject?.services &&
                  offerObject.services.map((service) => (
                    <StyledServicesInfo key={service.id}>
                      {service.name} - {service.price} PLN
                    </StyledServicesInfo>
                  ))}
              </StyledServicesWrapper>
            ) : (
              <StyledServicesWrapper>
                <StyledTitle>Usługi dodatkowe:</StyledTitle>
                <StyledServicesInfo>Brak usług dodatkowych</StyledServicesInfo>
              </StyledServicesWrapper>
            )}

            {/* {offerObject.special_discount ? (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny handlowiec:</StyledTitle>
                {offerObject.special_discount} zł
              </StyledSpecialDiscountWrapper>
            ) : (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny handlowiec:</StyledTitle>
                Brak rabatu specjalnego od handlowca
              </StyledSpecialDiscountWrapper>
            )} */}
            {offerObject.special_discount_manager > 0 ? (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Dyrektor:</StyledTitle>
                {offerObject.special_discount_manager} zł
              </StyledSpecialDiscountWrapper>
            ) : (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Dyrektor:</StyledTitle>
                Brak rabatu specjalnego od Dyrektora
              </StyledSpecialDiscountWrapper>
            )}

            {offerObject.special_discount_management > 0 ? (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Zarząd:</StyledTitle>
                {offerObject.special_discount_management} zł
              </StyledSpecialDiscountWrapper>
            ) : (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Zarząd:</StyledTitle>
                Brak rabatu specjalnego od Zarządu
              </StyledSpecialDiscountWrapper>
            )}

            {offerObject.description ? (
              <StyledDescriptionWrapper>
                <StyledTitle>Uwagi:</StyledTitle>
                {offerObject.description}
              </StyledDescriptionWrapper>
            ) : (
              <StyledDescriptionWrapper>
                <StyledTitle>Uwagi:</StyledTitle>
                Brak uwag
              </StyledDescriptionWrapper>
            )}

            {offerObject.wirless_controller ? (
              <StyledDescriptionWrapper>
                <StyledTitle>Sterownik bezprzewodowy:</StyledTitle>
                Tak
              </StyledDescriptionWrapper>
            ) : (
              <StyledDescriptionWrapper>
                <StyledTitle>Sterownik bezprzewodowy:</StyledTitle>
                Nie
              </StyledDescriptionWrapper>
            )}
            {offerObject.leaving_existing_source ? (
              <StyledDescriptionWrapper>
                <StyledTitle>
                  Pozostawienie istniejącego żródła ciepła:
                </StyledTitle>
                Tak
              </StyledDescriptionWrapper>
            ) : (
              <StyledDescriptionWrapper>
                <StyledTitle>
                  Pozostawienie istniejącego żródła ciepła:
                </StyledTitle>
                Nie
              </StyledDescriptionWrapper>
            )}
            {offerObject.flushing_system ? (
              <StyledDescriptionWrapper>
                <StyledTitle>Płukanie układu:</StyledTitle>
                Tak
              </StyledDescriptionWrapper>
            ) : (
              <StyledDescriptionWrapper>
                <StyledTitle>Płukanie układu:</StyledTitle>
                Nie
              </StyledDescriptionWrapper>
            )}

            {offerObject?.user && (
              <StyledUserWrapper>
                <StyledTitle>Oferta wygenerowana przez:</StyledTitle>
                <StyledUserLink>{offerObject.user.name}</StyledUserLink>
              </StyledUserWrapper>
            )}
            {offerObject?.file && (
              <StyledFileWrapper>
                <StyledTitle>Oferta:</StyledTitle>
                <StyledFileLink
                  href={`${offerObject.file.path_name}`}
                  download={offerObject.file.name}
                  target="_blank"
                >
                  <StyledFileContainer>
                    <PdfDownload />
                    <StyledFileNameWrapper>
                      <StyledPName title={offerObject.file.name}>
                        {offerObject.file.name}
                      </StyledPName>
                      <StyledFileDescriptionWrapper>
                        <StyledPDescription>PDF</StyledPDescription>
                        <StyledPDescription>
                          {offerObject.file.size
                            ? `${(
                                Number(offerObject.file.size) / 1048576
                              ).toFixed(2)} MB`
                            : ""}
                        </StyledPDescription>
                      </StyledFileDescriptionWrapper>
                    </StyledFileNameWrapper>
                  </StyledFileContainer>
                </StyledFileLink>
              </StyledFileWrapper>
            )}
          </StyledPhotovoltaicInstalationDetails>
          {opportunities?.status === 1 && (
            <StyledButtonWrapper>
              {/* <StyledCreateButton onClick={handleCreateSimilarOffer}>
                Stwórz podobną oferte{" "}
              </StyledCreateButton> */}

              {buttonContext}
            </StyledButtonWrapper>
          )}
        </StyledModal>
      )}

      <GenerateContract
        opportunities={opportunities}
        offerObject={offerObject}
        isModalOpen={isGenerateModalOpen}
        handleOnClose={handleOnCloseGenrateModal}
        fetchData={fetchData}
      />
    </Modal>
  );
};

const StyledAcceptanceButton = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledAcceptanceButtonNotClickable = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #cadeee;
  color: #053257;
  cursor: none;
  border-radius: 5px;
  pointer-events: none;
`;

const StyledCreateButton = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const StyledDescriptionWrapper = styled.div`
  margin-top: 10px;
`;
const StyledSpecialDiscountWrapper = styled.div`
  margin-top: 10px;
`;
const StyledServicesInfo = styled.p``;

const StyledServicesWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPowerWrapper = styled.div`
  margin-top: 10px;
`;

const StyledSecureInfo = styled.p``;

const StyledSecureWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPDescription = styled.p`
  margin-top: 5px;
  margin-right: 5px;
  font-size: 14px;
`;
const StyledPName = styled.p`
  margin-top: 5px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledFileDescriptionWrapper = styled.div`
  display: flex;
`;
const StyledFileNameWrapper = styled.div`
  width: calc(100% - 40px);
  p {
    color: #777;
  }
`;
const StyledFileLink = styled.a`
  text-decoration: none;
  display: block;
`;

const StyledFileContainer = styled.div`
  margin-top: 10px;
  display: flex;
  /* overflow: hidden; */

  svg {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 60px;
  }
`;

const StyledFileWrapper = styled.div`
  margin-top: 10px;
`;

const StyledUserLink = styled.p``;

const StyledUserWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPriceWrapper = styled.div`
  margin-top: 10px;
`;

const StyledEndDateWrapper = styled.div``;

const StyledConstructionWrapper = styled.div`
  margin-top: 10px;
`;

const StyledConstructionName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInverterWrapper = styled.div`
  margin-top: 10px;
`;

const StyledInverterName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPanelWrapper = styled.div``;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledPanelName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPhotovoltaicInstalationDetails = styled.div`
  margin-top: 20px;
`;

const StyledDateWrapper = styled.div``;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
  @media screen and (max-width: 768px) {
    top: 0;
    right: 0;
  }
`;

const StyledModal = styled.div`
  width: 360px;
  background-color: white;
`;

export default HeatPumpOfferDetails;
