import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SearchClientItem = ({ client }) => {
  return (
    <StyledClientWrapper
      to={`/dashboard/contacts/clients/details/${client.id}`}
    >
      <StyledClientName>
        {client.first_name} {client.last_name}
      </StyledClientName>
    </StyledClientWrapper>
  );
};

const StyledClientName = styled.p`
  color: #134771;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledClientWrapper = styled(Link)`
  background: white;
  padding: 10px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

export default SearchClientItem;
