import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";

import styled from "styled-components";

import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as PdfDownload } from "../../../../../../../img/pdf-download-2617.svg";
import Modal from "../../../../../../../components/Modal";
import request from "../../../../../../../helpers/request";
import ItemFile from "./ItemFile";

const ShowContractDetails = ({
  showModalOpen,
  handleOnClose,
  contract,
  fetchData,
  opportunities,
  handleOnOpenAnnexModal,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isSendedApproval, setIsSendedApproval] = useState(false);
  const [isSendedSigned, setIsSendedSigned] = useState(false);
  const [isSendedWin, setIsSendedWin] = useState(false);

  const handleSendToApproval = async () => {
    setIsSendedApproval(true);
    try {
      const { status } = await request.patch(
        `api/contract/send-to-approval/${contract.id}`,
        {
          send_approve: true,
        }
      );

      if (status === 200) {
        fetchData();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSendedApproval(false);
  };

  const handleSignedContract = async () => {
    setIsSendedSigned(true);
    try {
      const { status } = await request.patch(
        `api/contract/signed-contract/${contract.id}`,
        {
          signed: true,
        }
      );

      if (status === 200) {
        fetchData();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSendedSigned(false);
  };

  const handleWinOpportunity = async () => {
    setIsSendedWin(true);
    try {
      const { status } = await request.patch(
        `api/contract/win-opportunity/${contract.id}`,
        {
          status: 2,
        }
      );

      if (status === 200) {
        fetchData();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSendedWin(false);
  };

  return (
    <Modal
      isModalOpen={showModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      {contract && (
        <StyledModal>
          <StyledDateWrapper>
            Data utworzenia:{" "}
            {moment(contract.created_at).format("DD.MM.YY HH.mm")}
          </StyledDateWrapper>

          <StyledPhotovoltaicInstalationDetails>
            {contract?.user && (
              <StyledUserWrapper>
                <StyledTitle>Umowa wygenerowana przez:</StyledTitle>
                <StyledUserLink>{contract.user.name}</StyledUserLink>
              </StyledUserWrapper>
            )}
            {contract?.offer_number && (
              <StyledItemWrapper style={{ color: "rgb(244,118,49)" }}>
                <StyledItemTitle>Oferta nr :</StyledItemTitle>
                <StyledItemValue>{contract?.offer_number}</StyledItemValue>
              </StyledItemWrapper>
            )}

            <StyledItemWrapper>
              <StyledItemTitle>Rodzaj płatności:</StyledItemTitle>
              {Number(contract.payment_type) === 1 && (
                <StyledItemValue>Płatność kredytem</StyledItemValue>
              )}
              {Number(contract.payment_type) === 2 && (
                <StyledItemValue>
                  Płatność kredytem z wkładem własnym
                </StyledItemValue>
              )}
              {Number(contract.payment_type) === 3 && (
                <StyledItemValue>Płatność leasing</StyledItemValue>
              )}
              {Number(contract.payment_type) === 4 && (
                <StyledItemValue>Płatność ze środków własnych</StyledItemValue>
              )}
            </StyledItemWrapper>

            {contract.payment_type === 1 && (
              <StyledItemWrapper>
                <StyledItemTitle>Wartość kredytu</StyledItemTitle>
                <StyledItemValue>
                  {contract?.loan_value ? `${contract?.loan_value} zł` : ""}
                </StyledItemValue>
              </StyledItemWrapper>
            )}

            {contract.payment_type === 2 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Wartość umowy</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.loan_brutto_price
                      ? `${contract?.loan_brutto_price} zł `
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>

                <StyledItemWrapper>
                  <StyledItemTitle>Wpłata własna</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.loan_self_deposit
                      ? `${contract?.loan_self_deposit} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>

                <StyledItemWrapper>
                  <StyledItemTitle>Wartość kredytu</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.loan_value_deposit
                      ? `${contract?.loan_value_deposit} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
              </>
            )}

            {contract.payment_type === 3 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Leasing cena netto</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.lease_netto_price
                      ? `${contract?.lease_netto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>

                <StyledItemWrapper>
                  <StyledItemTitle>Leasing cena brutto</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.lease_brutto_price
                      ? `${contract?.lease_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
              </>
            )}

            {contract.payment_type === 4 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Pierwsza transza</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.first_tranche_percentage
                      ? `${contract?.first_tranche_percentage}%`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Pierwsza transza cena</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.first_tranche_brutto_price
                      ? `${contract?.first_tranche_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Druga transza</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.second_tranche_percentage
                      ? `${contract?.second_tranche_percentage} %`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Druga transza cena</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.second_tranche_brutto_price
                      ? `${contract?.second_tranche_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Trzecia transza</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.third_tranche_percentage
                      ? `${contract?.third_tranche_percentage} %`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Trzecia transza cena</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.third_tranche_brutto_price
                      ? `${contract?.third_tranche_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
              </>
            )}

            {contract?.offer && (
              <StyledItemWrapper>
                <StyledItemTitle>Marża :</StyledItemTitle>
                <StyledItemValue>{contract?.offer?.margin} zł</StyledItemValue>
              </StyledItemWrapper>
            )}

            <StyledItemWrapper>
              <StyledItemTitle>Moc instalacji</StyledItemTitle>
              <StyledItemValue>
                {contract?.investor_instalation_power
                  ? `${contract?.investor_instalation_power} kWp`
                  : ""}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Termin realizacji</StyledItemTitle>
              <StyledItemValue>
                {contract?.realization_date
                  ? `${contract?.realization_date} tyg`
                  : ""}
              </StyledItemValue>
            </StyledItemWrapper>

            {contract?.services_5 && (
              <StyledItemArragementWrapper>
                <StyledItemTitleArragement>
                  Ustalenia dodatkowe:
                </StyledItemTitleArragement>
                <StyledItemValueArragement>
                  {contract?.services_5}
                </StyledItemValueArragement>
              </StyledItemArragementWrapper>
            )}

            {contract.accepting_user && (
              <StyledItemWrapper>
                <StyledItemTitle>Zaakceptował:</StyledItemTitle>
                <StyledItemValue>
                  {contract.accepting_user.name}

                  {contract.accepted_date && (
                    <p>
                      Dnia:{" "}
                      {moment(contract.accepted_date).format("DD.MM.YYYY")}
                    </p>
                  )}
                </StyledItemValue>
              </StyledItemWrapper>
            )}
            {contract.rejecting_user && (
              <StyledItemWrapper>
                <StyledItemTitle>Odrzucił:</StyledItemTitle>
                <StyledItemValue>
                  {contract.rejecting_user.name}

                  {contract.rejected_date && (
                    <p>
                      Dnia:{" "}
                      {moment(contract.rejected_date).format("DD.MM.YYYY")}
                    </p>
                  )}
                </StyledItemValue>
              </StyledItemWrapper>
            )}

            {contract?.file && (
              <StyledFileWrapper>
                <StyledTitle>Umowa:</StyledTitle>
                <StyledFileLink
                  href={`${contract.file.path_name}`}
                  download={contract.file.name}
                  target="_blank"
                >
                  <StyledFileContainer>
                    <PdfDownload />
                    <StyledFileNameWrapper>
                      <StyledPName title={contract.file.name}>
                        {contract.file.name}
                      </StyledPName>
                      <StyledFileDescriptionWrapper>
                        <StyledPDescription>PDF</StyledPDescription>
                        <StyledPDescription>
                          {contract.file.size
                            ? `${(Number(contract.file.size) / 1048576).toFixed(
                                2
                              )} MB`
                            : ""}
                        </StyledPDescription>
                      </StyledFileDescriptionWrapper>
                    </StyledFileNameWrapper>
                  </StyledFileContainer>
                </StyledFileLink>
              </StyledFileWrapper>
            )}

            {contract.lost_reason ? (
              <LostReasonWrapper>
                <LostReasonTitle>
                  Powód przegrania szansy sprzedaży:
                </LostReasonTitle>
                {contract.lost_reason.reason}

                <StyledAnexFileContainer>
                  {contract.lost_reason?.files &&
                    contract.lost_reason?.files.map((file) => (
                      <ItemFile key={file.id} fileItem={file} />
                    ))}
                </StyledAnexFileContainer>
              </LostReasonWrapper>
            ) : null}
          </StyledPhotovoltaicInstalationDetails>
          {contract.is_accepted === 0 &&
            contract.send_for_approval === 0 &&
            opportunities.status === 1 && (
              <StyledButtonWrapper>
                <StyledButton
                  onClick={handleSendToApproval}
                  disabled={isSendedApproval}
                >
                  Wyślij do akceptacji
                </StyledButton>
              </StyledButtonWrapper>
            )}

          {contract.is_accepted === 1 &&
            contract.is_signed === 0 &&
            opportunities.status === 1 && (
              <StyledButtonWrapper>
                <StyledButton
                  onClick={handleSignedContract}
                  disabled={isSendedSigned}
                >
                  Umowa podpisana
                </StyledButton>
              </StyledButtonWrapper>
            )}

          {contract.is_accepted === 1 &&
            contract.is_signed === 1 &&
            opportunities.status === 1 && (
              <StyledButtonWrapper>
                <StyledButton
                  onClick={handleWinOpportunity}
                  disabled={isSendedWin}
                >
                  Wygrana Szansa sprzedaży
                </StyledButton>
              </StyledButtonWrapper>
            )}
          {/* {Number(opportunities.status) === 2 && contract.is_win === 1 && (
            <StyledButtonWrapper>
              <StyledButton onClick={handleOnOpenAnnexModal}>
                Stwórz aneks
              </StyledButton>
            </StyledButtonWrapper>
          )} */}
        </StyledModal>
      )}
    </Modal>
  );
};

const StyledAnexFileContainer = styled.div`
  padding-bottom: 10px;
  max-width: 360px;
  display: grid;
  padding-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 120px));
`;

const LostReasonTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const LostReasonWrapper = styled.div``;

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledItemValue = styled.div`
  margin-left: 5px;
`;
const StyledItemWrapper = styled.div`
  padding-bottom: 5px;
  display: flex;
`;

const StyledItemTitle = styled.div`
  font-weight: bold;
`;
const StyledItemArragementWrapper = styled.div`
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
`;
const StyledItemValueArragement = styled.div`
  margin-left: 5px;
  text-align: justify;
`;

const StyledItemTitleArragement = styled.div`
  font-weight: bold;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const StyledPDescription = styled.p`
  margin-top: 5px;
  margin-right: 5px;
  font-size: 14px;
`;
const StyledPName = styled.p`
  margin-top: 5px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledFileDescriptionWrapper = styled.div`
  display: flex;
`;
const StyledFileNameWrapper = styled.div`
  width: calc(100% - 40px);
  p {
    color: #777;
  }
`;
const StyledFileLink = styled.a`
  text-decoration: none;
  display: block;
`;

const StyledFileContainer = styled.div`
  margin-top: 10px;
  display: flex;
  /* overflow: hidden; */

  svg {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 60px;
  }
`;

const StyledFileWrapper = styled.div`
  margin-top: 10px;
`;

const StyledUserLink = styled.p``;

const StyledUserWrapper = styled.div`
  margin: 10px 0;
`;

const StyledPhotovoltaicInstalationDetails = styled.div`
  margin-top: 20px;
`;

const StyledDateWrapper = styled.div``;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledModal = styled.div`
  width: 360px;
  background-color: white;
`;

export default ShowContractDetails;
