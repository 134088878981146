import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../store/StoreProvider";
import AddMargin from "./Components/AddMargin";

const OfferSettings = () => {
  const { user } = useContext(StoreContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsEditMode(false);
  };

  const handleEditModal = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  return (
    <StyledWrapper>
      <StyledMarginWrapper>
        {!user.offer_margin ? (
          <StyledInfoWrapper>
            Marża na 1 kWp w ofertówce: Nie ustalono{" "}
          </StyledInfoWrapper>
        ) : (
          <StyledInfoWrapper>
            Marża na 1 kWp w ofertówce:{" "}
            {user.offer_margin ? `${user.offer_margin} zł` : ""}
          </StyledInfoWrapper>
        )}
        <StyledButtonWrapper>
          {!user.offer_margin ? (
            <StyledButton onClick={handleOpenModal}>
              Dodaj wartość marży
            </StyledButton>
          ) : (
            <StyledButton onClick={handleEditModal}>
              Zmień wartość marży
            </StyledButton>
          )}
        </StyledButtonWrapper>
      </StyledMarginWrapper>

      <AddMargin
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        isEditMode={isEditMode}
      />
    </StyledWrapper>
  );
};

const StyledButtonWrapper = styled.div``;

const StyledButton = styled.button`
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  background: rgb(19, 71, 113);
  color: rgb(202, 222, 238);
`;

const StyledInfoWrapper = styled.div`
  margin-right: 10px;
`;

const StyledMarginWrapper = styled.div`
  background: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
`;

export default OfferSettings;
