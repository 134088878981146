// const isSelected = (day, value) => {
//   return value.isSame(day, "day");
// };

const isToday = (day) => {
  return day.isSame(new Date(), "day");
};

const dayStyles = (day, value) => {
  // if (isSelected(day, value)) {
  //   return "selected";
  // }
  if (isToday(day)) {
    return "today";
  }
  return "";
};

export default dayStyles;
