import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";

import Today from "./Today";
import TodoSideBarList from "./TodoSideBarList";

const TodoSideBar = () => {
  const [todoList, setTodoList] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [taskDeleyCount, setTaskDeleyCount] = useState(0);
  const [filterOption, setFilterOption] = useState(1);
  const [hideOld, setHideOld] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const fetchToDo = async () => {
    try {
      const { status, data } = await request.get("/api/user-todo", {
        params: {
          filter: filterOption,
        },
      });
      if (status === 200) {
        setTodoList(data.todos);
        setTaskCount(data.today);
        setTaskDeleyCount(data.deley);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    fetchToDo();
  }, [filterOption]);

  return (
    <StyledToDolist>
      <Today taskCount={taskCount} taskDeleyCount={taskDeleyCount} />
      <TodoSideBarList
        todoList={todoList}
        fetchData={fetchToDo}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        setHideOld={setHideOld}
        hideOld={hideOld}
      />
    </StyledToDolist>
  );
};

const StyledToDolist = styled.div`
  min-width: 340px;
  max-width: 380px;
  flex: 0 0 auto;
  background: #fff;
  border-radius: 10px;
`;

export default TodoSideBar;
