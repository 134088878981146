import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AddClients from "./Components/AddClients";
import AddCompany from "./Components/AddCompany";

const CreateContact = ({ lead, fetchData }) => {
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

  const handleOnCloseClientModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsClientModalOpen(false);
  };

  const handleOnCloseCompanyModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsCompanyModalOpen(false);
  };

  const handleOnOpenClientModal = () => {
    setIsClientModalOpen(true);
  };
  const handleOnOpenCompanyModal = () => {
    setIsCompanyModalOpen(true);
  };

  return (
    <StyledWrapper>
      {lead.contact_create === 0 ? (
        <>
          {" "}
          <StyledButtonWrapper>
            <StyledButton onClick={handleOnOpenClientModal}>
              Stwórz klienta
            </StyledButton>
            <StyledButton onClick={handleOnOpenCompanyModal}>
              Stwórz firmę
            </StyledButton>
          </StyledButtonWrapper>
          <AddClients
            lead={lead}
            isModalOpen={isClientModalOpen}
            handleOnClose={handleOnCloseClientModal}
            fetchData={fetchData}
          />
          <AddCompany
            lead={lead}
            isModalOpen={isCompanyModalOpen}
            handleOnClose={handleOnCloseCompanyModal}
            fetchDate={fetchData}
          />
        </>
      ) : (
        <>
          <StyledContactCreated>
            Stworzono juz klienta/firmę
          </StyledContactCreated>
          {lead.client && (
            <StyledDirectionLink
              to={`/dashboard/contacts/clients/details/${lead.client.id}`}
            >
              {lead.client.first_name} {lead.client.last_name}
            </StyledDirectionLink>
          )}
          {lead.company && (
            <StyledDirectionLink
              to={`/dashboard/contacts/companies/details/${lead.company.id}`}
            >
              {lead.company.name}
            </StyledDirectionLink>
          )}
        </>
      )}
    </StyledWrapper>
  );
};

const StyledDirectionLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none;
  &:hover {
    color: #134771;
    text-decoration: underline;
  }
`;

const StyledContactCreated = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: rgb(19, 71, 113);
  color: rgb(202, 222, 238);
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledWrapper = styled.div`
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;

  padding: 10px;
`;
export default CreateContact;
