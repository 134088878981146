import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router";

import CompanyForm from "./Components/CompanyForm";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import request from "../../../../../../../helpers/request";
import Modal from "../../../../../../../components/Modal";

const validationSchema = yup.object({
  instalation_id: yup.string().required("Wybierz instalacje fotowoltaiczną"),
  construction_id: yup.string().required("Wybierz konstrukcje montażową"),
  investor_address: yup
    .string()
    .required("Adres wymagany sprawdź poprawność danych inwestora")
    .typeError("Adres wymagany sprawdź poprawność danych inwestora"),
  panel_count: yup
    .string()
    .required("Podaj ilość paneli fotowoltaicznych")
    .matches(/^[0-9]+$/, "Ilość paneli musi składać się wyłącznie z cyfr 0-9"),
  inverter_count: yup
    .string()
    .required("Podaj ilość falowników")
    .matches(
      /^[0-9]+$/,
      "Ilość falowników musi składać się wyłącznie z cyfr 0-9"
    )
    .min(1),
  construction_count: yup
    .string()
    .required("Podaj ilość konstrukcji montażowych")
    .matches(
      /^[0-9]+$/,
      "Ilosć konstrukcji musi składać się wyłącznie z cyfr 0-9"
    ),
  power: yup
    .number()
    .typeError("Podana wartość musin być liczbą")
    .required("Podaj ilość konstrukcji montażowych"),
  price_netto: yup
    .number()
    .typeError("Podana wartość musin być liczbą")
    .required("Suma netto jest wymagana"),
  price_vat: yup
    .number()
    .typeError("Podana wartość musin być liczbą")
    .required("Suma VAT jest wymagana"),
  price_sum: yup
    .number()
    .typeError("Podana wartość musin być liczbą")
    .required("Suma razem jest wymagana"),
  offer_date: yup
    .number()
    .typeError("Podana wartość musin być liczbą")
    .required("Ważność oferty jest wymagana"),
  services: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Nazwa jest wymagana."),
      price: yup
        .string()
        .matches(/^[0-9]+$/, "Cena musi składać się wyłącznie z cyfr 1-9")
        .transform((cv, ov) => {
          return ov === "" ? null : cv;
        })
        .nullable(true),
    })
  ),
});

const MakeCompanyFarmerOffer = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  company,
  opportunities_id,
}) => {
  const { constructions, setConstructions, user, setOpportunities } =
    useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      inverter_count: 1,
      construction_count: 1,
      offer_date: 7,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });

  const watchFieldArray = watch("services");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const resetInputValue = () => {
    setPanelCount(minPanels);
    setInstalation(null);
    setConstruction(null);
    setMargin(0);
    setVatRate(23);
    setSpecialDiscountManagement(0);
    setSpecialDiscountManager(0);
    setWireLength(0);
    setCutLength(0);
    setCutIndividualPricing(false);
    setWireIndividualPricing(false);
    reset({
      instalation_id: "",
      construction_id: "",
      inverter_count: 1,
      construction_count: 1,
      margin: 0,
      special_discount_management: 0,
      special_discount_manager: 0,
      cut_length: 0,
      cut_length_price: 0,
      wire_length: 0,
      wire_length_price: 0,
      offer_date: 7,
      offer_type: "farmer",
      description: "",
    });
  };

  const [instalation, setInstalation] = useState([]);
  const [construction, setConstruction] = useState(null);
  const [panelCount, setPanelCount] = useState(0);
  const [optimizer, setOptimizer] = useState(null);
  const [optimizerValue, setOptimizerValue] = useState("");
  const [inverterCount, setInverterCount] = useState(1);
  const [vatRate, setVatRate] = useState(23);
  const [margin, setMargin] = useState(0);
  const [antiFireCost, setAntiFireCost] = useState(0);
  const [instalationType, setInstaltaionType] = useState([]);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [investorName, setInvestorName] = useState("");
  const [investorAdress, setInvestorAdress] = useState("");
  const [specialDiscountManagement, setSpecialDiscountManagement] = useState(0);
  const [specialDiscountManager, setSpecialDiscountManager] = useState(0);
  const [cutLength, setCutLength] = useState(0);
  const [wireLength, setWireLength] = useState(0);
  const [optimizers, setOptimizers] = useState([]);
  const [minModules, setMinModules] = useState(0);
  const [minModulesRWB, setMinModulesRWB] = useState(0);
  const [minPanels, setMinPanels] = useState(1);
  const [minOptimizers, setMinOptimizers] = useState(0);
  const [isSended, setIsSended] = useState(false);
  const [endDate, setEndDate] = useState(7);
  const [cutIndividualPricing, setCutIndividualPricing] = useState(false);
  const [wireIndividualPricing, setWireIndividualPricing] = useState(false);
  const [minPower, setMinPower] = useState(null);
  const [maxPower, setMaxPower] = useState(null);

  const fetchOpportunities = async () => {
    try {
      const { status, data } = await request.get("/api/opportunities");
      if (status === 200) {
        setOpportunities(data.data);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  //Funkcja generowania oferty

  const handleOnSubmitwithClose = async (sendData) => {
    setIsSended(true);
    try {
      const { data, status } = await request.post("/api/offer-opportunity", {
        ...sendData,
      });

      if (status === 200) {
        fetchData();
        fetchOpportunities();
        resetInputValue();
        handleOnClose();
        if (data.sale_opportunity_id) {
          history.push(
            `/dashboard/opportunities/details/${data.sale_opportunity_id}`
          );
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSendToPricing = async (sendData) => {
    setIsSended(true);
    try {
      const { data, status } = await request.post(
        "/api/send-to-pricing-opportunity",
        {
          ...sendData,
        }
      );

      if (status === 200) {
        fetchData();
        fetchOpportunities();
        resetInputValue();
        handleOnClose();
        if (data.sale_opportunity_id) {
          history.push(
            `/dashboard/opportunities/details/${data.sale_opportunity_id}`
          );
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  // Funkcja wyboru optymalizaotora

  const handleOnChooseOptimizer = (e) => {
    if (Number(e.target.value) > 0) {
      const optimizer = optimizers.filter(
        (opt) => opt.id === Number(e.target.value)
      )[0];
      setOptimizer(optimizer);
      setValue("optimizer_id", optimizer.id);
    } else {
      setOptimizer(null);
      setValue("optimizer_id", null);
    }
  };

  const handleOnChangeIverterCount = (e) => {
    if (e.target.value) {
      if (e.target.value < 1) {
        setInverterCount(1);
        setValue("inverter_count", 1);
      } else {
        setInverterCount(Number(e.target.value));
        setValue("inverter_count", e.target.value);
      }
    } else {
      setInverterCount(null);
      setValue("inverter_count", null);
    }
  };

  const handleOnBLurInverter = (e) => {
    if (!e.target.value) {
      setInverterCount(1);
      setValue("inverter_count", 1);
    }
  };

  const handleOnChooseInstalation = (e) => {
    if (Number(e.target.value) > 0) {
      const inst = instalationType.filter(
        (instalation) => instalation.id === Number(e.target.value)
      )[0];

      if (inst) {
        setOptimizers(inst.inverter?.optimizers);
        setOptimizer(null);
        setMinModules(inst.inverter?.min_modules);
        setMinModulesRWB(inst.inverter?.min_modules_rwb);
        setValue(
          "price_include_optimizers",
          inst.inverter?.price_include_optimizers
        );

        const panelsCount = getValues("panel_count");
        const minPanels =
          inst.inverter?.min_modules_rwb || inst.inverter?.min_modules;
        if (Number(panelsCount) < Number(minPanels)) {
          setValue("panel_count", minPanels || 1);
          setPanelCount(minPanels || 1);
        }
        setMinPanels(
          inst.inverter?.min_modules_rwb || inst.inverter?.min_modules || 1
        );
        setMinOptimizers(inst.inverter?.min_optimizers || "");
        setOptimizerValue("");
        setValue("optimizer_count", "");
        setInstalation(inst);

        if (inst.inverter?.min_power) {
          setMinPower(inst.inverter?.min_power);
          const minPanels =
            (Number(inst.inverter?.min_power) * 1000) /
            Number(inst.solar_panel.power);
          if (minPanels) {
            setPanelCount(Number(minPanels.toFixed()));
            setValue("panel_count", Number(minPanels.toFixed()));
          }
        } else {
          setMinPower(null);
        }

        if (inst.inverter?.max_power) {
          setMaxPower(inst.inverter?.max_power);
        } else {
          setMaxPower(null);
        }

        if (inst.inverter?.additional_services?.length > 0) {
          inst.inverter.additional_services.forEach((serv) => {
            if (
              watchFieldArray.filter(
                (services) => services.name === serv.service_name
              ).length > 0
            ) {
            } else {
              append(
                { name: serv.service_name, price: serv.service_price },
                { shouldFocus: false }
              );
            }
          });
        }

        CheckIsRWB(
          inst.inverter?.min_modules,
          inst.inverter?.min_modules_rwb,
          Number(panelCount) < Number(minPanels)
            ? Number(minPanels)
            : Number(panelCount)
        );
      }
    } else {
      setInstalation(null);
    }
  };

  const handleOnCalcPower = () => {
    if (instalation && construction && construction.length > 15) {
      setValue("instalation_id", instalation.id);
      const construct = JSON.parse(construction);
      setValue("construction_id", construct.id);
      setValue("power", (panelCount * instalation.solar_panel?.power) / 1000);
      const functionPower =
        (panelCount * instalation.solar_panel?.power) / 1000;
      if (optimizer) {
        setValue("optimizer_id", optimizer.id);
        setValue("optimizer_price", Number(optimizer.price) * optimizerValue);
      } else {
        setValue("optimizer_id", null);
        setValue("optimizer_price", null);
      }

      setValue("vat", vatRate);

      if (functionPower >= 6.5) {
        if (!getValues("secure").includes("Przeciwpożarowe")) {
          const inputValue = getValues("secure");
          setAntiFireCost(1000);
          setValue("anti_fire_cost", 1000);
          setValue("secure", `Przeciwpożarowe ${inputValue}`);
        } else {
        }
      } else {
        if (getValues("secure").includes("Przeciwpożarowe")) {
          const inputValue = getValues("secure").replace("Przeciwpożarowe", "");
          setAntiFireCost(0);
          setValue("anti_fire_cost", 0);
          setValue("secure", inputValue);
        }
      }

      // const margin_function = functionPower * (user.offer_margin || 0);
      // setMargin(margin_function);
      // const margin_with_discount = margin_function - specialDiscount;
      setValue("margin", margin);

      let sumall;
      if (watchFieldArray) {
        sumall = watchFieldArray
          .map((item) => item.name.trim() && item.price)
          .reduce((prev, curr) => Number(prev) + Number(curr), 0);
      } else {
        sumall = 0;
      }

      let cutPrice = 0;
      let wirePrice = 0;

      if (cutLength > 10 && cutLength <= 90) {
        setCutIndividualPricing(false);

        if (user.cut_and_wire_price) {
          const priceArray = user.cut_and_wire_price.find(
            (price) => cutLength <= price.up_to_meter
          );
          if (functionPower < 10) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_10) + 20);
          } else if (functionPower < 20) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_20) + 20);
          } else if (functionPower < 30) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_30) + 20);
          } else if (functionPower < 40) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_40) + 20);
          } else {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_50) + 20);
          }
          setValue("cut_length_price", cutPrice.toFixed());
        } else {
          setValue("cut_length_price", 0);
        }
      } else if (cutLength > 90) {
        setValue("cut_length_price", 0);
        setCutIndividualPricing(true);
      } else {
        setCutIndividualPricing(false);
        setValue("cut_length_price", 0);
      }

      if (wireLength > 10 && wireLength <= 90) {
        setWireIndividualPricing(false);

        if (user.cut_and_wire_price) {
          const priceArray = user.cut_and_wire_price.find(
            (price) => wireLength <= price.up_to_meter
          );
          if (functionPower < 10) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_10);
          } else if (functionPower < 20) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_20);
          } else if (functionPower < 30) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_30);
          } else if (functionPower < 40) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_40);
          } else {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_50);
          }
          setValue("wire_length_price", wirePrice.toFixed());
        } else {
          setValue("wire_length_price", 0);
        }
      } else if (wireLength > 90) {
        setValue("wire_length_price", 0);
        setWireIndividualPricing(true);
      } else {
        setWireIndividualPricing(false);
        setValue("wire_length_price", 0);
      }

      if (functionPower <= 4) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_4 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_4 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }

        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 5) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_5 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_5 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 6) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_6 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_6 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 7) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_7 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_7 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 8) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_8 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_8 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 9) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_9 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_9 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 10) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_10 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_10 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 15) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_15 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_15 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 20) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_20 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_20 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 30) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_30 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_30 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 40) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_40 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_40 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 50) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_50 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_50 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      } else {
        let netto = 0;
        if (optimizer?.price && Number(optimizerValue) > 0) {
          netto =
            functionPower * instalation.price_to_50 +
            functionPower * construct.price +
            Number(optimizer.price) * optimizerValue +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * instalation.price_to_50 +
            functionPower * construct.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construct.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);
        const vatRounded = bruttoRounded - nettoRounded;

        setValue("price_sum", Number(bruttoRounded).toFixed(2));
        setValue("price_vat", Number(vatRounded).toFixed(2));
        setValue("price_netto", Number(nettoRounded).toFixed(2));
      }
    } else {
      setValue("price_sum", 0);
      setValue("price_vat", 0);
      setValue("price_netto", 0);
      setValue("power", 0);
    }
  };

  const fetchConstructions = async () => {
    try {
      const { status, data } = await request.get("/api/constructions");
      if (status === 200) {
        setConstructions(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push("login");
      }
    }
  };

  useEffect(() => {
    if (constructions === undefined || constructions.length === 0) {
      fetchConstructions();
    }
  }, []);
  useEffect(() => {
    if (company) {
      setValue("company_id", company.id);
      setValue("investor_name", ` ${company.name}`);
      setInvestorName(`${company.name}`);

      if (
        company.city &&
        company.building_number &&
        company.post_code &&
        company.post &&
        company.street
      ) {
        setInvestorAdress(
          `ul. ${company?.street} ${company?.building_number}, ${company?.city} ${company?.post_code} ${company?.post}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street} ${company?.building_number}, ${company?.city} ${company?.post_code} ${company?.post}`
        );
      } else if (
        company?.city &&
        company?.building_number &&
        company?.post &&
        company?.post_code
      ) {
        setInvestorAdress(
          `${company?.city} ${company?.building_number}, ${company?.post_code} ${company?.post} `
        );
        setValue(
          "investor_address",
          `${company?.city} ${company?.building_number}, ${company?.post_code} ${company?.post} `
        );
      } else if (
        company?.city &&
        company?.street &&
        company?.post &&
        company?.post_code
      ) {
        setInvestorAdress(
          `ul. ${company?.street} ${company?.city}, ${company?.post_code} ${company?.post}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street} ${company?.city}, ${company?.post_code} ${company?.post}`
        );
      } else if (
        company.city &&
        company.building_number &&
        company.post_code &&
        company.street
      ) {
        setInvestorAdress(
          `ul. ${company?.street} ${company?.building_number}, ${company?.post_code} ${company?.city}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street} ${company?.building_number}, ${company?.post_code} ${company?.city}`
        );
      } else if (
        company?.city &&
        company?.building_number &&
        company?.post_code
      ) {
        setInvestorAdress(
          `${company?.building_number}, ${company?.post_code} ${company?.city} `
        );
        setValue(
          "investor_address",
          `${company?.building_number}, ${company?.post_code} ${company?.city} `
        );
      } else if (company?.city && company?.street && company?.post_code) {
        setInvestorAdress(
          `ul. ${company?.street}, ${company?.post_code} ${company?.city}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street}, ${company?.post_code} ${company?.city}`
        );
      } else {
        setValue("investor_address", null);
        setInvestorAdress("Sprawdź poprawność danych inwestora");
      }
    }
  }, [
    company,
    company?.post_code,
    company?.street,
    company?.city,
    company?.building_number,
    isModalOpen,
  ]);

  useEffect(() => {
    setValue("opportunity_id", opportunities_id);
  }, [opportunities_id]);

  useEffect(() => {
    setValue("special_discount_management", specialDiscountManagement);
    setValue("special_discount_manager", specialDiscountManager);
    handleOnCalcPower();
  }, [
    instalation,
    panelCount,
    vatRate,
    margin,
    construction,
    specialDiscountManagement,
    specialDiscountManager,
    cutLength,
    wireLength,
    optimizer,
    antiFireCost,
    optimizerValue,
    JSON.stringify(watchFieldArray),
    watch,
  ]);

  useEffect(() => {
    if (user.offer_form?.instalation?.length > 0) {
      setInstaltaionType(
        user.offer_form?.instalation?.filter(
          (instalation) => instalation.is_active === 1
        )
      );
    }
  }, [user]);

  const handleOpenCompanyInfo = (e) => {
    e.preventDefault();
    setShowCompanyInfo((prev) => !prev);
  };

  useEffect(() => {
    setValue("offer_type", "farmer");
  }, []);

  const handleSetHeight = (e) => {
    e.target.style.height = "inherit";

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    e.target.style.height = `${height}px`;
  };
  const handleOnPanelCountChange = (e) => {
    setPanelCount(e.target.value);
    setValue("panel_count", e.target.value);
    if (e.target.value >= minModules) {
      CheckIsRWB(minModules, minModulesRWB, Number(e.target.value));
    }
  };
  const handleOnPanelChangeBlur = (e) => {
    if (minModules && minModulesRWB !== 0) {
      if (e.target.value < minModulesRWB) {
        setPanelCount(minModulesRWB);
        setValue("panel_count", minModulesRWB);
        CheckIsRWB(minModules, minModulesRWB, minModulesRWB);
      } else {
        setPanelCount(e.target.value);
        setValue("panel_count", e.target.value);
        CheckIsRWB(minModules, minModulesRWB, Number(e.target.value));
      }
    } else if (minModules && minModulesRWB === 0) {
      if (e.target.value < minModules) {
        setPanelCount(minModules);
        setValue("panel_count", minModules);
        CheckIsRWB(minModules, minModulesRWB, minModules);
      } else {
        setPanelCount(e.target.value);
        setValue("panel_count", e.target.value);
        CheckIsRWB(minModules, minModulesRWB, Number(e.target.value));
      }
    }
    if (instalation.inverter && instalation.solar_panel) {
      if (minPower) {
        const panelAmount = (minPower * 1000) / instalation.solar_panel?.power;
        if (panelAmount > panelCount) {
          setPanelCount(Number(panelAmount.toFixed()));
          setValue("panel_count", Number(panelAmount.toFixed()));
        }
      }
      if (maxPower) {
        const panelAmount = (maxPower * 1000) / instalation.solar_panel?.power;
        if (panelAmount < panelCount) {
          setPanelCount(Number(panelAmount.toFixed()));
          setValue("panel_count", Number(panelAmount.toFixed()));
        }
      }
    }
  };

  const handleOnChangeMargin = (e) => {
    if (e.target.value < 0) {
      setMargin(0);
    } else {
      setMargin(e.target.value);
    }
  };

  const handleOnChangeEndDate = (e) => {
    if (e.target.value < 0) {
      setEndDate(0);
      setValue("offer_date", 0);
    } else if (e.target.value > 7) {
      setEndDate(7);
      setValue("offer_date", 7);
    } else {
      setEndDate(Number(e.target.value).toFixed());
      setValue("offer_date", Number(e.target.value).toFixed());
    }
  };

  const handleOnChangeCutLength = (e) => {
    if (e.target.value < 0) {
      setCutLength(0);
      setValue("cut_length", 0);
    } else {
      setCutLength(Number(e.target.value).toFixed());
      setValue("cut_length", Number(e.target.value).toFixed());
      setWireLength(0);
      setValue("wire_length", 0);
    }
  };

  const handleOnChangeWireLength = (e) => {
    if (e.target.value < 0) {
      setWireLength(0);
      setValue("wire_length", 0);
    } else {
      setWireLength(Number(e.target.value).toFixed());
      setValue("wire_length", Number(e.target.value).toFixed());
      setCutLength(0);
      setValue("cut_length", 0);
    }
  };

  const CheckIsRWB = (minModule, minModuleRwb, panelCount) => {
    if (minModule && minModuleRwb === 0) {
      if (panelCount < minModule) {
        if (
          watchFieldArray.filter((services) => services.name === "Inverter RWB")
            .length > 0
        ) {
        } else {
          append(
            { name: "Inverter RWB", price: "1500" },
            { shouldFocus: false }
          );
        }
      } else {
        const index = watchFieldArray.findIndex(
          (services) => services.name === "Inverter RWB"
        );
        if (index >= 0) {
          remove(index);
        }
      }
    } else if (minModule && minModuleRwb !== 0) {
      if (
        Number(panelCount) >= minModuleRwb &&
        Number(panelCount) <= minModule
      ) {
        if (
          watchFieldArray.filter((services) => services.name === "Inverter RWB")
            .length > 0
        ) {
        } else {
          append(
            { name: "Inverter RWB", price: "1500" },
            { shouldFocus: false }
          );
        }
      } else {
        const index = watchFieldArray.findIndex(
          (services) => services.name === "Inverter RWB"
        );
        if (index >= 0) {
          remove(index);
        }
      }
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledFormHeader>
        <StyledCloseButton onClick={handleOnClose} type="button">
          <FontAwesomeIcon icon={faTimes} />
        </StyledCloseButton>
      </StyledFormHeader>

      <StyledInvestorInformationWrapper>
        <StyledCopmanyInformationWrapper>
          <CompanyForm
            company={company}
            fetchData={fetchData}
            showCompanyInfo={showCompanyInfo}
            setShowCompanyInfo={setShowCompanyInfo}
          />
        </StyledCopmanyInformationWrapper>
      </StyledInvestorInformationWrapper>
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        {/* {!user.offer_margin && (
          <div style={{ padding: "10px 0" }}>
            <p style={{ color: "red", fontSize: "14px" }}>
              W ustawieniach profilu proszę wpisać warośc marży na 1 kWp
            </p>
          </div>
        )} */}
        <StyledInvestorInfoWrapper>
          <StyledInvestorInfoTitleWrapper>
            <StyledInvestorInfoTitle>Dane do oferty:</StyledInvestorInfoTitle>
          </StyledInvestorInfoTitleWrapper>
          <StyledInvestorInfoContentWrapper>
            <StyledInvestorDetailsWrapper>
              <StyledInvestorNameWrapper>
                <StyledInvestorName title={investorName}>
                  Inwestor: {investorName}
                </StyledInvestorName>
                <input type="hidden" {...register("investor_name")} />
              </StyledInvestorNameWrapper>
              <StyledInvestorAdressWrapper>
                <StyledInvestorAdress title={investorAdress}>
                  Adres: {investorAdress}
                </StyledInvestorAdress>
                {errors?.investor_address && (
                  <span className="error">
                    {errors.investor_address.message}
                  </span>
                )}
                <input type="hidden" {...register("investor_address")} />
              </StyledInvestorAdressWrapper>
            </StyledInvestorDetailsWrapper>
            {!showCompanyInfo && (
              <StyledInfoButtonWrapper>
                <StyledInfoButton onClick={handleOpenCompanyInfo}>
                  Edytuj
                </StyledInfoButton>
              </StyledInfoButtonWrapper>
            )}
          </StyledInvestorInfoContentWrapper>
          <input type="hidden" {...register("opportunity_id")} />
        </StyledInvestorInfoWrapper>

        <StyledInstalationInfoWrapper>
          <StyledInvestorInfoTitleWrapper>
            <StyledInvestorInfoTitle>Dane Instalacji:</StyledInvestorInfoTitle>
          </StyledInvestorInfoTitleWrapper>
          {instalationType && (
            <StyledInstalationWrapper>
              <StyledInstalation>
                <StyledInput>
                  <div className="input-group">
                    <label>Instalacja fotowoltaiczna:</label>
                    <select onChange={handleOnChooseInstalation}>
                      <option value="">Wybierz...</option>
                      {instalationType.map((instalation) => {
                        return (
                          <option key={instalation.id} value={instalation.id}>
                            {instalation.solar_panel?.name} +{" "}
                            {instalation.inverter?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </StyledInput>
                <input type="hidden" {...register("instalation_id")} />
                <input type="hidden" {...register("construction_id")} />
                <input type="hidden" {...register("client_id")} />
                <input
                  type="hidden"
                  {...register("price_include_optimizers")}
                />
                {errors?.instalation_id && (
                  <span className="error">{errors.instalation_id.message}</span>
                )}{" "}
              </StyledInstalation>
              <StyledInstalationCountWrapper>
                <StyledInput>
                  <div className="input-group">
                    <label>Ilość paneli</label>
                    <input
                      type="number"
                      value={panelCount}
                      onInput={handleOnPanelCountChange}
                      onBlur={handleOnPanelChangeBlur}
                    />
                  </div>{" "}
                  <input type="hidden" {...register("panel_count")} />
                  {errors?.panel_count && (
                    <span className="error">{errors.panel_count.message}</span>
                  )}
                </StyledInput>

                <StyledInput>
                  <div className="input-group">
                    <label>Ilość falowników</label>
                    <input
                      type="number"
                      value={inverterCount}
                      onChange={handleOnChangeIverterCount}
                      onBlur={handleOnBLurInverter}
                    />
                  </div>
                  <input type="hidden" {...register("inverter_count")} />
                  {errors?.inverter_count && (
                    <span className="error">
                      {errors.inverter_count.message}
                    </span>
                  )}
                </StyledInput>
              </StyledInstalationCountWrapper>
            </StyledInstalationWrapper>
          )}
          <StyledConstructionWrapper>
            <StyledConstruction>
              <StyledInput>
                <div className="input-group" style={{ flex: "1" }}>
                  <label>Konstrukcja:</label>
                  <select onChange={(e) => setConstruction(e.target.value)}>
                    <option value={{}}>Wybierz...</option>
                    {constructions.map((construction) => {
                      return (
                        <option
                          key={construction.id}
                          value={JSON.stringify(construction)}
                        >
                          {construction.name}
                        </option>
                      );
                    })}
                  </select>
                </div>{" "}
                {errors?.construction_id && (
                  <span className="error">
                    {errors.construction_id.message}
                  </span>
                )}
              </StyledInput>
            </StyledConstruction>
            <StyledConstructionCount>
              <StyledInput>
                <div className="input-group">
                  <label>Ilość konstrukcji</label>
                  <input type="number" {...register("construction_count")} />
                </div>
                {errors?.construction_count && (
                  <span className="error">
                    {errors.construction_count.message}
                  </span>
                )}
              </StyledInput>
            </StyledConstructionCount>
          </StyledConstructionWrapper>

          <StyledConstructionWrapper>
            <StyledConstruction>
              <StyledOtherInput>
                <StyledOtherInputWrapper style={{ flex: "1" }}>
                  <StyledOtherInputLabel>Optymalizatory:</StyledOtherInputLabel>
                  <select
                    onChange={handleOnChooseOptimizer}
                    style={{ width: "100%" }}
                  >
                    <option value="">Wybierz...</option>
                    {optimizers.map((optimizer) => {
                      return (
                        <option key={optimizer.id} value={optimizer.id}>
                          {optimizer.name}
                        </option>
                      );
                    })}
                  </select>
                </StyledOtherInputWrapper>

                <StyledOtherInputWrapper>
                  <StyledOtherInputLabel>Ilość</StyledOtherInputLabel>
                  <input
                    style={{ width: "40px" }}
                    type="number"
                    value={optimizerValue}
                    onInput={(e) => {
                      if (e.target.value < 0) {
                        setOptimizerValue(0);
                        setValue("optimizer_count", 0);
                      } else {
                        setOptimizerValue(e.target.value);
                        setValue("optimizer_count", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      if (Number(e.target.value) === 0) {
                        setOptimizerValue("");
                        setValue("optimizer_count", "");
                      } else if (e.target.value < minOptimizers) {
                        setOptimizerValue("");
                        setValue("optimizer_count", "");
                      } else {
                        setOptimizerValue(e.target.value);
                        setValue("optimizer_count", e.target.value);
                      }
                    }}
                  />
                </StyledOtherInputWrapper>
                <input type="hidden" {...register("optimizer_count")} />
                <input type="hidden" {...register("optimizer_id")} />
                <input type="hidden" {...register("optimizer_price")} />
                {errors?.optimizer_count && (
                  <span className="error">
                    {errors.optimizer_count.message}
                  </span>
                )}
                {errors?.optimizer_id && (
                  <span className="error">{errors.optimizer_id.message}</span>
                )}
              </StyledOtherInput>
            </StyledConstruction>
          </StyledConstructionWrapper>

          <StyledDetailsWrapper>
            <StyledDetailsLeftSide>
              <StyledInput>
                <div className="input-group">
                  <label>Koszt ppoż</label>
                  <input
                    type="number"
                    onChange={(e) => setAntiFireCost(e.target.value)}
                    value={antiFireCost}
                  />
                </div>
              </StyledInput>
              <input
                type="hidden"
                {...register("anti_fire_cost")}
                value={antiFireCost}
              />{" "}
              <StyledInput>
                <div className="input-group">
                  <label>Zabezpieczenie</label>
                  <input type="text" {...register("secure")} />
                </div>{" "}
                {errors?.secure && (
                  <span className="error">{errors.secure.message}</span>
                )}
              </StyledInput>
            </StyledDetailsLeftSide>
            <StyledDetailsRightSide>
              <StyledInput>
                <div className="input-group">
                  <label>Moc (kWp)</label>
                  <input type="number" {...register("power")} readOnly />
                </div>{" "}
                {errors?.power && (
                  <span className="error">{errors.power.message}</span>
                )}
              </StyledInput>
              <StyledSectionTitleWrapper>
                Usługi dodatkowe:
              </StyledSectionTitleWrapper>
              {cutIndividualPricing && (
                <StyledAlertInfoWrapper>
                  <StyledAlert>
                    W przypadku kompleksowego wykonania przekopu wraz z
                    przewodami AC/DC przekraczającego 90m - wycena indywidualna{" "}
                  </StyledAlert>
                </StyledAlertInfoWrapper>
              )}
              {wireIndividualPricing && (
                <StyledAlertInfoWrapper>
                  <StyledAlert>
                    W przypadku połozenia przewody elektryczne AC/DC z przekopem
                    po stronie inwestora przekraczającego 90m - wycena
                    indywidualna{" "}
                  </StyledAlert>
                </StyledAlertInfoWrapper>
              )}

              <StyledServicesInput>
                <StyledServicesTitle>
                  Kompleksowe wykonanie przekopu wraz z przewodami AC/DC
                </StyledServicesTitle>
                <StyledServicesInputWrapper>
                  <StyledServicesInputLabel>m</StyledServicesInputLabel>
                  <input
                    type="number"
                    value={cutLength}
                    onChange={handleOnChangeCutLength}
                  />
                  <input type="hidden" {...register("cut_length")} />
                </StyledServicesInputWrapper>
                <StyledServicesInputWrapper>
                  <StyledServicesInputLabel>Cena</StyledServicesInputLabel>
                  <input
                    type="text"
                    {...register("cut_length_price")}
                    readOnly
                  />
                </StyledServicesInputWrapper>
                {(errors?.cut_length_price || errors?.cut_length) && (
                  <span className="error">
                    {errors?.cut_length_price?.message}
                    {errors?.cut_length?.message}
                  </span>
                )}
              </StyledServicesInput>
              <StyledServicesInput>
                <StyledServicesTitle>
                  Przewody elektryczne AC/DC - przekop po stronie inwestora
                </StyledServicesTitle>
                <StyledServicesInputWrapper>
                  <StyledServicesInputLabel>m</StyledServicesInputLabel>
                  <input
                    type="number"
                    value={wireLength}
                    onChange={handleOnChangeWireLength}
                  />
                  <input type="hidden" {...register("wire_length")} />
                </StyledServicesInputWrapper>{" "}
                <StyledServicesInputWrapper>
                  <StyledServicesInputLabel>Cena</StyledServicesInputLabel>
                  <input
                    type="text"
                    {...register("wire_length_price")}
                    readOnly
                  />
                </StyledServicesInputWrapper>
                {errors?.wire_length && (
                  <span className="error">{errors.wire_length.message}</span>
                )}
                {errors?.wire_length_price && (
                  <span className="error">
                    {errors.wire_length_price.message}
                  </span>
                )}
              </StyledServicesInput>
              {controlledFields.map((field, index) => {
                return (
                  <StyledServicesOtherInput key={field.id}>
                    <StyledInputWrapper>
                      <StyledOtherServicesInputWrapper>
                        <input
                          type="text"
                          {...register(`services.${index}.name`)}
                          value={field.name}
                        />
                      </StyledOtherServicesInputWrapper>{" "}
                      <StyledServicesInputWrapper>
                        <StyledServicesInputLabel>
                          Cena
                        </StyledServicesInputLabel>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          {...register(`services.${index}.price`)}
                          value={field.price}
                        />
                      </StyledServicesInputWrapper>
                      <StyledRemoveButtonWrapper>
                        <StyledRemoveButton onClick={() => remove(index)}>
                          <FontAwesomeIcon icon={faMinus} />
                        </StyledRemoveButton>
                      </StyledRemoveButtonWrapper>
                    </StyledInputWrapper>
                    <StyledErrorWrapper>
                      {errors?.services &&
                        errors?.services[index] &&
                        errors?.services[index].name && (
                          <span className="error">
                            {errors?.services[index].name.message}{" "}
                          </span>
                        )}
                      {errors?.services &&
                        errors?.services[index] &&
                        errors?.services[index].price && (
                          <span className="error">
                            {errors?.services[index].price.message}
                          </span>
                        )}
                    </StyledErrorWrapper>
                  </StyledServicesOtherInput>
                );
              })}
              <StyledAddOtherServiceButton
                type="button"
                onClick={() => append({ name: "", price: "" })}
              >
                <FontAwesomeIcon icon={faPlus} />
              </StyledAddOtherServiceButton>
            </StyledDetailsRightSide>
          </StyledDetailsWrapper>

          <StyledPriceWrapper>
            <StyledPriceLeftSide>
              <StyledInput>
                <div className="input-group">
                  <label>Cena instalacji (netto)</label>
                  <input type="text" {...register("price_netto")} readOnly />
                </div>
              </StyledInput>
              {errors?.price_netto && (
                <span className="error">{errors.price_netto.message}</span>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Stawka Vat</label>
                  <select
                    onChange={(e) => setVatRate(e.target.value)}
                    defaultValue={"23"}
                  >
                    <option value="8">8%</option>
                    <option value="23">23%</option>
                  </select>
                </div>
              </StyledInput>

              <input type="hidden" {...register("vat")} />
              <input type="hidden" {...register("price_vat")} />
              {errors?.price_vat && (
                <span className="error">{errors.price_vat.message}</span>
              )}
            </StyledPriceLeftSide>
            <StyledPriceRightSide>
              <StyledInput>
                <div className="input-group">
                  <label>Marża</label>
                  <input
                    type="number"
                    min={0}
                    value={margin}
                    onChange={handleOnChangeMargin}
                  />
                  <input type="hidden" {...register("margin")} />
                </div>
              </StyledInput>
              {user?.role?.id !== 2 && (
                <StyledInput>
                  <div className="input-group">
                    <label>Rabat specjalny Dyrektor(netto)</label>
                    <input
                      min={0}
                      type="number"
                      value={specialDiscountManager}
                      onChange={(e) =>
                        setSpecialDiscountManager(e.target.value)
                      }
                    />
                  </div>
                  <input
                    type="hidden"
                    {...register("special_discount_manager")}
                  />
                </StyledInput>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Rabat specjalny Zarząd (netto)</label>
                  <input
                    type="number"
                    min={0}
                    value={specialDiscountManagement}
                    onChange={(e) =>
                      setSpecialDiscountManagement(e.target.value)
                    }
                  />
                </div>
                <input
                  type="hidden"
                  {...register("special_discount_management")}
                />
              </StyledInput>

              <StyledBruttoPriceInput>
                <div className="input-group">
                  <StyledBruttoPriceLabel>
                    Cena końcowa instalacji (brutto)
                  </StyledBruttoPriceLabel>
                  <input type="text" {...register("price_sum")} readOnly />
                </div>
              </StyledBruttoPriceInput>
              {errors?.price_sum && (
                <span className="error">{errors.price_sum.message}</span>
              )}
            </StyledPriceRightSide>
          </StyledPriceWrapper>

          <StyledValidityWrapper>
            <StyledValidityLeftSide>
              {" "}
              <StyledInput>
                <div className="input-group">
                  <label>Ważność oferty (dni)</label>
                  <input
                    type="number"
                    onChange={handleOnChangeEndDate}
                    value={endDate}
                  />
                </div>
                <input type="hidden" {...register("offer_date")} />
                {errors?.offer_date && (
                  <span className="error">{errors.offer_date.message}</span>
                )}
              </StyledInput>
            </StyledValidityLeftSide>
            <StyledValidityRightSide>
              <StyledInput>
                <div className="input-group">
                  <label>Oferta dla:</label>
                  <select {...register("offer_type")}>
                    <option value="individual">Klienta indywidualnego</option>
                    <option value="buisness">Biznesu</option>
                    <option value="farmer">Rolnika</option>
                  </select>
                </div>
              </StyledInput>

              <input type="hidden" {...register("price_vat")} />
              {errors?.price_vat && (
                <span className="error">{errors.price_vat.message}</span>
              )}
            </StyledValidityRightSide>
          </StyledValidityWrapper>
          <StyledInput>
            <div className="input-group">
              <label>Opis:</label>
              <textarea
                {...register("description")}
                onKeyUp={handleSetHeight}
              ></textarea>
            </div>
          </StyledInput>
        </StyledInstalationInfoWrapper>

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          {(cutLength > 90 || wireLength > 90) && (
            <button
              disabled={isSended}
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSendToPricing)}
            >
              Wyślij do wyceny
            </button>
          )}
          <button
            disabled={isSended}
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Generuj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledAlertInfoWrapper = styled.div`
  padding: 10px;
`;

const StyledAlert = styled.p`
  color: red;
  font-size: 14px;
`;

const StyledBruttoPriceInput = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    input {
      border: none;
      border-bottom: 2px solid black;
      width: 100%;
      outline: none;
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const StyledBruttoPriceLabel = styled.label`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 16px;
  font-weight: bold;
  color: rgb(244, 118, 49);
`;

const StyledErrorWrapper = styled.div``;

const StyledInputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const StyledRemoveButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  height: 19px;
  border-radius: 5px;
  width: 100%;
`;
const StyledRemoveButtonWrapper = styled.div`
  margin-top: 20px;
  width: 60px;
`;
const StyledSectionTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #134771;
`;

const StyledServicesOtherInput = styled.div``;

const StyledOtherServicesInputWrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  position: relative;
  & input {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
`;
const StyledAddOtherServiceButton = styled.button`
  margin-top: 10px;
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
`;

const StyledServicesInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;

const StyledServicesInputWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  & input {
    width: 60px;
  }
`;

const StyledServicesTitle = styled.div`
  flex: 1;
  font-size: 14px;
  margin-top: 20px;
`;

const StyledServicesInput = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledOtherInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;
const StyledOtherInputWrapper = styled.div`
  margin-top: 20px;
  position: relative;
`;

const StyledOtherInput = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledInfoButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
  &:hover {
    background: #053257;
  }
`;

const StyledInfoButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledInvestorInfoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
`;
const StyledInvestorDetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const StyledValidityRightSide = styled.div`
  flex: 1;
  min-width: 0;
`;

const StyledValidityLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledValidityWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
`;
const StyledPriceRightSide = styled.div`
  flex: 1;
`;

const StyledPriceLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledDetailsRightSide = styled.div`
  flex: 1;
`;

const StyledDetailsLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledDetailsWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledConstructionCount = styled.div`
  flex: 1;
`;

const StyledConstruction = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledConstructionWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;
const StyledInstalation = styled.div`
  flex: 1;
  margin-right: 10px;
`;
const StyledInstalationCountWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const StyledInstalationWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledInstalationInfoWrapper = styled.div``;

const StyledInvestorAdress = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

const StyledInvestorName = styled.p`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledInvestorAdressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-top: 10px;
`;
const StyledInvestorNameWrapper = styled.div`
  display: flex;
  min-width: 0;
  margin-top: 10px;
`;

const StyledInvestorInfoTitle = styled.p`
  color: #134771;
`;
const StyledInvestorInfoTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledInvestorInfoWrapper = styled.div`
  min-width: 0;
`;

const StyledCopmanyInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledInvestorInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledFormHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledCloseButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 1rem;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 360px;
  min-width: 0;
  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .todo-input-group {
      width: 49%;

      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;

  margin-top: 25px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      padding: 10px;
      overflow-y: hidden;
      border-radius: 10px;
      outline: none;
    }
  }
`;

export default MakeCompanyFarmerOffer;
