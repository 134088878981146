import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const TaskComponent = ({ task }) => {
  const [top, setTop] = useState(0);
  const [height, setHeight] = useState(40);
  const [startHoureText, setStartHoureText] = useState("00:00");
  const [endHoureText, setEndHoureText] = useState("00:00");
  useEffect(() => {
    const startHoure = moment(task.start_date).hour();
    const startMinute = moment(task.start_date).minute();

    var hours = moment
      .duration(
        moment(task.end_date, "YYYY/MM/DD HH:mm").diff(
          moment(task.start_date, "YYYY/MM/DD HH:mm")
        )
      )
      .asHours();

    if (hours) {
      setHeight(hours * 40 - 1);
    }
    setTop(startHoure * 40 + (startMinute * 40) / 60 + 1);
    setStartHoureText(moment(task.start_date).format("HH:mm"));
    setEndHoureText(moment(task.end_date).format("HH:mm"));
  }, [task, task?.start_date, task.end_date]);

  return (
    <StyledWrapper style={{ top: `${top}px`, height: `${height}px` }}>
      <StyledNameWrapper>{task.name}</StyledNameWrapper>

      <StyledTimeWrapper>
        {startHoureText} - {endHoureText}
      </StyledTimeWrapper>
    </StyledWrapper>
  );
};

const StyledNameWrapper = styled.div``;

const StyledTimeWrapper = styled.div``;

const StyledWrapper = styled.div`
  position: absolute;
  left: 5px;
  right: 5px;
  top: 0px;
  z-index: 10;
  background: #134771;
  color: #cadeee;

  min-height: 38px;
  font-size: 14px;
  padding: 2px;
  border-radius: 5px;
`;

export default TaskComponent;
