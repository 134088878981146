import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

const DateRangeFilter = ({
  searchStartDate,
  setSearchStartDate,
  searchEndDate,
  setSearchEndDate,
  setDateFilter,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const CustomStartInput = forwardRef(
    ({ value, onClick, placeholder }, ref) => (
      <StyledDatePickerButton onClick={onClick} ref={ref}>
        {value ? value : placeholder}
      </StyledDatePickerButton>
    )
  );
  const CustomEndInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <StyledDatePickerButton onClick={onClick} ref={ref}>
      {value ? value : placeholder}
    </StyledDatePickerButton>
  ));

  const handleSetDate = (e) => {
    e.preventDefault();
    setSearchStartDate(startDate);
    setSearchEndDate(endDate);
    setDateFilter(null);
  };

  useEffect(() => {
    if (searchStartDate && searchEndDate) {
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [searchStartDate, searchEndDate]);

  const handleSetStartDate = (date, endDate) => {
    setStartDate(date);
    if (moment(date).isAfter(endDate, "day")) {
      setEndDate(date);
    } else if (endDate === null) {
      setEndDate(date);
    }
  };

  const handleSetEndDate = (date, startDate) => {
    setEndDate(date);
    if (startDate === null) {
      setStartDate(date);
    }
  };

  const handleClearDate = () => {
    setSearchStartDate(null);
    setSearchEndDate(null);
    setStartDate(null);
    setEndDate(null);
    setDateFilter(null);
  };

  return (
    <StyledWrapper>
      <StyledDateInputWrapper>
        <StyledInputLabel>Od:</StyledInputLabel>
        <ReactDatePicker
          customInput={<CustomStartInput />}
          selected={startDate}
          selectsStart
          closeOnScroll={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => handleSetStartDate(date, endDate)}
          placeholderText="Wybierz datę"
        />
      </StyledDateInputWrapper>
      <StyledDateInputWrapper>
        <StyledInputLabel>Do:</StyledInputLabel>
        <ReactDatePicker
          customInput={<CustomEndInput />}
          selected={endDate}
          selectsEnd
          closeOnScroll={true}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          onChange={(date) => handleSetEndDate(date, startDate)}
          placeholderText="Wybierz datę"
          popperPlacement="bottom-end"
        />
      </StyledDateInputWrapper>
      <StyledButtonWrapper>
        <StyledConfirmButton onClick={handleSetDate}>
          Zatwierdź
        </StyledConfirmButton>
        <StyledClearButton onClick={handleClearDate}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledClearButton>
      </StyledButtonWrapper>
    </StyledWrapper>
  );
};

const StyledDatePickerButton = styled.button`
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  background: #134771;
  color: rgb(202, 222, 238);

  @media screen and (max-width: 425px) {
    font-size: 12px;
    padding: 3px 6px;
  }
`;

const StyledClearButton = styled.button`
  border: none;
  width: 25px;
  height: 25px;
  margin: 5px;
  transition: all 0.3s ease;
  border-radius: 50%;
  background: #134771;
  color: rgb(202, 222, 238);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 425px) {
    font-size: 12px;
    padding: 3px 6px;
  }
`;

const StyledConfirmButton = styled.button`
  border: none;
  padding: 5px 10px;
  margin: 5px;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: #134771;
  color: rgb(202, 222, 238);
  cursor: pointer;

  @media screen and (max-width: 425px) {
    font-size: 12px;
    padding: 3px 6px;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
`;

const StyledInputLabel = styled.label`
  margin-right: 5px;
  font-size: 14px;

  @media screen and (max-width: 425px) {
    font-size: 12px;
    margin-right: 3px;
  }
  @media screen and (max-width: 334px) {
    margin-right: 0px;
  }
`;
const StyledDateInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 425px) {
    gap: 5px;
  }

  @media screen and (max-width: 334px) {
    gap: 0px;
  }
`;

export default DateRangeFilter;
