import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import RoleSettingsItem from "./RoleSettingsItem";

const RoleSettings = () => {
  const { roles, setRoles } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();
  const fetchDataRole = async () => {
    try {
      const { status, data } = await request.get("/api/role");
      if (status === 200) {
        await setRoles(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (roles.length === 0) {
      fetchDataRole();
    }
  }, []);

  return (
    <StyledRoleWrapper>
      <StyledButtonWrapper></StyledButtonWrapper>
      <StyledRoleTable>
        <thead>
          <tr>
            <th>Nazwa</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) => {
            return <RoleSettingsItem role={role} key={role.id} />;
          })}
        </tbody>
      </StyledRoleTable>
    </StyledRoleWrapper>
  );
};

const StyledRoleWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledRoleTable = styled.table`
  width: 100%;
  padding: 40px 0;
  th {
    text-align: start;
    padding: 5px;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default RoleSettings;
