import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Modal from "../../../../../components/Modal";

const OpportunityAlert = ({
  isAlertOpen,
  handleOnClose,
  handleClickNext,
  handleOnRedirectClient,
  handleOnRedirectCompany,
  isClientHasOpportunities,
  isCompanyHasOpportunities,
}) => {
  return (
    <Modal
      isModalOpen={isAlertOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledContentWrapper>
        Do klienta / firmy przypisana jest już szansa sprzedaży, czy chcesz
        stworzyć kolejną ?
      </StyledContentWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleClickNext}>Tak</StyledButton>
        {isClientHasOpportunities > 0 && (
          <StyledButton onClick={handleOnRedirectClient}>
            Przenieś do klienta
          </StyledButton>
        )}
        {isCompanyHasOpportunities > 0 && (
          <StyledButton onClick={handleOnRedirectCompany}>
            Przenieś do firmy
          </StyledButton>
        )}
      </StyledButtonWrapper>
    </Modal>
  );
};

const StyledButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px;
  max-width: 360px;
  display: flex;
  justify-content: space-evenly;
`;

const StyledContentWrapper = styled.div`
  padding: 10px;
  max-width: 360px;
  text-align: center;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

export default OpportunityAlert;
