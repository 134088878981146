import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import request from "../../../../helpers/request";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../components/Modal";

const validationSchema = yup.object({
  note: yup
    .string()
    .required("Notatka jest wymagana")
    .min(3, "Notatka musi posiadać co najmniej 3 znaków")
    .max(255, "Notatka nie może być dłuższa niż 255 znaków"),
});
const AddContactNote = ({ todo, isModalOpen, handleOnClose, fetchData }) => {
  const location = useLocation();
  const history = useHistory();
  const [isSended, setIsSended] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      note: "",
    });
  };

  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/opportunities/contact-note/${todo.opportunity.id}`,
        {
          todo_id: todo.id,
          note: `${todo.name}: ${data.note}`,
          done: true,
          contact_make: data.contact_make,
        }
      );
      if (status === 200) {
        fetchData();

        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data, e) => {
    e.preventDefault();
    if (todo?.opportunity?.id) {
      sendData(data);
    }
  };

  useEffect(() => {
    setValue("note", "");
  }, []);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm onKeyDown={(e) => checkKeyDown(e)}>
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledInput>
          <div className="input-group">
            <label>Notatka</label>
            <input type="text" {...register("note")} />
          </div>
        </StyledInput>
        {errors?.note && <span className="error">{errors.note.message}</span>}

        <StyledCheckBox>
          <StyledInputGroup>
            <StyledCheckBoxLabel>
              <Controller
                name="contact_make"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <StyledCheckBoxInput
                    type="checkbox"
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
              Kontakt nawiązany
            </StyledCheckBoxLabel>
          </StyledInputGroup>
        </StyledCheckBox>

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Wykonaj zadanie i dodaj notatke
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .todo-input-group {
      width: 49%;

      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddContactNote;
