import React, { useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";

import styled from "styled-components";

import StatisticsOpportunities from "./StatisticsOpportunities/StatisticsOpportunities";
import StatisticsLeads from "./StatisticsLeads/StatisticsLeads";

const Statistics = () => {
  let { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/dashboard/statistics") {
      history.push("/dashboard/statistics/opportunities");
    }
  }, []);

  return (
    <StyledContactsNav>
      <ContentContainer>
        <Switch>
          <Route
            exact
            path={`${path}/opportunities`}
            component={StatisticsOpportunities}
          />

          <Route exact path={`${path}/leads`} component={StatisticsLeads} />
        </Switch>
      </ContentContainer>
    </StyledContactsNav>
  );
};

const StyledContactsNav = styled.div`
  flex: 1;
  min-width: 0;
`;

const ContentContainer = styled.div`
  width: 100%;
  min-width: 0;
`;

export default Statistics;
