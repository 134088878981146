import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../store/StoreProvider";
import AssemblyExecution from "./Components/AssemblyExecution/AssemblyExecution";
import AssemblyFault from "./Components/AssemblyFault/AssemblyFault";
import AssemblyPlanning from "./Components/AssemblyPlanning/AssemblyPlanning";
import InstallationDesign from "./Components/InstallationDesign/InstallationDesign";
import PaymentMonitoring from "./Components/PaymentMonitoring/PaymentMonitoring";
import ProductOrder from "./Components/ProductOrder/ProductOrder";
import Registration from "./Components/Registration/Registration";

const OpportunityContractRealization = ({ opportunities, fetchData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const { user } = useContext(StoreContext);
  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };
  useEffect(() => {
    if (content && isOpen) {
      setHeight(`${content.current.scrollHeight}px`);
    }
  }, [opportunities]);

  return (
    <StyledStatusWrapper>
      <StyledTitleWrapper onClick={handleOnClick}>
        <StyledTitle>
          Proces realizacji
          {isOpen ? (
            <FontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faChevronDown} />
            </FontAwesomeWrapper>
          ) : (
            <FontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faChevronUp} />
            </FontAwesomeWrapper>
          )}
        </StyledTitle>
      </StyledTitleWrapper>
      <StyledItemWrapper>
        <StyledContentWrapper ref={content} style={{ maxHeight: `${height}` }}>
          <StyledRealizationWrapper>
            <PaymentMonitoring
              opportunities={opportunities}
              fetchData={fetchData}
            />

            <InstallationDesign
              opportunities={opportunities}
              fetchData={fetchData}
            />
            <ProductOrder opportunities={opportunities} fetchData={fetchData} />
            <AssemblyPlanning
              opportunities={opportunities}
              fetchData={fetchData}
            />
            <AssemblyExecution
              opportunities={opportunities}
              fetchData={fetchData}
            />
            <Registration opportunities={opportunities} fetchData={fetchData} />

            {(user.role?.id === 3 || user.role?.id === 1) && (
              <AssemblyFault
                opportunities={opportunities}
                fetchData={fetchData}
              />
            )}
          </StyledRealizationWrapper>
        </StyledContentWrapper>
      </StyledItemWrapper>
    </StyledStatusWrapper>
  );
};

const StyledRealizationWrapper = styled.div`
  padding: 10px;
`;

const StyledContentWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const FontAwesomeWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #134771;
`;

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
`;

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledStatusWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
`;

export default OpportunityContractRealization;
