import React from "react";

const Main = () => {
  return (
    <div>
      <p>
        Znajdować się tu będzie wszystkie ustawienia związane z Szansami
        sprzedarzy
      </p>
      <p>Ogólne:</p>
      <ul>
        <li>
          Pokazuj prawdopodobieństwo wygrania szansy sprzedaży wynikające z
          postępu w procesie na listach i profilach
        </li>
        <li>Włącz możliwość dodawania kosztów w szansach sprzedaży</li>
        <li>
          Włącz możliwość dodawania szczegółów Firmy i Osoby w formularzu
          sprzedaży{" "}
        </li>
        <li>Włącz możliwość dodawania szczegółów Firmy w formularzu Osoby </li>
        <li>
          Aktywność z kontaktami powoduje aktualizację daty ostatniego kontaktu
          z otwartymi szansami sprzedaży
        </li>
        <li>Włącz obsługę produktów </li>
      </ul>

      <p>Procesy:</p>
      <ul>
        <li>
          <p>
            Tutaj znajdowac się będzie możliwość dodania zmiany i usunięcie
            procesu sprzedażowego
          </p>
        </li>
      </ul>

      <p>Produkty:</p>
      <ul>
        <li>
          <p>
            Tutaj znajdować sie będize możliwość dodanie edycji usunięcia
            Produktu pozwalające precyzyjnie określić co sprzedajemy
          </p>
        </li>
      </ul>

      <p>Koszty:</p>
      <ul>
        <li>
          <p>
            Tutaj znajdować się będą kategorie kosztów z możliwością dadanie
            edycji oraz usunięcia
          </p>
        </li>
        <li>
          <p>Możliwośc dodania grup kategori kosztów </p>
        </li>
      </ul>
      <p>Powody zmiany statusów:</p>
      <ul>
        <li>
          <p>
            Tu będą znajdować się powody zmiany statusów szans sprzedaży w
            trzech osobnych tabelach podzielonycha [Wygrane szanse sprzedaży,
            Przegrane szanse sprzedaży, nieaktualne szanse sprzedaży ]
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Main;
