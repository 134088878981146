import {
  faBan,
  faBullseye,
  faCheck,
  faSolarPanel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as HeatPump } from "../../../img/heat_pump.svg";

const SearchOpportunityItem = ({ opportunity }) => {
  const [spanObject, setSpanObject] = useState(null);

  useEffect(() => {
    if (opportunity.status === 2) {
      setSpanObject(
        <StyledSpan style={{ background: "rgb(178, 233, 176)" }}>
          <FontAwesomeIcon style={{ color: "white" }} icon={faCheck} />
        </StyledSpan>
      );
    } else if (opportunity.status === 1) {
      setSpanObject(
        <StyledSpan style={{ background: "rgb(255, 238, 127)" }}>
          <FontAwesomeIcon style={{ color: "white" }} icon={faBullseye} />
        </StyledSpan>
      );
    } else if (opportunity.status === 3) {
      setSpanObject(
        <StyledSpan style={{ background: "white" }}>
          <FontAwesomeIcon style={{ color: "#ff7199" }} icon={faBan} />
        </StyledSpan>
      );
    }
  }, [opportunity]);

  return (
    <StyledOpportunityWrapper
      title={opportunity.name}
      to={`/dashboard/opportunities/details/${opportunity.id}`}
    >
      {spanObject}
      {opportunity.is_photovoltaic === 1 && (
        <StyledPanelIcon icon={faSolarPanel} />
      )}
      {opportunity.is_heat_pump === 1 && <StyledHeatPump />}
      <StyledOpportunityName>{opportunity.name}</StyledOpportunityName>
    </StyledOpportunityWrapper>
  );
};

const StyledPanelIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledHeatPump = styled(HeatPump)`
  font-size: 14px;
  height: 16px;
  margin-right: 5px;
`;

const StyledSpan = styled.span`
  display: block;

  height: 16px;
  flex-basis: 16px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 5px;
`;

const StyledOpportunityName = styled.p`
  color: #134771;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledOpportunityWrapper = styled(Link)`
  background: white;
  padding: 10px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

export default SearchOpportunityItem;
