import React, { useState } from "react";
import styled from "styled-components";
import ContractItem from "./ContractItem";
import ShowContractDetails from "./ShowContractDetails";

const ShowContract = ({ opportunities, fetchData }) => {
  const [isShowContract, setIsShowContract] = useState(false);
  const [contractObject, setContractObject] = useState(null);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowContract(false);
    setContractObject(null);
  };

  return (
    <StyledWrapper>
      <StyledHeaderText>Umowy</StyledHeaderText>
      <StyledContractListWrapper>
        {opportunities.heat_pump_contracts?.length > 0 ? (
          <>
            {opportunities.heat_pump_contracts.map((contract) => (
              <ContractItem
                key={contract.id}
                contract={contract}
                setIsShowContract={setIsShowContract}
                setContractObject={setContractObject}
              />
            ))}
          </>
        ) : (
          <StyledInfo>Brak wygenerowanych umów</StyledInfo>
        )}
      </StyledContractListWrapper>

      <ShowContractDetails
        fetchData={fetchData}
        contractObject={contractObject}
        isShowModalOpen={isShowContract}
        handleOnClose={handleOnClose}
        opportunities={opportunities}
      />
    </StyledWrapper>
  );
};

const StyledInfo = styled.div`
  padding: 10px;
  font-size: 14px;
`;

const StyledContractListWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;
const StyledWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
`;
const StyledHeaderText = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgb(244, 118, 49);
  font-weight: bold;
`;
export default ShowContract;
