import React, { useEffect, useState } from "react";
import styled from "styled-components";
import buildCalendarMonth from "./buildCalendarMonth";
import DayItem from "./DayItem";

const YearMonthComponent = ({ month, todoList, handleOnDayOpen, setValue }) => {
  const [monthItem, setMonthItem] = useState([]);

  useEffect(() => {
    setMonthItem(buildCalendarMonth(month));
  }, [month]);

  const monthName = () => {
    return month.format("MMMM");
  };

  return (
    <StyledMonthWrapper>
      <StyledMonthNameWrapper>{monthName()}</StyledMonthNameWrapper>
      <StyledDaysWrapper>
        {monthItem.length > 0 &&
          monthItem.map((day) => (
            <DayItem
              key={day}
              day={day}
              month={month}
              todoList={todoList}
              handleOnDayOpen={handleOnDayOpen}
              setValue={setValue}
            />
          ))}
      </StyledDaysWrapper>
    </StyledMonthWrapper>
  );
};
const StyledMonthNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
const StyledMonthWrapper = styled.div`
  padding: 10px;
  width: calc(100% / 4);
`;

const StyledDaysWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default YearMonthComponent;
