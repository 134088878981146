import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import AddInformation from "./Components/AddInformation";
import DeleteInformationAlert from "./Components/DeleteInformationAlert";
import InformationItem from "./Components/InformationItem";

const ImportantInformation = ({ opportunities, fetchOpportunities }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const sendDelete = async (id) => {
    setIsSended(true);
    try {
      const { status } = await request.delete(
        `api/opportunities/important-information/${id}`
      );
      if (status === 200) {
        fetchOpportunities();
        setIsDeleteAlertOpen(false);
        setDeleteItem(null);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOnOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOnOpenAlert = (item) => {
    setDeleteItem(item);
    setIsDeleteAlertOpen(true);
  };

  const handleOnCloseAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsDeleteAlertOpen(false);
    setDeleteItem(null);
  };

  const handleOnDelete = () => {
    if (deleteItem) {
      sendDelete(deleteItem.id);
    }
  };
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Istotne informacje</StyledTitle>
      </StyledTitleWrapper>
      {opportunities.important_information?.length > 0 ? (
        <>
          {opportunities.important_information?.map((information) => (
            <InformationItem
              information={information}
              key={information.id}
              handleOnOpenAlert={handleOnOpenAlert}
              fetchOpportunities={fetchOpportunities}
            />
          ))}
        </>
      ) : (
        <StyledInformation>Brak informacji</StyledInformation>
      )}
      <StyledButtonWrapper>
        <StyledButton onClick={handleOnOpenModal}>
          Dodaj Informację
        </StyledButton>
      </StyledButtonWrapper>

      <AddInformation
        isModalOpen={isModalOpen}
        opportunity_id={opportunities?.id}
        handleOnClose={handleOnClose}
        fetchOpportunity={fetchOpportunities}
      />
      <DeleteInformationAlert
        isAlertOpen={isDeleteAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledWrapper>
  );
};

const StyledButton = styled.button`
  padding: 5px;
  background: #134771;
  color: white;
  border: none;
  width: 100%;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

const StyledButtonWrapper = styled.div`
  padding-top: 10px;
`;

const StyledInformation = styled.div`
  padding: 10px;
  color: rgb(244, 118, 49);
`;

const StyledWrapper = styled.div`
  background: white;
  padding: 10px;
  border-radius: 10px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export default ImportantInformation;
