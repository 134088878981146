import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { ReactComponent as PdfDownload } from "../../../../../img/pdf-download-2617.svg";
import Modal from "../../../../../components/Modal";
import loading from "../../../../../img/48x48.gif";

const ContractApproveItemDetails = ({
  isShowModalOpen,
  handleOnClose,
  contract,
  handleAccept,
  handleReject,
  isSended,
}) => {
  return (
    <Modal
      isModalOpen={isShowModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      {contract && (
        <StyledModal>
          <StyledDateWrapper>
            Data utworzenia:{" "}
            {moment(contract.created_at).format("DD.MM.YY HH.mm")}
          </StyledDateWrapper>

          <StyledPhotovoltaicInstalationDetails>
            {contract?.user && (
              <StyledUserWrapper>
                <StyledTitle>Umowa wygenerowana przez:</StyledTitle>
                <StyledUserLink>{contract.user.name}</StyledUserLink>
              </StyledUserWrapper>
            )}

            <StyledItemWrapper>
              <StyledItemTitle>Rodzaj płatności:</StyledItemTitle>
              {Number(contract.payment_type) === 1 && (
                <StyledItemValue>Płatność kredytem</StyledItemValue>
              )}
              {Number(contract.payment_type) === 2 && (
                <StyledItemValue>
                  Płatność kredytem z wkładem własnym
                </StyledItemValue>
              )}
              {Number(contract.payment_type) === 3 && (
                <StyledItemValue>Płatność leasing</StyledItemValue>
              )}
              {Number(contract.payment_type) === 4 && (
                <StyledItemValue>Płatność ze środków własnych</StyledItemValue>
              )}
            </StyledItemWrapper>

            {contract.payment_type === 1 && (
              <StyledItemWrapper>
                <StyledItemTitle>Wartość kredytu</StyledItemTitle>
                <StyledItemValue>
                  {contract?.loan_value ? `${contract?.loan_value} zł` : ""}
                </StyledItemValue>
              </StyledItemWrapper>
            )}

            {contract.payment_type === 2 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Wartość umowy</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.loan_brutto_price
                      ? `${contract?.loan_brutto_price} zł `
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>

                <StyledItemWrapper>
                  <StyledItemTitle>Wpłata własna</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.loan_self_deposit
                      ? `${contract?.loan_self_deposit} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>

                <StyledItemWrapper>
                  <StyledItemTitle>Wartość kredytu</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.loan_value_deposit
                      ? `${contract?.loan_value_deposit} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
              </>
            )}

            {contract.payment_type === 2 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Leasing cena netto</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.lease_netto_price
                      ? `${contract?.lease_netto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>

                <StyledItemWrapper>
                  <StyledItemTitle>Leasing cena brutto</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.lease_brutto_price
                      ? `${contract?.lease_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
              </>
            )}

            {contract.payment_type === 4 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Pierwsza transza</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.first_tranche_percentage
                      ? `${contract?.first_tranche_percentage}%`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Pierwsza transza cena</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.first_tranche_brutto_price
                      ? `${contract?.first_tranche_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Druga transza</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.second_tranche_percentage
                      ? `${contract?.second_tranche_percentage} %`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Druga transza cena</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.second_tranche_brutto_price
                      ? `${contract?.second_tranche_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Trzecia transza</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.third_tranche_percentage
                      ? `${contract?.third_tranche_percentage} %`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Trzecia transza cena</StyledItemTitle>
                  <StyledItemValue>
                    {contract?.third_tranche_brutto_price
                      ? `${contract?.third_tranche_brutto_price} zł`
                      : ""}
                  </StyledItemValue>
                </StyledItemWrapper>
              </>
            )}

            <StyledItemWrapper>
              <StyledItemTitle>Moc instalacji</StyledItemTitle>
              <StyledItemValue>
                {contract?.investor_instalation_power
                  ? `${contract?.investor_instalation_power} kWp`
                  : ""}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Termin realizacji</StyledItemTitle>
              <StyledItemValue>
                {contract?.realization_date
                  ? `${contract?.realization_date} tyg`
                  : ""}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemServicesWrapper>
              <StyledItemServicesTitle>
                Ustalenia dodatkowe
              </StyledItemServicesTitle>
              <StyledItemServicesValue>
                {contract?.services_5 ? `${contract?.services_5}` : ""}
              </StyledItemServicesValue>
            </StyledItemServicesWrapper>

            {contract?.file && (
              <StyledFileWrapper>
                <StyledTitle>Umowa:</StyledTitle>
                <StyledFileLink
                  href={`${contract.file.path_name}`}
                  download={contract.file.name}
                  target="_blank"
                >
                  <StyledFileContainer>
                    <PdfDownload />
                    <StyledFileNameWrapper>
                      <StyledPName title={contract.file.name}>
                        {contract.file.name}
                      </StyledPName>
                      <StyledFileDescriptionWrapper>
                        <StyledPDescription>PDF</StyledPDescription>
                        <StyledPDescription>
                          {contract.file.size
                            ? `${(Number(contract.file.size) / 1048576).toFixed(
                                2
                              )} MB`
                            : ""}
                        </StyledPDescription>
                      </StyledFileDescriptionWrapper>
                    </StyledFileNameWrapper>
                  </StyledFileContainer>
                </StyledFileLink>
              </StyledFileWrapper>
            )}
          </StyledPhotovoltaicInstalationDetails>

          {contract.is_accepted === 1 || contract.is_rejected === 1 ? (
            <StyledButtonWrapper>
              <StyledButtonDisable>Akceptuj</StyledButtonDisable>
              <StyledButtonDisable>Odrzuć</StyledButtonDisable>
            </StyledButtonWrapper>
          ) : (
            <StyledButtonWrapper>
              <StyledButton onClick={handleAccept} disabled={isSended}>
                Akceptuj
              </StyledButton>
              <StyledButton onClick={handleReject} disabled={isSended}>
                Odrzuć
              </StyledButton>
            </StyledButtonWrapper>
          )}

          {isSended && (
            <StyledSendNoteWrapper>
              <StyledSendNoteInnerWrapper>
                Przesyłanie...
                <StyledLoadingImage src={loading} alt="loading" />
              </StyledSendNoteInnerWrapper>
            </StyledSendNoteWrapper>
          )}
        </StyledModal>
      )}
    </Modal>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledItemServicesValue = styled.div`
  margin-left: 5px;
`;

const StyledItemServicesTitle = styled.div`
  font-weight: bold;
`;

const StyledItemServicesWrapper = styled.div`
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
`;

const StyledItemValue = styled.div`
  margin-left: 5px;
`;

const StyledItemTitle = styled.div`
  font-weight: bold;
`;

const StyledItemWrapper = styled.div`
  padding-bottom: 5px;
  display: flex;
`;

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 10px 15px;
  }
`;

const StyledButtonDisable = styled.button`
  padding: 0.8rem 1rem;

  border: none;
  background: #cadeee;
  color: #053257;
  cursor: none;
  pointer-events: none;
  border-radius: 5px;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const StyledPDescription = styled.p`
  margin-top: 5px;
  margin-right: 5px;
  font-size: 14px;
`;
const StyledPName = styled.p`
  margin-top: 5px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledFileDescriptionWrapper = styled.div`
  display: flex;
`;
const StyledFileNameWrapper = styled.div`
  width: calc(100% - 40px);
  p {
    color: #777;
  }
`;
const StyledFileLink = styled.a`
  text-decoration: none;
  display: block;
`;

const StyledFileContainer = styled.div`
  margin-top: 10px;
  display: flex;
  /* overflow: hidden; */

  svg {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 60px;
  }
`;

const StyledFileWrapper = styled.div`
  margin-top: 10px;
`;

const StyledUserLink = styled.p``;

const StyledUserWrapper = styled.div`
  margin: 10px 0;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledPhotovoltaicInstalationDetails = styled.div`
  margin-top: 20px;
`;

const StyledDateWrapper = styled.div``;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledModal = styled.div`
  background-color: white;
`;

export default ContractApproveItemDetails;
