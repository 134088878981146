import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../components/Modal";
import request from "../../../../../../helpers/request";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Hasło jest wymagane")
    .min(5, "Hasło musi zawierać co najmniej 5 znaków")
    .max(255, "Hasło nie nie może być duższe niż 255 znaków"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Podane hasła nie mogą się różnić"),
});
const EditUserPassword = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  editObject,
}) => {
  const location = useLocation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const sendData = async (data) => {
    try {
      const { status } = await request.patch(`/api/user/${editObject.id}`, {
        password: data.password,
      });
      if (status === 201) {
        fetchData();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="form-header">
          <button
            onClick={handleOnClose}
            className="btn-form-times"
            type="button"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <StyledInputWrapper>
          <div className="input-group">
            <label htmlFor="passwordInput">Hasło</label>

            <input type="password" {...register("password")} />
          </div>
        </StyledInputWrapper>
        {errors?.password && (
          <span className="error">{errors.password.message}</span>
        )}

        <StyledInputWrapper>
          <div className="input-group">
            <label htmlFor="password_confirmationInput">Potwierdź hasło</label>

            <input type="password" {...register("password_confirmation")} />
          </div>
        </StyledInputWrapper>
        {errors?.password_confirmation && (
          <span className="error">{errors.password_confirmation.message}</span>
        )}
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Zapisz
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  max-width: 360px;
  .form-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .btn-form-times {
    border: none;
    outline: none;
    background: transparent;
    padding: 1rem;
    cursor: pointer;
  }
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #2d96db;
        background: transparent;
      }
      &.close:hover {
        color: black;
      }
      &.add {
        background: #134771;
        color: white;
      }
      &.add:hover {
        background: #005e9c;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInputWrapper = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default EditUserPassword;
