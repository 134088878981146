import React from "react";
import styled from "styled-components";
import ImportantInformation from "./ImportantInformation/ImportantInformation";
import OpportunitiesClientInfo from "./OpportunitiesClientInfo/OpportunitiesClientInfo";
import OpportunitiesClientLead from "./OpportunitiesClientInfo/OpportunitiesClientLead";
import OpportunitiesCompanyInfo from "./OpportunitiesCompanyInfo/OpportunitiesCompanyInfo";
import OpportunitiesCompanyLead from "./OpportunitiesCompanyInfo/OpportunitiesCompanyLead";
import OpportunitiesInfo from "./OpportunitiesInfo/OpportunitiesInfo";

const OpportunitiesLeftAside = ({ opportunities, fetchOpportunities }) => {
  return (
    <StyledContentContainer>
      <OpportunitiesInfo
        opportunities={opportunities}
        fetchOpportunities={fetchOpportunities}
      />
      <ImportantInformation
        opportunities={opportunities}
        fetchOpportunities={fetchOpportunities}
      />

      {opportunities.company && (
        <>
          <OpportunitiesCompanyInfo
            company={opportunities.company}
            fetchOpportunities={fetchOpportunities}
          />
          {opportunities.company.lead && (
            <OpportunitiesCompanyLead
              lead={opportunities.client.lead}
              fetchOpportunities={fetchOpportunities}
            />
          )}
        </>
      )}
      {opportunities.client && (
        <>
          <OpportunitiesClientInfo
            client={opportunities.client}
            fetchOpportunities={fetchOpportunities}
          />
          {opportunities.client.lead && (
            <OpportunitiesClientLead
              lead={opportunities.client.lead}
              fetchOpportunities={fetchOpportunities}
            />
          )}
        </>
      )}
    </StyledContentContainer>
  );
};

const StyledContentContainer = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default OpportunitiesLeftAside;
