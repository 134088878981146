import React from "react";
import styled from "styled-components";

const ClientItem = ({ client, handleOnClick }) => {
  return (
    <StyledWrapper onClick={() => handleOnClick(client)} type="button">
      {client.first_name} {client.last_name}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.button`
  border: none;
  outline: none;
  text-align: left;
  background: transparent;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #cadeee;
  }
`;

export default ClientItem;
