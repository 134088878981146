import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AddClients from "../../../../Contact/Clients/AddClients/AddClients";

const OpportunitiesClientInfo = ({ client, fetchOpportunities }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const [adressString, setAdressString] = useState(null);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setEditMode(false);
    setEditObject(null);
  };

  const handleOnClickEdit = () => {
    setEditObject(client);
    setEditMode(true);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (
      client.city &&
      client.building_number &&
      client.postCode &&
      client.post &&
      client.street
    ) {
      setAdressString(
        `ul. ${client?.street} ${client?.building_number}, ${client?.city} ${client?.postCode} ${client?.post}`
      );
    } else if (
      client?.city &&
      client?.building_number &&
      client?.post &&
      client?.postCode
    ) {
      setAdressString(
        `${client?.city} ${client?.building_number}, ${client?.postCode} ${client?.post} `
      );
    } else if (
      client?.city &&
      client?.street &&
      client?.post &&
      client?.postCode
    ) {
      setAdressString(
        `ul. ${client?.street} ${client?.city}, ${client?.postCode} ${client?.post}`
      );
    } else if (
      client.city &&
      client.building_number &&
      client.postCode &&
      client.street
    ) {
      setAdressString(
        `ul. ${client?.street} ${client?.building_number}, ${client?.postCode} ${client?.city}`
      );
    } else if (client?.city && client?.building_number && client?.postCode) {
      setAdressString(
        `${client?.building_number}, ${client?.postCode} ${client?.city} `
      );
    } else if (client?.city && client?.street && client?.postCode) {
      setAdressString(
        `ul. ${client?.street}, ${client?.postCode} ${client?.city}`
      );
    } else {
      setAdressString(" Brak adresu / Adres nieprawidłowy");
    }
  }, [
    client,
    client?.postCode,
    client?.street,
    client?.city,
    client?.building_number,
  ]);
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitleP>Osoba kontaktowa:</StyledTitleP>
      </StyledTitleWrapper>
      <StyledNameWrapper>
        <StyledNameLink to={`/dashboard/contacts/clients/details/${client.id}`}>
          {client.first_name} {client.last_name}
        </StyledNameLink>
      </StyledNameWrapper>
      <StyledDetailsWrapper>
        {client.email && (
          <StyledEmailWrapper>
            <StyledEmailIcon icon={faEnvelope}></StyledEmailIcon>
            <StyledEmailLink href={`mailto:${client.email}`}>
              {client.email}
            </StyledEmailLink>
          </StyledEmailWrapper>
        )}
        {client.phone && (
          <StyledPhoneWrapper>
            <StyledPhoneIcon icon={faPhone}></StyledPhoneIcon>
            <StyledPhoneLink href={`tel:${client.phone}`}>
              {client.phone}
            </StyledPhoneLink>
          </StyledPhoneWrapper>
        )}
        {client.position && (
          <StyledItemWrapper>
            <StyledItemTitle>Stanowisko:</StyledItemTitle>
            <StyledItemP>{client.position}</StyledItemP>
          </StyledItemWrapper>
        )}
        {client.NIP && (
          <StyledItemWrapper>
            <StyledItemTitle>NIP:</StyledItemTitle>
            <StyledItemP>{client.NIP}</StyledItemP>
          </StyledItemWrapper>
        )}
        {adressString && (
          <StyledItemWrapper>
            <StyledMarkerIcon icon={faMapMarkerAlt} />
            <StyledItemP>{adressString}</StyledItemP>
          </StyledItemWrapper>
        )}
        {client.voivodeship && (
          <StyledItemWrapper>
            <StyledItemTitle>Województwo:</StyledItemTitle>
            <StyledItemP>{client.voivodeship.name}</StyledItemP>
          </StyledItemWrapper>
        )}
        {client.country && (
          <StyledItemWrapper>
            <StyledItemTitle>Kraj:</StyledItemTitle>
            <StyledItemP>{client.country}</StyledItemP>
          </StyledItemWrapper>
        )}
        {client.description && (
          <StyledItemWrapper>
            <StyledItemTitle>Opis:</StyledItemTitle>
            <StyledItemP>{client.description}</StyledItemP>
          </StyledItemWrapper>
        )}

        <StyledButtonWrapper>
          <StyledButton onClick={handleOnClickEdit}>Edytuj</StyledButton>
        </StyledButtonWrapper>
      </StyledDetailsWrapper>

      <AddClients
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchOpportunities}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledWrapper>
  );
};

const StyledMarkerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledButton = styled.button`
  padding: 5px;
  background: #134771;
  color: #cadeee;
  border: none;
  width: 100%;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledItemP = styled.p``;
const StyledItemTitle = styled.p`
  margin-right: 5px;
  font-weight: bold;
`;
const StyledItemWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

const StyledPhoneLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

const StyledPhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  font-size: 14px;
`;

const StyledPhoneWrapper = styled.div`
  margin-top: 5px;
  display: flex;
`;

const StyledEmailIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  font-size: 14px;
`;
const StyledEmailWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

const StyledEmailLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;
const StyledDetailsWrapper = styled.div`
  padding: 10px;
`;
const StyledTitleP = styled.p`
  font-size: 18px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  border-radius: 5px 5px 0 0;
  padding: 5px;
`;
const StyledNameLink = styled(Link)`
  font-size: 18px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;
const StyledNameWrapper = styled.div`
  padding: 10px; ;
`;
const StyledWrapper = styled.div`
  min-width: 300px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 1600px) {
    flex: 1;
  }
`;

export default OpportunitiesClientInfo;
