import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import OfferList from "./Components/OfferList";
import ShowOfferDetails from "./Components/ShowOfferDetails";
import ChooseOfferType from "./MakeOffer/ChooseOfferType/ChooseOfferType";
import MakeCompanyBuisnesOffer from "./MakeOffer/MakeCompanyBuisnesOffer";
import MakeCompanyFarmerOffer from "./MakeOffer/MakeCompanyFarmerOffer";
import MakeIndividualFarmerOffer from "./MakeOffer/MakeIndividualFarmerOffer";
import MakeIndividualOffer from "./MakeOffer/MakeIndividualOffer";
import MakeSimilarOffer from "./MakeOffer/MakeSimilarOffer";

const PhotovoltaicMakeOffer = ({ opportunities, fetchData }) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [offerObject, setOfferObject] = useState(null);
  const [isChooseModalOpen, setIsChooseModalOpen] = useState(false);
  const [isIndividualOpen, setIsIndividualOpen] = useState(false);
  const [isIndividualFarmerOpen, setIsIndividualFarmerOpen] = useState(false);
  const [isBuisnessOpen, setIsBuisnessOpen] = useState(false);
  const [isBuisnessFarmerOpen, setIsBuisnessFarmerOpen] = useState(false);
  const [isSimilarOfferOpen, setIsSimilarOfferOpen] = useState(false);

  const { user } = useContext(StoreContext);

  const handleOnChangePhotovoltaic = () => {
    setIsChooseModalOpen(true);
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOnCloseChoose = () => {
    setIsChooseModalOpen(false);
  };

  const handleChooseBuisness = () => {
    setIsChooseModalOpen(false);
    setIsBuisnessOpen(true);
  };

  const handleChooseFarmerBuisness = () => {
    setIsChooseModalOpen(false);
    setIsBuisnessFarmerOpen(true);
  };

  const handleChooseIndividual = () => {
    setIsChooseModalOpen(false);
    setIsIndividualOpen(true);
  };

  const handleChooseFarmer = () => {
    setIsChooseModalOpen(false);
    setIsIndividualFarmerOpen(true);
  };

  const handleOnCloseIndividual = () => {
    setIsIndividualOpen(false);
  };
  const handleOnCloseIndividualFarmer = () => {
    setIsIndividualFarmerOpen(false);
  };

  const handleOnCloseBuisness = () => {
    setIsBuisnessOpen(false);
  };

  const handleOnCloseBuisnessFarmer = () => {
    setIsBuisnessFarmerOpen(false);
  };

  const handleCreateSimilarOffer = () => {
    setIsShowModalOpen(false);
    setIsSimilarOfferOpen(true);
  };

  const handleOnCloseSimilarOffer = () => {
    setIsSimilarOfferOpen(false);
  };

  return (
    <StyledOfferWrapper>
      <StyledHeaderText>Oferty</StyledHeaderText>
      <OfferList
        offers={opportunities?.offer}
        setIsShowModalOpen={setIsShowModalOpen}
        setOfferObject={setOfferObject}
      />

      {opportunities?.status === 1 && (
        <StyledMakeOfferButtonWrapper>
          <StyledMakeOfferButton onClick={handleOnChangePhotovoltaic}>
            Generuj oferte
          </StyledMakeOfferButton>
        </StyledMakeOfferButtonWrapper>
      )}
      <ShowOfferDetails
        opportunities={opportunities}
        fetchData={fetchData}
        isShowModalOpen={isShowModalOpen}
        offerObject={offerObject}
        handleOnClose={handleOnClose}
        handleCreateSimilarOffer={handleCreateSimilarOffer}
      />

      <ChooseOfferType
        opportunities={opportunities}
        handleOnClose={handleOnCloseChoose}
        isChooseModalOpen={isChooseModalOpen}
        handleChooseBuisness={handleChooseBuisness}
        handleChooseFarmerBuisness={handleChooseFarmerBuisness}
        handleChooseIndividual={handleChooseIndividual}
        handleChooseFarmer={handleChooseFarmer}
      />

      <MakeIndividualOffer
        client={opportunities.client}
        opportunities_id={opportunities.id}
        isModalOpen={isIndividualOpen}
        setIsModalOpen={setIsIndividualOpen}
        handleOnClose={handleOnCloseIndividual}
        fetchData={fetchData}
      />
      <MakeIndividualFarmerOffer
        client={opportunities.client}
        opportunities_id={opportunities.id}
        isModalOpen={isIndividualFarmerOpen}
        setIsModalOpen={setIsIndividualFarmerOpen}
        handleOnClose={handleOnCloseIndividualFarmer}
        fetchData={fetchData}
      />
      <MakeCompanyBuisnesOffer
        company={opportunities.company}
        opportunities_id={opportunities.id}
        isModalOpen={isBuisnessOpen}
        setIsModalOpen={setIsBuisnessOpen}
        handleOnClose={handleOnCloseBuisness}
        fetchData={fetchData}
      />

      <MakeCompanyFarmerOffer
        company={opportunities.company}
        opportunities_id={opportunities.id}
        isModalOpen={isBuisnessFarmerOpen}
        setIsModalOpen={setIsBuisnessFarmerOpen}
        handleOnClose={handleOnCloseBuisnessFarmer}
        fetchData={fetchData}
      />
      <MakeSimilarOffer
        company={opportunities.company}
        client={opportunities.client}
        offerObject={offerObject}
        opportunities_id={opportunities.id}
        isModalOpen={isSimilarOfferOpen}
        setIsModalOpen={setIsSimilarOfferOpen}
        handleOnClose={handleOnCloseSimilarOffer}
        fetchData={fetchData}
      />
    </StyledOfferWrapper>
  );
};
const StyledMakeOfferButton = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;
const StyledMakeOfferButtonWrapper = styled.div`
  padding: 10px;
`;
const StyledOfferWrapper = styled.div`
  background: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #13477140;
`;
const StyledHeaderText = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgb(244, 118, 49);
  font-weight: bold;
`;

export default PhotovoltaicMakeOffer;
