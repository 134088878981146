import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../components/Modal";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nazwa jest wymagana")
    .min(3, "Nazwa musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwa nie może być dłuższa niż 255 znaków"),
  price: yup.string().required("Cena jest wymagana"),
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "black",
    padding: 2,
    fontSize: 14,
  }),
  control: () => ({
    display: "flex",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#cadeee",
    };
  },
  placeholder: (styles) => ({
    ...styles,
    color: "black",
    fontSize: 14,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    color: "black",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "rgb(19, 71, 113)",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "rgb(19, 71, 113)",
    ":hover": {
      backgroundColor: "rgb(19, 71, 113)",
      color: "#cadeee",
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const AddConstruction = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      price: 0,
      margin: 0,
      warranty: 0,
      max_panel_length: null,
      construction_type_id: "",
      transport_type_id: "",
      add_price: 0,
      panels: "",
    },
  });

  const location = useLocation();
  const history = useHistory();
  const [isSended, setIsSended] = useState(false);
  const [selectOption, setSelectOption] = useState([]);
  const { constructionType, panels, transports } = useContext(StoreContext);

  const resetInputValue = () => {
    reset({
      name: "",
      price: 0,
      margin: 0,
      warranty: 0,
      max_panel_length: null,
      construction_type_id: "",
      transport_type_id: "",
      add_price: 0,
      panels: "",
    });
  };

  const sendData = async (data) => {
    setIsSended(true);
    try {
      if (isEditMode) {
        const { status } = await request.patch(
          `/api/constructions/${editObject.id}`,
          { ...data }
        );
        if (status === 200) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      } else {
        const { status } = await request.post("/api/constructions", {
          ...data,
        });
        if (status === 201) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (editObject) {
      setValue("name", editObject.name);
      setValue("price", editObject.price);
      setValue("margin", editObject.margin);
      setValue("warranty", editObject.warranty);
      setValue("max_panel_length", editObject.max_panel_length);
      setValue("construction_type_id", editObject.construction_type?.id || "");
      setValue("transport_type_id", editObject.transport_type?.id || "");
      setValue("add_price", editObject.add_price);
      setValue(
        "panels",
        editObject?.panels.map((panel) => {
          return { label: panel.name, value: panel.id };
        })
      );
    } else {
      setValue("name", "");
      setValue("price", 0);
      setValue("margin", 0);
      setValue("warranty", 0);
      setValue("max_panel_length", null);
      setValue("construction_type_id", "");
      setValue("transport_type_id", "");
      setValue("add_price", 0);
      setValue("panels", "");
    }
  }, [editObject]);

  useEffect(() => {
    if (panels) {
      setSelectOption(
        panels.map((panel) => {
          return {
            label: `${panel.name} - ${panel.width} x ${panel.length}`,
            value: panel.id,
          };
        })
      );
    }
  }, [panels, editObject]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledInput>
          <div className="input-group">
            <label>Nazwa konstruckji</label>
            <input type="text" {...register("name")} />
          </div>
        </StyledInput>
        {errors?.name && <span className="error">{errors.name.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Gwarancja</label>
            <input type="number" {...register("warranty")} />
          </div>
        </StyledInput>
        {errors?.warranty && (
          <span className="error">{errors.warranty.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Maksymalna długość panelu</label>
            <input type="number" {...register("max_panel_length")} />
          </div>
        </StyledInput>
        {errors?.max_panel_length && (
          <span className="error">{errors.max_panel_length.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Cena za 1kWp</label>
            <input type="number" {...register("price")} />
          </div>
        </StyledInput>
        {errors?.price && <span className="error">{errors.price.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Cena jednorazowa dodatkowa</label>
            <input type="number" {...register("add_price")} />
          </div>
        </StyledInput>
        {errors?.add_price && (
          <span className="error">{errors.add_price.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Marża</label>
            <input type="number" {...register("margin")} />
          </div>
        </StyledInput>
        {errors?.margin && (
          <span className="error">{errors.margin.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Typ konstrukcji</label>
            <select type="number" {...register("construction_type_id")}>
              <option value={""}>Wybierz...</option>
              {constructionType &&
                constructionType.map((type) => (
                  <option key={type.id} value={type.id}>
                    {" "}
                    {type.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.margin && (
          <span className="error">{errors.margin.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Transport</label>
            <select type="number" {...register("transport_type_id")}>
              <option value={""}>Wybierz...</option>
              {transports &&
                transports.map((type) => (
                  <option key={type.id} value={type.id}>
                    {" "}
                    {type.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.transport_type_id && (
          <span className="error">{errors.transport_type_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Pasujące panele</label>
            <Controller
              control={control}
              name="panels"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  placeholder={"Wybierz..."}
                  isMulti
                  onChange={(val) => onChange(val)}
                  value={value}
                  styles={customStyles}
                  options={selectOption}
                />
              )}
            />
          </div>
        </StyledInput>

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            {isEditMode ? "Zapisz" : "Dodaj"}
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 360px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #2d96db;
        background: transparent;
      }
      &.close:hover {
        color: black;
      }
      &.add {
        background: #134771;
        color: white;
      }
      &.add:hover {
        background: #005e9c;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;
const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddConstruction;
