import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import OpportunitiesStatus from "./OpportunitiesStatus/OpportunitiesStatus";
import { StoreContext } from "../../../../store/StoreProvider";
import request from "../../../../helpers/request";
import { useLocation, useHistory } from "react-router";
import OpportunitiesAssembly from "./OpportunitiesAssembly/OpportunitiesAssembly";
import PhotovoltaicSaleProcess from "./PhotovoltaicSaleProcess/PhotovoltaicSaleProcess";
import TodoList from "./TodoSideBarList/TodoList/TodoList";
import OpportunityContractRealization from "./OpportunityContractRealization/OpportunityContractRealization";
import HeatPumpSaleProcess from "./HeatPumpSaleProcess/HeatPumpSaleProcess";
import SaleOpportunityContact from "./SaleOpportunityContact/SaleOpportunityContact";

const OpportunitiesSideBar = ({
  opportunities,
  fetchData,
  setIsAssemblyModalOpen,
  isAssemblyModalOpen,
  setIsAssemblyEditMode,
  setAssemblyEditObject,
}) => {
  const location = useLocation();
  const history = useHistory();

  const {
    opportunityStatuses,
    setOpportunityStatuses,
    assemblyBus,
    setAssemblyBus,
    user,
  } = useContext(StoreContext);

  const fetchOpportunityStatuses = async () => {
    try {
      const { data, status } = await request.get("api/opportunities/status");
      if (status === 200) {
        setOpportunityStatuses(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchDataAssembluBus = async () => {
    try {
      const { status, data } = await request.get("/api/assembly-bus");
      if (status === 200) {
        await setAssemblyBus(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (opportunityStatuses === undefined || opportunityStatuses.length === 0) {
      fetchOpportunityStatuses();
    }
    if (assemblyBus === undefined || assemblyBus.length === 0) {
      fetchDataAssembluBus();
    }
  }, []);

  return (
    <StyledOportunitiesSideBarWrapper>
      {(user?.role?.id === 1 ||
        user?.role?.id === 3 ||
        opportunities?.status === 1) && (
        <StyledOpportunitieslist>
          <TodoList
            todos={opportunities.todo || []}
            fetchData={fetchData}
            opportunities_id={opportunities.id}
          />
        </StyledOpportunitieslist>
      )}
      <SaleOpportunityContact opportunities={opportunities} />

      <OpportunitiesStatus
        status={opportunities.status}
        statuses={opportunityStatuses}
        fetchData={fetchData}
        opportunities={opportunities}
      />
      {(opportunities.status === 2 ||
        user?.role?.id === 1 ||
        user?.role?.id === 3) && (
        <OpportunityContractRealization
          opportunities={opportunities}
          fetchData={fetchData}
        />
      )}
      {opportunities.is_photovoltaic === 1 && (
        <PhotovoltaicSaleProcess
          opportunities={opportunities}
          fetchData={fetchData}
        />
      )}
      {opportunities.is_heat_pump === 1 && (
        <HeatPumpSaleProcess
          opportunities={opportunities}
          fetchData={fetchData}
        />
      )}
      <OpportunitiesAssembly
        opportunities={opportunities}
        fetchData={fetchData}
        setIsAssemblyModalOpen={setIsAssemblyModalOpen}
        isAssemblyModalOpen={isAssemblyModalOpen}
        setIsAssemblyEditMode={setIsAssemblyEditMode}
        setAssemblyEditObject={setAssemblyEditObject}
      />
    </StyledOportunitiesSideBarWrapper>
  );
};

const StyledOportunitiesSideBarWrapper = styled.div`
  grid-area: sidebar;
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const StyledOpportunitieslist = styled.div`
  max-height: 400px;
`;

export default OpportunitiesSideBar;
