import React from "react";
import styled from "styled-components";

const CollapseYourWorkItem = ({
  name,
  id,
  setYourTaskFilter,
  yourTaskFilter,
}) => {
  const handleOnClick = () => {
    if (yourTaskFilter === id) {
      setYourTaskFilter(null);
    } else {
      setYourTaskFilter(id);
    }
  };

  return (
    <StyledCollapseItemButton
      onClick={handleOnClick}
      className={yourTaskFilter === id && "active"}
    >
      <span></span>
      <p>{name}</p>
    </StyledCollapseItemButton>
  );
};
const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 2px 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  &:hover {
    background: lightgray;
  }
  &.active {
    background: lightgray;
  }

  span {
    display: block;
    height: 10px;
    margin: 0 10px;
    width: 10px;
    border: 1px solid black;
    border-radius: 50%;
    text-align: center;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
  }
`;

export default CollapseYourWorkItem;
