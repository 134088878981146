import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../store/StoreProvider";
import CollapseTyp from "./CollapseTyp/CollapseTyp";
import CollapseUser from "./CollapseUser/CollapseUser";

const CalendarAside = ({
  setTaskTypeFilter,
  taskTypeFilter,
  userFilter,
  setUserFilter,
}) => {
  const { user } = useContext(StoreContext);
  return (
    <StyledAside>
      {user.role?.id !== 4 && (
        <CollapseUser
          title="Osoby"
          userFilter={userFilter}
          setUserFilter={setUserFilter}
        />
      )}

      <CollapseTyp
        title="Typ"
        setTaskTypeFilter={setTaskTypeFilter}
        taskTypeFilter={taskTypeFilter}
      />
    </StyledAside>
  );
};

const StyledAside = styled.div`
  width: 200px;
`;

export default CalendarAside;
