import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import CommissionedOrderModal from "./Components/CommissionedOrderModal";
import ProductOrderModal from "./Components/ProductOrderModal";
import ProductOrderAddModal from "./Components/ProductOrderAddModal";
import GoodsShipped from "./Components/GoodsShippedModal";
import ProductOrderShowModal from "./Components/ProductOrderShowModal";
import ProductOrderEdit from "./Components/ProductOrderEdit/ProductOrderEdit";
import ChangeFile from "./Components/ProductOrderEdit/ChangeFile";

const ProductOrder = ({ opportunities, fetchData }) => {
  const { user } = useContext(StoreContext);
  const [isSended, setIsSended] = useState(false);
  const [isOrderProductModalOpen, setIsOrderProductModalOpen] = useState(false);
  const [isOrderProductAddModalOpen, setIsOrderProductAddModalOpen] =
    useState(false);
  const [isOrderProductShowModalOpen, setIsOrderProductShowModalOpen] =
    useState(false);
  const [isCommissionedOrderModalOpen, setIsCommissionedOrderModalOpen] =
    useState(false);
  const [isGoodsShippedModalOpen, setIsGoodsShippedModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);

  const handleOnOpenProductOrderModal = () => {
    setIsOrderProductModalOpen(true);
  };

  const handleOnOpenProductOrderAddModal = () => {
    setIsOrderProductModalOpen(false);
    setIsOrderProductAddModalOpen(true);
  };

  const handleOnOpenCommissionedOrderModal = () => {
    setIsOrderProductModalOpen(false);
    setIsCommissionedOrderModalOpen(true);
  };

  const handleOnOpenGoodsShippedModal = () => {
    setIsOrderProductModalOpen(false);
    setIsGoodsShippedModalOpen(true);
  };

  const handleOnOpenProductOrderShowModal = () => {
    setIsOrderProductModalOpen(false);
    setIsOrderProductShowModalOpen(true);
  };

  const handleOnOpenEditModal = () => {
    setIsOrderProductShowModalOpen(false);
    setIsEditModalOpen(true);
  };

  const handleOnOpenChangeModal = (e) => {
    e.preventDefault();
    setIsEditModalOpen(false);
    setIsChangeModalOpen(true);
  };

  const handleOnCloseProductOrderModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOrderProductModalOpen(false);
  };

  const handleOnCloseCommissionedOrderModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsCommissionedOrderModalOpen(false);
  };

  const handleOnCloseProductOrderAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOrderProductAddModalOpen(false);
  };

  const handleOnCloseGoodsShipperModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsGoodsShippedModalOpen(false);
  };

  const handleOnCloseOrderProductShowModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOrderProductShowModalOpen(false);
  };

  const handleOnCloseEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
  };
  const handleOnCloseChangeModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsChangeModalOpen(false);
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Zamówienie towaru</StyledTitle>
      </StyledTitleWrapper>
      {(!opportunities.product_order ||
        (opportunities.product_order &&
          opportunities.product_order.commissioned_order === 0 &&
          opportunities.product_order.is_order === 0 &&
          opportunities.product_order.goods_shipped === 0)) && (
        <StyledFalseInfo>Towar nie zamówiony</StyledFalseInfo>
      )}

      {opportunities.product_order &&
        opportunities.product_order.commissioned_order === 1 &&
        opportunities.product_order.is_order === 0 &&
        opportunities.product_order.goods_shipped === 0 && (
          <StyledTrueInfo>Zamówienie zlecone</StyledTrueInfo>
        )}

      {opportunities.product_order &&
        opportunities.product_order.commissioned_order === 1 &&
        opportunities.product_order.is_order === 1 &&
        opportunities.product_order.goods_shipped === 0 && (
          <StyledTrueInfo>WZ wystawiona</StyledTrueInfo>
        )}
      {opportunities.product_order &&
        opportunities.product_order.commissioned_order === 1 &&
        opportunities.product_order.is_order === 1 &&
        opportunities.product_order.goods_shipped === 1 && (
          <StyledTrueInfo>Towar wysłany</StyledTrueInfo>
        )}

      {(user?.role?.id === 1 || user.role?.id === 3) && (
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnOpenProductOrderModal}>
            Otwórz opcje
          </StyledButton>
        </StyledButtonWrapper>
      )}

      <ProductOrderShowModal
        isModalOpen={isOrderProductShowModalOpen}
        handleOnOpenEditModal={handleOnOpenEditModal}
        handleOnClose={handleOnCloseOrderProductShowModal}
        opportunities={opportunities}
      />

      <CommissionedOrderModal
        isModalOpen={isCommissionedOrderModalOpen}
        fetchData={fetchData}
        setIsSended={setIsSended}
        isSended={isSended}
        handleOnClose={handleOnCloseCommissionedOrderModal}
        opportunities={opportunities}
      />
      <ProductOrderModal
        isModalOpen={isOrderProductModalOpen}
        fetchData={fetchData}
        setIsSended={setIsSended}
        opportunities={opportunities}
        handleOnClose={handleOnCloseProductOrderModal}
        handleOnOpenCommissionedOrderModal={handleOnOpenCommissionedOrderModal}
        handleOnOpenProductOrderAddModal={handleOnOpenProductOrderAddModal}
        handleOnOpenGoodsShippedModal={handleOnOpenGoodsShippedModal}
        handleOnOpenProductOrderShowModal={handleOnOpenProductOrderShowModal}
      />
      <ProductOrderAddModal
        isModalOpen={isOrderProductAddModalOpen}
        fetchData={fetchData}
        setIsSended={setIsSended}
        isSended={isSended}
        handleOnClose={handleOnCloseProductOrderAddModal}
        opportunities={opportunities}
      />
      <GoodsShipped
        isModalOpen={isGoodsShippedModalOpen}
        fetchData={fetchData}
        setIsSended={setIsSended}
        isSended={isSended}
        handleOnClose={handleOnCloseGoodsShipperModal}
        opportunities={opportunities}
      />
      <ProductOrderEdit
        isModalOpen={isEditModalOpen}
        handleOnClose={handleOnCloseEditModal}
        fetchData={fetchData}
        opportunities={opportunities}
        handleOnOpenChangeModal={handleOnOpenChangeModal}
      />

      <ChangeFile
        isModalOpen={isChangeModalOpen}
        handleOnClose={handleOnCloseChangeModal}
        opportunities={opportunities}
        fetchData={fetchData}
        setIsModalOpen={setIsChangeModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        setIsSended={setIsSended}
        isSended={isSended}
      />
    </StyledWrapper>
  );
};

const StyledFalseInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(244, 118, 49);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledTrueInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(102, 188, 70);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div``;

export default ProductOrder;
