import moment from "moment";
import React from "react";
import styled from "styled-components";

const ContactLead = ({ lead }) => {
  return (
    <StyledWrapper>
      {lead?.contact_the_lead === 0 ? (
        <StyledNoContactWrapper>Kontakt nie nawiązany</StyledNoContactWrapper>
      ) : (
        <StyledContactInfoWrapper>
          <StyledInfoItem>Kontakt nawiązany</StyledInfoItem>
        </StyledContactInfoWrapper>
      )}
      <StyledContactInfoWrapper>
        {lead?.contact_note.map((note) => (
          <StyledNoteItemWrapper key={note.id}>
            <StyledInfoItem>Przez: {note.user.name}</StyledInfoItem>
            <StyledInfoItem>
              Dnia : {moment(note.date).format("DD.MM.YY HH:mm")}
            </StyledInfoItem>
            <StyledInfoItem> {note.note} </StyledInfoItem>
          </StyledNoteItemWrapper>
        ))}
      </StyledContactInfoWrapper>
    </StyledWrapper>
  );
};

const StyledNoteItemWrapper = styled.div`
  margin: 10px 0;
`;

const StyledInfoItem = styled.div``;

const StyledContactInfoWrapper = styled.div``;

const StyledNoContactWrapper = styled.div``;

const StyledWrapper = styled.div`
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
`;

export default ContactLead;
