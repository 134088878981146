import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../helpers/request";
import { StoreContext } from "../../../../store/StoreProvider";
import AddAssemblyBus from "./AddAssemblyBus";
import AssemblyItem from "./AssemblyItem/AssemblyItem";

const AssemblySettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState();

  const location = useLocation();
  const history = useHistory();

  const { assemblyBus, setAssemblyBus } = useContext(StoreContext);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleOpenEditBus = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchDataAssembluBus = async () => {
    try {
      const { status, data } = await request.get("/api/assembly-bus");
      if (status === 200) {
        await setAssemblyBus(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (assemblyBus === undefined || assemblyBus.length === 0) {
      fetchDataAssembluBus();
    }
  }, []);

  return (
    <StyledAssembluBusWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj busa</StyledButton>
      </StyledButtonWrapper>
      <StyledAssembluBusList>
        {assemblyBus.map((element) => {
          return (
            <AssemblyItem
              handleOpenEditStatusTask={handleOpenEditBus}
              fetchDataAssembluBus={fetchDataAssembluBus}
              setEditObject={setEditObject}
              element={element}
              key={element.id}
            />
          );
        })}
      </StyledAssembluBusList>
      <AddAssemblyBus
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataAssembluBus}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledAssembluBusWrapper>
  );
};
const StyledAssembluBusWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledAssembluBusList = styled.ul`
  padding: 40px 0;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default AssemblySettings;
