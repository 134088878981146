import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faBullseye,
  faCheck,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import request from "../../helpers/request";
import { StoreContext } from "../../store/StoreProvider";
import Opportunities from "./Opportunities";
import OpportunitiesDetails from "./OpportunitiesDetails/OpportunitiesDetails";

library.add(faBullseye, faEllipsisH, faBan, faCheck);
const OpportunitiesSwitch = () => {
  const {
    opportunityStatuses,
    setOpportunityStatuses,
    sources,
    teams,
    setTeams,
    departments,
    setDepartments,
    voivodeships,
    setVoivodeships,
  } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  let { path } = useRouteMatch();

  const fetchOpportunityStatuses = async () => {
    try {
      const { data, status } = await request.get("api/opportunities/status");
      if (status === 200) {
        setOpportunityStatuses(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchDataDepartment = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (opportunityStatuses === undefined || opportunityStatuses.length === 0) {
      fetchOpportunityStatuses();
    }
    if (teams === undefined || teams.length === 0) {
      fetchDataTeams();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartment();
    }
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
  }, []);

  return (
    <>
      <Switch>
        <Route exact path={`${path}/`}>
          <Opportunities opportunityStatuses={opportunityStatuses} />
        </Route>
        <Route path={`${path}/details/:id`}>
          <OpportunitiesDetails />
        </Route>
      </Switch>
    </>
  );
};

export default OpportunitiesSwitch;
