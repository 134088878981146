import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";

const validationSchema = yup.object({
  text: yup.string(),
});

const SearchBar = ({
  setShowOpportunities,
  setShowCompanies,
  setShowClients,
  setSearchClients,
  setSearchCompanies,
  setSearchOpportunities,
  setSearching,
  setClientsCount,
  setCompaniesCount,
  setOpportunitiesCount,
  setSearchLeads,
  setLeadsCount,
  setShowLeads,
  text,
}) => {
  const { register, handleSubmit, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const history = useHistory();
  const location = useLocation();

  const sendData = async (query) => {
    setSearching(true);
    try {
      const { data, status } = await request.get(`/api/search?text=${query}`);

      if (status === 200) {
        setSearchClients(data.clients);
        setClientsCount(data.clientCount);
        if (data.clientCount > 0) {
          setShowClients(true);
        } else {
          setShowClients(false);
        }
        setSearchCompanies(data.companies);
        setCompaniesCount(data.companiesCount);
        if (data.companiesCount > 0) {
          setShowCompanies(true);
        } else {
          setShowCompanies(false);
        }

        setSearchOpportunities(data.opportunities);
        setOpportunitiesCount(data.opportunitiesCount);

        if (data.opportunitiesCount > 0) {
          setShowOpportunities(true);
        } else {
          setShowOpportunities(false);
        }

        setSearchLeads(data.leads);
        setLeadsCount(data.leadsCount);

        if (data.leadsCount > 0) {
          setShowLeads(true);
        } else {
          setShowLeads(false);
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setSearching(false);
  };

  const handleOnSubmit = (data) => {
    if (data?.text) {
      const searchText = data.text.replace(" ", "+");
      history.push(`/dashboard/search?text=${searchText}`);
    }
  };

  useEffect(() => {
    if (text) {
      setValue("text", text);
      sendData(text);
    }
  }, [text]);

  return (
    <StyledSearchBarWrapper>
      <StyledSearchBar onSubmit={handleSubmit(handleOnSubmit)}>
        <StyledSearchInput
          type="text"
          {...register("text")}
          autoComplete="off"
        />
        <StyledSearchButton type="submit">
          <FontAwesomeIcon icon={faSearch} />
        </StyledSearchButton>
      </StyledSearchBar>
    </StyledSearchBarWrapper>
  );
};

const StyledSearchButton = styled.button`
  border: none;
  padding: 5px;
  background: white;
  color: #134771;
  border-radius: 0 5px 5px 0;
  cursor: pointer;

  &:hover {
    color: #053257;
  }
`;
const StyledSearchBar = styled.form`
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`;
const StyledSearchInput = styled.input`
  width: 360px;
  border: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
`;
const StyledSearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 60px;
`;

export default SearchBar;
