import { faBan, faBullseye, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import OpportunitiesPopOverCopmonent from "./OpportunitiesPopOverCopmonent";

const CompanyComponentItem = ({ opportunity }) => {
  const [hover, setHover] = useState(false);
  const [spanObject, setSpanObject] = useState(null);
  const hoverRef = useRef();
  let timer;
  const timerOuter = useRef(null);

  const handleOnHover = (e) => {
    timer = setTimeout(() => {
      setHover(true);
    }, 500);

    window.addEventListener("mousemove", handleOnMouseMove, true);
  };

  const handleOnSetTimeOut = () => {
    setHover(false);
    window.removeEventListener("mousemove", handleOnMouseMove, true);
  };
  const handleOnMouseLeave = (e) => {
    clearTimeout(timer);
    timerOuter.current = setTimeout(handleOnSetTimeOut, 500);
  };

  const handleOnMouseMove = (e) => {
    if (hoverRef?.current?.contains(e.target)) {
      clearTimeout(timerOuter.current);
      window.removeEventListener("mousemove", handleOnMouseMove, true);
    }
  };

  useEffect(() => {
    if (opportunity.status === 2) {
      setSpanObject(
        <StyledSpan style={{ background: "rgb(178, 233, 176)" }}>
          <FontAwesomeIcon style={{ color: "white" }} icon={faCheck} />
        </StyledSpan>
      );
    } else if (opportunity.status === 1) {
      setSpanObject(
        <StyledSpan style={{ background: "rgb(255, 238, 127)" }}>
          <FontAwesomeIcon style={{ color: "white" }} icon={faBullseye} />
        </StyledSpan>
      );
    } else if (opportunity.status === 3) {
      setSpanObject(
        <StyledSpan style={{ background: "white" }}>
          <FontAwesomeIcon style={{ color: "#ff7199" }} icon={faBan} />
        </StyledSpan>
      );
    }
  }, [opportunity]);

  return (
    <StyledWrapper>
      {spanObject}
      <StyledDetailsWrapper>
        <StyledNameLink
          onMouseEnter={handleOnHover}
          onMouseLeave={handleOnMouseLeave}
          to={`/dashboard/opportunities/details/${opportunity.id}`}
        >
          {opportunity.name}
        </StyledNameLink>
      </StyledDetailsWrapper>
      <OpportunitiesPopOverCopmonent
        opportunity={opportunity}
        hover={hover}
        setHover={setHover}
        hoverRef={hoverRef}
      />
    </StyledWrapper>
  );
};

const StyledSpan = styled.span`
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 50%;
  flex-shrink: 0;
`;

const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const StyledNameLink = styled(Link)`
  color: #134771;
  font-size: 18px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledWrapper = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
  min-width: 0;
`;

export default CompanyComponentItem;
