import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { useLocation, useHistory } from "react-router";
import { StoreContext } from "../../../../../../store/StoreProvider";
import request from "../../../../../../helpers/request";
import Modal from "../../../../../../components/Modal";

const validationSchema = yup.object({
  assembly_start: yup.date().required("Data finalizacji jest wymagana"),
  assembly_end: yup.date().required("Data finalizacji jest wymagana"),
  assembly: yup.boolean().required(),
});

const AddAssembly = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const { assemblyBus } = useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();
  const Today = new Date();
  const [isSended, setIsSended] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      assembly_start: Today,
      assembly_end: Today,
      assembly: true,
      bus_id: null,
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `/api/opportunities/update-assembly/${editObject.id}`,
        { ...data }
      );

      if (status === 201) {
        handleOnClose();
        fetchData();
        isEditMode(false);
        resetInputValue();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (Boolean(editObject.assembly)) {
      setValue("assembly_start", new Date(editObject.assembly_start));
      setValue("assembly_end", new Date(editObject.assembly_end));
      setValue("assembly", editObject.assembly);
      setValue("bus_id", editObject.bus?.id);
    } else {
      setValue("assembly_start", new Date(Today));
      setValue("assembly_end", new Date(Today));
      setValue("assembly", true);
      setValue("bus_id", null);
    }
  }, [editObject]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledInput>
          <div className="input-group">
            <label htmlFor="date">Data rozpoczęcia:</label>
            <Controller
              name="assembly_start"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  minDate={Today}
                  dateFormat="dd-MM-yyyy"
                  selected={field.value}
                />
              )}
            />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label htmlFor="date">Data zakończenia:</label>
            <Controller
              name="assembly_end"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  minDate={Today}
                  dateFormat="dd-MM-yyyy"
                  selected={field.value}
                />
              )}
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Bus:</label>
            <select {...register("bus_id")}>
              <option value="">Wybierz...</option>
              {assemblyBus.map((bus) => {
                return (
                  <option key={bus.id} value={bus.id}>
                    {bus.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.bus_id && (
          <span className="error">{errors.bus_id.message}</span>
        )}

        <input type="hidden" {...register("assembly")} />

        {isEditMode ? (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Zapisz
            </button>
          </div>
        ) : (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Dodaj
            </button>
          </div>
        )}
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .todo-input-group {
      width: 49%;
      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddAssembly;
