import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../components/Modal";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Nazwa jest wymagana")
    .min(3, "Nazwa musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwa nie może być dłuższa niż 255 znaków"),
  email: yup
    .string()
    .required("Email jest wymagany")
    .email("Niepoprawny adres Email")
    .min(5, "Email musi posiadać co najmniej 5 znaków")
    .max(255, "email nie może posiadać więcej niż 255 znaków"),
  password: yup
    .string()
    .required("Hasło jest wymagane")
    .min(5, "Hasło musi zawierać co najmniej 5 znaków")
    .max(255, "Hasło nie nie może być duższe niż 255 znaków"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Podane hasła nie mogą się różnić"),
  phone: yup
    .string()
    .required("Proszę podać numer telefonu")
    .matches(/^[0-9]+$/, "Numer telefonu musi składać się wyłącznie z cyfr 1-9")
    .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków")
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .nullable(true),
  team: yup.string().nullable(),
  offer_form: yup.string().nullable(),
  role: yup.number().typeError("Prosze wybrać role").required(),
});

const AddUser = ({ isModalOpen, handleOnClose, fetchData }) => {
  const { departments, roles, teams, instalationsType } =
    useContext(StoreContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: { name: "" },
  });

  const location = useLocation();
  const history = useHistory();
  const [isSended, setIsSended] = useState(false);

  const resetInputValue = () => {
    reset({
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      position: "",
      phone: "",
      department: "",
      role: "",
      team: "",
      offer_form: null,
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.post("/api/user", { ...data });
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="form-header">
          <button
            onClick={handleOnClose}
            className="btn-form-times"
            type="button"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <StyledInputWrapper>
          <div className="input-group">
            <label>Imię i nazwisko użytkownika</label>
            <input type="text" {...register("name")} />
          </div>
        </StyledInputWrapper>
        {errors?.name && <span className="error">{errors.name.message}</span>}

        <StyledInputWrapper>
          <div className="input-group">
            <label>Email</label>
            <input type="email" {...register("email")} />
          </div>
        </StyledInputWrapper>
        {errors?.email && <span className="error">{errors.email.message}</span>}

        <StyledInputWrapper>
          <div className="input-group">
            <label>Telefon</label>

            <input type="text" {...register("phone")} />
          </div>
        </StyledInputWrapper>
        {errors?.phone && <span className="error">{errors.phone.message}</span>}

        <StyledInputWrapper>
          <div className="input-group">
            <label>Stanowisko</label>

            <input type="text" {...register("position")} />
          </div>
        </StyledInputWrapper>
        {errors?.position && (
          <span className="error">{errors.position.message}</span>
        )}

        <StyledInputWrapper>
          <div className="input-group">
            <label htmlFor="passwordInput">Hasło</label>

            <input type="password" {...register("password")} />
          </div>
        </StyledInputWrapper>
        {errors?.password && (
          <span className="error">{errors.password.message}</span>
        )}

        <StyledInputWrapper>
          <div className="input-group">
            <label htmlFor="password_confirmationInput">Potwierdź hasło</label>

            <input type="password" {...register("password_confirmation")} />
          </div>
        </StyledInputWrapper>
        {errors?.password_confirmation && (
          <span className="error">{errors.password_confirmation.message}</span>
        )}
        <StyledInputWrapper>
          <div className="input-group">
            <label>Oddział:</label>
            <select {...register("department")}>
              <option value="">Wybierz...</option>
              {departments &&
                departments.map((department) => {
                  return (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </StyledInputWrapper>
        {errors?.department && (
          <span className="error">{errors.department.message}</span>
        )}
        <StyledInputWrapper>
          <div className="input-group">
            <label>Forma ofertowania:</label>
            <select {...register("offer_form")}>
              <option value="">Wybierz...</option>
              {instalationsType &&
                instalationsType.map((type) => {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </StyledInputWrapper>
        {errors?.offer_form && (
          <span className="error">{errors.offer_form.message}</span>
        )}
        <StyledInputWrapper>
          <div className="input-group">
            <label>Zespół:</label>
            <select {...register("team")}>
              <option value="">Wybierz...</option>
              {teams.map((team) => {
                return (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInputWrapper>
        {errors?.team && <span className="error">{errors.team.message}</span>}
        <StyledInputWrapper>
          <div className="input-group">
            <label>Rola w Zespole:</label>
            <select {...register("role")}>
              <option hidden value="">
                Wybierz...
              </option>
              {roles.map((role) => {
                return (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInputWrapper>

        {errors?.role && <span className="error">{errors.role.message}</span>}

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Dodaj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  max-width: 360px;
  .form-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .btn-form-times {
    border: none;
    outline: none;
    background: transparent;
    padding: 1rem;
    cursor: pointer;
  }
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #2d96db;
        background: transparent;
      }
      &.close:hover {
        color: black;
      }
      &.add {
        background: #134771;
        color: white;
      }
      &.add:hover {
        background: #005e9c;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInputWrapper = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddUser;
