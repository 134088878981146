import React from "react";
import styled from "styled-components";
import CompanyOpportunity from "./Components/CompanyOpportunity/CompanyOpportunity";

const CompanySideBar = ({ fetchCompany, company }) => {
  return (
    <StyledCompanylist>
      {company?.opportunities && (
        <CompanyOpportunity company={company} fetchCompany={fetchCompany} />
      )}
    </StyledCompanylist>
  );
};

const StyledCompanylist = styled.div`
  grid-area: sidebar;
`;

export default CompanySideBar;
