import moment from "moment";
import React, { useState } from "react";

import styled from "styled-components";
import LeadChangeResponsive from "./LeadChangeResponsive";

const LeadResponsible = ({ lead, fetchData }) => {
  const [isOpenChange, setIsOpenChange] = useState(false);

  const handleOpenChange = () => {
    setIsOpenChange((prev) => !prev);
  };

  return (
    <StyledWrapper>
      <StyledHeaderText>Przypisany handlowiec</StyledHeaderText>
      <StyledItemWrapper>
        <StyledTextWrapper>
          {lead.maintainer ? (
            <StyledUserWrapper>{lead.maintainer.name}</StyledUserWrapper>
          ) : (
            <StyledUserWrapper>Nie przypisano handlowca</StyledUserWrapper>
          )}
        </StyledTextWrapper>
        {lead.maintainer ? (
          <StyledStatusChangeButton onClick={handleOpenChange}>
            {isOpenChange ? "Zamknij" : "Zmień"}
          </StyledStatusChangeButton>
        ) : (
          <StyledStatusChangeButton onClick={handleOpenChange}>
            {isOpenChange ? "Zamknij" : "Przypisz"}
          </StyledStatusChangeButton>
        )}
      </StyledItemWrapper>

      {lead.unassigned_note && lead.unassigned_note.length > 0 && (
        <StyledUnassignedNoteWrapper>
          <StyledUnassignedTitleWrapper>
            Powody rezygnacji
          </StyledUnassignedTitleWrapper>
          {lead.unassigned_note.map((note) => (
            <UnassignedNoteItem key={note.id}>
              <StyledNoteItem>Przez: {note.user.name}</StyledNoteItem>
              <StyledNoteItem>
                Dnia: {moment(note.date).format("DD.MM.YY HH:mm")}
              </StyledNoteItem>
              <StyledNoteItem>Powód: {note.note} </StyledNoteItem>
            </UnassignedNoteItem>
          ))}
        </StyledUnassignedNoteWrapper>
      )}

      <LeadChangeResponsive
        isOpen={isOpenChange}
        setIsOpenChange={setIsOpenChange}
        lead={lead}
        fetchData={fetchData}
      />
    </StyledWrapper>
  );
};

const StyledNoteItem = styled.div``;
const UnassignedNoteItem = styled.div`
  margin: 10px 0;
`;

const StyledUnassignedTitleWrapper = styled.div``;

const StyledUnassignedNoteWrapper = styled.div`
  padding: 10px;
`;

const StyledStatusChangeButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledItemWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

const StyledHeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
`;
const StyledUserWrapper = styled.div``;

const StyledWrapper = styled.div`
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;
`;

export default LeadResponsible;
