import React, { createContext, useState } from "react";

export const LeadContext = createContext(null);

const LeadProvider = ({ children }) => {
  const [teamFilter, setTeamFilter] = useState(null);
  const [departmentFilter, setDepartmentFilter] = useState(null);
  const [complaintFilter, setComplaintFilter] = useState(null);
  const [wrongLeadFilter, setWrongLeadFilter] = useState(1);
  const [contactCreateFilter, setContactCreateFilter] = useState(false);
  const [voivodeshipFilter, setVoivodeshipFilter] = useState(null);
  const [makeContactFilter, setMakeContactFilter] = useState(null);
  const [acquisitionSourceFilter, setAcquisitionSourceFilter] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <LeadContext.Provider
      value={{
        teamFilter,
        setTeamFilter,
        departmentFilter,
        setDepartmentFilter,
        complaintFilter,
        setComplaintFilter,
        wrongLeadFilter,
        setWrongLeadFilter,
        contactCreateFilter,
        setContactCreateFilter,
        voivodeshipFilter,
        setVoivodeshipFilter,
        makeContactFilter,
        setMakeContactFilter,
        acquisitionSourceFilter,
        setAcquisitionSourceFilter,
        userFilter,
        setUserFilter,
        isLoading,
        setIsloading,
        pageCount,
        setPageCount,
        pageNumber,
        setPageNumber,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};

export default LeadProvider;
