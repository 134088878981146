import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import InstallationDesignEdit from "./Components/InstallationDesignEdit/InstallationDesignEdit";
import InstallationDesignModal from "./Components/InstallationDesignModal";
import InstallationDesignShow from "./Components/InstallationDesignShow";

const InstallationDesign = ({ opportunities, fetchData }) => {
  const { user, setOpportunities } = useContext(StoreContext);
  const [isSended, setIsSended] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOnOpenAddDesign = () => {
    setIsModalOpen(true);
  };

  const handleOnOpenShowDesign = () => {
    setIsShowModalOpen(true);
  };
  const handleOnOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleOnCloseAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOnCloseShowModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOnCloseEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Projekt Instalacji</StyledTitle>
      </StyledTitleWrapper>
      {opportunities.project ? (
        <StyledTrueInfo>Dodany</StyledTrueInfo>
      ) : (
        <StyledFalseInfo>Brak</StyledFalseInfo>
      )}
      {opportunities.project === null &&
        (user?.role?.id === 1 || user.role?.id === 3) && (
          <StyledButtonWrapper>
            <StyledButton onClick={handleOnOpenAddDesign} disabled={isSended}>
              Dodaj projekt
            </StyledButton>
          </StyledButtonWrapper>
        )}
      {opportunities.project && (
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnOpenShowDesign} disabled={isSended}>
            Podgląd projektu
          </StyledButton>
        </StyledButtonWrapper>
      )}
      <InstallationDesignModal
        isModalOpen={isModalOpen}
        handleOnClose={handleOnCloseAddModal}
        opportunities={opportunities}
        fetchData={fetchData}
      />
      <InstallationDesignShow
        isModalOpen={isShowModalOpen}
        handleOnClose={handleOnCloseShowModal}
        opportunities={opportunities}
        handleOnOpenEditModal={handleOnOpenEditModal}
      />
      <InstallationDesignEdit
        isModalOpen={isEditModalOpen}
        handleOnClose={handleOnCloseEditModal}
        opportunities={opportunities}
        fetchData={fetchData}
      />
    </StyledWrapper>
  );
};

const StyledFalseInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(244, 118, 49);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledTrueInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(102, 188, 70);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div``;

export default InstallationDesign;
