import { faCircle, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const OfferListItem = ({ offer, setIsShowModalOpen, setOfferObject }) => {
  const [spanObject, setSpanObject] = useState(null);
  const [sendObject, setSendObject] = useState(null);

  const handleOnModalOpen = () => {
    setIsShowModalOpen(true);
    setOfferObject(offer);
  };
  useEffect(() => {
    if (offer) {
      if (
        offer.accepted_management &&
        !offer.rejected_management &&
        offer.accepted_manager &&
        !offer.rejected_manager
      ) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(102, 188, 70` }}>
            <FontAwesomeIcon icon={faCheck} />{" "}
          </StyledSpan>
        );
      } else if (offer.rejected_management || offer.rejected_manager) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(244, 118, 49)` }}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </StyledSpan>
        );
      } else if (
        (!offer.accepted_management && !offer.rejected_management) ||
        (!offer.accepted_manager && !offer.rejected_manager)
      ) {
        setSpanObject(
          <StyledSpan style={{ background: `lightgray` }}>
            <FontAwesomeIcon icon={faCircle} />{" "}
          </StyledSpan>
        );
        if (offer.send_for_approval === 1) {
          setSendObject(
            <StyledSendFontAwesome
              style={{ color: "rgb(102, 188, 70)" }}
              icon={faPaperPlane}
            />
          );
        } else {
          setSendObject(
            <StyledSendFontAwesome
              style={{ color: "rgb(244, 118, 49)" }}
              icon={faPaperPlane}
            />
          );
        }
      }
    }
  }, [offer]);

  return (
    <StyledOfferItem onClick={handleOnModalOpen}>
      <StyledSpanWrapper>{spanObject}</StyledSpanWrapper>
      {offer.offer_number ? (
        <StyledOfferName
          title={` Oferta ${offer?.offer_number}/${moment(
            offer.created_at
          ).format("YYYY")}`}
        >
          Oferta {offer?.offer_number}/{moment(offer.created_at).format("YYYY")}
          {sendObject}
        </StyledOfferName>
      ) : (
        <StyledOfferName
          title={` Oferta z dnia: $moment(offer.created_at).format("DD.MM.YY")}`}
        >
          Oferta z dnia: {moment(offer.created_at).format("DD.MM.YY")}
        </StyledOfferName>
      )}
    </StyledOfferItem>
  );
};

const StyledSendFontAwesome = styled(FontAwesomeIcon)`
  margin-left: 5px;
`;

const StyledOfferName = styled.p``;

const StyledSpan = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

const StyledSpanWrapper = styled.div`
  width: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledOfferItem = styled.button`
  display: flex;
  padding: 5px 10px;
  text-align: left;
  margin-bottom: 4px;
  border: none;
  background: white;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
`;

export default OfferListItem;
