import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";

const AddComments = ({ note, fetchData, setIsSended }) => {
  const location = useLocation();
  const history = useHistory();
  const inputDiv = useRef(null);

  const { user } = useContext(StoreContext);
  const sendData = async (comment) => {
    setIsSended(true);

    try {
      const { status } = await request.post("/api/comments", {
        comment,
        note_id: note.id,
      });
      if (status === 200) {
        fetchData();
        resetInputValue();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const resetInputValue = () => {
    inputDiv.current.innerHTML = "";
  };
  const handleOnSubmitwithClose = (comment) => {
    if (comment.trim()) {
      sendData(comment);
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnSubmitwithClose(e.target.innerHTML);
      inputDiv.current = e.target;
    }
  };

  return (
    <StyledAddNote>
      <StyledAddNoteForm>
        <StyledUserInitialsWrapper>
          <StyledUserInitials>{user.initials}</StyledUserInitials>
        </StyledUserInitialsWrapper>
        <StyledTextInput
          onKeyPress={handleOnKeyPress}
          ref={inputDiv}
          contentEditable
          suppressContentEditableWarning={true}
        ></StyledTextInput>
      </StyledAddNoteForm>
    </StyledAddNote>
  );
};
const StyledUserInitialsWrapper = styled.div`
  width: 42px;
  height: 36px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
const StyledUserInitials = styled.div`
  width: 32px;
  height: 32px;
  background: #cadeee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledTextInput = styled.div`
  width: 100%;
  resize: none;
  font-size: 16px;
  color: #134771;
  padding: 7px 4px;
  overflow-y: hidden;
  border-radius: 10px;
  outline: none;
  min-height: 30px;
  overflow: auto;
  white-space: pre-wrap;
  border: 1px solid #134771;
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;
const StyledAddNoteForm = styled.form`
  display: flex;

  @media screen and (max-width: 1300px) {
    margin-bottom: 10px;
  }
`;

const StyledAddNote = styled.div`
  width: 100%;
  border-radius: 10px;
  background: white;
  position: relative;
`;

export default AddComments;
