import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { YieldCalculatorContext } from "../../../../store/YieldCalculatorProvider";
import zones from "../../../../img/Insolation-zones.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import loading from "../../../../img/48x48.gif";
import PdfFileRenderer from "./Components/PdfFileRenderer";
import { Bar } from "react-chartjs-2";
const SideDegree = [
  { id: 1, degree: 270, name: "Zachód" },
  { id: 2, degree: 240, name: "Południowy zachód" },
  { id: 3, degree: 210, name: "Południowy zachód" },
  { id: 4, degree: 180, name: "Południe" },
  { id: 5, degree: 150, name: "Południowy wschód" },
  { id: 6, degree: 120, name: "Południowy wschód" },
  { id: 7, degree: 90, name: "Wschód" },
];

const YieldDegreeObject = [
  {
    id: 1,
    degree: 15,
    270: 86,
    240: 91,
    210: 95,
    180: 97,
    150: 96,
    120: 92,
    90: 86,
  },
  {
    id: 2,
    degree: 20,
    270: 85,
    240: 93,
    210: 97,
    180: 99,
    150: 97,
    120: 93,
    90: 86,
  },
  {
    id: 3,
    degree: 30,
    270: 83,
    240: 93,
    210: 99,
    180: 100,
    150: 100,
    120: 93,
    90: 84,
  },

  {
    id: 4,
    degree: 40,
    270: 80,
    240: 92,
    210: 99,
    180: 100,
    150: 99,
    120: 92,
    90: 81,
  },

  {
    id: 5,
    degree: 45,
    270: 78,
    240: 90,
    210: 98,
    180: 99,
    150: 98,
    120: 90,
    90: 79,
  },
];

const YieldZoneObject = [
  {
    id: 1,
    color: "#D93B33",
    name: "Strefa I",
    kWh: 1200,
  },
  {
    id: 2,
    color: "#E98235",
    name: "Strefa II",
    kWh: 1100,
  },
  {
    id: 3,
    color: "#EB9F41",
    name: "Strefa III",
    kWh: 1000,
  },
  {
    id: 4,
    color: "#F4C245",
    name: "Strefa IV",
    kWh: 900,
  },
];

const options = {
  plugins: {
    legend: { display: false },
  },
};

const YieldCalculator = () => {
  const {
    installationValue,
    setInstallationValue,
    installationPower,
    setInstallationPower,
    yearElectricityBill,
    setYearElectricityBill,
    increaseElectricityBill,
    setIncreaseElectricityBill,
    serviceLive,
    setServiceLive,
    reliefMyCurrent,
    setReliefMyCurrent,
    roofAngle,
    setRoofAngle,
    sideDegree,
    setSideDegree,
    yieldZone,
    setYieldZone,
    pitValue,
    setPitValue,
    sumOfSubsidies,
    setSumOfSubsidies,
    referenceYield,
    setReferenceYield,
    annualYield,
    setAnnualYield,
    buyRatePerKilowattHour,
    setBuyRatePerKilowattHour,
    sellRatePerMegawattHour,
    setSellRatePerMegawattHour,
    selfConsumption,
    setSelfConsumption,
    storage,
    setStorage,
    investmentAmount,
    setInvestmentAmount,
    saving,
    setSaving,
    otherRelief,
    setOtherRelief,
  } = useContext(YieldCalculatorContext);

  const [instalationValueError, setInstalationValueError] = useState(false);
  const [installationPowerError, setInstallationPowerError] = useState(false);
  const [ratePerKilowattHourError, setRatePrKilowattHourError] =
    useState(false);
  const [pitValueError, setPitValueError] = useState(false);
  const [increaseElectricityBillError, setIncreaseElectricityBillError] =
    useState(false);
  const [isZoneOpen, setIsZoneOpen] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [data, setData] = useState({
    data: {
      labels: [],
      datasets: [{ data: [] }],
    },
    paybackPeriod: 0,
  });

  const location = useLocation();
  const history = useHistory();

  const barRef = useRef(null);

  useEffect(() => {
    if (location.state) {
      setInstallationValue(location.state.price);
      setInstallationPower(location.state.power);
    }
  }, [location]);

  const handleOnChangeInstallationValue = (e) => {
    if (e.target.value < 0) {
      setInstallationValue(0);
    } else {
      setInstallationValue(e.target.value);
    }
  };

  const handleOnChangeIncreaseElectricityBill = (e) => {
    if (e.target.value < 0) {
      setIncreaseElectricityBill(0);
    } else {
      setIncreaseElectricityBill(e.target.value);
    }
  };

  const handleOnChangeServiceLive = (e) => {
    if (e.target.value < 0) {
      setServiceLive(0);
    } else {
      setServiceLive(e.target.value);
    }
  };

  const handleOnChangeReliefMyCurrance = (e) => {
    if (e.target.value < 0) {
      setReliefMyCurrent(0);
    } else {
      setReliefMyCurrent(e.target.value);
    }
  };

  const handleOnChageOtherRelief = (e) => {
    if (e.target.value < 0) {
      setOtherRelief(0);
    } else {
      setOtherRelief(e.target.value);
    }
  };

  const handleOnChangePitValue = (e) => {
    if (e.target.value < 0) {
      setPitValue(0);
    } else {
      setPitValue(e.target.value);
    }
  };

  const handleOnChangeRoofAngle = (e) => {
    setRoofAngle(Number(e.target.value));
  };

  const handleOnChangeSideDegree = (e) => {
    setSideDegree(Number(e.target.value));
  };

  const handleonChangeYieldZone = (e) => {
    setYieldZone(Number(e.target.value));
  };

  const handleOnChangeInstallationPower = (e) => {
    if (e.target.value < 0) {
      setInstallationPower(0);
    } else {
      setInstallationPower(e.target.value);
    }
  };

  const handleOnChangeRatePerKilowatt = (e) => {
    if (e.target.value < 0) {
      setBuyRatePerKilowattHour(0);
    } else {
      setBuyRatePerKilowattHour(e.target.value);
    }
  };

  const handleOnChangeSelfConsumption = (e) => {
    if (e.target.value < 0) {
      setSelfConsumption(0);
      setStorage(100);
    } else if (e.target.value > 100) {
      setSelfConsumption(100);
      setStorage(0);
    } else {
      setSelfConsumption(e.target.value);
      setStorage(100 - Number(e.target.value));
    }
  };

  const handleHoverInsolationZone = () => {
    setIsZoneOpen(true);
  };
  const handleOverInsolationZone = () => {
    setIsZoneOpen(false);
  };
  const handleClickInsolationZone = () => {
    setIsZoneOpen((prev) => !prev);
  };

  useEffect(() => {
    if (Number(installationValue) === 0) {
      setInstalationValueError(true);
    } else {
      setInstalationValueError(false);
    }

    if (Number(installationPower) === 0) {
      setInstallationPowerError(true);
    } else {
      setInstallationPowerError(false);
    }

    if (Number(buyRatePerKilowattHour) === 0) {
      setRatePrKilowattHourError(true);
    } else {
      setRatePrKilowattHourError(false);
    }

    if (Number(pitValue) === 0) {
      setPitValueError(true);
    } else {
      setPitValueError(false);
    }
    if (Number(increaseElectricityBill) === 0) {
      setIncreaseElectricityBillError(true);
    } else {
      setIncreaseElectricityBillError(false);
    }

    const chooseYieldZone = YieldZoneObject.find(
      (zone) => zone.id === yieldZone
    );
    const chooseRoofAngle = YieldDegreeObject.find(
      (angle) => angle.id === roofAngle
    );
    const chooseSideDegree = SideDegree.find((side) => side.id === sideDegree);

    const productionPercentage =
      Number(chooseRoofAngle[chooseSideDegree.degree]) - 8;

    const referenceYieldInstallation =
      Number(chooseYieldZone.kWh) * Number(installationPower);

    setReferenceYield(referenceYieldInstallation);

    const annualYieldInstallation = Math.ceil(
      (Number(productionPercentage) * Number(referenceYieldInstallation)) / 100
    );

    setAnnualYield(annualYieldInstallation);

    // const energeticBill = Number(
    //    * Number(buyRatePerKilowattHour)
    // ).toFixed();

    const selfConsumptionPower =
      (Number(annualYieldInstallation) * Number(selfConsumption)) / 100;

    const selfConsumptionSaving =
      Number(selfConsumptionPower) * Number(buyRatePerKilowattHour);

    const storageSaving =
      ((Number(annualYieldInstallation) - Number(selfConsumptionPower)) *
        sellRatePerMegawattHour) /
      1000;

    const energeticBill = Number(selfConsumptionSaving) + Number(storageSaving);
    setYearElectricityBill(Number(energeticBill).toFixed(2));

    const priceValueFirstYear = Number(energeticBill);

    const yieldTableInstallation = createTableFunction(
      priceValueFirstYear,
      serviceLive,
      increaseElectricityBill
    );

    const thermomodernizationReliefFunction = Math.ceil(
      ((Number(installationValue) - Number(reliefMyCurrent)) *
        Number(pitValue)) /
        100
    );

    const investment =
      Number(installationValue) -
      Number(reliefMyCurrent) -
      Number(otherRelief) -
      Number(thermomodernizationReliefFunction);
    setInvestmentAmount(investment);

    const barDatasets = createBarDataFunction(
      priceValueFirstYear,
      serviceLive,
      increaseElectricityBill,
      investment
    );
    setData(barDatasets);

    setSumOfSubsidies(
      Number(reliefMyCurrent) +
        Number(thermomodernizationReliefFunction) +
        Number(otherRelief)
    );
    if (yieldTableInstallation.length > 0) {
      setSaving(
        Number(
          yieldTableInstallation[yieldTableInstallation.length - 1].sume -
            investment
        ).toFixed()
      );
    } else {
      setSaving(0);
    }
  }, [
    installationValue,
    installationPower,
    yearElectricityBill,
    increaseElectricityBill,
    serviceLive,
    reliefMyCurrent,
    pitValue,
    sumOfSubsidies,
    referenceYield,
    sideDegree,
    roofAngle,
    yieldZone,
    annualYield,
    buyRatePerKilowattHour,
    selfConsumption,
    storage,
    investmentAmount,
    saving,
    otherRelief,
  ]);

  const createTableFunction = (
    priceValueFirstYear,
    serviceLive,
    increaseElectricityBill
  ) => {
    const functionTable = [];
    let functionPrice = priceValueFirstYear;
    let sume = priceValueFirstYear;
    const risePercentage = 1 + Number(increaseElectricityBill) / 100;

    for (let i = 0; i < serviceLive; i++) {
      if (i > 0 && increaseElectricityBill > 0) {
        functionPrice = Number(functionPrice) * Number(risePercentage);
        sume += Number(functionPrice);
      }
      functionTable.push({
        id: i,
        year: i + 1,
        year_price: functionPrice,
        sume: sume,
      });
    }

    return functionTable;
  };

  const createBarDataFunction = (
    priceValueFirstYear,
    serviceLive,
    increaseElectricityBill,
    investmentAmount
  ) => {
    const labelTable = [];
    const functionTable = [];
    let functionPrice = priceValueFirstYear;
    let sume = -investmentAmount + priceValueFirstYear;
    const risePercentage = 1 + Number(increaseElectricityBill) / 100;
    let payback = 0;
    let paybackSume = 0;
    let paybackPrice = 0;

    for (let i = 0; i < serviceLive; i++) {
      if (i > 0 && increaseElectricityBill > 0) {
        functionPrice = Number(functionPrice) * Number(risePercentage);
        sume += Number(functionPrice);
        if (sume < 0) {
          payback += 1;
          paybackSume = sume;
          paybackPrice = Number(functionPrice) * Number(risePercentage);
        }
      }
      labelTable.push(i + 1);
      functionTable.push(Math.ceil(sume));
    }
    let month = 0;
    if (functionPrice && paybackSume < 0) {
      month = Math.ceil(Number((paybackSume *= -1)) / (paybackPrice / 12));
    }

    let sendPaybackYear = "0 lat";
    let sendPaybackMonth = "0 miesięcy";
    if (payback === 0) {
      sendPaybackYear = `0 lat`;
    } else if (payback === 1) {
      sendPaybackYear = `1 rok`;
    } else if (payback < 5) {
      sendPaybackYear = `${payback} lata`;
    } else {
      sendPaybackYear = `${payback} lat`;
    }
    if (month === 0) {
      sendPaybackMonth = "0 miesięcy";
    } else if (month <= 1) {
      sendPaybackMonth = `${month} miesiąc`;
    } else if (month < 5) {
      sendPaybackMonth = `${month} miesiące`;
    } else {
      sendPaybackMonth = `${month} miesięcy`;
    }

    const sendPayback = `${sendPaybackYear} ${sendPaybackMonth}`;

    return {
      data: {
        labels: labelTable,
        datasets: [
          {
            xAxisID: "PLN",
            yAxisID: "lata",
            data: functionTable,
            backgroundColor: ["#134771"],
            borderColor: ["#134771"],
          },
        ],
      },
      paybackPeriod: payback,
      paybackPeriodName: sendPayback,
    };
  };

  const handleOnRelocate = () => {
    if (location?.state?.from) {
      history.push({
        pathname: location.state.from.pathname,
      });
    }
  };

  return (
    <StyledWrapper>
      <StyledSecondWrapper>
        <StyledNameWrapper>
          <StyledName>Kalkulator Rentowności</StyledName>
        </StyledNameWrapper>
        <StyledCalculatorWrapper>
          <StyledTitleWrapper>
            <StyledTitle>Kalkulacja Uzysków</StyledTitle>
          </StyledTitleWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={
                installationPowerError
                  ? { color: "rgb(244, 118, 49)" }
                  : { color: "#134771" }
              }
            >
              Moc instalacji PV (kWp):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={installationPower}
              onChange={handleOnChangeInstallationPower}
              style={
                installationPowerError
                  ? {
                      borderColor: "rgb(244, 118, 49)",
                      color: "rgb(244, 118, 49)",
                    }
                  : { borderColor: "#134771", color: "#134771" }
              }
            />
          </StyledNumberInputWrapper>

          <StyledSelectWrapper>
            <StyledSelectLabel>
              Strefa nasłonecznienia:
              <FontAwesomeIcon
                icon={faInfoCircle}
                onMouseOver={handleHoverInsolationZone}
                onMouseLeave={handleOverInsolationZone}
                onClick={handleClickInsolationZone}
              />
            </StyledSelectLabel>
            <StyledSelect value={yieldZone} onChange={handleonChangeYieldZone}>
              {YieldZoneObject &&
                YieldZoneObject.map((zone) => (
                  <option key={zone.id} value={zone.id}>
                    {zone.kWh} kWh/kWp
                  </option>
                ))}
            </StyledSelect>
            <StyledInsolationZonesWrapper isOpen={isZoneOpen}>
              <StyledInsolationZenesTitle>
                Strefy nasłonecznienia
              </StyledInsolationZenesTitle>
              <StyledInsolationZonesImage
                src={zones}
                alt="strefy nasłonecznienia"
              />
            </StyledInsolationZonesWrapper>
          </StyledSelectWrapper>

          <StyledSelectWrapper>
            <StyledSelectLabel>Kąt nachylenia generatora:</StyledSelectLabel>
            <StyledSelect value={roofAngle} onChange={handleOnChangeRoofAngle}>
              {YieldDegreeObject &&
                YieldDegreeObject.map((degree) => (
                  <option value={degree.id} key={degree.id}>
                    {degree.degree}&deg;
                  </option>
                ))}
            </StyledSelect>
          </StyledSelectWrapper>

          <StyledSelectWrapper>
            <StyledSelectLabel>Orientacja generatora(%):</StyledSelectLabel>
            <StyledSelect
              value={sideDegree}
              onChange={handleOnChangeSideDegree}
            >
              {SideDegree &&
                SideDegree.map((side) => (
                  <option value={side.id} key={side.id}>
                    {side.degree}&deg; - {side.name}
                  </option>
                ))}
            </StyledSelect>
          </StyledSelectWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>
              Prognozowana produkcja energii (kWh/rok):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="text"
              value={annualYield + " kWh/rok"}
              readOnly
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>
              Prognozowana autokonsumpcja energii (%):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={selfConsumption}
              onChange={handleOnChangeSelfConsumption}
            />
          </StyledNumberInputWrapper>

          <StyledTitleWrapper>
            <StyledTitle>Kalkulacja Oszczędności</StyledTitle>
          </StyledTitleWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={
                instalationValueError
                  ? { color: "rgb(244, 118, 49)" }
                  : { color: "#134771" }
              }
            >
              Koszt zakupu instalacji:
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={installationValue}
              onChange={handleOnChangeInstallationValue}
              style={
                instalationValueError
                  ? {
                      borderColor: "rgb(244, 118, 49)",
                      color: "rgb(244, 118, 49)",
                    }
                  : { borderColor: "#134771", color: "#134771" }
              }
            />
          </StyledNumberInputWrapper>

          <StyledReliefWrapper>
            <StyledInsertTitleWrapper>
              <StyledInsertTitle>Ulgi/Dotacje:</StyledInsertTitle>
            </StyledInsertTitleWrapper>
            <StyledNumberInputWrapper>
              <StyledNumberInputLabel
                style={
                  pitValueError
                    ? { color: "rgb(244, 118, 49)" }
                    : { color: "#134771" }
                }
              >
                Ulga termomodernizacyjna PIT (%):
              </StyledNumberInputLabel>
              <StyledNumberInput
                type="number"
                value={pitValue}
                onChange={handleOnChangePitValue}
                style={
                  pitValueError
                    ? {
                        borderColor: "rgb(244, 118, 49)",
                        color: "rgb(244, 118, 49)",
                      }
                    : { borderColor: "#134771", color: "#134771" }
                }
              />
            </StyledNumberInputWrapper>

            <StyledNumberInputWrapper>
              <StyledNumberInputLabel>
                Program mój prąd (zł):
              </StyledNumberInputLabel>
              <StyledNumberInput
                type="number"
                value={reliefMyCurrent}
                onChange={handleOnChangeReliefMyCurrance}
              />
            </StyledNumberInputWrapper>

            <StyledNumberInputWrapper>
              <StyledNumberInputLabel>
                Inne programy wsparcia (zł):
              </StyledNumberInputLabel>
              <StyledNumberInput
                type="number"
                value={otherRelief}
                onChange={handleOnChageOtherRelief}
              />
            </StyledNumberInputWrapper>

            <StyledNumberInputWrapper>
              <StyledNumberInputLabel>Suma dotacji:</StyledNumberInputLabel>
              <StyledNumberInput
                type="text"
                value={sumOfSubsidies + " zł"}
                readOnly
              />
            </StyledNumberInputWrapper>
          </StyledReliefWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>
              Wysokość inwestycji:
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="text"
              value={investmentAmount + " zł"}
              readOnly
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={
                ratePerKilowattHourError
                  ? { color: "rgb(244, 118, 49)" }
                  : { color: "#134771" }
              }
            >
              Zakup energii czynnej + koszt dystrybucji (zł):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={buyRatePerKilowattHour}
              step="0.01"
              onChange={handleOnChangeRatePerKilowatt}
              style={
                ratePerKilowattHourError
                  ? {
                      borderColor: "rgb(244, 118, 49)",
                      color: "rgb(244, 118, 49)",
                    }
                  : { borderColor: "#134771", color: "#134771" }
              }
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>
              Oszczędność - autokonsumpcja + depozyt prosumencki:
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="text"
              value={yearElectricityBill + " zł"}
              readOnly
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={
                increaseElectricityBillError
                  ? { color: "rgb(244, 118, 49)" }
                  : { color: "#134771" }
              }
            >
              Prognozowany wzrost cen prądu rocznie(%):
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={increaseElectricityBill}
              onChange={handleOnChangeIncreaseElectricityBill}
              style={
                increaseElectricityBillError
                  ? {
                      borderColor: "rgb(244, 118, 49)",
                      color: "rgb(244, 118, 49)",
                    }
                  : { borderColor: "#134771", color: "#134771" }
              }
            />
          </StyledNumberInputWrapper>
          <StyledNumberInputWrapper>
            <StyledNumberInputLabel>Okres eksploatacji:</StyledNumberInputLabel>
            <StyledNumberInput
              type="number"
              value={serviceLive}
              onChange={handleOnChangeServiceLive}
            />
          </StyledNumberInputWrapper>

          <StyledNumberInputWrapper>
            <StyledNumberInputLabel
              style={{ color: "rgb(102, 188, 70)", fontWeight: "bold" }}
            >
              Prognozowane oszczędności przez {serviceLive} lat:
            </StyledNumberInputLabel>
            <StyledNumberInput
              type="text"
              value={saving + " zł"}
              readOnly
              style={{
                color: "rgb(102, 188, 70)",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            />
          </StyledNumberInputWrapper>

          {data.paybackPeriod > 0 && (
            <StyledNumberInputWrapper>
              <StyledNumberInputLabel
                style={{ color: "rgb(102, 188, 70)", fontWeight: "bold" }}
              >
                Prognozowane zwrot z inwestycji:
              </StyledNumberInputLabel>
              <StyledNumberInput
                type="text"
                value={data.paybackPeriodName}
                readOnly
                style={{
                  color: "rgb(102, 188, 70)",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              />
            </StyledNumberInputWrapper>
          )}

          {buyRatePerKilowattHour > 0 && (
            <StyledSeenBarWeapper>
              <Bar data={data.data} options={options} />
            </StyledSeenBarWeapper>
          )}
        </StyledCalculatorWrapper>

        <StyledButtonWrapper>
          {location?.state?.from?.pathname && (
            <StyledButtonRelocate onClick={handleOnRelocate}>
              Wróć
            </StyledButtonRelocate>
          )}
          <PdfFileRenderer
            barRef={barRef}
            paybackPeriodName={data.paybackPeriodName}
            pitValue={pitValue}
            investmentAmount={investmentAmount}
            buyRatePerKilowattHour={buyRatePerKilowattHour}
            sellRatePerMegawattHour={sellRatePerMegawattHour}
            selfConsumption={selfConsumption}
            increaseElectricityBill={increaseElectricityBill}
            setIsSended={setIsSended}
            serviceLive={serviceLive}
            saving={saving}
          />
        </StyledButtonWrapper>

        {isSended && (
          <StyledSendNoteWrapper>
            <StyledSendNoteInnerWrapper>
              <StyledLoadingImage src={loading} alt="loading" />
            </StyledSendNoteInnerWrapper>
          </StyledSendNoteWrapper>
        )}
      </StyledSecondWrapper>

      {buyRatePerKilowattHour > 0 && (
        <StyledBarSecondWrapper>
          <StyledBarWrapper>
            <Bar data={data.data} options={options} ref={barRef} />
          </StyledBarWrapper>
        </StyledBarSecondWrapper>
      )}
    </StyledWrapper>
  );
};

const StyledSeenBarWeapper = styled.div`
  padding: 10px 0;
`;

const StyledBarSecondWrapper = styled.div`
  overflow: hidden;
  max-width: 400px;
`;

const StyledBarWrapper = styled.div`
  width: 1029px;
  height: 0;
  overflow: hidden;
`;
const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledButtonRelocate = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledInsolationZonesWrapper = styled.div`
  display: ${(prev) => (prev.isOpen ? "block" : "none")};

  position: absolute;
  z-index: 2;
  padding: 10px;
  background: white;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;
const StyledInsolationZenesTitle = styled.p`
  padding: 5px;
  text-align: center;
  color: #134771;
  font-weight: bold;
  font-size: 16px;
`;
const StyledInsolationZonesImage = styled.img`
  max-width: 360px;
`;

const StyledReliefWrapper = styled.div`
  padding: 20px 0 0 10px;
`;

const StyledInsertTitle = styled.p`
  color: #134771;
  font-size: 14px;
  font-weight: bold;
`;

const StyledInsertTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const StyledTitle = styled.p`
  color: #134771;
  font-size: 16px;
  font-weight: bold;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSelect = styled.select`
  width: 100%;
  color: #134771;
  border: 1px solid #134771;
  border-radius: 4px;
`;

const StyledSelectLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
  color: #134771;
`;

const StyledSelectWrapper = styled.div`
  margin-top: 25px;
  position: relative;
`;

const StyledNumberInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #134771;
  color: #134771;
`;

const StyledNumberInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
  color: #134771;
`;

const StyledNumberInputWrapper = styled.div`
  margin-top: 25px;
  position: relative;
`;

const StyledCalculatorWrapper = styled.div``;

const StyledButtonWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const StyledName = styled.p`
  color: #134771;
  font-size: 16px;
`;

const StyledNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const StyledSecondWrapper = styled.div`
  background: white;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  min-width: 400px;
`;

const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export default YieldCalculator;
