import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import request from "../helpers/request";
import requestMulti from "../helpers/requestMulti";
import { useHistory, useLocation } from "react-router";
import { StoreContext } from "../store/StoreProvider";
const SecureRoute = ({ component: Component, ...rest }) => {
  const cookie = new Cookies();
  const location = useLocation();
  const isLogin = localStorage.getItem("isLogged");

  if (isLogin) {
    request.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "access-token"
    )}`;
    requestMulti.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${cookie.get("access-token")}`;
  }

  if (isLogin) {
    return (
      <Route
        {...rest}
        render={(props) => {
          <Component {...props} />;
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }
};

export default SecureRoute;
