import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import request from "../../helpers/request";

import styled from "styled-components";
import OpportunitiesAside from "./OpportunitiesAside/OpportunitiesAside";
import OpportunitiesList from "./OpportunitiesList/OpportunitiesList";
import AddHPOpportunities from "./AddOpportunities/AddHPOpportunities";
import AddPVOpportunities from "./AddOpportunities/AddPVOpportunities";
import ChooseAddModal from "./AddOpportunities/ChooseAddModal";
import moment from "moment";
import { useContext } from "react";
import { OpportunityFilterContext } from "../../store/OpportunityFilterProvider";

const Opportunities = ({ opportunityStatuses }) => {
  const [opportunities, setOpportunities] = useState([]);

  const {
    showFilter,
    setShowFilter,
    userFilter,
    setUserFilter,
    statusFilter,
    setStatusFilter,
    statusFilterFunction,
    setStatusFilterFunction,
    departmentFilter,
    setDepartmentFilter,
    typeFilter,
    setTypeFilter,
    paymentFilter,
    setPaymentFilter,
    completeFilter,
    setCompleteFilter,
    searchStartDate,
    setSearchStartDate,
    searchEndDate,
    setSearchEndDate,
    dateFilter,
    setDateFilter,
    completeFilterFunction,
    setCompleteFilterFunction,
    orderProductFilter,
    setOrderProductFilter,
    searchValue,
    setSearchValue,
    teamFilter,
    setTeamFilter,
    osdFilter,
    setOsdFilter,
  } = useContext(OpportunityFilterContext);
  const [isHPModalOpen, setIsHPModalOpen] = useState(false);
  const [isPVModalOpen, setIsPVModalOpen] = useState(false);
  const [isChooseModalOpen, setIsChooseModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (completeFilterFunction === 1) {
      setStatusFilter(2);
      setCompleteFilter(1);
    } else if (completeFilterFunction === 2) {
      setStatusFilter(2);
      setCompleteFilter(2);
    } else {
      setCompleteFilter(null);
    }
  }, [completeFilterFunction]);

  useEffect(() => {
    if (statusFilterFunction === 1) {
      setStatusFilter(1);
      setCompleteFilter(null);
    } else if (statusFilterFunction === 2) {
      setStatusFilter(2);
    } else if (statusFilterFunction === 3) {
      setStatusFilter(3);
      setCompleteFilter(null);
    } else {
      setStatusFilter(null);
      setCompleteFilter(null);
    }
  }, [statusFilterFunction]);

  const fetchOpportunity = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(
        `/api/opportunities?page=${number}`,
        {
          params: {
            status: statusFilter,
            complete: completeFilter,
            department: departmentFilter,
            payment: paymentFilter,
            order_product: orderProductFilter,
            search: searchValue,
            start_date: searchStartDate,
            end_date: searchEndDate,
            type: typeFilter,
            user: userFilter,
            team: teamFilter,
            osd: osdFilter,
          },
        }
      );
      if (status === 200) {
        setOpportunities(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (dateFilter) {
      if (dateFilter === 1) {
        setSearchStartDate(moment().startOf("week").toDate());
        setSearchEndDate(moment().endOf("week").toDate());
      } else if (dateFilter === 2) {
        setSearchStartDate(moment().startOf("month").toDate());
        setSearchEndDate(moment().endOf("month").toDate());
      } else if (dateFilter === 3) {
        setSearchStartDate(
          moment().subtract(1, "month").startOf("month").toDate()
        );
        setSearchEndDate(moment().subtract(1, "month").endOf("month").toDate());
      } else if (dateFilter === 4) {
        setSearchStartDate(moment().subtract(7, "day").toDate());
        setSearchEndDate(moment().toDate());
      } else if (dateFilter === 5) {
        setSearchStartDate(moment().subtract(30, "day").toDate());
        setSearchEndDate(moment().toDate());
      }
    } else {
      setSearchStartDate(null);
      setSearchEndDate(null);
    }
  }, [dateFilter]);

  useEffect(() => {
    if (opportunities.length === 0) {
      fetchOpportunity(1);
    }
  }, []);

  const handleOnChooseHP = () => {
    setIsChooseModalOpen(false);
    setIsHPModalOpen(true);
  };
  const handleOnChoosePV = () => {
    setIsChooseModalOpen(false);
    setIsPVModalOpen(true);
  };

  const handleOnCloseHP = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsHPModalOpen(false);
  };
  const handleOnClosePV = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsPVModalOpen(false);
  };
  const handleOnClick = () => {
    setIsChooseModalOpen(true);
  };

  const handleOnCloseChooseModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsChooseModalOpen(false);
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Szanse sprzedaży</StyledTitle>
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnClick} title="Dodaj szansę sprzedaży">
            <FontAwesomeIcon icon={faPlus} />{" "}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledTitleWrapper>
      <ContentWrapper>
        <OpportunitiesAside
          setStatusFilter={setStatusFilterFunction}
          setShowFilter={setShowFilter}
          setDepartmentFilter={setDepartmentFilter}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          opportunityStatuses={opportunityStatuses}
          showFilter={showFilter}
          statusFilter={statusFilter}
          departmentFilter={departmentFilter}
          paymentFilter={paymentFilter}
          setPaymentFilter={setPaymentFilter}
          orderProductFilter={orderProductFilter}
          setOrderProductFilter={setOrderProductFilter}
          completeFilter={completeFilter}
          setCompleteFilter={setCompleteFilterFunction}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          teamFilter={teamFilter}
          setTeamFilter={setTeamFilter}
          osdFilter={osdFilter}
          setOsdFilter={setOsdFilter}
        />
        <OpportunitiesList
          opportunityStatuses={opportunityStatuses}
          showFilter={showFilter}
          statusFilter={statusFilter}
          departmentFilter={departmentFilter}
          paymentFilter={paymentFilter}
          orderProductFilter={orderProductFilter}
          completeFilter={completeFilter}
          typeFilter={typeFilter}
          userFilter={userFilter}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setSearchInput={setSearchInput}
          setPageNumber={setPageNumber}
          fetchOpportunity={fetchOpportunity}
          isLoading={isLoading}
          searchInput={searchInput}
          opportunities={opportunities}
          pageNumber={pageNumber}
          pageCount={pageCount}
          searchStartDate={searchStartDate}
          setSearchStartDate={setSearchStartDate}
          searchEndDate={searchEndDate}
          setSearchEndDate={setSearchEndDate}
          setDateFilter={setDateFilter}
          teamFilter={teamFilter}
          osdFilter={osdFilter}
        />
      </ContentWrapper>

      <ChooseAddModal
        isChooseModalOpen={isChooseModalOpen}
        handleOnChooseHP={handleOnChooseHP}
        handleOnChoosePV={handleOnChoosePV}
        handleOnClose={handleOnCloseChooseModal}
      />

      <AddHPOpportunities
        isModalOpen={isHPModalOpen}
        setIsModalOpen={setIsHPModalOpen}
        handleOnClose={handleOnCloseHP}
        fetchData={fetchOpportunity}
      />
      <AddPVOpportunities
        isModalOpen={isPVModalOpen}
        setIsModalOpen={setIsPVModalOpen}
        handleOnClose={handleOnClosePV}
        fetchData={fetchOpportunity}
      />
    </StyledWrapper>
  );
};

const StyledButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: transparent;
  color: black;
  font-size: 28px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  &:hover {
    color: rgb(244, 118, 49);
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  min-width: 0;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export default Opportunities;
