import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import pdfImage from "../../../../img/pdf-download-2617.svg";
import fileImage from "../../../../img/file.svg";
import request from "../../../../helpers/request";

const UserItemFile = ({ file, setIsSended }) => {
  const history = useHistory();
  const location = useLocation();

  const handleOnDownload = async (id, name) => {
    setIsSended(true);
    try {
      const { status, data } = await request.get(`api/file/download/${id}`, {
        responseType: "blob",
      });
      if (status === 200) {
        const href = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", name); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  return (
    <>
      {file.mime_type === "application/pdf" ? (
        <StyledFileContainer
          onClick={() => handleOnDownload(file.id, file.name)}
        >
          <StyledImage src={pdfImage} />
          <StyledFileNameWrapper>
            <StyledPName title={file.name}>{file.name}</StyledPName>
            <StyledFileDescriptionWrapper>
              <StyledPDescription>PDF</StyledPDescription>
              <StyledPDescription>
                {file.size
                  ? `${(Number(file.size) / 1048576).toFixed(2)} MB`
                  : ""}
              </StyledPDescription>
            </StyledFileDescriptionWrapper>
          </StyledFileNameWrapper>
        </StyledFileContainer>
      ) : (
        <StyledFileContainer
          onClick={() => handleOnDownload(file.id, file.name)}
        >
          <StyledImage src={fileImage} />
          <StyledFileNameWrapper>
            <StyledPName title={file.name}>{file.name}</StyledPName>
            <StyledFileDescriptionWrapper>
              <StyledPDescription>PDF</StyledPDescription>
              <StyledPDescription>
                {file.size
                  ? `${(Number(file.size) / 1048576).toFixed(2)} MB`
                  : ""}
              </StyledPDescription>
            </StyledFileDescriptionWrapper>
          </StyledFileNameWrapper>
        </StyledFileContainer>
      )}
    </>
  );
};

const StyledImage = styled.img`
  width: 35px;
  margin-top: 10px;
  align-self: flex-start;
`;
const StyledPDescription = styled.p`
  margin-top: 5px;
  margin-right: 5px;
  font-size: 14px;
`;
const StyledPName = styled.p`
  margin-top: 5px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledFileDescriptionWrapper = styled.div`
  display: flex;
`;
const StyledFileNameWrapper = styled.div`
  width: calc(100% - 40px);
  p {
    color: #777;
  }
`;

const StyledFileContainer = styled.div`
  margin-top: 10px;
  display: flex;
  /* overflow: hidden; */
  width: 25%;
  cursor: pointer;

  svg {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 60px;
  }
`;
export default UserItemFile;
