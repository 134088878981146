import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Modal from "../../../../../../../../../components/Modal";
const ShowOwnerDetails = ({
  ownerDetails,
  isModalOpen,
  handleOnClose,
  handleOnEdit,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledWrapper>
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledSectionTitle>Dane własciciela</StyledSectionTitle>
        <StyledItemWrapper>
          <StyledItemTitle>Imię i nazwisko / nazwa firmy </StyledItemTitle>
          <StyledItemValue>{ownerDetails?.investor_name_1}</StyledItemValue>
        </StyledItemWrapper>
        {ownerDetails?.investor_id_card_1 && (
          <StyledItemWrapper>
            <StyledItemTitle>Seria i numer dowodu:</StyledItemTitle>
            <StyledItemValue>
              {ownerDetails?.investor_id_card_1}
            </StyledItemValue>
          </StyledItemWrapper>
        )}
        {ownerDetails?.investor_NIP_1 && (
          <StyledItemWrapper>
            <StyledItemTitle>NIP:</StyledItemTitle>
            <StyledItemValue>{ownerDetails?.investor_NIP_1}</StyledItemValue>
          </StyledItemWrapper>
        )}
        {ownerDetails?.investor_krs && (
          <StyledItemWrapper>
            <StyledItemTitle>Numer KRS:</StyledItemTitle>
            <StyledItemValue>{ownerDetails?.investor_krs}</StyledItemValue>
          </StyledItemWrapper>
        )}
        {ownerDetails?.investor_regon && (
          <StyledItemWrapper>
            <StyledItemTitle>REGON:</StyledItemTitle>
            <StyledItemValue>{ownerDetails?.investor_regon}</StyledItemValue>
          </StyledItemWrapper>
        )}
        {ownerDetails?.investor_pesel_1 && (
          <StyledItemWrapper>
            <StyledItemTitle>Pesel:</StyledItemTitle>
            <StyledItemValue>{ownerDetails?.investor_pesel_1}</StyledItemValue>
          </StyledItemWrapper>
        )}

        <StyledItemWrapper>
          <StyledItemTitle>Email:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.investor_email_1}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Telefon:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.investor_phone_1}</StyledItemValue>
        </StyledItemWrapper>

        <StyledSectionTitle>Adres właściciela</StyledSectionTitle>
        <StyledItemWrapper>
          <StyledItemTitle>Ulica:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.street}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Numer budynku:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.building_number}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Kod-pocztowy:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.post_code}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Miejscowość:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.city}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Poczta:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.post}</StyledItemValue>
        </StyledItemWrapper>

        <StyledSectionTitle>Dane współwłasciciela</StyledSectionTitle>
        <StyledItemWrapper>
          <StyledItemTitle>Imię i nazwisko </StyledItemTitle>
          <StyledItemValue>{ownerDetails?.investor_name_2}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Seria i numer dowodu:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.investor_id_card_2}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Pesel:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.investor_pesel_2}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Telefon:</StyledItemTitle>
          <StyledItemValue>{ownerDetails?.investor_phone_2}</StyledItemValue>
        </StyledItemWrapper>
      </StyledWrapper>
      <StyledButtonWrapper>
        <StyledEditButton onClick={handleOnEdit}>Edytuj</StyledEditButton>
      </StyledButtonWrapper>
    </Modal>
  );
};
const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  padding: 10px 0;

  align-items: center;
  justify-content: flex-start;
`;

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0;
`;

const StyledItemTitle = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;
const StyledItemValue = styled.div``;
const StyledWrapper = styled.div``;
const StyledItemWrapper = styled.div`
  padding: 5px 0;
  display: flex;
`;

export default ShowOwnerDetails;
