import React from "react";
import Modal from "../../../../../../../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ProductOrderModal = ({
  isModalOpen,
  handleOnClose,
  handleOnOpenCommissionedOrderModal,
  handleOnOpenProductOrderAddModal,
  handleOnOpenProductOrderShowModal,
  handleOnOpenGoodsShippedModal,
  opportunities,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledWrapper>
        <StyledButtonsWrapper>
          {opportunities.product_order &&
          opportunities.product_order.commissioned_order === 1 ? (
            <StyledAddButton disabled={true}>
              Zamówienie zlecone
            </StyledAddButton>
          ) : (
            <StyledAddButton onClick={handleOnOpenCommissionedOrderModal}>
              Zamówienie zlecono
            </StyledAddButton>
          )}

          {opportunities.product_order &&
          opportunities.product_order.is_order === 1 ? (
            <StyledAddButton onClick={handleOnOpenProductOrderShowModal}>
              Pokaż WZ
            </StyledAddButton>
          ) : (
            <StyledAddButton onClick={handleOnOpenProductOrderAddModal}>
              Dodaj WZ
            </StyledAddButton>
          )}
          {opportunities.product_order &&
          opportunities.product_order.goods_shipped === 1 ? (
            <StyledAddButton disabled={true}>Towar wysłany</StyledAddButton>
          ) : (
            <StyledAddButton onClick={handleOnOpenGoodsShippedModal}>
              Towar wysłany
            </StyledAddButton>
          )}
        </StyledButtonsWrapper>
      </StyledWrapper>
    </Modal>
  );
};

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledAddButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  min-width: 280px;
  &:hover {
    background: #053257;
  }
  &:disabled {
    cursor: inherit;
    background: #cadeee;
    color: #134771;
  }
`;
const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledWrapper = styled.div`
  max-width: 600px;
  min-width: 300px;
`;

export default ProductOrderModal;
