import React, { useState } from "react";
import request from "../../helpers/request";

import styled from "styled-components";

import AddToDoItem from "./AddToDoItem/AddToDoItem";
import TodoAside from "./ToDoAside/TodoAside";
import TodoList from "./ToDoList/TodoList";
import { useLocation, useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { StoreContext } from "../../store/StoreProvider";

const ToDo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const [taskTypeFilter, setTaskTypeFilter] = useState(null);
  const [yourTaskFilter, setYourTaskFilter] = useState(null);
  const [checkDoneFilter, setCheckDoneFilter] = useState(false);
  const [ToDoList, setToDoList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const { user } = useContext(StoreContext);
  const [userFilter, setUserFilter] = useState(user?.id);
  const location = useLocation();
  const history = useHistory();
  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setEditMode(false);
  };
  const handleOnClick = () => {
    setIsModalOpen(true);
    setEditObject(null);
  };

  const handleOnClickEdit = () => {
    setEditMode(true);
    setIsModalOpen(true);
  };

  const fetchToDo = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(`/api/todo?page=${number}`, {
        params: {
          user: userFilter,
          is_done: checkDoneFilter,
          type: taskTypeFilter,
          date: yourTaskFilter,
        },
      });
      if (status === 200) {
        setToDoList(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Zadania</StyledTitle>
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnClick} title="Dodaj zadanie">
            <FontAwesomeIcon icon={faPlus} />{" "}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledTitleWrapper>
      <StyledTodowrapper>
        <TodoAside
          taskTypeFilter={taskTypeFilter}
          setTaskTypeFilter={setTaskTypeFilter}
          yourTaskFilter={yourTaskFilter}
          setYourTaskFilter={setYourTaskFilter}
          setUserFilter={setUserFilter}
          userFilter={userFilter}
          checkDoneFilter={checkDoneFilter}
          setCheckDoneFilter={setCheckDoneFilter}
        />
        <TodoList
          handleOnClickEdit={handleOnClickEdit}
          setEditObject={setEditObject}
          taskTypeFilter={taskTypeFilter}
          yourTaskFilter={yourTaskFilter}
          userFilter={userFilter}
          checkDoneFilter={checkDoneFilter}
          fetchToDo={fetchToDo}
          isLoading={isLoading}
          setPageNumber={setPageNumber}
          ToDoList={ToDoList}
          pageNumber={pageNumber}
          pageCount={pageCount}
        />
      </StyledTodowrapper>
      <AddToDoItem
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={() => fetchToDo(1)}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledWrapper>
  );
};

const StyledButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: transparent;
  color: black;
  font-size: 28px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  &:hover {
    color: rgb(244, 118, 49);
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const StyledTodowrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
`;
export default ToDo;
