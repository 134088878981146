import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import request from "../../../../../helpers/request";
import styled from "styled-components";
import OfferApproveItemDetails from "./HeatPumpApproveItemDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import ChangeDiscount from "./ChangeDiscount";

const HeatPumpApproveItem = ({ offer, fetchData, setPageNumber }) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [spanObject, setSpanObject] = useState(null);
  const [isSended, setIsSended] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOnOpen = () => {
    setIsShowModalOpen(true);
  };

  const sendAccept = async () => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/heat-pump-offer-accepted/${offer.id}`,
        {
          accepted: true,
        }
      );

      if (status === 200) {
        fetchData(1);
        setPageNumber(1);
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const sendReject = async () => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/heat-pump-offer-rejected/${offer.id}`,
        {
          rejected: true,
        }
      );

      if (status === 200) {
        fetchData();
        setPageNumber(1);
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleAccept = () => {
    sendAccept();
  };

  const handleReject = () => {
    sendReject();
  };

  const handleChangeAccept = () => {
    setIsChangeModalOpen(true);
    setIsShowModalOpen(false);
  };

  const handleOnChangeModalClose = () => {
    setIsChangeModalOpen(false);
    setIsShowModalOpen(true);
  };

  useEffect(() => {
    if (offer) {
      if (
        offer.accepted_management &&
        offer.accepted_manager &&
        !offer.rejected_management &&
        !offer.rejected_manager
      ) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(102, 188, 70` }}>
            <FontAwesomeIcon icon={faCheck} />{" "}
          </StyledSpan>
        );
      } else if (offer.rejected_management || offer.rejected_manager) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(244, 118, 49)` }}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </StyledSpan>
        );
      } else if (
        (!offer.accepted_management && !offer.rejected_management) ||
        (!offer.accepted_manager && !offer.rejected_manager)
      ) {
        setSpanObject(
          <StyledSpan style={{ background: `lightgray` }}>
            <FontAwesomeIcon icon={faCircle} />{" "}
          </StyledSpan>
        );
      }
    }
  }, [offer]);

  return (
    <StyledWrapper>
      <StyledSpanWrapper>{spanObject}</StyledSpanWrapper>
      <StyledInfoWrapper>
        <StyledOfferNameWrapper>
          {offer.offer_number ? (
            <StyledOfferName
              onClick={handleOnOpen}
              title={` Oferta ${offer?.offer_number}/${moment(
                offer.created_at
              ).format("YYYY")}`}
            >
              Oferta {offer?.offer_number}/
              {moment(offer.created_at).format("YYYY")}
            </StyledOfferName>
          ) : (
            <StyledOfferName
              onClick={handleOnOpen}
              title={` Oferta z ${offer?.opportunity?.name}`}
            >
              Oferta z {offer?.opportunity?.name}
            </StyledOfferName>
          )}
        </StyledOfferNameWrapper>
        <StyledOfferDate>
          Data utworzenia: {moment(offer.created_at).format("DD.MM.YY HH:mm")}
        </StyledOfferDate>

        <StyledOfferRelationWrapper>
          {offer.opportunity?.company && (
            <StyledOpportunityCompanyWrapper>
              <StyledLabel> Firma:</StyledLabel>

              <StyledOpportunityCompanyLink
                title={offer.opportunity.company.name}
                to={`/dashboard/contacts/companies/details/${offer.opportunity.company.id}`}
              >
                {offer.opportunity.company.name}
              </StyledOpportunityCompanyLink>
            </StyledOpportunityCompanyWrapper>
          )}

          {offer.opportunity?.client && (
            <StyledOpportunityClientWrapper>
              <StyledLabel> Klient:</StyledLabel>

              <StyledOpportunityClientLink
                title={`${offer.opportunity.client.first_name} ${offer.opportunity.client.last_name}`}
                to={`/dashboard/contacts/clients/details/${offer.opportunity.client.id}`}
              >
                {offer.opportunity.client.first_name}{" "}
                {offer.opportunity.client.last_name}
              </StyledOpportunityClientLink>
            </StyledOpportunityClientWrapper>
          )}
        </StyledOfferRelationWrapper>
      </StyledInfoWrapper>

      <OfferApproveItemDetails
        isShowModalOpen={isShowModalOpen}
        handleOnClose={handleOnClose}
        offerObject={offer}
        handleAccept={handleAccept}
        handleReject={handleReject}
        handleChangeAccept={handleChangeAccept}
        isSended={isSended}
      />
      <ChangeDiscount
        offer={offer}
        isModalOpen={isChangeModalOpen}
        handleOnClose={handleOnChangeModalClose}
        fetchData={fetchData}
        setPageNumber={setPageNumber}
        setIsSended={setIsSended}
        isSended={isSended}
      />
    </StyledWrapper>
  );
};

const StyledInfoWrapper = styled.div`
  flex: 1;
  min-width: 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledSpan = styled.span`
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const StyledSpanWrapper = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOfferDate = styled.div``;

const StyledOfferName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #053257;
  }
`;

const StyledLabel = styled.label`
  margin-right: 5px;
`;

const StyledOpportunityClientLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledOpportunityClientWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledOpportunityCompanyLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledOpportunityCompanyWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledOfferNameWrapper = styled.div``;

const StyledOfferRelationWrapper = styled.div``;

const StyledWrapper = styled.div`
  background: white;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  display: flex;
  min-width: 0;
`;

export default HeatPumpApproveItem;
