import React, { createContext, useState } from "react";

export const AssemblyContext = createContext(null);

const AssemblyProvider = ({ children }) => {
  const [chooseOpportunities, setChooseOpportunities] = useState([]);
  const [assemblyOpportunities, setAssemblyOpportunities] = useState([]);

  return (
    <AssemblyContext.Provider
      value={{
        chooseOpportunities,
        setChooseOpportunities,
        assemblyOpportunities,
        setAssemblyOpportunities,
      }}
    >
      {children}
    </AssemblyContext.Provider>
  );
};

export default AssemblyProvider;
