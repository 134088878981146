import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import styled from "styled-components";
import request from "../../../helpers/request";
import OpportunitiesAddNote from "./OpportunitiesAddNote/OpportunitiesAddNote";
import OpportunitiesLeftAside from "./OpportunitiesLeftAside/OpportunitiesLeftAside";
import OpportunitiesNoteList from "./OpportunitiesNoteList/OpportunitiesNoteList";
import AddAssembly from "./OpportunitiesSideBar/OpportunitiesAssembly/AddAssembly/AddAssembly";

import OpportunitiesSideBar from "./OpportunitiesSideBar/OpportunitiesSideBar";

const OpportunitiesDetails = () => {
  const { id } = useParams();

  const [opportunityItem, setOpportunityItem] = useState({});
  const [isAssemblyModalOpen, setIsAssemblyModalOpen] = useState(false);
  const [isAssemblyEditMode, setIsAssemblyEditMode] = useState(false);
  const [assemblyEditObject, setAssemblyEditObject] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const handleOnAssemblyModalClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAssemblyModalOpen(false);
  };

  const fetchOpportunityItem = async (id) => {
    try {
      const { data, status } = await request.get(`api/opportunities/${id}`);
      if (status === 200) {
        setOpportunityItem(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      } else {
        history.push("/dashboard/opportunities");
      }
    }
  };

  useEffect(() => {
    fetchOpportunityItem(id);
  }, []);

  return (
    <StyledWrapper>
      <OpportunitiesLeftAside
        opportunities={opportunityItem}
        fetchOpportunities={() => fetchOpportunityItem(id)}
      />

      <StyledMainWall>
        <OpportunitiesAddNote
          fetchData={() => fetchOpportunityItem(id)}
          opportunitiesId={opportunityItem?.id}
        />
        <OpportunitiesNoteList
          note={opportunityItem.notes}
          fetchData={() => fetchOpportunityItem(id)}
        />
      </StyledMainWall>

      <OpportunitiesSideBar
        fetchData={() => fetchOpportunityItem(id)}
        opportunities={opportunityItem}
        setIsAssemblyModalOpen={setIsAssemblyModalOpen}
        isAssemblyModalOpen={isAssemblyModalOpen}
        setIsAssemblyEditMode={setIsAssemblyEditMode}
        setAssemblyEditObject={setAssemblyEditObject}
      />

      <AddAssembly
        isModalOpen={isAssemblyModalOpen}
        handleOnClose={handleOnAssemblyModalClose}
        fetchData={() => fetchOpportunityItem(id)}
        isEditMode={isAssemblyEditMode}
        editObject={assemblyEditObject}
      />
    </StyledWrapper>
  );
};

const StyledMainWall = styled.div`
  flex-grow: 1;
  width: 100%;
  grid-area: note;
  min-width: 0;

  @media screen and (max-width: 1600px) {
    margin: 0;
    flex: 1;
  }
`;
const StyledWrapper = styled.div`
  display: grid;
  padding: 10px;
  max-width: 1400px;
  margin: 0 auto;
  flex: 1;
  grid-template-columns: minmax(320px, 340px) 1fr minmax(360px, 380px);
  grid-auto-rows: fit-content(1em);
  gap: 10px;
  grid-template-areas:
    "info note sidebar"
    "info note sidebar";

  @media screen and (max-width: 1600px) {
    grid-template-columns: 1fr minmax(360px, 380px);
    grid-template-areas:
      "info sidebar"
      "note sidebar"
      "none sidebar";
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "info"
      "sidebar"
      "note";
  }
`;

// const StyledInfoWrapper = styled.div`
//   display: flex;
//   flex-grow: 1;
//   align-items: flex-start;

//   @media screen and (max-width: 1600px) {
//     flex-direction: column;
//   }
// `;
export default OpportunitiesDetails;
