import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../../../../components/Modal";

import request from "../../../../../../../../../../helpers/request";
import OpportunityContractRealization from "../../../../../../OpportunityContractRealization/OpportunityContractRealization";

const validationSchema = yup.object({
  investor_phone_1: yup
    .string()
    .matches(/^[0-9]+$/, "Numer telefonu musi składać się wyłącznie z cyfr 1-9")
    .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków")
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .nullable(true),
  investor_email_1: yup.string().email("proszę podać prawidłowy adres email"),
  investor_pesel_1: yup.string().nullable(),
});
const AddOwnerDetails = ({
  opportunities,
  isModalOpen,
  handleOnClose,
  fetchData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({});
  };
  const sendData = async (data) => {
    try {
      const { status } = await request.post(`api/owner-details`, {
        ...data,
      });
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    setValue("opportunity_id", opportunities.id);

    if (opportunities.client && !opportunities.company) {
      setValue(
        "investor_name_1",
        `${opportunities.client.first_name} ${opportunities.client.last_name}`
      );
      setValue("investor_phone_1", opportunities.client.phone);
      setValue("investor_email_1", opportunities.client.email);
      setValue("street", opportunities.client.street);
      setValue("building_number", opportunities.client.building_number);
      setValue("city", opportunities.client.city);
      setValue("post_code", opportunities.client.postCode);
    }
    if (opportunities.company && opportunities.client) {
      setValue("investor_name_1", opportunities.company.name);
      setValue("investor_phone_1", opportunities.company.phone);
      setValue("investor_email_1", opportunities.company.email);
      setValue("street", opportunities.company.street);
      setValue("building_number", opportunities.company.building_number);
      setValue("city", opportunities.company.city);
      setValue("post_code", opportunities.company.post_code);
      setValue(
        "investor_name_2",
        `${opportunities.client.first_name} ${opportunities.client.last_name}`
      );
      setValue("investor_phone_2", opportunities.client.phone);
      setValue("investor_email_2", opportunities.client.email);
      setValue("investor_NIP_1", opportunities.company.NIP);
    } else if (opportunities.company) {
      setValue("investor_name_1", opportunities.company.name);
      setValue("investor_phone_1", opportunities.company.phone);
      setValue("investor_NIP_1", opportunities.company.NIP);
      setValue("investor_email_1", opportunities.company.email);
      setValue("street", opportunities.company.street);
      setValue("building_number", opportunities.company.building_number);
      setValue("city", opportunities.company.city);
      setValue("post_code", opportunities.company.post_code);
    }
  }, [opportunities.company?.id, opportunities.client?.id, opportunities]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <input type="hidden" {...register("opportunity_id")} />

        <StyledTitleWrapper>Dane właściciela</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            {opportunities.company ? (
              <label>Nazwa firmy</label>
            ) : (
              <label>Imię i nazwisko</label>
            )}
            <input
              type="text"
              {...register("investor_name_1")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.investor_name_1 && (
          <span className="error">{errors.investor_name_1.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Email</label>
            <input
              type="text"
              {...register("investor_email_1")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.investor_email_1 && (
          <span className="error">{errors.investor_email_1.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input
              type="text"
              {...register("investor_phone_1")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.investor_phone_1 && (
          <span className="error">{errors.investor_phone_1.message}</span>
        )}
        {!opportunities.company && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Seria i numer dowodu osobistego</label>
                <input
                  type="text"
                  {...register("investor_id_card_1")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.investor_id_card_1 && (
              <span className="error">{errors.investor_id_card_1.message}</span>
            )}
          </>
        )}

        {opportunities.company && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>NIP</label>
                <input
                  type="text"
                  {...register("investor_NIP_1")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.investor_NIP_1 && (
              <span className="error">{errors.investor_NIP_1.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Numer KRS</label>
                <input
                  type="text"
                  {...register("investor_krs")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.investor_krs && (
              <span className="error">{errors.investor_krs.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>REGON</label>
                <input
                  type="text"
                  {...register("investor_regon")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.investor_regon && (
              <span className="error">{errors.investor_regon.message}</span>
            )}
          </>
        )}

        {!opportunities.company && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Pesel</label>
                <input
                  type="text"
                  {...register("investor_pesel_1")}
                  autoComplete="off"
                />
              </div>
            </StyledInput>
            {errors?.investor_pesel_1 && (
              <span className="error">{errors.investor_pesel_1.message}</span>
            )}
          </>
        )}

        <StyledTitleWrapper>Adres właściciela</StyledTitleWrapper>

        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input type="text" {...register("street")} autoComplete="off" />
          </div>
        </StyledInput>
        {errors?.street && (
          <span className="error">{errors.street.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("building_number")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.building_number && (
          <span className="error">{errors.building_number.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input type="text" {...register("post_code")} autoComplete="off" />
          </div>
        </StyledInput>
        {errors?.post_code && (
          <span className="error">{errors.post_code.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input type="text" {...register("post")} autoComplete="off" />
          </div>
        </StyledInput>
        {errors?.post && <span className="error">{errors.post.message}</span>}
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input type="text" {...register("city")} autoComplete="off" />
          </div>
        </StyledInput>
        {errors?.city && <span className="error">{errors.city.message}</span>}

        {opportunities.company ? (
          <StyledTitleWrapper>Dane osoby kontaktowej</StyledTitleWrapper>
        ) : (
          <StyledTitleWrapper>Dane współwłaściciela</StyledTitleWrapper>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Imię i nazwisko</label>
            <input
              type="text"
              {...register("investor_name_2")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.investor_name_2 && (
          <span className="error">{errors.investor_name_2.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input
              type="text"
              {...register("investor_phone_2")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.investor_phone_2 && (
          <span className="error">{errors.investor_phone_2.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Seria i numer dowodu osobistego</label>
            <input
              type="text"
              {...register("investor_id_card_2")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.investor_id_card_2 && (
          <span className="error">{errors.investor_id_card_2.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Pesel</label>
            <input
              type="text"
              {...register("investor_pesel_2")}
              autoComplete="off"
            />
          </div>
        </StyledInput>
        {errors?.investor_pesel_2 && (
          <span className="error">{errors.investor_pesel_2.message}</span>
        )}
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Dodaj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 320px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddOwnerDetails;
