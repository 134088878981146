import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import OpportunityItem from "./Components/OpportunityItem";

const WinOpportunityList = ({
  opportunities,
  setBusInfo,
  setDayInfo,
  handleOnDragStart,
  handleOnTouchEnd,
  handleOnDragEnterOpportunities,
  pageCount,
  setPageNumber,
  fetchOpportunity,
  pageNumber,
  isLoading,
}) => {
  const handleOnChangePage = (event) => {
    window.scrollTo(0, 0);
    setPageNumber(event.selected + 1);
    fetchOpportunity(event.selected + 1);
  };

  return (
    <StyledWrapper>
      {opportunities &&
        opportunities.map((opportunity) => (
          <OpportunityItem
            key={opportunity.id}
            setBusInfo={setBusInfo}
            setDayInfo={setDayInfo}
            opportunity={opportunity}
            handleOnDragStart={handleOnDragStart}
            handleOnTouchEnd={handleOnTouchEnd}
            onDragEnter={() => handleOnDragEnterOpportunities()}
          />
        ))}

      <StyledPaginateWrapper>
        <StyledReactPaginated
          breakLabel="..."
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
          pageClassName="paginate_button"
          disableInitialCallback={isLoading}
          onPageChange={(event) => handleOnChangePage(event)}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          renderOnZeroPageCount={null}
          forcePage={pageNumber - 1}
        />
      </StyledPaginateWrapper>
    </StyledWrapper>
  );
};

const StyledReactPaginated = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .paginate_button {
    list-style: none;
    color: #134771;
    &.selected {
      color: rgb(244, 118, 49);
    }
  }
  & .paginate_button a {
    padding: 10px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .break {
    list-style: none;
  }
  & .break a {
    padding: 10px;
    border: none;
    font-size: 18px;
    color: #134771;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .previous {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }

  & .previous a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
  & .next {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }
  & .next a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
`;

const StyledPaginateWrapper = styled.div``;

const StyledWrapper = styled.div`
  flex: 1;
`;
export default WinOpportunityList;
