import React, { useState } from "react";
import styled from "styled-components";
import ShowImageModal from "../../../../../../../../../../../../../components/ShowImage/ShowImageModal";

import NoteItem from "../NoteItem/NoteItem";

const NoteList = ({ note, fetchData }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [allImageCount, setAllImageCount] = useState(0);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState([]);

  const handleOnClickImage = (index, imageFile) => {
    setImageFile(imageFile);
    setAllImageCount(imageFile.length);
    setImageIndex(index);
    setIsImageModalOpen(true);
  };

  const handleOnCloseImage = () => {
    setIsImageModalOpen(false);
    setImageFile([]);
    setAllImageCount(0);
    setImageIndex(0);
  };

  return (
    <StyledNoteWrapper>
      {note &&
        note.map((item) => (
          <NoteItem
            key={item.id}
            note={item}
            fetchData={fetchData}
            handleOnClickImage={handleOnClickImage}
          />
        ))}
      <ShowImageModal
        isModalOpen={isImageModalOpen}
        handleOnClose={handleOnCloseImage}
        setImageIndex={setImageIndex}
        imageIndex={imageIndex}
        imageObject={imageFile}
        allImageCount={allImageCount}
      />
    </StyledNoteWrapper>
  );
};

const StyledNoteWrapper = styled.div`
  margin-top: 10px;
`;

export default NoteList;
