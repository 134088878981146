import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import request from "../../helpers/request";
import { StoreContext } from "../../store/StoreProvider";
import AddLead from "./Components/AddLead/AddLead";
import Lead from "./Lead";
import LeadDetails from "./LeadDetails/LeadDetails";

const LeadSwitch = () => {
  const {
    leads,
    setLeads,
    voivodeships,
    setVoivodeships,
    sources,
    setTeams,
    teams,
    setDepartments,
    departments,
    leadTypes,
    setLeadTypes,
  } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  let { path } = useRouteMatch();

  const [editObject, setEditObject] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const fetchLeads = async () => {
    try {
      const { status, data } = await request.get("/api/lead");

      if (status === 200) {
        setLeads(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchDataDepartments = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchLeadTypes = async () => {
    try {
      const { status, data } = await request.get("/api/lead-types");
      if (status === 200) {
        await setLeadTypes(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (leadTypes === undefined || leadTypes.length === 0) {
      fetchLeadTypes();
    }
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
    if (teams === undefined || teams.length === 0) {
      fetchDataTeams();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartments();
    }
  }, []);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAddModalOpen(false);
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}/`}>
          <Lead
            fetchData={fetchLeads}
            leads={leads}
            setIsAddModalOpen={setIsAddModalOpen}
          />
        </Route>
        <Route path={`${path}/details/:id`}>
          <LeadDetails />
        </Route>
      </Switch>
      <AddLead
        isEditMode={isEditMode}
        isModalOpen={isAddModalOpen}
        editObject={editObject}
        handleOnClose={handleOnClose}
        fetchData={fetchLeads}
      />
    </>
  );
};

export default LeadSwitch;
