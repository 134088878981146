import React from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Main from "./Pages/Main/Main";
import SalesFunnel from "./Pages/SalesFunel/SalesFunnel";

const DealSettings = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <div>
        <StyledCalendarSettingsNav>
          <ul>
            <StyledCalendarSettingsNavItem>
              <NavLink exact to="/settings/deal/" activeClassName="active">
                Ogólne
              </NavLink>
            </StyledCalendarSettingsNavItem>
            <StyledCalendarSettingsNavItem>
              <NavLink to="/settings/deal/salesfunnel" activeClassName="active">
                Lejek Sprzedażowy
              </NavLink>
            </StyledCalendarSettingsNavItem>
          </ul>
        </StyledCalendarSettingsNav>
      </div>

      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/`}>
            <Main />
          </Route>
          <Route path={`${path}/salesfunnel`}>
            <SalesFunnel />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  );
};

const StyledCalendarSettingsNav = styled.nav`
  padding: 20px 40px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledCalendarSettingsNavItem = styled.li`
  list-style: none;
  a {
    display: block;
    padding: 10px 15px;
    font-size: 20px;
    text-decoration: none;
  }
  a.active {
    border-bottom: 4px solid #2d96db;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
`;
export default DealSettings;
