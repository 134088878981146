export default function buildCalendar(value) {
  const startDay = value.clone().startOf("week");
  const endDay = value.clone().endOf("week");

  const day = startDay.clone().subtract(1, "day");

  const calendar = [];
  while (day.isBefore(endDay, "day")) {
    calendar.push(day.add(1, "day").clone());
  }

  return calendar;
}
