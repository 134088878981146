import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect, useContext } from "react";
import styled from "styled-components";

const SearchTypeChoose = ({
  showClients,
  showCompanies,
  showOpportunities,
  showLeads,
  setShowClients,
  setShowCompanies,
  setShowOpportunities,
  setShowLeads,
}) => {
  const handleOnChangeClient = (e) => {
    setShowClients(e.target.checked);
  };
  const handleOnChangeCompanies = (e) => {
    setShowCompanies(e.target.checked);
  };
  const handleOnChangeOpportunity = (e) => {
    setShowOpportunities(e.target.checked);
  };
  const handleOnChangeLeads = (e) => {
    setShowLeads(e.target.checked);
  };
  return (
    <StyledCollapse>
      <StyledTitleWrapper>
        <StyledTitle>Uwzględnij</StyledTitle>
      </StyledTitleWrapper>
      <StyledCollapseItemWrapper>
        <StyledInputWrapper>
          <StyledInput
            type="checkbox"
            checked={Boolean(showClients)}
            onChange={handleOnChangeClient}
          />{" "}
          Osoba
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInput
            type="checkbox"
            checked={Boolean(showCompanies)}
            onChange={handleOnChangeCompanies}
          />{" "}
          Firmy
        </StyledInputWrapper>{" "}
        <StyledInputWrapper>
          <StyledInput
            type="checkbox"
            checked={Boolean(showOpportunities)}
            onChange={handleOnChangeOpportunity}
          />{" "}
          Szanse Sprzedaży
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInput
            type="checkbox"
            checked={Boolean(showLeads)}
            onChange={handleOnChangeLeads}
          />{" "}
          Leady
        </StyledInputWrapper>
      </StyledCollapseItemWrapper>
    </StyledCollapse>
  );
};

const StyledInput = styled.input``;
const StyledInputWrapper = styled.div`
  padding: 5px;
`;
const StyledCollapseItemWrapper = styled.div`
  overflow: hidden;
  border-top: 1px solid rgba(188, 188, 188, 0.4);
  transition: max-height 0.5s ease;
`;

const StyledTitle = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledTitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  background: white;
  display: flex;
`;

const StyledCollapse = styled.div`
  background-color: white;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  overflow: hidden;
`;

export default SearchTypeChoose;
