import {
  faBolt,
  faClock,
  faDollarSign,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EditOpportunities from "./Components/EditOpportunities";

const OpportunitiesInfo = ({ opportunities, fetchOpportunities }) => {
  const [showCount, setShowCount] = useState(null);
  const [showDate, setShowDate] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    if (opportunities.price) {
      setShowCount(
        Number(opportunities.price)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
      );
    }
    if (opportunities.endDate) {
      setShowDate(new Date(opportunities.endDate).toLocaleDateString());
    }
  }, [opportunities]);
  const handleOnEditOpportunity = () => {
    setShowEditModal(true);
  };

  const handleOnCloseEditOpportunity = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowEditModal(false);
  };
  return (
    <StyledWrapper>
      <StyledOpportunitiesInfo>
        <StyledTitleWrapper>
          {opportunities.is_photovoltaica === 1 && (
            <StyledTitle>Fotowoltaika</StyledTitle>
          )}
          {opportunities.is_heat_pump === 1 && (
            <StyledTitle>Pompa ciepła</StyledTitle>
          )}
        </StyledTitleWrapper>
        <StyledNameWrapper>
          <StyledOpportunitiesName>
            {opportunities.name}
          </StyledOpportunitiesName>
        </StyledNameWrapper>
        {/* <StyledOpportunitiesUsers>
          <FontAwesomeIcon icon={faUsers} />
          {opportunities.company?.name}
        </StyledOpportunitiesUsers> */}
      </StyledOpportunitiesInfo>
      <StyledOpportunitiesDescription>
        {showCount && (
          <StyledPriceWrapper>
            <FontAwesomeIcon icon={faDollarSign} />
            <StyledPriceP>{showCount} PLN</StyledPriceP>
          </StyledPriceWrapper>
        )}
        {showDate && (
          <StyledDateWrapper>
            <FontAwesomeIcon icon={faClock} />
            <StyledDateP>{showDate}</StyledDateP>
          </StyledDateWrapper>
        )}
        {opportunities.kWp && (
          <StyledkWpWrapper>
            <FontAwesomeIcon icon={faBolt} />
            <StyledkWp>{opportunities.kWp} kWp</StyledkWp>
          </StyledkWpWrapper>
        )}
        {opportunities.maintainer && (
          <StyledDepartmentWrapper>
            <FontAwesomeIcon icon={faUser} />{" "}
            {opportunities.maintainer?.department ? (
              opportunities.maintainer?.department.name
            ) : (
              <>
                {opportunities.maintainer?.role?.id === 1 && `Zarząd`}
                {opportunities.maintainer?.role?.id === 3 && `Biuro`}
              </>
            )}
            {" - "}
            {opportunities.maintainer?.name}
          </StyledDepartmentWrapper>
        )}
        {opportunities.source && (
          <StyledSourceWrapper>
            <StyledSourcaLabel>Źródło pozyskania:</StyledSourcaLabel>
            <StyledSourceP>{opportunities.source?.name}</StyledSourceP>
          </StyledSourceWrapper>
        )}
        {opportunities.description && (
          <StyledDescriptionWrapper>
            <StyledDescriptionMainText>
              Istotne informacje:
            </StyledDescriptionMainText>
            <StyledDescriptionP>{opportunities.description}</StyledDescriptionP>
          </StyledDescriptionWrapper>
        )}{" "}
        {opportunities.energetic_join_details !== undefined && (
          <ElectricitySupiller>
            <label>Operator sieci dystrybucyjnej:</label>
            {Number(
              opportunities.energetic_join_details?.electricity_supplier
            ) === 1 && <>PGE</>}
            {Number(
              opportunities.energetic_join_details?.electricity_supplier
            ) === 2 && <>Tauron</>}
            {Number(
              opportunities.energetic_join_details?.electricity_supplier
            ) === 3 && <>Enea</>}
            {Number(
              opportunities.energetic_join_details?.electricity_supplier
            ) === 4 && <>Energa</>}
            {Number(
              opportunities.energetic_join_details?.electricity_supplier
            ) === 5 && <>Innogy</>}
            {Number(
              opportunities.energetic_join_details?.electricity_supplier
            ) === 6 && (
              <>
                Inne:{" "}
                {
                  opportunities.energetic_join_details
                    .electricity_supplier_other
                }
              </>
            )}
          </ElectricitySupiller>
        )}
        {opportunities.win_date && (
          <WinDateWrapper>
            Szansa wygrana:{" "}
            {moment(opportunities.win_date).format("DD MMM YY  HH:mm")}
          </WinDateWrapper>
        )}
      </StyledOpportunitiesDescription>
      {opportunities.status === 1 && (
        <StyledButtonWrapper>
          <StyledEditButton onClick={handleOnEditOpportunity}>
            Edytuj
          </StyledEditButton>
        </StyledButtonWrapper>
      )}
      <EditOpportunities
        isModalOpen={showEditModal}
        handleOnClose={handleOnCloseEditOpportunity}
        fetchOpportunities={fetchOpportunities}
        opportunities={opportunities}
      />
    </StyledWrapper>
  );
};

const WinDateWrapper = styled.div``;

const ElectricitySupiller = styled.div`
  margin: 2px 0;
  & label {
    margin-right: 5px;
  }
`;

const StyledkWpWrapper = styled.div`
  color: rgb(102, 188, 70);
  display: flex;
`;
const StyledkWp = styled.p`
  margin-left: 5px;
  font-weight: bold;
`;

const StyledTitle = styled.p`
  color: #134771;
  font-size: 20px;
  font-weight: bold;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSourcaLabel = styled.p`
  font-size: 12px;
`;
const StyledSourceP = styled.p`
  color: black;
`;

const StyledSourceWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;

const StyledDescriptionMainText = styled.p`
  font-weight: bold;
`;

const StyledPriceWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  color: black;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledDateWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;

const StyledDescriptionWrapper = styled.div`
  margin: 10px 0;

  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledDepartmentWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledPriceP = styled.p`
  font-weight: bold;
`;

const StyledDescriptionP = styled.p`
  font-size: 16px;
`;
const StyledDateP = styled.p`
  color: black;
  text-decoration: none;
  &:hover {
    color: #777;
    text-decoration: underline;
  }
`;

const StyledOpportunitiesInfo = styled.div`
  padding: 10px;
`;
const StyledOpportunitiesDescription = styled.div`
  padding: 10px;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledEditButton = styled.button`
  padding: 5px;
  background: #134771;
  color: white;
  border: none;
  width: 100%;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #053257;
    text-decoration: underline;
  }
`;

const StyledNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledWrapper = styled.div`
  min-width: 300px;
  background: white;
  border-radius: 10px;
`;

const StyledOpportunitiesName = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export default OpportunitiesInfo;
