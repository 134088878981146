import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../helpers/request";
import { StoreContext } from "../../store/StoreProvider";
import OfferPricingAside from "./Components/OfferApproveAside/OfferPricingAside";
import OfferPricingList from "./Components/OfferPricingList";

const OfferPricing = () => {
  const { voivodeships, setVoivodeships, user } = useContext(StoreContext);
  const [voivodeshipFilter, setVoivodeshipFilter] = useState(null);
  const [pricingFilter, setPricingFilter] = useState(false);
  const [photovoltaicOffer, setPhotovoltaicOffer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();
  const history = useHistory();

  const fetchPhotovoltaic = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(
        `/api/offer-pricing?page=${number}`,
        {
          params: {
            voivodeship: voivodeshipFilter,
            pricing: pricingFilter,
          },
        }
      );
      if (status === 200) {
        setPhotovoltaicOffer(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };
  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setVoivodeshipFilter(user.department?.voivodeship_id);
    }
  }, [user]);

  const handleOnChangePage = (event) => {
    window.scrollTo(0, 0);
    setPageNumber(event.selected + 1);
    fetchPhotovoltaic(event.selected + 1);
  };
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Wycena ofert</StyledTitle>
      </StyledTitleWrapper>
      <ContentWrapper>
        <OfferPricingAside
          setVoivodeshipFilter={setVoivodeshipFilter}
          voivodeshipFilter={voivodeshipFilter}
          pricingFilter={pricingFilter}
          setPricingFilter={setPricingFilter}
        />

        <OfferPricingList
          pricingFilter={pricingFilter}
          voivodeshipFilter={voivodeshipFilter}
          offerPricingList={photovoltaicOffer}
          fetchData={fetchPhotovoltaic}
          isLoading={isLoading}
          handleOnChangePage={handleOnChangePage}
          pageCount={pageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </ContentWrapper>
    </StyledWrapper>
  );
};

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  min-width: 0;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

export default OfferPricing;
