import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../../../../components/Modal";
import request from "../../../../../../../../../../helpers/request";

const validationSchema = yup.object({});
const AddContractTermsDetails = ({
  opportunities,
  isModalOpen,
  handleOnClose,
  fetchData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: { payment_type: 1, realization_date: 10 },
  });

  const [vatRate, setVatRate] = useState(1);
  const [paymentType, setPaymentType] = useState(1);
  const [firstTranchePercentage, setFirstTranchePercentage] = useState(50);
  const [secondTranchePercentage, setSecondTranchePercentage] = useState(40);
  const [thirdTranchePercentage, setThirdTranchePercentage] = useState(10);
  const [realizationDate, setRealizationDate] = useState(10);
  const [isSended, setIsSended] = useState(false);

  const resetInputValue = () => {
    reset({});
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.post(
        `api/heat-pump-contract-terms-details`,
        {
          ...data,
        }
      );
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (opportunities) {
      setValue("loan_brutto_price", opportunities.price);
      setValue("brutto_price", opportunities.price);
      setValue("opportunity_id", opportunities.id);
    }
  }, [opportunities]);

  const handleOnChangeVatType = (e) => {
    setValue("vat_rate_type", Number(e.target.value));
    setValue("usable_area", "");
    setVatRate(Number(e.target.value));
  };

  const handleOnChangePaymentType = (e) => {
    setPaymentType(Number(e.target.value));
    setValue("payment_type", Number(e.target.value));
    if (Number(e.target.value) === 4) {
      setFirstTranchePercentage(50);
      setValue("first_tranche_percentage", 50);
      setSecondTranchePercentage(40);
      setValue("second_tranche_percentage", 40);
      setThirdTranchePercentage(10);
      setValue("third_tranche_percentage", 10);
    } else {
      setFirstTranchePercentage(0);
      setValue("first_tranche_percentage", "");
      setSecondTranchePercentage(0);
      setValue("second_tranche_percentage", "");
      setThirdTranchePercentage(0);
      setValue("third_tranche_percentage", "");
    }
    if (Number(e.target.value) === 2) {
    } else {
      setValue("loan_self_deposit", "");
    }
  };

  useEffect(() => {
    setValue("vat_rate_type", 1);
  }, []);

  const handleUpFirstTranche = (e) => {
    e.preventDefault();

    const firstTrancheValue = firstTranchePercentage + 10;
    if (firstTranchePercentage >= 100) {
      setFirstTranchePercentage(100);
      setSecondTranchePercentage(0);
      setThirdTranchePercentage(0);
    } else {
      setFirstTranchePercentage(firstTrancheValue);
      if (firstTrancheValue <= 70) {
        setSecondTranchePercentage(90 - firstTrancheValue);
        setThirdTranchePercentage(10);
      } else {
        setSecondTranchePercentage(100 - firstTrancheValue);
        setThirdTranchePercentage(0);
      }
    }
  };
  const handleDownFirstTranche = (e) => {
    e.preventDefault();

    setFirstTranchePercentage(firstTranchePercentage - 10);
    const firstTrancheValue = firstTranchePercentage - 10;
    if (firstTrancheValue <= 60) {
      setSecondTranchePercentage(90 - firstTrancheValue);
      setThirdTranchePercentage(10);
    } else {
      setSecondTranchePercentage(100 - firstTrancheValue);
      setThirdTranchePercentage(0);
    }
  };

  const handleUpSecondTranche = (e) => {
    e.preventDefault();

    if (secondTranchePercentage >= 90) {
      setFirstTranchePercentage(10);
      setSecondTranchePercentage(90);
      setThirdTranchePercentage(0);
    } else {
      setSecondTranchePercentage(secondTranchePercentage + 10);
      const secondTrancheValue = secondTranchePercentage + 10;
      if (thirdTranchePercentage > 0) {
        setThirdTranchePercentage(
          100 - firstTranchePercentage - secondTrancheValue
        );
      } else {
        setFirstTranchePercentage(100 - secondTrancheValue);
      }
    }
  };
  const handleDownSecondTranche = (e) => {
    e.preventDefault();

    setSecondTranchePercentage(secondTranchePercentage - 10);
    const secondTrancheValue = secondTranchePercentage - 10;
    if (secondTranchePercentage <= 0) {
      setFirstTranchePercentage(100);
      setSecondTranchePercentage(0);
      setThirdTranchePercentage(0);
    } else {
      if (thirdTranchePercentage > 0) {
        setThirdTranchePercentage(
          100 - firstTranchePercentage - secondTrancheValue
        );
      } else {
        setFirstTranchePercentage(100 - secondTrancheValue);
      }
    }
  };
  const handleUpThirdTranche = (e) => {
    e.preventDefault();
    if (thirdTranchePercentage >= 80) {
      setFirstTranchePercentage(10);
      setSecondTranchePercentage(10);
      setThirdTranchePercentage(80);
    } else {
      setThirdTranchePercentage(thirdTranchePercentage + 10);
      const thirdTrancheValue = thirdTranchePercentage + 10;
      if (secondTranchePercentage === 10) {
        setFirstTranchePercentage(
          100 - Number(secondTranchePercentage) - thirdTrancheValue
        );
      } else {
        setSecondTranchePercentage(
          100 - Number(firstTranchePercentage) - thirdTrancheValue
        );
      }
    }
  };
  const handleDownThirdTranche = (e) => {
    e.preventDefault();
    if (thirdTranchePercentage === 0) {
    } else {
      setThirdTranchePercentage(thirdTranchePercentage - 10);
      const thirdTrancheValue = thirdTranchePercentage - 10;

      if (secondTranchePercentage === 10) {
        setFirstTranchePercentage(
          100 - Number(secondTranchePercentage) - thirdTrancheValue
        );
      } else {
        setSecondTranchePercentage(
          100 - Number(firstTranchePercentage) - thirdTrancheValue
        );
      }
    }
  };

  useEffect(() => {
    setValue("first_tranche_percentage", firstTranchePercentage);
    setValue("second_tranche_percentage", secondTranchePercentage);
    setValue("third_tranche_percentage", thirdTranchePercentage);
  }, [firstTranchePercentage, secondTranchePercentage, thirdTranchePercentage]);

  const handleOnChangeRealizationDate = (e) => {
    e.preventDefault();
    if (Number(e.target.value) < 0) {
      setRealizationDate(0);
      setValue("realization_date", 0);
    } else {
      setRealizationDate(Number(e.target.value).toFixed());
      setValue("realization_date", Number(e.target.value).toFixed());
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <input type="hidden" {...register("opportunity_id")} />
        <StyledTitleWrapper>Warunki realizacji umowy</StyledTitleWrapper>

        <StyledInput>
          <div className="input-group">
            <label>
              Inwestor oświadcza, że montaż Instalacji ma miejsce na:
            </label>
            <select onChange={handleOnChangeVatType}>
              <option value={1}>
                budynku mieszkalnym o powierzchni użytkowej do 300 m2 — stawka
                należnego podatku VAT wynosi 8%.
              </option>
              <option value={2}>
                budynku mieszkalnym o powierzchni użytkowej pow. 300 m2 — stawka
                należnego podatku VAT wynosi 23%.
              </option>
              <option value={3}>
                innym typie budynku — dla klienta firmowego — stawka należnego
                podatku VAT wynosi 23%.
              </option>
            </select>
          </div>
          <input type="hidden" {...register("vat_rate_type")} />
        </StyledInput>
        {vatRate === 2 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>
                  Powierzchnia użytkowa budynku (m<sup>2</sup>):
                </label>
                <input type="text" {...register("usable_area")} />
              </div>
            </StyledInput>
            {errors?.usable_area && (
              <span className="error">{errors.usable_area.message}</span>
            )}
          </>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Rodzaj płatności:</label>
            <select onChange={handleOnChangePaymentType} value={paymentType}>
              <option value={1}>Płatność kredytem</option>
              <option value={2}>Płatność kredytem z wkładem własnym</option>
              <option value={3}>Płatność leasing</option>
              <option value={4}>Płatność ze środków własnych</option>
            </select>
            <input type="hidden" {...register("payment_type")} />
          </div>
        </StyledInput>

        {paymentType === 2 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Wpłata własna</label>
                <input
                  type="number"
                  min={0}
                  {...register("loan_self_deposit")}
                />
              </div>
            </StyledInput>
            {errors?.loan_self_deposit && (
              <span className="error">{errors.loan_self_deposit.message}</span>
            )}
          </>
        )}
        {paymentType === 4 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Pierwsza transza (%)</label>
                <StyledInputWrapper>
                  <input
                    type="number"
                    min={0}
                    {...register("first_tranche_percentage")}
                    readOnly
                  />
                  <StyledCalcButton onClick={handleUpFirstTranche}>
                    <FontAwesomeIcon icon={faPlus} />
                  </StyledCalcButton>
                  <StyledCalcButton onClick={handleDownFirstTranche}>
                    {" "}
                    <FontAwesomeIcon icon={faMinus} />{" "}
                  </StyledCalcButton>
                </StyledInputWrapper>
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Druga transza (%)</label>
                <StyledInputWrapper>
                  <input
                    type="number"
                    min={0}
                    {...register("second_tranche_percentage")}
                    readOnly
                  />
                  <StyledCalcButton onClick={handleUpSecondTranche}>
                    <FontAwesomeIcon icon={faPlus} />
                  </StyledCalcButton>
                  <StyledCalcButton onClick={handleDownSecondTranche}>
                    {" "}
                    <FontAwesomeIcon icon={faMinus} />{" "}
                  </StyledCalcButton>
                </StyledInputWrapper>
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Trzecia transza (%)</label>
                <StyledInputWrapper>
                  <input
                    type="number"
                    min={0}
                    {...register("third_tranche_percentage")}
                    readOnly
                  />
                  <StyledCalcButton onClick={handleUpThirdTranche}>
                    <FontAwesomeIcon icon={faPlus} />
                  </StyledCalcButton>
                  <StyledCalcButton onClick={handleDownThirdTranche}>
                    {" "}
                    <FontAwesomeIcon icon={faMinus} />{" "}
                  </StyledCalcButton>
                </StyledInputWrapper>
              </div>
            </StyledInput>
          </>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Termin montażu (tyg.)</label>
            <input
              type="number"
              pattern="[0-9]"
              onInput={handleOnChangeRealizationDate}
              value={realizationDate}
            />
            <input type="hidden" {...register("realization_date")} />
          </div>
        </StyledInput>

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Dodaj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCalcButton = styled.button`
  margin-left: 10px;
  color: white;
  background: #134771;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 5px;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  max-width: 600px;
  min-width: 450px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddContractTermsDetails;
