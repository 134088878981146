import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddInstalationType from "./AddInstalationType/AddInstalationType";
import InstalationTypeItem from "./InstalationTypeItem/InstalationTypeItem";

const Instalation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(false);

  const { installationsType, setInstallationsType } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(false);
    setIsModalOpen(true);
  };

  const handleOnDeleteType = async (id) => {
    const { status } = await request.delete(`/api/instalations-type/${id}`);
    if (status === 200) {
      fetchInstalation();
    }
  };

  const handleOpenEditInstalationType = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchInstalation = async () => {
    try {
      const { status, data } = await request.get("/api/instalations-type");
      if (status === 200) {
        await setInstallationsType(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (installationsType === undefined || installationsType.length === 0) {
      fetchInstalation();
    }
  }, []);

  return (
    <StyledTaskTypeWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj Typ</StyledButton>
      </StyledButtonWrapper>

      {installationsType.map((instalation) => {
        return (
          <InstalationTypeItem
            instalationType={instalation}
            handleOnDeleteType={handleOnDeleteType}
            handleOpenEditInstalationType={handleOpenEditInstalationType}
            fetchInstalation={fetchInstalation}
          />
        );
      })}

      <AddInstalationType
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchInstalation}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledTaskTypeWrapper>
  );
};

const StyledTaskTypeWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: #cadeee;
  background: #134771;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;
export default Instalation;
