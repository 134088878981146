import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import { StoreContext } from "../../../../store/StoreProvider";
import request from "../../../../helpers/request";
import { PvCalculatorContext } from "../../../../store/PvCalculatorProvider";

const PVCalculator = () => {
  const { user, constructions, setConstructions } = useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();

  const {
    installation,
    setInstallation,
    installationId,
    setInstallationId,
    construction,
    setConstruction,
    constructionId,
    setConstructionId,
    panelCount,
    setPanelCount,
    optimizer,
    setOptimizer,
    optimizerId,
    setOptimizerId,
    optimizerCount,
    setOptimizerCount,
    inverterCount,
    setInverterCount,
    secure,
    setSecure,
    services,
    setServices,
    vatRate,
    setVatRate,
    margin,
    setMargin,
    antiFireCost,
    setAntiFireCost,
    instalationType,
    setInstaltaionType,
    specialDiscountManagement,
    setSpecialDiscountManagement,
    specialDiscountManager,
    setSpecialDiscountManager,
    cutLength,
    setCutLength,
    wireLength,
    setWireLength,
    optimizers,
    setOptimizers,
    minModules,
    setMinModules,
    minModulesRWB,
    setMinModulesRWB,
    cutIndividualPricing,
    setCutIndividualPricing,
    wireIndividualPricing,
    setWireIndividualPricing,
    installationPower,
    setInstallationPower,
    priceBrutto,
    setPriceBrutto,
    priceNetto,
    setPriceNetto,
    cutLengthPrice,
    setCutLengthPrice,
    wireLengthPrice,
    setWireLengthPrice,
    constructionCount,
    setConstructionCount,
    minPower,
    setMinPower,
    maxPower,
    setMaxPower,
  } = useContext(PvCalculatorContext);

  const handleOnChooseOptimizer = (e) => {
    setOptimizerId(e.target.value);
    if (Number(e.target.value) > 0) {
      const optimizer = optimizers.filter(
        (opt) => opt.id === Number(e.target.value)
      )[0];
      setOptimizer(optimizer);
    } else {
      setOptimizer(null);
    }
  };

  const handleOnChangeIverterCount = (e) => {
    if (e.target.value) {
      if (e.target.value < 1) {
        setInverterCount(1);
      } else {
        setInverterCount(Number(e.target.value));
      }
    } else {
      setInverterCount(null);
    }
  };

  const handleOnBLurInverter = (e) => {
    if (!e.target.value) {
      setInverterCount(1);
    }
  };

  const handleOnChangeConstruction = (e) => {
    setConstructionId(e.target.value);
    if (constructions) {
      setConstruction(
        constructions.find((con) => Number(con.id) === Number(e.target.value))
      );
    }
  };

  const handleOnChooseInstalation = (e) => {
    setInstallationId(e.target.value);
    if (Number(e.target.value) > 0) {
      const inst = instalationType.filter(
        (instalation) => instalation.id === Number(e.target.value)
      )[0];

      if (inst) {
        setOptimizers(inst.inverter?.optimizers);
        setMinModules(inst.inverter?.min_modules);
        setMinModulesRWB(inst.inverter?.min_modules_rwb);

        const minPanels =
          inst.inverter?.min_modules_rwb || inst.inverter?.min_modules;
        if (Number(panelCount) < Number(minPanels)) {
          setPanelCount(minPanels || 1);
          setOptimizerCount(minPanels || 0);
        }

        if (inst.inverter?.min_power) {
          setMinPower(inst.inverter?.min_power);
          const minPanels =
            (Number(inst.inverter?.min_power) * 1000) /
            Number(inst.solar_panel.power);
          if (minPanels) {
            setPanelCount(Number(minPanels.toFixed()));
          }
        } else {
          setMinPower(null);
        }

        if (inst.inverter?.max_power) {
          setMaxPower(inst.inverter?.max_power);
        } else {
          setMaxPower(null);
        }

        CheckIsRWB(
          inst.inverter?.min_modules,
          inst.inverter?.min_modules_rwb,
          Number(panelCount) < Number(minPanels)
            ? Number(minPanels)
            : Number(panelCount)
        );

        if (inst.inverter?.additional_services?.length > 0) {
          inst.inverter.additional_services.forEach((serv) => {
            if (
              services.filter((services) => services.name === serv.service_name)
                .length > 0
            ) {
            } else {
              setServices((prev) => [
                ...prev,
                { name: serv.service_name, price: serv.service_price },
              ]);
            }
          });
        }

        setOptimizerCount(inst.inverter?.min_optimizers || "");
        setInstallation(inst);
      } else {
        setInstallation(null);
      }
    }
  };

  const handleOnRelocate = () => {
    const price = priceBrutto;
    const power = installationPower;

    if (Number(price) > 0 && Number(power) > 0) {
      history.push({
        pathname: "/dashboard/quick-calculation/yield-calculator",
        state: { from: location, price: price, power: power },
      });
    }
  };

  const handleOnCalcPower = () => {
    if (installation && construction) {
      setInstallationId(installation.id);

      const functionPower =
        (panelCount * installation.solar_panel?.power) / 1000;
      setInstallationPower(functionPower);
      if (optimizer && optimizerCount) {
        setOptimizerId(optimizer.id);
      } else {
        setOptimizerId("");
      }

      if (functionPower >= 6.5) {
        if (!secure.includes("Przeciwpożarowe")) {
          setAntiFireCost(1000);
          setSecure(`Przeciwpożarowe ${secure}`);
        } else {
        }
      } else {
        if (secure.includes("Przeciwpożarowe")) {
          const inputValue = secure.replace("Przeciwpożarowe", "");
          setAntiFireCost(0);
          setSecure(inputValue);
        }
      }

      let sumall;
      if (services) {
        sumall = services
          .map((item) => item.name.trim() && item.price)
          .reduce((prev, curr) => Number(prev) + Number(curr), 0);
      } else {
        sumall = 0;
      }

      let cutPrice = 0;
      let wirePrice = 0;

      if (cutLength > 10 && cutLength <= 90) {
        setCutIndividualPricing(false);

        if (user.cut_and_wire_price) {
          const priceArray = user.cut_and_wire_price.find(
            (price) => cutLength <= price.up_to_meter
          );
          if (functionPower < 10) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_10) + 20);
          } else if (functionPower < 20) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_20) + 20);
          } else if (functionPower < 30) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_30) + 20);
          } else if (functionPower < 40) {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_40) + 20);
          } else {
            cutPrice = (cutLength - 10) * (Number(priceArray.price_to_50) + 20);
          }
          setCutLengthPrice(cutPrice.toFixed());
        } else {
          setCutLengthPrice(0);
        }
      } else if (cutLength > 90) {
        setCutLengthPrice(0);
        setCutIndividualPricing(true);
      } else {
        setCutIndividualPricing(false);
        setCutLengthPrice(0);
      }

      if (wireLength > 10 && wireLength <= 90) {
        setWireIndividualPricing(false);

        if (user.cut_and_wire_price) {
          const priceArray = user.cut_and_wire_price.find(
            (price) => wireLength <= price.up_to_meter
          );
          if (functionPower < 10) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_10);
          } else if (functionPower < 20) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_20);
          } else if (functionPower < 30) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_30);
          } else if (functionPower < 40) {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_40);
          } else {
            wirePrice = (wireLength - 10) * Number(priceArray.price_to_50);
          }
          setWireLengthPrice(wirePrice.toFixed());
        } else {
          setWireLengthPrice(0);
        }
      } else if (wireLength > 90) {
        setWireLength(0);
        setWireIndividualPricing(true);
      } else {
        setWireIndividualPricing(false);
        setWireLengthPrice(0);
      }

      if (functionPower <= 4) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_4 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_4 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }

        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 5) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_5 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_5 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 6) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_6 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_6 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 7) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_7 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_7 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 8) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_8 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_8 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 9) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_9 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_9 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 10) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_10 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_10 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 15) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_15 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_15 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 20) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_20 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_20 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 30) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_30 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_30 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 40) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_40 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_40 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else if (functionPower <= 50) {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_50 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_50 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      } else {
        let netto = 0;
        if (optimizer?.price && Number(optimizerCount) > 0) {
          netto =
            functionPower * installation.price_to_50 +
            functionPower * construction.price +
            Number(optimizer.price) * optimizerCount +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        } else {
          netto =
            functionPower * installation.price_to_50 +
            functionPower * construction.price +
            Number(antiFireCost) +
            Number(margin) +
            Number(construction.add_price) -
            Number(specialDiscountManagement) -
            Number(specialDiscountManager) +
            Number(cutPrice) +
            Number(wirePrice) +
            Number(sumall);
        }
        const vat = ((Number(netto) * vatRate) / 100).toFixed(2);
        const bruttoRounded = Math.ceil(Number(netto) + Number(vat));
        const nettoRounded =
          Number(bruttoRounded) / (1 + Number(vatRate) / 100);

        setPriceBrutto(Number(bruttoRounded).toFixed(2));
        setPriceNetto(Number(nettoRounded).toFixed(2));
      }
    } else {
      setPriceBrutto(0);
      setPriceNetto(0);
      setInstallationPower(0);
    }
  };
  const fetchConstructions = async () => {
    try {
      const { status, data } = await request.get("/api/constructions");
      if (status === 200) {
        setConstructions(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push("login");
      }
    }
  };

  useEffect(() => {
    if (constructions === undefined || constructions.length === 0) {
      fetchConstructions();
    }
  }, []);

  useEffect(() => {
    handleOnCalcPower();
  }, [
    installation,
    panelCount,
    vatRate,
    margin,
    construction,
    specialDiscountManagement,
    specialDiscountManager,
    cutLength,
    wireLength,
    optimizer,
    optimizerCount,
    services,
    antiFireCost,
  ]);

  useEffect(() => {
    if (user.offer_form?.instalation?.length > 0) {
      setInstaltaionType(
        user.offer_form?.instalation?.filter(
          (instalation) => instalation.is_active === 1
        )
      );
    }
  }, [user]);

  const handleOnPanelCountChange = (e) => {
    setPanelCount(e.target.value);
    if (e.target.value >= minModules) {
      CheckIsRWB(minModules, minModulesRWB, Number(e.target.value));
    }
  };
  const handleOnPanelChangeBlur = (e) => {
    if (minModules && minModulesRWB !== 0) {
      if (e.target.value < minModulesRWB) {
        setPanelCount(minModulesRWB);
        CheckIsRWB(minModules, minModulesRWB, minModulesRWB);
      } else {
        setPanelCount(e.target.value);
        CheckIsRWB(minModules, minModulesRWB, Number(e.target.value));
      }
    } else if (minModules && minModulesRWB === 0) {
      if (e.target.value < minModules) {
        setPanelCount(minModules);
        CheckIsRWB(minModules, minModulesRWB, minModules);
      } else {
        setPanelCount(e.target.value);
        CheckIsRWB(minModules, minModulesRWB, Number(e.target.value));
      }
    }

    if (installation.inverter && installation.solar_panel) {
      if (minPower) {
        const panelAmount = (minPower * 1000) / installation.solar_panel?.power;
        console.log(installation);
        if (panelAmount > panelCount) {
          setPanelCount(Number(panelAmount.toFixed()));
        }
      }
      if (maxPower) {
        const panelAmount = (maxPower * 1000) / installation.solar_panel?.power;
        if (panelAmount < panelCount) {
          setPanelCount(Number(panelAmount.toFixed()));
        }
      }
    }
  };

  const handleOnChangeMargin = (e) => {
    if (e.target.value < 0) {
      setMargin(0);
    } else {
      setMargin(e.target.value);
    }
  };

  const handleOnChangeCutLength = (e) => {
    if (e.target.value < 0) {
      setCutLength(0);
    } else {
      setCutLength(Number(e.target.value).toFixed());
      setWireLength(0);
    }
  };

  const handleOnChangeWireLength = (e) => {
    if (e.target.value < 0) {
      setWireLength(0);
    } else {
      setWireLength(Number(e.target.value).toFixed());
      setCutLength(0);
    }
  };

  const handleOnChangeServiceName = (e, index) => {
    setServices((prev) =>
      prev.map((el, elIndex) =>
        elIndex === index ? { ...el, name: e.target.value } : el
      )
    );
  };
  const handleOnChangeServicePrice = (e, index) => {
    setServices((prev) =>
      prev.map((el, elIndex) =>
        elIndex === index ? { ...el, price: e.target.value } : el
      )
    );
  };

  const CheckIsRWB = (minModule, minModuleRwb, panelCount) => {
    if (minModule && minModuleRwb === 0) {
      if (panelCount < minModule) {
        if (
          services.filter((services) => services.name === "Inverter RWB")
            .length > 0
        ) {
        } else {
          setServices((prev) => [
            ...prev,
            { name: "Inverter RWB", price: "1500" },
          ]);
        }
      } else {
        const index = services.findIndex(
          (services) => services.name === "Inverter RWB"
        );
        if (index >= 0) {
          const splitServices = [...services];
          splitServices.splice(index, 1);
          setServices(splitServices);
        }
      }
    } else if (minModule && minModuleRwb !== 0) {
      if (
        Number(panelCount) >= minModuleRwb &&
        Number(panelCount) <= minModule
      ) {
        if (
          services.filter((services) => services.name === "Inverter RWB")
            .length > 0
        ) {
        } else {
          setServices((prev) => [
            ...prev,
            { name: "Inverter RWB", price: "1500" },
          ]);
        }
      } else {
        const index = services.findIndex(
          (services) => services.name === "Inverter RWB"
        );
        if (index >= 0) {
          const splitServices = [...services];
          splitServices.splice(index, 1);
          setServices(splitServices);
        }
      }
    }
  };

  const handleOnRemoveItem = (e, index) => {
    e.preventDefault();
    const serviceTable = [...services];
    serviceTable.splice(index, 1);
    setServices(serviceTable);
  };

  return (
    <StyledWrapper>
      <StyledItemWrapper>
        <StyledForm
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        >
          <StyledInstalationInfoWrapper>
            <StyledInvestorInfoTitleWrapper>
              <StyledInvestorInfoTitle>
                Dane Instalacji:
              </StyledInvestorInfoTitle>
            </StyledInvestorInfoTitleWrapper>
            {instalationType && (
              <StyledInstalationWrapper>
                <StyledInstalation>
                  <StyledInput>
                    <div className="input-group">
                      <label>Instalacja fotowoltaiczna:</label>
                      <select
                        onChange={handleOnChooseInstalation}
                        value={installationId}
                      >
                        <option value="">Wybierz...</option>
                        {instalationType.map((instalation) => {
                          return (
                            <option key={instalation.id} value={instalation.id}>
                              {instalation.solar_panel?.name} +{" "}
                              {instalation.inverter?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </StyledInput>
                </StyledInstalation>
                <StyledInstalationCountWrapper>
                  <StyledInput>
                    <div className="input-group">
                      <label>Ilość paneli</label>
                      <input
                        type="number"
                        value={panelCount}
                        onInput={handleOnPanelCountChange}
                        onBlur={handleOnPanelChangeBlur}
                      />
                    </div>
                  </StyledInput>

                  <StyledInput>
                    <div className="input-group">
                      <label>Ilość falowników</label>
                      <input
                        type="number"
                        value={inverterCount}
                        onChange={handleOnChangeIverterCount}
                        onBlur={handleOnBLurInverter}
                      />
                    </div>
                  </StyledInput>
                </StyledInstalationCountWrapper>
              </StyledInstalationWrapper>
            )}

            <StyledConstructionWrapper>
              <StyledConstruction>
                <StyledInput>
                  <div className="input-group" style={{ flex: "1" }}>
                    <label>Konstrukcja:</label>
                    <select
                      onChange={handleOnChangeConstruction}
                      value={constructionId}
                    >
                      <option value={""}>Wybierz...</option>
                      {constructions?.map((construction) => {
                        return (
                          <option key={construction.id} value={construction.id}>
                            {construction.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>{" "}
                </StyledInput>
              </StyledConstruction>
              <StyledConstructionCount>
                <StyledInput>
                  <div className="input-group">
                    <label>Ilość konstrukcji</label>
                    <input
                      type="number"
                      value={constructionCount}
                      onChange={(e) => setConstructionCount(e.target.value)}
                    />
                  </div>
                </StyledInput>
              </StyledConstructionCount>
            </StyledConstructionWrapper>

            <StyledConstructionWrapper>
              <StyledConstruction>
                <StyledOtherInput>
                  <StyledOtherInputWrapper style={{ flex: "1" }}>
                    <StyledOtherInputLabel>
                      Optymalizatory:
                    </StyledOtherInputLabel>
                    <select
                      onChange={handleOnChooseOptimizer}
                      style={{ width: "100%" }}
                      value={optimizerId}
                    >
                      <option value="">Wybierz...</option>
                      {optimizers.map((optimizer) => {
                        return (
                          <option key={optimizer.id} value={optimizer.id}>
                            {optimizer.name}
                          </option>
                        );
                      })}
                    </select>
                  </StyledOtherInputWrapper>

                  <StyledOtherInputWrapper>
                    <StyledOtherInputLabel>Ilość</StyledOtherInputLabel>
                    <input
                      style={{ width: "40px" }}
                      type="number"
                      value={optimizerCount}
                      onInput={(e) => {
                        setOptimizerCount(e.target.value);
                      }}
                    />
                  </StyledOtherInputWrapper>
                </StyledOtherInput>
              </StyledConstruction>
            </StyledConstructionWrapper>

            <StyledDetailsWrapper>
              <StyledDetailsLeftSide>
                <StyledInput>
                  <div className="input-group">
                    <label>Koszt ppoż</label>
                    <input
                      type="number"
                      onChange={(e) => setAntiFireCost(e.target.value)}
                      value={antiFireCost}
                    />
                  </div>
                </StyledInput>
                <StyledInput>
                  <div className="input-group">
                    <label>Zabezpieczenie</label>
                    <input type="text" value={secure} readOnly />
                  </div>
                </StyledInput>
              </StyledDetailsLeftSide>
              <StyledDetailsRightSide>
                <StyledInput>
                  <div className="input-group">
                    <label>Moc (kWp)</label>
                    <input type="number" value={installationPower} readOnly />
                  </div>{" "}
                </StyledInput>

                <StyledSectionTitleWrapper>
                  Usługi dodatkowe:
                </StyledSectionTitleWrapper>
                {cutIndividualPricing && (
                  <StyledAlertInfoWrapper>
                    <StyledAlert>
                      W przypadku kompleksowego wykonania przekopu wraz z
                      przewodami AC/DC przekraczającego 90m - wycena
                      indywidualna{" "}
                    </StyledAlert>
                  </StyledAlertInfoWrapper>
                )}
                {wireIndividualPricing && (
                  <StyledAlertInfoWrapper>
                    <StyledAlert>
                      W przypadku połozenia przewody elektryczne AC/DC z
                      przekopem po stronie inwestora przekraczającego 90m -
                      wycena indywidualna{" "}
                    </StyledAlert>
                  </StyledAlertInfoWrapper>
                )}

                <StyledServicesInput>
                  <StyledServicesTitle>
                    Kompleksowe wykonanie przekopu wraz z przewodami AC/DC
                  </StyledServicesTitle>
                  <StyledServicesInputWrapper>
                    <StyledServicesInputLabel>m</StyledServicesInputLabel>
                    <input
                      type="number"
                      value={cutLength}
                      onChange={handleOnChangeCutLength}
                    />
                  </StyledServicesInputWrapper>
                  <StyledServicesInputWrapper>
                    <StyledServicesInputLabel>Cena</StyledServicesInputLabel>
                    <input type="text" value={cutLengthPrice} readOnly />
                  </StyledServicesInputWrapper>
                </StyledServicesInput>
                <StyledServicesInput>
                  <StyledServicesTitle>
                    Przewody elektryczne AC/DC - przekop po stronie inwestora
                  </StyledServicesTitle>
                  <StyledServicesInputWrapper>
                    <StyledServicesInputLabel>m</StyledServicesInputLabel>
                    <input
                      type="number"
                      value={wireLength}
                      onChange={handleOnChangeWireLength}
                    />
                  </StyledServicesInputWrapper>{" "}
                  <StyledServicesInputWrapper>
                    <StyledServicesInputLabel>Cena</StyledServicesInputLabel>
                    <input type="text" value={wireLengthPrice} readOnly />
                  </StyledServicesInputWrapper>
                </StyledServicesInput>
                {services.map((field, index) => (
                  <StyledServicesOtherInput key={index}>
                    <StyledInputWrapper>
                      <StyledOtherServicesInputWrapper>
                        <input
                          type="text"
                          onChange={(e) => handleOnChangeServiceName(e, index)}
                          value={field.name}
                        />
                      </StyledOtherServicesInputWrapper>{" "}
                      <StyledServicesInputWrapper>
                        <StyledServicesInputLabel>
                          Cena
                        </StyledServicesInputLabel>
                        <input
                          type="number"
                          onChange={(e) => handleOnChangeServicePrice(e, index)}
                          onWheel={(e) => e.target.blur()}
                          value={field.price}
                        />
                      </StyledServicesInputWrapper>
                      <StyledRemoveButtonWrapper>
                        <StyledRemoveButton
                          onClick={(e) => handleOnRemoveItem(e, index)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </StyledRemoveButton>
                      </StyledRemoveButtonWrapper>
                    </StyledInputWrapper>
                    <StyledErrorWrapper></StyledErrorWrapper>
                  </StyledServicesOtherInput>
                ))}
                <StyledAddOtherServiceButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setServices((prev) => [...prev, { name: "", price: "" }]);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </StyledAddOtherServiceButton>
              </StyledDetailsRightSide>
            </StyledDetailsWrapper>

            <StyledPriceWrapper>
              <StyledPriceLeftSide>
                <StyledInput>
                  <div className="input-group">
                    <label>Cena instalacji (netto)</label>
                    <input type="text" value={priceNetto} readOnly />
                  </div>
                </StyledInput>

                <StyledInput>
                  <div className="input-group">
                    <label>Stawka Vat</label>
                    <select
                      onChange={(e) => setVatRate(Number(e.target.value))}
                      value={vatRate}
                    >
                      <option value={8}>8%</option>
                      <option value={23}>23%</option>
                    </select>
                  </div>
                </StyledInput>
              </StyledPriceLeftSide>
              <StyledPriceRightSide>
                <StyledInput>
                  <div className="input-group">
                    <label>Marża</label>
                    <input
                      type="number"
                      min={0}
                      value={margin}
                      onChange={handleOnChangeMargin}
                    />
                  </div>
                </StyledInput>
                {user?.role?.id !== 2 && (
                  <StyledInput>
                    <div className="input-group">
                      <label>Rabat specjalny Dyrektor(netto)</label>
                      <input
                        min={0}
                        type="number"
                        value={specialDiscountManager}
                        onChange={(e) =>
                          setSpecialDiscountManager(e.target.value)
                        }
                      />
                    </div>
                  </StyledInput>
                )}

                <StyledInput>
                  <div className="input-group">
                    <label>Rabat specjalny Zarzad(netto)</label>
                    <input
                      min={0}
                      type="number"
                      value={specialDiscountManagement}
                      onChange={(e) =>
                        setSpecialDiscountManagement(e.target.value)
                      }
                    />
                  </div>
                </StyledInput>

                <StyledBruttoPriceInput>
                  <div className="input-group">
                    <StyledBruttoPriceLabel>
                      Cena końcowa instalacji (brutto)
                    </StyledBruttoPriceLabel>
                    <input type="text" value={priceBrutto} readOnly />
                  </div>
                </StyledBruttoPriceInput>
              </StyledPriceRightSide>
            </StyledPriceWrapper>
          </StyledInstalationInfoWrapper>
        </StyledForm>
        <StyledButtonsWrapper>
          <StyledButton onClick={handleOnRelocate}>
            Kalkulacja uzysków
          </StyledButton>
        </StyledButtonsWrapper>
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

const StyledButtonsWrapper = styled.div``;

const StyledButton = styled.button`
  margin-top: 10px;
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledAlertInfoWrapper = styled.div`
  padding: 10px;
`;

const StyledAlert = styled.p`
  color: red;
  font-size: 14px;
`;

const StyledBruttoPriceInput = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    input {
      border: none;
      border-bottom: 2px solid black;
      width: 100%;
      outline: none;
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const StyledBruttoPriceLabel = styled.label`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 16px;
  font-weight: bold;
  color: rgb(244, 118, 49);
`;

const StyledItemWrapper = styled.div`
  background: white;
  padding: 10px;
  max-width: 360px;
  border-radius: 10px;
`;

const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
const StyledErrorWrapper = styled.div``;

const StyledInputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const StyledRemoveButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  height: 19px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;
const StyledRemoveButtonWrapper = styled.div`
  margin-top: 20px;
  width: 60px;
`;

const StyledSectionTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #134771;
`;

const StyledServicesOtherInput = styled.div``;

const StyledOtherServicesInputWrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  position: relative;
  & input {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
`;
const StyledAddOtherServiceButton = styled.button`
  margin-top: 10px;
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledServicesInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;

const StyledServicesInputWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  & input {
    width: 60px;
  }
`;

const StyledServicesTitle = styled.div`
  flex: 1;
  font-size: 14px;
  margin-top: 20px;
`;

const StyledServicesInput = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledOtherInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;
const StyledOtherInputWrapper = styled.div`
  margin-top: 20px;
  position: relative;
`;

const StyledOtherInput = styled.div`
  display: flex;
  gap: 10px;
`;
const StyledPriceRightSide = styled.div`
  flex: 1;
`;

const StyledPriceLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
`;

const StyledDetailsRightSide = styled.div`
  flex: 1;
`;

const StyledDetailsLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledDetailsWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledConstructionCount = styled.div`
  flex: 1;
`;

const StyledConstruction = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledConstructionWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;
const StyledInstalation = styled.div`
  flex: 1;
  margin-right: 10px;
`;
const StyledInstalationCountWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const StyledInstalationWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledInstalationInfoWrapper = styled.div``;

const StyledInvestorInfoTitle = styled.p`
  color: #134771;
`;
const StyledInvestorInfoTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  max-width: 640px;
  min-width: 0;
  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .todo-input-group {
      width: 49%;

      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      padding: 10px;
      overflow-y: hidden;
      border-radius: 10px;
      outline: none;
    }
  }
`;

export default PVCalculator;
