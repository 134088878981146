import React, { useState } from "react";
import styled from "styled-components";
import UnassignedAddNote from "./UnassignedAddNote";

const LeadUnassigned = ({ lead, fetchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOnOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <StyledWrapper>
      <StyledButton onClick={handleOnOpenModal}>Zrezygnuj z leada</StyledButton>
      <UnassignedAddNote
        lead={lead}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
      />
    </StyledWrapper>
  );
};

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  width: 100%;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledWrapper = styled.div`
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
`;
export default LeadUnassigned;
