import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const CollapseTypItem = ({
  id,
  name,
  prefix,
  icon,
  setTaskTypeFilter,
  taskTypeFilter,
}) => {
  const handleOnClick = () => {
    if (taskTypeFilter === id) {
      setTaskTypeFilter(null);
    } else {
      setTaskTypeFilter(id);
    }
  };

  return (
    <StyledCollapseItemButton
      onClick={handleOnClick}
      className={taskTypeFilter === id && "active"}
    >
      <StyledSVGWrapper>
        <FontAwesomeIcon icon={[prefix, icon]} />
      </StyledSVGWrapper>
      <p>{name}</p>
    </StyledCollapseItemButton>
  );
};

const StyledSVGWrapper = styled.div`
  width: 20px;
  padding-right: 10px;
  height: 20px;
  margin: 0 10px;
  text-align: center;
`;

const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 2px 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
  }
`;
export default CollapseTypItem;
