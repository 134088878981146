import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";

import * as yup from "yup";
import requestMulti from "../../../../helpers/requestMulti";
import heic2any from "heic2any";
import loading from "../../../../img/48x48.gif";
import AddFileItem from "./Components/AddFileItem";
import imageCompression from "browser-image-compression";

import { useDropzone } from "react-dropzone";
const validationSchema = yup.object({
  text: yup.string(),
});

const getColor = (props) => {
  if (props.isDragAccept) {
    return " rgb(102, 188, 70)";
  }
  if (props.isDragReject) {
    return "rgb(244, 118, 49)";
  }
  if (props.isDragActive) {
    return " rgb(102, 188, 70)";
  }
  return "#134771";
};
const ClientAddNote = ({ fetchData, clientId }) => {
  const [isOpenAddFileInput, setIsOpenAddFileInput] = useState("none");
  const [isConvert, setIsConvert] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [convertedFile, setConvertedFile] = useState([]);
  const [fileContext, setFileContext] = useState([]);

  const { register, handleSubmit, reset, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const location = useLocation();
  const history = useHistory();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: isConvert,
    onDrop: (acceptedFiles) => handleOnFileChange(acceptedFiles),
  });

  const sendData = async (data) => {
    setIsSended(true);
    const formData = new FormData();

    formData.append(`text`, data.text);
    formData.append(`client_id`, clientId);
    if (data.file) {
      for (let i = 0; i < data.file.length; i++) {
        formData.append(`file[${i}]`, data.file[i]);
      }
    }
    try {
      const { status } = await requestMulti.post("/api/note", formData);
      if (status === 200) {
        fetchData();
        resetInputValue();
        setConvertedFile([]);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };
  const resetInputValue = () => {
    reset({
      text: "",
      file: null,
    });
    document.getElementById("TextArea").style.height = "60px";
  };

  const handleOnSubmitwithClose = (data) => {
    if (data.text.trim() || (data.file && data.file.length)) {
      sendData(data);
    }
  };

  const handleChangeAddFileInput = (e) => {
    e.preventDefault();
    if (isOpenAddFileInput === "none") {
      setIsOpenAddFileInput("block");
    } else {
      setIsOpenAddFileInput("none");
    }
  };

  const handleSetHeight = (e) => {
    e.target.style.height = "inherit";

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    e.target.style.height = `${height}px`;
  };

  const handleOnFileChange = async (files) => {
    const asyncArray = await Promise.all(
      [...files].map((file) => convertFile(file))
    );
    setConvertedFile((prev) => [...prev, ...asyncArray]);

    setIsConvert(false);
  };

  useEffect(() => {
    if (convertedFile && convertedFile.length >= 0) {
      let fileArray = [];

      setValue("file", convertedFile);
      [...convertedFile].forEach((file) => {
        fileArray.push({
          name: file.name,
          size: (file.size / 1000).toFixed(2),
          mime_type: file.type,
          url: URL.createObjectURL(file),
        });
      });

      setFileContext(fileArray);
    }
  }, [convertedFile]);

  const convertFile = async (file) => {
    if (file.type === "image/heif" || file.type === "image/heic") {
      let blobURL = URL.createObjectURL(file);
      // convert "fetch" the new blob url
      let blobRes = await fetch(blobURL);
      // convert response to blob
      let blob = await blobRes.blob();
      // convert to PNG - response is blob
      let conversionResult = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });

      // convert to blob url
      const fileItem = new File(
        [conversionResult],
        file.name.replace(/\.[^/.]+$/, ".jpeg"),
        { type: "image/jpeg" }
      );

      return fileItem;
    } else if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/bmp"
    ) {
      try {
        const compresedfile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        });

        const fileItem = new File([compresedfile], file.name, {
          type: file.type,
        });
        return fileItem;
      } catch (e) {
        console.warn(e);
      }
    } else {
      return file;
    }
  };

  const handleOnCancel = (e) => {
    if (e) {
      e.preventDefault();
    }
    resetInputValue();
    setConvertedFile([]);
  };
  const handleOnDelete = (e, index) => {
    if (e) {
      e.preventDefault();
    }
    const array = [...convertedFile];

    array.splice(index, 1);

    setConvertedFile(array);
  };

  return (
    <StyledAddNote>
      <StyledAddNoteForm encType="multipart/form-data">
        <StyledTextArea
          id="TextArea"
          {...register("text")}
          onKeyUp={handleSetHeight}
        ></StyledTextArea>

        <StyledAddFileInputWrapper style={{ display: isOpenAddFileInput }}>
          <StyledDropContainer
            {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            <p>Upuść tutaj, lub kliknij aby wybrać pliki</p>
          </StyledDropContainer>
          {isConvert && (
            <StyledConvertWrapper>
              <img src={loading} alt="loading" />
            </StyledConvertWrapper>
          )}
          <StyledFileWrapper>
            {fileContext.map((file, index) => (
              <AddFileItem
                file={file}
                key={index}
                index={index}
                handleOnDelete={handleOnDelete}
              />
            ))}
          </StyledFileWrapper>
        </StyledAddFileInputWrapper>
        <StyledButtonsWrapper>
          <StyledAddFileButton
            title="Dodaj załącznik"
            onClick={handleChangeAddFileInput}
          >
            <FontAwesomeIcon icon={faPaperclip} />
          </StyledAddFileButton>
          <div>
            <StyledCancelButton onClick={handleOnCancel}>
              Anuluj
            </StyledCancelButton>
            <StyledAddButton
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disable={isConvert}
            >
              Dodaj Notatkę
            </StyledAddButton>
          </div>
        </StyledButtonsWrapper>
      </StyledAddNoteForm>
      {isSended && (
        <StyledSendNoteWrapper>
          <StyledSendNoteInnerWrapper>
            Przesyłanie...
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledSendNoteInnerWrapper>
        </StyledSendNoteWrapper>
      )}
    </StyledAddNote>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledDropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 10px;
  border-style: dashed;
  border-color: ${(props) => getColor(props)};
  background-color: #cadeee;
  color: #134771;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const StyledConvertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const StyledFileWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 140px));
`;

const StyledAddFileInputWrapper = styled.div`
  padding: 5px 0;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 16px;
  padding: 10px;
  overflow-y: hidden;
  border-radius: 10px;
  outline: none;
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;
const StyledAddFileButton = styled.button`
  border: none;
  padding: 0.6rem 0.8rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-size: 18px;
  background: transparent;
  color: #134771;

  &:hover {
    color: #053257;
  }
`;
const StyledAddNoteForm = styled.form`
  padding: 10px;
`;

const StyledAddNote = styled.div`
  width: 100%;
  border-radius: 10px;
  background: white;
  position: relative;
`;
const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCancelButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: transparent;
  color: #134771;

  &:hover {
    color: #053257;
  }
`;
const StyledAddButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;

  background: #134771;
  color: #cadeee;

  &:hover {
    background: #053257;
  }
`;
export default ClientAddNote;
