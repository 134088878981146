import React, { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../store/StoreProvider";
import CollapseVoivodeship from "./CollapseVoivodeship/CollapseVoivodehip";
import PricingFilter from "./PricingFilter/PricingFilter";

const OfferPricingAside = ({
  setVoivodeshipFilter,
  voivodeshipFilter,
  pricingFilter,
  setPricingFilter,
}) => {
  const { user } = useContext(StoreContext);

  return (
    <StyledAside>
      <PricingFilter
        pricingFilter={pricingFilter}
        setPricingFilter={setPricingFilter}
      />
      {user.role?.id === 1 && (
        <CollapseVoivodeship
          setVoivodeshipFilter={setVoivodeshipFilter}
          voivodeshipFilter={voivodeshipFilter}
        />
      )}
    </StyledAside>
  );
};

const StyledAside = styled.div`
  min-width: 200px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export default OfferPricingAside;
