import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const TodoPopOver = ({
  todo,
  isOpen,
  setIsOpen,
  handleOnOpenAlert,
  handleOnClickEdit,
  setEditObject,
  handleChangeComplete,
}) => {
  const dropRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOpen(false);
  };

  const handleOnEditTodo = () => {
    setEditObject(todo);
    handleOnClickEdit();
  };

  return (
    <StyledPopOver
      ref={dropRef}
      style={isOpen ? { display: "block" } : { display: "none" }}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledInfoWrapper>
        <StyledNameWrapper>
          <StyledSetDoneButton
            onClick={handleChangeComplete}
            style={
              todo.done === 1
                ? { background: "#66bc46" }
                : { background: "transparent" }
            }
          >
            <StyledCheckIcon icon={faCheck} />
          </StyledSetDoneButton>
          {todo.name}
        </StyledNameWrapper>
        <StyledDataWrapper>
          <StyledDate>
            {moment(todo.start_date).locale("pl").format("DD MMMM")}
          </StyledDate>
        </StyledDataWrapper>
        <StyledResponsibleWrapper>
          Dla:{" "}
          <StyledResponsibleLink>{todo.responsible.name}</StyledResponsibleLink>
        </StyledResponsibleWrapper>
        {todo.description && (
          <StyledDescriptionWrapper>
            <StyledDescriptionP>Opis: {todo.description}</StyledDescriptionP>
          </StyledDescriptionWrapper>
        )}

        <StyledContactWrapper>
          <StyledContactTitle>Dotyczy:</StyledContactTitle>
          {todo.opportunity && (
            <StyledOpportunityLink
              to={`/dashboard/opportunities/details/${todo.opportunity.id}`}
            >
              {todo.opportunity.name}
            </StyledOpportunityLink>
          )}
          {todo.lead && (
            <StyledOpportunityLink
              to={`/dashboard/lead/details${todo.lead.id}`}
            >
              {todo.lead?.name} {todo.lead?.phone} {todo.lead?.email}
            </StyledOpportunityLink>
          )}
        </StyledContactWrapper>
      </StyledInfoWrapper>
      <StyledPopOverBottom>
        <StyledEditButton onClick={handleOnEditTodo}>Edytuj</StyledEditButton>
        <StyledDeleteButton onClick={handleOnOpenAlert}>
          Usuń
        </StyledDeleteButton>
      </StyledPopOverBottom>
    </StyledPopOver>
  );
};

const StyledDeleteButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: #053257;
  }
`;

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledInfoWrapper = styled.div`
  padding: 10px;
`;
const StyledPopOverBottom = styled.div`
  padding: 10px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const StyledOpportunityLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  display: block;

  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledCompanyLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  display: block;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledClientLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  display: block;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledContactTitle = styled.p``;

const StyledContactWrapper = styled.div`
  margin-top: 20px;
`;

const StyledDescriptionP = styled.p``;

const StyledDescriptionWrapper = styled.div``;

const StyledResponsibleLink = styled.span`
  color: #134771;
  text-decoration: none;

  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledResponsibleWrapper = styled.div``;

const StyledDate = styled.p``;

const StyledDataWrapper = styled.div``;

const StyledCheckIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 12px;
`;

const StyledSetDoneButton = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #66bc46;
  background: none;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledPopOver = styled.div`
  position: absolute;
  left: 10px;
  z-index: 9999;
  background: white;
  min-width: 360px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`;
export default TodoPopOver;
