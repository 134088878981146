import React from "react";
import styled from "styled-components";

const TodoSideBarNav = ({ handleOpenModal }) => {
  return (
    <StyledSideBarNav>
      <StyledTitle>Zadania</StyledTitle>
    </StyledSideBarNav>
  );
};
const StyledTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const StyledSideBarNav = styled.div`
  padding: 5px;
  color: black;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default TodoSideBarNav;
