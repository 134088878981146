import React, { useContext } from "react";
import styled from "styled-components";
import TodoList from "./TodoSideBarList/TodoList/TodoList";
import LeadType from "./LeadType/LeadType";
import LeadResponsible from "./LeadResponsible/LeadResponsible";
import { StoreContext } from "../../../../../store/StoreProvider";
import LeadUnassigned from "./LeadUnassigned/LeadUnassigned";
import CreateContact from "./CreateContact/CreateContact";
import ContactLead from "./ContactLead/ContactLead";
import WrongLead from "./WrongLead/WrongLead";
import LeadComplaint from "./LeadComplaint/LeadComplaint";

const LeadSideBar = ({ lead, fetchData }) => {
  const { user } = useContext(StoreContext);

  return (
    <StyledOportunitiesSideBarWrapper>
      {lead.wrong_lead === 0 ? (
        <>
          <StyledOpportunitieslist>
            <TodoList
              todos={lead.todo || []}
              fetchData={fetchData}
              lead_id={lead?.id}
              lead={lead}
            />
          </StyledOpportunitieslist>

          <ContactLead lead={lead} fetchData={fetchData} />
          {lead.contact_create === 0 && (
            <>
              <LeadType lead={lead} fetchData={fetchData} />
              {(user.role?.id === 1 ||
                user.role?.id === 2 ||
                user.role?.id === 3) && (
                <LeadResponsible lead={lead} fetchData={fetchData} />
              )}
              {user.role?.id === 4 && (
                <LeadUnassigned lead={lead} fetchData={fetchData} />
              )}
            </>
          )}
          {lead.contact_the_lead === 1 && (
            <CreateContact lead={lead} fetchData={fetchData} />
          )}
        </>
      ) : null}

      {lead.contact_create === 0 && (
        <WrongLead lead={lead} fetchData={fetchData} />
      )}
      {lead.wrong_lead === 1 && (
        <LeadComplaint lead={lead} fetchData={fetchData} />
      )}
    </StyledOportunitiesSideBarWrapper>
  );
};

const StyledOportunitiesSideBarWrapper = styled.div`
  grid-area: sidebar;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
const StyledOpportunitieslist = styled.div``;

export default LeadSideBar;
