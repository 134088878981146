import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";

import moment from "moment";
import { ReactComponent as PdfDownload } from "../../../../../img/pdf-download-2617.svg";
import Modal from "../../../../../components/Modal";
import { StoreContext } from "../../../../../store/StoreProvider";
import loading from "../../../../../img/48x48.gif";

const OfferApproveItemDetails = ({
  isShowModalOpen,
  handleOnClose,
  offerObject,
  handleAccept,
  handleReject,
  handleChangeAccept,
  isSended,
}) => {
  const [endDate, setEndDate] = useState(null);
  const [nettoPrice, setNettoPrice] = useState(null);
  const [vatPrice, setVatPrice] = useState(null);
  const [bruttoPrice, setBruttoPrice] = useState(null);
  const [margin, setMargin] = useState(null);

  const { user } = useContext(StoreContext);

  useEffect(() => {
    if (offerObject && Object.keys(offerObject).length > 0) {
      const date = moment(offerObject.created_at)
        .clone()
        .add(Number(offerObject.date), "day")
        .format("DD.MM.YY");
      setEndDate(date);
    }
  }, [offerObject?.date, offerObject?.created_at]);

  useEffect(() => {
    if (offerObject?.price_netto) {
      setNettoPrice(
        Number(offerObject.price_netto)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setNettoPrice(null);
    }

    if (offerObject?.price_vat) {
      setVatPrice(
        Number(offerObject.price_vat)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setVatPrice(null);
    }

    if (offerObject?.price_brutto) {
      setBruttoPrice(
        Number(offerObject.price_brutto)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setBruttoPrice(null);
    }

    if (offerObject?.margin) {
      setMargin(
        Number(offerObject.margin)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setMargin(null);
    }
  }, [
    offerObject?.price_netto,
    offerObject?.brutto_price,
    offerObject?.vat_price,
    offerObject?.margin,
  ]);

  return (
    <Modal
      isModalOpen={isShowModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      {offerObject && (
        <StyledModal>
          <StyledDateWrapper>
            Data utworzenia: {moment(offerObject.created_at).format("DD.MM.YY")}{" "}
            godz.{moment(offerObject.created_at).format("HH:mm")}
          </StyledDateWrapper>
          {endDate && (
            <StyledEndDateWrapper>
              Data wygaśnięcia oferty: {endDate}
            </StyledEndDateWrapper>
          )}

          <StyledPhotovoltaicInstalationDetails>
            <StyledPanelWrapper>
              <StyledTitle>Panele:</StyledTitle>
              <StyledPanelName>
                <div>
                  {offerObject.photovoltaic_instalation?.solar_panel?.name}
                </div>
                <div>{offerObject.solar_panel_count}</div>
              </StyledPanelName>
            </StyledPanelWrapper>

            <StyledInverterWrapper>
              <StyledTitle>Inwerter:</StyledTitle>
              <StyledInverterName>
                <div>
                  {offerObject.photovoltaic_instalation?.inverter?.name}
                </div>
                <div>{offerObject.inverter_count}</div>
              </StyledInverterName>
            </StyledInverterWrapper>

            <StyledConstructionWrapper>
              <StyledTitle>Konstrukcja:</StyledTitle>
              <StyledConstructionName>
                <div>{offerObject.construction?.name}</div>
                <div>{offerObject.construction_count}</div>
              </StyledConstructionName>
            </StyledConstructionWrapper>

            <StyledPowerWrapper>
              <StyledTitle>Moc:</StyledTitle>
              {offerObject.instalaction_power} kWp
            </StyledPowerWrapper>

            <StyledPriceWrapper>
              <StyledTitle>Cena:</StyledTitle>

              {nettoPrice && (
                <StyledPrice>
                  <div>Netto:</div>
                  <div>{nettoPrice}</div>
                </StyledPrice>
              )}
              {vatPrice && (
                <StyledPrice>
                  <div>
                    Vat {offerObject.vat ? <>({offerObject.vat}%)</> : ""}:{" "}
                  </div>
                  <div>{vatPrice}</div>
                </StyledPrice>
              )}
              {bruttoPrice && (
                <StyledPrice>
                  <div>Brutto: </div>
                  <div> {bruttoPrice}</div>
                </StyledPrice>
              )}
              {margin && (
                <StyledPrice style={{ marginTop: "10px" }}>
                  <div>Marża:</div>
                  <div>{margin}</div>
                </StyledPrice>
              )}
            </StyledPriceWrapper>

            {offerObject?.secure ? (
              <StyledSecureWrapper>
                <StyledTitle>Zabezpieczenia:</StyledTitle>
                <StyledSecureInfo>
                  {" "}
                  AC/DC, {offerObject.secure}
                </StyledSecureInfo>
              </StyledSecureWrapper>
            ) : (
              <StyledSecureWrapper>
                <StyledTitle>Zabezpieczenia:</StyledTitle>
                <StyledSecureInfo> AC/DC </StyledSecureInfo>
              </StyledSecureWrapper>
            )}

            {offerObject?.services.length > 0 ||
            (offerObject.cut_length && offerObject.cut_length_price) ||
            (offerObject.wire_length && offerObject.wire_length_price) ? (
              <StyledServicesWrapper>
                <StyledTitle>Usługi dodatkowe:</StyledTitle>
                {Number(offerObject.cut_length) !== 0 &&
                  Number(offerObject.cut_length_price) !== 0 && (
                    <StyledSecureInfo>
                      Przekop {offerObject.cut_length}m -{" "}
                      {offerObject.cut_length_price} PLN
                    </StyledSecureInfo>
                  )}
                {Number(offerObject.wire_length) !== 0 &&
                  Number(offerObject.wire_length_price) !== 0 && (
                    <StyledSecureInfo>
                      Kabel {offerObject.wire_length}m -{" "}
                      {offerObject.wire_length_price} PLN
                    </StyledSecureInfo>
                  )}
                {offerObject?.services &&
                  offerObject.services.map((service) => (
                    <StyledServicesInfo key={service.id}>
                      {service.name} - {service.price} PLN
                    </StyledServicesInfo>
                  ))}
              </StyledServicesWrapper>
            ) : (
              <StyledServicesWrapper>
                <StyledTitle>Usługi dodatkowe:</StyledTitle>
                <StyledServicesInfo>Brak usług dodatkowych</StyledServicesInfo>
              </StyledServicesWrapper>
            )}

            {offerObject.special_discount_manager ? (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Dyrektor:</StyledTitle>
                {offerObject.special_discount_manager} PLN
              </StyledSpecialDiscountWrapper>
            ) : (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Dyrektor:</StyledTitle>
                Brak rabatu specjalnego od Dyrektora
              </StyledSpecialDiscountWrapper>
            )}

            {offerObject.special_discount_management ? (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Zarząd:</StyledTitle>
                {offerObject.special_discount_management} PLN
              </StyledSpecialDiscountWrapper>
            ) : (
              <StyledSpecialDiscountWrapper>
                <StyledTitle>Rabat specjalny Zarząd:</StyledTitle>
                Brak rabatu specjalnego od Zarządu
              </StyledSpecialDiscountWrapper>
            )}

            {offerObject.description ? (
              <StyledDescriptionWrapper>
                <StyledTitle>Uwagi:</StyledTitle>
                {offerObject.description}
              </StyledDescriptionWrapper>
            ) : (
              <StyledDescriptionWrapper>
                <StyledTitle>Uwagi:</StyledTitle>
                Brak uwag
              </StyledDescriptionWrapper>
            )}

            {offerObject?.user && (
              <StyledUserWrapper>
                <StyledTitle>Oferta wygenerowana przez:</StyledTitle>
                <StyledUserLink>{offerObject.user.name}</StyledUserLink>
              </StyledUserWrapper>
            )}

            {Boolean(offerObject.accepted_manager) === true && (
              <StyledAcceptedInformationWrapper>
                {offerObject.accepted_manager_user && (
                  <StyledInformationWrapper>
                    <StyledTitle>Zaakceptowana przez dyrektora:</StyledTitle>
                    {offerObject.accepted_manager_user.name}
                  </StyledInformationWrapper>
                )}
                {offerObject.accepted_manager_date && (
                  <StyledInformationWrapper>
                    <StyledTitle>Data akceptacji przez dyrektora:</StyledTitle>
                    {moment(offerObject.accepted_manager_date).format(
                      "DD.MM.YY HH:mm"
                    )}
                  </StyledInformationWrapper>
                )}
              </StyledAcceptedInformationWrapper>
            )}

            {Boolean(offerObject.rejected_manager) === true && (
              <StyledRejectedInformationWrapper>
                {offerObject.rejected_manager_user && (
                  <StyledInformationWrapper>
                    <StyledTitle>Odrzucona przez dyrektora:</StyledTitle>
                    {offerObject.rejected_manager_user.name}
                  </StyledInformationWrapper>
                )}
                {offerObject.rejected_manager_date && (
                  <StyledInformationWrapper>
                    <StyledTitle>Data odrzucenia przez dyrektora:</StyledTitle>
                    {moment(offerObject.rejected_manager_date).format(
                      "DD.MM.YY HH:mm"
                    )}
                  </StyledInformationWrapper>
                )}
              </StyledRejectedInformationWrapper>
            )}
            {Boolean(offerObject.accepted_management) === true && (
              <StyledAcceptedInformationWrapper>
                {offerObject.accepted_management_user && (
                  <StyledInformationWrapper>
                    <StyledTitle>Zaakceptowana przez zarząd:</StyledTitle>
                    {offerObject.accepted_management_user.name}
                  </StyledInformationWrapper>
                )}
                {offerObject.accepted_management_date && (
                  <StyledInformationWrapper>
                    <StyledTitle>Data akceptacji przez zarząd:</StyledTitle>
                    {moment(offerObject.accepted_management_date).format(
                      "DD.MM.YY HH:mm"
                    )}
                  </StyledInformationWrapper>
                )}
              </StyledAcceptedInformationWrapper>
            )}

            {Boolean(offerObject.rejected_management) === true && (
              <StyledRejectedInformationWrapper>
                {offerObject.rejected_management_user && (
                  <StyledInformationWrapper>
                    <StyledTitle>Odrzucona przez zarząd:</StyledTitle>
                    {offerObject.rejected_management_user.name}
                  </StyledInformationWrapper>
                )}
                {offerObject.rejected_management_date && (
                  <StyledInformationWrapper>
                    <StyledTitle>Data odrzucenia przez zarząd:</StyledTitle>
                    {moment(offerObject.rejected_management_date).format(
                      "DD.MM.YY HH:mm"
                    )}
                  </StyledInformationWrapper>
                )}
              </StyledRejectedInformationWrapper>
            )}

            {offerObject?.file && (
              <StyledFileWrapper>
                <StyledTitle>Oferta:</StyledTitle>
                <StyledFileLink
                  href={`${offerObject.file.path_name}`}
                  download={offerObject.file.name}
                  target="_blank"
                >
                  <StyledFileContainer>
                    <PdfDownload />
                    <StyledFileNameWrapper>
                      <StyledPName title={offerObject.file.name}>
                        {offerObject.file.name}
                      </StyledPName>
                      <StyledFileDescriptionWrapper>
                        <StyledPDescription>PDF</StyledPDescription>
                        <StyledPDescription>
                          {offerObject.file.size
                            ? `${(
                                Number(offerObject.file.size) / 1048576
                              ).toFixed(2)} MB`
                            : ""}
                        </StyledPDescription>
                      </StyledFileDescriptionWrapper>
                    </StyledFileNameWrapper>
                  </StyledFileContainer>
                </StyledFileLink>
              </StyledFileWrapper>
            )}
          </StyledPhotovoltaicInstalationDetails>
          {user?.role.id === 2 ? (
            <>
              {offerObject.accepted_manager === 1 ||
              offerObject.rejected_manager === 1 ? (
                <StyledButtonWrapper>
                  <StyledButtonDisable>Akceptuj</StyledButtonDisable>
                  <StyledButtonDisable>Odrzuć</StyledButtonDisable>
                </StyledButtonWrapper>
              ) : (
                <StyledButtonWrapper>
                  <StyledButton onClick={handleAccept} disabled={isSended}>
                    Akceptuj
                  </StyledButton>
                  <StyledButton
                    onClick={handleChangeAccept}
                    disabled={isSended}
                  >
                    Zmień rabat i akceptuj
                  </StyledButton>
                  <StyledButton onClick={handleReject} disabled={isSended}>
                    Odrzuć
                  </StyledButton>
                </StyledButtonWrapper>
              )}
            </>
          ) : (
            <>
              {offerObject.accepted_management === 1 ||
              offerObject.rejected_management === 1 ? (
                <StyledButtonWrapper>
                  <StyledButtonDisable>Akceptuj</StyledButtonDisable>
                  <StyledButtonDisable>Odrzuć</StyledButtonDisable>
                </StyledButtonWrapper>
              ) : (
                <StyledButtonWrapper>
                  <StyledButton onClick={handleAccept} disabled={isSended}>
                    Akceptuj
                  </StyledButton>
                  <StyledButton
                    onClick={handleChangeAccept}
                    disabled={isSended}
                  >
                    Zmień rabat i akceptuj
                  </StyledButton>
                  <StyledButton onClick={handleReject} disabled={isSended}>
                    Odrzuć
                  </StyledButton>
                </StyledButtonWrapper>
              )}
            </>
          )}
          {isSended && (
            <StyledSendNoteWrapper>
              <StyledSendNoteInnerWrapper>
                Przesyłanie...
                <StyledLoadingImage src={loading} alt="loading" />
              </StyledSendNoteInnerWrapper>
            </StyledSendNoteWrapper>
          )}
        </StyledModal>
      )}
    </Modal>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledInformationWrapper = styled.div`
  margin-top: 10px;
`;
const StyledRejectedInformationWrapper = styled.div`
  margin-top: 10px;
`;
const StyledAcceptedInformationWrapper = styled.div`
  margin-top: 10px;
`;

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 10px 15px;
  }
`;

const StyledButtonDisable = styled.button`
  padding: 0.8rem 1rem;

  border: none;
  background: #cadeee;
  color: #053257;
  cursor: none;
  pointer-events: none;
  border-radius: 5px;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const StyledDescriptionWrapper = styled.div`
  margin-top: 10px;
`;
const StyledSpecialDiscountWrapper = styled.div`
  margin-top: 10px;
`;
const StyledServicesInfo = styled.p``;

const StyledServicesWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPowerWrapper = styled.div`
  margin-top: 10px;
`;

const StyledSecureInfo = styled.p``;

const StyledSecureWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPDescription = styled.p`
  margin-top: 5px;
  margin-right: 5px;
  font-size: 14px;
`;
const StyledPName = styled.p`
  margin-top: 5px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledFileDescriptionWrapper = styled.div`
  display: flex;
`;
const StyledFileNameWrapper = styled.div`
  width: calc(100% - 40px);
  p {
    color: #777;
  }
`;
const StyledFileLink = styled.a`
  text-decoration: none;
  display: block;
`;

const StyledFileContainer = styled.div`
  margin-top: 10px;
  display: flex;
  /* overflow: hidden; */

  svg {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 60px;
  }
`;

const StyledFileWrapper = styled.div`
  margin-top: 10px;
`;

const StyledUserLink = styled.p``;

const StyledUserWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPriceWrapper = styled.div`
  margin-top: 10px;
`;

const StyledEndDateWrapper = styled.div``;

const StyledConstructionWrapper = styled.div`
  margin-top: 10px;
`;

const StyledConstructionName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInverterWrapper = styled.div`
  margin-top: 10px;
`;

const StyledInverterName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPanelWrapper = styled.div``;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledPanelName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPhotovoltaicInstalationDetails = styled.div`
  margin-top: 20px;
`;

const StyledDateWrapper = styled.div``;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledModal = styled.div`
  background-color: white;
`;

export default OfferApproveItemDetails;
