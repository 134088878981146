import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const ToDoTag = ({
  id,
  icon,
  prefix,
  name,
  setValue,
  taskType,
  setTaskType,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    setValue("tasktype_id", id);
    setTaskType(id);
  };

  return (
    <StyledButton
      type="button"
      className={taskType === id ? "active" : ""}
      onClick={handleOnClick}
    >
      <FontAwesomeIcon icon={[prefix, icon]} /> {name}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: block;
  margin: 2px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: white;
  background: #2d96db;
  cursor: pointer;
  &.active {
    color: #a9dcfd;
  }
  &:hover {
    background: #005e9c;
  }
`;
export default ToDoTag;
