import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../helpers/request";
import buildCalendar from "./Components/buildCalendar";
import BusComponent from "./Components/BusComponent";
import DateHeader from "./Components/DateHeader";
import Header from "./Components/Header";
import moment from "moment";
import AssemblySameTimeAlert from "./Components/AssemblySameTimeAlert/AssemblySameTImeAlert";
import AssemblyOpportunityAside from "./Components/AssemblyOpportunityAside/AssemblyOpportunityAside";
import WinOpportunityList from "./Components/WinOpportunityList/WinOpportunityList";

const AssembleComponent = ({ assemblyBus }) => {
  const [value, setValue] = useState(moment());
  const [opportunities, setOpportunities] = useState([]);
  const [changeOpportunities, setChangeOpportunities] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [assemblyBusFilter, setAssemblyBusFilter] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState([]);
  const [sortFilter, setSortFilter] = useState(true);

  const [dayInfo, setDayInfo] = useState(null);
  const [busInfo, setBusInfo] = useState(null);
  const [opportunityInfo, setOpportunityInfo] = useState(null);
  const [durationInfo, setDurationInfo] = useState(null);
  const [duration, setDuration] = useState(0);

  const [isDropped, setIsDropped] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isFingerUp, setIsFingerUp] = useState(false);
  const [elementRightSite, setElementRightSite] = useState(0);
  const [elementWidth, setElementWidth] = useState(0);
  const [cursorPositionX, setCursorPositionX] = useState(0);
  const [isStretch, setStretch] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const location = useLocation();
  const history = useHistory();

  const dropRef = useRef();

  const fetchOpportunities = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(
        `/api/assembly/opportunities?page=${number}`,
        {
          params: {
            department: departmentFilter,
            sort_filter: sortFilter,
          },
        }
      );
      if (status === 200) {
        setOpportunities(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOpportunities(1);
    setPageNumber(1);
  }, [departmentFilter, sortFilter]);

  useEffect(() => {
    if (opportunities === undefined || opportunities.length === 0) {
      fetchOpportunities(1);
    }
  }, []);

  useEffect(() => {
    setCalendar(buildCalendar(value));
  }, [value]);

  useEffect(() => {
    setAssemblyBusFilter(
      assemblyBus.filter((bus) => Boolean(bus.value) === true)
    );
  }, [assemblyBus]);

  const handleOnTouchEnd = (e) => {
    e.stopPropagation();
    if (e.cancelable) {
      e.preventDefault();
    }
    var changedTouch = e.changedTouches[0];
    var elem = document.elementFromPoint(
      changedTouch.clientX,
      changedTouch.clientY
    );

    if (elem === e.target) {
    } else {
      if (elem.getAttribute("data-bus") && elem.getAttribute("data-day")) {
        setBusInfo({ id: Number(elem.getAttribute("data-bus")) });
        setDayInfo(moment(elem.getAttribute("data-day")));
        setIsDropped(true);
      } else {
        setBusInfo({ id: null });
        setDayInfo(null);
        setIsDropped(true);
      }
    }
  };

  const handleOnDragStart = (opportunity, duration, e) => {
    setOpportunityInfo(opportunity);
    setDurationInfo(duration);
    dropRef.current = e.target;
    dropRef.current.addEventListener("dragend", () => setIsDropped(true));
  };
  const handleOnTouchStart = (opportunity, duration, e) => {
    setOpportunityInfo(opportunity);
    setDurationInfo(duration);
  };

  const handleOnDragEnter = (day, bus) => {
    setDayInfo(day);
    setBusInfo(bus);
  };

  const handleOnDragEnterOpportunities = (e) => {};

  const handleOnMouseUp = () => {
    setIsFingerUp(true);
    window.removeEventListener("mousemove", handleOnAssemblyMouseMove, false);
    window.removeEventListener("mouseup", handleOnMouseUp);
  };

  const handleOnAssemblyMouseMove = (e) => {
    setCursorPositionX(e.clientX);
  };

  const handleOnAssemblyFingerMove = (e) => {
    setCursorPositionX(e.touches[0].clientX);
  };

  const handleOnStrechTouchEnd = () => {
    setIsFingerUp(true);
    window.removeEventListener("touchmove", handleOnAssemblyFingerMove, false);
    window.removeEventListener("touchend", handleOnStrechTouchEnd);
  };

  const handleStretchAssemblyItem = (e, width, opportunity) => {
    setElementWidth(width);
    setOpportunityInfo(opportunity);
    setStretch(true);
    setElementRightSite(
      e.target.parentNode.getBoundingClientRect().x +
        e.target.parentNode.getBoundingClientRect().width
    );

    window.addEventListener("mousemove", handleOnAssemblyMouseMove);
    window.addEventListener("mouseup", handleOnMouseUp);
  };

  const handleStretchAssemblyItemTouch = (e, width, opportunity) => {
    setElementWidth(width);
    setOpportunityInfo(opportunity);
    setStretch(true);
    setElementRightSite(
      e.target.parentNode.getBoundingClientRect().x +
        e.target.parentNode.getBoundingClientRect().width
    );

    window.addEventListener("touchmove", handleOnAssemblyFingerMove);
    window.addEventListener("touchend", handleOnStrechTouchEnd);
  };

  const sendData = async (opportunityInfo) => {
    try {
      const { status } = await request.patch(
        `/api/opportunities/update-assembly/${opportunityInfo.id}`,
        {
          bus_id: opportunityInfo["bus_id"],
          assembly: true,
          assembly_start: opportunityInfo["assembly_start"],
          assembly_end: opportunityInfo["assembly_end"],
        }
      );
      if (status === 201) {
        fetchOpportunities();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      } else if (e.response?.status === 409) {
        fetchOpportunities();
        setIsAlertOpen(true);
      }
    }
  };

  const sendDataAssemblyTakeBack = async () => {
    try {
      const { status } = await request.patch(
        `/api/opportunities/update-assembly/${opportunityInfo.id}`,
        {
          bus_id: null,
          assembly: false,
          assembly_start: null,
          assembly_end: null,
        }
      );
      if (status === 201) {
        fetchOpportunities();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (isDropped && busInfo?.id && dayInfo) {
      const oppIndex = changeOpportunities.findIndex(
        (opportunity) => opportunity.id === opportunityInfo.id
      );

      opportunityInfo["bus_id"] = busInfo.id;
      opportunityInfo["assembly"] = true;
      opportunityInfo["assembly_start"] = moment(dayInfo).toLocaleString();
      opportunityInfo["assembly_end"] = moment(dayInfo)
        .add(durationInfo, "days")
        .endOf("day")
        .toLocaleString();
      let changeArray = JSON.parse(JSON.stringify(changeOpportunities));
      changeArray.splice(oppIndex, 1, opportunityInfo);
      setChangeOpportunities(changeArray);
      sendData(opportunityInfo);
      setIsDropped(false);
      setBusInfo(null);
      setDayInfo(null);

      if (dropRef?.current) {
        dropRef.current.removeEventListener("dragend", () =>
          setIsDropped(true)
        );
      }
    }

    if (isDropped && !busInfo?.id && !dayInfo) {
      const oppIndex = changeOpportunities.findIndex(
        (opportunity) => opportunity.id === opportunityInfo.id
      );

      opportunityInfo["bus_id"] = null;
      opportunityInfo["assembly"] = false;
      opportunityInfo["assembly_start"] = null;
      opportunityInfo["assembly_end"] = null;
      let changeArray = JSON.parse(JSON.stringify(changeOpportunities));
      changeArray.splice(oppIndex, 1, opportunityInfo);
      setChangeOpportunities(changeArray);
      sendDataAssemblyTakeBack(opportunityInfo);
      setIsDropped(false);
      setBusInfo(null);
      setDayInfo(null);
      if (dropRef?.current) {
        dropRef.current.removeEventListener("dragend", () =>
          setIsDropped(true)
        );
      }
    }
  }, [dayInfo, busInfo, isDropped, opportunityInfo]);

  useEffect(() => {
    setChangeOpportunities(opportunities);
  }, [opportunities]);

  useEffect(() => {
    if (cursorPositionX > elementRightSite) {
      setElementRightSite((prev) => prev + elementWidth);
      setDuration((prev) => prev + 1);
    }

    if (cursorPositionX < elementRightSite - elementWidth) {
      if (duration > 0) {
        setElementRightSite((prev) => prev - elementWidth);
        setDuration((prev) => prev - 1);
      }
    }
  }, [elementRightSite, elementWidth, cursorPositionX]);

  useEffect(() => {
    if (isStretch) {
      const oppIndex = changeOpportunities.findIndex(
        (opportunity) => opportunity.id === opportunityInfo.id
      );
      opportunityInfo["assembly_end"] = moment(
        opportunityInfo["assembly_start"]
      )
        .clone()
        .add(duration, "day")
        .endOf("day")
        .toLocaleString();
      let changeArray = JSON.parse(JSON.stringify(changeOpportunities));

      changeArray.splice(oppIndex, 1, opportunityInfo);

      setChangeOpportunities(changeArray);
    }
  }, [isStretch, duration]);

  useEffect(() => {
    if (opportunityInfo && isFingerUp === true) {
      sendData(opportunityInfo);

      setStretch(false);
      setElementWidth(0);
      setElementRightSite(0);
      setCursorPositionX(0);
      setIsFingerUp(false);
    }
  }, [opportunityInfo, isFingerUp]);

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledWrapper>
      <Header value={value} setValue={setValue} />
      <StyledBusWrapper>
        <DateHeader calendar={calendar} />
        {assemblyBusFilter.map((bus) => {
          return (
            <BusComponent
              calendar={calendar}
              bus={bus}
              key={bus.id}
              setBusInfo={setBusInfo}
              setDayInfo={setDayInfo}
              handleOnDragEnter={handleOnDragEnter}
              handleOnDragStart={handleOnDragStart}
              handleOnTouchStart={handleOnTouchStart}
              handleStretchAssemblyItem={handleStretchAssemblyItem}
              handleStretchAssemblyItemTouch={handleStretchAssemblyItemTouch}
              handleOnTouchEnd={handleOnTouchEnd}
              setDuration={setDuration}
            />
          );
        })}{" "}
      </StyledBusWrapper>

      <StyledOpportunitiesWrapper
        onDragEnter={() => handleOnDragEnter(null, null)}
        onMouseEnter={() => handleOnDragEnter(null, null)}
      >
        <AssemblyOpportunityAside
          departmentFilter={departmentFilter}
          setDepartmentFilter={setDepartmentFilter}
          sortFilter={sortFilter}
          setSortFilter={setSortFilter}
        />
        <WinOpportunityList
          opportunities={opportunities}
          setBusInfo={setBusInfo}
          setDayInfo={setDayInfo}
          handleOnDragStart={handleOnDragStart}
          handleOnTouchEnd={handleOnTouchEnd}
          fetchOpportunity={fetchOpportunities}
          pageCount={pageCount}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          isLoading={isLoading}
        />
      </StyledOpportunitiesWrapper>
      <AssemblySameTimeAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
      />
    </StyledWrapper>
  );
};

const StyledOpportunitiesWrapper = styled.div`
  padding: 40px 0;
  border-radius: 10px;

  display: flex;
  gap: 10px;

  overflow: hidden;
`;

const StyledBusWrapper = styled.div`
  border-radius: 10px;
  padding: 10px;
  background: white;
  /* overflow: hidden; */
`;
const StyledWrapper = styled.div`
  margin: 0 auto;
  flex: 1;
  padding: 10px;
  border-radius: 10px;
`;

export default AssembleComponent;
