import { faEllipsisH, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import request from "../../../../helpers/request";
import UserItemFile from "./UserItemFile";
import NoteDropDownOptions from "./NoteDropDownOption";
import DeleteAlert from "./DeleteAlert";
import loading from "../../../../img/48x48.gif";
import { useHistory, useLocation } from "react-router-dom";
import { StoreContext } from "../../../../store/StoreProvider";

const UserNoteItem = ({
  note,
  handleOnClickEdit,
  fetchData,
  setEditObject,
  handleOnClickImage,
}) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const { user } = useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/note/${note.id}`);
    if (status === 201) {
      fetchData();
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    setEditObject(note);
    handleOnClickEdit();
  };

  const handleDropDownOptions = (e) => {
    e.preventDefault();
    setIsOpenOptions((prev) => !prev);
  };
  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
    isOpenOptions(false);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  const handleOnDownloadAll = async () => {
    setIsSended(true);
    try {
      const { status, data } = await request.get(
        `api/file/download-all/${note.id}`,
        {
          responseType: "blob",
        }
      );
      if (status === 200) {
        const href = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = href;
        if (note.opportunities?.name) {
          link.setAttribute(
            "download",
            `${note.opportunities.name.replaceAll(" ", "_")}.zip`
          ); //or any other extension
        } else {
          link.setAttribute("download", `AllFiles.zip`); //or any other extension
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  return (
    <StyledNoteItem>
      <StyledHeadWrapper>
        <StyledUserAvatar>
          <FontAwesomeIcon icon={faUser} />
        </StyledUserAvatar>
        <StyledHeadContent>
          <p>{note.user.name}</p>
        </StyledHeadContent>
        <StyledCollapseMenu>
          <button onClick={handleDropDownOptions} type="button">
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        </StyledCollapseMenu>
        <NoteDropDownOptions
          isOpen={isOpenOptions}
          setIsOpenOptions={setIsOpenOptions}
          handleOnOpenAlert={handleOnOpenAlert}
          handleOnClick={handleOnEdit}
          handleOnDownloadAll={handleOnDownloadAll}
          user={user}
          note={note}
        />
      </StyledHeadWrapper>
      <StyledContentWrapper>
        <p>{note.text ? note.text : "Załączył(a) plik(i)"}</p>
        <StyledItemContentWrapper>
          {note.files &&
            note.files.map((file) => (
              <UserItemFile
                key={file.id}
                file={file}
                setIsSended={setIsSended}
              />
            ))}
        </StyledItemContentWrapper>
      </StyledContentWrapper>
      {isSended && (
        <StyledSendNoteWrapper>
          <StyledSendNoteInnerWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledSendNoteInnerWrapper>
        </StyledSendNoteWrapper>
      )}
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnDelete={handleOnDelete}
        handleOnClose={handleOnCloseAlert}
      />
    </StyledNoteItem>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledCollapseMenu = styled.div`
  button {
    padding: 1rem;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 10px;
  right: 15px;
`;
const StyledHeadContent = styled.div`
  flex: 1;
`;

const StyledContentWrapper = styled.div`
  margin-top: 10px;
`;
const StyledItemContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledUserAvatar = styled.div`
  width: 40px;
  height: 40px;
  background: #d1d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const StyledHeadWrapper = styled.div`
  display: flex;
`;
const StyledNoteItem = styled.div`
  background-color: white;
  padding: 10px;
  margin: 10px 0;
  position: relative;
`;

export default UserNoteItem;
