import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import DateFiltersItem from "./DateFiltersItem";

const showItem = [
  {
    id: 1,
    name: "Ten tydzień",
  },
  {
    id: 2,
    name: "Ten miesiąc",
  },
  {
    id: 3,
    name: "Poprzedni miesiąc",
  },
  {
    id: 4,
    name: "Ostatnie 7 dni",
  },
  {
    id: 5,
    name: "Ostatnie 30 dni",
  },
  {
    id: 6,
    name: "Ten rok",
  },
  {
    id: 7,
    name: "Poprzedni rok",
  },
];

const DateFilters = ({ setDateFilters, dateFilters }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState(true);

  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <StyledCollapse>
      <StyledCollapseButton onClick={handleOnClick}>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
        <StyledCollapseTitle>Okres</StyledCollapseTitle>
      </StyledCollapseButton>
      <StyledCollapseItemWrapper
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        {showItem.map((element) => {
          return (
            <DateFiltersItem
              dateFilters={dateFilters}
              setDateFilters={setDateFilters}
              id={element.id}
              name={element.name}
              key={element.id}
            />
          );
        })}
      </StyledCollapseItemWrapper>
    </StyledCollapse>
  );
};

const StyledCollapseItemWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const StyledCollapseTitle = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledCollapseButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 0 10px;
  border: none;
  background: white;
  display: flex;
  align-items: center;
`;

const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px 0;
  overflow: hidden;
`;

export default DateFilters;
