import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
body{
    background:#F2EFF6;
    font-family: 'ubuntu', sans-serif;
    overflow-x:hidden;
}
button{
  font-family: 'ubuntu', sans-serif;
}
textarea {
  font-family: 'ubuntu', sans-serif;
  font-size: 14px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #444;
  -webkit-text-fill-color: #444;
  box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
  font-family: 'ubuntu', sans-serif;
}


`;

export default GlobalStyle;
