import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import request from "../../../../../helpers/request";
import { useLocation, useHistory } from "react-router-dom";
import { StoreContext } from "../../../../../store/StoreProvider";

const OpportunitiesAssembly = ({
  opportunities,
  setIsAssemblyModalOpen,
  setIsAssemblyEditMode,
  setAssemblyEditObject,
  fetchData,
}) => {
  const [notSameDate, setNotSameDate] = useState(true);
  const { user } = useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setNotSameDate(
      !moment(opportunities.assembly_start).isSame(
        moment(opportunities.assembly_end),
        "day"
      )
    );
  }, [opportunities]);

  const handleOnClickOpen = () => {
    setIsAssemblyModalOpen(true);
    setAssemblyEditObject(opportunities);
  };

  const handleOnClickEditOpen = () => {
    setIsAssemblyModalOpen(true);
    setIsAssemblyEditMode(true);
    setAssemblyEditObject(opportunities);
  };

  const handleOnClickDelete = async () => {
    try {
      const { status } = await request.patch(
        `/api/opportunities/update-assembly/${opportunities.id}`,
        {
          assembly: false,
          assembly_start: null,
          assembly_end: null,
          bus_id: null,
        }
      );

      if (status === 201) {
        fetchData();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  return (
    <StyledAssemblyWrapper>
      <StyledHeaderText>Data montażu</StyledHeaderText>

      {Boolean(opportunities.assembly) && (
        <StyledAssemblyInfo>
          {notSameDate ? (
            <StyledAssemblyDate>
              <StyledAssemblyDateTitle>Data:</StyledAssemblyDateTitle>

              {moment(opportunities.assembly_start).format("DD.MM.YY")}
              {"-"}
              {moment(opportunities.assembly_end).format("DD.MM.YY")}
            </StyledAssemblyDate>
          ) : (
            <StyledAssemblyDate>
              <StyledAssemblyDateTitle>Data:</StyledAssemblyDateTitle>
              {moment(opportunities.assembly_start).format("DD.MM.YY")}
            </StyledAssemblyDate>
          )}
          {opportunities.bus && (
            <StyledBusInfo>Bus: {opportunities.bus?.name}</StyledBusInfo>
          )}
        </StyledAssemblyInfo>
      )}
      {(user.role?.id === 1 || user.role?.id === 3) && (
        <StyledAssemblyButtonWrapper>
          {opportunities.assembly ? (
            <StyledButtonWrapper>
              <StyledAssemblyButton onClick={handleOnClickEditOpen}>
                Edytuj
              </StyledAssemblyButton>
              <StyledAssemblyButton onClick={handleOnClickDelete}>
                Usuń
              </StyledAssemblyButton>
            </StyledButtonWrapper>
          ) : (
            <StyledAssemblyButton onClick={handleOnClickOpen}>
              Dodaj
            </StyledAssemblyButton>
          )}
        </StyledAssemblyButtonWrapper>
      )}
    </StyledAssemblyWrapper>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBusInfo = styled.div``;

const StyledAssemblyDateTitle = styled.p`
  margin-right: 5px;
`;

const StyledAssemblyDate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledAssemblyInfo = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.4);
`;

const StyledAssemblyButton = styled.button`
  padding: 0.8rem 1rem;
  background: #134771;
  border: none;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;
const StyledAssemblyButtonWrapper = styled.div`
  padding: 10px;
`;
const StyledAssemblyWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
`;
const StyledHeaderText = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;
export default OpportunitiesAssembly;
