import React from "react";
import styled from "styled-components";
import CommentItem from "./Components/CommentItem";

const CommentsList = ({ comments }) => {
  return (
    <StyledWrapper>
      {comments &&
        comments.map((comment) => (
          <CommentItem comment={comment} key={comment.id} />
        ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;

export default CommentsList;
