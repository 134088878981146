import {
  faEllipsisH,
  faEnvelope,
  faPhoneAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../helpers/request.js";
import { StoreContext } from "../../../../../store/StoreProvider.js";
import CompanyDropDownOptions from "./CompanyDropDownOptions";
import DeleteAlert from "./DeleteAlert.jsx";

const CompanysItem = ({
  company,
  fetchData,
  handleOnClickEdit,
  setEditObject,
}) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const { user } = useContext(StoreContext);
  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/companies/${company.id}`);
    if (status === 201) {
      fetchData();
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    setEditObject(company);
    handleOnClickEdit();
  };

  const handleDropDownOptions = (e) => {
    e.preventDefault();
    setIsOpenOptions(!isOpenOptions);
  };

  const handleOnAlertOpen = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };

  const handleOnAlertClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  return (
    <StyledWrapper>
      <StyledInfo>
        <StyledTitle>
          <StyledNameLink
            to={`/dashboard/contacts/companies/details/${company.id}`}
          >
            {company.name}
          </StyledNameLink>
        </StyledTitle>
        <StyledCompanyInfo>
          {company.phone && (
            <>
              <StyledPhoneIcon icon={faPhoneAlt} />
              <StyledLink href={`tel:${company.phone}`}>
                {company.phone}
              </StyledLink>
            </>
          )}

          {company.email && (
            <>
              <StyledMailIcon icon={faEnvelope} />
              <StyledLink href={`mailto:${company.email}`}>
                {company.email}
              </StyledLink>
            </>
          )}
        </StyledCompanyInfo>
      </StyledInfo>
      {company.maintainer?.department ? (
        <StyledMaintainer>
          <FontAwesomeIcon icon={faUser} />{" "}
          {company.maintainer?.department.name}
        </StyledMaintainer>
      ) : (
        <StyledMaintainer>
          <FontAwesomeIcon icon={faUser} />{" "}
          {company.maintainer?.role?.id === 1 &&
            `Zarząd - ${company.maintainer?.name}`}
          {company.maintainer?.role?.id === 3 &&
            `Biuro - ${company.maintainer?.name}`}
        </StyledMaintainer>
      )}
      <StyledActions>
        <StyledAction>
          Notatki( {company.notes && company.notes.length} )
        </StyledAction>
        <StyledAction>
          Szanse sprzedaży({" "}
          {company.opportunities && company.opportunities.length} )
        </StyledAction>
      </StyledActions>
      <StyledCollapseMenu>
        <button onClick={handleDropDownOptions} type="button">
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </StyledCollapseMenu>
      <CompanyDropDownOptions
        companyId={company.id}
        isOpen={isOpenOptions}
        setIsOpenOptions={setIsOpenOptions}
        handleOnAlertOpen={handleOnAlertOpen}
        handleOnClick={handleOnEdit}
        user={user}
        companyUser={company.maintainer}
      />
      <DeleteAlert
        handleOnDelete={handleOnDelete}
        handleOnClose={handleOnAlertClose}
        isAlertOpen={isAlertOpen}
      />
    </StyledWrapper>
  );
};
const StyledNameLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    color: #777;
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
const StyledAction = styled.p`
  margin: 0 15px;
  color: black;
  font-size: 14px;
`;

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledTitle = styled.div`
  margin-bottom: 10px;
`;

const StyledCompanyInfo = styled.div`
  display: flex;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const StyledMaintainer = styled.div`
  margin-top: 15px;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const StyledPhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
const StyledMailIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledInfo = styled.div``;

const StyledCollapseMenu = styled.div`
  button {
    padding: 1rem;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 10px;
  right: 15px;

  @media screen and (max-width: 768px) {
    top: 5px;
    right: 10px;
    button {
      font-size: 16px;
      padding: 10px;
    }
  }
`;
const StyledWrapper = styled.div`
  background: white;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 10px;
`;
export default CompanysItem;
