import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import styled from "styled-components";
import request from "../../../../../../helpers/request";
import DeleteAlert from "./DeleteAlert";

const ChargerAccessoriesItem = ({
  chargerAccessorie,
  handleOpenEdit,
  setEditObject,
  fetchCharger,
}) => {
  const { id, name, price } = chargerAccessorie;
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleOnEdit = () => {
    setEditObject(chargerAccessorie);
    handleOpenEdit();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/charger-accessories/${id}`);
    if (status === 200) {
      fetchCharger();
      setIsAlertOpen(false);
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledTaskTypeItem>
      <StyledDescription>
        <p>{name}</p>
      </StyledDescription>
      <StyledDescription>
        <p>{price}</p>
      </StyledDescription>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.tr``;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.td``;

const StyledButtonGroup = styled.td`
  text-align: right;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
export default ChargerAccessoriesItem;
