import React, { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import request from "../../../../../helpers/request";
import Modal from "../../../../../components/Modal";

const validationSchema = yup.object().shape({});

const AddLabour = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: { name: "" },
  });

  const location = useLocation();
  const history = useHistory();
  const [isSended, setIsSended] = useState(false);

  const resetInputValue = () => {
    reset({
      name: "",
      price: "",
      margin: "",
      price_per_kWp: "",
    });
  };

  const sendData = async (data) => {
    setIsSended(true);
    try {
      if (isEditMode) {
        const { status } = await request.patch(`/api/labour/${editObject.id}`, {
          ...data,
        });
        if (status === 200) {
          fetchData();
          handleOnClose();
          resetInputValue();
        }
      } else {
        const { status } = await request.post("/api/labour", {
          ...data,
        });
        if (status === 201) {
          fetchData();
          handleOnClose();
          resetInputValue();
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (editObject) {
      setValue("name", editObject.name);
      setValue("price", editObject.price);
      setValue("price_per_kWp", editObject.price_per_kWp);
      setValue("margin", editObject.margin);
    } else {
      setValue("name", "");
      setValue("price", "");
      setValue("price_per_kWp", "");
      setValue("margin", "");
    }
  }, [editObject]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>

        <StyledInput>
          <div className="input-group">
            <label>Nazwa</label>
            <input type="text" {...register("name")} />
          </div>
        </StyledInput>
        {errors?.name && <span className="error">{errors.name.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Cena za usługę</label>
            <input type="number" {...register("price")} />
          </div>
        </StyledInput>
        {errors?.price && <span className="error">{errors.price.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Cena usługi za 1kWp </label>
            <input type="number" {...register("price_per_kWp")} />
          </div>
        </StyledInput>
        {errors?.price_per_kWp && (
          <span className="error">{errors.price_per_kWp.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Marża</label>
            <input type="number" {...register("margin")} />
          </div>
        </StyledInput>
        {errors?.margin && (
          <span className="error">{errors.margin.message}</span>
        )}

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            {isEditMode ? "Zapisz" : "Dodaj"}
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 360px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #2d96db;
        background: transparent;
      }
      &.close:hover {
        color: black;
      }
      &.add {
        background: #134771;
        color: white;
      }
      &.add:hover {
        background: #005e9c;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;
const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddLabour;
