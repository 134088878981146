import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../../../components/Modal";

import request from "../../../../../../../../../helpers/request";
import { StoreContext } from "../../../../../../../../../store/StoreProvider";

const validationSchema = yup.object({});
const GenerateContract = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  opportunities,
  offerObject,
}) => {
  const { user } = useContext(StoreContext);
  const [paymentType, setPaymentType] = useState(1);
  const [vatRateType, setVatRateType] = useState(1);

  const [isSended, setIsSended] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const { register, handleSubmit, reset, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({});
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.post(`api/make-heat-pump-contract`, {
        ...data,
      });
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (opportunities) {
      if (opportunities.company) {
        setValue("is_company", 1);
      } else setValue("is_company", 0);

      setValue("investor_name_1", opportunities.owner_details?.investor_name_1);
      setValue(
        "investor_pesel_1",
        opportunities.owner_details?.investor_pesel_1
      );
      setValue(
        "investor_phone_1",
        opportunities.owner_details?.investor_phone_1
      );
      setValue(
        "investor_id_card_1",
        opportunities.owner_details?.investor_id_card_1
      );
      setValue(
        "investor_email_1",
        opportunities.owner_details?.investor_email_1
      );
      setValue("proxy_street", opportunities.owner_details?.street);
      setValue("proxy_city", opportunities.owner_details?.city);
      setValue(
        "proxy_building_number",
        opportunities.owner_details?.building_number
      );
      setValue("proxy_post", opportunities.owner_details?.post);
      setValue("proxy_post_code", opportunities.owner_details?.post_code);

      setValue("investor_name_2", opportunities.owner_details?.investor_name_2);
      setValue(
        "investor_pesel_2",
        opportunities.owner_details?.investor_pesel_2
      );
      setValue(
        "investor_phone_2",
        opportunities.owner_details?.investor_phone_2
      );
      setValue(
        "investor_id_card_2",
        opportunities.owner_details?.investor_id_card_2
      );
      setValue(
        "investor_email_2",
        opportunities.owner_details?.investor_email_2
      );
      setValue("investor_NIP_1", opportunities.owner_details?.investor_NIP_1);
      setValue("investor_regon", opportunities.owner_details?.investor_regon);

      setValue("opportunities_id", opportunities.id);
      setValue("investor_street", opportunities.property_details?.street);
      setValue(
        "investor_building_number",
        opportunities.property_details?.building_number
      );
      setValue("investor_post_code", opportunities.property_details?.post_code);
      setValue("investor_post", opportunities.property_details?.post);
      setValue("investor_city", opportunities.property_details?.city);
      setValue("investor_community", opportunities.property_details?.community);
      setValue("investor_district", opportunities.property_details?.district);

      setValue(
        "usable_area",
        opportunities.heat_pump_terms_details?.usable_area
      );
      setValue(
        "vat_rate_type",
        opportunities.heat_pump_terms_details?.vat_rate_type
      );
      setVatRateType(
        opportunities.heat_pump_terms_details?.vat_rate_type
          ? Number(opportunities.heat_pump_terms_details?.vat_rate_type)
          : 1
      );
      setValue(
        "payment_type",
        Number(opportunities.heat_pump_terms_details?.payment_type)
      );
      setValue(
        "realization_date",
        opportunities.heat_pump_terms_details?.realization_date
          ? Number(opportunities.heat_pump_terms_details?.realization_date)
          : 10
      );
      setPaymentType(
        opportunities.heat_pump_terms_details?.payment_type
          ? Number(opportunities.heat_pump_terms_details?.payment_type)
          : 1
      );
      if (offerObject) {
        if (Number(opportunities.heat_pump_terms_details?.payment_type) === 1) {
          setValue("loan_value", offerObject.price_brutto);
          setValue("loan_brutto_price", null);
          setValue("loan_value_deposit", null);
          setValue("loan_self_deposit");
          setValue("lease_netto_price", null);
          setValue("lease_brutto_price", null);
          setValue("brutto_price", null);
          setValue("first_tranche_percentage", null);
          setValue("first_tranche_brutto_price", null);
          setValue("second_tranche_percentage", null);
          setValue("second_tranche_brutto_price", null);
          setValue("third_tranche_percentage", null);
          setValue("third_tranche_brutto_price", null);
        } else if (
          Number(opportunities.heat_pump_terms_details?.payment_type) === 2
        ) {
          setValue("loan_brutto_price", Number(offerObject.price_brutto));
          setValue(
            "loan_value_deposit",
            Number(offerObject.price_brutto) -
              Number(opportunities.heat_pump_terms_details?.loan_self_deposit)
          );
          setValue(
            "loan_self_deposit",
            Number(opportunities.heat_pump_terms_details?.loan_self_deposit)
          );
          setValue("loan_value", null);
          setValue("lease_netto_price", null);
          setValue("lease_brutto_price", null);
          setValue("brutto_price", null);
          setValue("first_tranche_percentage", null);
          setValue("first_tranche_brutto_price", null);
          setValue("second_tranche_percentage", null);
          setValue("second_tranche_brutto_price", null);
          setValue("third_tranche_percentage", null);
          setValue("third_tranche_brutto_price", null);
        } else if (
          Number(opportunities.heat_pump_terms_details?.payment_type) === 3
        ) {
          setValue("lease_netto_price", offerObject.price_netto);
          setValue("lease_brutto_price", Number(offerObject.price_brutto));
          setValue("loan_value", null);
          setValue("loan_brutto_price", null);
          setValue("loan_value_deposit", null);
          setValue("loan_self_deposit");
          setValue("brutto_price", null);
          setValue("first_tranche_percentage", null);
          setValue("first_tranche_brutto_price", null);
          setValue("second_tranche_percentage", null);
          setValue("second_tranche_brutto_price", null);
          setValue("third_tranche_percentage", null);
          setValue("third_tranche_brutto_price", null);
        } else if (
          Number(opportunities.heat_pump_terms_details?.payment_type) === 4
        ) {
          setValue("loan_value", null);
          setValue("lease_netto_price", null);
          setValue("lease_brutto_price", null);
          setValue("loan_brutto_price", null);
          setValue("loan_value_deposit", null);

          setValue("loan_self_deposit");
          setValue("brutto_price", null);
          setValue("brutto_price", offerObject.price_brutto);
          setValue(
            "first_tranche_percentage",
            Number(
              opportunities.heat_pump_terms_details?.first_tranche_percentage
            )
          );
          setValue(
            "first_tranche_brutto_price",
            (
              (Number(offerObject.price_brutto) *
                Number(
                  opportunities.heat_pump_terms_details
                    ?.first_tranche_percentage
                )) /
              100
            ).toFixed(2)
          );
          setValue(
            "second_tranche_percentage",
            Number(
              opportunities.heat_pump_terms_details?.second_tranche_percentage
            )
          );
          setValue(
            "second_tranche_brutto_price",
            (
              (Number(offerObject.price_brutto) *
                Number(
                  opportunities.heat_pump_terms_details
                    ?.second_tranche_percentage
                )) /
              100
            ).toFixed(2)
          );
          setValue(
            "third_tranche_percentage",
            Number(
              opportunities.heat_pump_terms_details?.third_tranche_percentage
            )
          );
          setValue(
            "third_tranche_brutto_price",
            (
              (Number(offerObject.price_brutto) *
                Number(
                  opportunities.heat_pump_terms_details
                    ?.third_tranche_percentage
                )) /
              100
            ).toFixed(2)
          );
        }
      }

      setValue("services", offerObject?.services);
      setValue("secure", offerObject?.secure);
      setValue("offer_id", offerObject?.id);
      setValue(
        "offer_number",
        `${offerObject?.offer_number}/${moment(offerObject?.created_at).format(
          "YYYY"
        )}`
      );
    }
    if (offerObject?.heat_pump) {
      setValue(
        "heat_pump_name",
        `${offerObject.heat_pump.brand} ${offerObject.heat_pump.model}
  ${offerObject.heat_pump.power} kW ${offerObject.heat_pump.type}`
      );

      setValue("heat_pump_id", offerObject.heat_pump.id);
      setValue("heat_pump_brand", offerObject.heat_pump.brand);
      setValue("heat_pump_model", offerObject.heat_pump.model);
      setValue("heat_pump_power", offerObject.heat_pump.power);
      setValue("heat_pump_type", offerObject.heat_pump.type);
      setValue("heat_pump_count", offerObject.heat_pump_count);
    }
    if (offerObject?.hot_water_tank) {
      setValue(
        "hot_water_tank",
        `${offerObject.hot_water_tank.name} ${offerObject.hot_water_tank.capacity} L`
      );
      setValue("hot_water_tank_id", offerObject.hot_water_tank.id);
      setValue("hot_water_tank_name", offerObject.hot_water_tank.name);
      setValue("hot_water_tank_capacity", offerObject.hot_water_tank.capacity);
      setValue("hot_water_tank_count", offerObject.hot_water_tank_count);
    }
    setValue("flushing_system", offerObject?.flushing_system);
    setValue("leaving_existing_source", offerObject?.leaving_existing_source);
    setValue("wireless_controller", offerObject?.wireless_controller);
  }, [user, opportunities, offerObject]);

  useEffect(() => {
    if (opportunities.owner_details) {
      if (
        opportunities?.owner_details.city &&
        opportunities?.owner_details.building_number &&
        opportunities?.owner_details.post_code &&
        opportunities?.owner_details.post &&
        opportunities?.owner_details.street
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street} ${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.city} ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.post}`
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.building_number &&
        opportunities?.owner_details?.post &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `${opportunities?.owner_details?.city} ${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.post} `
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.street &&
        opportunities?.owner_details?.post &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street} ${opportunities?.owner_details?.city}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.post}`
        );
      } else if (
        opportunities?.owner_details.city &&
        opportunities?.owner_details.building_number &&
        opportunities?.owner_details.post_code &&
        opportunities?.owner_details.street
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street} ${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.city}`
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.building_number &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.city} `
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.street &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.city}`
        );
      } else {
        setValue("client_address", null);
      }
    }

    if (opportunities?.property_details) {
      if (
        opportunities?.property_details.city &&
        opportunities?.property_details.building_number &&
        opportunities?.property_details.post_code &&
        opportunities?.property_details.post &&
        opportunities?.property_details.street
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street} ${opportunities?.property_details?.building_number} ${opportunities?.property_details?.city}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.post}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.building_number &&
        opportunities?.property_details?.property_details &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `${opportunities?.property_details?.building_number} ${opportunities?.property_details?.city} ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.post}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.street &&
        opportunities?.property_details?.post &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street} ${opportunities?.property_details?.city}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.post}`
        );
      } else if (
        opportunities?.property_details.city &&
        opportunities?.property_details.building_number &&
        opportunities?.property_details.post_code &&
        opportunities?.property_details.street
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street} ${opportunities?.property_details?.building_number}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.city}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.building_number &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `${opportunities?.property_details?.post_code} ${opportunities?.property_details?.city} ${opportunities?.property_details?.building_number}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.street &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.city}`
        );
      } else {
        setValue("montage_address", null);
      }
    }
  }, [opportunities.property_details, isModalOpen]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <input type="hidden" {...register("opportunity_id")} />
        <input type="hidden" {...register("offer_number")} />
        <input type="hidden" {...register("offer_id")} />
        {opportunities.company ? (
          <StyledTitleWrapper>Dane firmy</StyledTitleWrapper>
        ) : (
          <StyledTitleWrapper>Dane właściciela</StyledTitleWrapper>
        )}
        <StyledInput>
          <div className="input-group">
            {opportunities.company ? (
              <label>Nazwa firmy</label>
            ) : (
              <label>Imię i nazwisko</label>
            )}
            <input type="text" {...register("investor_name_1")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Email</label>
            <input type="text" {...register("investor_email_1")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input type="text" {...register("investor_phone_1")} readOnly />
          </div>
        </StyledInput>
        {!opportunities.company && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Seria i numer dowodu osobistego</label>
                <input
                  type="text"
                  {...register("investor_id_card_1")}
                  readOnly
                />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Pesel</label>
                <input type="text" {...register("investor_pesel_1")} readOnly />
              </div>
            </StyledInput>
          </>
        )}
        {opportunities.company && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>NIP</label>
                <input type="text" {...register("investor_NIP_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Numer KRS</label>
                <input type="text" {...register("investor_krs")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>REGON</label>
                <input type="text" {...register("investor_regon")} readOnly />
              </div>
            </StyledInput>
          </>
        )}

        <StyledTitleWrapper>Adres właściciela</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input type="text" {...register("proxy_street")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("proxy_building_number")}
              readOnly
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input type="text" {...register("proxy_post_code")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input type="text" {...register("proxy_post")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input type="text" {...register("proxy_city")} readOnly />
          </div>
        </StyledInput>

        {opportunities.company ? (
          <StyledTitleWrapper>Dane właściciela</StyledTitleWrapper>
        ) : (
          <StyledTitleWrapper>Dane współwłaściciela</StyledTitleWrapper>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Imię i nazwisko</label>
            <input type="text" {...register("investor_name_2")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input type="text" {...register("investor_phone_2")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Seria i numer dowodu osobistego</label>
            <input type="text" {...register("investor_id_card_2")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Pesel</label>
            <input type="text" {...register("investor_pesel_2")} readOnly />
          </div>
        </StyledInput>
        <StyledTitleWrapper>Dane nieruchomości</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input type="text" {...register("investor_street")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("investor_building_number")}
              readOnly
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input type="text" {...register("investor_post_code")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input type="text" {...register("investor_post")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input type="text" {...register("investor_city")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Gmina</label>
            <input type="text" {...register("investor_community")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Powiat</label>
            <input type="text" {...register("investor_district")} readOnly />
          </div>
        </StyledInput>

        <StyledTitleWrapper>Szczegóły oferty:</StyledTitleWrapper>

        <StyledInput>
          <div className="input-group">
            <label>Pompa ciepła:</label>
            <input type="text" {...register("heat_pump_name")} readOnly />
            <input type="hidden" {...register("heat_pump_id")} />
            <input type="hidden" {...register("heat_pump_brand")} />
            <input type="hidden" {...register("heat_pump_model")} />
            <input type="hidden" {...register("heat_pump_power")} />
            <input type="hidden" {...register("heat_pump_type")} />
            <input type="hidden" {...register("heat_pump_count")} />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Zasobnik CWU:</label>
            <input type="text" {...register("hot_water_tank")} readOnly />
            <input type="hidden" {...register("hot_water_tank_id")} />
            <input type="hidden" {...register("hot_water_tank_name")} />
            <input type="hidden" {...register("hot_water_tank_capacity")} />
            <input type="hidden" {...register("hot_water_tank_count")} />
            <input type="hidden" {...register("flushing_system")} />
            <input type="hidden" {...register("wireless_controller")} />
            <input type="hidden" {...register("leaving_existing_source")} />
          </div>
        </StyledInput>
        <StyledTitleWrapper>Szczegóły płatności:</StyledTitleWrapper>
        {!opportunities.company && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>
                  Powierzchnia użytkowa budynku (M<sup>2</sup>):
                </label>
                <input type="text" {...register("usable_area")} readOnly />
              </div>
            </StyledInput>{" "}
            <StyledInput>
              <div className="input-group">
                <label>
                  Inwestor oświadcza, że montaż Instalacji ma miejsce na:
                </label>
                <input type="hidden" {...register("vat_rate_type")} />
                <StyledOptionWrapper>
                  {vatRateType === 1 && (
                    <StyledOptionItem
                      title="budynku mieszkalnym o powierzchni użytkowej do 300 m2 — stawka
                      należnego podatku VAT wynosi 8%."
                    >
                      budynku mieszkalnym o powierzchni użytkowej do 300 m2 —
                      stawka należnego podatku VAT wynosi 8%..
                    </StyledOptionItem>
                  )}
                  {vatRateType === 2 && (
                    <StyledOptionItem
                      title="budynku mieszkalnym o powierzchni użytkowej pow. 300 m2 — stawka
                      należnego podatku VAT wynosi 23%."
                    >
                      budynku mieszkalnym o powierzchni użytkowej pow. 300 m2 —
                      stawka należnego podatku VAT wynosi 23%.
                    </StyledOptionItem>
                  )}
                  {vatRateType === 3 && (
                    <StyledOptionItem
                      title="innym typie budynku lub gruncie — dla klienta indywidualnego —
                      stawka należnego podatku VAT wynosi 8%."
                    >
                      innym typie budynku lub gruncie — dla klienta
                      indywidualnego — stawka należnego podatku VAT wynosi 8%.
                    </StyledOptionItem>
                  )}
                </StyledOptionWrapper>
              </div>
            </StyledInput>
          </>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Rodzaj płatności:</label>
            <input type="hidden" {...register("payment_type")} />
            <StyledOptionWrapper>
              {paymentType === 1 && (
                <StyledOptionItem>Płatność kredytem</StyledOptionItem>
              )}
              {paymentType === 2 && (
                <StyledOptionItem>
                  Płatność kredytem z wkładem własnym
                </StyledOptionItem>
              )}
              {paymentType === 3 && (
                <StyledOptionItem>Płatność leasing</StyledOptionItem>
              )}
              {paymentType === 4 && (
                <StyledOptionItem>
                  Płatność ze środków własnych
                </StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
          <input type="hidden" />
        </StyledInput>
        {paymentType === 1 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Wartość kredytu (zł)</label>
                <input type="text" {...register("loan_value")} readOnly />
              </div>{" "}
            </StyledInput>
          </>
        )}
        {paymentType === 2 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Wartość brutto oferty (zł)</label>
                <input
                  type="text"
                  {...register("loan_brutto_price")}
                  readOnly
                />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Wartość kredytu (zł)</label>
                <input
                  type="text"
                  {...register("loan_value_deposit")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Wkład własny (zł)</label>
                <input
                  type="number"
                  {...register("loan_self_deposit")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}
        {paymentType === 3 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Cena netto leasingu (zł)</label>
                <input
                  type="text"
                  {...register("lease_netto_price")}
                  readOnly
                />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Cena brutto leasingu (zł)</label>
                <input
                  type="text"
                  {...register("lease_brutto_price")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}
        {paymentType === 4 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Cena brutto (zł)</label>
                <input type="text" {...register("brutto_price")} readOnly />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Pierwsza transza (%)</label>
                <input
                  type="number"
                  {...register("first_tranche_percentage")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Pierwsza transza cena brutto (zł)</label>
                <input
                  type="text"
                  {...register("first_tranche_brutto_price")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Druga transza (%)</label>
                <input
                  type="number"
                  {...register("second_tranche_percentage")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Druga transza cena brutto (zł)</label>
                <input
                  type="text"
                  {...register("second_tranche_brutto_price")}
                  readOnly
                />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Trzecia transza (%)</label>
                <input
                  type="number"
                  {...register("third_tranche_percentage")}
                  readOnly
                />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Trzecia transza cena brutto (zł)</label>
                <input
                  type="text"
                  {...register("third_tranche_brutto_price")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}
        <input type="hidden" {...register("montage_address")} />
        <input type="hidden" {...register("client_address")} />
        <input type="hidden" {...register("is_company")} />
        <input type="hidden" {...register("services")} />
        <input type="hidden" {...register("secure")} />
        <input type="hidden" {...register("contract_number")} />
        <StyledTitleWrapper>Ustalenia terminu </StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Termin montażu (tyg.)</label>
            <input type="text" {...register("realization_date")} readOnly />
          </div>
        </StyledInput>

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            disabled={isSended}
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Generuj umowę
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledOptionItem = styled.div`
  margin-left: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledOptionWrapper = styled.div`
  font-size: 14px;
  min-width: 0;
  border-bottom: 1px solid black;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 14px;
`;

const StyledForm = styled.form`
  max-width: 600px;
  min-width: 360px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    min-width: 0;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default GenerateContract;
