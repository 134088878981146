import React, { useState } from "react";
import request from "../../../../../../helpers/request";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faIdCard,
  faEllipsisH,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import DeleteAlert from "../DeleteAlert";
import moment from "moment";

const LeadsItem = ({ lead, fetchLeads, handleOnClickEdit, setEditObject }) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleDropDownOptions = (e) => {
    e.preventDefault();
    setIsOpenOptions(!isOpenOptions);
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/lead/${lead.id}`);
    if (status === 201) {
      fetchLeads(1);
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    setEditObject(lead);
    handleOnClickEdit();
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };
  return (
    <StyledWrapper>
      <StyledLeadWrapper>
        <StyledStatusWrapper>
          <StyledLeadInfoItem>
            {lead.contact_the_lead ? (
              <StyledYesSpanBig title="Kontakt wykonany">
                <FontAwesomeIcon icon={faCheck} />
              </StyledYesSpanBig>
            ) : (
              <StyledNoSpanBig title="Kontakt nie wykonany">
                <FontAwesomeIcon icon={faTimes} />
              </StyledNoSpanBig>
            )}
          </StyledLeadInfoItem>
        </StyledStatusWrapper>
        <StyledStatusInfoWrapper>
          <StyledInfoWrapper>
            <StyledNameLink to={`/dashboard/lead/details/${lead.id}`}>
              {lead.name && (
                <StyledInfoItem>
                  <FontAwesomeIcon icon={faIdCard} />
                  {lead.name}
                </StyledInfoItem>
              )}{" "}
              {lead.phone && (
                <StyledInfoItem>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  {lead.phone}
                </StyledInfoItem>
              )}{" "}
              {lead.email && (
                <StyledInfoItem>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {lead.email}
                </StyledInfoItem>
              )}
            </StyledNameLink>
            {lead.maintainer ? (
              <StyledMaintainerInfo>
                Przypisany handlowiec:
                <StyleMaintainerLink>
                  {lead.maintainer.name}
                </StyleMaintainerLink>
              </StyledMaintainerInfo>
            ) : (
              <StyledMaintainerInfo>
                Brak przypisanego handlowca
              </StyledMaintainerInfo>
            )}
            {lead.voivodeship && (
              <StyledVoivodeshipWrapper>
                Województwo: {lead.voivodeship.name}
              </StyledVoivodeshipWrapper>
            )}
          </StyledInfoWrapper>
          <StyledLeadInfo>
            <StyledLeadInfoItem>
              Lead utworzony: {moment(lead.created_at).format("DD.MM.YY HH:mm")}
            </StyledLeadInfoItem>

            <StyledLeadInfoItem>
              {lead.contact_create ? (
                <StyledYesSpan>
                  <FontAwesomeIcon icon={faCheck} />
                </StyledYesSpan>
              ) : (
                <StyledNoSpan>
                  <FontAwesomeIcon icon={faTimes} />
                </StyledNoSpan>
              )}
              Utworzony Klient
            </StyledLeadInfoItem>
            <StyledLeadInfoItem>
              {lead.wrong_lead === 0 ? (
                <StyledSpanWrapper>
                  <StyledSpan
                    style={{ background: lead.lead_type?.color }}
                  ></StyledSpan>{" "}
                  {lead.lead_type?.name}
                </StyledSpanWrapper>
              ) : (
                <StyledSpanWrapper>
                  <StyledSpan style={{ background: "red" }}>
                    <FontAwesomeIcon
                      style={{ color: "white" }}
                      icon={faTimes}
                    />
                  </StyledSpan>{" "}
                  Błędny lead/Utracony lead
                </StyledSpanWrapper>
              )}
            </StyledLeadInfoItem>
            <StyledLeadInfoItem>
              {lead.complaint ? (
                <StyledSpanWrapper>
                  <StyledSpan
                    style={{ backgroundColor: "#FF6961" }}
                  ></StyledSpan>
                  Reklamacja zgłoszona
                </StyledSpanWrapper>
              ) : null}
            </StyledLeadInfoItem>
          </StyledLeadInfo>
        </StyledStatusInfoWrapper>
      </StyledLeadWrapper>
      <StyledActions></StyledActions>
      <StyledCollapseMenuButtonWrapper>
        <button onClick={handleDropDownOptions} type="button">
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </StyledCollapseMenuButtonWrapper>
      <DropDownMenu
        isOpen={isOpenOptions}
        setIsOpenOptions={setIsOpenOptions}
        handleOnOpenAlert={handleOnOpenAlert}
        handleOnClick={handleOnEdit}
        leadId={lead.id}
      />
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledWrapper>
  );
};

const StyledStatusInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const StyledSpanWrapper = styled.div`
  display: flex;
  margin-right: 5px;
`;

const StyledYesSpanBig = styled.span`
  display: inline-block;
  color: white;
  background: rgb(102, 188, 70);
  font-size: 22px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
`;
const StyledNoSpanBig = styled.span`
  display: inline-block;
  color: white;
  background: rgb(244, 118, 49);
  font-size: 22px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
`;
const StyledYesSpan = styled.span`
  display: inline-block;
  color: white;
  background: rgb(102, 188, 70);
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;
const StyledNoSpan = styled.span`
  display: inline-block;
  color: white;
  background: rgb(244, 118, 49);
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const StyledLeadInfoItem = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 1px;
  }
`;

const StyledLeadInfo = styled.div`
  margin-right: 80px;
  @media screen and (max-width: 1024px) {
    flex: 1;
    margin-right: 0;
    margin-top: 10px;
  }
`;

const StyledVoivodeshipWrapper = styled.div`
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledInfoItem = styled.div`
  font-size: 16px;
  svg {
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyleMaintainerLink = styled.p`
  margin-left: 5px;
`;

const StyledMaintainerInfo = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledSpan = styled.span`
  display: block;
  color: black;
  width: 16px;
  height: 16px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const StyledStatusWrapper = styled.div`
  flex-basis: 50px;
  @media screen and (max-width: 768px) {
    flex-basis: 40px;
  }
`;

const StyledInfoWrapper = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLeadWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  min-width: 0;
`;

const StyledNameLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;

  min-width: 0;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCollapseMenuButtonWrapper = styled.div`
  button {
    padding: 1rem;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 10px;
  right: 15px;

  @media screen and (max-width: 768px) {
    top: 5px;
    right: 10px;
    button {
      font-size: 16px;
      padding: 10px;
    }
  }
`;

const StyledWrapper = styled.div`
  background: white;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  border-bottom: 1px solid #ebebeb;
  position: relative;
`;
export default LeadsItem;
