import React from "react";

const PermissionSetting = () => {
  return (
    <div>
      <p>
        Znajduje się menu z dwiema możliwościami do wyburu dostępy oraz
        widoczność
      </p>
      <p>
        {" "}
        Tutaj znajują się możliwośc tworzenia/edycja ról urzytkowników z
        dostępnych z dostępnych opcji napisanych przez programistów{" "}
      </p>
      <p>zadania:</p>
      <ul>
        <li>Edycja zdań</li>
        <li>usuwanie zadań</li>
        <li>Wykonywanie zadań</li>
        <li>Eksport z profilu zadań </li>
      </ul>
      <p>Osoby: </p>
      <ul>
        <li>Edytowanie osób</li>
        <li>Usuwanie osób</li>
        <li>Zmiania grupy osoby</li>
        <li>Export z profilu osoby</li>
        <li>Łączenie osób</li>
      </ul>
      <p>Domyślnie zaproszony:</p>
      <ul>
        <li>Wpisz osobę </li>
        <li>Cała firma</li>
        <li>Brak użytkownika</li>
        <li>Zalogowany użytkownik</li>
        <li>Zespół główny uzytkownika</li>
      </ul>

      <p>Firmy:</p>
      <ul>
        <li>Edytowanie Firmy</li>
        <li>Usuwanie Firmy</li>
        <li>Zmiana grupy firmy</li>
        <li>Export z profilu firmy</li>
        <li>Łączenie firm</li>
      </ul>
      <p>Domyślnie zaproszony do firmy:</p>
      <ul>
        <li>Wpisz osobę </li>
        <li>Cała firma</li>
        <li>Brak użytkownika</li>
        <li>Zalogowany użytkownik</li>
        <li>Zespół główny uzytkownika</li>
      </ul>
      <p>Szanse sprzedaży:</p>
      <ul>
        <li>Edytowanie szans sprzedaży</li>
        <li>Usuwanie szans sprzedaży</li>
        <li>Zmiana grupy szans sprzedaży</li>
        <li>Zmiana daty zamknięcia</li>
        <li>Zmiana daty zmiany statusu</li>
        <li>Zmiana statusu</li>
        <li>Export z profilu szans sprzedaży</li>
      </ul>
      <p>Domyślnie zaproszony do szans sprzedaży:</p>
      <ul>
        <li>Wpisz osobę </li>
        <li>Cała firma</li>
        <li>Brak użytkownika</li>
        <li>Zalogowany użytkownik</li>
        <li>Zespół główny uzytkownika</li>
      </ul>
    </div>
  );
};

export default PermissionSetting;
