import React from "react";
import styled from "styled-components";

const RocketChat = () => {
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Rocket Chat</StyledTitle>
      </StyledTitleWrapper>
      <StyledRocketChatWrapper>
        <iframe
          frameBorder="0"
          style={{ width: "100%", height: "100%" }}
          src="https://chat.syntezaoze.pl/channel/general"
          title="myframe"
        ></iframe>
      </StyledRocketChatWrapper>
    </StyledWrapper>
  );
};
const StyledRocketChatWrapper = styled.div`
  height: calc(100vh - 200px);
  border-radius: 10px;
  overflow: hidden;
`;
const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
`;

export default RocketChat;
