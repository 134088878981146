import React from "react";
import styled from "styled-components";
import OfferListItem from "./OfferListItem";

const OfferList = ({ offers, setIsShowModalOpen, setOfferObject }) => {
  return (
    <StyledWrapper>
      {offers &&
        offers.map((offer) => (
          <OfferListItem
            key={offer.id}
            offer={offer}
            setIsShowModalOpen={setIsShowModalOpen}
            setOfferObject={setOfferObject}
          />
        ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export default OfferList;
