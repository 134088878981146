import {
  faEllipsisH,
  faEnvelope,
  faPhoneAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../helpers/request.js";
import { StoreContext } from "../../../../../store/StoreProvider.js";
import ClientsDropDownOptions from "./ContactDropDownOptions";
import DeleteAlert from "./DeleteAlert.jsx";

const ClientsItem = ({
  client,
  fetchData,
  handleOnClickEdit,
  setEditObject,
}) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const { user } = useContext(StoreContext);

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/clients/${client.id}`);
    if (status === 201) {
      fetchData();
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    setEditObject(client);
    handleOnClickEdit();
  };

  const handleDropDownOptions = (e) => {
    e.preventDefault();
    setIsOpenOptions(!isOpenOptions);
  };

  const handleOnAlertClose = () => {
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  const handleOnAlertOpen = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };

  return (
    <StyledWrapper>
      <StyledInfo>
        <StyledTitle>
          <StyledNameLink
            to={`/dashboard/contacts/clients/details/${client.id}`}
          >
            {client.first_name} {client.last_name}
          </StyledNameLink>
        </StyledTitle>
        <StyledClientsInfo>
          {client.phone && (
            <>
              <StyledPhoneIcon icon={faPhoneAlt} />
              <StyledLink href={`tel:${client.phone}`}>
                {client.phone}
              </StyledLink>
            </>
          )}
          {client.email && (
            <>
              <StyledMailIcon icon={faEnvelope} />
              <StyledLink href={`mailto:${client.email}`}>
                {client.email}
              </StyledLink>
            </>
          )}
        </StyledClientsInfo>
      </StyledInfo>
      {client.maintainer?.department ? (
        <StyledMaintainer>
          <FontAwesomeIcon icon={faUser} /> {client.maintainer?.department.name}
        </StyledMaintainer>
      ) : (
        <StyledMaintainer>
          <FontAwesomeIcon icon={faUser} />{" "}
          {client.maintainer?.role?.id === 1 &&
            `Zarząd - ${client.maintainer?.name}`}
          {client.maintainer?.role?.id === 3 &&
            `Biuro - ${client.maintainer?.name}`}
        </StyledMaintainer>
      )}
      <StyledActions>
        <StyledAction>
          Notatki( {client.notes && client.notes.length} )
        </StyledAction>
        <StyledAction>
          Szanse sprzedaży({" "}
          {client.opportunities && client.opportunities.length} )
        </StyledAction>
      </StyledActions>
      <StyledCollapseMenu>
        <button onClick={handleDropDownOptions} type="button">
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </StyledCollapseMenu>
      <ClientsDropDownOptions
        isOpen={isOpenOptions}
        setIsOpenOptions={setIsOpenOptions}
        handleOnAlertOpen={handleOnAlertOpen}
        handleOnClick={handleOnEdit}
        client_id={client.id}
        user={user}
        clientUser={client.maintainer}
      />
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnAlertClose}
        handleOnDelete={handleOnDelete}
      />
    </StyledWrapper>
  );
};

const StyledNameLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    color: #777;
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
const StyledAction = styled.p`
  margin: 0 15px;
  color: black;
  font-size: 14px;
`;

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledTitle = styled.div`
  margin-bottom: 10px;
`;

const StyledClientsInfo = styled.div`
  display: flex;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const StyledMaintainer = styled.div`
  margin-top: 15px;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const StyledPhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
const StyledMailIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledInfo = styled.div``;

const StyledCollapseMenu = styled.div`
  button {
    padding: 1rem;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 10px;
  right: 15px;

  @media screen and (max-width: 768px) {
    top: 5px;
    right: 10px;
    button {
      font-size: 16px;
      padding: 10px;
    }
  }
`;
const StyledWrapper = styled.div`
  background: white;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 10px;
`;

export default ClientsItem;
