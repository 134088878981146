import React, { useEffect, useState } from "react";
import styled from "styled-components";
import fileSvg from "../../../../../../../../img/file.svg";
import pdfSvg from "../../../../../../../../img/pdf-download-2617.svg";

const ItemFile = ({ fileItem }) => {
  const [imageFile, setImageFile] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (
      fileItem.mime_type === "image/jpg" ||
      fileItem.mime_type === "image/png" ||
      fileItem.mime_type === "image/jpeg"
    ) {
      setImageFile(fileItem);
    } else {
      setFile(fileItem);
    }
  }, [fileItem]);

  return (
    <>
      {imageFile && (
        <StyledImageWrapper>
          <StyledImage src={fileItem.path_name} alt={fileItem.name} />
          <StyledNameWrapper>
            <StyledName title={fileItem.name}>{fileItem.name}</StyledName>
            <StyledSize>{fileItem.size} kB</StyledSize>
          </StyledNameWrapper>
        </StyledImageWrapper>
      )}
      {file && (
        <StyledFileWrapper>
          <StyledNameWrapper>
            {file.mime_type === "application/pdf" ? (
              <StyledFileImgWrapper>
                <StyledFileImg src={pdfSvg} />
              </StyledFileImgWrapper>
            ) : (
              <StyledFileImgWrapper>
                <StyledFileImg src={fileSvg} />
              </StyledFileImgWrapper>
            )}
            <StyledName title={file.name}>{file.name}</StyledName>
            <StyledSize>{file.size} kB</StyledSize>
          </StyledNameWrapper>
        </StyledFileWrapper>
      )}
    </>
  );
};

const StyledFileImg = styled.img`
  max-height: 80px;
`;

const StyledFileImgWrapper = styled.div``;

const StyledDeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: 2px solid #134771;
  color: #134771;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: white;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledSize = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const StyledName = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledNameWrapper = styled.div`
  font-size: 14px;
  text-align: center;
`;
const StyledFileWrapper = styled.div`
  padding: 10px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
`;

const StyledImageWrapper = styled.div`
  padding: 10px;
`;

export default ItemFile;
