import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import buildCalendar from "./buildCalendar";

import CalendarMonthDayItem from "./CalendarMonthDayItem";
import loading from "../../../../../../img/48x48.gif";
import moment from "moment";
const CalendarMonth = ({
  todoList,
  fetchData,
  handleOnClickEdit,
  setEditObject,
  value,
  setValue,
  isMonthOpen,
  handleOnDayOpen,
  handleOnClickAdd,
  setStartDate,
  setEndDate,
  isLoading,
}) => {
  const [calendar, setCalendar] = useState([]);
  useEffect(() => {
    setCalendar(buildCalendar(value));
    setStartDate(moment(value).startOf("month"));
    setEndDate(moment(value).endOf("month"));
  }, [value]);

  return (
    <StyledWrapper
      style={isMonthOpen ? { display: "flex" } : { display: "none" }}
    >
      <StyledCalendarWrapper>
        {isLoading ? (
          <StyledIsLoadingWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledIsLoadingWrapper>
        ) : (
          <>
            <StyledDayName>
              {["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"].map((d) => (
                <div key={d}>{d}</div>
              ))}
            </StyledDayName>
            <StyledMonthContainer>
              {calendar.map((day) => (
                <CalendarMonthDayItem
                  key={day}
                  day={day}
                  value={value}
                  setValue={setValue}
                  todoList={todoList}
                  fetchData={fetchData}
                  handleOnClickEdit={handleOnClickEdit}
                  handleOnClickAdd={handleOnClickAdd}
                  setEditObject={setEditObject}
                  handleOnDayOpen={handleOnDayOpen}
                />
              ))}
            </StyledMonthContainer>
          </>
        )}
      </StyledCalendarWrapper>
    </StyledWrapper>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 48px;
  height: 48px;
`;

const StyledIsLoadingWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 220px);
`;

const StyledDayName = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  text-align: center;
`;

const StyledCalendarWrapper = styled.div`
  padding: 20px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledMonthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

const StyledWrapper = styled.div`
  margin: 0 10px;
  flex: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;
`;
export default CalendarMonth;
