import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { StoreContext } from "../../../../../store/StoreProvider";
import AddClients from "../../../../Contact/Clients/AddClients/AddClients";

const OpportunitiesCompanyLead = ({ lead, fetchOpportunities }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const { user } = useContext(StoreContext);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setEditMode(false);
    setEditObject(null);
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitleP>Lead:</StyledTitleP>
      </StyledTitleWrapper>
      <StyledNameWrapper>
        <StyledNameLink to={`/dashboard/lead/details/${lead.id}`}>
          {lead.name} {lead.phone} {lead.email}
        </StyledNameLink>
      </StyledNameWrapper>
      <StyledDetailsWrapper>
        {lead.email && (
          <StyledEmailWrapper>
            <StyledEmailIcon icon={faEnvelope}></StyledEmailIcon>
            <StyledEmailLink href={`mailto:${lead.email}`}>
              {lead.email}
            </StyledEmailLink>
          </StyledEmailWrapper>
        )}
        {lead.phone && (
          <StyledPhoneWrapper>
            <StyledPhoneIcon icon={faPhone}></StyledPhoneIcon>
            <StyledPhoneLink href={`tel:${lead.phone}`}>
              {lead.phone}
            </StyledPhoneLink>
          </StyledPhoneWrapper>
        )}
      </StyledDetailsWrapper>

      <AddClients
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchOpportunities}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledWrapper>
  );
};

const StyledPhoneLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

const StyledPhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  font-size: 14px;
`;

const StyledPhoneWrapper = styled.div`
  margin-top: 5px;
  display: flex;
`;

const StyledEmailIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  font-size: 14px;
`;
const StyledEmailWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

const StyledEmailLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;
const StyledDetailsWrapper = styled.div`
  padding: 10px;
`;
const StyledTitleP = styled.p`
  font-size: 18px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  border-radius: 5px 5px 0 0;
  padding: 5px;
`;
const StyledNameLink = styled(Link)`
  font-size: 18px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;
const StyledNameWrapper = styled.div`
  padding: 10px; ;
`;
const StyledWrapper = styled.div`
  min-width: 300px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 1600px) {
    flex: 1;
  }
`;

export default OpportunitiesCompanyLead;
