import React, { useContext, useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import { useLocation, useHistory } from "react-router";

const AssemblyPlanning = ({ opportunities, fetchData }) => {
  const { user, setOpportunities } = useContext(StoreContext);
  const [isSended, setIsSended] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const handleOrderProduct = async () => {
    setIsSended(true);
    try {
      const { status } = await request.get(
        `/api/opportunities/assembly-planning/${opportunities.id}`
      );

      if (status === 200) {
        fetchData();
        fetchOpportunities();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const fetchOpportunities = async () => {
    try {
      const { status, data } = await request.get("/api/opportunities");
      if (status === 200) {
        await setOpportunities(data.data);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Planowanie montażu</StyledTitle>
      </StyledTitleWrapper>
      {opportunities.assembly_planning === 1 ? (
        <StyledTrueInfo>Zaplanowany</StyledTrueInfo>
      ) : (
        <StyledFalseInfo>Nie Zaplanowany</StyledFalseInfo>
      )}
      {opportunities.assembly_planning === 0 &&
        (user?.role?.id === 1 || user.role?.id === 3) && (
          <StyledButtonWrapper>
            <StyledButton onClick={handleOrderProduct} disabled={isSended}>
              Zaznacz zaplanowanie montażu
            </StyledButton>
          </StyledButtonWrapper>
        )}
    </StyledWrapper>
  );
};

const StyledFalseInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(244, 118, 49);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledTrueInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(102, 188, 70);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div``;

export default AssemblyPlanning;
