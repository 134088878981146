import React from "react";
import styled from "styled-components";

const RoleSettingsItem = ({ role }) => {
  const { name } = role;

  return (
    <StyledTaskStatusItem>
      <StyledDescription>
        <p>{name}</p>
      </StyledDescription>
    </StyledTaskStatusItem>
  );
};

const StyledTaskStatusItem = styled.tr`
  font-size: 18px;
`;
const StyledDescription = styled.td`
  display: flex;
  padding: 5px;
`;

export default RoleSettingsItem;
