import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import CollapseShowItem from "./CollapseShowItem";

const showItem = [
  {
    id: 1,
    name: "Moje",
  },
  {
    id: 2,
    name: "Biorę udział",
  },
  {
    id: 3,
    name: "Usunięte",
  },
  {
    id: 4,
    name: "Obserwowane",
  },
  {
    id: 5,
    name: "Wszystkie",
  },
];

const CollapseShow = ({ setShowFilter }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState(true);

  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <StyledCollapse>
      <button className="title-collapse" onClick={handleOnClick}>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
        <h4>Pokaż</h4>
      </button>
      <div
        className="collapse"
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        <ul>
          {showItem.map((element) => {
            return (
              <CollapseShowItem
                setShowFilter={setShowFilter}
                id={element.id}
                name={element.name}
                key={element.id}
              />
            );
          })}
        </ul>
      </div>
    </StyledCollapse>
  );
};

const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;

  .title-collapse {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: none;
    background: white;
    border-bottom: 1px solid black;
    display: flex;
    h4 {
      margin-left: 10px;
      font-size: 16px;
    }
  }
  .collapse {
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  span.to-do {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    margin: 0 10px;
  }
`;

export default CollapseShow;
