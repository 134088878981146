import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import request from "../../../../helpers/request";

const UserSidebarListItem = ({ todo, fetchData }) => {
  const TodayDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const changeDate = new Date(todo.date);
  let dateString = "";
  if (
    changeDate.getDate() === TodayDate.getDate() &&
    changeDate.getMonth() === TodayDate.getMonth() &&
    changeDate.getFullYear() === TodayDate.getFullYear()
  ) {
    dateString = "dzisiaj";
  } else if (
    changeDate.getDate() === tomorrow.getDate() &&
    changeDate.getMonth() === tomorrow.getMonth() &&
    changeDate.getFullYear() === tomorrow.getFullYear()
  ) {
    dateString = "jutro";
  } else if (
    changeDate.getDate() < TodayDate.getDate() &&
    changeDate.getMonth() <= TodayDate.getMonth() &&
    changeDate.getFullYear() <= TodayDate.getFullYear()
  ) {
    dateString = <span style={{ color: "red" }}>spóźnione</span>;
  } else {
    dateString = changeDate.toLocaleDateString();
  }

  const handleChangeComplete = async () => {
    await request.put(`api/todo/${todo.id}`, { done: !todo.done });
    fetchData();
  };
  return (
    <StyledItem>
      <StyledInfoIconWrapper>
        <StyledCheckWrapper>
          <StyledSpan
            onClick={handleChangeComplete}
            className="check-complete"
            style={
              todo.done
                ? { background: "green" }
                : { background: "transparent" }
            }
            title={
              todo.done ? "oznacz jako nie wykonane" : "oznacz jako wykonane"
            }
          ></StyledSpan>
        </StyledCheckWrapper>
        <StyledFontAwesomeIcon
          icon={[todo.taskType?.prefix, todo.taskType?.icon]}
        />
      </StyledInfoIconWrapper>

      <StyledInfo>
        <StyledName title={todo.name}>{todo.name}</StyledName>
        <p>{todo.responsivePerson}</p>
      </StyledInfo>
      <p>{dateString}</p>
    </StyledItem>
  );
};

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  background: white;
  border-bottom: #dfdede 1px solid;
  width: 100%;
`;

const StyledInfoIconWrapper = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
`;
const StyledCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpan = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid lightblue;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 18px !important;
`;

const StyledInfo = styled.div`
  width: 55%;
`;
const StyledName = styled.p`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export default UserSidebarListItem;
