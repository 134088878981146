import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddJoinDetails from "./AddJoinDetails/AddJoinDetails";
import EditJoinDetails from "./EditJoinDetails/EditJoinDetails";
import ShowJoinDetails from "./ShowJoinDetails";

const ContractJoinDetails = ({
  energeticJoinDetails,
  ownerDetails,
  opportunities,
  fetchData,
}) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUncomplete, setIsUncomplete] = useState(false);

  const handleOnShow = () => {
    setIsShowModalOpen(true);
  };
  const handleOnAdd = () => {
    setIsAddModalOpen(true);
  };

  const handleOnCloseShowModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOnEdit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
    setIsEditModalOpen(true);
  };
  const handleOnCloseEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
  };

  const handleOnCloseAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAddModalOpen(false);
  };

  useEffect(() => {
    if (opportunities.energetic_join_details && opportunities.status === 2) {
      if (
        opportunities.energetic_join_details.metering_protection_value ===
          null ||
        opportunities.energetic_join_details.power_connection_power === null ||
        opportunities.energetic_join_details.contracted_power === null ||
        opportunities.energetic_join_details.energy_consumption_point ===
          null ||
        opportunities.energetic_join_details.counter_number === null
      ) {
        setIsUncomplete(true);
      } else {
        setIsUncomplete(false);
      }
    }
  }, [energeticJoinDetails]);
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Dane Przyłącza Energetycznego</StyledTitle>
      </StyledTitleWrapper>

      {!energeticJoinDetails && (
        <StyledButtonWrapper>
          <StyledButtonLight onClick={handleOnAdd}>
            Dodaj dane przyłącza
          </StyledButtonLight>
        </StyledButtonWrapper>
      )}

      {energeticJoinDetails && (
        <>
          {isUncomplete ? (
            <StyledButtonWrapper>
              <StyledButtonUncompleted onClick={handleOnShow}>
                Pokaż dane przyłącza
              </StyledButtonUncompleted>
            </StyledButtonWrapper>
          ) : (
            <StyledButtonWrapper>
              <StyledButton onClick={handleOnShow}>
                Pokaż dane przyłącza
              </StyledButton>
            </StyledButtonWrapper>
          )}
        </>
      )}

      {energeticJoinDetails && (
        <ShowJoinDetails
          energeticJoinDetails={energeticJoinDetails}
          isModalOpen={isShowModalOpen}
          handleOnClose={handleOnCloseShowModal}
          handleOnEdit={handleOnEdit}
          opportunities={opportunities}
        />
      )}

      <AddJoinDetails
        opportunities={opportunities}
        isModalOpen={isAddModalOpen}
        handleOnClose={handleOnCloseAddModal}
        fetchData={fetchData}
        ownerDetails={ownerDetails}
      />
      <EditJoinDetails
        energeticJoinDetails={energeticJoinDetails}
        isModalOpen={isEditModalOpen}
        handleOnClose={handleOnCloseEditModal}
        fetchData={fetchData}
        opportunities={opportunities}
      />
    </StyledWrapper>
  );
};

const StyledButtonUncompleted = styled.button`
  padding: 5px 10px;
  border: none;
  background: rgb(244, 118, 49);
  color: #053257;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #cadeee;
  }
`;

const StyledButtonLight = styled.button`
  padding: 5px 10px;
  border: none;
  background: #cadeee;
  color: #053257;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #cadeee;
  }
`;

const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div``;

export default ContractJoinDetails;
