import React from "react";
import styled from "styled-components";

const CollapseGroupItem = ({ name, id, setPaymentFilter, paymentFilter }) => {
  const handleOnClick = () => {
    if (paymentFilter === id) {
      setPaymentFilter(null);
    } else {
      setPaymentFilter(id);
    }
  };

  return (
    <StyledCollapseItemButton
      onClick={handleOnClick}
      className={paymentFilter === id && "active"}
    >
      <p>{name}</p>
    </StyledCollapseItemButton>
  );
};

const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;

  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

export default CollapseGroupItem;
