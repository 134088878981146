import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AllCompanyDropdown from "./components/AllCompanyDropdown";

const ChooseUser = ({
  departments,
  teams,
  setValue,
  user,
  setValueTitle,
  isEditMode,
  editUser,
  setUserId,
}) => {
  const [name, setName] = useState("");
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const handleOpenSelect = (e) => {
    e.preventDefault();
    setIsOpenSelect((prev) => !prev);
  };

  useEffect(() => {
    if (isEditMode) {
      setName(editUser.name);
      setUserId(editUser.id);
      setValue(setValueTitle, editUser.id);
    } else {
      setName(user?.name);
      setUserId(user?.id);
      setValue(setValueTitle, user?.id);
    }
  }, [user, isEditMode]);

  const handleOnChooseUser = (user) => {
    setName(user.name);
    setUserId(user.id);
    setValue(setValueTitle, user.id);
    setIsOpenSelect(false);
  };

  const dropRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpenSelect(false);
    }
  };
  useEffect(() => {
    if (isOpenSelect) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  return (
    <>
      {user.role?.id === 4 && (
        <StyledWrapper ref={dropRef}>
          <StyledLabel>Przypisane do:</StyledLabel>
          <StyledSelectButton onClick={handleOpenSelect} disabled={true}>
            {name}
          </StyledSelectButton>
        </StyledWrapper>
      )}
      {(user.role?.id === 1 || user.role?.id === 2 || user.role?.id === 3) && (
        <StyledWrapper ref={dropRef}>
          <StyledLabel>Przypisane do:</StyledLabel>
          <StyledSelectButton onClick={handleOpenSelect}>
            {name} <StyledAngleIcon icon={faChevronDown} />
          </StyledSelectButton>
          <StyledSelectWrapper
            style={isOpenSelect ? { display: "block" } : { display: "none" }}
          >
            <AllCompanyDropdown
              user={user}
              departments={departments}
              teams={teams}
              handleOnChooseUser={handleOnChooseUser}
            />
          </StyledSelectWrapper>
        </StyledWrapper>
      )}
    </>
  );
};

const StyledSelectWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 10px;
  z-index: 1;
  padding: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  min-width: 300px;
  max-width: 360px;
`;
const StyledAngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 3px;
  font-size: 10px;
`;

const StyledWrapper = styled.div`
  flex: 1;
  margin-top: 25px;
  position: relative;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;

const StyledSelectButton = styled.button`
  width: 100%;
  position: relative;
  text-align: left;
  background-color: white;
  border-radius: 3px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  padding: 1px;
  color: black;
  cursor: pointer;
`;
export default ChooseUser;
