import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

const StatisticDateRange = ({
  searchStartDate,
  setSearchStartDate,
  searchEndDate,
  setSearchEndDate,
  setDateFilter,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const CustomStartInput = forwardRef(({ value, onClick }, ref) => (
    <StyledDatePickerButton onClick={onClick} ref={ref}>
      {value}
    </StyledDatePickerButton>
  ));
  const CustomEndInput = forwardRef(({ value, onClick }, ref) => (
    <StyledDatePickerButton onClick={onClick} ref={ref}>
      {value}
    </StyledDatePickerButton>
  ));

  const handleSetDate = (e) => {
    e.preventDefault();
    setSearchStartDate(startDate);
    setSearchEndDate(endDate);
    setDateFilter(null);
  };

  useEffect(() => {
    if (searchStartDate && searchEndDate) {
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [searchStartDate, searchEndDate]);

  const handleSetStartDate = (date, endDate) => {
    setStartDate(date);
    if (moment(date).isAfter(endDate, "day")) {
      setEndDate(date);
    }
  };

  return (
    <StyledWrapper>
      <StyledDateInputWrapper>
        <StyledInputLabel>Od:</StyledInputLabel>
        <ReactDatePicker
          customInput={<CustomStartInput />}
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => handleSetStartDate(date, endDate)}
        />
      </StyledDateInputWrapper>
      <StyledDateInputWrapper>
        <StyledInputLabel>Do:</StyledInputLabel>
        <ReactDatePicker
          customInput={<CustomEndInput />}
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          onChange={(date) => setEndDate(date)}
        />
      </StyledDateInputWrapper>
      <StyledButtonWrapper>
        <StyledConfirmButton onClick={handleSetDate}>
          Zatwierdź
        </StyledConfirmButton>
      </StyledButtonWrapper>
    </StyledWrapper>
  );
};

const StyledDatePickerButton = styled.button`
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  background: #134771;
  color: rgb(202, 222, 238);
`;

const StyledConfirmButton = styled.button`
  border: none;
  padding: 5px 10px;
  margin: 5px;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: #134771;
  color: rgb(202, 222, 238);
  cursor: pointer;
`;

const StyledButtonWrapper = styled.div``;

const StyledInputLabel = styled.label`
  margin-right: 5px;
  font-size: 14px;
`;
const StyledDateInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default StatisticDateRange;
