import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import DeleteAlert from "./DeleteAlert";

const ConstructionItem = ({
  construction,
  handleOpenEditTypeTask,
  setEditObject,
  fetchConstructions,
}) => {
  const {
    id,
    name,
    construction_type,
    price,
    add_price,
    margin,
    transport_type,
  } = construction;
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleOnEdit = () => {
    setEditObject(construction);
    handleOpenEditTypeTask();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/constructions/${id}`);
    if (status === 200) {
      fetchConstructions();
      setIsAlertOpen(false);
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledTaskTypeItem>
      <StyledDescription>
        <p>{name}</p>
      </StyledDescription>
      <StyledDescription>
        <p>{construction_type ? construction_type.name : ""}</p>
      </StyledDescription>
      <StyledDescription>
        <p>{price} zł</p>
      </StyledDescription>
      <StyledDescription>
        <p>{add_price} zł</p>
      </StyledDescription>
      <StyledDescription>
        <p>{transport_type ? transport_type.name : ""} </p>
      </StyledDescription>
      <StyledDescription>
        <p>{margin}</p>
      </StyledDescription>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.tr`
  min-width: 0;
`;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.td`
  min-width: 0;
  max-width: 340px;
  & p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const StyledButtonGroup = styled.td`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
export default ConstructionItem;
