import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import request from "../../../helpers/request";
import styled from "styled-components";
import StatisticsAside from "./StatisticsAside/StatisticsAside";
import OpportunitiesTable from "./Components/OpportunitiesTable/OpportunitiesTable";
import Charts from "./Components/Charts/Charts";
import Summary from "./Components/Summary/Summary";

import moment from "moment";
import StatisticFilter from "./StatisticFilter/StatisticFilter";

const StatisticsOpportunities = () => {
  const location = useLocation();
  const history = useHistory();
  const [statistic, setStatistic] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(2);
  const [dateFilters, setDateFilters] = useState(2);
  const [isLoading, setIsLoading] = useState(false);

  //Statistic Filter Status

  const [searchStartDate, setSearchStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().endOf("month").toDate()
  );

  const fetchDataStatistic = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get("/api/statistic", {
        params: {
          start_date: searchStartDate,
          end_date: searchEndDate,
          department: departmentFilter,
          status: statusFilter,
        },
      });
      if (status === 200) {
        setStatistic(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataStatistic();
  }, []);

  useEffect(() => {
    fetchDataStatistic();
  }, [searchStartDate, searchEndDate, departmentFilter, statusFilter]);

  useEffect(() => {
    if (dateFilters) {
      if (dateFilters === 1) {
        setSearchStartDate(moment().startOf("week").toDate());
        setSearchEndDate(moment().endOf("week").toDate());
      } else if (dateFilters === 2) {
        setSearchStartDate(moment().startOf("month").toDate());
        setSearchEndDate(moment().endOf("month").toDate());
      } else if (dateFilters === 3) {
        setSearchStartDate(
          moment().subtract(1, "month").startOf("month").toDate()
        );
        setSearchEndDate(moment().subtract(1, "month").endOf("month").toDate());
      } else if (dateFilters === 4) {
        setSearchStartDate(moment().subtract(7, "day").toDate());
        setSearchEndDate(moment().toDate());
      } else if (dateFilters === 5) {
        setSearchStartDate(moment().subtract(30, "day").toDate());
        setSearchEndDate(moment().toDate());
      } else if (dateFilters === 6) {
        setSearchStartDate(moment().startOf("year").toDate());
        setSearchEndDate(moment().endOf("year").toDate());
      } else if (dateFilters === 7) {
        setSearchStartDate(
          moment().subtract(1, "year").startOf("year").toDate()
        );
        setSearchEndDate(moment().subtract(1, "year").endOf("year").toDate());
      }
    }
  }, [dateFilters]);

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Statystyki</StyledTitle>
      </StyledTitleWrapper>
      <StyledStatisticsWrapper>
        <StatisticsAside
          setDepartmentFilter={setDepartmentFilter}
          departmentFilter={departmentFilter}
          setDateFilters={setDateFilters}
          dateFilters={dateFilters}
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
        />
        <StyledStatisticsDataWrapper>
          <StatisticFilter
            searchStartDate={searchStartDate}
            searchEndDate={searchEndDate}
            setSearchEndDate={setSearchEndDate}
            setSearchStartDate={setSearchStartDate}
            setDateFilter={setDateFilters}
          />
          <StyledFlexWrapper>
            <Charts
              statistic={statistic}
              departmentFilter={departmentFilter}
              statusFilter={statusFilter}
              isLoading={isLoading}
            />
          </StyledFlexWrapper>
          <StyledFlexWrapper>
            <Summary statistic={statistic} isLoading={isLoading} />
          </StyledFlexWrapper>
          <StyledFlexWrapper>
            <OpportunitiesTable statistic={statistic} isLoading={isLoading} />
          </StyledFlexWrapper>
        </StyledStatisticsDataWrapper>
      </StyledStatisticsWrapper>
    </StyledWrapper>
  );
};

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
const StyledStatisticsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-left: 10px;
  min-width: 0;
`;
const StyledStatisticsDataWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
  min-width: 0;
`;
const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  min-width: 0;
  margin: 0 auto;
`;
const StyledFlexWrapper = styled.div`
  background: white;
  display: flex;
  border-radius: 10px;
  min-width: 0;
`;

export default StatisticsOpportunities;
