import React from "react";
import styled from "styled-components";

const TodoSideBarNav = ({ filterOption, setFilterOption }) => {
  return (
    <StyledSideBarNav>
      <li>
        <button
          className={filterOption === 1 ? "active" : ""}
          onClick={() => setFilterOption(1)}
        >
          Wszystkie
        </button>
      </li>
      <li>
        <button
          className={filterOption === 2 ? "active" : ""}
          onClick={() => setFilterOption(2)}
        >
          Dziś
        </button>
      </li>
      <li>
        <button
          className={filterOption === 3 ? "active" : ""}
          onClick={() => setFilterOption(3)}
        >
          Jutro
        </button>
      </li>
      <li>
        <button
          className={filterOption === 4 ? "active" : ""}
          onClick={() => setFilterOption(4)}
        >
          Spóźnione
        </button>
      </li>
    </StyledSideBarNav>
  );
};

const StyledSideBarNav = styled.ul`
  padding: 5px;
  list-style: none;
  display: flex;
  li {
    margin-right: 10px;
    button {
      padding: 5px;
      cursor: pointer;
      background: transparent;
      outline: none;
      border: none;

      &.active {
        border-bottom: 2px solid blue;
      }
      &:hover {
        color: #5a5a5a;
      }
    }
  }
`;

export default TodoSideBarNav;
