import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import SecureRoute from "./components/SecureRoute";

import Login from "./pages/Login/Login";
import GlobalStyle from "./components/GlobalStyle";
import Dashboard from "./pages/Dashboard/Dashboard";
import SettingsIndex from "./pages/Settings/SettingsIndex";
import ToDoProvider from "./store/ToDoProvider";
import request from "./helpers/request";
import { Cookies } from "react-cookie";
import ProfileSettings from "./pages/ProfileSettings/ProfilesSettings";

function App() {
  const location = useLocation();
  // Funkcja przekierowująca do podstron Gdzy użytkownik jest zalogowany

  //   const renderRoutes=()=>{
  // <Switch location={location} key={location.key}>
  //     <Route exact path='/'>
  //       <Dashboard/>
  //     </Route>
  // </Switch>
  //   }
  const cookie = new Cookies();
  const isLogin = localStorage.getItem("isLogged");
  if (isLogin) {
    request.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "access-token"
    )}`;
  }

  return (
    <div className="App">
      <GlobalStyle />
      <ToDoProvider>
        <Switch location={location} key={location.key}>
          <SecureRoute path="/dashboard">
            <Dashboard />
          </SecureRoute>
          <SecureRoute exact path="/">
            <Redirect to="/dashboard/home" />
          </SecureRoute>
          <SecureRoute path="/settings">
            <SettingsIndex />
          </SecureRoute>
          <SecureRoute path="/user-settings">
            <ProfileSettings />
          </SecureRoute>
          <Route path="/login" component={Login} />

          {/* Nie znaleziono takiej strony page 404 */}
          {/* <Route component={NotFound} /> */}
        </Switch>
      </ToDoProvider>
    </div>
  );
}

export default App;
