import {
  faCheckCircle,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import ChangeAssigment from "./ChangeAssigment";
import TodoPopOver from "./TodoPopOver";

const TodoItem = ({ todo, fetchData, handleOnClickEdit, setEditObject }) => {
  const [hover, setHover] = useState(false);
  const [left, setLeft] = useState(-20);
  const [isAssigmentModalOpen, setIsAssigmentModalOpen] = useState(false);
  const hoverRef = useRef();
  let timer;
  const timerOuter = useRef(null);

  const handleOnHover = (e) => {
    timer = setTimeout(() => {
      setHover(true);
    }, 500);

    window.addEventListener("mousemove", handleOnMouseMove, true);
  };

  const handleOnSetTimeOut = () => {
    setHover(false);
    window.removeEventListener("mousemove", handleOnMouseMove, true);
  };
  const handleOnMouseLeave = (e) => {
    clearTimeout(timer);
    timerOuter.current = setTimeout(handleOnSetTimeOut, 500);
  };

  const handleOnMouseMove = (e) => {
    if (hoverRef?.current?.contains(e.target)) {
      clearTimeout(timerOuter.current);
      window.removeEventListener("mousemove", handleOnMouseMove, true);
    }
  };

  const handleOnDelete = async (e) => {
    e.stopPropagation();
    const { status } = await request.delete(`/api/todo/${todo.id}`);
    if (status === 200) {
      fetchData();
      setHover(false);
    }
  };

  const handleChangeComplete = async (e) => {
    e.stopPropagation();
    await request.put(`api/todo/${todo.id}`, { done: !todo.done });
    fetchData();
  };

  useEffect(() => {
    if (hoverRef) {
      const rect = hoverRef.current.getBoundingClientRect();

      const isInViewport =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (!isInViewport) {
        const overFlow =
          rect.right -
          (window.innerWidth || document.documentElement.clientWidth);

        setLeft(-50 - overFlow);
      }
    }
  }, [hover]);

  const handleOnEdit = (e) => {
    e.stopPropagation();
    setEditObject(todo);
    handleOnClickEdit();
  };

  const handleOnOpenAssigmentModal = (e) => {
    e.stopPropagation();
    setIsAssigmentModalOpen(true);
  };
  const handleOnCloseAssigmentModal = () => {
    setIsAssigmentModalOpen(false);
  };

  return (
    <StyledTodoItemWrapper>
      <StyledTodoButton
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnEdit}
      >
        {todo.taskType && (
          <StyledTypeIcon icon={[todo.taskType.prefix, todo.taskType.icon]} />
        )}
        {Boolean(todo.done) === true && (
          <StyledDoneIcon style={{ color: "#66bc46" }} icon={faCheckCircle} />
        )}
        {Boolean(todo.important) === true && (
          <StyledImportantIcon
            style={{ color: "#f47631" }}
            icon={faExclamation}
          />
        )}
        <StyledName title={todo.name}>{todo.name}</StyledName>
      </StyledTodoButton>

      <TodoPopOver
        todo={todo}
        left={left}
        hover={hover}
        setHover={setHover}
        hoverRef={hoverRef}
        handleOnDelete={handleOnDelete}
        handleOnClickEdit={handleOnClickEdit}
        setEditObject={setEditObject}
        handleChangeComplete={handleChangeComplete}
        handleOnOpenAssigmentModal={handleOnOpenAssigmentModal}
      />
      <ChangeAssigment
        isModalOpen={isAssigmentModalOpen}
        handleOnClose={handleOnCloseAssigmentModal}
        fetchData={fetchData}
        todo={todo}
      />
    </StyledTodoItemWrapper>
  );
};
const StyledTodoItemWrapper = styled.div`
  position: relative;
`;

const StyledTypeIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
const StyledDoneIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
const StyledImportantIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledTodoButton = styled.button`
  display: block;
  background: #134771;
  color: #cadeee;
  border: none;
  border-radius: 2px;
  outline: none;
  width: 100%;
  padding: 2px 5px;
  margin-bottom: 2px;
  display: flex;
  cursor: pointer;
`;

const StyledName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
`;
export default TodoItem;
