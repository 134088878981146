import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ClientPopOverCopmonent = ({ lead, hover, setHover, hoverRef }) => {
  const handleSetHover = () => {
    setTimeout(() => setHover(false), 500);
  };
  return (
    <StyledWrapper
      onMouseLeave={handleSetHover}
      style={hover ? { display: "flex" } : { display: "none" }}
      ref={hoverRef}
    >
      <StyledDetailsWrapper>
        {lead.name ? (
          <StyledNameWrapper>
            <StyledUserIcon icon={faUser} />
            <StyledNameP>{lead.name}</StyledNameP>
          </StyledNameWrapper>
        ) : (
          <StyledNameWrapper>
            <StyledUserIcon icon={faUser} /> Brak nazwy
          </StyledNameWrapper>
        )}
        {lead.phone ? (
          <StyledPhoneWrapper>
            <StyledPhoneIcon icon={faPhone} />
            <StyledPhoneLink href={`tel:${lead.phone}`}>
              {lead.phone}
            </StyledPhoneLink>
          </StyledPhoneWrapper>
        ) : (
          <StyledPhoneWrapper>
            <StyledPhoneIcon icon={faPhone} /> Brak Telefonu
          </StyledPhoneWrapper>
        )}
        {lead.email ? (
          <StyledMailWrapper>
            <StyledMailIcon icon={faEnvelope} />
            <StyledMailLink href={`mailto:${lead.email}`}>
              {lead.email}
            </StyledMailLink>
          </StyledMailWrapper>
        ) : (
          <StyledMailWrapper>
            <StyledMailIcon icon={faEnvelope} /> Brak E-maila
          </StyledMailWrapper>
        )}
      </StyledDetailsWrapper>
      <StyledPopOverBottom>
        <StyledProfileLink to={`/dashboard/lead/details/${lead.id}`}>
          Zobacz Profil
        </StyledProfileLink>
      </StyledPopOverBottom>
    </StyledWrapper>
  );
};

const StyledUserIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const StyledNameWrapper = styled.div`
  display: flex;
`;
const StyledNameP = styled.p``;

const StyledDetailsWrapper = styled.div`
  padding: 10px;
`;

const StyledProfileLink = styled(Link)`
  color: #134771;
  text-decoration: none;

  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const StyledPopOverBottom = styled.div`
  background: #cadeee;
  padding: 10px;
  border-radius: 0 0 10px 10px;
`;

const StyledMailLink = styled.a`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledMailIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const StyledMailWrapper = styled.div``;
const StyledPhoneLink = styled.a`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledPhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
const StyledPhoneWrapper = styled.div``;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 100;
  min-width: 320px;
  max-width: 360px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`;

export default ClientPopOverCopmonent;
