import React from "react";
import styled from "styled-components";
import SearchTypeChoose from "./SearchTypeChoose";

const SearchAside = ({
  showClients,
  showCompanies,
  showOpportunities,
  showLeads,
  setShowClients,
  setShowCompanies,
  setShowOpportunities,
  setShowLeads,
}) => {
  return (
    <StyledSearchAsideWrapper>
      <SearchTypeChoose
        showClients={showClients}
        showCompanies={showCompanies}
        showOpportunities={showOpportunities}
        showLeads={showLeads}
        setShowClients={setShowClients}
        setShowCompanies={setShowCompanies}
        setShowOpportunities={setShowOpportunities}
        setShowLeads={setShowLeads}
      />
    </StyledSearchAsideWrapper>
  );
};

const StyledSearchAsideWrapper = styled.div`
  margin-top: 60px;
  width: 220px;
`;

export default SearchAside;
