import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Modal from "../../../../components/Modal";

const OfferPricingItemDetails = ({
  isShowModalOpen,
  handleOnClose,
  offer,
  handleOpenPricingModal,
  isSended,
}) => {
  const [endDate, setEndDate] = useState(null);
  const [nettoPrice, setNettoPrice] = useState(null);
  const [vatPrice, setVatPrice] = useState(null);
  const [bruttoPrice, setBruttoPrice] = useState(null);
  const [margin, setMargin] = useState(null);

  useEffect(() => {
    if (offer && Object.keys(offer).length > 0) {
      const date = moment(offer.created_at)
        .clone()
        .add(Number(offer.date), "day")
        .format("DD.MM.YY");
      setEndDate(date);
    }
  }, [offer?.date, offer?.created_at]);

  useEffect(() => {
    if (offer?.price_netto) {
      setNettoPrice(
        Number(offer.price_netto)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setNettoPrice(null);
    }

    if (offer?.price_vat) {
      setVatPrice(
        Number(offer.price_vat)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setVatPrice(null);
    }

    if (offer?.price_brutto) {
      setBruttoPrice(
        Number(offer.price_brutto)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setBruttoPrice(null);
    }

    if (offer?.margin) {
      setMargin(
        Number(offer.margin)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " PLN"
      );
    } else {
      setMargin(null);
    }
  }, [
    offer?.price_netto,
    offer?.brutto_price,
    offer?.vat_price,
    offer?.margin,
  ]);

  return (
    <Modal
      isModalOpen={isShowModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      {offer && (
        <StyledModal>
          <StyledDateWrapper>
            Data utworzenia: {moment(offer.created_at).format("DD.MM.YY")} godz.
            {moment(offer.created_at).format("HH:mm")}
          </StyledDateWrapper>
          {endDate && (
            <StyledEndDateWrapper>
              Data wygaśnięcia oferty: {endDate}
            </StyledEndDateWrapper>
          )}

          <StyledPhotovoltaicInstalationDetails>
            <StyledPanelWrapper>
              <StyledTitle>Panele:</StyledTitle>
              <StyledPanelName>
                <div>{offer.photovoltaic_instalation?.solar_panel?.name}</div>
                <div>{offer.solar_panel_count}</div>
              </StyledPanelName>
            </StyledPanelWrapper>

            <StyledInverterWrapper>
              <StyledTitle>Inwerter:</StyledTitle>
              <StyledInverterName>
                <div>{offer.photovoltaic_instalation?.inverter?.name}</div>
                <div>{offer.inverter_count}</div>
              </StyledInverterName>
            </StyledInverterWrapper>

            <StyledConstructionWrapper>
              <StyledTitle>Konstrukcja:</StyledTitle>
              <StyledConstructionName>
                <div>{offer.construction?.name}</div>
                <div>{offer.construction_count}</div>
              </StyledConstructionName>
            </StyledConstructionWrapper>

            <StyledPowerWrapper>
              <StyledTitle>Moc:</StyledTitle>
              {offer.instalaction_power} kWp
            </StyledPowerWrapper>

            <StyledPriceWrapper>
              <StyledTitle>Cena:</StyledTitle>

              {nettoPrice && (
                <StyledPrice>
                  <div>Netto:</div>
                  <div>{nettoPrice}</div>
                </StyledPrice>
              )}
              {vatPrice && (
                <StyledPrice>
                  <div>Vat {offer.vat ? <>({offer.vat}%)</> : ""}: </div>
                  <div>{vatPrice}</div>
                </StyledPrice>
              )}
              {bruttoPrice && (
                <StyledPrice>
                  <div>Brutto: </div>
                  <div> {bruttoPrice}</div>
                </StyledPrice>
              )}
              {margin && (
                <StyledPrice style={{ marginTop: "10px" }}>
                  <div>Marża:</div>
                  <div>{margin}</div>
                </StyledPrice>
              )}
            </StyledPriceWrapper>

            {offer?.secure ? (
              <StyledSecureWrapper>
                <StyledTitle>Zabezpieczenia:</StyledTitle>
                <StyledSecureInfo> AC/DC, {offer.secure}</StyledSecureInfo>
              </StyledSecureWrapper>
            ) : (
              <StyledSecureWrapper>
                <StyledTitle>Zabezpieczenia:</StyledTitle>
                <StyledSecureInfo> AC/DC </StyledSecureInfo>
              </StyledSecureWrapper>
            )}

            <StyledServicesWrapper>
              <StyledTitle>Usługi dodatkowe:</StyledTitle>
              {Number(offer.cut_length) !== 0 && (
                <StyledSecureInfo>
                  Kompleksowe wykonanie przekopu wraz z przewodami AC/DC -{" "}
                  {offer.cut_length}m
                </StyledSecureInfo>
              )}
              {Number(offer.wire_length) !== 0 && (
                <StyledSecureInfo>
                  Przewody elektryczne AC/DC - przekop po stronie inwestora -{" "}
                  {offer.wire_length}m
                </StyledSecureInfo>
              )}
            </StyledServicesWrapper>

            {offer.description ? (
              <StyledDescriptionWrapper>
                <StyledTitle>Uwagi:</StyledTitle>
                {offer.description}
              </StyledDescriptionWrapper>
            ) : (
              <StyledDescriptionWrapper>
                <StyledTitle>Uwagi:</StyledTitle>
                Brak uwag
              </StyledDescriptionWrapper>
            )}

            {offer?.user && (
              <StyledUserWrapper>
                <StyledTitle>Oferta wygenerowana przez:</StyledTitle>
                <StyledUserLink>{offer.user.name}</StyledUserLink>
              </StyledUserWrapper>
            )}
          </StyledPhotovoltaicInstalationDetails>
          {offer.pricing_made === 1 ? (
            <StyledButtonWrapper>
              <StyledButtonDisable>Wyceń</StyledButtonDisable>
            </StyledButtonWrapper>
          ) : (
            <StyledButtonWrapper>
              <StyledButton
                onClick={handleOpenPricingModal}
                disabled={isSended}
              >
                Wyceń
              </StyledButton>
            </StyledButtonWrapper>
          )}
        </StyledModal>
      )}
    </Modal>
  );
};

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 10px 15px;
  }
`;

const StyledButtonDisable = styled.button`
  padding: 0.8rem 1rem;

  border: none;
  background: #cadeee;
  color: #053257;
  cursor: none;
  pointer-events: none;
  border-radius: 5px;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const StyledDescriptionWrapper = styled.div`
  margin-top: 10px;
`;

const StyledServicesWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPowerWrapper = styled.div`
  margin-top: 10px;
`;

const StyledSecureInfo = styled.p``;

const StyledSecureWrapper = styled.div`
  margin-top: 10px;
`;

const StyledUserLink = styled.p``;

const StyledUserWrapper = styled.div`
  margin-top: 10px;
`;

const StyledPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPriceWrapper = styled.div`
  margin-top: 10px;
`;

const StyledEndDateWrapper = styled.div``;

const StyledConstructionWrapper = styled.div`
  margin-top: 10px;
`;

const StyledConstructionName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInverterWrapper = styled.div`
  margin-top: 10px;
`;

const StyledInverterName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPanelWrapper = styled.div``;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledPanelName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPhotovoltaicInstalationDetails = styled.div`
  margin-top: 20px;
`;

const StyledDateWrapper = styled.div``;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledModal = styled.div`
  background-color: white;
`;

export default OfferPricingItemDetails;
