import React, { createContext, useState } from "react";

export const ToDoContext = createContext(null);

const ToDoProvider = ({ children }) => {
  const [taskStatus, setTaskStatus] = useState([]);
  const [taskType, setTaskType] = useState([]);
  const [yourTask, setYourTask] = useState([]);

  return (
    <ToDoContext.Provider
      value={{
        taskStatus,
        setTaskStatus,
        taskType,
        setTaskType,
        yourTask,
        setYourTask,
      }}
    >
      {children}
    </ToDoContext.Provider>
  );
};

export default ToDoProvider;
