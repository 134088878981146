import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import OfferPricingItemDetails from "./OfferPricingItemDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import PricingOfferModal from "./PricingOfferModal";

const OfferPricingItem = ({ offer, fetchData }) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [spanObject, setSpanObject] = useState(null);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [isSended, setIsSended] = useState(false);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOnOpen = () => {
    setIsShowModalOpen(true);
  };

  const handleOpenPricingModal = () => {
    setIsShowModalOpen(false);
    setIsPricingModalOpen(true);
  };

  const handleOnClosePricingModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsPricingModalOpen(false);
  };

  useEffect(() => {
    if (offer) {
      if (offer.pricing_made) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(102, 188, 70` }}>
            <FontAwesomeIcon icon={faCheck} />{" "}
          </StyledSpan>
        );
      } else {
        setSpanObject(
          <StyledSpan style={{ background: `lightgray` }}>
            <FontAwesomeIcon icon={faCircle} />{" "}
          </StyledSpan>
        );
      }
    }
  }, [offer]);

  return (
    <StyledWrapper>
      <StyledSpanWrapper>{spanObject}</StyledSpanWrapper>
      <StyledInfoWrapper>
        <StyledOfferNameWrapper>
          {offer.offer_number ? (
            <StyledOfferName
              onClick={handleOnOpen}
              title={` Oferta ${offer?.offer_number}/${moment(
                offer.created_at
              ).format("YYYY")}`}
            >
              Oferta {offer?.offer_number}/
              {moment(offer.created_at).format("YYYY")}
            </StyledOfferName>
          ) : (
            <StyledOfferName
              onClick={handleOnOpen}
              title={` Oferta z ${offer?.opportunity?.name}`}
            >
              Oferta z {offer?.opportunity?.name}
            </StyledOfferName>
          )}
        </StyledOfferNameWrapper>
        <StyledOfferDate>
          Data utworzenia: {moment(offer.created_at).format("DD.MM.YY HH:mm")}
        </StyledOfferDate>

        <StyledOfferRelationWrapper>
          {offer.opportunity?.company && (
            <StyledOpportunityCompanyWrapper>
              <StyledLabel> Firma:</StyledLabel>

              <StyledOpportunityCompanyLink
                title={offer.opportunity.company.name}
                to={`/dashboard/contacts/companies/details/${offer.opportunity.company.id}`}
              >
                {offer.opportunity.company.name}
              </StyledOpportunityCompanyLink>
            </StyledOpportunityCompanyWrapper>
          )}

          {offer.opportunity?.client && (
            <StyledOpportunityClientWrapper>
              <StyledLabel> Klient:</StyledLabel>

              <StyledOpportunityClientLink
                title={`${offer.opportunity.client.first_name} ${offer.opportunity.client.last_name}`}
                to={`/dashboard/contacts/clients/details/${offer.opportunity.client.id}`}
              >
                {offer.opportunity.client.first_name}{" "}
                {offer.opportunity.client.last_name}
              </StyledOpportunityClientLink>
            </StyledOpportunityClientWrapper>
          )}
        </StyledOfferRelationWrapper>
      </StyledInfoWrapper>

      <OfferPricingItemDetails
        isShowModalOpen={isShowModalOpen}
        handleOnClose={handleOnClose}
        offer={offer}
        handleOpenPricingModal={handleOpenPricingModal}
        isSended={isSended}
      />
      <PricingOfferModal
        offer={offer}
        isModalOpen={isPricingModalOpen}
        handleOnClose={handleOnClosePricingModal}
        fetchData={fetchData}
        setIsSended={setIsSended}
        isSended={isSended}
      />
    </StyledWrapper>
  );
};

const StyledInfoWrapper = styled.div`
  flex: 1;
  min-width: 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledSpan = styled.span`
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const StyledSpanWrapper = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOfferDate = styled.div``;

const StyledOfferName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #053257;
  }
`;

const StyledLabel = styled.label`
  margin-right: 5px;
`;

const StyledOpportunityClientLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledOpportunityClientWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledOpportunityCompanyLink = styled(Link)`
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;
const StyledOpportunityCompanyWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledOfferNameWrapper = styled.div``;

const StyledOfferRelationWrapper = styled.div``;

const StyledWrapper = styled.div`
  background: white;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  display: flex;
  min-width: 0;
`;

export default OfferPricingItem;
