import React, { useRef, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import OpportunityInfoItem from "./Components/OpportunityInfoItem";

const OpportunityItem = ({
  calendar,
  opportunity,
  handleOnDragStart,
  handleOnTouchStart,
  width,
  handleStretchAssemblyItem,
  handleStretchAssemblyItemTouch,
  setDuration,
  index,
  handleOnTouchEnd,
}) => {
  const [isDrag, setIsDrag] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(10);
  const [elementWidth, setElementWidth] = useState(width);
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(7);

  const dragItem = useRef();

  const handleOnDragItemStart = (e) => {
    const duration = moment
      .duration(
        moment(opportunity.assembly_end).diff(
          moment(opportunity.assembly_start)
        )
      )
      .days();
    handleOnDragStart(opportunity, duration, e);

    dragItem.current = e.target;
    dragItem.current.addEventListener("dragend", handleOnDragEnd);
    setTimeout(() => {
      setIsDrag(true);
    }, 0);
  };

  const handleOnDragEnd = () => {
    setIsDrag(false);

    dragItem.current.removeEventListener("dragend", handleOnDragEnd);
    dragItem.current = null;
  };

  useEffect(() => {
    if (opportunity) {
      if (
        moment(opportunity.assembly_start).isBetween(
          calendar[0],
          calendar[6],
          "day",
          "[]"
        )
      ) {
        setStartDay(moment(opportunity.assembly_start).isoWeekday());
      } else {
        setStartDay(1);
      }

      if (
        moment(opportunity.assembly_end).isBetween(
          calendar[0],
          calendar[6],
          "day",
          "[]"
        )
      ) {
        setEndDay(moment(opportunity.assembly_end).isoWeekday() + 1);
      } else {
        setEndDay(8);
      }
    }
  }, [opportunity.assembly_start, opportunity.assembly_end, calendar]);

  useEffect(() => {
    setLeft(startDay * width);
    setElementWidth((endDay - startDay) * width);
    setIsShow(true);
  }, [startDay, endDay, width, opportunity]);

  const handleStrech = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDuration(endDay - startDay - 1);
    handleStretchAssemblyItem(e, width, opportunity);
  };
  const handleStrechTouch = (e) => {
    e.stopPropagation();
    const duration = endDay - startDay - 1;
    setDuration(duration);
    handleStretchAssemblyItemTouch(e, width, opportunity);
  };

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleOnCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setTop(index * 30);
  }, [index]);

  const handleOnTouchFinger = (e) => {
    const duration = moment
      .duration(
        moment(opportunity.assembly_end).diff(
          moment(opportunity.assembly_start)
        )
      )
      .days();
    handleOnTouchStart(opportunity, duration, e);
    dragItem.current = e.target;
    document.body.style.overflow = "hidden";
    dragItem.current.addEventListener("touchend", handleOnEndTouch);
    setTimeout(() => {
      setIsDrag(true);
    }, 0);
  };

  const handleOnEndTouch = (e) => {
    e.preventDefault();
    setIsDrag(false);
    var changedTouch = e.changedTouches[0];
    var elem = document.elementFromPoint(
      changedTouch.clientX,
      changedTouch.clientY
    );
    document.body.style.overflow = "auto";
    if (elem === e.target) {
      setIsModalOpen(true);
    }

    dragItem.current.removeEventListener("touchend", handleOnEndTouch);
    dragItem.current = null;
  };

  return (
    <StyledOpportunityWrapper
      style={
        isShow
          ? {
              display: "block",
              top: `${top}px`,
              left: `${left + 4}px`,

              width: `${elementWidth - 6}px`,
            }
          : { display: "none" }
      }
    >
      <StyledOpportunity
        onTouchStart={handleOnTouchFinger}
        onTouchEnd={handleOnTouchEnd}
        title={opportunity.name}
        draggable
        onDragStart={handleOnDragItemStart}
        onClick={handleOnClick}
      >
        <StyledOpportunityName>{opportunity.name}</StyledOpportunityName>
      </StyledOpportunity>
      <StyledStretch
        onMouseDown={(e) => handleStrech(e)}
        onTouchStart={(e) => handleStrechTouch(e)}
      ></StyledStretch>
      <OpportunityInfoItem
        opportunity={opportunity}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleOnCloseModal={handleOnCloseModal}
      />
    </StyledOpportunityWrapper>
  );
};

const StyledStretch = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  bottom: 0;
  cursor: e-resize;
  z-index: 10;
  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;

const StyledOpportunityWrapper = styled.div`
  position: absolute;
  top: 15px;
`;

const StyledOpportunityName = styled.div`
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
`;

const StyledOpportunity = styled.div`
  background: #134771;
  color: white;
  padding: 5px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`;
export default OpportunityItem;
