import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const CollapseStatusItem = ({ setStatusFilter, status, statusFilter }) => {
  const handleOnClick = () => {
    if (statusFilter === status.id) {
      setStatusFilter(null);
    } else {
      setStatusFilter(status.id);
    }
  };

  return (
    <StyledCollapseItemButton
      onClick={handleOnClick}
      className={statusFilter === status.id && "active"}
    >
      <StyledSpan style={{ background: status.color }}>
        <FontAwesomeIcon
          style={{ color: status.icon_color }}
          icon={[status.prefix, status.icon]}
        />
      </StyledSpan>
      <p>{status.name}</p>
    </StyledCollapseItemButton>
  );
};

const StyledSpan = styled.span`
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;

  padding: 5px 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }

  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  p {
    font-weight: normal;
  }
`;
export default CollapseStatusItem;
