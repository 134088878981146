import {
  faChevronDown,
  faChevronUp,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const PrioritySelect = ({ priority, changePriority }) => {
  const [isOpen, setOpen] = useState(false);
  const dropRef = useRef(null);

  const openSelect = () => {
    setOpen(true);
  };

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [isOpen]);

  return (
    <Wrapper ref={dropRef}>
      <Title>Priorytet:</Title>
      <StyledValueButton onClick={openSelect}>
        {priority === 0 && (
          <>
            <FlagIcon style={{ color: "rgb(102, 188, 70)" }} icon={faFlag} />
            Niski Priorytet
          </>
        )}
        {priority === 1 && (
          <>
            <FlagIcon style={{ color: "#134771" }} icon={faFlag} />
            Średni Priorytet
          </>
        )}
        {priority === 2 && (
          <>
            <FlagIcon style={{ color: "rgb(244, 118, 49)" }} icon={faFlag} />{" "}
            Wysoki Priorytet
          </>
        )}
        <ChevronIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </StyledValueButton>

      <SelectWrapper style={isOpen ? { display: "flex" } : { display: "none" }}>
        <StyledOption onClick={() => changePriority(0)}>
          <FlagIcon style={{ color: "rgb(102, 188, 70)" }} icon={faFlag} />
          Niski Priorytet
        </StyledOption>
        <StyledOption onClick={() => changePriority(1)}>
          <FlagIcon style={{ color: "#134771" }} icon={faFlag} />
          Średni Priorytet
        </StyledOption>
        <StyledOption onClick={() => changePriority(2)}>
          <FlagIcon style={{ color: "rgb(244, 118, 49)" }} icon={faFlag} />
          Wysoki Priorytet
        </StyledOption>
      </SelectWrapper>
    </Wrapper>
  );
};

const FlagIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
`;

const StyledValueButton = styled.button`
  padding: 2px 5px;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 5px;
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }
`;

const StyledOption = styled.button`
  text-align: left;
  padding: 2px 5px;
  color: #134771;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #cadeee;
  }
`;

const SelectWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 100px;
  flex-direction: column;
  gap: 5px;
  display: none;
  background-color: white;
  padding: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #134771;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export default PrioritySelect;
