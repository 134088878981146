import {
  faBullseye,
  faCheck,
  faEllipsisH,
  faMapMarkerAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import DeleteAlert from "./DeleteAlert";
import DoneConfirm from "./DoneConfirm";
import DropDownMenu from "./DropDownMenu/DropDownMenu";
import loading from "../../../../../img/48x48.gif";
import EditAssemblyFault from "./EditAssemblyFault/EditAssemblyFault";
import moment from "moment";
import ShowAssemblyFaultModal from "./ShowAssemblyFaultModal/ShowAssemblyFaultModal";
import PrioritySelect from "./PrioritySelect/PrioritySelect";

const AssemblyFaultItem = ({
  assemblyFault,
  fetchData,
  handleOnClickEdit,
  setPageNumber,
}) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [address, setAddress] = useState("");
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);

  const handleDropDownOptions = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOpenOptions(!isOpenOptions);
  };

  const handleOnDelete = async () => {
    setIsSended(true);
    const { status } = await request.delete(
      `/api/assembly-faults/${assemblyFault.id}`
    );
    if (status === 201) {
      setPageNumber(1);
      fetchData();
      setIsAlertOpen(false);
    }
    setIsSended(false);
  };

  const changePriority = async (priority) => {
    setIsSended(true);
    const { status } = await request.patch(
      `/api/assembly-faults/priority/${assemblyFault.id}`,
      { priority: priority }
    );
    if (status === 201) {
      setPageNumber(1);
      fetchData();
      setIsAlertOpen(false);
    }
    setIsSended(false);
  };

  const handleOnDone = async () => {
    setIsSended(true);
    const { status } = await request.patch(
      `/api/assembly-faults/done/${assemblyFault.id}`,
      { is_done: 1 }
    );
    if (status === 201) {
      setPageNumber(1);
      fetchData();
      setIsAlertOpen(false);
    }
    setIsSended(false);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };
  const handleOnOpenConfirm = () => {
    setIsConfirmOpen(true);
    setIsOpenOptions(false);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  const handleOnCloseConfirm = () => {
    setIsOpenOptions(false);
    setIsConfirmOpen(false);
  };

  useEffect(() => {
    if (assemblyFault) {
      if (
        assemblyFault.city &&
        assemblyFault.building_number &&
        assemblyFault.post_code &&
        assemblyFault.post &&
        assemblyFault.street
      ) {
        setAddress(
          `ul. ${assemblyFault?.street} ${assemblyFault?.building_number}, ${assemblyFault?.city} ${assemblyFault?.post_code} ${assemblyFault?.post}`
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.building_number &&
        assemblyFault?.post &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `${assemblyFault?.city} ${assemblyFault?.building_number}, ${assemblyFault?.post_code} ${assemblyFault?.post} `
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.street &&
        assemblyFault?.post &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `ul. ${assemblyFault?.street} ${assemblyFault?.city}, ${assemblyFault?.post_code} ${assemblyFault?.post}`
        );
      } else if (
        assemblyFault.city &&
        assemblyFault.building_number &&
        assemblyFault.post_code &&
        assemblyFault.street
      ) {
        setAddress(
          `ul. ${assemblyFault?.street} ${assemblyFault?.building_number}, ${assemblyFault?.post_code} ${assemblyFault?.city}`
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.building_number &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `${assemblyFault?.building_number}, ${assemblyFault?.post_code} ${assemblyFault?.city} `
        );
      } else if (
        assemblyFault?.city &&
        assemblyFault?.street &&
        assemblyFault?.post_code
      ) {
        setAddress(
          `ul. ${assemblyFault?.street}, ${assemblyFault?.post_code} ${assemblyFault?.city}`
        );
      } else {
        setAddress(
          `${assemblyFault?.street ? assemblyFault?.street : ""} ${
            assemblyFault?.building_number ? assemblyFault?.building_number : ""
          } ${assemblyFault?.city ? assemblyFault?.city : ""} ${
            assemblyFault?.post_code ? assemblyFault?.post_code : ""
          } ${assemblyFault?.post ? assemblyFault?.post : ""}`
        );
      }
    }
    return () => {
      setAddress("");
    };
  }, [assemblyFault]);

  const closeEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setEditModalOpen(false);
  };

  const openShowModal = () => {
    setShowModalOpen(true);
  };

  const closeShowModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowModalOpen(false);
  };

  const changeStatus = () => {
    setShowModalOpen(false);
    setIsConfirmOpen(true);
  };

  return (
    <StyledWrapper>
      <StyledOpportunityWrapper>
        <StyledStatusWrapper>
          {assemblyFault.status === 0 && (
            <StyledSpan>
              <StyledSpanIcon icon={faTimes} />
            </StyledSpan>
          )}
          {assemblyFault.status === 1 && (
            <StyledSpan inProgress>
              <StyledSpanIcon icon={faBullseye} />
            </StyledSpan>
          )}
          {assemblyFault.status === 2 && (
            <StyledSpan done>
              <StyledSpanIcon icon={faCheck} />
            </StyledSpan>
          )}
        </StyledStatusWrapper>
        <ContentWrapper>
          <PrioritySelect
            priority={assemblyFault.priority}
            changePriority={changePriority}
          />
          <FaultWrapper>
            <FaultTitle>Opis usterki:</FaultTitle>
            <FaultContent onClick={openShowModal}>
              {assemblyFault.fault}
            </FaultContent>
          </FaultWrapper>
          <ClientWrapper>
            {" "}
            <FontAwesomeIcon icon={faUser} /> {assemblyFault.client_name}
          </ClientWrapper>
          {address && (
            <AddressWrapper>
              {" "}
              <FontAwesomeIcon icon={faMapMarkerAlt} /> {address}
            </AddressWrapper>
          )}
          {assemblyFault.filing_date && (
            <AddressWrapper>
              Usterka zgłoszona:{" "}
              {moment(assemblyFault.filing_date).format("DD MMM YY")}
            </AddressWrapper>
          )}
          {assemblyFault.reporting_person && (
            <AddressWrapper>
              Osoba zgłaszająca: {assemblyFault.reporting_person}
            </AddressWrapper>
          )}

          {assemblyFault.maintainer && (
            <AddressWrapper>
              Osoba odpowiedzialna: {assemblyFault.maintainer.name}
            </AddressWrapper>
          )}
        </ContentWrapper>
      </StyledOpportunityWrapper>
      <StyledCollapseMenuButtonWrapper>
        <button onClick={handleDropDownOptions} type="button">
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </StyledCollapseMenuButtonWrapper>
      <DropDownMenu
        isOpen={isOpenOptions}
        setIsOpenOptions={setIsOpenOptions}
        handleOnOpenAlert={handleOnOpenAlert}
        handleOnOpenConfirm={handleOnOpenConfirm}
        openEditModal={openEditModal}
      />
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
      <DoneConfirm
        isAlertOpen={isConfirmOpen}
        handleOnClose={handleOnCloseConfirm}
        handleOnDone={handleOnDone}
        assemblyFault={assemblyFault}
        fetchData={fetchData}
      />
      <EditAssemblyFault
        isModalOpen={isEditModalOpen}
        handleOnClose={closeEditModal}
        fetchData={fetchData}
        assemblyFault={assemblyFault}
      />
      <ShowAssemblyFaultModal
        assemblyFault={assemblyFault}
        isShowModalOpen={showModalOpen}
        handleOnClose={closeShowModal}
        fetchData={fetchData}
        changeStatus={changeStatus}
        changePriority={changePriority}
      />

      {isSended && (
        <StyledSendNoteWrapper>
          <StyledSendNoteInnerWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledSendNoteInnerWrapper>
        </StyledSendNoteWrapper>
      )}
    </StyledWrapper>
  );
};

const PriorityWrappper = styled.div``;

const FaultWrapper = styled.div`
  padding-right: 40px;
  margin-bottom: 10px;
`;
const FaultTitle = styled.h4`
  font-size: 14px;
  color: #134771;
`;
const FaultContent = styled.p`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const ClientWrapper = styled.p`
  font-size: 16px;
  color: #134771;
`;
const AddressWrapper = styled.p`
  font-size: 16px;
  color: #134771;
`;

const ContentWrapper = styled.div``;

const StyledSpanIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
`;

const StyledSpan = styled.span`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.done
      ? "rgb(102, 188, 70)"
      : props.inProgress
      ? "rgb(255, 238, 127)"
      : "rgb(244,118,49)"};

  justify-content: center;
  align-items: center;
  color: white;
`;

const StyledStatusWrapper = styled.div`
  flex-basis: 50px;
  @media screen and (max-width: 768px) {
    flex-basis: 0;
  }
`;

const StyledOpportunityWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  min-width: 0;
`;

const StyledCollapseMenuButtonWrapper = styled.div`
  button {
    padding: 1rem;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 10px;
  right: 15px;
  @media screen and (max-width: 768px) {
    top: 5px;
    right: 10px;
    button {
      font-size: 16px;
      padding: 10px;
    }
  }
`;

const StyledWrapper = styled.div`
  background: white;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
`;

export default AssemblyFaultItem;
