import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";

const ChartItem = ({ labelsChart, data, title }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (labelsChart && data) {
      setChart({
        labels: labelsChart,
        datasets: [
          {
            label: "Otwarte szanse sprzedaży (Wartość)",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data, labelsChart]);

  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledChartWrapper>
        {chart && (
          <Doughnut
            data={chart}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
            style={{ height: "400px" }}
            height="400"
          />
        )}
      </StyledChartWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;

const StyledChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 2;
  padding: 10px;
  h4 {
    margin-bottom: 20px;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;

export default ChartItem;
