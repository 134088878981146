import moment from "moment";
import React from "react";
import styled from "styled-components";

const TodoItem = ({ todo }) => {
  return (
    <StyledWrapper>
      <StyledNameWrapper>{todo.name}</StyledNameWrapper>
      <StyledTimeWrapper>
        {moment(todo.start_date).format("DD.MM.YY HH:mm")} -{" "}
        {moment(todo.end_date).format("HH:mm")}
      </StyledTimeWrapper>
    </StyledWrapper>
  );
};
const StyledTimeWrapper = styled.div``;
const StyledNameWrapper = styled.div`
  margin-right: 5px;
`;

const StyledWrapper = styled.div`
  display: flex;
  display: block;
  margin: 2px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  color: #cadeee;
  background: #134771;
  cursor: pointer;
`;

export default TodoItem;
