import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import AddNote from "../../components/Note/AddNote/AddNote";
import request from "../../helpers/request";
import { StoreContext } from "../../store/StoreProvider";

import HomeAside from "./Components/HomeAside/HomeAside";
import NewLoadingComponent from "./Components/NoteList/NoteItem/NewLoadingComponent";
import NoteList from "./Components/NoteList/NoteList";

const Home = () => {
  const { notes, setNotes, noteCoursorURL, setNoteCoursorURL } =
    useContext(StoreContext);

  const [isBottom, setIsBottom] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const fetchNote = async () => {
    try {
      const { status, data } = await request.get("/api/note");
      if (status === 200) {
        setNotes(data.data);
        setNoteCoursorURL(data.links.next);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchNextNote = async () => {
    setIsLoadingNext(true);
    try {
      const { status, data } = await request.get(noteCoursorURL);
      if (status === 200) {
        setNotes((prev) => [...prev, ...data.data]);
        setNoteCoursorURL(data.links.next);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }

    setIsLoadingNext(false);
    setIsBottom(false);
  };

  useEffect(() => {
    if (isBottom) {
      fetchNextNote();
    }
  }, [isBottom]);

  useEffect(() => {
    if (notes === undefined || notes.length === 0) {
      fetchNote();
    }
  }, []);

  const windowScroll = () => {
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
      document.body.offsetHeight
    ) {
      setIsBottom(true);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", windowScroll, false);
    return () => {
      document.removeEventListener("scroll", windowScroll, false);
    };
  }, []);

  return (
    <StyledWrapper>
      <StyledFlexWrapper>
        {/* <CompanyStatistic /> */}
        <StyledMainWall>
          <AddNote fetchData={fetchNote} />
          <NoteList note={notes} fetchData={fetchNote} />
          {isLoadingNext && <NewLoadingComponent />}
        </StyledMainWall>
      </StyledFlexWrapper>

      <HomeAside />
    </StyledWrapper>
  );
};

const StyledFlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 0;
  flex: 1;

  @media screen and (max-width: 1400px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const StyledMainWall = styled.div`
  flex-grow: 1;

  min-width: 0;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px;
  flex: 1;
  gap: 10px;
  min-width: 0;
  max-width: 1400px;
  margin: 0 auto;
`;

export default Home;
