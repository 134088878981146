import React from "react";
import styled from "styled-components";
import ContractItem from "./ContractItem";

const ShowContract = ({ opportunities, fetchData }) => {
  return (
    <StyledWrapper>
      <StyledHeaderText>Umowy</StyledHeaderText>
      <StyledContractListWrapper>
        {opportunities.contracts?.length > 0 ? (
          <>
            {opportunities.contracts.map((contract) => (
              <ContractItem
                key={contract.id}
                contract={contract}
                fetchData={fetchData}
                opportunities={opportunities}
              />
            ))}
          </>
        ) : (
          <StyledInfo>Brak wygenerowanych umów</StyledInfo>
        )}
      </StyledContractListWrapper>
    </StyledWrapper>
  );
};

const StyledInfo = styled.div`
  padding: 10px;
  font-size: 14px;
`;

const StyledContractListWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;
const StyledWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
`;
const StyledHeaderText = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgb(244, 118, 49);
  font-weight: bold;
`;
export default ShowContract;
