import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../store/StoreProvider";
import ChooseUser from "./ChooseUser/ChooseUser";
import request from "../../../../../../helpers/request";
import { useLocation, useHistory } from "react-router";

const LeadChangeResponsive = ({ isOpen, lead, fetchData, setIsOpenChange }) => {
  const [userId, setUserId] = useState(null);

  const { departments, teams, user } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const handleChangeMaintainer = async () => {
    try {
      const { status } = await request.patch(
        `api/lead/update-assigment/${lead.id}`,
        { maintainer_id: userId }
      );
      if (status === 201) {
        fetchData();
        setIsOpenChange(false);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  return (
    <StyledStatusesWrapper
      style={isOpen ? { display: "flex" } : { display: "none" }}
    >
      <ChooseUser
        departments={departments}
        teams={teams}
        user={user}
        userId={userId}
        setUserId={setUserId}
      />
      <StyledSendButtonWrapper>
        <StyledSendButton onClick={handleChangeMaintainer}>
          Przypisz handlowca
        </StyledSendButton>
      </StyledSendButtonWrapper>
    </StyledStatusesWrapper>
  );
};
const StyledSendButton = styled.button`
  padding: 0.8rem 1rem;
  width: 100%;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledSendButtonWrapper = styled.div`
  padding: 10px 0;
`;

const StyledStatusesWrapper = styled.div`
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid rgba(160, 160, 160, 0.4);
`;

export default LeadChangeResponsive;
