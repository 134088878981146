import React, { useEffect } from "react";
import styled from "styled-components";

import { useState } from "react";
import Header from "./Components/Header/Header";
import CalendarMonth from "./Components/CalendarMonth/CalendarMonth";
import CalendarDay from "./Components/CalendarDay/CalendarDay";
import CalendarYear from "./Components/CalendarYear/CalendarYear";
const CalendarComponent = ({
  todoList,
  fetchData,
  handleOnClickEdit,
  handleOnClickAdd,
  setEditObject,
  value,
  setValue,
  statusFilter,
  taskTypeFilter,
  userFilter,
  setStartDate,
  setEndDate,
  isLoading,
}) => {
  const [isMonthOpen, setIsMonthOpen] = useState(true);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const [isDayOpen, setIsDayOpen] = useState(false);
  const [todoFilter, setTodoFilter] = useState([]);

  const handleOnMonthOpen = () => {
    setIsYearOpen(false);
    setIsDayOpen(false);
    setIsMonthOpen(true);
  };
  const handleOnYearOpen = () => {
    setIsDayOpen(false);
    setIsMonthOpen(false);
    setIsYearOpen(true);
  };
  const handleOnDayOpen = () => {
    setIsYearOpen(false);
    setIsMonthOpen(false);
    setIsDayOpen(true);
  };

  useEffect(() => {
    let filterArray = todoList.filter(
      (todo) => Boolean(todo.start_date) === true
    );
    if (taskTypeFilter !== null) {
      filterArray = filterArray.filter(
        (todo) => Number(todo.taskType?.id) === taskTypeFilter
      );
    }

    if (userFilter) {
      filterArray = filterArray.filter((todo) => {
        return todo.user?.id === userFilter;
      });
    }
    setTodoFilter(filterArray);
  }, [statusFilter, taskTypeFilter, fetchData, todoList, userFilter]);

  return (
    <StyledWrapper>
      <StyledCalendarWrapper>
        <Header
          value={value}
          setValue={setValue}
          handleOnYearOpen={handleOnYearOpen}
          handleOnMonthOpen={handleOnMonthOpen}
          handleOnDayOpen={handleOnDayOpen}
          isDayOpen={isDayOpen}
          isMonthOpen={isMonthOpen}
          isYearOpen={isYearOpen}
        />

        <CalendarTypeWrapper>
          {isMonthOpen && (
            <CalendarMonth
              isMonthOpen={isMonthOpen}
              todoList={todoFilter}
              value={value}
              setValue={setValue}
              fetchData={fetchData}
              handleOnClickEdit={handleOnClickEdit}
              handleOnClickAdd={handleOnClickAdd}
              setEditObject={setEditObject}
              handleOnDayOpen={handleOnDayOpen}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              isLoading={isLoading}
            />
          )}
          {isDayOpen && (
            <CalendarDay
              isDayOpen={isDayOpen}
              value={value}
              todoList={todoFilter}
              handleOnClickEdit={handleOnClickEdit}
              fetchData={fetchData}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              isLoading={isLoading}
            />
          )}
          {isYearOpen && (
            <CalendarYear
              isYearOpen={isYearOpen}
              value={value}
              todoList={todoFilter}
              handleOnDayOpen={handleOnDayOpen}
              setValue={setValue}
              fetchData={fetchData}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              isLoading={isLoading}
            />
          )}
        </CalendarTypeWrapper>
      </StyledCalendarWrapper>
    </StyledWrapper>
  );
};

const CalendarTypeWrapper = styled.div``;

const StyledCalendarWrapper = styled.div`
  padding: 0 20px 20px 20px;
`;

const StyledWrapper = styled.div`
  margin: 0 10px;

  flex: 1;

  border-radius: 10px;

  min-width: 0;
`;
export default CalendarComponent;
