import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../helpers/request";
import { StoreContext } from "../../../../store/StoreProvider";
import CollapseDepartment from "./CollapseDepartment/CollapseDepartment";
import CollapseStatus from "./CollapseStatus/CollapseStatus";
import DateFilters from "./DateFilters/DateFilters";

const StatisticsAside = ({
  setStatusFilter,
  statusFilter,
  setDateFilters,
  dateFilters,
  departmentFilter,
  setDepartmentFilter,
}) => {
  const { departments, setDepartments } = useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();
  const fetchDataDepartment = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (departments.length === 0) {
      fetchDataDepartment();
    }
  }, []);
  return (
    <StyledAside>
      <CollapseStatus
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
      />
      <DateFilters setDateFilters={setDateFilters} dateFilters={dateFilters} />
      <CollapseDepartment
        setDepartmentFilter={setDepartmentFilter}
        departments={departments}
        departmentFilter={departmentFilter}
      />
    </StyledAside>
  );
};

const StyledAside = styled.div`
  width: 200px;
`;
export default StatisticsAside;
