import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../../../../../../../../../components/Modal";
import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import loading from "../../../../../../../../../img/48x48.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddFileItem from "./AddFileItem";
import { useForm } from "react-hook-form";
import requestMulti from "../../../../../../../../../helpers/requestMulti";
import { useHistory, useLocation } from "react-router-dom";

const getColor = (props) => {
  if (props.isDragAccept) {
    return " rgb(102, 188, 70)";
  }
  if (props.isDragReject) {
    return "rgb(244, 118, 49)";
  }
  if (props.isDragActive) {
    return " rgb(102, 188, 70)";
  }
  return "#134771";
};

const ChangeFile = ({
  isModalOpen,
  handleOnClose,
  opportunities,
  fetchData,
  setIsModalOpen,
  setIsEditModalOpen,
  setIsSended,
  isSended,
}) => {
  const [isConvert, setIsConvert] = useState(false);
  const [fileContext, setFileContext] = useState([]);
  const [convertedFile, setConvertedFile] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const { handleSubmit, reset, setValue } = useForm({
    mode: "all",
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: isConvert,
    multiple: false,
    onDrop: (acceptedFiles) => handleOnFileChange(acceptedFiles),
  });

  const handleOnFileChange = async (files) => {
    const asyncArray = await Promise.all(
      [...files].map((file) => convertFile(file))
    );
    setConvertedFile([...asyncArray]);

    setIsConvert(false);
  };

  const resetInputValue = () => {
    reset({
      file: null,
    });
  };

  useEffect(() => {
    if (convertedFile && convertedFile.length >= 0) {
      let fileArray = [];

      setValue("file", convertedFile);
      [...convertedFile].forEach((file) => {
        fileArray.push({
          name: file.name,
          size: (file.size / 1000).toFixed(2),
          mime_type: file.type,
          url: URL.createObjectURL(file),
        });
      });

      setFileContext(fileArray);
    }
  }, [convertedFile]);

  const convertFile = async (file) => {
    if (file.type === "image/heif" || file.type === "image/heic") {
      let blobURL = URL.createObjectURL(file);
      // convert "fetch" the new blob url
      let blobRes = await fetch(blobURL);
      // convert response to blob
      let blob = await blobRes.blob();
      // convert to PNG - response is blob
      let conversionResult = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });

      // convert to blob url
      const fileItem = new File(
        [conversionResult],
        file.name.replace(/\.[^/.]+$/, ".jpeg"),
        { type: "image/jpeg" }
      );

      return fileItem;
    } else if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/bmp"
    ) {
      try {
        const compresedfile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        });

        const fileItem = new File([compresedfile], file.name, {
          type: file.type,
        });
        return fileItem;
      } catch (e) {
        console.warn(e);
      }
    } else {
      return file;
    }
  };

  const handleOnSubmitwithClose = (data) => {
    if (data.file && data.file.length) {
      sendData(data);
    }
  };

  const sendData = async (data) => {
    setIsSended(true);
    const formData = new FormData();

    if (data.file) {
      for (let i = 0; i < data.file.length; i++) {
        formData.append(`file[${i}]`, data.file[i]);
      }
    }
    try {
      const { status } = await requestMulti.post(
        `/api/product-order/add-file/${opportunities.product_order.id}`,
        formData
      );
      if (status === 200) {
        fetchData();
        resetInputValue();
        setConvertedFile([]);
        setIsModalOpen(false);
        setIsEditModalOpen(true);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnDelete = (e, index) => {
    if (e) {
      e.preventDefault();
    }
    const array = [...convertedFile];

    array.splice(index, 1);

    setConvertedFile(array);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledAddFileInputWrapper>
          <StyledDropContainer
            {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            <p>Upuść tutaj, lub kliknij aby wybrać pliki</p>
          </StyledDropContainer>
          {isConvert && (
            <StyledConvertWrapper>
              <img src={loading} alt="loading" />
            </StyledConvertWrapper>
          )}
          <StyledFileWrapper>
            {fileContext.map((file, index) => (
              <AddFileItem
                file={file}
                key={index}
                index={index}
                handleOnDelete={handleOnDelete}
              />
            ))}
          </StyledFileWrapper>
        </StyledAddFileInputWrapper>
        <StyledButtonWrapper>
          <StyledAddButton
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Dodaj
          </StyledAddButton>
        </StyledButtonWrapper>
      </StyledForm>
      {isSended && (
        <StyledSendNoteWrapper>
          <StyledSendNoteInnerWrapper>
            Przesyłanie...
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledSendNoteInnerWrapper>
        </StyledSendNoteWrapper>
      )}
    </Modal>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledAddButton = styled.button`
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  background: #134771;
  color: #cadeee;

  &:hover {
    background: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 400px;
  min-width: 360px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledDropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 10px;
  border-style: dashed;
  border-color: ${(props) => getColor(props)};
  background-color: #cadeee;
  color: #134771;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const StyledConvertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const StyledFileWrapper = styled.div`
  display: grid;
  padding-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 120px));
`;

const StyledAddFileInputWrapper = styled.div`
  padding: 5px 0;
`;

export default ChangeFile;
