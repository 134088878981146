import React from "react";
import styled from "styled-components";

const SummarykWpItem = ({ kWp }) => {
  return (
    <StyledWrapper>
      <p>{Number(kWp).toFixed(2)} kWp</p>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;
export default SummarykWpItem;
