import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SearchLeadItem = ({ lead }) => {
  return (
    <StyledLeadWrapper
      title={`${lead.name} ${lead.phone} ${lead.email}`}
      to={`/dashboard/lead/details/${lead.id}`}
    >
      <StyledLeadName>
        {lead.name} {lead.phone} {lead.email}
      </StyledLeadName>
    </StyledLeadWrapper>
  );
};

const StyledLeadName = styled.p`
  color: #134771;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLeadWrapper = styled(Link)`
  background: white;
  padding: 10px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

export default SearchLeadItem;
