import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../../helpers/request";
import { StoreContext } from "../../../store/StoreProvider";
import OfferApproveAside from "./Components/OfferApproveAside/OfferApproveAside";
import OfferApproveList from "./Components/OfferApproveList";

const PhotovoltaicOfferApprove = () => {
  const { voivodeships, setVoivodeships, user } = useContext(StoreContext);

  const [voivodeshipFilter, setVoivodeshipFilter] = useState(null);
  const [showApproveFilter, setShowApproveFilter] = useState(false);
  const [showRejectedFilter, setShowRejectedFilter] = useState(false);
  const [photovoltaicOffer, setPhotovoltaicOffer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();
  const history = useHistory();

  const fetchPhotovoltaic = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(
        `/api/offer-approve?page=${number}`,
        {
          params: {
            voivodeship: voivodeshipFilter,
            rejected: Number(showRejectedFilter),
            approve: Number(showApproveFilter),
          },
        }
      );
      if (status === 200) {
        setPhotovoltaicOffer(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };
  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setVoivodeshipFilter(user.department?.voivodeship_id);
    }
  }, [user]);

  const handleOnChangePage = (event) => {
    window.scrollTo(0, 0);
    setPageNumber(event.selected + 1);
    fetchPhotovoltaic(event.selected + 1);
  };
  return (
    <ContentWrapper>
      <OfferApproveAside
        setVoivodeshipFilter={setVoivodeshipFilter}
        voivodeshipFilter={voivodeshipFilter}
        showApproveFilter={showApproveFilter}
        setShowApproveFilter={setShowApproveFilter}
        showRejectedFilter={showRejectedFilter}
        setShowRejectedFilter={setShowRejectedFilter}
      />

      <OfferApproveList
        showApproveFilter={showApproveFilter}
        showRejectedFilter={showRejectedFilter}
        voivodeshipFilter={voivodeshipFilter}
        offerApproveList={photovoltaicOffer}
        fetchData={fetchPhotovoltaic}
        isLoading={isLoading}
        handleOnChangePage={handleOnChangePage}
        pageCount={pageCount}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

export default PhotovoltaicOfferApprove;
