import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ClientCompany = ({ company }) => {
  return (
    <StyledWrapper>
      <StyledTitleWrapper>Powiązana Firma</StyledTitleWrapper>
      <StyledCompanyWrapper>
        <StyledCompanyLink
          to={`/dashboard/contacts/companies/details/${company.id}`}
        >
          {company.name}
        </StyledCompanyLink>
      </StyledCompanyWrapper>
    </StyledWrapper>
  );
};

const StyledCompanyLink = styled(Link)`
  display: block;
  width: 100%;

  background: #134771;
  color: #cadeee;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  &:hover {
    background: #053257;
    background: underline;
  }
`;

const StyledCompanyWrapper = styled.div`
  padding: 10px;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledWrapper = styled.div`
  margin-top: 10px;
  background: white;
  border-radius: 10px;
`;

export default ClientCompany;
