import React from "react";
import styled from "styled-components";

const TodoSideBarNav = ({ filterOption, setFilterOption }) => {
  return (
    <StyledSideBarNav>
      <StyledListItem>
        <StyledButton
          className={filterOption === 1 ? "active" : ""}
          onClick={() => setFilterOption(1)}
        >
          Wszystkie
        </StyledButton>
      </StyledListItem>
      <StyledListItem>
        <StyledButton
          className={filterOption === 2 ? "active" : ""}
          onClick={() => setFilterOption(2)}
        >
          Dziś
        </StyledButton>
      </StyledListItem>
      <StyledListItem>
        <StyledButton
          className={filterOption === 3 ? "active" : ""}
          onClick={() => setFilterOption(3)}
        >
          Jutro
        </StyledButton>
      </StyledListItem>
      <StyledListItem>
        <StyledButton
          className={filterOption === 4 ? "active" : ""}
          onClick={() => setFilterOption(4)}
        >
          Spóźnione
        </StyledButton>
      </StyledListItem>
    </StyledSideBarNav>
  );
};

const StyledButton = styled.button`
  padding: 5px;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  color: #134771;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid #f47631;
    color: #053257;
  }
  &.active:hover {
    border-bottom: 2px solid #f47631;
    color: #053257;
  }
  &:hover {
    color: #053257;
    border-bottom: 2px solid #053257;
  }
`;

const StyledListItem = styled.li`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSideBarNav = styled.ul`
  padding: 5px;
  list-style: none;
  display: flex;
  background: white;
  border-top: 1px solid rgba(188, 188, 188, 0.4);
`;

export default TodoSideBarNav;
