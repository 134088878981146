import React from "react";
import styled from "styled-components";

const DateHeader = ({ calendar }) => {
  return (
    <StyledDateWrapper>
      <StyledBusName></StyledBusName>
      {calendar.map((day) => {
        return <StyledDayDiv key={day}> {day.format("DD.MM.YY")}</StyledDayDiv>;
      })}
    </StyledDateWrapper>
  );
};
const StyledDayDiv = styled.div`
  width: calc(100% / 8);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBusName = styled.div`
  width: calc(100% / 8);
  padding: 10px;
  /* border: 1px solid black; */
`;

const StyledDateWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px 10px 0 0;
`;

export default DateHeader;
