import React, { createContext, useState } from "react";

export const AssemblyFaultsContext = createContext(null);

const AssemblyFaultsProvider = ({ children }) => {
  const [voivodeshipFilter, setVoivodeshipFilter] = useState([]);
  const [activeBuses, setActiveBuses] = useState([]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [doneFilter, setDoneFilter] = useState(null);
  const [priorityFilter, setPriorityFilter] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <AssemblyFaultsContext.Provider
      value={{
        voivodeshipFilter,
        setVoivodeshipFilter,
        searchStartDate,
        setSearchStartDate,
        searchEndDate,
        setSearchEndDate,
        dateFilter,
        setDateFilter,
        searchValue,
        setSearchValue,
        pageNumber,
        setPageNumber,
        doneFilter,
        setDoneFilter,
        priorityFilter,
        setPriorityFilter,
        activeBuses,
        setActiveBuses,
      }}
    >
      {children}
    </AssemblyFaultsContext.Provider>
  );
};

export default AssemblyFaultsProvider;
