import React from "react";
import styled from "styled-components";
import DateFilters from "./Components/DateFilters/DateFilters";
import DoneFilter from "./Components/DoneFilter/DoneFilter";
import PriorityFilter from "./Components/PriorityFilter/PriorityFilter";
import VoivodeshipFilter from "./Components/VoivodeshipFilter/VoivodeshipFilter";

const AssemblyFaultsAside = ({
  setDateFilters,
  dateFilters,
  voivodeshipFilter,
  setVoivodeshipFilter,
  doneFilter,
  setDoneFilter,
  priorityFilter,
  setPriorityFilter,
}) => {
  return (
    <StyledAside>
      <DoneFilter doneFilter={doneFilter} setDoneFilter={setDoneFilter} />
      <DateFilters setDateFilters={setDateFilters} dateFilters={dateFilters} />
      <PriorityFilter
        priorityFilter={priorityFilter}
        setPriorityFilter={setPriorityFilter}
      />
      <VoivodeshipFilter
        voivodeshipFilter={voivodeshipFilter}
        setVoivodeshipFilter={setVoivodeshipFilter}
      />
    </StyledAside>
  );
};

const StyledAside = styled.div`
  min-width: 200px;
  max-width: 220px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export default AssemblyFaultsAside;
