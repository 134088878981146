import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import request from "../../../../../helpers/request";
import styled from "styled-components";
import ContractApproveItemDetails from "./ContractApproveItemDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

const ContractApproveItem = ({ contract, fetchData, setPageNumber }) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [spanObject, setSpanObject] = useState(null);
  const [isSended, setIsSended] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOnOpen = () => {
    setIsShowModalOpen(true);
  };

  const sendAccept = async () => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/heat-pump-contract/contract-accepted/${contract.id}`,
        {
          accepted: true,
        }
      );

      if (status === 200) {
        fetchData(1);
        setPageNumber(1);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const sendReject = async () => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `api/heat-pump-contract/contract-rejected/${contract.id}`,
        {
          rejected: true,
        }
      );

      if (status === 200) {
        fetchData(1);
        setPageNumber(1);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleAccept = () => {
    sendAccept();
  };

  const handleReject = () => {
    sendReject();
  };

  useEffect(() => {
    if (contract) {
      if (contract.is_accepted && !contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(102, 188, 70` }}>
            <FontAwesomeIcon icon={faCheck} />{" "}
          </StyledSpan>
        );
      } else if (contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(244, 118, 49)` }}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </StyledSpan>
        );
      } else if (!contract.id_accepted && !contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `lightgray` }}>
            <FontAwesomeIcon icon={faCircle} />{" "}
          </StyledSpan>
        );
      }
    }
  }, [contract]);

  return (
    <StyledWrapper>
      <StyledSpanWrapper>{spanObject}</StyledSpanWrapper>
      <StyledInfoWrapper>
        <StyledContractNameWrapper>
          {contract.full_contract_number && (
            <StyledContractName
              onClick={handleOnOpen}
              title={contract.full_contract_number}
            >
              {contract.full_contract_number}
            </StyledContractName>
          )}
        </StyledContractNameWrapper>
        <StyledContractDate>
          Data utworzenia:{" "}
          {moment(contract.created_at).format("DD.MM.YY HH:mm")}
        </StyledContractDate>
      </StyledInfoWrapper>

      <ContractApproveItemDetails
        isShowModalOpen={isShowModalOpen}
        handleOnClose={handleOnClose}
        contract={contract}
        handleAccept={handleAccept}
        handleReject={handleReject}
        isSended={isSended}
      />
    </StyledWrapper>
  );
};

const StyledInfoWrapper = styled.div`
  flex: 1;
  min-width: 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledSpan = styled.span`
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const StyledSpanWrapper = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContractDate = styled.div``;

const StyledContractName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #053257;
  }
`;

const StyledContractNameWrapper = styled.div`
  padding-bottom: 5px;
`;

const StyledWrapper = styled.div`
  background: white;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  display: flex;
  min-width: 0;
`;

export default ContractApproveItem;
