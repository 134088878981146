import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Modal from "../../../../../../../../../components/Modal";
const ShowPropertyDetails = ({
  propertyDetails,
  isModalOpen,
  handleOnClose,
  handleOnEdit,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledWrapper>
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledSectionTitle>Dane nieruchomości</StyledSectionTitle>
        <StyledItemWrapper>
          <StyledItemTitle>Ulica:</StyledItemTitle>
          <StyledItemValue>{propertyDetails?.street}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Numer budynku:</StyledItemTitle>
          <StyledItemValue>{propertyDetails?.building_number}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Kod-pocztowy:</StyledItemTitle>
          <StyledItemValue>{propertyDetails?.post_code}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Miejscowość:</StyledItemTitle>
          <StyledItemValue>{propertyDetails?.city}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Poczta:</StyledItemTitle>
          <StyledItemValue>{propertyDetails?.post}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Gmina:</StyledItemTitle>
          <StyledItemValue>{propertyDetails?.community}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Powiat:</StyledItemTitle>
          <StyledItemValue>{propertyDetails?.district}</StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Status Nieruchomości:</StyledItemTitle>
          {Number(propertyDetails?.property_status) === 1 && (
            <StyledItemValue>Własność</StyledItemValue>
          )}
          {Number(propertyDetails?.property_status) === 2 && (
            <StyledItemValue>Współwłasność</StyledItemValue>
          )}
          {Number(propertyDetails?.property_status) === 3 && (
            <StyledItemValue>Inne</StyledItemValue>
          )}
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Numer ewidencyjny działki:</StyledItemTitle>
          <StyledItemValue>
            {propertyDetails?.plot_registration_number}
          </StyledItemValue>
        </StyledItemWrapper>
      </StyledWrapper>
      <StyledButtonWrapper>
        <StyledEditButton onClick={handleOnEdit}>Edytuj</StyledEditButton>
      </StyledButtonWrapper>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  padding: 10px 0;

  align-items: center;
  justify-content: flex-start;
`;

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0;
`;

const StyledItemTitle = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;
const StyledItemValue = styled.div``;
const StyledWrapper = styled.div``;
const StyledItemWrapper = styled.div`
  padding: 5px 0;
  display: flex;
`;

export default ShowPropertyDetails;
