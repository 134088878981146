import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../components/Modal";
import request from "../../../../../../../helpers/request";

const validationSchema = yup.object().shape({
  name: yup.string().required("Proszę podać nazwę"),
});

const AddInstalationType = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const location = useLocation();
  const history = useHistory();
  const [isSended, setIsSended] = useState(false);

  const resetInputValue = () => {
    reset({
      name: "",
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      if (isEditMode) {
        const { status } = await request.patch(
          `/api/instalations-type/${editObject.id}`,
          { ...data }
        );
        if (status === 200) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      } else {
        const { status } = await request.post("/api/instalations-type", {
          ...data,
        });
        if (status === 200) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
    fetchData();
    resetInputValue();
    handleOnClose();
  };

  useEffect(() => {
    if (editObject) {
      setValue("name", editObject.name);
    } else {
      setValue("name", "");
    }
  }, [editObject]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledHeader>
          <StyledCloseButton onClick={handleOnClose} type="button">
            <FontAwesomeIcon icon={faTimes} />
          </StyledCloseButton>
        </StyledHeader>

        <StyledInput>
          <div className="input-group">
            <label>Nazwa</label>
            <input type="text" {...register("name")} />
          </div>
        </StyledInput>
        {errors?.name && <span className="error">{errors.name.message}</span>}

        <StyledButtonWrapper>
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            {isEditMode ? "Zapisz" : "Dodaj"}
          </button>
        </StyledButtonWrapper>
      </StyledForm>
    </Modal>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  button {
    border: none;
    padding: 0.8rem 1rem;
    margin: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
    &.close {
      color: #134771;
      background: transparent;
    }
    &.close:hover {
      color: #053257;
      text-decoration: underline;
    }
    &.add {
      background: #134771;
      color: #cadeee;
    }
    &.add:hover {
      background: #053257;
    }
  }
`;

const StyledCloseButton = styled.button`
  border: none;
  outline: none;
  color: #134771;
  background: transparent;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    color: #053257;
  }
`;

const StyledHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
`;
const StyledForm = styled.form`
  max-width: 360px;

  span.error {
    font-size: 12px;
    color: red;
  }
`;
const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddInstalationType;
