import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserSideBarList from "./UserSideBarList";

const UserSideBar = ({ todos, fetchData }) => {
  const [doneTodo, setDoneToDo] = useState([]);
  const [filterTodo, setFilterToDo] = useState([]);
  const [filterOption, setFilterOption] = useState(1);
  const [hideOld, setHideOld] = useState(false);
  const today = new Date();

  const TaskFilter = () => {
    FilterToDo();
  };

  useEffect(() => {
    if (hideOld) {
      setDoneToDo(
        todos.filter((todo) => {
          const refferentDate = new Date(todo.date);
          return (
            todo.done === 0 &&
            refferentDate.getDate() >= today.getDate() &&
            refferentDate.getMonth() >= today.getMonth() &&
            refferentDate.getFullYear() >= today.getFullYear()
          );
        })
      );
    } else {
      setDoneToDo(todos.filter((todo) => todo.done === 0));
    }
  }, [todos]);

  useEffect(() => {
    if (hideOld) {
      setDoneToDo(
        todos.filter((todo) => {
          const refferentDate = new Date(todo.date);
          return (
            todo.done === 0 &&
            refferentDate.getDate() >= today.getDate() &&
            refferentDate.getMonth() >= today.getMonth() &&
            refferentDate.getFullYear() >= today.getFullYear()
          );
        })
      );
    } else {
      setDoneToDo(todos.filter((todo) => todo.done === 0));
    }
  }, [todos, hideOld]);

  useEffect(() => {
    TaskFilter();
  }, [doneTodo]);

  const FilterToDo = () => {
    if (filterOption === 1) {
      setFilterToDo(doneTodo);
    } else if (filterOption === 2) {
      setFilterToDo(
        doneTodo.filter((todo) => {
          const refferentDate = new Date(todo.date);
          return (
            refferentDate.getDate() === today.getDate() &&
            refferentDate.getMonth() === today.getMonth() &&
            refferentDate.getFullYear() === today.getFullYear()
          );
        })
      );
    } else if (filterOption === 3) {
      setFilterToDo(
        doneTodo.filter((todo) => {
          const refferentDate = new Date(todo.date);
          const tommorrow = new Date(today);
          tommorrow.setDate(tommorrow.getDate() + 1);
          return (
            refferentDate.getDate() === tommorrow.getDate() &&
            refferentDate.getMonth() === tommorrow.getMonth() &&
            refferentDate.getFullYear() === tommorrow.getFullYear()
          );
        })
      );
    } else if (filterOption === 4) {
      setFilterToDo(
        doneTodo.filter((todo) => {
          const refferentDate = new Date(todo.date);
          return (
            refferentDate.getDate() < today.getDate() &&
            refferentDate.getMonth() <= today.getMonth() &&
            refferentDate.getFullYear() <= today.getFullYear()
          );
        })
      );
    }
  };

  useEffect(() => {
    FilterToDo();
  }, [filterOption]);

  return (
    <StyledUserlist>
      <UserSideBarList
        todoList={filterTodo}
        fetchData={fetchData}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        setHideOld={setHideOld}
        hideOld={hideOld}
      />
    </StyledUserlist>
  );
};

const StyledUserlist = styled.div`
  width: 26%;
  max-height: 400px;
  background: #fff;
`;

export default UserSideBar;
