import { faChevronDown, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import ItemDropDown from "./ItemDropDown";

const AllCompanyDropdown = ({ departments, teams, handleOnChooseUser }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const { user } = useContext(StoreContext);
  const handleOpenDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  };
  return (
    <StyledWrapper>
      {(user.role?.id === 1 || user.role?.is === 3) && (
        <>
          <StyledButton onClick={handleOpenDropdown}>
            <StyledUserIcon icon={faUsers} />
            Cała firma
            <StyledAngleIcon icon={faChevronDown} />
          </StyledButton>
          <StyledItemWrapper
            style={isDropdownOpen ? { dispay: "block" } : { display: "none" }}
          >
            {departments &&
              departments.map((department) => {
                return (
                  <ItemDropDown
                    key={department.id}
                    title={department.name}
                    users={department.users}
                    handleOnChooseUser={handleOnChooseUser}
                  />
                );
              })}
            {teams &&
              teams.map((team) => {
                return (
                  <ItemDropDown
                    key={team.id}
                    title={team.name}
                    users={team.users}
                    handleOnChooseUser={handleOnChooseUser}
                  />
                );
              })}
          </StyledItemWrapper>
        </>
      )}
      {user.role?.id === 2 && (
        <>
          {departments &&
            departments
              .filter((department) => department.id === user.department.id)
              .map((department) => {
                return (
                  <ItemDropDown
                    key={department.id}
                    title={department.name}
                    users={department.users}
                    handleOnChooseUser={handleOnChooseUser}
                  />
                );
              })}
        </>
      )}
      {/* <ItemDropDown title="Oddziały" /> */}
    </StyledWrapper>
  );
};

const StyledItemWrapper = styled.div``;
const StyledWrapper = styled.div``;

const StyledUserIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledAngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px;
`;

const StyledButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 4px;
  position: relative;
  background: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    padding-left: 8px;
  }
`;
export default AllCompanyDropdown;
