import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Modal from "../../../../../../../../../components/Modal";
const ShowAssemblyDetails = ({
  opportunities,
  assemblyDetails,
  isModalOpen,
  handleOnClose,
  handleOnEdit,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledWrapper>
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>

        <StyledItemWrapper>
          <StyledItemTitle>Miejsce montażu paneli</StyledItemTitle>

          {Number(assemblyDetails?.roof_installation) === 1 && (
            <StyledItemValue>Dom mieszkalny</StyledItemValue>
          )}
          {Number(assemblyDetails?.roof_installation) === 2 && (
            <StyledItemValue>Budynek gospodarczy</StyledItemValue>
          )}
          {Number(assemblyDetails?.roof_installation) === 3 && (
            <StyledItemValue>Instalacja naziemna</StyledItemValue>
          )}
          {Number(assemblyDetails?.roof_installation) === 4 && (
            <StyledItemValue>Inna</StyledItemValue>
          )}
        </StyledItemWrapper>

        {Number(assemblyDetails?.roof_installation) !== 3 && (
          <>
            <StyledTitleWrapper>Wymiary budynku</StyledTitleWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>
                Czy istnieje możliwość dostarczenia projektu dachu
              </StyledItemTitle>

              {Number(assemblyDetails?.roof_design) === 1 && (
                <StyledItemValue>Tak</StyledItemValue>
              )}
              {Number(assemblyDetails?.roof_design) === 2 && (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Długość budynku</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.building_length &&
                  `${assemblyDetails?.building_length} m`}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Szerokość budynku</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.building_width &&
                  `${assemblyDetails?.building_width} m`}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Wysokość do krawędzi dachu</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.building_height &&
                  `${assemblyDetails.building_height} m`}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Długość krawędzi dachu</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.roof_edge_length &&
                  `${assemblyDetails?.roof_edge_length} m`}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Kąt pochylenia dachu</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.roof_angle &&
                  `${assemblyDetails?.roof_angle}  ${"\u00b0"} `}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Wymiary zostały </StyledItemTitle>
              {Number(assemblyDetails?.dimension_are) === 1 && (
                <StyledItemValue>Zmierzone</StyledItemValue>
              )}
              {Number(assemblyDetails?.dimension_are) === 2 && (
                <StyledItemValue>Oszacowane</StyledItemValue>
              )}
            </StyledItemWrapper>
          </>
        )}
        <StyledTitleWrapper>Dane montażowe</StyledTitleWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Budynek odebrany</StyledItemTitle>

          {Number(assemblyDetails?.building_received) === 1 && (
            <StyledItemValue>Tak</StyledItemValue>
          )}
          {Number(assemblyDetails?.building_received) === 2 && (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        {Number(assemblyDetails?.roof_installation) !== 3 && (
          <>
            <StyledItemWrapper>
              <StyledItemTitle>Izolacja dachu</StyledItemTitle>

              {Number(assemblyDetails?.roof_insulation) === 1 && (
                <StyledItemValue>Izolacja wierzchnia</StyledItemValue>
              )}
              {Number(assemblyDetails?.roof_insulation) === 2 && (
                <StyledItemValue>Izolacja spodnia</StyledItemValue>
              )}
              {Number(assemblyDetails?.roof_insulation) === 3 && (
                <StyledItemValue>Brak izolacji</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Instalacja odgromowa</StyledItemTitle>
              {Number(assemblyDetails?.lightning_protection) === 1 && (
                <StyledItemValue>Nie istnieje</StyledItemValue>
              )}
              {Number(assemblyDetails?.lightning_protection) === 2 && (
                <StyledItemValue>Istnieje</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Rodzaj dachu</StyledItemTitle>
              {Number(assemblyDetails?.roof_type) === 0 && (
                <StyledItemValue>Nie wybrano</StyledItemValue>
              )}
              {Number(assemblyDetails?.roof_type) === 1 && (
                <StyledItemValue>Dach jednospadowy</StyledItemValue>
              )}
              {Number(assemblyDetails?.roof_type) === 2 && (
                <StyledItemValue>Dach dwuspadowy</StyledItemValue>
              )}
              {Number(assemblyDetails?.roof_type) === 3 && (
                <StyledItemValue>Dach wielospadowy</StyledItemValue>
              )}
              {Number(assemblyDetails?.roof_type) === 4 && (
                <StyledItemValue>Płaski</StyledItemValue>
              )}
            </StyledItemWrapper>
          </>
        )}

        <StyledItemWrapper>
          <StyledItemTitle>Miejsce montażu falownika</StyledItemTitle>
          {Number(assemblyDetails?.inverter_mounting_location) === 1 && (
            <StyledItemValue>W budynku</StyledItemValue>
          )}
          {Number(assemblyDetails?.inverter_mounting_location) === 2 && (
            <StyledItemValue>Poza budynkiem</StyledItemValue>
          )}
        </StyledItemWrapper>
        {Number(assemblyDetails?.inverter_mounting_location) === 1 && (
          <>
            <StyledItemWrapper>
              <StyledItemTitle>Opis miejsca montażu falownika</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.inverter_mounting_location_description_1}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Całkowita powierzcznia użytkowa</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.total_usable_area} m<sup>2</sup>
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Kubatura</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.volume} m<sup>3</sup>
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Ilość kondygnacji</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.number_of_floors}
              </StyledItemValue>
            </StyledItemWrapper>
          </>
        )}
        {Number(assemblyDetails?.inverter_mounting_location) === 2 && (
          <>
            <StyledItemWrapper>
              <StyledItemTitle>Opis miejsca montażu falownika</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.inverter_mounting_location_description_2}
              </StyledItemValue>
            </StyledItemWrapper>
          </>
        )}
        {Number(assemblyDetails?.roof_installation) !== 3 && (
          <>
            <StyledSectionTitle>Rodzaje poszycia dachu</StyledSectionTitle>
            {Number(assemblyDetails?.roof_type_information) === 1 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Dachówka</StyledItemTitle>
                  {Number(assemblyDetails?.tile) === 0 && (
                    <StyledItemValue>Nie wybrano</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.tile) === 1 && (
                    <StyledItemValue>Betonowa</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.tile) === 2 && (
                    <StyledItemValue>Ceramiczna</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.tile) === 3 && (
                    <StyledItemValue>Karpiówka</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.tile) === 4 && (
                    <StyledItemValue>Inne</StyledItemValue>
                  )}
                </StyledItemWrapper>
                <StyledItemWrapper>
                  <StyledItemTitle>Wymiary dachówki</StyledItemTitle>
                  <StyledItemValue>
                    {assemblyDetails?.tile_dimension}
                  </StyledItemValue>
                </StyledItemWrapper>
                {Number(assemblyDetails?.tile) === 4 && (
                  <StyledItemWrapper>
                    <StyledItemTitle>Dachówka inne</StyledItemTitle>
                    <StyledItemValue>
                      {assemblyDetails?.tile_other}
                    </StyledItemValue>
                  </StyledItemWrapper>
                )}
              </>
            )}
            {Number(assemblyDetails?.roof_type_information) === 2 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Blacha</StyledItemTitle>
                  {Number(assemblyDetails?.steel_tile) === 0 && (
                    <StyledItemValue>Nie wybrano</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.steel_tile) === 1 && (
                    <StyledItemValue>Blachodachówka</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.steel_tile) === 2 && (
                    <StyledItemValue>Trapezowa</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.steel_tile) === 3 && (
                    <StyledItemValue>Płaska</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.steel_tile) === 4 && (
                    <StyledItemValue>Rąbek</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.steel_tile) === 5 && (
                    <StyledItemValue>Inne</StyledItemValue>
                  )}
                </StyledItemWrapper>
                {Number(assemblyDetails?.steel_tile) === 4 && (
                  <StyledItemWrapper>
                    <StyledItemTitle>Dachówka inne</StyledItemTitle>
                    <StyledItemValue>
                      {assemblyDetails?.steel_tile_other}
                    </StyledItemValue>
                  </StyledItemWrapper>
                )}
              </>
            )}

            {Number(assemblyDetails?.roof_type_information) === 3 && (
              <>
                <StyledItemWrapper>
                  <StyledItemTitle>Inne</StyledItemTitle>
                  {Number(assemblyDetails?.other_tile_other) === 0 && (
                    <StyledItemValue>Nie wybrano</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 1 && (
                    <StyledItemValue>Gont Bitumiczny</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 2 && (
                    <StyledItemValue>Papa + deskowanie</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 3 && (
                    <StyledItemValue>Ondulina + płyta</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 4 && (
                    <StyledItemValue>Beton</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 5 && (
                    <StyledItemValue>Papa + beton</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 6 && (
                    <StyledItemValue>Membrana EPDM/PVC</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 7 && (
                    <StyledItemValue>Podsypka żwirowa</StyledItemValue>
                  )}
                  {Number(assemblyDetails?.other_tile_other) === 8 && (
                    <StyledItemValue>Inne</StyledItemValue>
                  )}
                </StyledItemWrapper>

                {Number(assemblyDetails?.other_tile_other) === 5 && (
                  <StyledItemWrapper>
                    <StyledItemTitle>Inne</StyledItemTitle>
                    <StyledItemValue>
                      {assemblyDetails?.other_tile_other}
                    </StyledItemValue>
                  </StyledItemWrapper>
                )}
              </>
            )}
          </>
        )}

        {Number(assemblyDetails.roof_installation) === 3 && (
          <>
            <StyledTitleWrapper>Instalacja naziemna</StyledTitleWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>
                Długość boku 1 miejsca pod instalacje
              </StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.side_one_length}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>
                Długość boku 2 miejsca pod instalacje
              </StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.side_two_length}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Rodzaj podłoża</StyledItemTitle>
              {Number(assemblyDetails?.soil_type_substrate) === 0 && (
                <StyledItemValue>Nie wybrano</StyledItemValue>
              )}
              {Number(assemblyDetails?.soil_type_substrate) === 1 && (
                <StyledItemValue>Beton</StyledItemValue>
              )}
              {Number(assemblyDetails?.soil_type_substrate) === 2 && (
                <StyledItemValue>Grunt</StyledItemValue>
              )}
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Rodzaj podłoża inne</StyledItemTitle>
              <StyledItemValue>
                {assemblyDetails?.soil_type_substrate_other}
              </StyledItemValue>
            </StyledItemWrapper>
          </>
        )}

        <StyledTitleWrapper>Przyłącze elektryczne </StyledTitleWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Długość przewodu DC</StyledItemTitle>
          <StyledItemValue>
            {assemblyDetails?.dc_wire_length &&
              `${assemblyDetails?.dc_wire_length} m`}
          </StyledItemValue>
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Długość przewodu AC</StyledItemTitle>
          <StyledItemValue>
            {assemblyDetails?.ac_wire_length &&
              `${assemblyDetails?.ac_wire_length} m`}
          </StyledItemValue>
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Długość przekopu</StyledItemTitle>
          <StyledItemValue>
            {assemblyDetails?.cut_length && `${assemblyDetails?.cut_length} m`}
          </StyledItemValue>
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Sposób wykonania przekopu</StyledItemTitle>
          {Number(assemblyDetails?.cut_method) === 0 && (
            <StyledItemValue>Nie wybrano</StyledItemValue>
          )}
          {Number(assemblyDetails?.cut_method) === 1 && (
            <StyledItemValue>Ręcznie</StyledItemValue>
          )}
          {Number(assemblyDetails?.cut_method) === 2 && (
            <StyledItemValue>Koparką</StyledItemValue>
          )}
        </StyledItemWrapper>
        {Number(assemblyDetails?.cut_method) === 2 && (
          <>
            <StyledItemWrapper>
              <StyledItemTitle>Czy koparka może wjechać</StyledItemTitle>
              {Number(assemblyDetails?.excavator_entry) === 0 && (
                <StyledItemValue>Nie wybrano</StyledItemValue>
              )}
              {Number(assemblyDetails?.excavator_entry) === 1 && (
                <StyledItemValue>Tak</StyledItemValue>
              )}
              {Number(assemblyDetails?.excavator_entry) === 2 && (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
          </>
        )}
        <StyledTitleWrapper>
          Przyłącza ziemne mediów w okolicy:
        </StyledTitleWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Woda</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_water) !== 0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Gaz</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_gas) !== 0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Przyłącze elektryczne</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_energetic_join) !== 0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Kanalizacja/szambo</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_sewers) !== 0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle> Światłowód</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_optical_fiber) !== 0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle> Przydomowa oczyszczalnia scieków</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_sewage_treatment_plan) !==
          0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Przyłącze telekomunikacyjne</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_tel_connection) !== 0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Kanalizacja deszczowa</StyledItemTitle>
          {Number(assemblyDetails?.media_connections_rain_sawage) !== 0 ? (
            <StyledItemValue>Tak</StyledItemValue>
          ) : (
            <StyledItemValue>Nie</StyledItemValue>
          )}
        </StyledItemWrapper>
        <StyledItemWrapper>
          <StyledItemTitle>Przyłącza ziemne mediów inne</StyledItemTitle>
          <StyledItemValue>
            {assemblyDetails?.media_connections_other}
          </StyledItemValue>
        </StyledItemWrapper>

        <StyledTitleWrapper>Rozbiórka kostki </StyledTitleWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Odcinek kostki do rozbiórki</StyledItemTitle>
          <StyledItemValue>
            {assemblyDetails?.cube_dismantle_length &&
              `${assemblyDetails?.cube_dismantle_length} mb`}
          </StyledItemValue>
        </StyledItemWrapper>
      </StyledWrapper>
      <StyledButtonWrapper>
        <StyledEditButton onClick={handleOnEdit}>Edytuj</StyledEditButton>
      </StyledButtonWrapper>
    </Modal>
  );
};

const StyledTitleWrapper = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 16px;
`;
const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;
const StyledButtonWrapper = styled.div`
  display: flex;
  padding: 10px 0;

  align-items: center;
  justify-content: flex-start;
`;

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0;
`;

const StyledItemTitle = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;
const StyledItemValue = styled.div``;
const StyledWrapper = styled.div`
  min-width: 360px;
`;
const StyledItemWrapper = styled.div`
  padding: 5px 0;
  display: flex;
`;

export default ShowAssemblyDetails;
