import React, { useState } from "react";
import styled from "styled-components";
import AddAssemblyDetails from "./AddAssemblyDetails/AddAssemblyDetails";
import EditAssemblyDetails from "./EditAssemblyDetails/EditAssemblyDetails";
import ShowAssemblyDetails from "./ShowAssemblyDetails";

const AssemblyDetails = ({ assemblyDetails, opportunities, fetchData }) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOnShow = () => {
    setIsShowModalOpen(true);
  };
  const handleOnAdd = () => {
    setIsAddModalOpen(true);
  };

  const handleOnCloseShowModal = () => {
    setIsShowModalOpen(false);
  };

  const handleOnEdit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
    setIsEditModalOpen(true);
  };
  const handleOnCloseEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
  };

  const handleOnCloseAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAddModalOpen(false);
  };
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Dane Montażowe</StyledTitle>
      </StyledTitleWrapper>

      {!assemblyDetails && (
        <StyledButtonWrapper>
          <StyledButtonLight onClick={handleOnAdd}>
            Dodaj dane montażowe
          </StyledButtonLight>
        </StyledButtonWrapper>
      )}

      {assemblyDetails && (
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnShow}>
            Pokaż dane montażowe
          </StyledButton>
        </StyledButtonWrapper>
      )}

      {assemblyDetails && (
        <ShowAssemblyDetails
          opportunities={opportunities}
          assemblyDetails={assemblyDetails}
          isModalOpen={isShowModalOpen}
          handleOnClose={handleOnCloseShowModal}
          handleOnEdit={handleOnEdit}
        />
      )}

      <AddAssemblyDetails
        opportunities={opportunities}
        isModalOpen={isAddModalOpen}
        handleOnClose={handleOnCloseAddModal}
        fetchData={fetchData}
      />
      <EditAssemblyDetails
        opportunities={opportunities}
        assemblyDetails={assemblyDetails}
        isModalOpen={isEditModalOpen}
        handleOnClose={handleOnCloseEditModal}
        fetchData={fetchData}
      />
    </StyledWrapper>
  );
};
const StyledButtonLight = styled.button`
  padding: 5px 10px;
  border: none;
  background: #cadeee;
  color: #053257;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #cadeee;
  }
`;
const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div``;

export default AssemblyDetails;
