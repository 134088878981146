import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import loading from "../../../../../../../../../img/48x48.gif";
import Modal from "../../../../../../../../../components/Modal";
import request from "../../../../../../../../../helpers/request";
import ItemFile from "../ItemFile";
const validationSchema = yup.object({
  text: yup.string(),
});

const ProductOrderEdit = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  opportunities,
  handleOnOpenChangeModal,
}) => {
  const [isSended, setIsSended] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, reset, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const sendData = async (data) => {
    setIsSended(true);

    try {
      const { status } = await request.patch(
        `/api/product-order/${opportunities.project?.id}`,
        { ...data }
      );
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const resetInputValue = () => {
    reset({
      comments: "",
    });
    document.getElementById("TextArea").style.height = "60px";
  };
  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  const handleSetHeight = (e) => {
    e.target.style.height = "inherit";

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    e.target.style.height = `${height}px`;
  };
  useEffect(() => {
    if (opportunities.product_order) {
      setValue("comments", opportunities.product_order.comments);
    }
  }, [opportunities]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>

      <StyledModalWrapper>
        <StyledAddNoteForm>
          <StyledLabelWrapper>
            <StyledLabel>Dodaj projekt:</StyledLabel>
          </StyledLabelWrapper>
          <StyledFileContainer>
            {opportunities.product_order?.file && (
              <ItemFile
                file={opportunities.product_order?.file}
                setIsSended={setIsSended}
              />
            )}
          </StyledFileContainer>
          <StyledChangeButtonWrapper>
            <StyledChangeButton onClick={handleOnOpenChangeModal}>
              Podmień plik
            </StyledChangeButton>
          </StyledChangeButtonWrapper>
          <StyledLabelWrapper>
            <StyledLabel>Uwagi:</StyledLabel>
          </StyledLabelWrapper>
          <StyledTextArea
            id="TextArea"
            {...register("comments")}
            onKeyUp={handleSetHeight}
          ></StyledTextArea>

          <StyledButtonsWrapper>
            <StyledAddButton onClick={handleSubmit(handleOnSubmitwithClose)}>
              Edytuj
            </StyledAddButton>
          </StyledButtonsWrapper>
        </StyledAddNoteForm>
        {isSended && (
          <StyledSendNoteWrapper>
            <StyledSendNoteInnerWrapper>
              Przesyłanie...
              <StyledLoadingImage src={loading} alt="loading" />
            </StyledSendNoteInnerWrapper>
          </StyledSendNoteWrapper>
        )}
      </StyledModalWrapper>
    </Modal>
  );
};

const StyledChangeButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;

  background: #134771;
  color: rgb(202, 222, 238);

  &:hover {
    background: #053257;
  }
`;

const StyledChangeButtonWrapper = styled.div`
  padding: 10px 0px;
`;

const StyledFileContainer = styled.div``;

const StyledLabel = styled.p`
  color: #134771;
`;

const StyledLabelWrapper = styled.div``;

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 16px;
  padding: 10px;
  margin-top: 5px;
  overflow-y: hidden;
  border-radius: 10px;
  outline: none;
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

const StyledAddNoteForm = styled.form``;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;
const StyledAddButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;

  background: #134771;
  color: rgb(202, 222, 238);

  &:hover {
    background: #053257;
  }
`;

const StyledModalWrapper = styled.div`
  padding: 10px;
  min-width: 320px;
`;

export default ProductOrderEdit;
