import React from "react";
import styled from "styled-components";
import StatisticDateRange from "./Components/StatisticDateRange";

const StatisticFilter = ({
  searchStartDate,
  setSearchStartDate,
  searchEndDate,
  setSearchEndDate,
  setDateFilter,
}) => {
  return (
    <StyledWrapper>
      <StatisticDateRange
        searchStartDate={searchStartDate}
        setSearchStartDate={setSearchStartDate}
        searchEndDate={searchEndDate}
        setSearchEndDate={setSearchEndDate}
        setDateFilter={setDateFilter}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: white;
  flex: 1;
`;
export default StatisticFilter;
