import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../store/StoreProvider";
import AcquisitionSource from "./Components/AcquisitionSource/AcquisitionSource";
import ComplaintFilter from "./Components/ComplaintFilter/ComplaintFilter";

import ContactCreateFilter from "./Components/ContactCreateFilter";
import MakeContact from "./Components/MakeContact/MakeContact";
import UserFilter from "./Components/UserFilter/UserFilter";
import UserFilterManager from "./Components/UserFilterManager/UserFilterManager";
import VoivodeshipFilter from "./Components/VoivodeshipFilter/VoivodeshipFilter";
import WrongLeadFIlter from "./Components/WrongLeadFilter/WrongLeadFIlter";

const LeadAside = ({
  wrongLeadFilter,
  setWrongLeadFilter,
  contactCreateFilter,
  setContactCreateFilter,
  voivodeshipFilter,
  setVoivodeshipFilter,
  userFilter,
  setUserFilter,
  departmentFilter,
  setDepartmentFilter,
  teamFilter,
  setTeamFilter,
  makeContactFilter,
  setMakeContactFilter,
  acquisitionSourceFilter,
  setAcquisitionSourceFilter,
  setComplaintFilter,
  complaintFilter,
}) => {
  const { user } = useContext(StoreContext);
  return (
    <StyledAside>
      <WrongLeadFIlter
        wrongLeadFilter={wrongLeadFilter}
        setWrongLeadFilter={setWrongLeadFilter}
      />
      <ContactCreateFilter
        contactCreateFilter={contactCreateFilter}
        setContactCreateFilter={setContactCreateFilter}
      />
      <MakeContact
        makeContactFilter={makeContactFilter}
        setMakeContactFilter={setMakeContactFilter}
      />
      {(user?.role?.id === 1 || user.role?.id === 3) && (
        <ComplaintFilter
          setComplaintFilter={setComplaintFilter}
          complaintFilter={complaintFilter}
        />
      )}
      {(user?.role?.id === 1 || user.role?.id === 3) && (
        <AcquisitionSource
          acquisitionSourceFilter={acquisitionSourceFilter}
          setAcquisitionSourceFilter={setAcquisitionSourceFilter}
        />
      )}
      {(user?.role?.id === 1 || user.role?.id === 3) && (
        <UserFilter
          departmentFilter={departmentFilter}
          setDepartmentFilter={setDepartmentFilter}
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          teamFilter={teamFilter}
          setTeamFilter={setTeamFilter}
        />
      )}
      {user?.role?.id === 2 && (
        <UserFilterManager
          userFilter={userFilter}
          setUserFilter={setUserFilter}
        />
      )}
      {(user?.role?.id === 1 || user?.role?.id === 3) && (
        <VoivodeshipFilter
          voivodeshipFilter={voivodeshipFilter}
          setVoivodeshipFilter={setVoivodeshipFilter}
        />
      )}
    </StyledAside>
  );
};

const StyledAside = styled.div`
  width: 220px;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default LeadAside;
