import {
  faChevronDown,
  faChevronUp,
  faEllipsisH,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import loading from "../../../../../img/48x48.gif";
import OpportunityItem from "./OpportunityItem";

const SearchSaleOpportunity = ({
  opportunity,
  setOpportunity,
  setValue,
  chooseOpportunity,
  setChooseOpportunity,
}) => {
  const [opportunities, setOpportunities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [opportunityName, setOpportunityName] = useState("Wybierz...");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [wasSearch, setWasSearch] = useState(false);
  const content = useRef(null);
  const inputRef = useRef(null);
  const timerRef = useRef(null);

  const handleOnType = (e) => {
    setSearchText(e.target.value);
  };
  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const searchData = async (text) => {
    setWasSearch(true);
    setIsLoading(true);
    try {
      const { data, status } = await request.get(
        `/api/opportunities/search?name=${text}`
      );

      if (status === 200) {
        setOpportunities(data.data);
      }
    } catch (e) {
      console.warn(e);
    }

    setIsLoading(false);
  };

  const handleOnSearch = (e) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOnChooseOpportunity = (opportunity, e) => {
    if (e) {
      e.preventDefault();
    }
    setOpportunityName(`${opportunity.name}`);
    if (opportunity) {
      if (opportunity.company) {
        setValue("client_name", opportunity.company.name);
        setValue("street", opportunity.company.street);
        setValue("building_number", opportunity.company.building_number);
        setValue("city", opportunity.company.city);
        setValue("post_code", opportunity.company.post_code);
        setValue("post", opportunity.company.post);
        setValue("voivodeship", opportunity.company.voivodeship?.id);
      } else {
        setValue(
          "client_name",
          `${opportunity.client.first_name} ${opportunity.client.last_name}`
        );
        setValue("street", opportunity.client.street);
        setValue("building_number", opportunity.client.building_number);
        setValue("city", opportunity.client.city);
        setValue("post_code", opportunity.client.postCode);
        setValue("post", opportunity.client.post);
        setValue("voivodeship", opportunity.client.voivodeship?.id);
      }
      setValue("opportunity_id", opportunity.id);
    }
    setIsOpen(false);
    setSearchText("");
    setOpportunity(opportunity);
    setChooseOpportunity(true);
  };

  useEffect(() => {
    if (chooseOpportunity === false) {
      setOpportunities([]);
      setOpportunityName("Wybierz...");
      setSearchText("");
      setOpportunity(null);
    } else {
      setOpportunityName(`${opportunity.name}`);
    }
  }, [chooseOpportunity]);

  const deleteRelation = (e) => {
    if (e) {
      e.preventDefault();
    }
    setValue("opportunity_id", null);
    setOpportunityName("Wybierz...");
    setChooseOpportunity(false);
  };

  const handleOnClearSearch = () => {
    setSearchText("");
    setOpportunities([]);
    setWasSearch(false);
  };
  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchText.length > 3) {
      timerRef.current = setTimeout(() => {
        searchData(searchText);
      }, 1000);
    }
    return () => clearTimeout(timerRef.current);
  }, [searchText]);

  const handleOutsideClick = (event) => {
    if (content.current && !content.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  return (
    <StyledWrapper>
      <label>Wybierz szanse sprzedaży:</label>
      <StyledButtonsWrapper>
        <StyledNameWrapper onClick={handleOnClick}>
          <StyledName>{opportunityName}</StyledName>
          {isOpen ? (
            <StyledFontAwesomeIcon icon={faChevronDown} />
          ) : (
            <StyledFontAwesomeIcon icon={faChevronUp} />
          )}
        </StyledNameWrapper>
        <DeleteButton
          title="Usuń powiązanie z szansą sprzedaży"
          onClick={deleteRelation}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </DeleteButton>
      </StyledButtonsWrapper>
      <StyledDropDownContent
        ref={content}
        style={isOpen ? { display: "block" } : { display: "none" }}
      >
        <StyledSearchWrapper>
          <StyledInputWrapper>
            <StyledSearchIconWrapper onClick={handleOnSearch}>
              <StyledSearchFontAwesome icon={faSearch} />
            </StyledSearchIconWrapper>

            <StyledInput
              type="text"
              ref={inputRef}
              onInput={handleOnType}
              value={searchText}
            />

            <StyledCloseIconWrapper>
              <StyledCloseFontAwesome
                icon={faTimesCircle}
                onClick={handleOnClearSearch}
              />
            </StyledCloseIconWrapper>
          </StyledInputWrapper>
        </StyledSearchWrapper>

        {isLoading ? (
          <StyledSearchLoaderWrapper>
            <StyledLoaderImage src={loading} />
          </StyledSearchLoaderWrapper>
        ) : (
          <>
            {opportunities.length > 0 ? (
              <>
                <StyledClientListWrapper>
                  {opportunities.slice(0, 10).map((opportunity) => (
                    <OpportunityItem
                      key={opportunity.id}
                      opportunity={opportunity}
                      handleOnClick={handleOnChooseOpportunity}
                    />
                  ))}
                </StyledClientListWrapper>
                {opportunities.length > 10 && (
                  <StyledMoreWrapper>
                    <StyledMoreFontAwesomeIcon icon={faEllipsisH} />
                  </StyledMoreWrapper>
                )}
              </>
            ) : (
              <>{wasSearch && <StyledNoClient>Brak klienta</StyledNoClient>}</>
            )}
          </>
        )}
      </StyledDropDownContent>
    </StyledWrapper>
  );
};

const StyledButtonsWrapper = styled.div`
  display: flex;

  align-items: center;
  gap: 10px;
`;

const DeleteButton = styled.button`
  border: none;
  outline: none;
  color: #134771;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
  border-radius: 50%; ;
`;

const StyledCloseFontAwesome = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 2px;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: black;
  }
`;

const StyledCloseIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNoClient = styled.div`
  padding: 10px;
  font-size: 14px;
`;

const StyledMoreFontAwesomeIcon = styled(FontAwesomeIcon)``;

const StyledMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

const StyledSearchLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

const StyledLoaderImage = styled.img``;
const StyledClientListWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const StyledInputWrapper = styled.div`
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2px;
  display: flex;
`;

const StyledSearchWrapper = styled.div`
  padding: 10px;
`;

const StyledInput = styled.input`
  flex: 1;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const StyledSearchIconWrapper = styled.div`
  cursor: pointer;
  padding: 2px;
  font-size: 14px;
  color: #134771;
  margin-right: 2px;
`;

const StyledSearchFontAwesome = styled(FontAwesomeIcon)``;

const StyledDropDownContent = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: white;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 12px;
  padding: 2px 5px;
  background-color: white; ;
`;

const StyledName = styled.p`
  padding: 2px 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledNameWrapper = styled.div`
  position: relative;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 2px;
  flex: 1;
  padding-right: 20px;
  min-width: 0;
`;

const StyledWrapper = styled.div`
  position: relative;
  max-width: 360px;
`;

export default SearchSaleOpportunity;
