import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PopOver from "./PopOver";

const DayItem = ({ day, month, todoList, handleOnDayOpen, setValue }) => {
  const [todoItems, setTodoItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const CheckeIsThisMonth = () => {
    return day.isSame(month, "month");
  };

  const dayStyle = () => {
    if (CheckeIsThisMonth()) {
      if (todoItems.length > 0) {
        return "todo";
      } else {
        return "same";
      }
    }

    return "";
  };

  const handleOpenPopOver = () => {
    if (todoList.length > 0) {
      if (!isOpen) {
        setIsOpen(true);
      }
    }
  };

  useEffect(() => {
    setTodoItems(
      todoList.filter((todo) => {
        return day.isSame(new Date(todo.start_date), "day");
      })
    );
  }, [todoList]);

  const handleDayOpen = () => {
    setValue(day);
    handleOnDayOpen();
  };

  return (
    <StyledDayContainer
      className={dayStyle()}
      onMouseOver={handleOpenPopOver}
      onClick={handleDayOpen}
    >
      {day.format("D")}
      <PopOver isOpen={isOpen} todoItems={todoItems} setIsOpen={setIsOpen} />
    </StyledDayContainer>
  );
};

const StyledDayContainer = styled.div`
  width: calc(100% / 7);
  color: lightgray;
  text-align: right;
  padding: 4px;
  font-size: 14px;
  position: relative;
  &.same {
    color: black;
  }
  &.todo {
    background: #d0fd05;
    color: black;
    cursor: pointer;
  }
`;
export default DayItem;
