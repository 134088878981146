import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SearchCompanyItem = ({ company }) => {
  return (
    <StyledCompanyWrapper
      title={company.name}
      to={`/dashboard/contacts/companies/details/${company.id}`}
    >
      <StyledCompanyName>{company.name}</StyledCompanyName>
    </StyledCompanyWrapper>
  );
};

const StyledCompanyName = styled.p`
  color: #134771;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledCompanyWrapper = styled(Link)`
  background: white;
  padding: 10px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

export default SearchCompanyItem;
