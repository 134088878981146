import {
  faBuilding,
  faEnvelope,
  faPhoneAlt,
  faTachometerAlt,
  faUser,
  faUsers,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import styled from "styled-components";

const UserInfo = ({ user }) => {
  return (
    <StyledWrapper>
      <StyledUserInfo>
        <StyledNameWrapper>
          <StyledUserName>
            <FontAwesomeIcon icon={faUser} />
            {user.name}
          </StyledUserName>
          <StyledEditButton>Edytuj</StyledEditButton>
        </StyledNameWrapper>
      </StyledUserInfo>
      <StyledUserDescription>
        {user.phone && (
          <StyledPhoneWrapper>
            <FontAwesomeIcon icon={faPhoneAlt} />
            <StyledPhoneLink href={`tel:${user.phone}`}>
              {user.phone}
            </StyledPhoneLink>
          </StyledPhoneWrapper>
        )}
        {user.role && (
          <StyledRoleWrapper>
            <FontAwesomeIcon icon={faUserTag} />
            <StyledRoleP>{user.role.name}</StyledRoleP>
          </StyledRoleWrapper>
        )}
        {user.department && (
          <StyledDepartmentWrapper>
            <FontAwesomeIcon icon={faBuilding} />
            <StyledDepartmentP>{user.department.name}</StyledDepartmentP>
          </StyledDepartmentWrapper>
        )}
        <StyledEmailWrapper>
          <FontAwesomeIcon icon={faEnvelope} />
          <StyledEmailLink href={`email:${user.email}`}>
            {user.email}
          </StyledEmailLink>
        </StyledEmailWrapper>
      </StyledUserDescription>
    </StyledWrapper>
  );
};

const StyledPhoneWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledEmailWrapper = styled.div`
  margin: 10px 0;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledDepartmentWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledDepartmentP = styled.p`
  color: black;
`;

const StyledRoleWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  svg {
    font-size: 14px;
    margin-right: 5px;
  }
`;
const StyledRoleP = styled.p`
  color: black;
`;
const StyledPhoneLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: #777;
    text-decoration: underline;
  }
`;
const StyledEmailLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: #777;
    text-decoration: underline;
  }
`;

const StyledUserInfo = styled.div`
  padding: 20px 10px;
  background: white;
`;
const StyledUserDescription = styled.div`
  padding: 20px 10px;
  background: white;
`;
const StyledEditButton = styled.button`
  cursor: pointer;
  background: transparent;
  padding: 5px 10px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #2d96db;
  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;

const StyledNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledWrapper = styled.div`
  width: 24%;
  min-width: 300px;

  /* padding: 10px; */
  @media screen and (max-width: 1300px) {
    width: 100%;
    padding-right: 10px;
  }
`;

const StyledUserName = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export default UserInfo;
