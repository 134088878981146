import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import requestMulti from "../../../../../helpers/requestMulti";

const PdfFileRenderer = ({
  paybackPeriodName,
  barRef,
  pitValue,
  buyRatePerKilowattHour,
  sellRatePerMegawattHour,
  setIsSended,
  selfConsumption,
  increaseElectricityBill,
  saving,
  serviceLive,
  investmentAmount,
}) => {
  const history = useHistory();
  const location = useLocation();

  const sendData = async (data) => {
    const formData = new FormData();

    formData.append("pit_value", pitValue);
    formData.append("investment_amount", investmentAmount);
    formData.append("rate_per_kilowatt", buyRatePerKilowattHour);
    formData.append("sell_rate_per_megawatt", sellRatePerMegawattHour);
    formData.append("self_consumption", selfConsumption);
    formData.append("increase_electricity_bill", increaseElectricityBill);
    formData.append("saving", saving);
    formData.append("service_live", serviceLive);

    if (paybackPeriodName) {
      formData.append("payback_period", paybackPeriodName);
    }
    if (barRef.current) {
      const bar = barRef.current.toBase64Image();
      formData.append("bar", bar);
    }

    setIsSended(true);
    try {
      const { data, status } = await requestMulti.post(
        `/api/yield-pdf`,
        formData,
        {
          responseType: "blob",
        }
      );
      if (status === 200) {
        const href = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "oszczędności.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsSended(false);
      } else {
        setIsSended(false);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  return (
    <StyledWrapper>
      {buyRatePerKilowattHour > 0 && barRef.current && (
        <>
          <StyledButtonWrapper>
            <StyledGenerateButton onClick={sendData}>
              Wygeneruj PDF
            </StyledGenerateButton>
          </StyledButtonWrapper>
        </>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;
const StyledBarWrapper = styled.div``;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
`;

const StyledGenerateButton = styled.button`
  padding: 0.8rem 1rem;
  width: 100%;
  background: #2d96db;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;
export default PdfFileRenderer;
