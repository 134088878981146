import React from "react";
import styled from "styled-components";

const SummaryOpportunitiesItem = ({ opportunities }) => {
  return (
    <StyledWrapper>
      <p>{opportunities} szans sprzedaży</p>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;
export default SummaryOpportunitiesItem;
