import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LeadFilter from "../LeadFilter/LeadFilter";
import LeadsItem from "./Components/LeadsItem/LeadsItem";
import AddLead from "../AddLead/AddLead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import loading from "../../../../img/48x48.gif";
const LeadsList = ({
  leads,
  wrongLeadFilter,
  contactCreateFilter,
  voivodeshipFilter,
  userFilter,
  teamFilter,
  departmentFilter,
  isLoading,
  fetchLeads,
  pageCount,
  setPageNumber,
  pageNumber,
  makeContactFilter,
  acquisitionSourceFilter,
  complaintFilter,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setPageNumber(1);
      fetchLeads(1);
    }
  }, [
    wrongLeadFilter,
    contactCreateFilter,
    voivodeshipFilter,
    userFilter,
    teamFilter,
    departmentFilter,
    makeContactFilter,
    acquisitionSourceFilter,
    complaintFilter,
  ]);

  const handleOnChangePage = (event) => {
    window.scrollTo(0, 0);
    setPageNumber(event.selected + 1);
    fetchLeads(event.selected + 1);
  };

  const handleOnClickEdit = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setIsEditMode(false);
    setEditObject(null);
  };

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <div className="header">
          <LeadFilter />
        </div>
        {isLoading ? (
          <StyledLoadingWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledLoadingWrapper>
        ) : (
          <>
            {leads && leads.length > 0 ? (
              <>
                {leads.map((lead) => {
                  return (
                    <LeadsItem
                      key={lead.id}
                      lead={lead}
                      fetchLeads={fetchLeads}
                      handleOnClickEdit={handleOnClickEdit}
                      setEditObject={setEditObject}
                    />
                  );
                })}
              </>
            ) : (
              <>
                <StyledEmptySearchRecords>
                  <StyledEmptyInfo>
                    <StyledEmptyText>Brak wyników</StyledEmptyText>
                  </StyledEmptyInfo>
                </StyledEmptySearchRecords>
              </>
            )}
          </>
        )}
      </StyledContentWrapper>
      <StyledPaginateWrapper>
        <StyledReactPaginated
          breakLabel="..."
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
          pageClassName="paginate_button"
          disableInitialCallback={isLoading}
          onPageChange={(event) => handleOnChangePage(event)}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          renderOnZeroPageCount={null}
          forcePage={pageNumber - 1}
        />
      </StyledPaginateWrapper>

      <AddLead
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={() => fetchLeads(1)}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledWrapper>
  );
};

const StyledEmptySearchRecords = styled.div`
  background: white;
  border-radius: 10px;
  padding: 10px;
  height: calc(100vh - 260px);
`;
const StyledEmptyInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledEmptyText = styled.p`
  color: #134771;
`;

const StyledLoadingImage = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledLoadingWrapper = styled.div`
  background: white;
  height: calc(100vh - 260px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
`;

const StyledReactPaginated = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .paginate_button {
    list-style: none;
    color: #134771;
    &.selected {
      color: rgb(244, 118, 49);
    }
  }
  & .paginate_button a {
    padding: 10px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .break {
    list-style: none;
  }
  & .break a {
    padding: 10px;
    border: none;
    font-size: 18px;
    color: #134771;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }

  & .previous {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }

  & .previous a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
  & .next {
    list-style: none;
    color: #134771;
    cursor: pointer;
    &.disabled {
      color: rgb(244, 118, 49);
      pointer-events: none;
      cursor: none;
    }
  }
  & .next a {
    padding: 10px;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #cadeee;
    }
  }
`;

const StyledPaginateWrapper = styled.div``;

const StyledContentWrapper = styled.div`
  margin: 0 10px;

  border-radius: 10px;
  padding-bottom: 10px;
`;

const StyledWrapper = styled.div`
  flex: 1;

  min-width: 0;
  .header {
    display: flex;
    border-radius: 10px 10px 0 0;
    justify-content: space-between;
    align-items: center;
    position: relative;
    p {
      padding: 10px;
    }
  }
`;

export default LeadsList;
