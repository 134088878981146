import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import request from "../../../helpers/request";
import fileSvg from "../../../img/file.svg";
import pdfSvg from "../../../img/pdf-download-2617.svg";
import DeleteFileAlert from "./File/DeleteFileAlert";
import DeleteImageAlert from "./File/DeleteImageAlert";

const EditFileItem = ({ fileItem, fetchData, setIsModalOpen }) => {
  const [imageFile, setImageFile] = useState(null);
  const [file, setFile] = useState(null);
  const [isFileAlertOpen, setIsFileAlertOpen] = useState(false);
  const [isImageAlertOpen, setIsImageAlertOpen] = useState(false);

  useEffect(() => {
    if (
      fileItem.mime_type === "image/jpg" ||
      fileItem.mime_type === "image/png" ||
      fileItem.mime_type === "image/jpeg"
    ) {
      setImageFile(fileItem);
    } else {
      setFile(fileItem);
    }
  }, [fileItem]);

  const handleOnOpenFileAlert = (e) => {
    e.preventDefault();
    setIsFileAlertOpen(true);
    setIsModalOpen(false);
  };
  const handleOnOpenImageAlert = (e) => {
    e.preventDefault();
    setIsImageAlertOpen(true);
    setIsModalOpen(false);
  };

  const handleOnCloseFileAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(true);
    setIsFileAlertOpen(false);
  };
  const handleOnCloseImageAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(true);
    setIsImageAlertOpen(false);
  };
  const deleteData = async () => {
    const { status } = await request.delete(`/api/file/${fileItem.id}`);

    if (status === 201) {
      fetchData();
      setIsModalOpen(true);
      setIsImageAlertOpen(false);
      setIsFileAlertOpen(false);
    }
  };
  const handleOnDelete = (e) => {
    e.preventDefault();
    deleteData();
  };
  return (
    <>
      {imageFile && (
        <StyledImageWrapper>
          <StyledDeleteButton
            title="Usuś zdjęcie"
            onClick={handleOnOpenImageAlert}
          >
            <FontAwesomeIcon icon={faTimes} />
          </StyledDeleteButton>
          <StyledImage src={fileItem.path_name} alt={fileItem.name} />
          <StyledNameWrapper>
            <StyledName title={fileItem.name}>{fileItem.name}</StyledName>
            <StyledSize>{fileItem.size} kB</StyledSize>
          </StyledNameWrapper>
        </StyledImageWrapper>
      )}
      {file && (
        <StyledFileWrapper>
          <StyledDeleteButton title="Usuń plik" onClick={handleOnOpenFileAlert}>
            <FontAwesomeIcon icon={faTimes} />
          </StyledDeleteButton>
          <StyledNameWrapper>
            {file.mime_type === "application/pdf" ? (
              <StyledFileImgWrapper>
                <StyledFileImg src={pdfSvg} />
              </StyledFileImgWrapper>
            ) : (
              <StyledFileImgWrapper>
                <StyledFileImg src={fileSvg} />
              </StyledFileImgWrapper>
            )}
            <StyledName title={file.name}>{file.name}</StyledName>
            <StyledSize>{file.size} kB</StyledSize>
          </StyledNameWrapper>
        </StyledFileWrapper>
      )}

      <DeleteImageAlert
        isAlertOpen={isImageAlertOpen}
        handleOnClose={handleOnCloseImageAlert}
        handleOnDelete={handleOnDelete}
      />
      <DeleteFileAlert
        isAlertOpen={isFileAlertOpen}
        handleOnClose={handleOnCloseFileAlert}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};

const StyledFileImg = styled.img`
  max-height: 80px;
`;

const StyledFileImgWrapper = styled.div``;

const StyledDeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: 2px solid #134771;
  color: #134771;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: white;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledSize = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const StyledName = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledNameWrapper = styled.div`
  font-size: 14px;
  text-align: center;
`;
const StyledFileWrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
`;

const StyledImageWrapper = styled.div`
  padding: 10px;
  position: relative;
`;

export default EditFileItem;
