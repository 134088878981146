import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, Redirect } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import request from "../../helpers/request";
import SearchClientItem from "./Components/SearchClientItem";
import SearchCompanyItem from "./Components/SearchCompanyItem";
import SearchOpportunityItem from "./Components/SearchOpportunityItem";

import loading from "../../img/48x48.gif";
import SearchLeadItem from "./Components/SearchLeadItem";

const validationSchema = yup.object({
  text: yup.string(),
});

const Search = ({ isSearchOpen, setIsSearchOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const { pathname } = useLocation();
  const dropRef = useRef(null);
  const inputRef = useRef();
  const [searchText, setSearchText] = useState("");

  const [searchClients, setSearchClients] = useState([]);
  const [clientCount, setClientCount] = useState(0);
  const [searchOpportunities, setSearchOpportunities] = useState([]);
  const [opportunitiesCount, setOpportunitiesCount] = useState(0);
  const [searchCompanies, setSearchCompanies] = useState([]);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [searchLeads, setSearchLeads] = useState([]);
  const [leadsCount, setLeadsCount] = useState(0);
  const [searching, setSearching] = useState(false);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsSearchOpen(false);
      setSearchClients([]);
      setClientCount(0);
      setOpportunitiesCount(0);
      setCompaniesCount(0);
      setSearchCompanies([]);
      setSearchOpportunities([]);
      setSearchLeads([]);
      setLeadsCount(0);
      setIsSearchOpen(false);
      setSearchText("");
      setValue("text", "");
    }
  };

  useEffect(() => {
    if (isSearchOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  const sendData = async (query) => {
    setSearching(true);
    try {
      const { data, status } = await request.get(
        `/api/search?text=${query.text}`
      );

      if (status === 200) {
        setSearchClients(data.clients);
        setClientCount(data.clientCount);
        setSearchCompanies(data.companies);
        setCompaniesCount(data.companiesCount);
        setSearchOpportunities(data.opportunities);
        setOpportunitiesCount(data.opportunitiesCount);
        setSearchLeads(data.leads);
        setLeadsCount(data.leadsCount);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        return <Redirect to="login" from={pathname} />;
      }
    }
    setSearching(false);
  };

  const onSubmit = async (data) => {
    return <Redirect to={`/dashboard/search?text=${data.text}`} />;
  };
  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setSearchClients([]);
    setClientCount(0);
    setOpportunitiesCount(0);
    setCompaniesCount(0);
    setSearchCompanies([]);
    setSearchOpportunities([]);
    setSearchLeads([]);
    setLeadsCount(0);
    setIsSearchOpen(false);
    setSearchText("");
    setValue("text", "");
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length >= 3) {
        const data = { text: searchText };
        sendData(data);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (isSearchOpen) {
      setFocus("text");
    } else {
    }
  }, [isSearchOpen]);

  return (
    <StyledSearchWrapper
      ref={dropRef}
      style={isSearchOpen ? { display: "flex" } : { display: "none" }}
    >
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledInputWrapper>
          <StyledInput
            ref={inputRef}
            onInput={(e) => setSearchText(e.target.value)}
            {...register("text")}
            autoComplete="off"
          />
          {errors?.text && <span className="error">{errors.text.message}</span>}
        </StyledInputWrapper>
        <StyledButton type="button" onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledButton>
      </StyledForm>
      {searching && (
        <StyledLoadingFormWrapper>
          <img src={loading} alt="loading" />
        </StyledLoadingFormWrapper>
      )}

      {(searchCompanies?.length > 0 ||
        searchOpportunities?.length > 0 ||
        searchClients?.length > 0 ||
        searchLeads?.length > 0) &&
        !searching && (
          <>
            <StyledSearchItemWrapper>
              {searchClients.length > 0 && (
                <StyledClientWrapper>
                  <StyledTitleWrapper>
                    <SearchItemTitle>Osoby</SearchItemTitle>
                    {searchClients.length > 3 && (
                      <SearchItemCount
                        to={`/dashboard/search?text=${searchText}`}
                      >
                        Więcej wyników ({clientCount - 3})
                      </SearchItemCount>
                    )}
                  </StyledTitleWrapper>

                  {searchClients.slice(0, 3).map((client) => (
                    <SearchClientItem client={client} key={client.id} />
                  ))}
                </StyledClientWrapper>
              )}
              {searchOpportunities.length > 0 && (
                <StyledOpportunityWrapper>
                  <StyledTitleWrapper>
                    <SearchItemTitle>Szanse sprzedaży</SearchItemTitle>
                    {searchOpportunities.length > 3 && (
                      <SearchItemCount
                        to={`/dashboard/search?text=${searchText}`}
                      >
                        Więcej wyników ({opportunitiesCount - 3})
                      </SearchItemCount>
                    )}
                  </StyledTitleWrapper>
                  {searchOpportunities.slice(0, 3).map((opportunity) => (
                    <SearchOpportunityItem
                      opportunity={opportunity}
                      key={opportunity.id}
                    />
                  ))}
                </StyledOpportunityWrapper>
              )}
              {searchCompanies.length > 0 && (
                <StyledCompanyWrapper>
                  <StyledTitleWrapper>
                    <SearchItemTitle>Firmy</SearchItemTitle>

                    {searchCompanies.length > 3 && (
                      <SearchItemCount
                        to={`/dashboard/search?text=${searchText}`}
                      >
                        Więcej wyników ({companiesCount - 3})
                      </SearchItemCount>
                    )}
                  </StyledTitleWrapper>
                  {searchCompanies.slice(0, 3).map((company) => (
                    <SearchCompanyItem company={company} key={company.id} />
                  ))}
                </StyledCompanyWrapper>
              )}
              {searchLeads.length > 0 && (
                <StyledLeadsWrapper>
                  <StyledTitleWrapper>
                    <SearchItemTitle>Leady</SearchItemTitle>

                    {searchLeads.length > 3 && (
                      <SearchItemCount
                        to={`/dashboard/search?text=${searchText}`}
                      >
                        Więcej wyników ({leadsCount - 3})
                      </SearchItemCount>
                    )}
                  </StyledTitleWrapper>
                  {searchLeads.slice(0, 3).map((lead) => (
                    <SearchLeadItem lead={lead} key={lead.id} />
                  ))}
                </StyledLeadsWrapper>
              )}
            </StyledSearchItemWrapper>
          </>
        )}
    </StyledSearchWrapper>
  );
};

const StyledLoadingFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const SearchItemCount = styled(Link)`
  margin-left: 10px;
  font-size: 14px;
  color: #134771;
  text-decoration: none;
  &:hover {
    color: #053257;
    text-decoration: underline;
  }
`;

const SearchItemTitle = styled.div`
  display: flex;
  align-items: center;
  color: #134771;
`;
const StyledCompanyWrapper = styled.div``;
const StyledOpportunityWrapper = styled.div``;
const StyledClientWrapper = styled.div``;
const StyledLeadsWrapper = styled.div``;

const StyledSearchItemWrapper = styled.div`
  padding-bottom: 10px;
`;
const StyledInputWrapper = styled.div`
  flex: 1;
`;
const StyledSearchWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;

  right: 10px;
  top: 20px;
  max-width: 300px;
  border-radius: 10px;
  background-color: #cadeee;
  z-index: 10;
  display: flex;
  flex-direction: column;
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex: 1;
`;
const StyledInput = styled.input`
  background: white;
  border: none;
  outline: none;
  border-radius: 5px 0 0 5px;
  color: #134771;
  padding: 5px 5px;
  font-size: 16px;
  width: 100%;
  &:focus {
    color: #134771;
    font-size: 16px;
  }
`;
const StyledButton = styled.button`
  padding: 5px 5px;
  background: white;
  color: #0167a7;
  border: none;
  font-size: 16px;
  outline: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
`;

export default Search;
