import {
  faAddressCard,
  faCalculator,
  faCalendar,
  faCheckSquare,
  faClipboardCheck,
  faMoneyBill,
  faMoneyCheckAlt,
  faPoll,
  faTachometerAlt,
  faUserTag,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { faRocketchat } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { StoreContext } from "../../store/StoreProvider";
import { useState } from "react";

const AsideNav = ({ isMobileNavOpen }) => {
  const { user, uncompleteSaleOpportunities } = useContext(StoreContext);
  const [nucompleteOpporunitiesCount, setUncompleteOpportunitiesCount] =
    useState(0);

  const asideNav = useRef(null);

  useEffect(() => {
    if (asideNav) {
      [...asideNav.current.children].forEach((element, index) => {
        if ((index + 1) % 3 === 0) {
          element.children[0].children[0].style.background = "#66bc46";
        }
        if ((index + 1) % 3 === 1) {
          element.children[0].children[0].style.background = "#134771";
        }
        if ((index + 1) % 3 === 2) {
          element.children[0].children[0].style.background = "#f47631";
        }

        if (window.innerHeight <= 800) {
          element.style.height = `55px`;
          element.children[0].children[0].children[0].style.fontSize = "16px";
        } else if (window.innerHeight > 800 && window.innerHeight < 900) {
          element.style.height = `60px`;
          element.children[0].children[0].children[0].style.fontSize = "18px";
        } else if (window.innerHeight >= 900 && window.innerHeight < 1000) {
          element.style.height = `65px`;
          element.children[0].children[0].children[0].style.fontSize = "20px";
        } else {
          element.style.height = `70px`;
          element.children[0].children[0].children[0].style.fontSize = "22px";
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (uncompleteSaleOpportunities) {
      setUncompleteOpportunitiesCount(uncompleteSaleOpportunities.length);
    } else {
      setUncompleteOpportunitiesCount(0);
    }
  }, [uncompleteSaleOpportunities]);
  return (
    <StyledAsideNavWrapper
      style={isMobileNavOpen ? { left: "0" } : { left: "-240px" }}
    >
      {" "}
      <StyledList ref={asideNav}>
        <StyledListItem>
          <StyledNavLink
            to="/dashboard/home"
            activeClassName="active"
            title="Panel Główny"
          >
            {" "}
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faTachometerAlt} />
            </StyledFontAwesomeWrapper>
            <StyledName>Panel główny</StyledName>
          </StyledNavLink>
        </StyledListItem>

        <StyledListItem>
          <StyledNavLink
            to="/dashboard/lead"
            activeClassName="active"
            title="Lead"
          >
            {" "}
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faUserTag} />
            </StyledFontAwesomeWrapper>
            <StyledName> Lead</StyledName>
          </StyledNavLink>
        </StyledListItem>

        <StyledListItem>
          <StyledNavLink
            to="/dashboard/contacts"
            activeClassName="active"
            title="Kontakty"
          >
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faAddressCard} />{" "}
            </StyledFontAwesomeWrapper>
            <StyledName> Kontakty</StyledName>
          </StyledNavLink>
        </StyledListItem>
        <StyledListItem>
          <StyledNavLink
            to="/dashboard/opportunities"
            activeClassName="active"
            title="Szanse sprzedaży"
          >
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faMoneyBill} />
            </StyledFontAwesomeWrapper>
            <StyledName> Szanse sprzedaży</StyledName>
            {uncompleteSaleOpportunities > 0 && (
              <StyledAlertWrapper
                title={`Masz ${nucompleteOpporunitiesCount} szansę sprzedaży wymagającą uzupełnienia`}
              >
                {nucompleteOpporunitiesCount}
              </StyledAlertWrapper>
            )}
          </StyledNavLink>
        </StyledListItem>
        {(user?.role?.id === 1 || user?.role?.id === 2) && (
          <>
            <StyledListItem>
              <StyledNavLink
                to="/dashboard/offer-approve"
                activeClassName="active"
                title="Akceptacja ofert"
              >
                {" "}
                <StyledFontAwesomeWrapper>
                  <StyledFontAwesomeIcon icon={faClipboardCheck} />
                </StyledFontAwesomeWrapper>
                <StyledName> Akceptacja ofert</StyledName>
              </StyledNavLink>
            </StyledListItem>
          </>
        )}
        {(user?.role?.id === 1 || user?.role?.id === 3) && (
          <>
            <StyledListItem>
              <StyledNavLink
                to="/dashboard/offer-pricing"
                activeClassName="active"
                title="Wycena ofert"
              >
                {" "}
                <StyledFontAwesomeWrapper>
                  <StyledFontAwesomeIcon icon={faMoneyCheckAlt} />
                </StyledFontAwesomeWrapper>
                <StyledName>Wycena ofert</StyledName>
              </StyledNavLink>
            </StyledListItem>
            <StyledListItem>
              <StyledNavLink
                to="/dashboard/assembly-faults"
                activeClassName="active"
                title="Usterki montażowe"
              >
                {" "}
                <StyledFontAwesomeWrapper>
                  <StyledFontAwesomeIcon icon={faWrench} />
                </StyledFontAwesomeWrapper>
                <StyledName>Usterki montażowe</StyledName>
              </StyledNavLink>
            </StyledListItem>
          </>
        )}
        {user?.role?.id === 1 && (
          <StyledListItem>
            <StyledNavLink
              to="/dashboard/contract-approve"
              activeClassName="active"
              title="Akceptacja umów"
            >
              {" "}
              <StyledFontAwesomeWrapper>
                <StyledFontAwesomeIcon icon={faClipboardCheck} />
              </StyledFontAwesomeWrapper>
              <StyledName> Akceptacja umów</StyledName>
            </StyledNavLink>
          </StyledListItem>
        )}
        <StyledListItem>
          <StyledNavLink
            to="/dashboard/calendar"
            activeClassName="active"
            title="Kalendarz"
          >
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faCalendar} />{" "}
            </StyledFontAwesomeWrapper>
            <StyledName> Kalendarz</StyledName>
          </StyledNavLink>
        </StyledListItem>

        <StyledListItem>
          <StyledNavLink
            to="/dashboard/todo"
            activeClassName="active"
            title="Zadania"
          >
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faCheckSquare} />
            </StyledFontAwesomeWrapper>
            <StyledName>Zadania</StyledName>
          </StyledNavLink>
        </StyledListItem>

        {(user?.role?.id === 1 || user?.role?.id === 3) && (
          <StyledListItem>
            <StyledNavLink
              to="/dashboard/assembly"
              activeClassName="active"
              title="Planowanie montażu"
            >
              {" "}
              <StyledFontAwesomeWrapper>
                <StyledFontAwesomeIcon icon={faWrench} />
              </StyledFontAwesomeWrapper>
              <StyledName> Planowanie montażu</StyledName>
            </StyledNavLink>
          </StyledListItem>
        )}

        <StyledListItem>
          <StyledNavLink
            to="/dashboard/chat"
            activeClassName="active"
            title="Rocket chat"
          >
            {" "}
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faRocketchat} />
            </StyledFontAwesomeWrapper>
            <StyledName> Rocket Chat</StyledName>
          </StyledNavLink>
        </StyledListItem>
        {(user?.role?.id === 1 ||
          user?.role?.id === 2 ||
          user?.role?.id === 3) && (
          <StyledListItem>
            <StyledNavLink
              to="/dashboard/statistic/opportunities"
              activeClassName="active"
              title="Statstyki"
            >
              {" "}
              <StyledFontAwesomeWrapper>
                <StyledFontAwesomeIcon icon={faPoll} />
              </StyledFontAwesomeWrapper>
              <StyledName> Statystyki</StyledName>
            </StyledNavLink>
          </StyledListItem>
        )}
        <StyledListItem>
          <StyledNavLink
            to="/dashboard/quick-calculation"
            activeClassName="active"
            title="Szybka Kalkulacja"
          >
            {" "}
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faCalculator} />
            </StyledFontAwesomeWrapper>
            <StyledName> Szybki kalkulator</StyledName>
          </StyledNavLink>
        </StyledListItem>
      </StyledList>
      {/* {isNavOpen ? (
        <StyledStretchButton onClick={() => setIsNavOpen(false)}>
          <FontAwesomeIcon icon={faChevronCircleLeft} /> Zwiń
        </StyledStretchButton>
      ) : (
        <StyledStretchButton onClick={() => setIsNavOpen(true)}>
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </StyledStretchButton>
      )} */}
    </StyledAsideNavWrapper>
  );
};

const StyledAlertWrapper = styled.div`
  position: absolute;
  height: 15px;
  width: 15px;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  color: #134771;
  background: rgb(244, 118, 49);
  display: flex;
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
const StyledListItem = styled.li``;

const StyledFontAwesomeWrapper = styled.div`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-right: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledName = styled.div``;

// const StyledStretchButton = styled.button`
//   padding: 10px;
//   font-size: 16px;
//   text-align: left;
//   background: transparent;
//   border: none;
//   outline: none;
//   color: black;
//   cursor: pointer;
// `;

const StyledList = styled.ul`
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
`;

const StyledAsideNavWrapper = styled.div`
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0px;
  width: 240px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f2eff6;
  /* transition: all 1s ease; */
  @media screen and (max-width: 1200px) {
    left: -240px;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: #134771;
  opacity: 1;
  height: calc(100% - 10px);
  margin: 10px;
  border-radius: 10px;
  font-size: 16px;
  padding: 5px;
  background: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;
export default AsideNav;
