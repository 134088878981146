import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OpportunityInfoItem = ({
  opportunity,
  isModalOpen,
  setIsModalOpen,
  handleOnCloseModal,
}) => {
  const dropRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  return (
    <StyledOpportunityInfoWrapper
      ref={dropRef}
      style={isModalOpen ? { display: "block" } : { display: "none" }}
    >
      <StyledTop>
        <StyledCancelButton onClick={handleOnCloseModal}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
      </StyledTop>

      <StyledNameWrapper>
        <StyledNameLink
          to={`/dashboard/opportunities/details/${opportunity.id}`}
        >
          {opportunity.name}
        </StyledNameLink>
      </StyledNameWrapper>

      <StyledDetailsWrapper>
        {opportunity.company && (
          <OpportunityCompanyWrapper>
            Firma:{" "}
            <OpportunityCompanyLink
              to={`/dashboard/contacts/companies/details/${opportunity.company.id}`}
            >
              {opportunity.company.name}
            </OpportunityCompanyLink>
          </OpportunityCompanyWrapper>
        )}

        {opportunity.client && (
          <OpportunityClientWrapper>
            Osoba:{" "}
            <OpportunityClientLink
              to={`/dashboard/contacts/clients/details/${opportunity.client.id}`}
            >
              {opportunity.client.first_name} {opportunity.client.last_name}
            </OpportunityClientLink>
          </OpportunityClientWrapper>
        )}
        {opportunity.endDate && (
          <OpportunityFinalDate>
            Data finalizacji: {moment(opportunity.endDate).format("DD.MM.YY")}
          </OpportunityFinalDate>
        )}

        {opportunity.maintainer && (
          <OpportunityMaintainerWrapper>
            Osoba odpowiedzialna: {opportunity.maintainer.name}
          </OpportunityMaintainerWrapper>
        )}

        {opportunity.price && (
          <OpportunityPriceWrapper>
            Cena: {opportunity.price} PLN
          </OpportunityPriceWrapper>
        )}
      </StyledDetailsWrapper>
    </StyledOpportunityInfoWrapper>
  );
};

const OpportunityPriceWrapper = styled.div`
  font-size: 12px;
`;

const OpportunityCompanyLink = styled(Link)`
  font-size: 12px;
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;
const OpportunityClientLink = styled(Link)`
  font-size: 12px;
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;
const OpportunityMaintainerWrapper = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
`;
const OpportunityFinalDate = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
`;

const OpportunityClientWrapper = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OpportunityCompanyWrapper = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledDetailsWrapper = styled.div`
  padding: 10px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 0;
  right: 2px;
  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: gray;
  }
`;

const StyledTop = styled.div`
  position: relative;
`;

const StyledNameLink = styled(Link)`
  font-size: 12px;
  color: black;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

const StyledNameWrapper = styled.div`
  padding: 10px;
  display: flex;
  margin-right: 10px;
`;

const StyledOpportunityInfoWrapper = styled.div`
  top: 5px;
  left: 0;
  width: 320px;
  z-index: 1000;
  border-radius: 10px;
  background: white;

  position: relative;
`;

export default OpportunityInfoItem;
