import React from "react";
import styled from "styled-components";

import loading from "../../../../../img/48x48.gif";

const NewLoadingComponent = () => {
  return (
    <StyledWrapper>
      <StyledLoadingImage src={loading} alt="loading" />
    </StyledWrapper>
  );
};

const StyledLoadingImage = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export default NewLoadingComponent;
