import React from "react";
import styled from "styled-components";

const CollapseShowItem = ({ name, setShowFilter, id }) => {
  return (
    <li>
      <StyledCollapseItemButton onClick={() => setShowFilter(id)}>
        <p>{name}</p>
      </StyledCollapseItemButton>
    </li>
  );
};

const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
`;
export default CollapseShowItem;
