import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../../../../store/StoreProvider";
import ItemDropDown from "./ItemDropDown";

const AllCompanyDropdown = ({ departments, teams, handleOnChooseUser }) => {
  const { user } = useContext(StoreContext);
  return (
    <StyledWrapper>
      <StyledItemWrapper>
        {user.role?.id === 2 && (
          <>
            {departments &&
              departments
                .filter(
                  (department) =>
                    department.voivodeship.id === user.department.voivodeship_id
                )
                .map((department) => {
                  return (
                    <ItemDropDown
                      key={department.id}
                      title={department.name}
                      users={department.users}
                      handleOnChooseUser={handleOnChooseUser}
                    />
                  );
                })}
          </>
        )}
        {(user.role?.id === 1 || user.role?.id === 3) && (
          <>
            {departments &&
              departments.map((department) => {
                return (
                  <ItemDropDown
                    key={department.id}
                    title={department.name}
                    users={department.users}
                    handleOnChooseUser={handleOnChooseUser}
                  />
                );
              })}{" "}
            {teams &&
              teams.map((team) => {
                return (
                  <ItemDropDown
                    key={team.id}
                    title={team.name}
                    users={team.users}
                    handleOnChooseUser={handleOnChooseUser}
                  />
                );
              })}
          </>
        )}

        {/* <ItemDropDown title="Oddziały" /> */}
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

const StyledItemWrapper = styled.div``;
const StyledWrapper = styled.div``;

// const StyledUserIcon = styled(FontAwesomeIcon)`
//   margin-right: 5px;
// `;

// const StyledAngleIcon = styled(FontAwesomeIcon)`
//   position: absolute;
//   right: 5px;
//   top: 5px;
//   font-size: 10px;
// `;

// const StyledButton = styled.button`
//   width: 100%;
//   text-align: left;
//   padding: 4px;
//   position: relative;
//   background: white;
//   border: none;
//   outline: none;
//   cursor: pointer;

//   &:hover {
//     background: lightgray;
//   }
// `;
export default AllCompanyDropdown;
