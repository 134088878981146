import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import DeleteAlert from "./DeleteAlert";
const UserListItem = ({
  user,
  handleOpenEditUser,
  fetchDataUser,
  handleOpenEditPassword,
  setEditObject,
}) => {
  const { id, name, email, department, role, team } = user;

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleOnEdit = () => {
    setEditObject(user);
    handleOpenEditUser();
  };
  const handleOnEditPassword = () => {
    setEditObject(user);
    handleOpenEditPassword();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/user/${id}`);
    if (status === 200) {
      fetchDataUser();
      setIsAlertOpen(false);
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledTaskStatusItem>
      <StyledUserImage>
        <div></div>
      </StyledUserImage>

      <StyledDescription>
        <StyledName title="Edytuj" onClick={handleOnEdit}>
          {name}
        </StyledName>
        <StyledEmail>{email}</StyledEmail>
      </StyledDescription>
      <StyledUserItemTd>{department?.name}</StyledUserItemTd>
      <StyledUserItemTd>{team?.name}</StyledUserItemTd>
      <StyledUserItemTd>{role?.name}</StyledUserItemTd>
      <StyledButtonGroup>
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnOpenAlert} title="Usuń użytkownika">
            <StyledTrashIcon icon={faTrashAlt} />{" "}
          </StyledButton>
          <StyledButton onClick={handleOnEdit} title="Edytuj użytkownika">
            <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
          </StyledButton>
          <StyledButton onClick={handleOnEditPassword} title="Zmień hasło">
            <StyledEditIcon style={{ color: "LightSlateGrey" }} icon={faKey} />
          </StyledButton>
        </StyledButtonWrapper>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskStatusItem>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const StyledUserItemTd = styled.td`
  font-size: 16px;
`;

const StyledUserImage = styled.td`
  width: 60px;
  height: 60px;
  padding: 5px;
  div {
    width: 100%;
    height: 100%;
    background: gray;
    border-radius: 50%;
  }
`;

const StyledTaskStatusItem = styled.tr`
  font-size: 18px;
`;

const StyledName = styled.p`
  color: royalblue;
`;

const StyledEmail = styled.p`
  color: #aaa;
`;
const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 18px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 18px;
`;

const StyledDescription = styled.td`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 16px;
`;

const StyledButtonGroup = styled.td`
  vertical-align: middle;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
export default UserListItem;
