import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../helpers/request";
import { StoreContext } from "../../store/StoreProvider";
import AddToDoItem from "../ToDo/AddToDoItem/AddToDoItem";
import CalendarComponent from "./Components/Calendar/CalendarComponent";
import CalendarAside from "./Components/CalendarAside/CalendarAside";
import moment from "moment";
import "moment/locale/pl";
const Calendar = () => {
  moment.locale("pl");
  const [value, setValue] = useState(moment());

  const { departments, setDepartments, teams, setTeams } =
    useContext(StoreContext);

  const [toDoList, setToDoList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [taskTypeFilter, setTaskTypeFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addDayInfo, setAddDayInfo] = useState(moment());

  const location = useLocation();
  const history = useHistory();
  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setEditMode(false);
    setAddDayInfo(moment());
  };

  const handleOnClickEdit = () => {
    setEditMode(true);
    setIsModalOpen(true);
  };

  const handleOnClickAdd = (day) => {
    setEditMode(false);
    setIsModalOpen(true);
    setAddDayInfo(day);
  };

  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchDataDepartments = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchToDo = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get("/api/calendar-todo", {
        params: {
          user: userFilter,
          type: taskTypeFilter,
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
        },
      });
      if (status === 200) {
        setToDoList(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (startDate && endDate) {
      fetchToDo();
    }
  }, [userFilter, taskTypeFilter, startDate, endDate]);

  useEffect(() => {
    if (teams === undefined || teams.length === 0) {
      fetchDataTeams();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartments();
    }
  }, []);

  return (
    <StyledCalendarWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Kalendarz</StyledTitle>
      </StyledTitleWrapper>
      <StyledCalendarComponentWrapper>
        <CalendarAside
          setStatusFilter={setStatusFilter}
          setTaskTypeFilter={setTaskTypeFilter}
          statusFilter={statusFilter}
          taskTypeFilter={taskTypeFilter}
          userFilter={userFilter}
          setUserFilter={setUserFilter}
        />
        <CalendarComponent
          todoList={toDoList}
          fetchData={fetchToDo}
          handleOnClickEdit={handleOnClickEdit}
          handleOnClickAdd={handleOnClickAdd}
          setEditObject={setEditObject}
          value={value}
          setValue={setValue}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          statusFilter={statusFilter}
          taskTypeFilter={taskTypeFilter}
          userFilter={userFilter}
          isLoading={isLoading}
        />
      </StyledCalendarComponentWrapper>
      <StyledMobileDont>
        {" "}
        Kalendarz niedostępny na wersji mobilnej. Wersja mobila w trakcie
        przygotowań{" "}
      </StyledMobileDont>
      <AddToDoItem
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isEditMode={isEditMode}
        editObject={editObject}
        handleOnClose={handleOnClose}
        fetchData={fetchToDo}
        value={addDayInfo}
      />
    </StyledCalendarWrapper>
  );
};

const StyledMobileDont = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const StyledCalendarComponentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 0;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const StyledCalendarWrapper = styled.div`
  padding: 10px;
  max-width: 1400px;
  margin: 0 auto;
  flex: 1;
  min-width: 0;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
`;

export default Calendar;
