import React, { createContext, useState } from "react";

export const YieldCalculatorContext = createContext(null);

const YieldCalculatorProvider = ({ children }) => {
  const [installationValue, setInstallationValue] = useState(0);
  const [installationPower, setInstallationPower] = useState(0);
  const [yearElectricityBill, setYearElectricityBill] = useState(0);
  const [increaseElectricityBill, setIncreaseElectricityBill] = useState(5);
  const [serviceLive, setServiceLive] = useState(25);
  const [reliefMyCurrent, setReliefMyCurrent] = useState(0);
  const [otherRelief, setOtherRelief] = useState(0);
  const [roofAngle, setRoofAngle] = useState(1);
  const [sideDegree, setSideDegree] = useState(1);
  const [yieldZone, setYieldZone] = useState(1);
  const [thermomodernizationRelief, setThermomodenizationRelief] = useState(0);
  const [pitValue, setPitValue] = useState(0);
  const [sumOfSubsidies, setSumOfSubsidies] = useState(0);
  const [referenceYield, setReferenceYield] = useState(0);
  const [losses, setLosses] = useState(0);
  const [annualYield, setAnnualYield] = useState(0);
  const [buyRatePerKilowattHour, setBuyRatePerKilowattHour] = useState(0);
  const [sellRatePerMegawattHour, setSellRatePerMegawattHour] = useState(400);
  const [selfConsumption, setSelfConsumption] = useState(30);
  const [storage, setStorage] = useState(70);
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [saving, setSaving] = useState(0);

  return (
    <YieldCalculatorContext.Provider
      value={{
        installationValue,
        setInstallationValue,
        installationPower,
        setInstallationPower,
        yearElectricityBill,
        setYearElectricityBill,
        increaseElectricityBill,
        setIncreaseElectricityBill,
        serviceLive,
        setServiceLive,
        otherRelief,
        setOtherRelief,
        reliefMyCurrent,
        setReliefMyCurrent,
        roofAngle,
        setRoofAngle,
        sideDegree,
        setSideDegree,
        yieldZone,
        setYieldZone,
        thermomodernizationRelief,
        setThermomodenizationRelief,
        pitValue,
        setPitValue,
        sumOfSubsidies,
        setSumOfSubsidies,
        referenceYield,
        setReferenceYield,
        losses,
        setLosses,
        annualYield,
        setAnnualYield,
        buyRatePerKilowattHour,
        setBuyRatePerKilowattHour,
        sellRatePerMegawattHour,
        setSellRatePerMegawattHour,
        selfConsumption,
        setSelfConsumption,
        storage,
        setStorage,
        investmentAmount,
        setInvestmentAmount,
        saving,
        setSaving,
      }}
    >
      {children}
    </YieldCalculatorContext.Provider>
  );
};

export default YieldCalculatorProvider;
