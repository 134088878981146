import React, { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Modal from "../../../../../../components/Modal";
import request from "../../../../../../helpers/request";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Nazwa jest wymagana")
    .min(3, "Nazwa musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwa nie może być dłuższa niż 255 znaków"),
  color: yup.string().required("Kolor jest wymagana"),
});

const AddTaskStatus = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const { register, handleSubmit, errors, reset, setValue, control } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: { name: "", color: "#000000" },
  });

  const [isSended, setIsSended] = useState(false);

  const resetInputValue = () => {
    reset({
      name: "",
      color: "#000",
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    if (isEditMode) {
      await request.patch(`/api/taskstatus/${editObject.id}`, { ...data });
    } else {
      await request.post("/api/taskstatus", { data });
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
    fetchData();
    resetInputValue();
    handleOnClose();
  };

  useEffect(() => {
    if (editObject) {
      setValue("name", editObject.name);
      setValue("color", editObject.color);
    }
  }, [editObject]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <div className="name">
          <div className="input-group">
            <label htmlFor="todoNameInput">Status zadania</label>
            <Controller
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  id="todoNameInput"
                  value={field.value}
                />
              )}
              name="name"
              control={control}
            />
          </div>
        </div>
        {errors?.name && <span className="error">{errors.name.message}</span>}
        <div className="icon">
          <div className="input-group">
            <label htmlFor="icon-wrapper">Ikona typu zadania</label>
            <div className="icon-select-wrapper" id="icon-wrapper">
              <input type="color" {...register("color")} />
              <StyledTagList></StyledTagList>
            </div>
          </div>
        </div>

        {isEditMode ? (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Zapisz
            </button>
          </div>
        ) : (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Dodaj
            </button>
          </div>
        )}
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledTagList = styled.ul`
  margin-top: 30px;
  list-style: none;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
`;

const StyledForm = styled.form`
  max-width: 360px;
  .name {
    min-width: 300px;
    margin-top: 25px;
    display: flex;
    & .input-group {
      position: relative;
      flex: 1;
      label {
        position: absolute;
        top: -15px;
        left: 0;
        font-size: 14px;
      }
      input {
        border: none;
        border-bottom: 1px solid black;
        width: 100%;
        outline: none;
        font-size: 14px;
      }
    }
  }
  & .icon {
    margin-top: 25px;
    & .input-group {
      position: relative;
      flex: 1;
      label {
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 14px;
      }
      select {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #2d96db;
        background: transparent;
      }
      &.close:hover {
        color: black;
      }
      &.add {
        background: #134771;
        color: white;
      }
      &.add:hover {
        background: #005e9c;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

export default AddTaskStatus;
