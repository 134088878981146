import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import request from "../../../../../../helpers/request";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../components/Modal";

const validationSchema = yup.object({
  text: yup
    .string()
    .required("Informacja jest wymagana")
    .min(3, "Informacja musi posiadać co najmniej 3 znaków")
    .max(255, "Informacja nie może być dłuższa niż 255 znaków"),
});
const AddInformation = ({
  isModalOpen,
  opportunity_id,
  handleOnClose,
  fetchOpportunity,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isSended, setIsSended] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      text: "",
    });
  };

  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.post(
        `api/opportunities/important-information/${opportunity_id}`,
        {
          ...data,
        }
      );
      if (status === 200) {
        fetchOpportunity();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data, e) => {
    e.preventDefault();
    sendData(data);
  };

  useEffect(() => {
    setValue("text", "");
  }, []);

  useEffect(() => {
    setValue("opportunity_id", opportunity_id);
  }, [opportunity_id]);

  const handleSetHeight = (e) => {
    e.target.style.height = "inherit";

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    e.target.style.height = `${height}px`;
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledTextArea
          id="TextArea"
          {...register("text")}
          onKeyUp={handleSetHeight}
        ></StyledTextArea>
        {errors?.note && <span className="error">{errors.note.message}</span>}

        <input type="hidden" {...register("opportunity_id")} />
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Dodaj informację
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 16px;
  padding: 10px;
  overflow-y: hidden;
  border-radius: 10px;
  outline: none;
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 600px;
  min-width: 360px;

  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .todo-input-group {
      width: 49%;

      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

export default AddInformation;
