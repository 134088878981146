import moment from "moment";
import React from "react";
import styled from "styled-components";

const Today = ({ taskCount, taskDeleyCount }) => {
  const day = moment().format("DD ");
  const month = moment().format("MMMM");
  const year = moment().format("YYYY");

  return (
    <StyledToday>
      <StyledDateWrapper>
        <StyledDayWrapper>{day}</StyledDayWrapper>
        <StyledMonthWrapper>{month}</StyledMonthWrapper>
        <StyledYearWrapper>{year}</StyledYearWrapper>
      </StyledDateWrapper>
      <StyledInfoWrapper>
        <StyledTodayTask>
          {taskCount ? (
            <p>{taskCount} zadania na dzisiaj</p>
          ) : (
            <p>Brak zadań</p>
          )}
        </StyledTodayTask>
        <StyledDelayedTask>
          <p>spóźnione: {taskDeleyCount}</p>
        </StyledDelayedTask>
      </StyledInfoWrapper>
    </StyledToday>
  );
};

const StyledDelayedTask = styled.div`
  color: #f47631;
`;

const StyledTodayTask = styled.div`
  font-size: 1.5rem;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledDayWrapper = styled.div`
  font-size: 48px;
`;
const StyledMonthWrapper = styled.div`
  color: #f47631;
`;
const StyledYearWrapper = styled.div`
  font-size: 20px;
`;

const StyledDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledToday = styled.div`
  background: white;
  color: #134771;
  padding: 20px;
  display: flex;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
`;

export default Today;
