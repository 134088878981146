import { faFile } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { StoreContext } from "../../../../store/StoreProvider";

const AsideMenu = ({ isMobileNavOpen }) => {
  const { user } = useContext(StoreContext);

  const asideNav = useRef(null);

  useEffect(() => {
    if (asideNav) {
      [...asideNav.current.children].forEach((element, index) => {
        if ((index + 1) % 3 === 0) {
          element.children[0].children[0].style.background = "#66bc46";
        }
        if ((index + 1) % 3 === 1) {
          element.children[0].children[0].style.background = "#134771";
        }
        if ((index + 1) % 3 === 2) {
          element.children[0].children[0].style.background = "#f47631";
        }

        if (window.innerHeight <= 800) {
          element.style.height = `55px`;
          element.children[0].children[0].children[0].style.fontSize = "16px";
        } else if (window.innerHeight > 800 && window.innerHeight < 900) {
          element.style.height = `60px`;
          element.children[0].children[0].children[0].style.fontSize = "18px";
        } else if (window.innerHeight >= 900 && window.innerHeight < 1000) {
          element.style.height = `65px`;
          element.children[0].children[0].children[0].style.fontSize = "20px";
        } else {
          element.style.height = `70px`;
          element.children[0].children[0].children[0].style.fontSize = "22px";
        }
      });
    }
  }, [user]);

  return (
    <StyledAsideNavWrapper
      style={isMobileNavOpen ? { left: "0" } : { left: "-240px" }}
    >
      {" "}
      <StyledList ref={asideNav}>
        <StyledListItem>
          <StyledNavLink
            to="/user-settings/offer"
            activeClassName="active"
            title="Oferta"
          >
            {" "}
            <StyledFontAwesomeWrapper>
              <StyledFontAwesomeIcon icon={faFile} />
            </StyledFontAwesomeWrapper>
            <StyledName> Oferta</StyledName>
          </StyledNavLink>
        </StyledListItem>
      </StyledList>
    </StyledAsideNavWrapper>
  );
};
const StyledListItem = styled.li``;

const StyledFontAwesomeWrapper = styled.div`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-right: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledName = styled.div``;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
`;

const StyledAsideNavWrapper = styled.div`
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0px;
  width: 240px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f2eff6;
  /* transition: all 1s ease; */
  @media screen and (max-width: 1200px) {
    left: -240px;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  opacity: 1;
  display: block;
  height: calc(100% - 10px);
  margin: 10px;
  border-radius: 10px;
  font-size: 16px;
  padding: 5px;

  background: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;
export default AsideMenu;
