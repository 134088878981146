import {
  faCheck,
  faCircle,
  faPaperPlane,
  faPen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ContractItem = ({ contract, setIsShowContract, setContractObject }) => {
  const [spanObject, setSpanObject] = useState(null);
  const [sendObject, setSendObject] = useState(null);

  const handleOnOpenModal = () => {
    setContractObject(contract);
    setIsShowContract(true);
  };

  useEffect(() => {
    if (contract) {
      if (contract.is_accepted && !contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(102, 188, 70` }}>
            <FontAwesomeIcon icon={faCheck} />{" "}
          </StyledSpan>
        );
      } else if (contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `rgb(244, 118, 49)` }}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </StyledSpan>
        );
      } else if (!contract.is_accepted && !contract.is_rejected) {
        setSpanObject(
          <StyledSpan style={{ background: `lightgray` }}>
            <FontAwesomeIcon icon={faCircle} />{" "}
          </StyledSpan>
        );
        if (contract.send_for_approval === 1) {
          setSendObject(
            <StyledSendFontAwesome
              style={{ color: "rgb(102, 188, 70)" }}
              icon={faPaperPlane}
            />
          );
        } else {
          setSendObject(
            <StyledSendFontAwesome
              style={{ color: "rgb(244, 118, 49)" }}
              icon={faPaperPlane}
            />
          );
        }
      }
    }
  }, [contract]);

  return (
    <StyledButton onClick={handleOnOpenModal}>
      <StyledSpanWrapper>{spanObject}</StyledSpanWrapper>
      Umowa z dnia {moment(contract.created_at).format("DD.MM.YY HH.mm")}
      {sendObject}
      {contract.is_signed === 1 && <StyledSignedFontAwesomeIcon icon={faPen} />}
    </StyledButton>
  );
};

const StyledSignedFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #134771;
  margin-left: 5px;
`;

const StyledSendFontAwesome = styled(FontAwesomeIcon)`
  margin-left: 5px;
`;

const StyledSpan = styled.span`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

const StyledSpanWrapper = styled.div`
  width: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  display: flex;
  padding: 5px 10px;
  text-align: left;
  margin-bottom: 4px;
  border: none;
  background: white;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
`;

export default ContractItem;
