import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Modal from "../../../../../../../../components/Modal";
import AssemblyFaultItem from "./Components/AssemblyFaultItem/AssemblyFaultItem";

const AssemblyFaultModal = ({
  isModalOpen,
  handleOnClose,
  opportunities,
  openAddModal,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledModalWrapper>
        <StyledTitle>Zgłoszone usterki:</StyledTitle>
        <StyledList>
          {opportunities.assembly_fault &&
            opportunities.assembly_fault.map((assembly) => (
              <AssemblyFaultItem key={assembly.id} assemblyFault={assembly} />
            ))}
        </StyledList>
      </StyledModalWrapper>
      <ButtonWrapper>
        <StyledButton onClick={openAddModal}>
          Dodaj kolejną usterkę
        </StyledButton>
      </ButtonWrapper>
    </Modal>
  );
};

const StyledButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const ButtonWrapper = styled.div``;

const StyledList = styled.div``;

const StyledTitle = styled.h2`
  color: #134771;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledModalWrapper = styled.div`
  padding: 10px;
`;

export default AssemblyFaultModal;
