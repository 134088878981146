import React from "react";
import styled from "styled-components";

const Main = () => {
  return <StyledWrapper>Ustawnienia ogólne dla pomp Ciepła</StyledWrapper>;
};

const StyledWrapper = styled.div`
  padding: 10px;
`;

export default Main;
