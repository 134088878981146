import { faChevronDown, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import ItemDropDown from "./ItemDropDown";

const AllCompanyDropdown = ({ departments, teams, handleOnChooseUser }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const handleOpenDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  };
  return (
    <StyledWrapper>
      <StyledButton onClick={handleOpenDropdown}>
        <StyledUserIcon icon={faUsers} />
        Cała firma
        <StyledAngleIcon icon={faChevronDown} />
      </StyledButton>
      <StyledItemWrapper
        style={isDropdownOpen ? { dispay: "block" } : { display: "none" }}
      >
        {departments &&
          departments.map((department) => {
            return (
              <ItemDropDown
                key={department.id}
                title={department.name}
                users={department.users}
                handleOnChooseUser={handleOnChooseUser}
              />
            );
          })}
        {teams &&
          teams.map((team) => {
            return (
              <ItemDropDown
                key={team.id}
                title={team.name}
                users={team.users}
                handleOnChooseUser={handleOnChooseUser}
              />
            );
          })}
        {/* <ItemDropDown title="Oddziały" /> */}
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

const StyledItemWrapper = styled.div``;
const StyledWrapper = styled.div``;

const StyledUserIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledAngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px;
`;

const StyledButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 4px;
  position: relative;
  background: white;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
`;
export default AllCompanyDropdown;
