import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import WrongLeadNote from "./WrongLeadNote";

const WrongLead = ({ lead, fetchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOnOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <StyledWrapper>
      {lead.wrong_lead === 0 ? (
        <StyledButton onClick={handleOnOpenModal}>Błędny lead</StyledButton>
      ) : (
        <StyledNoteWrapper>
          {lead.wrong_lead_note.map((note) => (
            <StyledNoteItem key={note.id}>
              <StyledNoteP>Notatka: {note.note}</StyledNoteP>
              <StyledNoteP>Dodana przez: {note.user.name}</StyledNoteP>
              <StyledNoteP>
                Dnia: {moment(note.date).format("DD.MM.YY HH:mm")}
              </StyledNoteP>
            </StyledNoteItem>
          ))}
        </StyledNoteWrapper>
      )}
      <WrongLeadNote
        lead={lead}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
      />
    </StyledWrapper>
  );
};

const StyledNoteP = styled.p``;

const StyledNoteItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StyledNoteWrapper = styled.div``;

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  width: 100%;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px;
`;
export default WrongLead;
