import { faSolarPanel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import heat from "../../../../../../img/heat_pump.svg";
const CompanyOpportunityItem = ({ opportunity }) => {
  return (
    <StyledWrapper>
      <StyledOpportunityTypeWrapper>
        {opportunity.is_heat_pump === 1 && <StyledImg src={heat} />}
        {opportunity.is_photovoltaic === 1 && (
          <FontAwesomeIcon icon={faSolarPanel} />
        )}
      </StyledOpportunityTypeWrapper>
      <StyledOpportunityLink
        title={opportunity.name}
        to={`/dashboard/opportunities/details/${opportunity.id}`}
      >
        <StyledNameString>{opportunity.name}</StyledNameString>
        {opportunity.created_at ? (
          <StyledDateString>
            Utworzona :{" "}
            {new Date(opportunity.created_at).toLocaleDateString("pl-Pl")}
          </StyledDateString>
        ) : (
          ""
        )}
      </StyledOpportunityLink>
    </StyledWrapper>
  );
};

const StyledImg = styled.img`
  width: 20px;
`;

const StyledOpportunityTypeWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const StyledNameString = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const StyledDateString = styled.p``;

const StyledOpportunityLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

const StyledWrapper = styled.div`
  padding: 2px 0;
  display: flex;
`;
export default CompanyOpportunityItem;
