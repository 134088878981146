import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCog,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import NavOptionDropDown from "./TopNavOptionDropDown";
import Search from "../Search/Search";

const TopNav = ({ isMobileNavOpen, setIsMobileNavOpen }) => {
  const [isNavOptionMenuOpen, setNavOptionMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleOptionDropDown = () => {
    setNavOptionMenuOpen(true);
  };

  const handleOnSearchOpen = () => {
    setIsSearchOpen(true);
  };

  const handleOpenNavMenu = () => {
    setIsMobileNavOpen(true);
  };
  const handleCloseNavMenu = () => {
    setIsMobileNavOpen(false);
  };
  return (
    <>
      <StyledMainNavBar>
        <StyledBurgerMenuWrapper>
          {!isMobileNavOpen ? (
            <StyledBurgerMenu onClick={handleOpenNavMenu}>
              <StyledFontAwesomeBar icon={faBars} />
            </StyledBurgerMenu>
          ) : (
            <StyledBurgerMenu onClick={handleCloseNavMenu}>
              <StyledFontAwesomeBar icon={faTimes} />
            </StyledBurgerMenu>
          )}
        </StyledBurgerMenuWrapper>

        <StyledNavLink to="/dashboard/home">
          <StyledLogo />
        </StyledNavLink>

        <ul className="side">
          {/* <li>
            <button className="nav-button">
              <FontAwesomeIcon icon={faCommentAlt} />
            </button>
          </li>
          <li>
            <button className="nav-button">
              <FontAwesomeIcon icon={faBell} />
            </button>
          </li> */}
          <li>
            <StyledButton onClick={handleOnSearchOpen}>
              <FontAwesomeIcon icon={faSearch} />
            </StyledButton>
          </li>
          {/* <li>
            <button className="nav-button">
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </li> */}
          <li>
            <StyledButton onClick={handleOptionDropDown}>
              <FontAwesomeIcon icon={faCog} />
            </StyledButton>
            <NavOptionDropDown
              isOpen={isNavOptionMenuOpen}
              handleIsOpen={setNavOptionMenuOpen}
            />
          </li>
        </ul>
        <Search isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
      </StyledMainNavBar>
    </>
  );
};
const StyledBurgerMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  left: 10px;
  display: none;
  @media screen and (max-width: 1200px) {
    display: flex;
  }
`;

const StyledFontAwesomeBar = styled(FontAwesomeIcon)`
  font-size: 24px;
`;

const StyledBurgerMenu = styled.button`
  padding: 10px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
  position: absolute;
  top: 0;
  left: calc(50% - 102px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 204px;
  height: 60px;

  @media screen and (max-width: 768px) {
    width: 180px;
    height: 60px;
  }
`;

const StyledLogo = styled(Logo)`
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const StyledButton = styled.button`
  display: block;
  margin-right: 10px;
  padding: 10px;
  height: 100%;
  width: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d96db;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const StyledMainNavBar = styled.nav`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background: white;
  z-index: 1000;

  svg {
  }

  ul.side {
    position: absolute;
    height: 60px;
    top: 0;
    right: 0;
    display: flex;
    z-index: 10;
    list-style: none;
    li {
    }
  }
`;

export default TopNav;
