import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import { StoreContext } from "../../../../../store/StoreProvider";
import OpportunitiesChangeStatus from "./OpportunitiesChangeStatus";

const OpportunitiesStatus = ({
  status,
  statuses,
  fetchData,
  opportunities,
}) => {
  const [statusObject, setStatusObject] = useState([]);
  const [isOpenChangeStatus, setIsOpenChangeStatus] = useState(false);
  const { setOpportunities } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const handleOpenChangeStatus = () => {
    setIsOpenChangeStatus((prev) => !prev);
  };
  const handleChangeStatus = async (id) => {
    try {
      const { status } = await request.patch(
        `api/opportunities/update-status/${opportunities.id}`,
        { status: id }
      );
      if (status === 201) {
        fetchData();
        fetchOpportunities();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchOpportunities = async () => {
    try {
      const { status, data } = await request.get("/api/opportunities");
      if (status === 200) {
        setOpportunities(data.data);
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (statuses) {
      statuses.forEach((statusItem) => {
        if (statusItem.id === status) {
          setStatusObject(statusItem);
        }
      });
    }
  }, [status, statuses]);

  return (
    <StyledStatusWrapper>
      <StyledHeaderText>Status szansy sprzedaży</StyledHeaderText>
      <StyledStatusItemWrapper>
        <StyledStatusTextWrapper>
          <StyledStatusSpan style={{ background: statusObject.color }}>
            {statusObject.length !== 0 ? (
              <FontAwesomeIcon
                style={{ color: statusObject.icon_color }}
                icon={[statusObject.prefix, statusObject.icon]}
              />
            ) : null}
          </StyledStatusSpan>
          <StyledStatusText>{statusObject.name}</StyledStatusText>
        </StyledStatusTextWrapper>
        {status !== 2 && (
          <StyledStatusChangeButton onClick={handleOpenChangeStatus}>
            {isOpenChangeStatus ? "Zamknij" : "Zmień"}
          </StyledStatusChangeButton>
        )}
      </StyledStatusItemWrapper>
      <OpportunitiesChangeStatus
        opportunities={opportunities}
        isOpen={isOpenChangeStatus}
        setIsOpen={setIsOpenChangeStatus}
        statuses={statuses}
        handleChange={handleChangeStatus}
      />
    </StyledStatusWrapper>
  );
};
const StyledStatusChangeButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledStatusText = styled.p`
  font-size: 18px;
  letter-spacing: 2px;
`;

const StyledStatusTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledStatusSpan = styled.span`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 30px;
  }
`;

const StyledStatusItemWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

const StyledHeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
`;

const StyledStatusWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
`;

export default OpportunitiesStatus;
