import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const UserItem = ({ user }) => {
  return (
    <StyledWrapper>
      <StyledInfoWrapper>
        <StyledUserName>{user.name}</StyledUserName>
        <StyledSumeWrapper>
          <StyledSume>
            {Number(user.opportunities_kWp).toFixed(2)} kWp
          </StyledSume>
          <StyledSume>
            {new Intl.NumberFormat("pl-PL", {
              style: "currency",
              currency: "pln",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(user.opportunities_price)}
          </StyledSume>
        </StyledSumeWrapper>
      </StyledInfoWrapper>
      <StyledOpportunitiesWrapper>
        <StyledTitlesWrapper>
          <StyledName>Nazwa</StyledName>
          <StyledValue>Marża</StyledValue>
          <StyledValue>Ilość kWp</StyledValue>
          <StyledValue>Wartość</StyledValue>
        </StyledTitlesWrapper>
        {user.opportunities.map((opportunity, index) => (
          <StyledOpportunity key={opportunity.id}>
            <StyledName>
              {index + 1}.
              <StyledNameLink
                to={`/dashboard/opportunities/details/${opportunity.id}`}
              >
                {opportunity.name}{" "}
              </StyledNameLink>
            </StyledName>
            <StyledValue>
              {opportunity.win_contract?.offer?.margin
                ? `${opportunity.win_contract.offer.margin} zł`
                : ""}
            </StyledValue>
            <StyledValue>
              {opportunity.kWp ? `${opportunity.kWp} kWp` : ""}
            </StyledValue>
            <StyledValue>
              {opportunity.price
                ? new Intl.NumberFormat("pl-PL", {
                    style: "currency",
                    currency: "pln",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(opportunity.price)
                : ""}
            </StyledValue>
          </StyledOpportunity>
        ))}
      </StyledOpportunitiesWrapper>
    </StyledWrapper>
  );
};

const StyledOpportunity = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(202, 222, 238, 0.44);
  padding: 5px;
  min-width: 0;
`;

const StyledValue = styled.div`
  width: 120px;
`;

const StyledName = styled.div`
  flex: 1;
  display: inline-block;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledTitlesWrapper = styled.div`
  display: flex;
  padding: 5px;
`;

const StyledOpportunitiesWrapper = styled.div`
  min-width: 0;
`;

const StyledSumeWrapper = styled.div`
  display: flex;
  min-width: min-content;
`;

const StyledSume = styled.div`
  width: 120px;
  font-weight: bold;
`;

const StyledUserName = styled.div`
  flex: 1;
  font-weight: bold;
`;

const StyledInfoWrapper = styled.div`
  min-width: 0;
  display: flex;
  padding: 0 5px;
`;

const StyledWrapper = styled.div`
  margin-bottom: 10px;
  min-width: 0;
`;

const StyledNameLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

export default UserItem;
