import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { useLocation, useHistory } from "react-router";
import { StoreContext } from "../../../../../../../../../../../store/StoreProvider";
import request from "../../../../../../../../../../../helpers/request";
import Modal from "../../../../../../../../../../../components/Modal";
import { AssemblyFaultsContext } from "../../../../../../../../../../../store/AssemblyFaultsProvider";

const validationSchema = yup.object().shape({});

const EditAssemblyFault = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  assemblyFault,
}) => {
  const { voivodeships, teams } = useContext(StoreContext);
  const { activeBuses } = useContext(AssemblyFaultsContext);
  const location = useLocation();
  const history = useHistory();

  const [isSended, setIsSended] = useState(false);

  //Alert states

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const TextAreaRef = useRef(null);
  const { ref, ...rest } = register("fault");

  const sendData = async (sendData) => {
    setIsSended(true);

    try {
      const { status } = await request.patch(
        `/api/assembly-faults/${assemblyFault.id}`,
        {
          ...sendData,
        }
      );

      if (status === 201) {
        fetchData();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (assemblyFault) {
      setValue("fault", assemblyFault.fault);
      setValue("client_name", assemblyFault.client_name);
      setValue("reporting_person", assemblyFault.reporting_person);
      setValue("city", assemblyFault.city);
      setValue("street", assemblyFault.street);
      setValue("post_code", assemblyFault.post_code);
      setValue("building_number", assemblyFault.building_number);
      setValue("post", assemblyFault.post);
      setValue("voivodeship", assemblyFault.voivodeship.id);
      setValue("status", assemblyFault.status);
      setValue("bus_id", assemblyFault.bus?.id || "");
    }
  }, [assemblyFault, teams]);

  useEffect(() => {
    if (TextAreaRef) {
      handleSetHeight(TextAreaRef);
    }
  }, [TextAreaRef, isModalOpen]);

  const handleSetHeight = (e) => {
    if (e?.current) {
      e.current.style.height = "inherit";

      // Get the computed styles for the element
      const computed = window.getComputedStyle(e.current);

      // Calculate the height
      const height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        e.current.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom"), 10) +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      e.current.style.height = `${height}px`;
    } else {
      e.target.style.height = "inherit";

      // Get the computed styles for the element
      const computed = window.getComputedStyle(e.target);

      // Calculate the height
      const height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        e.target.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom"), 10) +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      e.target.style.height = `${height}px`;
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledTitleWrapper>
          <StyledTitle>Edytuj usterkę</StyledTitle>
        </StyledTitleWrapper>
        <StyledTextArea
          id="TextArea"
          {...rest}
          name="fault"
          ref={(e) => {
            ref(e);
            TextAreaRef.current = e; // you can still assign to ref
          }}
          onKeyUp={handleSetHeight}
        ></StyledTextArea>

        <StyledInput>
          <div className="input-group">
            <label>Nazwa klienta</label>
            <input
              type="text"
              {...register("client_name")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.client_name && (
          <span className="error">{errors.client_name.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Osoba zgłaszająca:</label>
            <input
              type="text"
              {...register("reporting_person")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.reporting_person && (
          <span className="error">{errors.reporting_person.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input
              type="text"
              {...register("street")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.street && (
          <span className="error">{errors.street.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("building_number")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.building_number && (
          <span className="error">{errors.building_number.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input
              type="text"
              {...register("city")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.city && <span className="error">{errors.city.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input
              type="text"
              {...register("post_code")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.postCode && (
          <span className="error">{errors.postCode.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input
              type="text"
              {...register("post")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.post && <span className="error">{errors.post.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Województwo</label>
            <select {...register("voivodeship")}>
              <option value="">Wybierz...</option>
              {voivodeships &&
                voivodeships.map((voivodeship) => (
                  <option value={voivodeship.id} key={voivodeship.id}>
                    {voivodeship.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.voivodeship && (
          <span className="error">{errors.voivodeship.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Osoba odpowiedzialna</label>
            <select {...register("maintainer_id")}>
              <option value="">Wybierz...</option>
              {teams &&
                teams
                  .find((team) => team.id === 2)
                  ?.users?.map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.name}
                    </option>
                  ))}
            </select>
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Przypisany bus</label>
            <select {...register("bus_id")}>
              <option value="">Wybierz...</option>
              {activeBuses &&
                activeBuses.map((bus) => (
                  <option value={bus.id} key={bus.id}>
                    {bus.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>

        <input type="hidden" {...register("status")} />

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Edytuj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  font-size: 16px;
  padding: 10px;
  overflow-y: hidden;
  border-radius: 10px;
  outline: none;
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

const StyledTitle = styled.p`
  color: #134771;
  font-size: 18px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 640px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
        text-decoration: underline;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default EditAssemblyFault;
