import React, { createContext, useState } from "react";

export const PowerCalculatorContext = createContext(null);

const PowerCalculatorProvider = ({ children }) => {
  const [installationPower, setInstallationPower] = useState(0);
  const [roofAngle, setRoofAngle] = useState(1);
  const [sideDegree, setSideDegree] = useState(1);
  const [yieldZone, setYieldZone] = useState(1);
  const [autoConsumption, setAutoConsumption] = useState(30);
  const [saleRate, setSaleRate] = useState(0);
  const [buyRate, setBuyRate] = useState(0);

  return (
    <PowerCalculatorContext.Provider
      value={{
        installationPower,
        setInstallationPower,
        roofAngle,
        setRoofAngle,
        sideDegree,
        setSideDegree,
        yieldZone,
        setYieldZone,
        autoConsumption,
        setAutoConsumption,
        saleRate,
        setSaleRate,
        buyRate,
        setBuyRate,
      }}
    >
      {children}
    </PowerCalculatorContext.Provider>
  );
};

export default PowerCalculatorProvider;
