import React from "react";
import styled from "styled-components";
import CollapseDepartment from "./CollapseDepartment/CollapseDepartment";
import CollapseShow from "./CollapseShow/CollapseShow";

const ClientsAside = ({
  setShowFilter,
  departmentFilter,
  setDepartmentFilter,
  departments,
}) => {
  return (
    <StyledAside>
      {/* <CollapseShow setShowFilter={setShowFilter} /> */}
      <CollapseDepartment
        setDepartmentFilter={setDepartmentFilter}
        departments={departments}
        departmentFilter={departmentFilter}
      />
    </StyledAside>
  );
};

const StyledAside = styled.div`
  width: 200px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export default ClientsAside;
