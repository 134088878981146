import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Modal from "../../../../../../../../components/Modal";
import ItemFile from "./ItemFile";
import loading from "../../../../../../../../img/48x48.gif";
import { StoreContext } from "../../../../../../../../store/StoreProvider";
const InstallationDesignShow = ({
  isModalOpen,
  handleOnClose,
  opportunities,
  handleOnOpenEditModal,
}) => {
  const [isSended, setIsSended] = useState(false);
  const { user } = useContext(StoreContext);
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>

      <StyledModalWrapper>
        <StyledLabelWrapper>
          <StyledLabel>Plik projektu:</StyledLabel>
        </StyledLabelWrapper>
        <StyledFileContainer>
          {opportunities.project?.file && (
            <ItemFile
              fileItem={opportunities.project?.file}
              setIsSended={setIsSended}
            />
          )}
          {opportunities.project?.files &&
            opportunities.project?.files.map((file) => (
              <ItemFile
                key={file.id}
                fileItem={file}
                setIsSended={setIsSended}
              />
            ))}
        </StyledFileContainer>

        <StyledLabelWrapper>
          <StyledLabel>Uwagi:</StyledLabel>
          {opportunities.project?.comments}
        </StyledLabelWrapper>
      </StyledModalWrapper>
      {(user.role?.id === 1 || user.role?.id === 3) && (
        <StyledButtonsWrapper>
          <StyledAddButton onClick={handleOnOpenEditModal}>
            Edytuj
          </StyledAddButton>
        </StyledButtonsWrapper>
      )}
      {isSended && (
        <StyledSendNoteWrapper>
          <StyledSendNoteInnerWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledSendNoteInnerWrapper>
        </StyledSendNoteWrapper>
      )}
    </Modal>
  );
};

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledFileContainer = styled.div`
  padding-bottom: 10px;
  max-width: 360px;
  display: grid;
  padding-top: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 120px));
`;

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledLabel = styled.p`
  color: #134771;
`;

const StyledLabelWrapper = styled.div``;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;
const StyledAddButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;

  background: #134771;
  color: rgb(202, 222, 238);

  &:hover {
    background: #053257;
  }
`;

const StyledModalWrapper = styled.div`
  padding: 10px;
  min-width: 320px;
`;
export default InstallationDesignShow;
