import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../../../../components/Modal";

import request from "../../../../../../../../../../helpers/request";

const validationSchema = yup.object({});
const EditAssemblyDetails = ({
  opportunities,
  assemblyDetails,
  isModalOpen,
  handleOnClose,
  fetchData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({});
  };

  const [roofType, setRoofType] = useState(0);
  const [cutMethod, setCutMethod] = useState(0);
  const [inverterMountingLocation, setInverterMountingLocation] = useState(1);
  const [chooseTile, setChooseTile] = useState(0);
  const [chooseSteelTile, setChooseSteelTile] = useState(0);
  const [roofInstallation, setRoofInstallation] = useState(1);
  const sendData = async (data) => {
    try {
      const { status } = await request.patch(
        `api/assembly-details/${assemblyDetails.id}`,
        {
          ...data,
        }
      );
      if (status === 201) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (assemblyDetails) {
      setValue("building_height", assemblyDetails?.building_height);
      setValue("building_length", assemblyDetails?.building_length);
      setValue("building_width", assemblyDetails?.building_width);
      setValue("building_received", Number(assemblyDetails?.building_received));
      setValue("cut_method", Number(assemblyDetails?.cut_method));
      setCutMethod(Number(assemblyDetails?.cut_method || 0));
      setValue("dimension_are", assemblyDetails?.dimension_are);
      setValue("excavator_entry", assemblyDetails?.excavator_entry);
      setValue("fastening_types", assemblyDetails?.fastening_types);
      setValue("flat_roof", assemblyDetails?.flat_roof);
      setValue("flat_roof_other", assemblyDetails?.flat_roof_other);
      setValue(
        "inverter_mounting_location",
        assemblyDetails?.inverter_mounting_location
      );
      setInverterMountingLocation(
        Number(assemblyDetails?.inverter_mounting_location) || 0
      );
      setValue(
        "inverter_mounting_location_description_1",
        assemblyDetails?.inverter_mounting_location_description_1
      );
      setValue(
        "inverter_mounting_location_description_2",
        assemblyDetails?.inverter_mounting_location_description_2
      );
      setValue("lightning_protection", assemblyDetails?.lightning_protection);
      setValue(
        "media_connections_energetic_join",
        Number(assemblyDetails?.media_connections_energetic_join)
      );
      setValue(
        "media_connections_gas",
        Number(assemblyDetails?.media_connections_gas)
      );
      setValue(
        "media_connections_optical_fiber",
        Number(assemblyDetails?.media_connections_optical_fiber)
      );
      setValue(
        "media_connections_other",
        assemblyDetails?.media_connections_other
      );
      setValue(
        "media_connections_rain_sawage",
        Number(assemblyDetails?.media_connections_rain_sawage)
      );
      setValue(
        "media_connections_sewage_treatment_plan",
        Number(assemblyDetails?.media_connections_sewage_treatment_plan)
      );
      setValue(
        "media_connections_sewers",
        Number(assemblyDetails?.media_connections_sewers)
      );
      setValue(
        "media_connections_tel_connection",
        Number(assemblyDetails?.media_connections_tel_connection)
      );
      setValue(
        "media_connections_water",
        Number(assemblyDetails?.media_connections_water)
      );
      setValue("number_of_floors", assemblyDetails?.number_of_floors);
      setValue("other_tile", Number(assemblyDetails?.other_tile));
      setValue("other_tile_other", assemblyDetails?.other_tile_other);
      setValue("rafters_roof_type", assemblyDetails?.rafters_roof_type);
      setValue(
        "rafters_roof_type_other",
        assemblyDetails?.rafters_roof_type_other
      );
      setValue("roof_angle", assemblyDetails?.roof_angle);
      setValue("roof_design", assemblyDetails?.roof_design);
      setValue("roof_edge_length", assemblyDetails?.roof_edge_length);
      setValue("roof_installation", assemblyDetails?.roof_installation);
      setRoofInstallation(Number(assemblyDetails?.roof_installation));
      setValue(
        "roof_installation_other",
        assemblyDetails?.roof_installation_other
      );
      setValue("roof_insulation", assemblyDetails?.roof_insulation);
      setValue("roof_type", assemblyDetails?.roof_type);
      setValue("roof_type_other", assemblyDetails?.roof_type_other);
      setValue(
        "roof_type_information",
        Number(assemblyDetails?.roof_type_information)
      );
      setRoofType(Number(assemblyDetails?.roof_type_information));

      setValue("side_one_length", assemblyDetails?.side_one_length);
      setValue("side_two_length", assemblyDetails?.side_two_length);
      setValue("soil_type_substrate", assemblyDetails?.soil_type_substrate);
      setValue(
        "soil_type_substrate_other",
        assemblyDetails?.soil_type_substrate_other
      );
      setValue("steel_tile", assemblyDetails?.steel_tile);
      setChooseSteelTile(Number(assemblyDetails?.steel_tile) || 0);
      setValue("tile", assemblyDetails?.tile);
      setChooseTile(Number(assemblyDetails?.tile) || 0);
      setValue("tile_other", assemblyDetails?.tile_other);
      setValue("tile_dimension", assemblyDetails?.tile_dimension);
      setValue("volume", assemblyDetails.volume);
      setValue("total_usable_area", assemblyDetails?.total_usable_area);
      setValue("dc_wire_length", assemblyDetails?.dc_wire_length);
      setValue("ac_wire_length", assemblyDetails?.ac_wire_length);
      setValue("cube_dismantle_length", assemblyDetails?.cube_dismantle_length);
      setValue("cut_length", assemblyDetails?.cut_length);
    }
  }, [assemblyDetails, assemblyDetails?.id]);

  const handleOnChangeRoofType = (e) => {
    setRoofType(Number(e.target.value));
    setValue("roof_type_information", Number(e.target.value));
    setValue("steel_tile", 0);
    setValue("tile", 0);
    setValue("tile_dimension", "");
    setValue("other_tile", 0);
    setValue("other_tile_other", "");
    setValue("fastening_types", 0);
    setValue("flat_roof", 0);
    setValue("flat_roof_other", "");
  };

  const handleOnChangeInverterMountingLocation = (e) => {
    setInverterMountingLocation(Number(e.target.value));

    setValue("inverter_mounting_location", Number(e.target.value));
  };

  const handleOnChangeCutMethod = (e) => {
    setCutMethod(Number(e.target.value));
    setValue("cut_method", Number(e.target.value));
  };

  const handleOnChangeTile = (e) => {
    setChooseTile(Number(e.target.value));
    setValue("tile", Number(e.target.value));

    setValue("tile_other", "");
  };
  const handleOnChangeSteelTile = (e) => {
    setChooseSteelTile(Number(e.target.value));
    setValue("steel_tile", Number(e.target.value));
    setValue("steel_tile_other", "");
  };

  const handleOnChangeRoofIntallation = (e) => {
    setRoofInstallation(Number(e.target.value));
    setValue("roof_installation", e.target.value);
    if (Number(e.target.value) === 3) {
      setValue("side_one_length", assemblyDetails.side_one_length);
      setValue("side_two_length", assemblyDetails.side_two_length);
      setValue("soil_type_substrate", assemblyDetails.soil_type_substrate || 0);
      setValue(
        "soil_type_substrate_other",
        assemblyDetails.soil_type_substrate_other
      );
      setValue("roof_type_information", 0);
      setValue("steel_tile", 0);
      setChooseSteelTile(0);
      setValue("steel_tile_other", "");
      setValue("tile", 0);
      setChooseTile(0);
      setValue("tile_other", "");
      setValue("tile_dimension", "");
      setValue("other_tile", 0);
      setValue("other_tile_other", "");
      setValue("roof_design", 0);
      setValue("building_length", "");
      setValue("building_width", "");
      setValue("building_height", "");
      setValue("roof_edge_length", "");
      setValue("roof_angle");
      setValue("dimension_are", 0);
      setValue("roof_insulation", 0);
      setValue("lightning_protection", 0);
      setValue("roof_type", 0);
      setRoofType(0);
    }
    if (Number(e.target.value) === 1 || Number(e.target.value) === 2) {
      setValue("roof_type_information", assemblyDetails.roof_type_other);
      setValue("steel_tile", assemblyDetails.steel_tile);
      setChooseSteelTile(Number(assemblyDetails.steel_tile));
      setValue("steel_tile_other", assemblyDetails.steel_tile_other);
      setValue("tile", assemblyDetails.tile);
      setChooseTile(Number(assemblyDetails.tile));
      setValue("tile_other", assemblyDetails.tile_other);
      setValue("tile_dimension", assemblyDetails.tile_dimension);
      setValue("other_tile", assemblyDetails.other_tile);
      setValue("other_tile_other", assemblyDetails.other_tile_other);
      setValue("roof_design", assemblyDetails.roof_design);
      setValue("building_length", assemblyDetails.building_length);
      setValue("building_width", assemblyDetails.building_length);
      setValue("building_height", assemblyDetails.building_height);
      setValue("roof_edge_length", assemblyDetails.roof_edge_length);
      setValue("roof_angle", assemblyDetails.roof_angle);
      setValue("dimension_are", assemblyDetails.dimension_are);
      setValue("roof_insulation", assemblyDetails.roof_insulation);
      setValue("lightning_protection", assemblyDetails.lightning_protection);
      setValue("roof_type", assemblyDetails.roof_type_information);
      setRoofType(Number(assemblyDetails.roof_type_information));
      setValue("side_one_length", "");
      setValue("side_two_length", "");
      setValue("soil_type_substrate", 0);
      setValue("soil_type_substrate_other", "");
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>

        <StyledInput>
          <div className="input-group">
            <label>Miejsce montażu paneli</label>
            <select
              onChange={handleOnChangeRoofIntallation}
              value={roofInstallation}
            >
              <option value={1}>Dom mieszkalny</option>
              <option value={2}>Budynek gospodarczy</option>
              <option value={3}>Instalacja naziemna</option>
              <option value={4}>Inne</option>
            </select>
          </div>
          <input type="hidden" {...register("roof_installation")} />
        </StyledInput>
        {errors?.roof_installation && (
          <span className="error">{errors.roof_installation.message}</span>
        )}
        {roofInstallation !== 3 && (
          <>
            <StyledTitleWrapper>Wymiary budynku</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>
                  Czy istnieje możliwość dostarczenia projektu dachu
                </label>
                <select {...register("roof_design")}>
                  <option value={0}>Wybierz...</option>
                  <option value={2}>Nie</option>
                  <option value={1}>Tak</option>
                </select>
              </div>
            </StyledInput>
            {errors?.roof_design && (
              <span className="error">{errors.roof_design.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Długość budynku (m)</label>
                <input type="number" {...register("building_length")} min={0} />
              </div>
            </StyledInput>
            {errors?.building_length && (
              <span className="error">{errors.building_length.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Szerokość budynku (m)</label>
                <input type="number" {...register("building_width")} min={0} />
              </div>
            </StyledInput>
            {errors?.building_width && (
              <span className="error">{errors.building_width.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Wysokość do krawędzi dachu</label>
                <input type="number" {...register("building_height")} />
              </div>
            </StyledInput>
            {errors?.building_height && (
              <span className="error">{errors.building_height.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Długość krawędzi dachu (m)</label>
                <input
                  type="number"
                  {...register("roof_edge_length")}
                  min={0}
                />
              </div>
            </StyledInput>
            {errors?.roof_edge_length && (
              <span className="error">{errors.roof_edge_length.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Kąt pochylenia dachu ( &deg; )</label>
                <input type="number" {...register("roof_angle")} min={0} />
              </div>
            </StyledInput>
            {errors?.roof_angle && (
              <span className="error">{errors.roof_angle.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Wymiary zostały </label>
                <select {...register("dimension_are")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Zmierzone</option>
                  <option value={2}>Oszacowane</option>
                </select>
              </div>
            </StyledInput>
            {errors?.dimension_are && (
              <span className="error">{errors.dimension_are.message}</span>
            )}
          </>
        )}
        <StyledTitleWrapper>Dane montażowe</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Budynek odebrany</label>
            <select {...register("building_received")}>
              <option value={0}>Wybierz...</option>
              <option value={1}>Tak</option>
              <option value={2}>Nie</option>
            </select>
          </div>
        </StyledInput>
        {errors?.building_received && (
          <span className="error">{errors.building_received.message}</span>
        )}

        {roofInstallation !== 3 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Izolacja dachu</label>
                <select {...register("roof_insulation")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Izolacja wierzchnia</option>
                  <option value={2}>Izolacja spodnia</option>
                  <option value={3}>Brak izolacji</option>
                </select>
              </div>
            </StyledInput>
            {errors?.roof_insulation && (
              <span className="error">{errors.roof_insulation.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Instalacja odgromowa</label>
                <select {...register("lightning_protection")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Nie istnieje</option>
                  <option value={2}>Istnieje</option>
                </select>
              </div>
            </StyledInput>
            {errors?.lightning_protection && (
              <span className="error">
                {errors.lightning_protection.message}
              </span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Rodzaj dachu</label>
                <select {...register("roof_type")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Dach jednospadowy</option>
                  <option value={2}>Dach dwuspadowy</option>
                  <option value={3}>Dach wielospadowy</option>
                  <option value={4}>Płaski</option>
                </select>
              </div>
            </StyledInput>
            {errors?.roof_type && (
              <span className="error">{errors.roof_type.message}</span>
            )}
          </>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Miejsce montażu falownika</label>
            <select onChange={handleOnChangeInverterMountingLocation}>
              <option value={1}>W budynku</option>
              <option value={2}>Poza budynkiem</option>
            </select>
          </div>
          <input type="hidden" {...register("inverter_mounting_location")} />
        </StyledInput>
        {errors?.inverter_mounting_location && (
          <span className="error">
            {errors.inverter_mounting_location.message}
          </span>
        )}

        {inverterMountingLocation === 1 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Opis miejsca montażu falownika</label>
                <input
                  type="text"
                  {...register("inverter_mounting_location_description_1")}
                />
              </div>
            </StyledInput>
            {errors?.inverter_mounting_location_description_1 && (
              <span className="error">
                {errors.inverter_mounting_location_description_1.message}
              </span>
            )}

            <StyledInput>
              <div className="input-group">
                <label>
                  Całkowita powierzcznia użytkowa (m<sup>2</sup>)
                </label>
                <input type="text" {...register("total_usable_area")} />
              </div>
            </StyledInput>
            {errors?.total_usable_area && (
              <span className="error">{errors.total_usable_area.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>
                  Kubatura (m<sup>3</sup>)
                </label>
                <input type="text" {...register("volume")} />
              </div>
            </StyledInput>
            {errors?.volume && (
              <span className="error">{errors.volume.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Ilość kondygnacji</label>
                <input type="text" {...register("number_of_floors")} />
              </div>
            </StyledInput>
            {errors?.number_of_floors && (
              <span className="error">{errors.number_of_floors.message}</span>
            )}
          </>
        )}

        {inverterMountingLocation === 2 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Opis miejsca montażu falownika</label>
                <input
                  type="text"
                  {...register("inverter_mounting_location_description_2")}
                />
              </div>
            </StyledInput>
            {errors?.inverter_mounting_location_description_2 && (
              <span className="error">
                {errors.inverter_mounting_location_description_2.message}
              </span>
            )}
          </>
        )}
        {roofInstallation !== 3 && (
          <>
            <StyledTitleWrapper> Rodzaje poszycia dachu</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>Rodzaj pokrycia dachowego</label>
                <select onChange={handleOnChangeRoofType} value={roofType}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Dachówka</option>
                  <option value={2}>Blacha</option>
                  <option value={3}>Inne</option>
                </select>
              </div>
              <input type="hidden" {...register("roof_type_information")} />
            </StyledInput>
            {errors?.tile && (
              <span className="error">{errors.tile.message}</span>
            )}
            {roofType === 1 && (
              <>
                <StyledInput>
                  <div className="input-group">
                    <label>Dachówka</label>
                    <select
                      onChange={handleOnChangeTile}
                      defaultValue={chooseTile}
                    >
                      <option value={0}>Wybierz...</option>
                      <option value={1}>Betonowa</option>
                      <option value={2}>Ceramiczna</option>
                      <option value={3}>Karpiówka</option>
                      <option value={4}>Inne</option>
                    </select>
                    <input type="hidden" {...register("tile")} />
                  </div>
                </StyledInput>
                {errors?.tile && (
                  <span className="error">{errors.tile.message}</span>
                )}
                <StyledInput>
                  <div className="input-group">
                    <label>Wymiary dachówki (cm x cm)</label>
                    <input type="text" {...register("tile_dimension")} />
                  </div>
                </StyledInput>
                {errors?.tile_dimension && (
                  <span className="error">{errors.tile_dimension.message}</span>
                )}
                {chooseTile === 4 && (
                  <>
                    <StyledInput>
                      <div className="input-group">
                        <label>Dachówka inne</label>
                        <input type="text" {...register("tile_other")} />
                      </div>
                    </StyledInput>
                    {errors?.tile_other && (
                      <span className="error">{errors.tile_other.message}</span>
                    )}
                  </>
                )}
              </>
            )}
            {roofType === 2 && (
              <>
                <StyledInput>
                  <div className="input-group">
                    <label>Blacha</label>
                    <select
                      onChange={handleOnChangeSteelTile}
                      value={chooseSteelTile}
                    >
                      <option value={0}>Wybierz...</option>
                      <option value={1}>Blachodachówka</option>
                      <option value={2}>Trapezowa</option>
                      <option value={3}>Płaska</option>
                      <option value={4}>Rąbek</option>
                      <option value={5}>Inne</option>
                    </select>
                    <input type="hidden" {...register("steel_tile")} />
                  </div>
                </StyledInput>
                {errors?.steel_tile && (
                  <span className="error">{errors.steel_tile.message}</span>
                )}
                {chooseSteelTile === 5 && (
                  <>
                    <StyledInput>
                      <div className="input-group">
                        <label>Blacha inne</label>
                        <input type="text" {...register("steel_tile_other")} />
                      </div>
                    </StyledInput>
                    {errors?.steel_tile_other && (
                      <span className="error">
                        {errors.steel_tile_other.message}
                      </span>
                    )}
                  </>
                )}
              </>
            )}
            {roofType === 3 && (
              <>
                <StyledInput>
                  <div className="input-group">
                    <label>Inne</label>
                    <select {...register("other_tile")}>
                      <option value={0}>Wybierz...</option>
                      <option value={1}>Gont Bitumiczny</option>
                      <option value={2}>Papa + deskowanie</option>
                      <option value={3}>Ondulina + płyta</option>
                      <option value={4}>Beton</option>
                      <option value={5}>Papa + beton</option>
                      <option value={6}>Membrana EPDM/PCV</option>
                      <option value={7}>Podsypka żwirowa</option>
                      <option value={8}>Inne</option>
                    </select>
                  </div>
                </StyledInput>
                {errors?.other_tile && (
                  <span className="error">{errors.other_tile.message}</span>
                )}
                <StyledInput>
                  <div className="input-group">
                    <label>Reszta Inne</label>
                    <input type="text" {...register("other_tile_other")} />
                  </div>
                </StyledInput>
                {errors?.other_tile_other && (
                  <span className="error">
                    {errors.other_tile_other.message}
                  </span>
                )}
              </>
            )}
          </>
        )}

        {roofInstallation === 3 && (
          <>
            <StyledTitleWrapper>Instalacja na gruncie:</StyledTitleWrapper>

            <StyledInput>
              <div className="input-group">
                <label>Długość boku 1 miejsca pod instalacje</label>
                <input type="text" {...register("side_one_length")} />
              </div>
            </StyledInput>
            {errors?.side_one_length && (
              <span className="error">{errors.side_one_length.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Długość boku 2 miejsca pod instalacje</label>
                <input type="text" {...register("side_two_length")} />
              </div>
            </StyledInput>
            {errors?.side_two_length && (
              <span className="error">{errors.side_two_length.message}</span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Rodzaj podłoża</label>
                <select {...register("soil_type_substrate")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Beton</option>
                  <option value={2}>Grunt</option>
                </select>
              </div>
            </StyledInput>
            {errors?.soil_type_substrate && (
              <span className="error">
                {errors.soil_type_substrate.message}
              </span>
            )}
            <StyledInput>
              <div className="input-group">
                <label>Rodzaj podłoża inne</label>
                <input type="text" {...register("soil_type_substrate_other")} />
              </div>
            </StyledInput>
            {errors?.soil_type_substrate_other && (
              <span className="error">
                {errors.soil_type_substrate_other.message}
              </span>
            )}
          </>
        )}
        <StyledTitleWrapper>Przyłącze elektryczne </StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Długość przewodu DC (m)</label>
            <input type="number" min={0} {...register("dc_wire_length")} />
          </div>
        </StyledInput>
        {errors?.dc_wire_length && (
          <span className="error">{errors.dc_wire_length.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Długość przewodu AC (m)</label>
            <input type="number" min={0} {...register("ac_wire_length")} />
          </div>
        </StyledInput>
        {errors?.ac_wire_length && (
          <span className="error">{errors.ac_wire_length.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Długość przekopu (m)</label>
            <input type="number" min={0} {...register("cut_length")} />
          </div>
        </StyledInput>
        {errors?.cut_length && (
          <span className="error">{errors.cut_length.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Sposób wykonania przekopu</label>
            <select onChange={handleOnChangeCutMethod} value={cutMethod}>
              <option value={0}>Wybierz...</option>
              <option value={1}>Ręcznie</option>
              <option value={2}>Koparką</option>
            </select>
          </div>
          <input type="hidden" {...register("cut_method")} />
        </StyledInput>
        {errors?.cut_method && (
          <span className="error">{errors.cut_method.message}</span>
        )}

        {(cutMethod === 1 || cutMethod === 2) && (
          <>
            <StyledTitleWrapperCutMethod>
              <p>
                Istniejące przyłącza ziemne mediów w okolicy planowanej
                instalacji:
              </p>
            </StyledTitleWrapperCutMethod>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_water"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Woda
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_gas"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Gaz
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_energetic_join"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przyłącze elektryczne
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_sewers"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Kanalizacja/szambo
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_optical_fiber"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Światłowód
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_sewage_treatment_plan"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przydomowa oczyszczalnia scieków
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_tel_connection"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przyłącze telekomunikacyjne
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_rain_sawage"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Kanalizacja deszczowa
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledInput>
              <div className="input-group">
                <label>Przyłącza ziemne mediów inne</label>
                <input type="text" {...register("media_connections_other")} />
              </div>
            </StyledInput>
            {errors?.media_connections_other && (
              <span className="error">
                {errors.media_connections_other.message}
              </span>
            )}
          </>
        )}
        {cutMethod === 2 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Czy koparka może wjechać</label>
                <select {...register("excavator_entry")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Tak</option>
                  <option value={2}>Nie</option>
                </select>
              </div>
            </StyledInput>
            {errors?.excavator_entry && (
              <span className="error">{errors.excavator_entry.message}</span>
            )}
          </>
        )}
        <StyledTitleWrapper>Rozbiórka kostki </StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Odcinek kostki do rozbiórki (mb)</label>
            <input
              type="number"
              min={0}
              {...register("cube_dismantle_length")}
            />
          </div>
        </StyledInput>
        {errors?.cube_dismantle_length && (
          <span className="error">{errors.cube_dismantle_length.message}</span>
        )}
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Zapisz
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};
const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;
const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTitleWrapperCutMethod = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const StyledForm = styled.form`
  max-width: 600px;
  min-width: 360px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 320px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default EditAssemblyDetails;
