import React, { useContext, useEffect } from "react";
import {
  Route,
  Switch,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router";
import { NavLink } from "react-router-dom";
import request from "../../../../helpers/request";
import styled from "styled-components";
import { StoreContext } from "../../../../store/StoreProvider";
import Construction from "./Pages/Construction/Construction";
import Inverters from "./Pages/Inverters/Inverters";
import Main from "./Pages/Main/Main";
import Optimizers from "./Pages/Optimizers/Optimizers";
import Panels from "./Pages/Panels/Panels";
import CutPrice from "./Pages/CutPrice/CutPrice";
import Instalation from "./Pages/Instalation/Instalation";

const OfferSettings = () => {
  let { path } = useRouteMatch();
  const { optimizers, setOptimizers } = useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();
  const fetchOptimizers = async () => {
    try {
      const { status, data } = await request.get("/api/optimizers");
      if (status === 200) {
        await setOptimizers(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (optimizers === undefined || optimizers.length === 0) {
      fetchOptimizers();
    }
  }, []);
  return (
    <>
      <div>
        <StyledOfferSettingsNav>
          <ul>
            <StyledOfferSettingsNavItem>
              <NavLink exact to="/settings/offer/" activeClassName="active">
                Ogólne
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink to="/settings/offer/panels" activeClassName="active">
                Panele
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink to="/settings/offer/inverters" activeClassName="active">
                Inwertery
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink
                to="/settings/offer/construction"
                activeClassName="active"
              >
                Konstrukcje
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink
                to="/settings/offer/instalation"
                activeClassName="active"
              >
                Instalacje
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink to="/settings/offer/optimizers" activeClassName="active">
                Optymalizatory
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink to="/settings/offer/cut-price" activeClassName="active">
                Koszty przekopu
              </NavLink>
            </StyledOfferSettingsNavItem>
          </ul>
        </StyledOfferSettingsNav>
      </div>

      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/`}>
            <Main />
          </Route>
          <Route path={`${path}/panels`}>
            <Panels />
          </Route>
          <Route path={`${path}/inverters`}>
            <Inverters />
          </Route>
          <Route path={`${path}/construction`}>
            <Construction />
          </Route>
          <Route path={`${path}/instalation`}>
            <Instalation />
          </Route>
          <Route path={`${path}/optimizers`}>
            <Optimizers />
          </Route>
          <Route path={`${path}/cut-price`}>
            <CutPrice />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  );
};

const StyledOfferSettingsNav = styled.nav`
  padding: 20px 40px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const StyledOfferSettingsNavItem = styled.li`
  list-style: none;
  a {
    display: block;
    padding: 10px 15px;
    font-size: 20px;
    text-decoration: none;
    color: #444;
    white-space: nowrap;
  }
  a.active {
    border-bottom: 4px solid #2d96db;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
`;
export default OfferSettings;
