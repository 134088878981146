import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import { StoreContext } from "../../../../../store/StoreProvider";

import AddLabour from "./AddLabour";
import LabourItem from "./LabourItem";

const Labour = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(false);

  const { labour, setLabour } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(false);
    setIsModalOpen(true);
  };

  const handleOpenEdit = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchLabour = async () => {
    try {
      const { status, data } = await request.get("/api/labour");
      if (status === 200) {
        setLabour(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (labour === undefined || labour.length === 0) {
      fetchLabour();
    }
  }, []);

  return (
    <StyledTaskTypeWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj robocizne</StyledButton>
      </StyledButtonWrapper>

      <StyledOfferTable>
        <StyledOfferTableHead>
          <tr>
            <StyledOfferTableHeadItem>Nazwa</StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Cena (netto)
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Cena usługi za 1kWp (netto)
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Marża (%)
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Akcje
            </StyledOfferTableHeadItem>
          </tr>
        </StyledOfferTableHead>
        <tbody>
          {labour.map((lab) => {
            return (
              <LabourItem
                key={lab.id}
                labour={lab}
                setEditObject={setEditObject}
                handleOpenEdit={handleOpenEdit}
                fetchCharger={fetchLabour}
              />
            );
          })}
        </tbody>
      </StyledOfferTable>

      <AddLabour
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchLabour}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledTaskTypeWrapper>
  );
};

const StyledOfferTable = styled.table`
  width: 100%;
  padding: 40px 0;
`;

const StyledOfferTableHead = styled.thead`
  font-size: 18px;
`;

const StyledOfferTableHeadItem = styled.th`
  text-align: left;
  font-size: 14px;
`;

const StyledTaskTypeWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: #cadeee;
  background: #134771;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;
export default Labour;
