import React, { createContext, useState } from "react";

export const OpportunityFilterContext = createContext(null);

const OpportunityFilterProvider = ({ children }) => {
  const [showFilter, setShowFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [statusFilterFunction, setStatusFilterFunction] = useState(null);
  const [departmentFilter, setDepartmentFilter] = useState(null);
  const [teamFilter, setTeamFilter] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [paymentFilter, setPaymentFilter] = useState(null);
  const [completeFilter, setCompleteFilter] = useState(null);
  const [osdFilter, setOsdFilter] = useState(false);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [completeFilterFunction, setCompleteFilterFunction] = useState(null);
  const [orderProductFilter, setOrderProductFilter] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  return (
    <OpportunityFilterContext.Provider
      value={{
        showFilter,
        setShowFilter,
        statusFilter,
        setStatusFilter,
        statusFilterFunction,
        setStatusFilterFunction,
        departmentFilter,
        setDepartmentFilter,
        typeFilter,
        setTypeFilter,
        paymentFilter,
        setPaymentFilter,
        completeFilter,
        setCompleteFilter,
        searchStartDate,
        setSearchStartDate,
        searchEndDate,
        setSearchEndDate,
        dateFilter,
        setDateFilter,
        completeFilterFunction,
        setCompleteFilterFunction,
        orderProductFilter,
        setOrderProductFilter,
        searchValue,
        setSearchValue,
        teamFilter,
        setTeamFilter,
        userFilter,
        setUserFilter,
        osdFilter,
        setOsdFilter,
      }}
    >
      {children}
    </OpportunityFilterContext.Provider>
  );
};

export default OpportunityFilterProvider;
