import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../../../helpers/request";
import styled from "styled-components";
import DeleteAlert from "./DeleteAlert";
import Switch from "../../../../../../../components/Switch";

const PanelItem = ({
  panel,
  fetchPanels,
  handleOpenEditTypeTask,
  setEditObject,
}) => {
  const { id, name, warranty, margin, price, is_active, width, length } = panel;
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOnEdit = () => {
    setEditObject(panel);
    handleOpenEditTypeTask();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/panels/${id}`);
    if (status === 200) {
      fetchPanels();
      setIsAlertOpen(false);
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const handleOnChangeActive = async () => {
    const { status } = await request.patch(`/api/panels/active/${id}`, {
      is_active: !is_active,
    });
    if (status === 200) {
      fetchPanels();
      setIsAlertOpen(false);
    }
  };

  return (
    <StyledTaskTypeItem>
      <StyledDescription>
        <Switch
          name={"check"}
          checked={is_active}
          handleOnChange={handleOnChangeActive}
          id={id}
        />
      </StyledDescription>
      <StyledDescription>
        <p>{name}</p>
      </StyledDescription>
      <StyledWarranty>
        <p>{warranty} lat</p>
      </StyledWarranty>
      <StyledWarranty>
        <p>{margin}%</p>
      </StyledWarranty>
      <StyledWarranty>
        <p>{width} cm</p>
      </StyledWarranty>
      <StyledWarranty>
        <p>{length} cm</p>
      </StyledWarranty>
      <StyledWarranty>
        <p>{price} zł</p>
      </StyledWarranty>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.tr``;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.td``;
const StyledWarranty = styled.td`
  text-align: right;
`;

const StyledButtonGroup = styled.td`
  text-align: right;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export default PanelItem;
