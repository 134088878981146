import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import "moment/locale/pl";
import { StoreContext } from "../../store/StoreProvider";
import request from "../../helpers/request";

import AssembleComponent from "./Components/AssembleComponent/AssembleComponent";
import AssemblyProvider from "../../store/AssemblyProvider";
const Assembly = () => {
  const {
    departments,
    setDepartments,
    teams,
    setTeams,
    assemblyBus,
    setAssemblyBus,
  } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchDataDepartments = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const fetchDataAssembluBus = async () => {
    try {
      const { status, data } = await request.get("/api/assembly-bus");
      if (status === 200) {
        await setAssemblyBus(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (teams === undefined || teams.length === 0) {
      fetchDataTeams();
    }
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartments();
    }
    if (assemblyBus === undefined || assemblyBus.length === 0) {
      fetchDataAssembluBus();
    }
  }, []);

  return (
    <AssemblyProvider>
      <StyledAssemblyWrapper>
        <StyledTitleWrapper>
          <StyledTitle>Planowanie montażu</StyledTitle>
        </StyledTitleWrapper>
        <StyledAssemblyComponentWrapper>
          <AssembleComponent assemblyBus={assemblyBus} />
        </StyledAssemblyComponentWrapper>
        <StyledMobileNote>
          Planowanie montażu niedostępne na wersji mobilnej. Wersja mobila w
          trakcie przygotowań{" "}
        </StyledMobileNote>
      </StyledAssemblyWrapper>
    </AssemblyProvider>
  );
};

const StyledMobileNote = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const StyledAssemblyComponentWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const StyledAssemblyWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

export default Assembly;
