import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import CollapseDepartmentItem from "./CollapseDepartmentItem";

const CollapseDepartment = ({
  setDepartmentFilter,
  departmentFilter,
  departments,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState(true);

  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <StyledCollapse>
      <StyledCollapseButton onClick={handleOnClick}>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
        <StyledCollapseTitle>Oddział</StyledCollapseTitle>
      </StyledCollapseButton>
      <StyledCollapseItemWrapper
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        {departments.map((element) => {
          return (
            <CollapseDepartmentItem
              departmentFilter={departmentFilter}
              setDepartmentFilter={setDepartmentFilter}
              id={element.id}
              name={element.name}
              key={element.id}
            />
          );
        })}
      </StyledCollapseItemWrapper>
    </StyledCollapse>
  );
};

const StyledCollapseItemWrapper = styled.div`
  overflow: hidden;

  transition: max-height 0.5s ease;
`;

const StyledCollapseTitle = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledCollapseButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  background: white;
  display: flex;
`;

const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;

  overflow: hidden;
`;

export default CollapseDepartment;
