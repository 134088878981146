import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Modal from "../../../../../../../../../components/Modal";
const ShowJoinDetails = ({
  energeticJoinDetails,
  isModalOpen,
  handleOnClose,
  handleOnEdit,
  opportunities,
}) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledWrapper>
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>

        <StyledSectionTitle>Dane przyłącza energetycznego</StyledSectionTitle>
        <StyledItemWrapper>
          <StyledItemTitle>Przyłącze energetyczne</StyledItemTitle>
          {Number(energeticJoinDetails.power_connection) === 1 && (
            <StyledItemValue>Napowietrzne</StyledItemValue>
          )}
          {Number(energeticJoinDetails.power_connection) === 2 && (
            <StyledItemValue>Kablowe</StyledItemValue>
          )}
        </StyledItemWrapper>
        {energeticJoinDetails.meter_installation_place !== null && (
          <StyledItemWrapper>
            <StyledItemTitle>Miejsce zainstalowania licznika:</StyledItemTitle>
            {Number(energeticJoinDetails.meter_installation_place) === 0 && (
              <StyledItemValue>Nie wybrano</StyledItemValue>
            )}
            {Number(energeticJoinDetails.meter_installation_place) === 1 && (
              <StyledItemValue>w ogrodzeniu</StyledItemValue>
            )}
            {Number(energeticJoinDetails.meter_installation_place) === 2 && (
              <StyledItemValue>w budynku</StyledItemValue>
            )}
            {Number(energeticJoinDetails.meter_installation_place) === 3 && (
              <StyledItemValue>na elewacji budynku</StyledItemValue>
            )}
          </StyledItemWrapper>
        )}
        <StyledItemWrapper>
          <StyledItemTitle>Dostawca energii elektrycznej</StyledItemTitle>
          {Number(energeticJoinDetails?.electricity_supplier) === 1 && (
            <StyledItemValue>PGE</StyledItemValue>
          )}
          {Number(energeticJoinDetails?.electricity_supplier) === 2 && (
            <StyledItemValue>Tauron</StyledItemValue>
          )}
          {Number(energeticJoinDetails?.electricity_supplier) === 3 && (
            <StyledItemValue>Enea</StyledItemValue>
          )}
          {Number(energeticJoinDetails?.electricity_supplier) === 4 && (
            <StyledItemValue>Energa</StyledItemValue>
          )}
          {Number(energeticJoinDetails?.electricity_supplier) === 5 && (
            <StyledItemValue>Innogy</StyledItemValue>
          )}
          {Number(energeticJoinDetails?.electricity_supplier) === 6 && (
            <StyledItemValue>Inne</StyledItemValue>
          )}
        </StyledItemWrapper>
        {Number(energeticJoinDetails?.electricity_supplier) === 6 && (
          <StyledItemWrapper>
            <StyledItemTitle>
              Dostawca energii elektrycznej inny{" "}
            </StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.electricity_suppiler_other}
            </StyledItemValue>
          </StyledItemWrapper>
        )}

        {energeticJoinDetails?.metering_protection_value ? (
          <StyledItemWrapper>
            <StyledItemTitle>
              Wartość zabezpieczenia przedlicznikowego (A):
            </StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.metering_protection_value}
            </StyledItemValue>
          </StyledItemWrapper>
        ) : (
          <StyledItemWrapper>
            <StyledItemTitleUncomplete>
              Wartość zabezpieczenia przedlicznikowego (A):
            </StyledItemTitleUncomplete>
            <StyledItemValueUncomplete>Brak</StyledItemValueUncomplete>
          </StyledItemWrapper>
        )}

        {energeticJoinDetails?.energy_consumption_point ? (
          <StyledItemWrapper>
            <StyledItemTitle>Nr PPE (Punkt Poboru Energii):</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.energy_consumption_point}
            </StyledItemValue>
          </StyledItemWrapper>
        ) : (
          <StyledItemWrapper>
            <StyledItemTitleUncomplete>
              Nr PPE (Punkt Poboru Energii):
            </StyledItemTitleUncomplete>
            <StyledItemValueUncomplete>Brak</StyledItemValueUncomplete>
          </StyledItemWrapper>
        )}

        {energeticJoinDetails?.counter_number ? (
          <StyledItemWrapper>
            <StyledItemTitle>Nr Licznika:</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.counter_number}
            </StyledItemValue>
          </StyledItemWrapper>
        ) : (
          <StyledItemWrapper>
            <StyledItemTitleUncomplete>Nr Licznika:</StyledItemTitleUncomplete>
            <StyledItemValueUncomplete>Brak</StyledItemValueUncomplete>
          </StyledItemWrapper>
        )}

        {energeticJoinDetails?.power_connection_power ? (
          <StyledItemWrapper>
            <StyledItemTitle>Moc przyłączeniowa (kW):</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.power_connection_power}
            </StyledItemValue>
          </StyledItemWrapper>
        ) : (
          <StyledItemWrapper>
            <StyledItemTitleUncomplete>
              Moc przyłączeniowa (kW):
            </StyledItemTitleUncomplete>
            <StyledItemValueUncomplete>Brak</StyledItemValueUncomplete>
          </StyledItemWrapper>
        )}

        {energeticJoinDetails?.contracted_power ? (
          <StyledItemWrapper>
            <StyledItemTitle>Moc umowna (kW):</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.contracted_power}
            </StyledItemValue>
          </StyledItemWrapper>
        ) : (
          <StyledItemWrapper>
            <StyledItemTitleUncomplete>
              Moc umowna (kW):
            </StyledItemTitleUncomplete>
            <StyledItemValueUncomplete>Brak</StyledItemValueUncomplete>
          </StyledItemWrapper>
        )}

        <StyledItemWrapper>
          <StyledItemTitle>Typ instalacji</StyledItemTitle>
          {Number(energeticJoinDetails.instalation_type) === 1 && (
            <StyledItemValue>Instalacja 1-fazowa</StyledItemValue>
          )}
          {Number(energeticJoinDetails.instalation_type) === 2 && (
            <StyledItemValue>Instalacja 3-fazowa</StyledItemValue>
          )}
        </StyledItemWrapper>

        <StyledItemWrapper>
          <StyledItemTitle>Dane przyłącza energetycznego:</StyledItemTitle>
          {Number(energeticJoinDetails.energetic_join_details_change) === 0 && (
            <StyledItemValue>Nie ulegną zmianie</StyledItemValue>
          )}
          {Number(energeticJoinDetails.energetic_join_details_change) === 1 && (
            <StyledItemValue>Wymagane zmiany</StyledItemValue>
          )}
        </StyledItemWrapper>

        {energeticJoinDetails.energetic_join_details_change !== 0 && (
          <>
            <StyledItemWrapper>
              <StyledItemTitle>Aktualizacja danych</StyledItemTitle>
              {Number(energeticJoinDetails.data_update) !== 0 ? (
                <StyledItemValue>Tak</StyledItemValue>
              ) : (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Przepisanie licznika</StyledItemTitle>
              {Number(energeticJoinDetails.rewriting_the_counter) !== 0 ? (
                <StyledItemValue>Tak</StyledItemValue>
              ) : (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Zmiana mocy przyłączeniowej</StyledItemTitle>
              {Number(energeticJoinDetails.connection_capacity_change) !== 0 ? (
                <StyledItemValue>Tak</StyledItemValue>
              ) : (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Scalenie liczników</StyledItemTitle>
              {Number(energeticJoinDetails.merge_counters) !== 0 ? (
                <StyledItemValue>Tak</StyledItemValue>
              ) : (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Zmiana faz</StyledItemTitle>
              {Number(energeticJoinDetails.phaze_change) !== 0 ? (
                <StyledItemValue>Tak</StyledItemValue>
              ) : (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Inne</StyledItemTitle>
              {Number(energeticJoinDetails.energetic_join_details_other) !==
              0 ? (
                <StyledItemValue>Tak</StyledItemValue>
              ) : (
                <StyledItemValue>Nie</StyledItemValue>
              )}
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Opis:</StyledItemTitle>
              <StyledItemValue>
                {energeticJoinDetails?.energetic_join_details}
              </StyledItemValue>
            </StyledItemWrapper>
          </>
        )}
      </StyledWrapper>
      <StyledSectionTitle>Dane do pełnomocnictwa</StyledSectionTitle>
      {energeticJoinDetails.people_count === 1 && opportunities.company && (
        <>
          <StyledInfoTitle> Osoba reprezentująca</StyledInfoTitle>
          <StyledItemWrapper>
            <StyledItemTitle>Imię i nazwisko:</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.proxy_name_1}
            </StyledItemValue>
          </StyledItemWrapper>

          <StyledItemWrapper>
            <StyledItemTitle>Seria i numer dowodu osobistego:</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.proxy_id_card_1}
            </StyledItemValue>
          </StyledItemWrapper>

          <StyledItemWrapper>
            <StyledItemTitle>Pesel:</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.proxy_pesel_1}
            </StyledItemValue>
          </StyledItemWrapper>
        </>
      )}

      {(energeticJoinDetails.people_count === 1 ||
        energeticJoinDetails.people_count === 2) &&
        !opportunities.company && (
          <>
            <StyledInfoTitle>Pierwszy nabywca z faktury</StyledInfoTitle>
            <StyledItemWrapper>
              <StyledItemTitle>Imię i nazwisko:</StyledItemTitle>
              <StyledItemValue>
                {energeticJoinDetails?.proxy_name_1}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>
                Seria i numer dowodu osobistego:
              </StyledItemTitle>
              <StyledItemValue>
                {energeticJoinDetails?.proxy_id_card_1}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Pesel:</StyledItemTitle>
              <StyledItemValue>
                {energeticJoinDetails?.proxy_pesel_1}
              </StyledItemValue>
            </StyledItemWrapper>
            <StyledItemWrapper>
              <StyledItemTitle>Ulica:</StyledItemTitle>
              <StyledItemValue>{energeticJoinDetails?.street}</StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Numer budynku:</StyledItemTitle>
              <StyledItemValue>
                {energeticJoinDetails?.building_number}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Kod-pocztowy:</StyledItemTitle>
              <StyledItemValue>
                {energeticJoinDetails?.post_code}
              </StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Miejscowość:</StyledItemTitle>
              <StyledItemValue>{energeticJoinDetails?.city}</StyledItemValue>
            </StyledItemWrapper>

            <StyledItemWrapper>
              <StyledItemTitle>Poczta:</StyledItemTitle>
              <StyledItemValue>{energeticJoinDetails?.post}</StyledItemValue>
            </StyledItemWrapper>
          </>
        )}

      {energeticJoinDetails.people_count === 2 && (
        <>
          <StyledInfoTitle>Drugi nabywca z faktury</StyledInfoTitle>
          <StyledItemWrapper>
            <StyledItemTitle>Imię i nazwisko:</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.proxy_name_2}
            </StyledItemValue>
          </StyledItemWrapper>

          <StyledItemWrapper>
            <StyledItemTitle>Seria i numer dowodu osobistego:</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.proxy_id_card_2}
            </StyledItemValue>
          </StyledItemWrapper>

          <StyledItemWrapper>
            <StyledItemTitle>Pesel:</StyledItemTitle>
            <StyledItemValue>
              {energeticJoinDetails?.proxy_pesel_2}
            </StyledItemValue>
          </StyledItemWrapper>
        </>
      )}

      <StyledButtonWrapper>
        <StyledEditButton onClick={handleOnEdit}>Edytuj</StyledEditButton>
      </StyledButtonWrapper>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  padding: 10px 0;

  align-items: center;
  justify-content: flex-start;
`;

const StyledEditButton = styled.button`
  padding: 0.8rem 1rem;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0;
`;
const StyledInfoTitle = styled.div`
  font-weight: bold;
  text-align: center;
  color: #134771;
  font-size: 16px;
  padding: 5px 0;
`;

const StyledItemTitle = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;
const StyledItemValue = styled.div``;
const StyledItemTitleUncomplete = styled.div`
  margin-right: 5px;
  font-weight: bold;
  color: rgb(244, 118, 49);
`;
const StyledItemValueUncomplete = styled.div`
  color: rgb(244, 118, 49);
`;
const StyledWrapper = styled.div``;
const StyledItemWrapper = styled.div`
  padding: 5px 0;
  display: flex;
`;

export default ShowJoinDetails;
