import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation, Redirect } from "react-router";
import styled from "styled-components";
import request from "../../../helpers/request";
import CompanyAddNote from "./CompanyAddNote/CompanyAddNote";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import CompanyNoteList from "./CompanyNoteList/CompanyNoteList";
import CompanySideBar from "./CompanySideBar/CompanySideBar";
import EditCompany from "./EditCompany/EditCompany";
const CompanyDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [company, setCompany] = useState([]);

  const fetchCompany = async () => {
    try {
      const { data, status } = await request.get(`api/companies/${id}`);
      if (status === 200) {
        setCompany(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      } else {
        history.push("/dashboard/contacts/companies");
      }
    }
  };

  useEffect(() => {
    setCompany([]);
    fetchCompany();
  }, []);

  const handleOnCloseEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
  };

  const handleOnOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  return (
    <StyledWrapper>
      <CompanyInfo company={company} handleOnClick={handleOnOpenEditModal} />
      <StyledMainWall>
        <CompanyAddNote fetchData={fetchCompany} companyId={company.id} />
        <CompanyNoteList note={company.notes} fetchData={fetchCompany} />
      </StyledMainWall>

      <CompanySideBar fetchCompany={fetchCompany} company={company} />

      <EditCompany
        handleOnClose={handleOnCloseEditModal}
        company={company}
        fetchData={fetchCompany}
        isModalOpen={isEditModalOpen}
      />
    </StyledWrapper>
  );
};

const StyledMainWall = styled.div`
  flex-grow: 1;
  width: 100%;
  grid-area: note;

  @media screen and (max-width: 1600px) {
    margin: 0;
    flex: 1;
  }
`;

const StyledWrapper = styled.div`
  display: grid;
  padding: 10px;
  max-width: 1400px;
  margin: 0 auto;
  flex: 1;
  grid-template-columns: minmax(320px, 340px) 1fr minmax(360px, 380px);
  grid-auto-rows: fit-content(1em);
  gap: 10px;
  grid-template-areas: "info note sidebar";

  @media screen and (max-width: 1600px) {
    grid-template-columns: 1fr minmax(360px, 380px);
    grid-template-areas:
      "info sidebar"
      "note sidebar"
      "none sidebar";
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "info"
      "sidebar"
      "note";
  }
`;

export default CompanyDetails;
