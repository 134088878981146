import React, { useEffect, useState } from "react";
import styled from "styled-components";

const HoureComponent = ({ index }) => {
  const [houre, setHoure] = useState("00:00");

  useEffect(() => {
    if (index < 10) {
      setHoure(`0${index}:00`);
    } else {
      setHoure(`${index}:00`);
    }
  }, []);
  return (
    <StyledHoureItem>
      <StyledHoureSpan>{index !== 0 && houre}</StyledHoureSpan>
    </StyledHoureItem>
  );
};

const StyledHoureSpan = styled.span`
  display: block;
  position: absolute;
  top: -8px;
  width: 35px;
  color: #134771;
  font-size: 12px;
  background: white;
`;
const StyledHoureItem = styled.div`
  border-top: 1px solid rgba(188, 188, 188, 0.4);
  position: relative;
  height: 40px;
`;

export default HoureComponent;
