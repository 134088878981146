import React from "react";
import styled from "styled-components";
import ChooseDepartment from "./Components/ChooseDepartment/ChooseDepartment";
import SortFilter from "./Components/SortFilter";

const AssemblyOpportunityAside = ({
  departmentFilter,
  setDepartmentFilter,
  sortFilter,
  setSortFilter,
}) => {
  return (
    <StyledAside>
      <SortFilter sortFilter={sortFilter} setSortFilter={setSortFilter} />

      <ChooseDepartment
        departmentFilter={departmentFilter}
        setDepartmentFilter={setDepartmentFilter}
      />
    </StyledAside>
  );
};

const StyledAside = styled.div`
  min-width: 200px;
`;
export default AssemblyOpportunityAside;
