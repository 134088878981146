import React from "react";

const Main = () => {
  return (
    <div>
      <p>
        Tu będa znajdowamy się ustawienia dotyczące kalendarza oraz zadań(ToDo)
      </p>
      Podział na podmenu w którym będzie można ustrawić Twoje zadania. Typy
      zadań oraz statusy zadań
    </div>
  );
};

export default Main;
