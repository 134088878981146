import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";

import * as yup from "yup";
import requestMulti from "../../../../helpers/requestMulti";

const validationSchema = yup.object({
  name: yup.string(),
});

const UserAddNote = ({ fetchData }) => {
  const [isOpenAddFileInput, setIsOpenAddFileInput] = useState("none");
  const [isSended, setIsSended] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const location = useLocation();
  const history = useHistory();

  const sendData = async (data) => {
    setIsSended(true);
    const formData = new FormData();

    formData.append(`text`, data.text);
    if (data.file) {
      for (let i = 0; i < data.file.length; i++) {
        formData.append(`file[${i}]`, data.file[i]);
      }
    }
    try {
      const { status } = await requestMulti.post("/api/note", formData);
      if (status === 200) {
        fetchData();
        resetInputValue();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const resetInputValue = () => {
    reset({
      text: "",
      file: null,
    });
  };
  const handleOnSubmitwithClose = async (data) => {
    if (data.text.trim() || (data.file && data.file.length)) {
      await sendData(data);
    }
  };

  const handleChangeAddFileInput = (e) => {
    e.preventDefault();
    if (isOpenAddFileInput === "none") {
      setIsOpenAddFileInput("block");
    } else {
      setIsOpenAddFileInput("none");
    }
  };
  return (
    <StyledAddNote>
      <StyledAddNoteForm encType="multipart/form-data">
        <StyledTextArea {...register("text")}></StyledTextArea>

        <StyledAddFileInputWrapper style={{ display: isOpenAddFileInput }}>
          <StyledAddFileInput type="file" {...register("file")} multiple />
        </StyledAddFileInputWrapper>
        <StyledButtonsWrapper>
          <StyledAddFileButton
            title="Dodaj załącznik"
            onClick={handleChangeAddFileInput}
          >
            <FontAwesomeIcon icon={faPaperclip} />
          </StyledAddFileButton>
          <div>
            <StyledCancelButton>Anuluj</StyledCancelButton>
            <StyledAddButton
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Dodaj Notatkę
            </StyledAddButton>
          </div>
        </StyledButtonsWrapper>
      </StyledAddNoteForm>
    </StyledAddNote>
  );
};

const StyledAddFileInputWrapper = styled.div``;
const StyledAddFileInput = styled.input``;
const StyledTextArea = styled.textarea`
  width: 100%;
`;
const StyledAddFileButton = styled.button`
  border: none;
  padding: 0.6rem 0.8rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-size: 18px;
  background: transparent;
  color: black;

  &:hover {
    color: #777;
  }
`;
const StyledAddNoteForm = styled.form`
  padding: 10px;
  background: white;

  @media screen and (max-width: 1300px) {
    margin: 10px 0;
  }
`;

const StyledAddNote = styled.div`
  width: 100%;
`;
const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCancelButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: transparent;
  color: #2d96db;

  &:hover {
    color: #005e9c;
  }
`;
const StyledAddButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;

  background: #2d96db;
  color: white;

  &:hover {
    background: #005e9c;
  }
`;
export default UserAddNote;
