import React, { useState } from "react";
import styled from "styled-components";

import AddAssemblyFault from "./AddFaultModal/AddFaultModal";
import AssemblyFaultModal from "./AssemblyFaultModal/AssemblyFaultModal";

const AssemblyFault = ({ opportunities, fetchData }) => {
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const openAssembluFaultShowModal = () => {
    setShowModalOpen(true);
  };

  const openAssemblyFaultAddModal = () => {
    setShowModalOpen(false);
    setAddModalOpen(true);
  };

  const closeShowModal = () => {
    setShowModalOpen(false);
  };
  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Usterki montażowe</StyledTitle>
      </StyledTitleWrapper>
      <StyledButtonWrapper>
        {opportunities.assembly_fault &&
        opportunities.assembly_fault.length > 0 ? (
          <StyledButton onClick={openAssembluFaultShowModal}>
            Zobacz zgłoszone usterki
          </StyledButton>
        ) : (
          <StyledButton onClick={openAssemblyFaultAddModal}>
            Dodaj usterkę montażową
          </StyledButton>
        )}
        <AssemblyFaultModal
          isModalOpen={showModalOpen}
          handleOnClose={closeShowModal}
          opportunities={opportunities}
          openAddModal={openAssemblyFaultAddModal}
        />
        <AddAssemblyFault
          isModalOpen={addModalOpen}
          handleOnClose={closeAddModal}
          fetchData={fetchData}
          opportunities={opportunities}
        />
      </StyledButtonWrapper>
    </StyledWrapper>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div`
  padding: 5px;
`;

export default AssemblyFault;
