import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import AddContactLeadNote from "./AddContactLeadNote";
import { StoreContext } from "../../../../../../../store/StoreProvider";

const TodoSidebarListItem = ({ todo, fetchData, handleOnClickEdit, lead }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMaintainer, setIsMaintainer] = useState(false);

  const { user } = useContext(StoreContext);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const today = moment().toDate();
  const tomorrow = moment().clone().add(1, "day").toDate();
  const [dateString, setDateString] = useState("");

  const changeDate = moment(todo.start_date).format("DD.MM.YYYY");
  useEffect(() => {
    if (Boolean(todo.some_day)) {
      setDateString("kiedyś");
    } else if (
      !Boolean(todo.some_day) &&
      Boolean(todo.start_date) &&
      Boolean(todo.end_date)
    ) {
      if (
        moment(todo.start_date).hour() === 0 &&
        moment(todo.start_date).minute() === 0 &&
        moment(todo.start_date).second() === 0 &&
        moment(todo.end_date).hour() === 23 &&
        moment(todo.end_date).minute() === 59 &&
        moment(todo.end_date).second() === 59
      ) {
        if (moment(todo.start_date).isSame(today, "day")) {
          setDateString("dzisiaj");
        } else if (moment(todo.start_date).isSame(tomorrow, "day")) {
          setDateString("jutro");
        } else if (moment(todo.start_date).isBefore(today, "day")) {
          setDateString(<div style={{ color: "#f47631" }}>{changeDate}</div>);
        } else {
          setDateString(changeDate);
        }
      } else {
        if (moment(todo.end_date).isBefore(moment(), "minute")) {
          if (moment(todo.start_date).isSame(todo.end_date, "day")) {
            setDateString(
              <div style={{ color: "#f47631" }}>
                {" "}
                <StyledDateString>
                  {moment(todo.start_date).format("DD.MM.YYYY")}
                </StyledDateString>
                <StyledDateString>
                  {moment(todo.start_date).format("HH:mm")}-
                  {moment(todo.end_date).format("HH:mm")}
                </StyledDateString>
              </div>
            );
          }
        } else {
          if (moment(todo.start_date).isSame(todo.end_date, "day")) {
            setDateString(
              <>
                <StyledDateString>
                  {moment(todo.start_date).format("DD.MM.YYYY")}
                </StyledDateString>
                <StyledDateString>
                  {moment(todo.start_date).format("HH:mm")}-
                  {moment(todo.end_date).format("HH:mm")}
                </StyledDateString>
              </>
            );
          }
        }
      }
    } else {
      setDateString();
      // `Dodane: ${moment(todo.created_at).format("DD.MM.YY HH:mm")}`
    }
  }, [todo.start_date, todo.end_date, todo]);

  const handleChangeComplete = async () => {
    if (todo.responsible.id === user.id || user.role?.id === 1) {
      if (!todo.done) {
        setIsModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (todo.responsible.id === user.id || user.role?.id === 1) {
      setIsMaintainer(true);
    } else {
      setIsMaintainer(false);
    }
  }, [todo, todo?.responsible, user]);

  return (
    <StyledItem>
      <StyledInfoWrapper>
        <StyledInfoIconWrapper>
          <StyledCheckWrapper>
            {isMaintainer ? (
              <StyledSpan
                onClick={handleChangeComplete}
                className="check-complete"
                style={
                  todo.done
                    ? { background: "green" }
                    : { background: "transparent" }
                }
                title={
                  todo.done
                    ? "oznacz jako nie wykonane"
                    : "oznacz jako wykonane"
                }
              ></StyledSpan>
            ) : (
              <StyledSpanDisabled
                style={
                  todo.done
                    ? { background: "green" }
                    : { background: "transparent" }
                }
                title={
                  todo.done
                    ? "oznacz jako nie wykonane"
                    : "oznacz jako wykonane"
                }
              ></StyledSpanDisabled>
            )}
          </StyledCheckWrapper>
          <StyledFontAwesomeIcon
            icon={[todo.taskType?.prefix, todo.taskType?.icon]}
          />
        </StyledInfoIconWrapper>

        <StyledInfo>
          <StyledName
            onClick={() => (todo.done ? null : handleOnClickEdit(todo))}
            title={todo.name}
          >
            {todo.name}
          </StyledName>
          <p>{todo.responsivePerson}</p>
        </StyledInfo>
        <StyledDateStringP>{dateString}</StyledDateStringP>
      </StyledInfoWrapper>
      {todo.description && (
        <StyledDescriptionWrapper>
          Opis: {todo.description}
        </StyledDescriptionWrapper>
      )}
      <AddContactLeadNote
        todo={todo}
        lead={lead}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
      />
    </StyledItem>
  );
};

const StyledDescriptionWrapper = styled.div`
  padding: 5px;
`;
const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDateString = styled.p`
  font-size: 14px;
`;
const StyledDateStringP = styled.div``;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;

  /* justify-content: space-evenly; */
  background: white;
  border-bottom: #dfdede 1px solid;
  width: 100%;
`;

const StyledInfoIconWrapper = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
`;
const StyledCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpan = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid lightblue;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
`;
const StyledSpanDisabled = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid lightblue;
  border-radius: 50%;
  margin-right: 5px;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 18px !important;
`;

const StyledInfo = styled.div`
  width: 55%;
`;
const StyledName = styled.p`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
export default TodoSidebarListItem;
