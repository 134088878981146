import React from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "../../../../../../../../components/Modal";
import request from "../../../../../../../../helpers/request";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ThirdTrancheModal = ({
  isModalOpen,
  handleOnClose,
  opportunities,
  setIsSended,
  fetchData,
  isSended,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { handleSubmit } = useForm();

  const sendThirdTranche = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.patch(
        `/api/opportunities/third-tranche/${opportunities.id}`,
        {
          ...data,
        }
      );

      if (status === 200) {
        fetchData();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data, e) => {
    e.preventDefault();
    sendThirdTranche(data);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        Wpłacono trzecią transzę
        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Potwierdź
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 600px;
  min-width: 300px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default ThirdTrancheModal;
