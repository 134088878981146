import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import styled from "styled-components";
import TopNav from "../../components/TopNav/TopNav";
import AccountInformation from "./Pages/AccountInformation";
import CalendarSettings from "./Pages/CalendarSettings/CalendarSettings";
import ContactSettings from "./Pages/ContactSettings/ContactSettings";
import DealSettings from "./Pages/DealSettings/DealSettings";
import OfferSettings from "./Pages/OfferSettings/OfferSettings";
import PermissionSetting from "./Pages/PermissionSettings/PermissionSetting";
import UserSettings from "./Pages/UsersSettings/UserSettings";
import SettingAside from "./SettingsAsideMenu/SettingAside";
import SourceSettings from "./Pages/SourceSettings/SourceSettings";
import AssemblySettings from "./Pages/AssemblySettings/AssemblySettings";
import HeatPumpOfferSettings from "./Pages/HeatPumpOfferSettings/HeatPumpOfferSettings";
import ChargerSettings from "./Pages/ChargerSettings/ChargerSettings";
import EnergyStorageSettings from "./Pages/EnergyStorageSettings/EnergyStorageSettings";
import LabourSettings from "./Pages/LabourSettings/LabourSettings";
import Transport from "./Pages/TransportSettings/Transport/Transport";

const SettingsIndex = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <TopNav />

      <ContentWrapper>
        <StyledAside>
          <SettingAside />
        </StyledAside>
        <ContentContainer>
          <Switch>
            <Route exact path={`${path}/`}>
              <AccountInformation />
            </Route>
            <Route path={`${path}/users`}>
              <UserSettings />
            </Route>
            <Route path={`${path}/permission`}>
              <PermissionSetting />
            </Route>
            <Route path={`${path}/contact`}>
              <ContactSettings />
            </Route>
            <Route path={`${path}/deal`}>
              <DealSettings />
            </Route>
            <Route path={`${path}/calendar`}>
              <CalendarSettings />
            </Route>
            <Route path={`${path}/offer`}>
              <OfferSettings />
            </Route>
            <Route path={`${path}/heat-pump-offer`}>
              <HeatPumpOfferSettings />
            </Route>
            <Route path={`${path}/source`}>
              <SourceSettings />
            </Route>
            <Route path={`${path}/assembly`}>
              <AssemblySettings />
            </Route>
            <Route path={`${path}/charger`}>
              <ChargerSettings />
            </Route>
            <Route path={`${path}/energy-storage`}>
              <EnergyStorageSettings />
            </Route>
            <Route path={`${path}/labour`}>
              <LabourSettings />
            </Route>
            <Route path={`${path}/transport`}>
              <Transport />
            </Route>
          </Switch>
        </ContentContainer>
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  margin: 80px auto 0;
  padding: 10px 0;
  width: 1200px;
`;
const ContentContainer = styled.div`
  width: 78%;
  background: #fff;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 100px);
`;
const StyledAside = styled.aside`
  width: 22%;
  padding: 0 10px;
`;

export default SettingsIndex;
