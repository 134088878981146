import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import AddHeatPump from "./AddHeatPump/AddHeatPump";
import HeatPumpItem from "./HeatPumpItem/HeatPumpItem";

const HeatPump = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(false);
  const [heatPumps, setHeatPumps] = useState([]);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(false);
    setIsModalOpen(true);
  };

  const handleOpenEditTypeTask = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchHeatPumps = async () => {
    try {
      const { status, data } = await request.get("/api/heat-pump");
      if (status === 200) {
        setHeatPumps(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (heatPumps === undefined || heatPumps.length === 0) {
      fetchHeatPumps();
    }
  }, []);

  return (
    <StyledTaskTypeWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>
          Dodaj Pompę Ciepła
        </StyledButton>
      </StyledButtonWrapper>

      <StyledOfferTable>
        <StyledOfferTableHead>
          <tr>
            <StyledOfferTableHeadItem>Aktywny</StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem>
              Nazwa Producenta
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem>Nazwa Modelu</StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem>Typ</StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem>Moc</StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem>Cena (zł)</StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Gwarancja
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Akcje
            </StyledOfferTableHeadItem>
          </tr>
        </StyledOfferTableHead>
        <tbody>
          {heatPumps.map((heatPump) => {
            return (
              <HeatPumpItem
                handleOpenEditTypeTask={handleOpenEditTypeTask}
                fetchHeatPumps={fetchHeatPumps}
                setEditObject={setEditObject}
                heatPump={heatPump}
                key={heatPump.id}
              />
            );
          })}
        </tbody>
      </StyledOfferTable>
      <AddHeatPump
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchHeatPumps}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledTaskTypeWrapper>
  );
};

const StyledTaskTypeWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledOfferTable = styled.table`
  width: 100%;
  padding: 40px 0;
`;

const StyledOfferTableHead = styled.thead`
  font-size: 18px;
`;

const StyledOfferTableHeadItem = styled.th`
  text-align: start;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default HeatPump;
