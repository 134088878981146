import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment";

const ChooseTime = ({
  errors,
  today,
  startDate,
  setStartDate,
  register,
  endDate,
  setEndDate,
}) => {
  const startDateRef = useRef(null);
  const [isDateShow, setIsDateShow] = useState(false);
  useEffect(() => {
    if (!startDate && !endDate) {
      setIsDateShow(false);
    }
  }, [startDate, endDate]);

  const handleOnWithoutDate = (e) => {
    e.preventDefault();
    setIsDateShow(!isDateShow);

    if (isDateShow) {
      if (startDate) {
        setStartDate(null);
      }
      if (endDate) {
        setEndDate(null);
      }
    } else {
      const minutes = moment().minutes();
      const hour = moment().hour();
      const day = moment();

      if (minutes <= 15) {
        setStartDate(day.hour(hour).minute(15).toDate());
        setEndDate(
          day.clone().hour(hour).minute(15).add(15, "minute").toDate()
        );
      } else if (minutes <= 30) {
        setStartDate(day.clone().minute(30).toDate());
        setEndDate(
          day.clone().hour(hour).minute(30).add(15, "minute").toDate()
        );
      } else if (minutes <= 45) {
        setStartDate(day.clone().hour(hour).minute(45).toDate());
        setEndDate(
          day.clone().hour(hour).minute(45).add(15, "minute").toDate()
        );
      } else if (minutes <= 60) {
        setStartDate(day.clone().hour(hour).minute(0).add(1, "hour").toDate());
        setEndDate(
          day
            .clone()
            .hour(hour)
            .minute(0)
            .add(1, "hour")
            .add(15, "minute")
            .toDate()
        );
      } else if (minutes === 0) {
        setStartDate(day.clone().hour(hour).minute(0).toDate());
        setEndDate(day.clone().hour(hour).minute(15).toDate());
      }
    }
  };
  const handleOnChangeStartDate = (date, e) => {
    const day = moment(date);

    const minutes = moment().minutes();
    const hour = moment().hour();

    if (minutes <= 15) {
      setStartDate(day.hour(hour).minute(15).toDate());
      setEndDate(day.clone().hour(hour).minute(15).add(15, "minute").toDate());
    } else if (minutes <= 30) {
      setStartDate(day.clone().minute(30).toDate());
      setEndDate(day.clone().hour(hour).minute(30).add(15, "minute").toDate());
    } else if (minutes <= 45) {
      setStartDate(day.clone().hour(hour).minute(45).toDate());
      setEndDate(day.clone().hour(hour).minute(45).add(15, "minute").toDate());
    } else if (minutes <= 60) {
      setStartDate(day.clone().hour(hour).minute(0).add(1, "hour").toDate());
      setEndDate(
        day
          .clone()
          .hour(hour)
          .minute(0)
          .add(2, "hour")
          .add(15, "minute")
          .toDate()
      );
    } else if (minutes === 0) {
      setStartDate(day.clone().hour(hour).minute(0).toDate());
      setEndDate(day.clone().hour(hour).minute(15).toDate());
    }
  };

  const handleOnChangeStartMinutes = (date) => {
    var minutes = moment
      .duration(
        moment(endDate, "YYYY/MM/DD HH:mm").diff(
          moment(date, "YYYY/MM/DD HH:mm")
        )
      )
      .asMinutes();
    if (minutes < 15) {
      setStartDate(date);
      setEndDate(moment(date).add(15, "minutes").toDate());
    } else if (minutes >= 15) {
      setStartDate(date);
    }
  };

  const handleOnChangeEndMinutes = (date) => {
    var minutes = moment
      .duration(
        moment(date, "YYYY/MM/DD HH:mm").diff(
          moment(startDate, "YYYY/MM/DD HH:mm")
        )
      )
      .asHours();
    if (minutes < 15) {
      setStartDate(moment(date).subtract(15, "minutes").toDate());
      setEndDate(date);
    } else if (minutes >= 15) {
      setEndDate(date);
    }
  };

  const handleOnchangeEndDate = (date) => {
    if (moment(date).isSame(startDate, "day")) {
      setEndDate(moment(date).endOf("day").toDate());
    } else if (moment(date).isAfter(startDate, "day")) {
      setEndDate(moment(date).endOf("day").toDate());
    } else if (moment(date).isBefore(startDate, "day")) {
      setStartDate(moment(date).startOf("day").toDate());
      setEndDate(moment(date).endOf("day").toDate());
    }
  };

  const StartDayInput = forwardRef(({ value, onClick }, ref) => (
    <StyledStartDateSpan onClick={onClick} ref={ref}>
      {moment(value).format("dddd, DD MMMM")}
    </StyledStartDateSpan>
  ));
  const StartTimeInput = forwardRef(({ value, onClick }, ref) => (
    <StyledStartDateSpan className={false ? "hidden" : ""} onClick={onClick}>
      {value}
    </StyledStartDateSpan>
  ));
  const EndDayInput = forwardRef(({ value, onClick }, ref) => (
    <StyledStartDateSpan
      className={false ? "" : "hidden"}
      onClick={onClick}
      ref={ref}
    >
      {moment(value).format("dddd, DD MMMM")}
    </StyledStartDateSpan>
  ));
  const EndTimeInput = forwardRef(({ value, onClick }, ref) => (
    <StyledStartDateSpan className={false ? "hidden" : ""} onClick={onClick}>
      {value}
    </StyledStartDateSpan>
  ));

  return (
    <StyledInputGroupWrapper>
      <StyledInputWrapper
        style={isDateShow ? { display: "flex" } : { display: "none" }}
      >
        <StyledDateInput>
          <DatePicker
            ref={startDateRef}
            customInput={<StartDayInput />}
            minDate={today}
            locale="pl"
            selected={startDate}
            onChange={(date, e) => handleOnChangeStartDate(date, e)}
            selectsStart
            startDate={startDate}
            shouldCloseOnSelect={false}
            endDate={endDate}
          />
          <DatePicker
            locale="pl"
            showTimeSelect
            showTimeSelectOnly
            customInput={<StartTimeInput />}
            selected={startDate}
            onChange={handleOnChangeStartMinutes}
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            startDate={startDate}
            endDate={endDate}
            selectsStart
          />
          <StyledSpanLine> - </StyledSpanLine>
          <DatePicker
            showTimeSelect
            showTimeSelectOnly
            customInput={<EndTimeInput />}
            locale="pl"
            selected={endDate}
            onChange={handleOnChangeEndMinutes}
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            startDate={startDate}
            endDate={endDate}
            selectsEnd
          />
          <DatePicker
            customInput={<EndDayInput />}
            minDate={today}
            locale="pl"
            selected={endDate}
            onChange={handleOnchangeEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
          />

          {errors?.start_date && (
            <span className="error">{errors.start_date.message}</span>
          )}
          {errors?.end_date && (
            <span className="error">{errors.end_date.message}</span>
          )}
        </StyledDateInput>
      </StyledInputWrapper>
      <StyledChooseDayButton onClick={handleOnWithoutDate}>
        {isDateShow ? "zadanie bez daty" : "zadanie z datą"}
      </StyledChooseDayButton>
    </StyledInputGroupWrapper>
  );
};

const StyledChooseDayButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledStartDateSpan = styled.span`
  font-size: 14px;
  cursor: pointer;
  padding: 5px 0;
  margin-right: 5px;
  &:hover {
    text-decoration: underline;
  }

  &.hidden {
    display: none;
  }
`;

const StyledSpanLine = styled.span`
  margin: 0 10px 0 5px;
`;

const StyledInputWrapper = styled.div`
  margin-top: 0px;
  display: flex;
  min-width: 0;
`;

const StyledInputGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;

  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const StyledDateInput = styled.div`
  display: flex;
  width: 100%;

  min-width: 0;
  margin-bottom: 10px;

  &:hover {
    background: #cadeee;
  }

  & .react-datepicker-wrapper {
    /* flex: 1; */
    min-width: 0;
  }

  & .react-datepicker__input-container {
    min-width: 0;
    display: flex;
  }
  & .react-datepicker-wrapper input {
    outline: none;
    flex: 1 1;
    min-width: 0%;
    border: none;
  }
`;

export default ChooseTime;
