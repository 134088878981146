import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../components/Modal";
import request from "../../../../helpers/request";
import { StoreContext } from "../../../../store/StoreProvider";
import ChooseUser from "./ChooseUser/ChooseUser";

const validationSchema = yup.object().shape(
  {
    name: yup.string().when(["phone", "email"], {
      is: (phone, email) => !phone && !email,
      then: yup.string().required("Wpisz jedno z trzech pól"),
    }),

    phone: yup.string().when(["name", "email"], {
      is: (name, email) => !name && !email,
      then: yup
        .string()
        .matches(/^[0-9]+$/, {
          message: "Numer telefonu musi składać się wyłącznie z cyfr 1-9",
          excludeEmptyString: true,
        })
        .required("Wpisz jedno z trzech pól")
        .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków"),
    }),
    email: yup
      .string()
      .email("proszę podać prawidłowy adres email")
      .when(["phone", "name"], {
        is: (phone, name) => !phone && !name,
        then: yup.string().required("Wpisz jedno z trzech pól"),
        otherwise: yup.string().nullable(),
      }),
    voivodeship: yup
      .string()
      .required("Proszę wybrać województwo")
      .typeError("Proszę wybrać województwo"),
    source_id: yup
      .string()
      .required("Proszę wybrać źródło pozyzkania Leada")
      .typeError("Proszę wybrać źródło pozyskania Leada"),
    description: yup.string().nullable(),
  },
  [
    ["email", "phone"],
    ["name", "phone"],
    ["email", "name"],
  ]
);

const AddLead = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const { departments, sources, teams, user, voivodeships, leadTypes } =
    useContext(StoreContext);

  const [isBuisness, setIsBuisness] = useState(false);
  const [name, setName] = useState("Wybierz użytkownika");
  const [isSended, setIsSended] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      name: "",
      phone: "",
      email: "",
      maintainer_id: user.id,
      voivodeship: "",
      source_id: "",
      lead_type: 1,
      description: "",
      is_buisness: false,
    });
    setName("Wybierz użytkownika");
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      if (isEditMode) {
        const { status } = await request.patch(`/api/lead/${editObject.id}`, {
          ...data,
        });
        if (status === 201) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      } else {
        const { status } = await request.post("/api/lead", { ...data });
        if (status === 200) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
      if (e.response?.status === 422) {
        if (e.response?.data?.errors?.hasOwnProperty("phone")) {
          setError("phone", {
            type: "manual",
            message: "Lead o tym nr. telefonu już istnieje",
          });
        }
        if (e.response?.data?.errors?.hasOwnProperty("email")) {
          setError("email", {
            type: "manual",
            message: "Lead o tym adresie email już istnieje",
          });
        }
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (editObject) {
      setValue("name", editObject.name);
      setValue("phone", editObject.phone);
      setValue("email", editObject.email);
      setValue("maintainer_id", editObject.maintainer?.id);
      setValue("voivodeship", editObject.voivodeship?.id);
      setValue("description", editObject.description);
      setValue("source_id", editObject.source?.id);
      setValue("lead_type", editObject.lead_type?.id);
      setValue("is_buisness", Boolean(editObject.is_buisness) || false);
    }
  }, [editObject]);

  useEffect(() => {
    if (!isEditMode) {
      setValue("name", "");
      setValue("phone", "");
      setValue("email", "");
      setValue("maintainer_id", user.id);
      setValue("voivodeship", "");
      setValue("description", "");
      setValue("source_id", "");
      setValue("lead_type", 1);
      setValue("is_buisness", false);
    }
  }, [isEditMode]);

  const handleOnChangeCheckBox = (e) => {
    setIsBuisness(e.target.checked);
    setValue("is_buisness", e.target.checked);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledInput>
          <div className="input-group">
            <label>Nazwa</label>
            <input type="text" {...register("name")} />
          </div>
        </StyledInput>
        {errors?.name && <span className="error">{errors.name.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input type="number" {...register("phone")} />
          </div>
        </StyledInput>
        {errors?.phone && <span className="error">{errors.phone.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Adres e-mail</label>
            <input type="text" {...register("email")} />
          </div>
        </StyledInput>
        {errors?.email && <span className="error">{errors.email.message}</span>}

        <ChooseUser
          departments={departments}
          teams={teams}
          setValue={setValue}
          setValueTitle="maintainer_id"
          user={user}
          name={name}
          setName={setName}
          isEditMode={isEditMode}
          editUser={editObject?.maintainer}
        />
        <input type="hidden" {...register("maintainer_id")} />
        {errors?.maintainer_id && (
          <span className="error">{errors.maintainer_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Źródło pozyskania:</label>
            <select {...register("source_id")}>
              <option value="">Wybierz...</option>
              {sources.map((source) => {
                return (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.source_id && (
          <span className="error">{errors.source_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Typ Leada:</label>
            <select {...register("lead_type")}>
              {leadTypes.map((lead_type) => {
                return (
                  <option key={lead_type.id} value={lead_type.id}>
                    {lead_type.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.source_id && (
          <span className="error">{errors.source_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Województwo</label>
            <select {...register("voivodeship")}>
              <option value="">Wybierz...</option>
              {voivodeships &&
                voivodeships.map((voivodeship) => (
                  <option value={voivodeship.id} key={voivodeship.id}>
                    {voivodeship.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.voivodeship && (
          <span className="error">{errors.voivodeship.message}</span>
        )}

        <StyledCheckBox>
          <StyledInputGroup>
            <StyledCheckBoxLabel>
              <StyledCheckBoxInput
                type="checkbox"
                checked={isBuisness}
                onChange={handleOnChangeCheckBox}
              />
              Klient biznesowy
            </StyledCheckBoxLabel>
            <input type="hidden" {...register("is_buisness")} />
            {isBuisness && (
              <StyledBuissnesInfo>
                Lead trafi do oddziału biznes
              </StyledBuissnesInfo>
            )}
          </StyledInputGroup>
        </StyledCheckBox>

        <StyledInput>
          <div className="input-group">
            <label>Opis</label>
            <input type="text" {...register("description")} />
          </div>
        </StyledInput>
        {errors?.description && (
          <span className="error">{errors.country.message}</span>
        )}

        {isEditMode ? (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Zapisz
            </button>
          </div>
        ) : (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Dodaj
            </button>
          </div>
        )}
      </StyledForm>
    </Modal>
  );
};

const StyledBuissnesInfo = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 14px;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddLead;
