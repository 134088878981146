import React, { useState } from "react";
import styled from "styled-components";
import AddOwnerDetails from "./AddOwnerDetails/AddOwnerDetails";
import EditOwnerDetails from "./EditOwnerDetails/EditOwnerDetails";
import ShowOwnerDetails from "./ShowOwnerDetails";

const OwnerDetails = ({ ownerDetails, opportunities, fetchData }) => {
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOnShow = () => {
    setIsShowModalOpen(true);
  };
  const handleOnAdd = () => {
    setIsAddModalOpen(true);
  };

  const handleOnCloseShowModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
  };

  const handleOnEdit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsShowModalOpen(false);
    setIsEditModalOpen(true);
  };
  const handleOnCloseEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
  };

  const handleOnCloseAddModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAddModalOpen(false);
  };
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Dane Właściciela</StyledTitle>
      </StyledTitleWrapper>

      {!ownerDetails && (
        <StyledButtonWrapper>
          <StyledButtonLight onClick={handleOnAdd}>
            Dodaj dane właściciela
          </StyledButtonLight>
        </StyledButtonWrapper>
      )}

      {ownerDetails && (
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnShow}>
            Pokaż dane właściciela
          </StyledButton>
        </StyledButtonWrapper>
      )}

      {ownerDetails && (
        <ShowOwnerDetails
          opportunities={opportunities}
          ownerDetails={ownerDetails}
          isModalOpen={isShowModalOpen}
          handleOnClose={handleOnCloseShowModal}
          handleOnEdit={handleOnEdit}
        />
      )}

      <AddOwnerDetails
        opportunities={opportunities}
        isModalOpen={isAddModalOpen}
        handleOnClose={handleOnCloseAddModal}
        fetchData={fetchData}
      />
      <EditOwnerDetails
        ownerDetails={ownerDetails}
        isModalOpen={isEditModalOpen}
        handleOnClose={handleOnCloseEditModal}
        fetchData={fetchData}
        opportunities={opportunities}
      />
    </StyledWrapper>
  );
};

const StyledButtonLight = styled.button`
  padding: 5px 10px;
  border: none;
  background: #cadeee;
  color: #053257;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #cadeee;
  }
`;
const StyledButton = styled.button`
  padding: 5px 10px;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledWrapper = styled.div``;

export default OwnerDetails;
