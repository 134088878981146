import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import ItemDropDown from "./ItemDropDown";

const AllCompanyDropdown = ({
  user,
  departments,
  teams,
  handleOnChooseUser,
}) => {
  return (
    <StyledWrapper>
      <StyledItemWrapper>
        {user.role?.id === 2 && (
          <>
            {departments &&
              departments
                .filter(
                  (department) =>
                    department.voivodeship.id === user.department.voivodeship_id
                )
                .map((department) => {
                  return (
                    <ItemDropDown
                      key={department.id}
                      title={department.name}
                      users={department.users}
                      handleOnChooseUser={handleOnChooseUser}
                    />
                  );
                })}
          </>
        )}
        {(user.role?.id === 1 || user.role?.id === 3) && (
          <>
            {departments &&
              departments.map((department) => {
                return (
                  <ItemDropDown
                    key={department.id}
                    title={department.name}
                    users={department.users}
                    handleOnChooseUser={handleOnChooseUser}
                  />
                );
              })}{" "}
            {teams &&
              teams.map((team) => {
                return (
                  <ItemDropDown
                    key={team.id}
                    title={team.name}
                    users={team.users}
                    handleOnChooseUser={handleOnChooseUser}
                  />
                );
              })}
          </>
        )}

        {/* <ItemDropDown title="Oddziały" /> */}
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

const StyledItemWrapper = styled.div``;
const StyledWrapper = styled.div``;
export default AllCompanyDropdown;
