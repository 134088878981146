import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import AddContactLeadNote from "./AddContactLeadNote";
import AddContactNote from "./AddContactNote";

const TodoSidebarListItem = ({ todo, fetchData }) => {
  const today = moment().toDate();
  const tomorrow = moment().clone().add(1, "day").toDate();
  const [dateString, setDateString] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpportunityModalOpen, setIsOpportunityModalOpen] = useState(false);

  const changeDate = moment(todo.start_date).format("DD.MM.YYYY");

  useEffect(() => {
    if (Boolean(todo.some_day)) {
      setDateString("kiedyś");
    } else if (
      !Boolean(todo.some_day) &&
      Boolean(todo.start_date) &&
      Boolean(todo.end_date)
    ) {
      if (
        moment(todo.start_date).hour() === 0 &&
        moment(todo.start_date).minute() === 0 &&
        moment(todo.start_date).second() === 0 &&
        moment(todo.end_date).hour() === 23 &&
        moment(todo.end_date).minute() === 59 &&
        moment(todo.end_date).second() === 59
      ) {
        if (moment(todo.start_date).isSame(today, "day")) {
          setDateString("dzisiaj");
        } else if (moment(todo.start_date).isSame(tomorrow, "day")) {
          setDateString("jutro");
        } else if (moment(todo.start_date).isBefore(today, "day")) {
          setDateString(<div style={{ color: "#f47631" }}>{changeDate}</div>);
        } else {
          setDateString(changeDate);
        }
      } else {
        if (moment(todo.end_date).isBefore(moment(), "minute")) {
          if (moment(todo.start_date).isSame(todo.end_date, "day")) {
            setDateString(
              <div style={{ color: "#f47631" }}>
                {" "}
                <StyledDateStringP>
                  {moment(todo.start_date).format("DD.MM.YYYY")}
                </StyledDateStringP>
                <StyledDateStringP>
                  {moment(todo.start_date).format("HH:mm")}-
                  {moment(todo.end_date).format("HH:mm")}
                </StyledDateStringP>
              </div>
            );
          }
        } else {
          if (moment(todo.start_date).isSame(todo.end_date, "day")) {
            setDateString(
              <>
                <StyledDateStringP>
                  {moment(todo.start_date).format("DD.MM.YYYY")}
                </StyledDateStringP>
                <StyledDateStringP>
                  {moment(todo.start_date).format("HH:mm")}-
                  {moment(todo.end_date).format("HH:mm")}
                </StyledDateStringP>
              </>
            );
          }
        }
      }
    } else {
      setDateString("nie ustalono");
    }
  }, [todo.start_date, todo.end_date, todo]);

  const handleChangeComplete = async () => {
    if (todo.lead) {
      setIsModalOpen(true);
    } else if (todo.opportunity) {
      setIsOpportunityModalOpen(true);
    } else {
      const { status } = await request.patch(`api/todo/${todo.id}`, {
        done: !todo.done,
      });

      if (status === 201) {
        fetchData();
      }
    }
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOnCloseOpportunityModal = () => {
    setIsOpportunityModalOpen(false);
  };
  return (
    <StyledItem>
      <StyledInfoIconWrapper>
        <StyledCheckWrapper>
          {todo.done ? (
            <StyledCompleteSpan
              onClick={handleChangeComplete}
              title="oznacz jako wykonane"
            >
              {" "}
              <FontAwesomeIcon icon={faCheck} />
            </StyledCompleteSpan>
          ) : (
            <StyledUncompleteSpan
              onClick={handleChangeComplete}
              title="oznacz jako wykonane"
            ></StyledUncompleteSpan>
          )}
        </StyledCheckWrapper>
        <StyledFontAwesomeIcon
          icon={[todo.taskType?.prefix, todo.taskType?.icon]}
        />
      </StyledInfoIconWrapper>
      <StyledContentWrapper>
        <StyledInfoWrapper>
          <StyledNameWrapper>
            <StyledName title={todo.name}>{todo.name}</StyledName>
          </StyledNameWrapper>
          <StyledDate>{dateString}</StyledDate>
        </StyledInfoWrapper>
        <StyledLinkWrapper>
          {todo.opportunity?.name && (
            <Link
              title={todo.opportunity.name}
              to={`/dashboard/opportunities/details/${todo.opportunity.id}`}
            >
              {todo.opportunity.name}
            </Link>
          )}
          {(todo.lead?.name || todo.lead?.phone || todo.lead?.email) && (
            <Link
              title={todo.lead?.name || todo.lead?.phone || todo.lead?.email}
              to={`/dashboard/lead/details/${todo.lead.id}`}
            >
              {todo.lead?.name} {todo.lead?.phone}
              {todo.lead?.email}
            </Link>
          )}
        </StyledLinkWrapper>
      </StyledContentWrapper>
      <AddContactLeadNote
        todo={todo}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
      />
      <AddContactNote
        todo={todo}
        isModalOpen={isOpportunityModalOpen}
        handleOnClose={handleOnCloseOpportunityModal}
        fetchData={fetchData}
      />
    </StyledItem>
  );
};
const StyledDateStringP = styled.p`
  font-size: 14px;
`;

const StyledUncompleteSpan = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #134771;
  cursor: pointer;
`;
const StyledCompleteSpan = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #66bc46;
  background: #66bc46;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
`;

const StyledDate = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  width: 90px;
  font-size: 14px;
`;
const StyledContentWrapper = styled.div`
  width: 50%;
  flex: 1;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
`;

const StyledLinkWrapper = styled.div`
  margin: 0 2px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  a {
    font-size: 12px;
    margin-right: 5px;
    color: #2d96db;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  background: white;
  border-bottom: #dfdede 1px solid;
  width: 100%;
`;

const StyledInfoIconWrapper = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
`;
const StyledCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpan = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid lightblue;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 18px !important;
`;

const StyledNameWrapper = styled.div`
  width: 50%;
  flex: 1;
`;
const StyledName = styled.p`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export default TodoSidebarListItem;
