import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../store/StoreProvider";
import ChooseUserFilter from "./ChooseUserFilter/ChooseUserFilter";

const UserFilter = ({ setUserFilter, setDepartmentFilter, setTeamFilter }) => {
  const { departments, teams } = useContext(StoreContext);
  const [name, setName] = useState("Cała polska");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledCollapse isOpen={isOpen}>
      <StyledCollapseItemWrapper>
        <ChooseUserFilter
          departments={departments}
          teams={teams}
          name={name}
          setName={setName}
          setUserFilter={setUserFilter}
          setDepartmentFilter={setDepartmentFilter}
          setTeamFilter={setTeamFilter}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </StyledCollapseItemWrapper>
    </StyledCollapse>
  );
};

const StyledCollapseItemWrapper = styled.div`
  transition: max-height 0.5s ease;
  padding: 10px;
  position: relative;
`;
const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
`;

export default UserFilter;
