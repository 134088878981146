import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ChooseDepartmentItem = ({
  name,
  id,
  setDepartmentFilter,
  departmentFilter,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleOnClick = () => {
    if (id) {
      if (!departmentFilter.includes(id)) {
        setDepartmentFilter((prev) => [...prev, id]);
      } else {
        setDepartmentFilter(departmentFilter.filter((item) => item !== id));
      }
    } else {
      setDepartmentFilter([]);
    }
  };
  useEffect(() => {
    if (departmentFilter.includes(id)) {
      setIsActive(true);
    } else {
      if (id) {
        setIsActive(false);
      } else {
        if (departmentFilter.length > 0) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }
      }
    }
  }, [departmentFilter]);

  return (
    <StyledCollapseItemButton
      onClick={handleOnClick}
      className={isActive && "active"}
    >
      <p>{name}</p>
    </StyledCollapseItemButton>
  );
};

const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;

  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

export default ChooseDepartmentItem;
