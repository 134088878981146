import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../../components/Modal";
import request from "../../../../../../../helpers/request";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import ChooseUser from "./ChooseUser/ChooseUser";

const validationSchema = yup.object().shape(
  {
    first_name: yup
      .string()
      .typeError("Imię jest wymagane")
      .required("Imię jest wymagana")
      .min(3, "Imię  musi posiadać co najmniej 3 znaków")
      .max(255, "Imię  nie może być dłuższa niż 255 znaków"),
    last_name: yup
      .string()
      .typeError("Nazwisko jest wymagane")
      .required("Nazwisko jest wymagana")
      .min(3, "Nazwisko musi posiadać co najmniej 3 znaków")
      .max(255, "Nazwisko nie może być dłuższa niż 255 znaków"),

    phone: yup.string().when("email", {
      is: (email) => !email,
      then: yup
        .string()
        .matches(/^[0-9]+$/, {
          message: "Numer telefonu musi składać się wyłącznie z cyfr 1-9",
          excludeEmptyString: true,
        })
        .required("Wpisz email lub telefon")
        .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków"),
    }),
    email: yup
      .string()
      .email("proszę podać prawidłowy adres email")
      .when("phone", {
        is: (phone) => !phone,
        then: yup.string().required("Wpisz email lub telefon"),
        otherwise: yup.string().nullable(),
      }),
    city: yup.string().nullable(),
    postCode: yup.string().nullable(),
    street: yup.string().nullable(),
    building_number: yup.string().nullable(),
    voivodeship: yup.string().required("Proszę wybrać województow"),
    source_id: yup.string().required("Proszę wybrać źródło pozyskania"),
    country: yup.string().nullable(),
  },
  [["phone", "email"]]
);

const AddClients = ({ isModalOpen, handleOnClose, fetchData, lead }) => {
  const { departments, sources, teams, user, voivodeships, heatingTypes } =
    useContext(StoreContext);
  const [isSended, setIsSended] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      maintainer_id: 1,
      city: "",
      postCode: "",
      street: "",
      building_number: "",
      heating_type: "",
      voivodeship: "",
      country: "",
      description: "",
    });
  };
  const sendData = async (sendData) => {
    setIsSended(true);
    try {
      const { status, data } = await request.patch(
        `/api/lead/create-client/${lead.id}`,
        { ...sendData }
      );
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
        if (data.client) {
          history.push(
            `/dashboard/contacts/clients/details/${data.client?.id}`
          );
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    setValue("first_name", "");
    setValue("last_name", "");
    setValue("phone", "");
    setValue("email", "");
    setValue("maintainer_id", user.id);
    setValue("city", "");
    setValue("postCode", "");
    setValue("street", "");
    setValue("heating_type", "");
    setValue("building_number", "");
    setValue("voivodeship", "");
    setValue("country", "Polska");
    setValue("description", "");
    setValue("source_id", "");
  }, []);

  useEffect(() => {
    if (lead) {
      setValue("first_name", lead.name);
      setValue("phone", lead.phone);
      setValue("email", lead.email);
      setValue("voivodeship", lead.voivodeship?.id);
      setValue("source_id", lead.source?.id);
      setValue("description", lead.description);
    }
  }, [lead]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledInput>
          <div className="input-group">
            <label>Imię</label>
            <input
              type="text"
              {...register("first_name")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.first_name && (
          <span className="error">{errors.first_name.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Nazwisko</label>
            <input
              type="text"
              {...register("last_name")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.last_name && (
          <span className="error">{errors.last_name.message}</span>
        )}
        <ChooseUser
          departments={departments}
          teams={teams}
          setValue={setValue}
          setValueTitle="maintainer_id"
          user={user}
        />

        {errors?.maintainer_id && (
          <span className="error">{errors.maintainer_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Źródło pozyskania:</label>
            <select {...register("source_id")}>
              <option value="">Wybierz...</option>
              {sources.map((source) => {
                return (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.source_id && (
          <span className="error">{errors.source_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input type="number" {...register("phone")} />
          </div>
        </StyledInput>
        {errors?.phone && <span className="error">{errors.phone.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Adres e-mail</label>
            <input
              type="text"
              {...register("email")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.email && <span className="error">{errors.email.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input
              type="text"
              {...register("street")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.street && (
          <span className="error">{errors.street.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("building_number")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.building_number && (
          <span className="error">{errors.building_number.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input
              type="text"
              {...register("postCode")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.postCode && (
          <span className="error">{errors.postCode.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input
              type="text"
              {...register("city")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.city && <span className="error">{errors.city.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Województwo</label>
            <select {...register("voivodeship")}>
              <option value="">Wybierz...</option>
              {voivodeships &&
                voivodeships.map((voivodeship) => (
                  <option value={voivodeship.id} key={voivodeship.id}>
                    {voivodeship.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.voivodeship && (
          <span className="error">{errors.voivodeship.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Kraj</label>
            <input
              type="text"
              {...register("country")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.country && (
          <span className="error">{errors.country.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>System ogrzewania</label>
            <select {...register("heating_type")}>
              <option value="">Wybierz...</option>
              {heatingTypes &&
                heatingTypes.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.heating_type && (
          <span className="error">{errors.heating_type.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Opis</label>
            <input
              type="text"
              {...register("description")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.description && (
          <span className="error">{errors.country.message}</span>
        )}

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            Dodaj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 600px;

  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .todo-input-group {
      width: 49%;

      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddClients;
