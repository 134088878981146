import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  NavLink,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import styled from "styled-components";
import CreditCalculator from "./Components/CreditCalculator";
// import PVCalculatorAdvanced from "./Components/PVCalculatorAdvanced/PVCalculatorAdvanced";
import HeatPumpCalculator from "./Components/HeatPumpCalculator";
import YieldCalculator from "./Components/YieldCalculator/YieldCalculator";
import PowerCalculator from "./Components/PowerCalculator";
import { StoreContext } from "../../store/StoreProvider";
import PVCalculator from "./Components/PvCalculator/PVCalculator";

const QuickCalculation = () => {
  const { path } = useRouteMatch();
  const { user } = useContext(StoreContext);

  return (
    <StyledWrapper>
      <StyledContactsNav>
        <StyledTitleWrapper>
          <StyledTitle>Szybki kalkulator</StyledTitle>
        </StyledTitleWrapper>
        <StyledContactsNavList>
          <StyledContactsNavItem>
            <StyledNavLink
              to="/dashboard/quick-calculation/pv-calculator"
              activeClassName="active"
            >
              <FontAwesomeWrapper style={{ background: "rgb(19, 71, 113)" }}>
                <FontAwesomeIcon icon={faCalculator} />
              </FontAwesomeWrapper>
              Kalulator fotowoltaika
            </StyledNavLink>
          </StyledContactsNavItem>
          {/* {(user.role?.id === 1 || user.role?.id === 3) && (
            <StyledContactsNavItem>
              <StyledNavLink
                to="/dashboard/quick-calculation/pv-calculator-advanced"
                activeClassName="active"
              >
                <FontAwesomeWrapper style={{ background: "rgb(244, 118, 49)" }}>
                  <FontAwesomeIcon icon={faCalculator} />
                </FontAwesomeWrapper>
                Kalulator fotowoltaika - zaawansowany
              </StyledNavLink>
            </StyledContactsNavItem>
          )} */}
          <StyledContactsNavItem>
            <StyledNavLink
              exact
              to="/dashboard/quick-calculation/credit-calculator"
              activeClassName="active"
            >
              <FontAwesomeWrapper style={{ background: "rgb(102, 188, 70)" }}>
                <FontAwesomeIcon icon={faCalculator} />
              </FontAwesomeWrapper>
              Kalkulator kredytowy
            </StyledNavLink>
          </StyledContactsNavItem>
          <StyledContactsNavItem>
            <StyledNavLink
              exact
              to="/dashboard/quick-calculation/hp-calculator"
              activeClassName="active"
            >
              <FontAwesomeWrapper style={{ background: "rgb(19, 71, 113)" }}>
                <FontAwesomeIcon icon={faCalculator} />
              </FontAwesomeWrapper>
              Kalkulator pompy ciepła
            </StyledNavLink>
          </StyledContactsNavItem>
          <StyledContactsNavItem>
            <StyledNavLink
              to="/dashboard/quick-calculation/yield-calculator"
              activeClassName="active"
            >
              <FontAwesomeWrapper style={{ background: "rgb(244, 118, 49)" }}>
                <FontAwesomeIcon icon={faCalculator} />
              </FontAwesomeWrapper>
              Kalulator rentowności
            </StyledNavLink>
          </StyledContactsNavItem>
          <StyledContactsNavItem>
            <StyledNavLink
              exact
              to="/dashboard/quick-calculation/power-calculator"
              activeClassName="active"
            >
              <FontAwesomeWrapper style={{ background: "rgb(102, 188, 70)" }}>
                <FontAwesomeIcon icon={faCalculator} />
              </FontAwesomeWrapper>
              Kalkulator mocy
            </StyledNavLink>
          </StyledContactsNavItem>
        </StyledContactsNavList>
      </StyledContactsNav>
      <StyledContentWrapper>
        <Switch>
          <Route exact path={`${path}/`}>
            <Redirect to={`${path}/pv-calculator`} />
          </Route>{" "}
          <Route path={`${path}/pv-calculator`}>
            <PVCalculator />
          </Route>
          {/* {(user.role?.id === 1 || user.role?.id === 3) && (
            <Route path={`${path}/pv-calculator-advanced`}>
              <PVCalculatorAdvanced />
            </Route>
          )} */}
          <Route path={`${path}/credit-calculator`}>
            <CreditCalculator />
          </Route>
          <Route path={`${path}/hp-calculator`}>
            <HeatPumpCalculator />
          </Route>
          <Route path={`${path}/yield-calculator`}>
            <YieldCalculator />
          </Route>
          <Route path={`${path}/power-calculator`}>
            <PowerCalculator />
          </Route>
        </Switch>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FontAwesomeWrapper = styled.div`
  flex-basis: 35px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 5px;
  flex-shrink: 0;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  background: white;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  height: 100%;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

const StyledContactsNav = styled.nav`
  padding-bottom: 10px;
`;

const StyledContactsNavList = styled.ul`
  display: flex;
  align-items: stretch;
  flex: 1;
  flex-wrap: wrap;

  gap: 10px;
  margin-right: 10px;
`;

const StyledContactsNavItem = styled.li`
  list-style: none;
  flex: 200px 1;
`;

const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 1400px;
  margin: 0 auto;
`;

export default QuickCalculation;
