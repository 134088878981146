import React, { useState, useContext } from "react";
import styled from "styled-components";
import request from "../../helpers/request";
import { useHistory, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import { StoreContext } from "../../store/StoreProvider";

import { useForm } from "react-hook-form";

import { ReactComponent as Logo } from "../../img/logo.svg";
import requestMulti from "../../helpers/requestMulti";

const LoginForm = () => {
  const { setUser, setUncompleteSaleOpportunities } = useContext(StoreContext);
  const { register, handleSubmit } = useForm();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let location = useLocation();
  let history = useHistory();

  const handleOnSubmitLogin = async (req) => {
    await request.get("/sanctum/csrf-cookie");

    try {
      const { status, data } = await request.post("api/login", req);
      if (status === 200) {
        const cookie = new Cookies();
        cookie.set("access-token", data.token, { expired: 60400 });
        // document.cookie = `access-token=${data.token}`;
        setUser(data.user);
        setUncompleteSaleOpportunities(data.uncomplete_sale_opportunities);
        request.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        requestMulti.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        localStorage.setItem("isLogged", true);
        let { from } = location.state || {
          from: { pathname: "/dashboard/home" },
        };
        history.push(from);
      }
    } catch (e) {
      if (e.response?.status === 401) {
        setErrorMessage(
          <p className="error-message">E-mail lub hasło są niepoprawne</p>
        );
      }
    }
  };

  return (
    <StyledLoginForm>
      <Logo />
      <StyledHeaderName className="header-name">Zaloguj się</StyledHeaderName>
      <StyledForm onSubmit={handleSubmit(handleOnSubmitLogin)}>
        <StyledInput>
          <label htmlFor="email"> E-mail:</label>
          <input type="email" name="email" id="email" {...register("email")} />
        </StyledInput>
        <StyledInput>
          <label htmlFor="password"> Password:</label>
          <input
            type="password"
            name="password"
            id="password"
            {...register("password")}
          />
        </StyledInput>
        {errorMessage && errorMessage}

        <StyledCheckInput>
          <label htmlFor="rememberMe">
            <input
              type="checkbox"
              name="rememberMe"
              id="rememberMe"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />{" "}
            Zapamiętaj mnie{" "}
          </label>
        </StyledCheckInput>
        <StyledButtonWrapper>
          <StyledButton type="submit">Zaloguj się</StyledButton>
        </StyledButtonWrapper>
      </StyledForm>

      {/* Funkcja odzyskiwania hasło  */}
    </StyledLoginForm>
  );
};
const StyledInput = styled.div`
  position: relative;
  margin-top: 20px;
  label {
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 14px;
  }
  input {
    width: 100%;
    font-size: 18px;
    padding: 0.3em 0;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
  }
  input:focus {
    background-color: transparent;
    outline: none;
  }
  input:hover {
    outline: none;
    border: none;
    border-bottom: 1px solid #134771;
  }
`;

const StyledCheckInput = styled.div`
  margin: 10px 0;
`;

const StyledButtonWrapper = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  padding: 0.3em 1em;
  border-radius: 5px;
  font-size: 18px;
  border: none;
  background: #134771;
  color: white;
  cursor: pointer;
  &:hover {
    background: rgba(19, 71, 113, 0.8);
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  color: black;
  margin-top: 20px;
`;

const StyledHeaderName = styled.h4`
  font-size: 1.5rem;
  font-weight: 300;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledLoginForm = styled.div`
  width: 320px;
  background-color: white;

  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default LoginForm;
