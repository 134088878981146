import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AllCompanyDropdown from "./components/AllCompanyDropdown";

const ChooseUser = ({ departments, teams, user, userId, setUserId }) => {
  const [name, setName] = useState("");
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const handleOpenSelect = (e) => {
    e.preventDefault();
    setIsOpenSelect((prev) => !prev);
  };

  useEffect(() => {
    if (user.role?.id === 1 || user.role?.id === 2 || user.role?.id === 3) {
      setName("Wybierz użytkownika");
      setUserId(null);
    } else {
      setName(user?.name);
      setUserId(user?.id);
    }
  }, [user]);

  const handleOnChooseUser = (user) => {
    setName(user.name);
    setUserId(user.id);
    setIsOpenSelect(false);
  };

  const dropRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpenSelect(false);
    }
  };
  useEffect(() => {
    if (isOpenSelect) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });
  return (
    <StyledWrapper ref={dropRef}>
      <StyledLabel>Handlowiec</StyledLabel>
      <StyledSelectButton onClick={handleOpenSelect}>
        {name} <StyledAngleIcon icon={faChevronDown} />
      </StyledSelectButton>
      <StyledSelectWrapper
        style={isOpenSelect ? { display: "block" } : { display: "none" }}
      >
        <AllCompanyDropdown
          departments={departments}
          teams={teams}
          handleOnChooseUser={handleOnChooseUser}
        />
        <StyledUnselectUserButton>
          <FontAwesomeIcon icon={faTimes} />
        </StyledUnselectUserButton>
      </StyledSelectWrapper>
    </StyledWrapper>
  );
};

const StyledUnselectUserButton = styled.button`
  width: 100%;
  background: transparent;
`;

const StyledSelectWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 10px;
  padding: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  z-index: 1;
  min-width: 300px;
  max-width: 360px;
`;
const StyledAngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 3px;
  font-size: 10px;
`;

const StyledWrapper = styled.div`
  min-width: 300px;
  margin-top: 25px;
  position: relative;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;

const StyledSelectButton = styled.button`
  width: 100%;
  position: relative;
  text-align: left;
  background-color: white;
  border-radius: 2px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgb(118, 118, 118);
  box-shadow: none;
  outline: none;
  padding: 1px;
  color: black;
  cursor: pointer;
`;
export default ChooseUser;
