import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Header = ({ value, setValue }) => {
  const currentMonthName = () => {
    return value.format("MMMM");
  };

  const currentYear = () => {
    return value.format("YYYY");
  };

  const currentDay = () => {
    const startDay = value.clone().startOf("week");
    const endDay = value.clone().endOf("week");

    return `${startDay.format("D")} - ${endDay.format("D")}`;
  };

  const prevWeek = () => {
    return value.clone().subtract(1, "week");
  };
  const nextWeek = () => {
    return value.clone().add(1, "week");
  };

  return (
    <StyledCalendarHeader>
      <StyledHeaderNavigationDiv>
        <StyledLeftButton onClick={() => setValue(prevWeek())}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </StyledLeftButton>
        <StyledMonthName>
          {currentDay()} {currentMonthName()} {currentYear()}
        </StyledMonthName>
        <StyledRightButton onClick={() => setValue(nextWeek())}>
          <FontAwesomeIcon icon={faChevronRight} />
        </StyledRightButton>
      </StyledHeaderNavigationDiv>
    </StyledCalendarHeader>
  );
};

const StyledMonthName = styled.div`
  padding: 10px;
  background: white;
  border-radius: 10px;
`;
const StyledRightButton = styled.button`
  padding: 10px;

  border: none;
  outline: none;
  cursor: pointer;
  background: white;
  border-radius: 10px;
  margin-left: 1px;
`;
const StyledLeftButton = styled.button`
  padding: 10px;

  border: none;
  outline: none;
  cursor: pointer;
  background: white;
  border-radius: 10px;
  margin-right: 1px;
`;
const StyledHeaderNavigationDiv = styled.div`
  display: flex;
`;
const StyledCalendarHeader = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Header;
