import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import * as yup from "yup";
import Modal from "../../../../../../../components/Modal";
import request from "../../../../../../../helpers/request";
import { StoreContext } from "../../../../../../../store/StoreProvider";
import ToDoTag from "./ToDoTag";

const validationSchema = yup.object({
  tasktype_id: yup.string().required("Proszę wybrać rodzaj zadania"),
});
const AddSaleFunnelItem = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      tasktype_id: "",
      days: null,
      hour: null,
      some_day: false,
    });
  };

  const location = useLocation();
  const history = useHistory();

  const { tags, setTags } = useContext(StoreContext);

  const [tagsContent, setTagContent] = useState(false);
  const [taskType, setTaskType] = useState(null);
  const [isTagContainerOpen, setIsTagContainerOpen] = useState(false);
  const [isDateShow, setIsDateShow] = useState(false);
  const [isSended, setIsSended] = useState(false);

  const sendData = async (data) => {
    setIsSended(true);
    try {
      if (isEditMode) {
        await request.patch(`/api/salesfunnel/${editObject.id}`, { ...data });
      } else {
        await request.post("/api/salesfunnel", { data });
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
    fetchData();
    resetInputValue();
    handleOnClose();
  };

  const handleOpenTagContainer = (e) => {
    e.preventDefault();
    setIsTagContainerOpen((prev) => !prev);
  };

  const handleOnChangeDate = (e) => {
    if (e.target.value === "noDate") {
      setIsDateShow(false);
      setValue("days", null);
      setValue("hour", null);
      setValue("some_day", false);
    } else if (e.target.value === "today") {
      setIsDateShow(false);
      setValue("days", 0);
      setValue("hour", null);
      setValue("some_day", false);
    } else if (e.target.value === "tomorrow") {
      setIsDateShow(false);
      setValue("days", 1);
      setValue("hour", null);
      setValue("some_day", false);
    } else if (e.target.value === "someDay") {
      setIsDateShow(false);
      setValue("days", null);
      setValue("hour", null);
      setValue("some_day", true);
    } else if (e.target.value === "chooseDay") {
      setIsDateShow(true);
      setValue("days", 0);
      setValue("hour", 0);
      setValue("some_day", false);
    }
  };

  const fetchTag = async () => {
    if (tags === undefined || tags.length === 0) {
      try {
        const { data, status } = await request.get("/api/tasktype");
        if (status === 200) {
          setTags(data);
        }
      } catch (e) {
        if (e.response?.status === 401 || e.response?.status === 419) {
          history.push({
            pathname: "/login",
            state: { from: location },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (editObject) {
      setIsTagContainerOpen(true);
      setValue("tasktype_id", editObject.tasktype.id);
      setValue("position", editObject.position);
      setValue("some_day", editObject.some_day);
      if (editObject.date_name === "chooseDay") {
        setIsDateShow(true);
      } else {
        setIsDateShow(false);
      }
      setTaskType(editObject.tasktype.id);
      // setValue("color", editObject.color);
    } else {
      setIsTagContainerOpen(false);
      setValue("tasktype_id", null);
      setValue("position", null);
      setValue("some_day", "false");
      setTaskType(undefined);
    }
  }, [editObject]);

  useEffect(() => {
    if (editObject) {
      setTagContent(
        tags.map((tag, index) => (
          <li key={index}>
            <ToDoTag
              {...tag}
              setValue={setValue}
              tasktype_id={editObject.taskType?.id}
              taskType={taskType}
              setTaskType={setTaskType}
            />
          </li>
        ))
      );
    } else {
      setTagContent(
        tags.map((tag, index) => (
          <li key={index}>
            <ToDoTag
              {...tag}
              setValue={setValue}
              taskType={taskType}
              setTaskType={setTaskType}
            />
          </li>
        ))
      );
    }
  }, [tags, editObject, taskType]);

  useEffect(() => {
    if (tags.length === 0) {
      fetchTag();
    }
  }, []);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <input type="hidden" {...register("tasktype_id")} />
        <StyledTags>
          <StyledTagsList
            style={isTagContainerOpen ? { height: "auto" } : { height: "55px" }}
          >
            {tagsContent}
          </StyledTagsList>
          <StyledTagButtonWrapper>
            <StyledTagButton onClick={handleOpenTagContainer}>
              {isTagContainerOpen ? "Schowaj" : "Pokaż wszystkie"}
            </StyledTagButton>
          </StyledTagButtonWrapper>
          {errors?.tasktype_id && (
            <span className="error">{errors.tasktype_id.message}</span>
          )}
        </StyledTags>

        <StyledInput>
          <div className="input-group">
            <label>Kiedy:</label>
            <select
              id="dateTaken"
              defaultValue="noDate"
              onChange={handleOnChangeDate}
            >
              <option value="noDate">Termin nieustalony</option>
              <option value="today">Dzisiaj</option>
              <option value="tomorrow">Jutro</option>
              <option value="someDay">Kiedyś</option>
              <option value="chooseDay">Wybranego Dnia</option>
            </select>
          </div>
        </StyledInput>

        <StyledInput
          style={isDateShow ? { display: "block" } : { display: "none" }}
        >
          <div className="input-group">
            <label>Ilość dni:</label>
            <input type="number" {...register("days")} />
          </div>
        </StyledInput>
        <input type="hidden" {...register("some_day")} />
        <StyledInput
          style={isDateShow ? { display: "block" } : { display: "none" }}
        >
          <div className="input-group">
            <label>Ilość godzin:</label>
            <input type="number" {...register("hour")} />
          </div>
        </StyledInput>

        <StyledInput
          style={isEditMode ? { display: "block" } : { display: "none" }}
        >
          <div className="input-group">
            <label>Pozycja:</label>
            <input type="number" {...register("position")} />
          </div>
        </StyledInput>
        {isEditMode ? (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Zapisz
            </button>
          </div>
        ) : (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Dodaj
            </button>
          </div>
        )}
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledInput = styled.div`
  min-width: 100%;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

const StyledTagsList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const StyledTags = styled.div`
  margin-top: 20px;
`;

const StyledTagButtonWrapper = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-end;
`;

const StyledTagButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 0.7rem;
  cursor: pointer;
  color: #2d96db;
  &:hover {
    color: #a9dcfd;
  }
`;
const StyledForm = styled.form`
  max-width: 360px;
  .name {
    min-width: 300px;
    margin-top: 25px;
    display: flex;
    & .input-group {
      position: relative;
      flex: 1;
      label {
        position: absolute;
        top: -15px;
        left: 0;
        font-size: 14px;
      }
      input {
        border: none;
        border-bottom: 1px solid black;
        width: 100%;
        outline: none;
        font-size: 14px;
      }
    }
  }
  & .icon {
    margin-top: 25px;
    & .input-group {
      position: relative;
      flex: 1;
      label {
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 14px;
      }
      select {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #2d96db;
        background: transparent;
      }
      &.close:hover {
        color: black;
      }
      &.add {
        background: #134771;
        color: white;
      }
      &.add:hover {
        background: #005e9c;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

export default AddSaleFunnelItem;
