import React, { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";

import TaskTypeItem from "./TaskTypeItem/TaskTypeItem";

const TaskType = () => {
  const location = useLocation();
  const history = useHistory();

  const { taskTypeSetting, setTaskTypeSetting } = useContext(StoreContext);

  const fetchDataTaskType = async () => {
    try {
      const { status, data } = await request.get("/api/task-type-setting");
      if (status === 200) {
        await setTaskTypeSetting(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (taskTypeSetting.length === 0) {
      fetchDataTaskType();
    }
  }, []);

  return (
    <StyledTaskTypeWrapper>
      <StyledTaskTypeList>
        {taskTypeSetting.map((element) => {
          return (
            <TaskTypeItem
              element={element}
              key={element.id}
              fetchDataTaskType={fetchDataTaskType}
            />
          );
        })}
      </StyledTaskTypeList>
    </StyledTaskTypeWrapper>
  );
};

const StyledTaskTypeWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledTaskTypeList = styled.ul`
  padding: 40px 0;
`;

export default TaskType;
