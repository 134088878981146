import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import HoureComponent from "./Components/HoureComponent";
import TaskComponent from "./Components/TaskComponent";
import TodoItem from "./TodoItem";
import loading from "../../../../../../img/48x48.gif";
const CalendarDay = ({
  isDayOpen,
  value,
  todoList,
  handleOnClickEdit,
  fetchData,
  setStartDate,
  setEndDate,
  isLoading,
}) => {
  useEffect(() => {
    setStartDate(moment(value).startOf("days"));
    setEndDate(moment(value).endOf("days"));
  }, [value]);

  return (
    <StyledWrapper
      style={isDayOpen ? { display: "flex" } : { display: "none" }}
    >
      {isLoading ? (
        <StyledLoadingWrapper>
          <StyledLoadingImage src={loading} />
        </StyledLoadingWrapper>
      ) : (
        <>
          <StyledDayNameWrapper>
            <StyledDayNameP>{value.format("dddd")}</StyledDayNameP>
          </StyledDayNameWrapper>
          <StyledHoureWrapper>
            <StyledHoureItemWrapper>
              {new Array(24).fill("1").map((item, index) => (
                <HoureComponent index={index} key={`houre:${index}`} />
              ))}
            </StyledHoureItemWrapper>
            <StyledItemWrapper>
              {new Array(24).fill("1").map((item, index) => {
                return <StyledHoure key={`task:${index}`}></StyledHoure>;
              })}

              {todoList.length > 0 &&
                todoList.map((task) => (
                  <TaskComponent task={task} key={task.id} />
                ))}
            </StyledItemWrapper>
          </StyledHoureWrapper>
        </>
      )}
    </StyledWrapper>
  );
};

const StyledLoadingImage = styled.img``;

const StyledLoadingWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 220px);
`;

const StyledItemWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const StyledHoureItemWrapper = styled.div`
  width: 50px;
`;
const StyledHoure = styled.div`
  height: 40px;
  flex: 1;
  border-top: 1px solid rgba(188, 188, 188, 0.4);
  border-left: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledHoureWrapper = styled.div`
  display: flex;
`;

const StyledDayNameP = styled.p`
  text-transform: capitalize;
`;

const StyledDayNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const StyledWrapper = styled.div`
  margin: 0 10px;
  flex: 1;
  background: white;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
`;

export default CalendarDay;
