import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import pdfSvg from "../../../../../../../../../img/pdf-download-2617.svg";
import fileSvg from "../../../../../../../../../img/file.svg";

const AddFileItem = ({ file, handleOnDelete, index }) => {
  const [isImage, setIsImage] = useState(false);
  const [isFile, setIsFile] = useState(false);

  useEffect(() => {
    if (file) {
      if (file?.mime_type.split("/")[0] === "image") {
        setIsImage(true);
        setIsFile(false);
      } else {
        setIsImage(false);
        setIsFile(true);
      }
    }
  }, [file, file?.name, file?.mime_type]);

  return (
    <>
      {isImage && (
        <StyledImageWrapper>
          <StyledDeleteButton
            title="Usuś zdjęcie"
            onClick={(e) => handleOnDelete(e, index)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </StyledDeleteButton>
          <StyledImage src={file.url} alt={file.name} />
          <StyledNameWrapper>
            <StyledName title={file.name}>{file.name}</StyledName>
            <StyledSize>{file.size} kB</StyledSize>
          </StyledNameWrapper>
        </StyledImageWrapper>
      )}
      {isFile && (
        <StyledFileWrapper>
          <StyledDeleteButton
            title="Usuń plik"
            onClick={(e) => handleOnDelete(e, index)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </StyledDeleteButton>
          <StyledNameWrapper>
            {file.mime_type === "application/pdf" ? (
              <StyledFileImgWrapper>
                <StyledFileImg src={pdfSvg} />
              </StyledFileImgWrapper>
            ) : (
              <StyledFileImgWrapper>
                <StyledFileImg src={fileSvg} />
              </StyledFileImgWrapper>
            )}
            <StyledName title={file.name}>{file.name}</StyledName>
            <StyledSize>{file.size} kB</StyledSize>
          </StyledNameWrapper>
        </StyledFileWrapper>
      )}
    </>
  );
};

const StyledFileImg = styled.img`
  max-height: 80px;
`;

const StyledFileImgWrapper = styled.div``;

const StyledDeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: 2px solid #134771;
  color: #134771;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: white;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledSize = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const StyledName = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledNameWrapper = styled.div`
  font-size: 14px;
  text-align: center;
`;
const StyledFileWrapper = styled.div`
  padding: 10px;
  position: relative;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
`;

const StyledImageWrapper = styled.div`
  padding: 10px;
  position: relative;
`;

export default AddFileItem;
