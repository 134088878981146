import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { StoreContext } from "../../../../../store/StoreProvider";
import ChooseUserFilter from "./ChooseUserFilter/ChooseUserFilter";

const CollapseUser = ({ title, setUserFilter, userFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [height, setHeight] = useState(150);
  const [name, setName] = useState("Osoba");
  const [userId, setUserId] = useState(null);

  const { user, departments, teams } = useContext(StoreContext);

  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? 0 : content.current.scrollHeight);
    setIsPopOverOpen(false);
  };

  useEffect(() => {
    setUserFilter(userId);
  }, [userId]);

  const handleRestartFilter = () => {
    setUserFilter(null);
    setUserId(null);
    setName("Osoba");
  };

  const handleOpenPopOver = () => {
    setIsPopOverOpen((prev) => !prev);
  };

  useEffect(() => {
    setHeight(!isOpen ? 0 : content.current.scrollHeight);
  }, []);

  const handleOnChooseUser = (user) => {
    setName(user.name);
    setUserId(user.id);
    setIsPopOverOpen(false);
  };

  useEffect(() => {
    setName(user.name);
    setUserId(user.id);
  }, [user]);

  return (
    <StyledCollapse>
      <StyledCollapseButton onClick={handleOnClick}>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
        <StyledCollapseTitle>{title}</StyledCollapseTitle>
      </StyledCollapseButton>
      <StyledCollapseItemWrapper
        ref={content}
        style={{ maxHeight: `${height}px` }}
      >
        <StyledSelectButtonWrapper>
          <StyledSelectButton onClick={handleOpenPopOver}>
            {name} <StyledIcon icon={faChevronDown} />
          </StyledSelectButton>
        </StyledSelectButtonWrapper>

        <StyledCancelButton onClick={handleRestartFilter}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
      </StyledCollapseItemWrapper>
      <ChooseUserFilter
        departments={departments}
        teams={teams}
        name={name}
        setName={setName}
        setUserId={setUserId}
        isPopOverOpen={isPopOverOpen}
        setIsPopOverOpen={setIsPopOverOpen}
        handleOnChooseUser={handleOnChooseUser}
      />
    </StyledCollapse>
  );
};

const StyledCollapseItemWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const StyledCollapseTitle = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledCollapseButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  background: transparent;
  display: flex;
`;

const StyledSelectButtonWrapper = styled.div`
  padding: 10px;
`;
const StyledMain = styled.div;

const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 12px;
`;

const StyledSelectButton = styled.button`
  width: 100%;
  padding: 5px 10px;
  position: relative;
  text-align: left;
  background: white;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: black;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    color: gray;
  }
`;

const StyledCancelButton = styled.button`
  background: transparent;
  width: 100%;

  border: none;
  outline: none;
  padding: 5px;
  cursor: pointer;

  &:hover {
    color: gray;
  }
`;

const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
`;

export default CollapseUser;
