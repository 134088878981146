import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../components/Modal";
import request from "../../../helpers/request";
import { StoreContext } from "../../../store/StoreProvider";
import { useLocation, useHistory } from "react-router";
import ChooseUser from "../../../components/Form/ChooseUser/ChooseUser";
import SearchClient from "./Components/SearchClient";
import SearchCompanies from "./Components/SearchCompanies";
import OpportunityAlert from "./Components/Alerts/OpportunityAlert";

const validationSchema = yup.object().shape(
  {
    company_id: yup.string().nullable(),
    source_id: yup.string().nullable(),
    first_name: yup
      .string()
      .typeError("Imię jest wymagane")
      .required("Imię jest wymagana")
      .min(3, "Imię musi posiadać co najmniej 3 znaków")
      .max(255, "Imię  nie może być dłuższa niż 255 znaków"),
    last_name: yup
      .string()
      .typeError("Nazwisko jest wymagane")
      .required("Nazwisko jest wymagana")
      .min(3, "Nazwisko musi posiadać co najmniej 3 znaków")
      .max(255, "Nazwisko nie może być dłuższa niż 255 znaków"),
    phone: yup.string().when("email", {
      is: (email) => !email,
      then: yup
        .string()
        .matches(/^[0-9]+$/, {
          message: "Numer telefonu musi składać się wyłącznie z cyfr 1-9",
          excludeEmptyString: true,
        })
        .required("Wpisz email lub telefon")
        .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków"),
    }),
    email: yup
      .string()
      .email("proszę podać prawidłowy adres email")
      .when("phone", {
        is: (phone) => !phone,
        then: yup.string().required("Wpisz email lub telefon"),
        otherwise: yup.string().nullable(),
      }),
    NIP: yup
      .string()
      .matches(/^[0-9]+$/, "NIP musi składać się wyłącznie z cyfr 1-9")
      .min(10, "NIP musi posiadać co najmniej 10 znaków")
      .max(10, "NIP nie może być dłuższy niż 10 znaków")
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true),
    companyName: yup.string().nullable(),
  },
  [["phone", "email"]]
);

const AddHPOpportunities = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  isEditMode,
  editObject,
  setIsModalOpen,
}) => {
  const { departments, sources, teams, user } = useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();

  const [client, setClient] = useState(null);
  const [company, setCompany] = useState(null);
  const [chooseClient, setChooseClient] = useState(false);
  const [chooseCompany, setChooseCompany] = useState(false);
  const [isSended, setIsSended] = useState(false);

  //Alert states

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isClientHasOpportunities, setIsClientHasOpportunities] =
    useState(false);
  const [isCompanyHasOpportunities, setIsCompanyHasOpportunities] =
    useState(false);

  const [forceData, setForceData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    setClient(null);
    setChooseClient(false);
    setCompany(null);
    setChooseCompany(false);
    reset({
      maintainer_id: user?.id,
      client_id: "",
      name: "",
      phone: "",
      email: "",
      source_id: 0,
    });
  };
  const sendData = async (sendData) => {
    setIsSended(true);
    if (isEditMode) {
      try {
        const { status } = await request.patch(
          `/api/opportunities/${editObject.id}`,
          { ...sendData }
        );

        if (status === 201) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      } catch (e) {
        if (e.response?.status === 401 || e.response?.status === 419) {
          history.push({
            pathname: "/login",
            state: { from: location },
          });
        }
      }
    } else {
      try {
        const { status } = await request.post("/api/opportunities", {
          ...sendData,
        });

        if (status === 200) {
          fetchData();
          resetInputValue();
          handleOnClose();
        }
      } catch (e) {
        if (e.response?.status === 401 || e.response?.status === 419) {
          history.push({
            pathname: "/login",
            state: { from: location },
          });
        }

        if (e.response?.status === 409) {
          setIsModalOpen(false);
          setIsAlertOpen(true);
          setIsClientHasOpportunities(e.response?.data.client_count);
          setIsCompanyHasOpportunities(e.response?.data.company_count);
          setForceData(sendData);
        }
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  const sendForceData = async (sendData) => {
    try {
      const { status } = await request.post("/api/opportunities", {
        ...sendData,
        force_add: true,
      });

      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
        setIsAlertOpen(false);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSendDataForce = () => {
    sendForceData(forceData);
  };

  const handleOnChooseClient = (e) => {
    e.preventDefault();
    setChooseClient(true);
    setValue("chooseClient", true);
  };

  const handleOnAddClient = (e) => {
    e.preventDefault();
    setChooseClient(false);
    setClient(null);
    setValue("chooseClient", false);
    setValue("client_id", "");
    setValue("first_name", "");
    setValue("last_name", "");
    setValue("phone", "");
    setValue("email", "");
  };

  const handleOnChooseCompany = (e) => {
    e.preventDefault();
    setChooseCompany(true);
    setValue("chooseCompany", true);
  };

  const handleOnAddCompany = (e) => {
    e.preventDefault();
    setChooseCompany(false);
    setCompany("");
    setValue("chooseCompany", false);
    setValue("company_id", "");
    setValue("companyName", "");
    setValue("NIP", "");
  };

  useEffect(() => {
    if (editObject) {
      if (editObject?.client) {
        setValue("client_id", editObject.client.id);
        if (editObject.client.first_name && editObject.client.last_name) {
          setValue("first_name", `${editObject.client.first_name}`);
          setValue("last_name", `${editObject.client.last_name}`);
        } else {
          setValue("first_name", editObject.client.first_name);
          setValue("last_name", "");
        }
        setValue("phone", editObject.client.phone);
        setValue("email", editObject.client.email);
      } else if (client) {
        setValue("first_name", client.first_name);
        setValue("last_name", client.last_name);
        setValue("phone", client.phone);
        setValue("email", client.email);
      } else {
        setValue("client_id", null);
        setValue("first_name", "");
        setValue("last_name", "");
        setValue("phone", "");
        setValue("email", "");
      }

      if (editObject?.company) {
        setValue("company_id", editObject.company.id);
        setValue("companyName", editObject.company.name);
        setValue("NIP", editObject.company.NIP);
      } else if (company) {
        setValue("company_id", company.id);
        setValue("companyName", company.name);
        setValue("NIP", company.NIP);
      } else {
        setValue("company_id", "");
        setValue("companyName", "");
        setValue("NIP", "");
      }

      setValue("maintainer_id", editObject?.maintainer?.id);
      setValue("company_id", editObject?.company?.id);
      setValue("source_id", editObject?.source?.id || 0);
      setValue("chooseClient", false);
      setValue("chooseCompany", false);
    }

    if (client) {
      setValue("first_name", client.first_name);
      setValue("last_name", client.last_name);
      setValue("phone", client.phone);
      setValue("email", client.email);
    } else {
      setValue("client_id", "");
      setValue("first_name", "");
      setValue("last_name", "");
      setValue("phone", "");
      setValue("email", "");
    }

    if (company) {
      setValue("company_id", company.id);
      setValue("companyName", company.name);
      setValue("NIP", company.NIP);
    } else {
      setValue("company_id", "");
      setValue("companyName", "");
      setValue("NIP", "");
    }
  }, [
    editObject?.client,
    editObject?.company,
    editObject,
    client,
    client?.first_name,
    company,
    company?.name,
  ]);

  useEffect(() => {
    if (!isEditMode) {
      setValue("price", "");
      setValue("kWp", "");
      setValue("maintainer_id", user.id);
      setValue("source_id", 0);
      setValue("chooseClient", false);
      setValue("chooseCompany", false);
    }
  }, [isEditMode]);

  //Alert Functions

  const handleOnCloseAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAlertOpen(false);
  };

  const handleOnRedirectClient = () => {
    if (client) {
      history.push({
        pathname: `/dashboard/contacts/clients/details/${client.id}`,
        state: { from: location },
      });
    }
  };

  const handleOnRedirectCompany = () => {
    if (company) {
      history.push({
        pathname: `/dashboard/contacts/companies/details/${company.id}`,
        state: { from: location },
      });
    }
  };
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledTitleWrapper>
          <StyledTitle>Szansa sprzedaży pompy ciepła</StyledTitle>
        </StyledTitleWrapper>
        <StyledContactWrapper>
          <StyledContactPersonWrapper>
            <StyledContactPersonButtonWrapper
              style={isEditMode ? { display: "none" } : { display: "block" }}
            >
              <StyledContactPersonButton
                onClick={handleOnChooseClient}
                className={chooseClient ? "active" : ""}
              >
                Wybierz klienta
              </StyledContactPersonButton>
              <StyledContactPersonButton
                onClick={handleOnAddClient}
                className={chooseClient ? "" : "active"}
              >
                Dodaj klienta
              </StyledContactPersonButton>
            </StyledContactPersonButtonWrapper>
            <StyledContactPersonFormWrapper>
              <StyledContactPersonSelectWrapper
                style={
                  chooseClient ? { display: "block" } : { display: "none" }
                }
              >
                <StyledInput>
                  <div className="input-group">
                    <SearchClient
                      client={client}
                      setClient={setClient}
                      setValue={setValue}
                      chooseClient={chooseClient}
                    />

                    <input type="hidden" {...register("chooseClient")} />
                    <input type="hidden" {...register("client_id")} />
                  </div>
                </StyledInput>
              </StyledContactPersonSelectWrapper>
              <StyledContactPersonInputWrapper>
                <StyledInput>
                  <div className="input-group">
                    <label>Imię</label>
                    <input
                      type="text"
                      {...register("first_name")}
                      readOnly={chooseClient || isEditMode}
                      autoComplete="new-password"
                    />
                  </div>
                </StyledInput>
                {errors?.first_name && (
                  <span className="error">{errors.first_name.message}</span>
                )}
                <StyledInput>
                  <div className="input-group">
                    <label>Nazwisko</label>
                    <input
                      type="text"
                      {...register("last_name")}
                      readOnly={chooseClient || isEditMode}
                      autoComplete="new-password"
                    />
                  </div>
                </StyledInput>
                {errors?.last_name && (
                  <span className="error">{errors.last_name.message}</span>
                )}
                <StyledInput>
                  <div className="input-group">
                    <label>Telefon</label>
                    <input
                      type="number"
                      {...register("phone")}
                      readOnly={chooseClient || isEditMode}
                    />
                  </div>
                </StyledInput>
                {errors?.phone && (
                  <span className="error">{errors.phone.message}</span>
                )}

                <StyledInput>
                  <div className="input-group">
                    <label>Adres e-mail</label>
                    <input
                      type="text"
                      {...register("email")}
                      readOnly={chooseClient || isEditMode}
                      autoComplete="new-password"
                    />
                  </div>
                </StyledInput>
                {errors?.email && (
                  <span className="error">{errors.email.message}</span>
                )}
              </StyledContactPersonInputWrapper>
            </StyledContactPersonFormWrapper>
          </StyledContactPersonWrapper>
          <StyledCompanyWrapper>
            <StyledCompanyButtonWrapper
              style={isEditMode ? { display: "none" } : { display: "block" }}
            >
              <StyledCompanyButton
                onClick={handleOnChooseCompany}
                className={chooseCompany ? "active" : ""}
              >
                Wybierz firmę
              </StyledCompanyButton>
              <StyledCompanyButton
                onClick={handleOnAddCompany}
                className={chooseCompany ? "" : "active"}
              >
                Dodaj firmę
              </StyledCompanyButton>
            </StyledCompanyButtonWrapper>
            <StyledCompanyFormWrapper>
              <StyledCompanySelectWrapper
                style={
                  chooseCompany ? { display: "block" } : { display: "none" }
                }
              >
                <StyledInput>
                  <div className="input-group">
                    <label>Firma:</label>

                    <SearchCompanies
                      company={company}
                      setCompany={setCompany}
                      setValue={setValue}
                      chooseCompany={chooseCompany}
                    />

                    <input type="hidden" {...register("chooseCompany")} />
                    <input type="hidden" {...register("company_id")} />
                  </div>
                </StyledInput>
              </StyledCompanySelectWrapper>
              <StyledCompanyInputWrapper>
                <StyledInput>
                  <div className="input-group">
                    <label>Nazwa firmy</label>
                    <input
                      type="text"
                      {...register("companyName")}
                      readOnly={chooseCompany || isEditMode}
                      autoComplete="new-password"
                    />
                  </div>
                </StyledInput>
                {errors?.companyName && (
                  <span className="error">{errors.companyName.message}</span>
                )}
                <StyledInput>
                  <div className="input-group">
                    <label>NIP</label>
                    <input
                      type="text"
                      {...register("NIP")}
                      readOnly={chooseCompany || isEditMode}
                    />
                  </div>
                </StyledInput>
                {errors?.NIP && (
                  <span className="error">{errors.NIP.message}</span>
                )}
              </StyledCompanyInputWrapper>
            </StyledCompanyFormWrapper>
          </StyledCompanyWrapper>
        </StyledContactWrapper>

        <ChooseUser
          departments={departments}
          teams={teams}
          setValue={setValue}
          setValueTitle="maintainer_id"
          user={user}
          isEditMode={isEditMode}
          editUser={editObject?.maintainer}
        />
        {errors?.maintainer_id && (
          <span className="error">{errors.maintainer_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Źródło pozyskania:</label>
            <select {...register("source_id")} defaultValue={0}>
              <option value={0}>Wybierz...</option>
              {sources.map((source) => {
                return (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.source_id && (
          <span className="error">{errors.source_id.message}</span>
        )}

        <input type="hidden" {...register("is_heat_pump")} value={1} />
        <input type="hidden" {...register("is_photovoltaic")} value={0} />

        {isEditMode ? (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Zapisz
            </button>
          </div>
        ) : (
          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmitwithClose)}
              disabled={isSended}
            >
              Dodaj
            </button>
          </div>
        )}
      </StyledForm>
      <OpportunityAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleClickNext={handleOnSendDataForce}
        handleOnRedirectClient={handleOnRedirectClient}
        handleOnRedirectCompany={handleOnRedirectCompany}
        isClientHasOpportunities={isClientHasOpportunities}
        isCompanyHasOpportunities={isCompanyHasOpportunities}
      />
    </Modal>
  );
};

const StyledTitle = styled.p`
  color: #134771;
  font-size: 18px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledContactWrapper = styled.div`
  display: flex;
`;
const StyledContactPersonSelectWrapper = styled.div``;
const StyledContactPersonInputWrapper = styled.div``;
const StyledContactPersonFormWrapper = styled.div`
  padding-bottom: 30px;
`;
const StyledContactPersonButton = styled.button`
  width: 49%;
  background: transparent;
  color: #134771;
  border: none;
  padding: 7px 5px;
  border-radius: 5px;
  &.active {
    color: #cadeee;
    background: #134771;
  }
`;
const StyledContactPersonButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;
const StyledContactPersonWrapper = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
  flex: 1;
`;

const StyledCompanySelectWrapper = styled.div``;
const StyledCompanyInputWrapper = styled.div``;
const StyledCompanyFormWrapper = styled.div`
  padding-bottom: 30px;
`;
const StyledCompanyButton = styled.button`
  width: 49%;
  background: transparent;
  color: #134771;
  border: none;
  padding: 7px 5px;
  border-radius: 5px;
  &.active {
    color: #cadeee;
    background: #134771;
  }
`;
const StyledCompanyButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;
const StyledCompanyWrapper = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
  flex: 1;
`;

const StyledForm = styled.form`
  max-width: 640px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
        text-decoration: underline;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddHPOpportunities;
