import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const Modal = ({
  children,
  isModalOpen,
  shouldBeCloseOnOutsideClick,
  handleOnClose,
}) => {
  const modalRef = useRef(null);
  const previousActiveElement = useRef(null);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = isModalOpen
      ? "hidden"
      : "";
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "";
    };
  }, [isModalOpen]);

  useEffect(() => {
    const { current: modal } = modalRef;
    const handleCancel = (event) => {
      event.preventDefault();
      handleOnClose();
    };
    modal.addEventListener("cancel", handleCancel);
    return () => {
      modal.removeEventListener("cancel", handleCancel);
    };
  }, [handleOnClose]);

  const handleOutsideClick = ({ target }) => {
    const { current } = modalRef;

    if (shouldBeCloseOnOutsideClick && target === current) {
      handleOnClose();
    }
  };

  return ReactDOM.createPortal(
    <StyledModal
      ref={modalRef}
      onClick={handleOutsideClick}
      hidden={!isModalOpen}
    >
      <StyledContentWrapper>
        <StyledContent>{children}</StyledContent>
      </StyledContentWrapper>
    </StyledModal>,
    document.body
  );
};

const StyledContent = styled.div`
  position: relative;
  background: white;
  padding: 30px;
  margin: 10px 0;
  max-width: calc((100% - 6px) - 2em);
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    margin: 5px 0;
    padding: 20px;
    max-width: calc(100% - 15px);
  }
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  z-index: 1040;
  border: none;
  backdrop-filter: blur(2px);
  overflow: unset;
  overflow-x: auto;
`;

export default Modal;
