import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import AddCutPrice from "./AddCutPrice";
import CutPriceItem from "./CutPriceItem/CutPriceItem";

const CutPrice = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(false);
  const [cutPrices, setCutPrices] = useState([]);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(false);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleOpenEdit = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchCutPrices = async () => {
    try {
      const { status, data } = await request.get("/api/cut-price");
      if (status === 200) {
        setCutPrices(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (cutPrices === undefined || cutPrices.length === 0) {
      fetchCutPrices();
    }
  }, []);

  return (
    <StyledTaskTypeWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj cennik</StyledButton>
      </StyledButtonWrapper>

      <StyledOfferTable>
        <StyledOfferTableHead>
          <tr>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              m/zł
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              do 10kW
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              do 15kW
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              do 20 kW
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              do 30 kW
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              do 40 kW
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              do 50 kW
            </StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Akcje
            </StyledOfferTableHeadItem>
          </tr>
        </StyledOfferTableHead>
        <tbody>
          {cutPrices.map((cutPrice) => {
            return (
              <CutPriceItem
                handleOpenEdit={handleOpenEdit}
                fetchCutPrices={fetchCutPrices}
                setEditObject={setEditObject}
                cutPrice={cutPrice}
                key={cutPrice.id}
              />
            );
          })}
        </tbody>
      </StyledOfferTable>
      <AddCutPrice
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchCutPrices={fetchCutPrices}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledTaskTypeWrapper>
  );
};

const StyledTaskTypeWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledOfferTable = styled.table`
  width: 100%;
  padding: 40px 0;
`;

const StyledOfferTableHead = styled.thead`
  font-size: 18px;
`;

const StyledOfferTableHeadItem = styled.th`
  text-align: start;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default CutPrice;
