import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const ImageModal = ({ children, isModalOpen }) => {
  const modalRef = useRef(null);

  return ReactDOM.createPortal(
    <div>
      {isModalOpen && (
        <StyledModal ref={modalRef}>
          <StyledContent>{children}</StyledContent>
        </StyledModal>
      )}
    </div>,
    document.body
  );
};

const StyledContent = styled.div`
  position: relative;
  background: white;
  padding-top: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
`;
const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 1040;
  border: none;
  overflow: unset;
  overflow-x: auto;
`;

export default ImageModal;
