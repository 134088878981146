import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../../store/StoreProvider";
import LeadComplaintNote from "./LeadComplaintNote";

const LeadComplaint = ({ lead, fetchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(StoreContext);
  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOnOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <StyledWrapper>
      <StyledTitle> Zgłoszone reklamacje</StyledTitle>
      <StyledNoteWrapper>
        {lead?.complaint && (
          <StyledNoteItem key={lead?.complaint?.id}>
            <StyledNoteP>Notatka: {lead?.complaint?.complaint}</StyledNoteP>
            <StyledNoteP>
              Dodana przez: {lead?.complaint?.user?.name}
            </StyledNoteP>
            <StyledNoteP>
              Dnia:{" "}
              {moment(lead?.complaint?.created_at).format("DD.MM.YY HH:mm")}
            </StyledNoteP>
          </StyledNoteItem>
        )}
      </StyledNoteWrapper>
      {(user.role?.id === 1 || user.role?.id === 3) &&
        lead?.complaint === null && (
          <StyledButton onClick={handleOnOpenModal}>
            Zgłoszenie reklamacji
          </StyledButton>
        )}
      <LeadComplaintNote
        lead={lead}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
      />
    </StyledWrapper>
  );
};
const StyledTitle = styled.h2`
  font-size: 18px;
  color: #134771;
  margin-bottom: 5px;
`;
const StyledNoteP = styled.p``;

const StyledNoteItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StyledNoteWrapper = styled.div``;

const StyledButton = styled.button`
  padding: 0.8rem 1rem;
  width: 100%;
  border: none;
  background: #134771;
  color: #cadeee;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px;
`;
export default LeadComplaint;
