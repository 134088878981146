import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import DeleteAlert from "./DeleteAlert";

const TodoPopOver = ({
  todo,
  hover,
  left,
  setHover,
  handleOnDelete,
  handleOnClickEdit,
  setEditObject,
  handleChangeComplete,
  handleOnOpenAssigmentModal,
  hoverRef,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [dateString, setDateString] = useState("");

  const handleSetHover = () => {
    setTimeout(() => setHover(false), 500);
  };

  const handleOnEditTodo = (e) => {
    e.stopPropagation();
    setEditObject(todo);
    handleOnClickEdit();
  };

  useEffect(() => {
    if (todo.start_date && todo.end_date) {
      if (moment(todo.start_date).isSame(todo.end_date, "day")) {
        setDateString(
          `${moment(todo.start_date).format(
            "dddd DD MMMM YYYY HH:mm"
          )} - ${moment(todo.end_date).format("HH:mm")}`
        );
      } else {
        setDateString(
          `${moment(todo.start_date).format(
            "dddd DD MMMM YYYY HH:mm"
          )} - ${moment(todo.end_date).format("dddd DD MMMM YYYY HH:mm")}`
        );
      }
    }
  }, [todo.start_date, todo.end_date, todo]);

  const handleOnAlertOpen = (e) => {
    e.stopPropagation();
    setIsAlertOpen(true);
    setHover(false);
  };

  const handleOnAlertClose = (e) => {
    e.stopPropagation();
    setIsAlertOpen(false);
  };

  const handleOnDeleteItem = (e) => {
    e.stopPropagation();
    handleOnDelete();
    setIsAlertOpen(false);
  };

  return (
    <StyledPopOver
      ref={hoverRef}
      onMouseLeave={handleSetHover}
      style={
        hover ? { display: "block", left: `${left}px` } : { display: "none" }
      }
    >
      <StyledInfoWrapper>
        <StyledNameWrapper>
          <StyledSetDoneButton
            onClick={handleChangeComplete}
            style={
              todo.done === 1
                ? { background: "green" }
                : { background: "transparent" }
            }
          ></StyledSetDoneButton>
          {todo.name}
        </StyledNameWrapper>
        <StyledDataWrapper>
          <StyledDate>{dateString}</StyledDate>
        </StyledDataWrapper>
        <StyledResponsibleWrapper>
          Dla:{" "}
          <StyledResponsibleLink>{todo.responsible.name}</StyledResponsibleLink>
        </StyledResponsibleWrapper>
        {todo.description && (
          <StyledDescriptionWrapper>
            <StyledDescriptionP>Opis: {todo.description}</StyledDescriptionP>
          </StyledDescriptionWrapper>
        )}
        {(todo.opportunity || todo.lead) && (
          <StyledContactWrapper>
            <StyledContactTitle>Dotyczy:</StyledContactTitle>
            {todo.opportunity && (
              <StyledOpportunityLink
                to={`/dashboard/opportunities/details/${todo.opportunity.id}`}
              >
                {todo.opportunity.name}
              </StyledOpportunityLink>
            )}
            {todo.lead && (
              <StyledOpportunityLink
                to={`/dashboard/lead/details${todo.lead.id}`}
              >
                {todo.lead?.name} {todo.lead?.phone} {todo.lead?.email}
              </StyledOpportunityLink>
            )}
          </StyledContactWrapper>
        )}
      </StyledInfoWrapper>
      <StyledPopOverBottom>
        <StyledEditButton onClick={handleOnEditTodo}>Edytuj</StyledEditButton>
        <StyledEditButton onClick={handleOnOpenAssigmentModal}>
          Edytuj przypisanie
        </StyledEditButton>
        <StyledDeleteButton onClick={handleOnAlertOpen}>
          Usuń
        </StyledDeleteButton>
      </StyledPopOverBottom>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnAlertClose}
        handleOnDelete={handleOnDeleteItem}
      />
    </StyledPopOver>
  );
};

const StyledDeleteButton = styled.button`
  color: #2d96db;
  text-decoration: none;
  border: none;
  background: transparent;
  outline: none;
  padding: 2px 5px;
  cursor: pointer;

  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;

const StyledEditButton = styled.button`
  color: #2d96db;
  text-decoration: none;
  border: none;
  background: transparent;
  outline: none;
  padding: 2px 5px;
  cursor: pointer;
  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;

const StyledInfoWrapper = styled.div`
  padding: 10px;
`;
const StyledPopOverBottom = styled.div`
  padding: 10px;
  border-radius: 0 0 10px 10px;
  background: #ebebeb;
  border-top: 1px solid gray;
`;

const StyledOpportunityLink = styled(Link)`
  color: #2d96db;
  text-decoration: none;
  display: block;

  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;
const StyledCompanyLink = styled(Link)`
  color: #2d96db;
  text-decoration: none;
  display: block;
  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;
const StyledClientLink = styled(Link)`
  color: #2d96db;
  text-decoration: none;
  display: block;
  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;

const StyledContactTitle = styled.p``;

const StyledContactWrapper = styled.div`
  margin-top: 20px;
`;

const StyledDescriptionP = styled.p``;

const StyledDescriptionWrapper = styled.div``;

const StyledResponsibleLink = styled.span`
  color: #2d96db;
  text-decoration: none;

  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;

const StyledResponsibleWrapper = styled.div``;

const StyledDate = styled.div``;

const StyledDataWrapper = styled.div``;

const StyledSetDoneButton = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid lightgreen;
  background: none;
  margin-right: 10px;
  cursor: pointer;
`;

const StyledNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledPopOver = styled.div`
  position: absolute;
  left: -20px;
  z-index: 9999;
  background: white;
  min-width: 360px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`;
export default TodoPopOver;
