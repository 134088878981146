import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ToDoDropdownOptions = ({
  isOpen,
  setIsOpenOptions,
  handleOnOpenAlert,
  handleOnClick,
  handleOnOpenAssigment,
  user,
  todoUser,
}) => {
  const dropRef = useRef(null);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isDirector, setIsDirector] = useState(false);
  const [isMaintainer, setIsMaintainer] = useState(false);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpenOptions(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  useEffect(() => {
    setIsAdmin(user.role?.id === 1);
    setIsDirector(
      user?.role?.id === 2 && user?.department?.id === todoUser?.department?.id
    );
    setIsMaintainer(user?.id === todoUser?.id);
  }, [todoUser, user]);

  return (
    <Dropdown ref={dropRef} className={isOpen ? " active" : ""}>
      <ul>
        {/* <li>
          {" "}
          <a href="#">Przejdź do profilu</a>{" "}
        </li>
        <li>
          <a href="#">Pokaż w kalendarzu</a>
        </li> */}
        {(isAdmin || isDirector || isMaintainer) && (
          <>
            <li>
              <button onClick={handleOnOpenAssigment}>
                Edytuj przypisanie
              </button>
            </li>
            <li>
              <button onClick={handleOnClick}>Edytuj</button>
            </li>
            <li>
              <button onClick={handleOnOpenAlert}>Usuń</button>
            </li>
          </>
        )}
      </ul>
    </Dropdown>
  );
};

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 15px;
  z-index: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  display: none;
  min-width: 160px;

  ul {
    list-style: none;
  }
  a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px 5px;
  }
  button {
    display: block;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    text-decoration: none;
    color: black;
    padding: 5px 5px;
    cursor: pointer;
  }
  &.active {
    display: block;
  }
`;

export default ToDoDropdownOptions;
