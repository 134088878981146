import React from "react";
import styled from "styled-components";

const ShowImage = ({ imageFile, handleOnClickImage }) => {
  return (
    <>
      {imageFile &&
        imageFile.map((file, index) => {
          return (
            <StyledImageContainer key={file.id}>
              <StyledImage
                src={`${file.path_name}`}
                alt={file.name}
                title={file.name}
                onClick={() => {
                  handleOnClickImage(index, imageFile);
                }}
              />
            </StyledImageContainer>
          );
        })}
    </>
  );
};

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const StyledImageContainer = styled.div`
  margin-top: 10px;
  padding: 5px;
  width: 25%;
  aspect-ratio: 1;

  @media screen and (max-width: 1000px) {
    width: 33%;
  }
`;
export default ShowImage;
