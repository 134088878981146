import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SearchAside from "./Components/SearchAside/SearchAside";
import SearchMainContent from "./Components/SearchMainContent/SearchMainContent";

const SearchFullSide = () => {
  const [showClients, setShowClients] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showOpportunities, setShowOpportunities] = useState(false);
  const [showLeads, setShowLeads] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [searching, setSearching] = useState(false);
  const [searchClients, setSearchClients] = useState([]);
  const [searchCompanies, setSearchCompanies] = useState([]);
  const [searchOpportunities, setSearchOpportunities] = useState([]);
  const [searchLeads, setSearchLeads] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [opportunitiesCount, setOpportunitiesCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const query = useQuery();
  const text = query.get("text");

  return (
    <StyledSearchWrapper>
      <SearchAside
        showClients={showClients}
        showCompanies={showCompanies}
        showOpportunities={showOpportunities}
        showLeads={showLeads}
        setShowClients={setShowClients}
        setShowCompanies={setShowCompanies}
        setShowOpportunities={setShowOpportunities}
        setShowLeads={setShowLeads}
      />
      <SearchMainContent
        setSearchClients={setSearchClients}
        setSearching={setSearching}
        setSearchCompanies={setSearchCompanies}
        setSearchOpportunities={setSearchOpportunities}
        setSearchLeads={setSearchLeads}
        setClientsCount={setClientsCount}
        setCompaniesCount={setCompaniesCount}
        setOpportunitiesCount={setOpportunitiesCount}
        setLeadsCount={setLeadsCount}
        searchClients={searchClients}
        clientsCount={clientsCount}
        searchCompanies={searchCompanies}
        searchLeads={searchLeads}
        leadsCount={leadsCount}
        companiesCount={companiesCount}
        searchOpportunities={searchOpportunities}
        opportunitiesCount={opportunitiesCount}
        showClients={showClients}
        showLeads={showLeads}
        showCompanies={showCompanies}
        showOpportunities={showOpportunities}
        setShowClients={setShowClients}
        setShowCompanies={setShowCompanies}
        setShowOpportunities={setShowOpportunities}
        setShowLeads={setShowLeads}
        text={text}
      />
    </StyledSearchWrapper>
  );
};

const StyledSearchWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  min-width: 0;
`;

export default SearchFullSide;
