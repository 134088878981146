import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import request from "../../../../../helpers/request";
import { StoreContext } from "../../../../../store/StoreProvider";
import { ToDoContext } from "../../../../../store/ToDoProvider";
import CollapseTypeItem from "./CollapseTypItem";

const CollapseTyp = ({ title, setTaskTypeFilter, taskTypeFilter }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState(true);
  const { taskType, setTaskType } = useContext(ToDoContext);
  const { user } = useContext(StoreContext);

  const content = useRef(null);
  const handleOnClick = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
  };

  const fetchData = async () => {
    const { data, status } = await request.get("/api/tasktype");
    if (status === 200) {
      setTaskType(data);
    }
  };

  useEffect(() => {
    if (taskType === undefined || taskType.length === 0) {
      fetchData();
    }
  }, []);
  return (
    <StyledCollapse>
      <StyledCollapseButton onClick={handleOnClick}>
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
        <StyledCollapseTitle>{title}</StyledCollapseTitle>
      </StyledCollapseButton>
      <StyledCollapseItemWrapper
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        {user.role?.id === 4 ? (
          <>
            {taskType
              .filter((tag) => Boolean(tag.trader))
              .map((element) => {
                return (
                  <CollapseTypeItem
                    taskTypeFilter={taskTypeFilter}
                    setTaskTypeFilter={setTaskTypeFilter}
                    key={element.id}
                    id={element.id}
                    name={element.name}
                    prefix={element.prefix}
                    icon={element.icon}
                  />
                );
              })}
          </>
        ) : (
          <>
            {taskType.map((element) => {
              return (
                <CollapseTypeItem
                  taskTypeFilter={taskTypeFilter}
                  setTaskTypeFilter={setTaskTypeFilter}
                  key={element.id}
                  id={element.id}
                  name={element.name}
                  prefix={element.prefix}
                  icon={element.icon}
                />
              );
            })}
          </>
        )}
      </StyledCollapseItemWrapper>
    </StyledCollapse>
  );
};

const StyledCollapseItemWrapper = styled.div`
  overflow: hidden;

  transition: max-height 0.5s ease;
`;

const StyledCollapseTitle = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledCollapseButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  background: white;
  display: flex;
`;

const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

export default CollapseTyp;
