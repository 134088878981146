import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import buildCalendar from "./Components/buildCalendar";
import YearMonthComponent from "./Components/YearMonthComponent";
import loading from "../../../../../../img/48x48.gif";
const CalendarYear = ({
  isYearOpen,
  value,
  todoList,
  handleOnDayOpen,
  setValue,
  setStartDate,
  setEndDate,
  isLoading,
}) => {
  const [calendar, setCalendar] = useState([]);
  useEffect(() => {
    setCalendar(buildCalendar(value));
    setStartDate(moment(value).startOf("year"));
    setEndDate(moment(value).endOf("year"));
  }, [value]);

  return (
    <StyledWrapper
      style={isYearOpen ? { display: "flex" } : { display: "none" }}
    >
      {isLoading ? (
        <StyledLoadingWrapper>
          <StyledLoadingImage src={loading} alt="loading" />
        </StyledLoadingWrapper>
      ) : (
        <StyledMonthsWrapper>
          {calendar.length > 0 &&
            calendar.map((month) => (
              <YearMonthComponent
                key={month}
                month={month}
                todoList={todoList}
                handleOnDayOpen={handleOnDayOpen}
                setValue={setValue}
              />
            ))}
        </StyledMonthsWrapper>
      )}
    </StyledWrapper>
  );
};

const StyledLoadingImage = styled.img``;

const StyledLoadingWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 220px);
`;

const StyledMonthsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledWrapper = styled.div`
  width: 100%;
  margin: 0 10px;
  flex: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;
`;

export default CalendarYear;
