import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChartItem from "./Components/ChartItem";
import loading from "../../../../../img/48x48.gif";

const Charts = ({ departmentFilter, statistic, statusFilter, isLoading }) => {
  const [labelsChart, setLabelsChart] = useState([]);
  const [dataChartTotalkWp, setDataChartTotalkWp] = useState([]);
  const [dataChartTotalPrice, setDataChartTotalPrice] = useState([]);
  const [dataChartTotalOpportunities, setDataChartTotalOpportunities] =
    useState([]);

  useEffect(() => {
    if (departmentFilter) {
      setLabelsChart(
        statistic
          .filter((user) => user.opportunities.length > 0)
          .map((user) => {
            return user.name;
          })
      );

      setDataChartTotalkWp(
        statistic
          .filter((user) => user.opportunities.length > 0)
          .map((user) => {
            return user.opportunities_kWp;
          })
      );

      setDataChartTotalPrice(
        statistic
          .filter((user) => user.opportunities.length > 0)
          .map((user) => {
            return user.opportunities_price;
          })
      );
      setDataChartTotalOpportunities(
        statistic
          .filter((user) => user.opportunities.length > 0)
          .map((user) => {
            return user.opportunities_count;
          })
      );
      // statistic.map((user) => {
      //   const opportunities = user.opportunities.reduce(
      //     (value, opportunity) => {
      //       return (value += Number(opportunity.price));
      //     },
      //     0
      //   );
      //   return price.toFixed(2);
      // })
    } else {
      const departmentObj = {};
      statistic
        .filter((user) => user.opportunities.length > 0)
        .forEach((user) => {
          if (user.department) {
            if (departmentObj.hasOwnProperty(user.department.name)) {
              const departmentName = user.department.name;
              departmentObj[departmentName].push(user);
            } else {
              const departmentName = user.department.name;
              departmentObj[departmentName] = [user];
            }
          }
          if (user.role?.id === 1) {
            if (departmentObj.hasOwnProperty("Zarząd")) {
              departmentObj["Zarząd"].push(user);
            } else {
              departmentObj["Zarząd"] = [user];
            }
          }
          if (user.role?.id === 3) {
            if (departmentObj.hasOwnProperty("Biuro")) {
              departmentObj["Biuro"].push(user);
            } else {
              departmentObj["Biuro"] = [user];
            }
          }
        });

      setLabelsChart(Object.keys(departmentObj));
      const kwp = Object.entries(departmentObj).map((department) => {
        const departmentkWp = department[1].reduce((kWp, user) => {
          return kWp + Number(user.opportunities_kWp);
        }, 0);
        return departmentkWp;
      });
      const price = Object.entries(departmentObj).map((department) => {
        const departmentPrice = department[1].reduce((price, user) => {
          return price + Number(user.opportunities_price);
        }, 0);
        return departmentPrice;
      });
      const opportunities = Object.entries(departmentObj).map((department) => {
        const opportunities_count = department[1].reduce((count, user) => {
          return count + Number(user.opportunities_count);
        }, 0);
        return opportunities_count;
      });

      setDataChartTotalkWp(kwp);
      setDataChartTotalPrice(price);
      setDataChartTotalOpportunities(opportunities);
    }
  }, [statistic]);

  return (
    <StyledWrapper>
      <StyledTitleWrapper>Wykresy</StyledTitleWrapper>
      {isLoading ? (
        <StyledLoadingWrapper>
          <StyledLoadingImg src={loading} alt="Loading..." />
        </StyledLoadingWrapper>
      ) : (
        <StyledChartWrapper>
          {statusFilter === 2 && (
            <>
              <ChartItem
                labelsChart={labelsChart}
                data={dataChartTotalkWp}
                title="kWp"
              />
              <ChartItem
                labelsChart={labelsChart}
                data={dataChartTotalPrice}
                title="Cena"
              />
            </>
          )}
          <ChartItem
            labelsChart={labelsChart}
            data={dataChartTotalOpportunities}
            title="Szans sprzedaży"
          />
        </StyledChartWrapper>
      )}
    </StyledWrapper>
  );
};

const StyledLoadingWrapper = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoadingImg = styled.img``;

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;

const StyledChartWrapper = styled.div`
  min-width: 0;
  padding: 10px;
  flex: 1;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export default Charts;
