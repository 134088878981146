import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useRef } from "react";

const OpportunityItem = ({
  opportunity,
  handleOnDragStart,
  handleOnTouchEnd,
}) => {
  const [isDrag, setIsDrag] = useState(false);

  const dragItem = useRef();

  const handleOnDragItemStart = (e) => {
    const duration = 0;
    handleOnDragStart(opportunity, duration, e);
    dragItem.current = e.target;
    dragItem.current.addEventListener("dragend", handleOnDragEnd);
    setTimeout(() => {
      setIsDrag(true);
    }, 0);
  };

  const handleOnTouchFinger = (e) => {
    const duration = 0;
    handleOnDragStart(opportunity, duration, e);
    dragItem.current = e.target;
    document.body.style.overflow = "hidden";
    dragItem.current.addEventListener("touchend", handleOnTouchFingerEnd);
    setTimeout(() => {
      setIsDrag(true);
    }, 0);
  };

  const handleOnTouchFingerEnd = (e) => {
    setIsDrag(false);
    document.body.style.overflow = "auto";
    dragItem.current.removeEventListener("touchend", handleOnTouchFingerEnd);
    dragItem.current = null;
  };

  const handleOnDragEnd = () => {
    setIsDrag(false);

    dragItem.current.removeEventListener("dragend", handleOnDragEnd);
    dragItem.current = null;
  };

  return (
    <StyledOpportunityWrapper
      onDragStart={(e) => handleOnDragItemStart(e)}
      onTouchStart={handleOnTouchFinger}
      onTouchEnd={handleOnTouchEnd}
      className={isDrag ? "drag" : ""}
      draggable
    >
      <StyledOpportunityName>{opportunity.name}</StyledOpportunityName>
      {opportunity.maintainer?.department ? (
        <StyledOpportunityDepartment>
          {opportunity.maintainer?.department.name}
        </StyledOpportunityDepartment>
      ) : (
        <StyledOpportunityDepartment>
          {opportunity.maintainer?.role?.id === 1 &&
            `Zarząd - ${opportunity.maintainer?.name}`}
          {opportunity.maintainer?.role?.id === 3 &&
            `Biuro - ${opportunity.maintainer?.name}`}
        </StyledOpportunityDepartment>
      )}
      <StyledOpportunityFinalDate>
        {opportunity.assembly_final_date &&
          moment(opportunity.assembly_final_date).format("DD.MM.YY")}
      </StyledOpportunityFinalDate>
    </StyledOpportunityWrapper>
  );
};

const StyledOpportunityFinalDate = styled.div`
  flex-basis: 100px;
`;

const StyledOpportunityDepartment = styled.div`
  flex-basis: 200px;
`;

const StyledOpportunityName = styled.div`
  flex: 1;
`;

const StyledOpportunityWrapper = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  background: white;
  cursor: pointer;
  &.drag {
    color: lightgray;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

export default OpportunityItem;
