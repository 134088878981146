import React, { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import TodoItem from "./TodoItem";

const PopOver = ({ isOpen, todoItems, setIsOpen }) => {
  const dropRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mouseover", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("mouseover", handleOutsideClick, true);
    };
  });
  return (
    <StyledWrapper
      ref={dropRef}
      style={isOpen ? { display: "block" } : { display: "none" }}
    >
      {todoItems.map((todo) => (
        <TodoItem todo={todo} key={todo.id} />
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: -20px;
  width: 240px;
  height: auto;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background: white;
`;
export default PopOver;
