import React, { useState, useContext, useEffect } from "react";
import request from "../../../helpers/request";
import { StoreContext } from "../../../store/StoreProvider";

import styled from "styled-components";
import ClientsAside from "./ClientsAside/ClientsAside";
import ClientsList from "./ClientsList/ClientsList";
import AddClients from "./AddClients/AddClients";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faPlus, faUsers } from "@fortawesome/free-solid-svg-icons";

const Clients = () => {
  const {
    setDepartments,
    departments,
    sources,
    teams,
    setTeams,
    voivodeships,
    setVoivodeships,
    user,
  } = useContext(StoreContext);
  const location = useLocation();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const [showFilter, setShowFilter] = useState(null);
  const [departmentFilter, setDepartmentFilter] = useState(null);
  const [clients, setClients] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setEditMode(false);
  };
  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleOnClickEdit = () => {
    setEditMode(true);
    setIsModalOpen(true);
  };

  const fetchClients = async (number = 1) => {
    setIsLoading(true);
    try {
      const { status, data } = await request.get(
        `/api/clients?page=${number}`,
        {
          params: {
            department: departmentFilter,
          },
        }
      );
      if (status === 200) {
        setClients(data.data);
        setPageCount(data.meta.last_page);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsLoading(false);
  };

  const fetchDataDepartments = async () => {
    try {
      const { status, data } = await request.get("/api/department");
      if (status === 200) {
        await setDepartments(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (departments === undefined || departments.length === 0) {
      fetchDataDepartments();
    }
    if (teams === undefined || teams.length === 0) {
      fetchDataTeams();
    }
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
  }, []);
  return (
    <StyledWrapper>
      <StyledContactsNav>
        <StyledTitleWrapper>
          <StyledTitle>Kontakty</StyledTitle>
          <StyledButtonWrapper>
            <StyledButton onClick={handleOnClick} title="Dodaj klienta">
              <FontAwesomeIcon icon={faPlus} />{" "}
            </StyledButton>
          </StyledButtonWrapper>
        </StyledTitleWrapper>
        <StyledContactsNavList>
          <StyledContactsNavItem>
            <StyledNavLink
              to="/dashboard/contacts/clients"
              activeClassName="active"
            >
              <FontAwesomeWrapper style={{ background: "rgb(244, 118, 49)" }}>
                <FontAwesomeIcon icon={faUsers} />
              </FontAwesomeWrapper>
              Osoby
            </StyledNavLink>
          </StyledContactsNavItem>
          <StyledContactsNavItem>
            <StyledNavLink
              exact
              to="/dashboard/contacts/companies"
              activeClassName="active"
            >
              <FontAwesomeWrapper style={{ background: "rgb(102, 188, 70)" }}>
                <FontAwesomeIcon icon={faBuilding} />
              </FontAwesomeWrapper>
              Firmy
            </StyledNavLink>
          </StyledContactsNavItem>
        </StyledContactsNavList>
      </StyledContactsNav>

      <StyledClientWrapper>
        {(user?.role?.id === 1 || user?.role?.id === 3) && (
          <ClientsAside
            departments={departments}
            setShowFilter={setShowFilter}
            setDepartmentFilter={setDepartmentFilter}
            departmentFilter={departmentFilter}
          />
        )}

        <ClientsList
          departmentFilter={departmentFilter}
          handleOnClickEdit={handleOnClickEdit}
          setEditObject={setEditObject}
          fetchClients={fetchClients}
          isLoading={isLoading}
          setPageNumber={setPageNumber}
          clients={clients}
          pageNumber={pageNumber}
          pageCount={pageCount}
        />
      </StyledClientWrapper>
      <AddClients
        fetchData={fetchClients}
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledWrapper>
  );
};

const FontAwesomeWrapper = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 5px;
`;

const StyledButton = styled.button`
  border: none;
  padding: 0.8rem 1rem;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  background: transparent;
  color: black;
  font-size: 28px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  &:hover {
    color: rgb(244, 118, 49);
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const StyledClientWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    margin: 0 10px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  font-size: 16px;
  background: white;
  margin-left: 10px;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

const StyledContactsNav = styled.nav`
  padding-bottom: 10px;
`;

const StyledContactsNavList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: 10px;
`;

const StyledContactsNavItem = styled.li`
  list-style: none;
  flex-basis: 50%;
`;
const StyledWrapper = styled.div`
  padding: 0 10px 10px;
  max-width: 1400px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export default Clients;
