import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../../../../../components/Modal";

const ClientPVOpportunityAlert = ({
  isAlertOpen,
  handleOnClose,
  handleOnRedirect,
  handleClickYes,
}) => {
  return (
    <Modal
      isModalOpen={isAlertOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledContentWrapper>
        Do klienta przypisana jest już szansa sprzedaży, czy chcesz stworzyć
        kolejną ?
      </StyledContentWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleClickYes}>Tak</StyledButton>
        <StyledButton onClick={handleOnRedirect}>
          Przenieś do szansy sprzedaży
        </StyledButton>
      </StyledButtonWrapper>
    </Modal>
  );
};

const StyledButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px;
  max-width: 360px;
  display: flex;
  justify-content: space-evenly;
`;

const StyledContentWrapper = styled.div`
  padding: 10px;
  max-width: 360px;
  text-align: center;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

export default ClientPVOpportunityAlert;
