import React, { useContext, useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import request from "../../../../helpers/request";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from "react-router";
import { StoreContext } from "../../../../store/StoreProvider";
import Modal from "../../../../components/Modal";
import Select from "react-select";

const validationSchema = yup.object({});
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "black",
    padding: 2,
    fontSize: 14,
  }),
  control: () => ({
    display: "flex",
    height: 20,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    height: 20,
  }),
  value: (styles) => {
    return {
      ...styles,
      backgroundColor: "#cadeee",
    };
  },
  placeholder: (styles) => ({
    ...styles,
    color: "black",
    fontSize: 14,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    color: "black",
  }),
  valueLabel: (styles) => ({
    ...styles,
    color: "rgb(19, 71, 113)",
  }),

  singleValue: (provided, state) => {
    return { ...provided, color: "black", fontSize: 14 };
  },
};

const ChangeAssigment = ({ isModalOpen, handleOnClose, todo }) => {
  const location = useLocation();
  const history = useHistory();
  const [opportunitiesOption, setOpportunitiesOption] = useState([]);
  const [leadsOption, setLeadsOption] = useState([]);
  const { register, control, handleSubmit, reset, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      lead: null,
      opportunities: null,
    });
  };
  const sendData = async (data) => {
    try {
      const { status } = await request.patch(
        `/api/todo/change-assigment/${todo.id}`,
        {
          opportunities: data.opportunities?.value || null,
          leads: data.leads?.value || null,
          is_lead: data.is_lead,
          is_opportunity: data.is_opportunity,
        }
      );

      if (status === 200) {
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
      if (e.response?.status === 409) {
        handleOnClose();
      }
    }
  };

  const handleOnSubmit = async (data) => {
    await sendData(data);
  };

  // useEffect(() => {
  //   if (opportunities) {
  //     setOpportunitiesOption(
  //       opportunities.map((opt) => {
  //         return { label: opt.name, value: opt.id };
  //       })
  //     );
  //   }
  // }, [opportunities]);

  // useEffect(() => {
  //   if (leads) {
  //     setLeadsOption(
  //       leads.map((lead) => {
  //         return {
  //           label: `${lead.name || ""} ${lead.phone || ""} ${lead.email || ""}`,
  //           value: lead.id,
  //         };
  //       })
  //     );
  //   }
  // }, [leads]);

  useEffect(() => {
    if (todo.opportunity) {
      setValue("opportunities", {
        label: todo.opportunity.name,
        value: todo.opportunity.id,
      });
      setValue("is_opportunity", 1);
    } else {
      setValue("opportunities", null);
      setValue("is_opportunity", 0);
    }
    if (todo.lead) {
      setValue("leads", {
        label: `${todo.lead.name || ""} ${todo.lead.phone || ""} ${
          todo.lead.email || ""
        }`,
        value: todo.lead.id,
      });
      setValue("is_lead", 1);
    } else {
      setValue("leads", null);
      setValue("is_lead", 0);
    }
  }, [todo, todo.lead, todo.opportunity]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledFormWrapper>
        <StyledForm
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        >
          <StyledAddToDoHeader>
            <StyledTimesButton onClick={handleOnClose} type="button">
              <FontAwesomeIcon icon={faTimes} />
            </StyledTimesButton>
          </StyledAddToDoHeader>

          <input type="hidden" {...register("is_opportunity")} />
          <input type="hidden" {...register("is_lead")} />
          <StyledInput>
            <div className="input-group">
              <label>Szanse sprzedaży</label>
              <Controller
                control={control}
                name="opportunities"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    placeholder={"Wybierz..."}
                    onChange={(val) => {
                      reset({ leads: null });
                      onChange(val);
                    }}
                    value={value}
                    styles={customStyles}
                    options={opportunitiesOption}
                  />
                )}
              />
            </div>
          </StyledInput>
          <StyledInput>
            <div className="input-group">
              <label>Leady</label>
              <Controller
                control={control}
                name="leads"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    placeholder={"Wybierz..."}
                    onChange={(val) => {
                      reset({ opportunities: null });
                      onChange(val);
                    }}
                    value={value}
                    styles={customStyles}
                    options={leadsOption}
                  />
                )}
              />
            </div>
          </StyledInput>

          <div className="buttons">
            <button type="button" className="close" onClick={handleOnClose}>
              Anuluj
            </button>
            <button
              type="button"
              className="add"
              onClick={handleSubmit(handleOnSubmit)}
            >
              Zapisz
            </button>
          </div>
        </StyledForm>
      </StyledFormWrapper>
    </Modal>
  );
};

const StyledFormWrapper = styled.div`
  display: flex;
`;

const StyledTimesButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 1rem;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledAddToDoHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px 10px 0 0;
`;

const StyledForm = styled.form`
  max-width: 400px;
  min-width: 360px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.addNext {
        background: transparent;
        color: #134771;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }
      &.addNext:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 33%;

  margin: 25px 5px 0 0;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;
export default ChangeAssigment;
