import React from "react";
import styled from "styled-components";
import ClientComponent from "./Components/ClientComponent/ClientComponent";
import CompanyComponent from "./Components/CompanyComponent/CompanyComponent";
import LeadComponent from "./Components/LeadComponent/LeadComponent";
import OpportunitiesComponent from "./Components/OpportunitiesComponent/OpportunitiesComponent";
import SearchBar from "./Components/SearchBar";

const SearchMainContent = ({
  showClients,
  showCompanies,
  showOpportunities,
  setShowClients,
  setShowCompanies,
  setShowOpportunities,
  setSearching,
  setSearchClients,
  setSearchCompanies,
  setSearchOpportunities,
  setClientsCount,
  setCompaniesCount,
  setOpportunitiesCount,
  searchClients,
  clientsCount,
  searchCompanies,
  companiesCount,
  opportunitiesCount,
  searchOpportunities,
  text,
  leadsCount,
  showLeads,
  searchLeads,
  setShowLeads,
  setSearchLeads,
  setLeadsCount,
}) => {
  return (
    <StyledWrapper>
      <SearchBar
        setShowClients={setShowClients}
        setShowCompanies={setShowCompanies}
        setShowOpportunities={setShowOpportunities}
        setShowLeads={setShowLeads}
        setSearchClients={setSearchClients}
        setSearching={setSearching}
        setSearchCompanies={setSearchCompanies}
        setSearchOpportunities={setSearchOpportunities}
        setSearchLeads={setSearchLeads}
        setClientsCount={setClientsCount}
        setCompaniesCount={setCompaniesCount}
        setOpportunitiesCount={setOpportunitiesCount}
        setLeadsCount={setLeadsCount}
        text={text}
      />

      {clientsCount !== 0 && showClients && (
        <ClientComponent
          searchClients={searchClients}
          clientsCount={clientsCount}
        />
      )}

      {companiesCount !== 0 && showCompanies && (
        <CompanyComponent
          searchCompanies={searchCompanies}
          companiesCount={companiesCount}
        />
      )}

      {opportunitiesCount !== 0 && showOpportunities && (
        <OpportunitiesComponent
          searchOpportunities={searchOpportunities}
          opportunitiesCount={opportunitiesCount}
        />
      )}
      {leadsCount !== 0 && showLeads && (
        <LeadComponent searchLeads={searchLeads} leadsCount={leadsCount} />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export default SearchMainContent;
