import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddSource from "./AddSource";
import SourceItem from "./SourceItem/SourceItem";

const Source = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState(false);

  const { sources, setSources } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(false);
    setIsModalOpen(true);
  };

  const handleOpenEditSource = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchSources = async () => {
    try {
      const { status, data } = await request.get("/api/source");
      if (status === 200) {
        await setSources(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (sources === undefined || sources.length === 0) {
      fetchSources();
    }
  }, []);

  return (
    <StyledSourceWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>
          Dodaj źródła pozyskania
        </StyledButton>
      </StyledButtonWrapper>

      <StyledOfferTable>
        <StyledOfferTableHead>
          <tr>
            <StyledOfferTableHeadItem>Źródło</StyledOfferTableHeadItem>
            <StyledOfferTableHeadItem style={{ textAlign: "right" }}>
              Akcje
            </StyledOfferTableHeadItem>
          </tr>
        </StyledOfferTableHead>
        <tbody>
          {sources.map((source) => {
            return (
              <SourceItem
                handleOpenEditSource={handleOpenEditSource}
                fetchSources={fetchSources}
                setEditObject={setEditObject}
                source={source}
                key={source.id}
              />
            );
          })}
        </tbody>
      </StyledOfferTable>
      <AddSource
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchSources}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledSourceWrapper>
  );
};

const StyledSourceWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledOfferTable = styled.table`
  width: 100%;
  padding: 40px 0;
`;

const StyledOfferTableHead = styled.thead`
  font-size: 18px;
`;

const StyledOfferTableHeadItem = styled.th`
  text-align: start;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;
export default Source;
