import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../components/Modal";
import request from "../../../../helpers/request";
import { StoreContext } from "../../../../store/StoreProvider";

const validationSchema = yup.object({
  name: yup
    .string()
    .typeError("Nazwa firmy jest wymagana")
    .required("Nazwa firmy jest wymagana")
    .min(3, "Nazwa firmy musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwa firmy nie może być dłuższa niż 255 znaków"),
  NIP: yup
    .string()
    .matches(/^[0-9]+$/, "NIP musi składać się wyłącznie z cyfr 1-9")
    .min(10, "NIP musi posiadać co najmniej 10 znaków")
    .max(10, "NIP nie może być dłuższy niż 10 znaków")
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .nullable(true),
  REGON: yup
    .string()
    .matches(/^[0-9]+$/, "REGON musi składać się wyłącznie z cyfr 1-9")
    .min(9, "REGON musi posiadać co najmniej 9 znaków")
    .max(9, "REGON nie może być dłuższy niż 9 znaków")
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .nullable(true),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Numer telefonu musi składać się wyłącznie z cyfr 1-9")
    .min(9, "Numer telefonu musi zawierać conajmniej 9 znaków")
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .nullable(true),
  email: yup.string().email("proszę podać prawidłowy adres email"),
  city: yup.string().required("Nazwa miesjcowości jest wymagana"),
  postCode: yup.string().required("Kod pocztowy jest wymagany"),
  street: yup.string().nullable(),
  building_number: yup.string().nullable(),
  voivodeship: yup.string().required("Nazwa województwa jest wymagane"),
  source_id: yup.string().nullable(),
});

const AddCompany = ({ isModalOpen, handleOnClose, fetchData, company }) => {
  const { sources, voivodeships, heatingTypes } = useContext(StoreContext);

  const location = useLocation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const resetInputValue = () => {
    reset({
      name: "",
      NIP: "",
      REGON: "",
      phone: "",
      email: "",
      source_id: "",
      status: 1,
      city: "",
      postCode: "",
      post: "",
      street: "",
      building_number: "",
      heating_type: "",
      voivodeship: "",
      description: "",
    });
  };
  const sendData = async (data) => {
    try {
      const { status } = await request.patch(`/api/companies/${company.id}`, {
        ...data,
      });
      if (status === 201) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  useEffect(() => {
    if (company) {
      setValue("name", company.name);
      setValue("NIP", company.NIP);
      setValue("REGON", company.REGON);
      setValue("phone", company.phone);
      setValue("email", company.email);
      setValue("city", company.city);
      setValue("postCode", company.post_code);
      setValue("post", company.post);
      setValue("street", company.street);
      setValue("building_number", company.building_number);
      setValue("heating_type", company.heating_type?.id);
      setValue("voivodeship", company.voivodeship?.id);
      setValue("description", company.description);
      setValue("source_id", company.source?.id);
    }
  }, [company, sources]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <StyledInput>
          <div className="input-group">
            <label>Nazwa </label>
            <input
              type="text"
              {...register("name")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.name && <span className="error">{errors.name.message}</span>}
        <StyledInput>
          <div className="input-group">
            <label>NIP</label>
            <input type="text" {...register("NIP")} />
          </div>
        </StyledInput>
        {errors?.NIP && <span className="error">{errors.NIP.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>REGON</label>
            <input type="text" {...register("REGON")} />
          </div>
        </StyledInput>
        {errors?.REGON && <span className="error">{errors.REGON.message}</span>}
        <StyledInput>
          <div className="input-group">
            <label htmlFor="status">Status:</label>
            <select id="status" {...register("status")}>
              <option hidden value="">
                Wybierz...
              </option>
              <option value={1}>nieokreślony</option>
              <option value={2}>aktualny klient</option>
              <option value={2}>były klient</option>
            </select>
          </div>
        </StyledInput>
        {errors?.status && (
          <span className="error">{errors.status.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Źródło pozyskania:</label>
            <select {...register("source_id")}>
              <option value="">Wybierz...</option>
              {sources.map((source) => {
                return (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                );
              })}
            </select>
          </div>
        </StyledInput>
        {errors?.maintainer_id && (
          <span className="error">{errors.maintainer_id.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input type="number" {...register("phone")} />
          </div>
        </StyledInput>
        {errors?.phone && <span className="error">{errors.phone.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Adres e-mail</label>
            <input
              type="text"
              {...register("email")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.email && <span className="error">{errors.email.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input
              type="text"
              {...register("street")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.street && (
          <span className="error">{errors.street.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("building_number")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.building_number && (
          <span className="error">{errors.building_number.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input
              type="text"
              {...register("city")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.city && <span className="error">{errors.city.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input
              type="text"
              {...register("postCode")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.postCode && (
          <span className="error">{errors.postCode.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input
              type="text"
              {...register("post")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.post && <span className="error">{errors.post.message}</span>}

        <StyledInput>
          <div className="input-group">
            <label>Województwo</label>
            <select {...register("voivodeship")}>
              <option value="">Wybierz...</option>
              {voivodeships &&
                voivodeships.map((voivodeship) => (
                  <option value={voivodeship.id} key={voivodeship.id}>
                    {voivodeship.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.voivodeship && (
          <span className="error">{errors.voivodeship.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Kraj</label>
            <input
              type="text"
              {...register("country")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.country && (
          <span className="error">{errors.country.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>System ogrzewania</label>
            <select {...register("heating_type")}>
              <option value="">Wybierz...</option>
              {heatingTypes &&
                heatingTypes.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.name}
                  </option>
                ))}
            </select>
          </div>
        </StyledInput>
        {errors?.heating_type && (
          <span className="error">{errors.heating_type.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Opis</label>
            <input
              type="text"
              {...register("description")}
              autoComplete="new-password"
            />
          </div>
        </StyledInput>
        {errors?.description && (
          <span className="error">{errors.country.message}</span>
        )}

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Zapisz
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;
const StyledForm = styled.form`
  max-width: 600px;

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
        text-decoration: underline;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default AddCompany;
