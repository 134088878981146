import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const NoteDropDownOptions = ({
  isOpen,
  setIsOpenOptions,
  handleOnOpenAlert,
  handleOnClick,
  user,
  note,
  handleOnDownloadAll,
}) => {
  const dropRef = useRef(null);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isDirector, setIsDirector] = useState(false);
  const [isOffice, setIsOffice] = useState(false);
  const [isMaintainer, setIsMaintainer] = useState(false);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpenOptions(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });

  useEffect(() => {
    if (user && note?.user) {
      setIsAdmin(user.role?.id === 1);
      setIsOffice(user.role?.id === 3);
      setIsDirector(
        user.role?.id === 2 && user.department?.id === note?.user.department?.id
      );
      setIsMaintainer(user.id === note?.user.id);
    }
  }, [note?.user, user]);
  return (
    <Dropdown ref={dropRef} className={isOpen ? " active" : ""}>
      <ul>
        {(isAdmin || isDirector || isMaintainer) &&
          Number(note.system_note) === 0 && (
            <>
              <li>
                <button onClick={handleOnClick}>Edytuj</button>
              </li>
              <li>
                <button onClick={handleOnOpenAlert}>Usuń</button>
              </li>
            </>
          )}
        {(isAdmin || isOffice) && Number(note.system_note) === 1 && (
          <>
            <li>
              <button onClick={handleOnClick}>Edytuj</button>
            </li>
            <li>
              <button onClick={handleOnOpenAlert}>Usuń</button>
            </li>
          </>
        )}
        {note?.files?.length > 1 && (
          <li>
            <button onClick={handleOnDownloadAll}>
              Pobierz ( {note?.files?.length}{" "}
              {note?.files?.length < 4 ? "pliki" : "plików"} )
            </button>
          </li>
        )}
      </ul>
    </Dropdown>
  );
};

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 15px;
  z-index: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  display: none;
  min-width: 160px;

  ul {
    list-style: none;
  }
  a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px 5px;
  }
  button {
    display: block;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    text-decoration: none;
    color: black;
    padding: 5px 5px;
    cursor: pointer;
  }
  &.active {
    display: block;
  }
`;

export default NoteDropDownOptions;
