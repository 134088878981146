import React from "react";

const Calendar = () => {
  return (
    <div>
      <p>
        Tu znajdować się będą opcję dla kalendarza. Jeszcze nie wiem co ale sie
        napewno coś znajdzie
      </p>
    </div>
  );
};

export default Calendar;
