import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../../helpers/request";
import DeleteAlert from "./DeleteAlert";

const CutPriceItem = ({
  handleOpenEdit,
  fetchCutPrices,
  setEditObject,
  cutPrice,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleOnDelete = async () => {
    try {
      const { status } = await request.delete(`/api/cut-price/${cutPrice.id}`);
      if (status === 200) {
        fetchCutPrices();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnCloseAlert = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsAlertOpen(false);
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnEdit = () => {
    setEditObject(cutPrice);
    handleOpenEdit();
  };

  return (
    <StyledTaskTypeItem>
      <StyledPriceWrapper>{cutPrice.up_to_meter} m</StyledPriceWrapper>
      <StyledPriceWrapper>
        {Number(cutPrice.price_to_10).toFixed(2)} zł/m
      </StyledPriceWrapper>
      <StyledPriceWrapper>
        {Number(cutPrice.price_to_15).toFixed(2)} zł/m
      </StyledPriceWrapper>
      <StyledPriceWrapper>
        {Number(cutPrice.price_to_20).toFixed(2)} zł/m
      </StyledPriceWrapper>
      <StyledPriceWrapper>
        {Number(cutPrice.price_to_30).toFixed(2)} zł/m
      </StyledPriceWrapper>
      <StyledPriceWrapper>
        {Number(cutPrice.price_to_40).toFixed(2)} zł/m
      </StyledPriceWrapper>
      <StyledPriceWrapper>
        {Number(cutPrice.price_to_50).toFixed(2)} zł/m
      </StyledPriceWrapper>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnDelete={handleOnDelete}
        handleOnClose={handleOnCloseAlert}
      />
    </StyledTaskTypeItem>
  );
};

const StyledTaskTypeItem = styled.tr``;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledPriceWrapper = styled.td`
  text-align: right;
`;

const StyledButtonGroup = styled.td`
  text-align: right;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
export default CutPriceItem;
