import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Modal from "../../../../../../components/Modal";
import request from "../../../../../../helpers/request";

const validationSchema = yup.object().shape({});

const AddCutPrice = ({
  isModalOpen,
  handleOnClose,
  fetchCutPrices,
  isEditMode,
  editObject,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const location = useLocation();
  const history = useHistory();

  const [isSended, setIsSended] = useState(false);

  const resetInputValue = () => {
    reset({
      up_to_meter: null,
      price_to_10: null,
      price_to_15: null,
      price_to_20: null,
      price_to_30: null,
      price_to_40: null,
      price_to_50: null,
    });
  };
  const sendData = async (data) => {
    setIsSended(true);
    try {
      if (isEditMode) {
        const { status } = await request.patch(
          `/api/cut-price/${editObject.id}`,
          { ...data }
        );
        if (status === 201) {
          fetchCutPrices();
          resetInputValue();
          handleOnClose();
        }
      } else {
        const { status } = await request.post("/api/cut-price", { ...data });
        if (status === 200) {
          fetchCutPrices();
          resetInputValue();
          handleOnClose();
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = (data) => {
    sendData(data);
  };

  useEffect(() => {
    if (editObject) {
      setValue("up_to_meter", editObject.up_to_meter);
      setValue("price_to_10", editObject.price_to_10);
      setValue("price_to_15", editObject.price_to_15);
      setValue("price_to_20", editObject.price_to_20);
      setValue("price_to_30", editObject.price_to_30);
      setValue("price_to_40", editObject.price_to_40);
      setValue("price_to_50", editObject.price_to_50);
    } else {
      setValue("up_to_meter", null);
      setValue("price_to_10", null);
      setValue("price_to_15", null);
      setValue("price_to_20", null);
      setValue("price_to_30", null);
      setValue("price_to_40", null);
      setValue("price_to_50", null);
    }
  }, [editObject]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>

        <StyledInput>
          <div className="input-group">
            <label>Do ilu metrow</label>
            <input type="number" {...register("up_to_meter")} />
          </div>
        </StyledInput>
        {errors?.up_to_meter && (
          <span className="error">{errors.up_to_meter.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Cena do 10 kWp</label>
            <input type="number" {...register("price_to_10")} />
          </div>
        </StyledInput>
        {errors?.price_to_10 && (
          <span className="error">{errors.price_to_10.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Cena do 15 kWp</label>
            <input type="number" {...register("price_to_15")} />
          </div>
        </StyledInput>
        {errors?.price_to_15 && (
          <span className="error">{errors.price_to_10.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Cena do 20 kWp</label>
            <input type="number" {...register("price_to_20")} />
          </div>
        </StyledInput>
        {errors?.price_to_20 && (
          <span className="error">{errors.price_to_20.message}</span>
        )}

        <StyledInput>
          <div className="input-group">
            <label>Cena do 30 kWp</label>
            <input type="number" {...register("price_to_30")} />
          </div>
        </StyledInput>
        {errors?.price_to_30 && (
          <span className="error">{errors.price_to_30.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Cena do 40 kWp</label>
            <input type="number" {...register("price_to_40")} />
          </div>
        </StyledInput>
        {errors?.price_to_40 && (
          <span className="error">{errors.price_to_40.message}</span>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Cena do 50 kWp</label>
            <input type="number" {...register("price_to_50")} />
          </div>
        </StyledInput>
        {errors?.price_to_50 && (
          <span className="error">{errors.price_to_50.message}</span>
        )}

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
            disabled={isSended}
          >
            {isEditMode ? "Zapisz" : "Dodaj"}
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 360px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
        text-decoration: underline;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;
const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;
export default AddCutPrice;
