import {
  faBullseye,
  faCheck,
  faEllipsisH,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import ItemFile from "../../../../../../../../components/Note/ItemFile";
import ShowImage from "../../../../../../../../components/ShowImage/ShowImage";
import request from "../../../../../../../../helpers/request";
import { StoreContext } from "../../../../../../../../store/StoreProvider";

import DeleteAlert from "./DeleteAlert";
import loading from "../../../../../../../../img/48x48.gif";
import EditNote from "./EditNote/EditNote";
import NoteDropDownOptions from "./NoteDropDownOption";

const NoteItem = ({ note, fetchData, handleOnClickImage }) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [file, setFile] = useState([]);
  const [time, setTime] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSended, setIsSended] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleOnClickEdit = () => {
    setIsModalOpen(true);
  };
  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };
  const { user } = useContext(StoreContext);

  const handleOnDelete = async () => {
    const { status } = await request.delete(
      `/api/assembly_faults_note/${note.id}`
    );
    if (status === 201) {
      fetchData();
      setIsAlertOpen(false);
    }
  };

  const handleOnEdit = () => {
    handleOnClickEdit();
  };

  const handleDropDownOptions = (e) => {
    e.preventDefault();
    setIsOpenOptions((prev) => !prev);
  };

  useEffect(() => {
    if (note.created_at) {
      if (moment(note.created_at).isSame(new Date(), "year")) {
        setTime(moment(note.created_at).format("DD MMM HH:mm"));
      } else {
        setTime(moment(note.created_at).format("lll"));
      }
    }
  }, [note]);

  useEffect(() => {
    setFile([]);
    setImageFile([]);
    if (note.files.length > 0) {
      [...note.files].forEach((file) => {
        if (
          file.mime_type === "image/jpg" ||
          file.mime_type === "image/png" ||
          file.mime_type === "image/jpeg"
        ) {
          setImageFile((prev) => [...prev, file]);
        } else {
          setFile((prev) => [...prev, file]);
        }
      });
    }
  }, [note.files]);

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
    setIsOpenOptions(false);
  };

  const handleOnAlertOpen = () => {
    setIsAlertOpen(true);
    setIsOpenOptions(false);
  };

  const handleOnDownloadAll = async () => {
    setIsSended(true);
    try {
      const { status, data } = await request.get(
        `api/file/download-all/${note.id}`,
        {
          responseType: "blob",
        }
      );
      if (status === 200) {
        const href = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = href;
        if (note.opportunities?.name) {
          link.setAttribute(
            "download",
            `${note.opportunities.name.replaceAll(" ", "_")}.zip`
          ); //or any other extension
        } else {
          link.setAttribute("download", `AllFiles.zip`); //or any other extension
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  return (
    <StyledNoteItem>
      <StyledHeadWrapper>
        <StyledUserAvatar>
          <FontAwesomeIcon icon={faUser} />
        </StyledUserAvatar>
        <StyledHeadContent>
          <StyledNameWrapper>
            <StyledNameP>{note.user?.name}</StyledNameP>
          </StyledNameWrapper>
          <StyledTimeWrapper>{time}</StyledTimeWrapper>
        </StyledHeadContent>
        <StyledCollapseMenu>
          {note.files?.length > 0 && user.role?.id === 1 && (
            <button onClick={handleDropDownOptions} type="button">
              <FontAwesomeIcon icon={faEllipsisH} />
            </button>
          )}
        </StyledCollapseMenu>

        <NoteDropDownOptions
          isOpen={isOpenOptions}
          setIsOpenOptions={setIsOpenOptions}
          handleOnAlertOpen={handleOnAlertOpen}
          handleOnClick={handleOnEdit}
          handleOnDownloadAll={handleOnDownloadAll}
          user={user}
          note={note}
        />
      </StyledHeadWrapper>
      <StyledContentWrapper>
        <StyledStatusWrapper>
          <StatusTitle>Status:</StatusTitle>
          {note.status === 0 && (
            <StyledStatus>
              <StyledSpan>
                <StyledSpanIcon icon={faTimes} />
              </StyledSpan>
              <StatusSpanTitle>Niewykonano</StatusSpanTitle>
            </StyledStatus>
          )}
          {note.status === 1 && (
            <StyledStatus>
              <StyledSpan inProgress>
                <StyledSpanIcon icon={faBullseye} />
              </StyledSpan>
              <StatusSpanTitle>W trakcie</StatusSpanTitle>
            </StyledStatus>
          )}
          {note.status === 2 && (
            <StyledStatus>
              <StyledSpan done>
                <StyledSpanIcon icon={faCheck} />
              </StyledSpan>
              <StatusSpanTitle>Wykonane</StatusSpanTitle>
            </StyledStatus>
          )}
        </StyledStatusWrapper>
        <StyledNoteP>
          {note.text ? note.text : "Załączył(a) plik(i)"}
        </StyledNoteP>
        <StyledItemContentWrapper>
          {imageFile.length > 0 && (
            <ShowImage
              imageFile={imageFile}
              handleOnClickImage={handleOnClickImage}
            />
          )}
          {file.length > 0 &&
            file.map((item) => (
              <ItemFile key={item.id} file={item} setIsSended={setIsSended} />
            ))}
        </StyledItemContentWrapper>
      </StyledContentWrapper>
      {isSended && (
        <StyledSendNoteWrapper>
          <StyledSendNoteInnerWrapper>
            <StyledLoadingImage src={loading} alt="loading" />
          </StyledSendNoteInnerWrapper>
        </StyledSendNoteWrapper>
      )}
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnDelete={handleOnDelete}
        handleOnClose={handleOnCloseAlert}
      />
      <EditNote
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchData}
        note={note}
        setIsModalOpen={setIsModalOpen}
      />
    </StyledNoteItem>
  );
};
const StatusTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #134771;
  margin-right: 10px;
`;
const StatusSpanTitle = styled.p`
  color: #134771;
`;

const StyledStatus = styled.div`
  display: flex;
`;

const StyledSpanIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const StyledSpan = styled.span`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.done
      ? "rgb(102, 188, 70)"
      : props.inProgress
      ? "rgb(255, 238, 127)"
      : "rgb(244,118,49)"};

  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 5px;
`;

const StyledStatusWrapper = styled.div`
  display: flex;
`;

const StyledLoadingImage = styled.img`
  margin-top: 10px;
`;

const StyledSendNoteInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

const StyledTimeWrapper = styled.div`
  color: gray;
  font-size: 14px;
`;
const StyledNameWrapper = styled.div`
  display: flex;
`;

const StyledNoteP = styled.p`
  white-space: pre-line;
`;

const StyledNameP = styled.p`
  margin-right: 5px;
  white-space: nowrap;
`;
const StyledCollapseMenu = styled.div`
  button {
    padding: 0 10px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  position: absolute;
  top: 0px;
  right: 0px;
`;
const StyledHeadContent = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
  margin-right: 40px;
  flex: 1;
`;

const StyledContentWrapper = styled.div`
  margin-top: 10px;
`;
const StyledItemContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledUserAvatar = styled.div`
  width: 40px;
  height: 40px;
  background: #cadeee;
  display: flex;

  align-items: center;
  justify-content: center;
  color: #134771;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const StyledHeadWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 40px;
  justify-content: center;
  align-items: center;
`;
const StyledNoteItem = styled.div`
  background-color: white;
  margin: 10px 0;
  border-radius: 10px;

  position: relative;
`;

export default NoteItem;
