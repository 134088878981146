import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../store/StoreProvider";
import UserButton from "./UserButton";

const UserFilterManager = ({ userFilter, setUserFilter }) => {
  const { departments, user } = useContext(StoreContext);

  const [managerDepartment, setManagerDepartment] = useState([]);
  const [chooseUser, setChooseUser] = useState(null);

  useEffect(() => {
    if (departments.length > 0 && user) {
      const filteredDepartments = departments.find(
        (department) => department.id === user.department?.id
      );

      setManagerDepartment(filteredDepartments);
    }
  }, [departments, user]);

  const handleOnConfirmSelection = () => {
    setUserFilter(chooseUser);
  };
  return (
    <StyledCollapse>
      <StyledTitleWrapper>
        <StyledCollapseTitle>Handlowcy</StyledCollapseTitle>
      </StyledTitleWrapper>

      <StyledCollapseItemWrapper>
        {managerDepartment.users &&
          managerDepartment.users.map((user) => {
            return (
              <UserButton
                key={user.id}
                user={user}
                chooseUser={chooseUser}
                setChooseUser={setChooseUser}
              />
            );
          })}
      </StyledCollapseItemWrapper>
      <StyledButtonWrapper>
        <StyledConfirmButton onClick={handleOnConfirmSelection}>
          Zatwierdź
        </StyledConfirmButton>
      </StyledButtonWrapper>
    </StyledCollapse>
  );
};

const StyledButtonWrapper = styled.div`
  padding: 10px;
`;

const StyledConfirmButton = styled.button`
  border: none;
  width: 100%;
  padding: 10px 5px;

  cursor: pointer;
  border-radius: 5px;
  background: #134771;
  color: rgb(202, 222, 238);
  &:hover {
    background: #053257;
  }
`;

const StyledCollapseItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCollapseTitle = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledTitleWrapper = styled.div`
  padding: 10px 0;
`;

const StyledCollapse = styled.div`
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

export default UserFilterManager;
