import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Modal from "../../../../../../components/Modal";

const ClientChooseOfferOpportunity = ({
  isChooseModalOpen,
  handleOnClose,
  handleOnChooseHP,
  handleOnChoosePV,
}) => {
  return (
    <Modal
      isModalOpen={isChooseModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledCancelButton onClick={handleOnClose}>
        <FontAwesomeIcon icon={faTimes} />
      </StyledCancelButton>
      <StyledContentWrapper>Szansy sprzedaży</StyledContentWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOnChoosePV}>Fotowoltaika</StyledButton>
        <StyledButton onClick={handleOnChooseHP}>Pompa Ciepła</StyledButton>
      </StyledButtonWrapper>
    </Modal>
  );
};

const StyledButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #053257;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px;
  max-width: 360px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledContentWrapper = styled.div`
  padding: 10px;
  max-width: 360px;
  text-align: center;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

export default ClientChooseOfferOpportunity;
