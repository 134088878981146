import React, { useState } from "react";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import request from "../../../../../../../helpers/request";
import DeleteAlert from "./DeleteAlert";

const SalesFunnelItem = ({
  element,
  handleOpenEditStatusTask,
  setEditObject,
  fetchDataSalesFunnel,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOnEdit = () => {
    setEditObject(element);
    handleOpenEditStatusTask();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/salesfunnel/${element.id}`);
    if (status === 200) {
      fetchDataSalesFunnel();
      setIsAlertOpen(false);
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <StyledTaskStatusItem>
      <StyledDescription>
        <StyledTaskTypeIconContainer>
          <StyledTaskTypeIcon
            icon={[element.tasktype?.prefix, element.tasktype?.icon]}
          />
        </StyledTaskTypeIconContainer>
        <p>{element.tasktype?.name}</p>
      </StyledDescription>
      <StyledPositionWrapper>
        Pozycja: <b>{element.position}</b>
      </StyledPositionWrapper>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>

      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskStatusItem>
  );
};

const StyledPositionWrapper = styled.div`
  margin-right: 20px;
`;
const StyledTaskTypeIcon = styled(FontAwesomeIcon)``;
const StyledTaskTypeIconContainer = styled.div`
  width: 25px;
  text-align: center;
  margin-right: 10px;
`;

const StyledTaskStatusItem = styled.li`
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
`;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.div`
  display: flex;
  flex: 1;
`;

const StyledButtonGroup = styled.div`
  display: flex;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;
export default SalesFunnelItem;
