import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import styled from "styled-components";
import request from "../../../helpers/request";
import ClientAddNote from "./ClientAddNote/ClientAddNote";
import ClientInfo from "./ClientInfo/ClientInfo";
import ClientNoteList from "./ClientNoteList/ClientNoteList";
import ClientSideBar from "./ClientSideBar/ClientSideBar";
import EditClient from "./EditClient/EditClient";

const ClientDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [client, setClient] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const fetchClient = async () => {
    try {
      const { data, status } = await request.get(`api/clients/${id}`);
      if (status === 200) {
        setClient(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      } else {
        history.push("/dashboard/contacts/clients");
      }
    }
  };

  useEffect(() => {
    setClient([]);
    fetchClient();
  }, []);

  const handleOnClick = () => {
    setIsEditModalOpen(true);
  };

  const handleOnEditModalClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditModalOpen(false);
  };

  return (
    <StyledWrapper>
      <ClientInfo client={client} handleOnClick={handleOnClick} />
      <StyledMainWall>
        <ClientAddNote fetchData={fetchClient} clientId={client.id} />
        <ClientNoteList note={client.notes} fetchData={fetchClient} />
      </StyledMainWall>

      <ClientSideBar client={client} fetchData={fetchClient} />

      <EditClient
        handleOnClose={handleOnEditModalClose}
        client={client}
        fetchData={fetchClient}
        isModalOpen={isEditModalOpen}
      />
    </StyledWrapper>
  );
};

const StyledMainWall = styled.div`
  flex-grow: 1;
  width: 100%;
  grid-area: note;

  @media screen and (max-width: 1600px) {
    margin: 0;
    flex: 1;
  }
`;
const StyledWrapper = styled.div`
  display: grid;
  padding: 10px;
  max-width: 1400px;
  margin: 0 auto;
  flex: 1;
  grid-template-columns: minmax(320px, 340px) 1fr minmax(360px, 380px);
  grid-auto-rows: fit-content(1em);
  gap: 10px;
  grid-template-areas: "info note sidebar";

  @media screen and (max-width: 1600px) {
    grid-template-columns: 1fr minmax(360px, 380px);
    grid-template-areas:
      "info sidebar"
      "note sidebar"
      "none sidebar";
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "info"
      "sidebar"
      "note";
  }
`;

export default ClientDetails;
