import React from "react";
import styled from "styled-components";

const TodoItem = ({ todo }) => {
  return (
    <StyledTodoItemWrapper title={todo.name}>
      <StyledTodoName>{todo.name}</StyledTodoName>
      {todo.opportunity && (
        <StyledOpportunityP>{todo.opportunity?.name}</StyledOpportunityP>
      )}
    </StyledTodoItemWrapper>
  );
};
const StyledOpportunityP = styled.p`
  margin: 0 10px;
  color: #7a1b1b;
`;

const StyledTodoItemWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  text-align: left;
  padding: 2px;
  display: flex;
  &:nth-child(even) {
    background: #ebebeb;
  }
`;

const StyledTodoName = styled.p``;

export default TodoItem;
