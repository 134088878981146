import React from "react";
import styled from "styled-components";
import ContractTermsDetails from "./Components/ContractTermsDetails/ContractTermsDetails";
import OwnerDetails from "./Components/OwnerDetails/OwnerDetails";

import PropertyDetails from "./Components/PropertyDetails/PropertyDetails";

const HeatPumpContractInfo = ({ opportunities, fetchData }) => {
  return (
    <StyledStepWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Zbieranie danych do umowy</StyledTitle>
      </StyledTitleWrapper>
      <OwnerDetails
        opportunities={opportunities}
        fetchData={fetchData}
        ownerDetails={opportunities.owner_details}
      />
      <PropertyDetails
        opportunities={opportunities}
        fetchData={fetchData}
        propertyDetails={opportunities.property_details}
      />

      <ContractTermsDetails
        opportunities={opportunities}
        fetchData={fetchData}
        contractTermsDetails={opportunities.heat_pump_terms_details}
      />
    </StyledStepWrapper>
  );
};

const StyledTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: rgb(244, 118, 49);
`;
const StyledTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
`;
const StyledStepWrapper = styled.div`
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #13477140;
`;

export default HeatPumpContractInfo;
