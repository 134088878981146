import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddCompany from "../../../../Contact/Companies/AddCompany/AddCompany";
import styled from "styled-components";

const OpportunitiesCompanyInfo = ({ company, fetchOpportunities }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [editObject, setEditObject] = useState(null);

  const [adressString, setAdressString] = useState(null);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
    setEditMode(false);
    setEditObject(null);
  };

  const handleOnClickEdit = () => {
    setEditObject(company);
    setEditMode(true);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (
      company.city &&
      company.building_number &&
      company.post_code &&
      company.post &&
      company.street
    ) {
      setAdressString(
        `ul. ${company?.street} ${company?.building_number}, ${company?.city} ${company?.post_code} ${company?.post}`
      );
    } else if (
      company?.city &&
      company?.building_number &&
      company?.post &&
      company?.post_code
    ) {
      setAdressString(
        `${company?.city} ${company?.building_number}, ${company?.post_code} ${company?.post} `
      );
    } else if (
      company?.city &&
      company?.street &&
      company?.post &&
      company?.post_code
    ) {
      setAdressString(
        `ul. ${company?.street} ${company?.city}, ${company?.post_code} ${company?.post}`
      );
    } else if (
      company.city &&
      company.building_number &&
      company.post_code &&
      company.street
    ) {
      setAdressString(
        `ul. ${company?.street} ${company?.building_number}, ${company?.post_code} ${company?.city}`
      );
    } else if (
      company?.city &&
      company?.building_number &&
      company?.post_code
    ) {
      setAdressString(
        `${company?.building_number}, ${company?.post_code} ${company?.city} `
      );
    } else if (company?.city && company?.street && company?.post_code) {
      setAdressString(
        `ul. ${company?.street}, ${company?.post_code} ${company?.city}`
      );
    } else {
      setAdressString(" Brak adresu / Adres nieprawidłowy");
    }
  }, [
    company,
    company?.post_code,
    company?.street,
    company?.city,
    company?.building_number,
  ]);
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitleP>Firma</StyledTitleP>
      </StyledTitleWrapper>
      <StyledNameWrapper>
        <StyledNameLink
          to={`/dashboard/contacts/companies/details/${company.id}`}
        >
          {company.name}
        </StyledNameLink>
      </StyledNameWrapper>
      <StyledDetailsWrapper>
        {company.email && (
          <StyledEmailWrapper>
            <StyledEmailIcon icon={faEnvelope}></StyledEmailIcon>
            <StyledEmailLink href={`mailto:${company.email}`}>
              {company.email}
            </StyledEmailLink>
          </StyledEmailWrapper>
        )}
        {company.phone && (
          <StyledPhoneWrapper>
            <StyledPhoneIcon icon={faPhone}></StyledPhoneIcon>
            <StyledPhoneLink href={`tel:${company.phone}`}>
              {company.phone}
            </StyledPhoneLink>
          </StyledPhoneWrapper>
        )}
        {company.NIP && (
          <StyledItemWrapper>
            <StyledItemTitle>NIP:</StyledItemTitle>
            <StyledItemP>{company.NIP}</StyledItemP>
          </StyledItemWrapper>
        )}
        {company.REGON && (
          <StyledItemWrapper>
            <StyledItemTitle>REGON:</StyledItemTitle>
            <StyledItemP>{company.REGON}</StyledItemP>
          </StyledItemWrapper>
        )}
        {adressString && (
          <StyledItemWrapper>
            <StyledMarkerIcon icon={faMapMarkerAlt} />
            <StyledItemP>{adressString}</StyledItemP>
          </StyledItemWrapper>
        )}
        {company.voivodeship && (
          <StyledItemWrapper>
            <StyledItemTitle>Województwo:</StyledItemTitle>
            <StyledItemP>{company.voivodeship.name}</StyledItemP>
          </StyledItemWrapper>
        )}
        {company.country && (
          <StyledItemWrapper>
            <StyledItemTitle>Kraj:</StyledItemTitle>
            <StyledItemP>{company.country}</StyledItemP>
          </StyledItemWrapper>
        )}
        {company.description && (
          <StyledItemWrapper>
            <StyledItemTitle>Opis:</StyledItemTitle>
            <StyledItemP>{company.description}</StyledItemP>
          </StyledItemWrapper>
        )}
        <StyledButtonWrapper>
          <StyledButton onClick={handleOnClickEdit}>Edytuj</StyledButton>
        </StyledButtonWrapper>
      </StyledDetailsWrapper>

      <AddCompany
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchOpportunities}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledWrapper>
  );
};

const StyledMarkerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledButton = styled.button`
  padding: 5px;
  background: #134771;
  color: white;
  border: none;
  width: 100%;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #004c7a;
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledItemP = styled.p``;
const StyledItemTitle = styled.p`
  margin-right: 5px;
  font-weight: bold;
`;
const StyledItemWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

const StyledPhoneLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;

const StyledPhoneIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  font-size: 14px;
`;

const StyledPhoneWrapper = styled.div`
  margin-top: 5px;
  display: flex;
`;

const StyledEmailIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  font-size: 14px;
`;
const StyledEmailWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

const StyledEmailLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: gray;
    text-decoration: underline;
  }
`;
const StyledDetailsWrapper = styled.div`
  padding: 10px;
`;
const StyledTitleP = styled.p`
  font-size: 18px;
  font-weight: bold;
`;
const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  border-radius: 5px 5px 0 0;
  padding: 5px;
`;
const StyledNameLink = styled(Link)`
  font-size: 18px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: #0167a7;
    text-decoration: underline;
  }
`;
const StyledNameWrapper = styled.div`
  padding: 10px; ;
`;
const StyledWrapper = styled.div`
  min-width: 300px;
  background: #fff;
  border-radius: 10px;
  @media screen and (max-width: 1600px) {
    flex: 1;
  }
`;

export default OpportunitiesCompanyInfo;
