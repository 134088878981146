import React from "react";

const ContactSettings = () => {
  return (
    <div>
      <p>
        znajduje się trzy podmenu z stanowiskiami i możliwościa dodawania
        ustawiania edycji i usiwania{" "}
      </p>
      <p>
        możliwość segmentacji klijenta oraz możliwośc ustawiania statusu
        klijenta
      </p>
    </div>
  );
};

export default ContactSettings;
