import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { StoreContext } from "../../../store/StoreProvider";

const validationSchema = yup.object({
  heat_pump_id: yup
    .string()
    .typeError("Proszę wybrać pompę ciepła")
    .required("Proszę wybrać pompę ciepła"),
  hot_water_tank_id: yup
    .string()
    .typeError("Proszę wybrać zasobnik CWU")
    .required("Proszę wybrać zasobnik CWU"),
  heat_pump_count: yup
    .string()
    .typeError("Proszę podać ilość pomp ciepłą")
    .required("Proszę podać ilość pomp ciepła")
    .matches(
      /^[0-9]+$/,
      "Ilość pomp ciepła musi składać się wyłącznie z cyfr 0-9"
    ),
  hot_water_tank_count: yup
    .string()
    .typeError("Proszę podać ilość zasobników CWU")
    .required("Proszę podać ilość zasobników CWU")
    .matches(
      /^[0-9]+$/,
      "Ilość zasobników CWU musi składać się wyłącznie z cyfr 0-9"
    ),
  services: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Nazwa jest wymagana."),
      price: yup
        .string()
        .matches(/^[0-9]+$/, "Cena musi składać się wyłącznie z cyfr 1-9")
        .transform((cv, ov) => {
          return ov === "" ? null : cv;
        })
        .nullable(true),
    })
  ),
});

const HeatPumpCalculator = () => {
  const { user } = useContext(StoreContext);
  const [flushingSystem, setFlushingSystem] = useState(false);
  const [leaveExistingSource, setLeaveExistingSource] = useState(false);
  const [wirlessController, setWirlessController] = useState(false);
  const [specialDiscountManager, setSpecialDiscountManager] = useState(0);
  const [specialDiscountManagement, setSpecialDiscountManagement] = useState(0);
  const [heatPumps, setHeatPumps] = useState([]);
  const [heatPumpId, setHeatPumpId] = useState(null);
  const [heatPumpValue, setHeatPumpValue] = useState(1);
  const [hotWaterTanks, setHotWaterTanks] = useState([]);
  const [hotWaterTankId, setHotWaterTankId] = useState(null);
  const [hotWaterTankValue, setHotWaterTankValue] = useState(1);
  const [vatRate, setVatRate] = useState(8);
  const [margin, setMargin] = useState(0);

  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      heat_pump_id: "",
      heat_pump_count: 1,
      hot_water_tank_id: 1,
      hot_water_tank_count: 1,
      flushing_system: false,
      leaving_existing_source: false,
      wirless_controller: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });

  const watchFieldArray = watch("services");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const handleOnCalcPower = () => {
    let netto = 0;
    if (heatPumpId > 0) {
      const heatPump = heatPumps.find((pump) => pump.id === Number(heatPumpId));
      setValue("heat_pump_id", heatPump.id);

      netto += Number(heatPump.price) * heatPumpValue;
    } else {
      setValue("heat_pump_id", "");
    }
    if (hotWaterTankId > 0) {
      const hotWaterTank = hotWaterTanks.find(
        (tank) => tank.id === Number(hotWaterTankId)
      );
      setValue("hot_water_tank_id", hotWaterTank.id);
      netto += Number(hotWaterTank.price) * hotWaterTankValue;
    } else {
      setValue("hot_water_tank_id", "");
    }

    if (heatPumpId > 0 || hotWaterTankId > 0) {
      netto += 8000;
    }
    if (flushingSystem) {
      netto += 3000;
    }
    if (specialDiscountManagement) {
      netto -= Number(specialDiscountManagement);
    }
    if (specialDiscountManager) {
      netto -= Number(specialDiscountManager);
    }

    if (leaveExistingSource) {
      netto += 3000;
    }
    if (wirlessController) {
      netto += 600;
    }
    if (margin > 0) {
      netto += Number(margin);
    }

    let sumall;
    if (watchFieldArray) {
      sumall = watchFieldArray
        .map((item) => item.name.trim() && item.price)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    } else {
      sumall = 0;
    }

    if (sumall > 0) {
      netto += Number(sumall);
    }

    const vatPrice = (Number(netto) * Number(vatRate)) / 100;

    setValue("price_netto", Number(netto).toFixed(2));
    setValue("price_sum", Math.ceil(netto + Number(vatPrice)));
    setValue("price_vat", Math.ceil(vatPrice));
  };

  useEffect(() => {
    handleOnCalcPower();
  }, [
    vatRate,
    margin,
    watchFieldArray,
    heatPumpId,
    heatPumpValue,
    hotWaterTankId,
    hotWaterTankValue,
    wirlessController,
    leaveExistingSource,
    flushingSystem,
    specialDiscountManagement,
    specialDiscountManager,
  ]);

  useEffect(() => {
    if (user.heat_pump?.length > 0) {
      setHeatPumps(user.heat_pump);
    }
    if (user.hot_water_tank?.length > 0) {
      setHotWaterTanks(user.hot_water_tank);
    }
  }, [user]);

  const handleOnChangeMargin = (e) => {
    if (e.target.value < 0) {
      setMargin(0);
    } else {
      setMargin(e.target.value);
    }
  };

  const handleOnChooseHeatPump = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value > 0) {
      setHeatPumpId(e.target.value);
    } else {
      setHeatPumpId(null);
    }
  };

  const handleOnHeatPumpValueChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value > 0) {
      setHeatPumpValue(e.target.value);
    } else {
      setHeatPumpValue(0);
    }
  };

  const handleOnChangeHotWaterTank = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value > 0) {
      setHotWaterTankId(e.target.value);
    } else {
      setHotWaterTankId(null);
    }
  };

  const handleOnChangeHotWaterTankValue = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (e.target.value > 0) {
      setHotWaterTankValue(e.target.value);
    } else {
      setHotWaterTankValue(0);
    }
  };

  const handleOnCheckedFlushingSystem = (e) => {
    setFlushingSystem(e.target.checked);
    setValue("flushing_system", e.target.checked);
  };
  const handleOnCheckedLeaveExistingSource = (e) => {
    setLeaveExistingSource(e.target.checked);
    setValue("leaving_existing_source", e.target.checked);
  };
  const handleOnCheckedWirlessController = (e) => {
    setWirlessController(e.target.checked);
    setValue("wirless_controller", e.target.checked);
  };

  return (
    <StyledWrapper>
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        {/* {!user.offer_margin && (
          <div style={{ padding: "10px 0" }}>
            <p style={{ color: "red", fontSize: "14px" }}>
              W ustawieniach profilu proszę wpisać warośc marży na 1 kWp
            </p>
          </div>
        )} */}
        <StyledInstalationInfoWrapper>
          <StyledInvestorInfoTitleWrapper>
            <StyledInvestorInfoTitle>Dane Instalacji:</StyledInvestorInfoTitle>
          </StyledInvestorInfoTitleWrapper>

          <StyledInstalationWrapper>
            <StyledHeatPump>
              <StyledInput>
                <div className="input-group">
                  <label>Pompa ciepła:</label>
                  <select onChange={handleOnChooseHeatPump}>
                    <option value="">Wybierz...</option>
                    {heatPumps.map((heatPump) => {
                      return (
                        <option key={heatPump.id} value={heatPump.id}>
                          {heatPump.brand} {heatPump.model} {heatPump.power} kW{" "}
                          {heatPump.type}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </StyledInput>
              <input type="hidden" {...register("heat_pump_id")} />
              {errors?.heat_pump_id && (
                <span className="error">{errors.instalation_id.message}</span>
              )}{" "}
            </StyledHeatPump>
            <StyledInstalationCountWrapper>
              <StyledInput>
                <div className="input-group">
                  <label>Ilość pomp ciepła</label>
                  <input
                    type="number"
                    value={heatPumpValue}
                    min={1}
                    onInput={handleOnHeatPumpValueChange}
                  />
                </div>{" "}
                <input type="hidden" {...register("heat_pump_count")} />
                {errors?.heat_pump_count && (
                  <span className="error">
                    {errors.heat_pump_count.message}
                  </span>
                )}
              </StyledInput>
            </StyledInstalationCountWrapper>
          </StyledInstalationWrapper>

          <StyledConstructionWrapper>
            <StyledConstruction>
              <StyledInput>
                <div className="input-group" style={{ flex: "1" }}>
                  <label>Zasobnik CWU:</label>
                  <select onChange={handleOnChangeHotWaterTank}>
                    <option value="">Wybierz...</option>
                    {hotWaterTanks.map((hotWaterTank) => {
                      return (
                        <option key={hotWaterTank.id} value={hotWaterTank.id}>
                          {hotWaterTank.name} {hotWaterTank.capacity} L
                        </option>
                      );
                    })}
                  </select>
                </div>{" "}
                <input type="hidden" {...register("hot_water_tank_id")} />
                {errors?.hot_water_tank_id && (
                  <span className="error">
                    {errors.hot_water_tank_id.message}
                  </span>
                )}
              </StyledInput>
            </StyledConstruction>
            <StyledConstructionCount>
              <StyledInput>
                <div className="input-group">
                  <label>Ilość zasobników CWU</label>
                  <input
                    type="number"
                    value={hotWaterTankValue}
                    onInput={handleOnChangeHotWaterTankValue}
                  />
                  <input type="hidden" {...register("hot_water_tank_count")} />
                </div>
                {errors?.hot_water_tank_count && (
                  <span className="error">
                    {errors.hot_water_tank_count.message}
                  </span>
                )}
              </StyledInput>
            </StyledConstructionCount>
          </StyledConstructionWrapper>

          <StyledDetailsWrapper>
            <StyledSectionTitleWrapper>
              Usługi dodatkowe:
            </StyledSectionTitleWrapper>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <StyledCheckBoxInput
                    type="checkbox"
                    checked={flushingSystem}
                    onChange={handleOnCheckedFlushingSystem}
                  />
                  Płukanie układu
                </StyledCheckBoxLabel>
                <input type="hidden" {...register("flushing_system")} />
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <StyledCheckBoxInput
                    type="checkbox"
                    checked={leaveExistingSource}
                    onChange={handleOnCheckedLeaveExistingSource}
                  />
                  Pozostawienie istniejącego źródła ciepła
                </StyledCheckBoxLabel>
                <input type="hidden" {...register("leaving_existing_source")} />
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <StyledCheckBoxInput
                    type="checkbox"
                    checked={wirlessController}
                    onChange={handleOnCheckedWirlessController}
                  />
                  Sterownik bezprzewodowy
                </StyledCheckBoxLabel>
                <input type="hidden" {...register("wirless_controller")} />
              </StyledInputGroup>
            </StyledCheckBox>

            {controlledFields.map((field, index) => {
              return (
                <StyledServicesOtherInput key={field.id}>
                  <StyledInputWrapper>
                    <StyledOtherServicesInputWrapper>
                      <input
                        type="text"
                        {...register(`services[${index}].name`)}
                      />
                    </StyledOtherServicesInputWrapper>{" "}
                    <StyledServicesInputWrapper>
                      <StyledServicesInputLabel>Cena</StyledServicesInputLabel>
                      <input
                        type="text"
                        {...register(`services[${index}].price`)}
                      />
                    </StyledServicesInputWrapper>
                    <StyledRemoveButtonWrapper>
                      <StyledRemoveButton onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faMinus} />
                      </StyledRemoveButton>
                    </StyledRemoveButtonWrapper>
                  </StyledInputWrapper>
                  <StyledErrorWrapper>
                    {errors?.services &&
                      errors?.services[index] &&
                      errors?.services[index].name && (
                        <span className="error">
                          {errors?.services[index].name.message}{" "}
                        </span>
                      )}
                    {errors?.services &&
                      errors?.services[index] &&
                      errors?.services[index].price && (
                        <span className="error">
                          {errors?.services[index].price.message}
                        </span>
                      )}
                  </StyledErrorWrapper>
                </StyledServicesOtherInput>
              );
            })}
            <StyledAddOtherServiceButton
              type="button"
              onClick={() => append({ name: "", price: "" })}
            >
              <FontAwesomeIcon icon={faPlus} />
            </StyledAddOtherServiceButton>
          </StyledDetailsWrapper>

          <StyledPriceWrapper>
            <StyledPriceLeftSide>
              <StyledInput>
                <div className="input-group">
                  <label>Cena instalacji (netto)</label>
                  <input type="text" {...register("price_netto")} readOnly />
                </div>
              </StyledInput>
              {errors?.price_netto && (
                <span className="error">{errors.price_netto.message}</span>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Stawka Vat</label>
                  <select
                    onChange={(e) => setVatRate(e.target.value)}
                    value={vatRate}
                  >
                    <option value="8">8%</option>
                    <option value="23">23%</option>
                  </select>
                </div>
              </StyledInput>
              <input type="hidden" {...register("vat")} />
              <input type="hidden" {...register("price_vat")} />
              {errors?.price_vat && (
                <span className="error">{errors.price_vat.message}</span>
              )}
            </StyledPriceLeftSide>
            <StyledPriceRightSide>
              <StyledInput>
                <div className="input-group">
                  <label>Marża</label>
                  <input
                    type="number"
                    min={0}
                    value={margin}
                    onChange={handleOnChangeMargin}
                  />
                  {/* <input type="hidden" {...register("special_discount")} /> */}
                  <input type="hidden" {...register("margin")} />
                </div>
              </StyledInput>
              {user?.role?.id !== 2 && (
                <StyledInput>
                  <div className="input-group">
                    <label>Rabat specjalny Dyrektor(netto)</label>
                    <input
                      min={0}
                      type="number"
                      value={specialDiscountManager}
                      onChange={(e) =>
                        setSpecialDiscountManager(e.target.value)
                      }
                    />
                  </div>
                  <input
                    type="hidden"
                    {...register("special_discount_manager")}
                  />
                </StyledInput>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Rabat specjalny Zarząd(netto)</label>
                  <input
                    type="number"
                    min={0}
                    value={specialDiscountManagement}
                    onChange={(e) =>
                      setSpecialDiscountManagement(e.target.value)
                    }
                  />
                </div>
                <input
                  type="hidden"
                  {...register("special_discount_management")}
                />
              </StyledInput>

              <StyledBruttoPriceInput>
                <div className="input-group">
                  <StyledBruttoPriceLabel>
                    Cena końcowa instalacji (brutto)
                  </StyledBruttoPriceLabel>
                  <input type="text" {...register("price_sum")} readOnly />
                </div>
              </StyledBruttoPriceInput>
              {errors?.price_sum && (
                <span className="error">{errors.price_sum.message}</span>
              )}
            </StyledPriceRightSide>
          </StyledPriceWrapper>
        </StyledInstalationInfoWrapper>
      </StyledForm>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  background: white;
  border-radius: 10px;
  min-width: 360px;
  padding: 10px;
`;

const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledBruttoPriceInput = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    input {
      border: none;
      border-bottom: 2px solid black;
      width: 100%;
      outline: none;
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const StyledBruttoPriceLabel = styled.label`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 16px;
  font-weight: bold;
  color: rgb(244, 118, 49);
`;

const StyledErrorWrapper = styled.div``;

const StyledInputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const StyledRemoveButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  height: 19px;
  border-radius: 5px;
  width: 100%;
`;
const StyledRemoveButtonWrapper = styled.div`
  margin-top: 20px;
  width: 60px;
`;
const StyledSectionTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #134771;
`;

const StyledServicesOtherInput = styled.div``;

const StyledOtherServicesInputWrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  position: relative;
  & input {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
`;
const StyledAddOtherServiceButton = styled.button`
  margin-top: 10px;
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
`;

const StyledServicesInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;

const StyledServicesInputWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  & input {
    width: 60px;
  }
`;

const StyledPriceRightSide = styled.div`
  flex: 1;
`;

const StyledPriceLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledDetailsWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledConstructionCount = styled.div`
  flex: 1;
`;

const StyledConstruction = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledConstructionWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;
const StyledHeatPump = styled.div`
  flex: 1;
  margin-right: 10px;
`;
const StyledInstalationCountWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const StyledInstalationWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledInstalationInfoWrapper = styled.div``;

const StyledInvestorInfoTitle = styled.p`
  color: #134771;
`;
const StyledInvestorInfoTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  max-width: 640px;
  min-width: 0;
  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .todo-input-group {
      width: 49%;

      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;

  margin-top: 25px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      padding: 10px;
      overflow-y: hidden;
      border-radius: 10px;
      outline: none;
    }
  }
`;

export default HeatPumpCalculator;
