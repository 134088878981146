import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddTeamsSetting from "./AddTeamsSetting";
import TeamsSettingItem from "./TeamSettingItem/TeamsSettingItem";

const TeamsSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState();

  const location = useLocation();
  const history = useHistory();

  const { teams, setTeams } = useContext(StoreContext);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(null);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleOpenEditTeams = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const fetchDataTeams = async () => {
    try {
      const { status, data } = await request.get("/api/team");
      if (status === 200) {
        await setTeams(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (teams.length === 0) {
      fetchDataTeams();
    }
  }, []);

  return (
    <StyledTeamsWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj zespół</StyledButton>
      </StyledButtonWrapper>
      <StyledTeamsTable>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Liczba użytkowników</th>
            <th width={"100px"}>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => {
            return (
              <TeamsSettingItem
                handleOpenEditTeams={handleOpenEditTeams}
                fetchDataTeams={fetchDataTeams}
                setEditObject={setEditObject}
                team={team}
                key={team.id}
              />
            );
          })}
        </tbody>
      </StyledTeamsTable>
      <AddTeamsSetting
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataTeams}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledTeamsWrapper>
  );
};

const StyledTeamsWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledTeamsTable = styled.table`
  width: 100%;
  padding: 40px 0;
  th {
    text-align: start;
    padding: 5px;
  }
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default TeamsSettings;
