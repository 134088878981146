import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Charger from "./Pages/Charger/Charger";
import ChargerAccessories from "./Pages/ChargerAccessories/ChargerAccessories";

const ChargerSettings = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <div>
        <StyledOfferSettingsNav>
          <ul>
            <StyledOfferSettingsNavItem>
              <NavLink exact to="/settings/charger/" activeClassName="active">
                Ładowarki
              </NavLink>
            </StyledOfferSettingsNavItem>
            <StyledOfferSettingsNavItem>
              <NavLink
                exact
                to="/settings/charger/accessories"
                activeClassName="active"
              >
                Akcesoria
              </NavLink>
            </StyledOfferSettingsNavItem>
          </ul>
        </StyledOfferSettingsNav>
      </div>

      <ContentContainer>
        <Switch>
          <Route exact path={`${path}/`}>
            <Charger />
          </Route>
          <Route path={`${path}/accessories`}>
            <ChargerAccessories />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  );
};

const StyledOfferSettingsNav = styled.nav`
  padding: 20px 40px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const StyledOfferSettingsNavItem = styled.li`
  list-style: none;
  a {
    display: block;
    padding: 10px 15px;
    font-size: 20px;
    text-decoration: none;
    color: #444;
    white-space: nowrap;
  }
  a.active {
    border-bottom: 4px solid #2d96db;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
`;
export default ChargerSettings;
