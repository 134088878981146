import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import request from "../../../../../../helpers/request";
import { StoreContext } from "../../../../../../store/StoreProvider";
import AddSaleFunnelItem from "./AddSaleFunnelItem/AddSaleFunnelItem";
import SalesFunnelItem from "./SalesFunnelItem.jsx/SalesFunnelItem";

const SalesFunnel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editObject, setEditObject] = useState();

  const location = useLocation();
  const history = useHistory();

  const { salesFunnel, setSalesFunnel } = useContext(StoreContext);

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setEditObject(null);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleOpenEditStatusTask = () => {
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const handleOnSwitchChange = async (id, data) => {
    const { status } = await request.patch(`/api/salesfunnel/${id}`, {
      value: data,
    });
    if (status === 200) {
      fetchDataSalesFunnel();
    }
  };

  const fetchDataSalesFunnel = async () => {
    try {
      const { status, data } = await request.get("/api/salesfunnel");
      if (status === 200 && data.data.length > 0) {
        await setSalesFunnel(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };
  useEffect(() => {
    if (salesFunnel.length === 0) {
      fetchDataSalesFunnel();
    }
  }, []);

  return (
    <StyledSalesFunnelWrapper>
      <StyledButtonWrapper>
        <StyledButton onClick={handleOpenModal}>Dodaj zadanie</StyledButton>
      </StyledButtonWrapper>
      <StyledSalesFunnelList>
        {salesFunnel.map((element) => {
          return (
            <SalesFunnelItem
              handleOnSwitchChange={handleOnSwitchChange}
              handleOpenEditStatusTask={handleOpenEditStatusTask}
              fetchDataSalesFunnel={fetchDataSalesFunnel}
              setEditObject={setEditObject}
              element={element}
              key={element.id}
            />
          );
        })}
      </StyledSalesFunnelList>
      <AddSaleFunnelItem
        isModalOpen={isModalOpen}
        handleOnClose={handleOnClose}
        fetchData={fetchDataSalesFunnel}
        isEditMode={isEditMode}
        editObject={editObject}
      />
    </StyledSalesFunnelWrapper>
  );
};

const StyledSalesFunnelWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;
`;

const StyledSalesFunnelList = styled.ul`
  padding: 40px 0;
`;

const StyledButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled.button`
  padding: 10px 15px;
  color: white;
  background: #2d96db;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default SalesFunnel;
