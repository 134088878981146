import React from "react";
import styled from "styled-components";

const DateFiltersItem = ({ name, dateFilters, setDateFilters, id }) => {
  const handleOnClick = () => {
    if (dateFilters === id) {
      setDateFilters(null);
    } else {
      setDateFilters(id);
    }
  };
  return (
    <StyledCollapseItemButton
      onClick={handleOnClick}
      className={dateFilters === id && "active"}
    >
      <p>{name}</p>
    </StyledCollapseItemButton>
  );
};

const StyledCollapseItemButton = styled.button`
  width: 100%;
  text-align: left;

  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

export default DateFiltersItem;
