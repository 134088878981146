import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AllCompanyDropdown from "./components/AllCompanyDropdown";

const ChooseUserFilter = ({
  departments,
  teams,
  name,
  setName,
  setUserFilter,
  setDepartmentFilter,
  setTeamFilter,
  isOpen,
  setIsOpen,
}) => {
  const [chooseUser, setChooseUser] = useState(null);
  const [chooseDepartment, setChooseDepartment] = useState(null);
  const [chooseTeam, setChooseTeam] = useState(null);
  const [chooseName, setChooseName] = useState("Cała polska");

  const handleOpenSelect = (e) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const handleOnChooseUser = (user) => {
    if (user.id === chooseUser) {
      setChooseName("Cała polska");
      setChooseUser(null);
      setChooseDepartment(null);
      setChooseTeam(null);
    } else if (user) {
      setChooseName(user.name);
      setChooseUser(user.id);
      setChooseDepartment(null);
      setChooseTeam(null);
    } else {
      setChooseName("Cała polska");
      setChooseUser(null);
      setChooseDepartment(null);
      setChooseTeam(null);
    }
  };

  const handleOnChooseDepartment = (department) => {
    if (department) {
      setChooseName(department.name);
      setChooseUser(null);
      setChooseDepartment(department.id);
      setChooseTeam(null);
    } else {
      setChooseName("Cała polska");
      setChooseUser(null);
      setChooseDepartment(null);
      setChooseTeam(null);
    }
  };

  const handleOnChooseTeam = (team) => {
    if (team) {
      setChooseName(team.name);
      setChooseUser(null);
      setChooseDepartment(null);
      setChooseTeam(team.id);
    } else {
      setChooseName("Cała polska");
      setChooseUser(null);
      setChooseDepartment(null);
      setChooseTeam(null);
    }
  };

  const handleOnConfirmSelection = () => {
    setName(chooseName);
    setUserFilter(chooseUser);
    setDepartmentFilter(chooseDepartment);
    setTeamFilter(chooseTeam);
    setIsOpen(false);
  };

  const dropRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  });
  return (
    <StyledWrapper ref={dropRef}>
      <StyledSelectButton onClick={handleOpenSelect}>
        {name}
        <StyledIconWrapper>
          {isOpen ? (
            <StyledAngleIcon icon={faChevronUp} />
          ) : (
            <StyledAngleIcon icon={faChevronDown} />
          )}
        </StyledIconWrapper>
      </StyledSelectButton>
      <StyledSelectWrapper
        style={isOpen ? { display: "block" } : { display: "none" }}
        isOpen={isOpen}
      >
        <AllCompanyDropdown
          departments={departments}
          teams={teams}
          handleOnChooseUser={handleOnChooseUser}
          handleOnChooseDepartment={handleOnChooseDepartment}
          handleOnChooseTeam={handleOnChooseTeam}
          chooseDepartment={chooseDepartment}
          chooseTeam={chooseTeam}
          chooseUser={chooseUser}
        />{" "}
        <StyledButtonWrapper>
          <StyledConfirmButton onClick={handleOnConfirmSelection}>
            Zatwierdź
          </StyledConfirmButton>
        </StyledButtonWrapper>
      </StyledSelectWrapper>
    </StyledWrapper>
  );
};

const StyledButtonWrapper = styled.div`
  padding: 10px;
`;

const StyledConfirmButton = styled.button`
  border: none;
  width: 100%;
  padding: 10px 5px;

  cursor: pointer;
  border-radius: 5px;
  background: #134771;
  color: rgb(202, 222, 238);
  &:hover {
    background: #053257;
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20px;
`;

const StyledSelectWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 1;
  padding: 10px 0;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;
const StyledAngleIcon = styled(FontAwesomeIcon)`
  font-size: 10px;
`;

const StyledWrapper = styled.div`
  width: 100%;
  margin-right: 10px;
`;

const StyledSelectButton = styled.button`
  width: 100%;
  position: relative;
  text-align: left;
  background-color: white;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgb(118, 118, 118);
  outline: none;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  cursor: pointer;
`;
export default ChooseUserFilter;
