import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import ItemDropDownDepartments from "./ItemDropDownDepartments";
import ItemDropDownTeams from "./ItemDropDownTeams";

const AllCompanyDropdown = ({
  departments,
  teams,
  handleOnChooseUser,
  handleOnChooseDepartment,
  handleOnChooseTeam,
  chooseDepartment,
  chooseTeam,
  chooseUser,
}) => {
  const [searchUserName, setSearchUserName] = useState("");
  const [searchedDepartment, setSearchedDepartment] = useState([]);
  const [seatchedTeams, setSearchedTeams] = useState([]);

  useEffect(() => {
    const useEffectDepartments = [...departments];
    const useEffectTeams = [...teams];
    let functionDepartments = [];
    let functionTeams = [];
    if (searchUserName.trim().length > 0) {
      functionDepartments = useEffectDepartments.map((department) => {
        const filterUsers = department.users.filter((user) =>
          user.name.toLowerCase().includes(searchUserName.trim().toLowerCase())
        );

        return { ...department, users: filterUsers };
      });

      functionTeams = useEffectTeams.map((team) => {
        const filterUsers = team.users.filter((user) =>
          user.name.toLowerCase().includes(searchUserName.trim().toLowerCase())
        );

        return { ...team, users: filterUsers };
      });
    } else {
      functionDepartments = [...useEffectDepartments];
      functionTeams = [...useEffectTeams];
    }
    setSearchedDepartment(
      functionDepartments.filter((departments) => departments.users.length > 0)
    );
    setSearchedTeams(functionTeams.filter((team) => team.users.length > 0));
  }, [searchUserName, departments, teams]);

  return (
    <StyledWrapper>
      <StyledSearchWrapper>
        <StyledSearchInput
          type="text"
          value={searchUserName}
          onChange={(e) => setSearchUserName(e.target.value)}
        />
        <StyledSearchIconWrapper>
          <StyledSearchIcon icon={faSearch} />
        </StyledSearchIconWrapper>
      </StyledSearchWrapper>
      <StyledItemWrapper>
        {searchedDepartment &&
          searchedDepartment.map((department) => {
            return (
              <ItemDropDownDepartments
                key={department.id}
                department={department}
                handleOnChooseUser={handleOnChooseUser}
                handleOnChooseDepartment={handleOnChooseDepartment}
                searchUserName={searchUserName}
                chooseDepartment={chooseDepartment}
                chooseTeam={chooseTeam}
                chooseUser={chooseUser}
              />
            );
          })}
        {seatchedTeams &&
          seatchedTeams.map((team) => {
            return (
              <ItemDropDownTeams
                key={team.id}
                team={team}
                handleOnChooseUser={handleOnChooseUser}
                handleOnChooseTeam={handleOnChooseTeam}
                searchUserName={searchUserName}
                chooseDepartment={chooseDepartment}
                chooseTeam={chooseTeam}
                chooseUser={chooseUser}
              />
            );
          })}
      </StyledItemWrapper>
    </StyledWrapper>
  );
};

const StyledSearchIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 0 5px 5px 0;
  border-left: none;
  padding: 2px;
`;

const StyledSearchIcon = styled(FontAwesomeIcon)``;

const StyledSearchInput = styled.input`
  border: 1px solid black;
  border-radius: 5px 0 0 5px;
  border-right: none;
  flex: 1;
  padding: 2px;
`;

const StyledSearchWrapper = styled.div`
  display: flex;
  padding: 0 10px;
`;

const StyledItemWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const StyledWrapper = styled.div``;

export default AllCompanyDropdown;
