import React, { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../store/StoreProvider";

import ApproveFilter from "./ApproveFilter/ApproveFilter";
import CollapseVoivodeship from "./CollapseVoivodeship/CollapseVoivodehip";
import RejectedFilter from "./RejectedFilter/RejectedFilter";

const HeatPumpApproveAside = ({
  setVoivodeshipFilter,
  voivodeshipFilter,
  showApproveFilter,
  setShowApproveFilter,
  showRejectedFilter,
  setShowRejectedFilter,
}) => {
  const { user } = useContext(StoreContext);

  return (
    <StyledAside>
      <ApproveFilter
        showApproveFilter={showApproveFilter}
        setShowApproveFilter={setShowApproveFilter}
      />
      <RejectedFilter
        showRejectedFilter={showRejectedFilter}
        setShowRejectedFilter={setShowRejectedFilter}
      />
      {user.role?.id === 1 && (
        <CollapseVoivodeship
          setVoivodeshipFilter={setVoivodeshipFilter}
          voivodeshipFilter={voivodeshipFilter}
        />
      )}
    </StyledAside>
  );
};

const StyledAside = styled.div`
  min-width: 200px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export default HeatPumpApproveAside;
