import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Modal from "../../../../../../../../../components/Modal";
import request from "../../../../../../../../../helpers/request";
import { StoreContext } from "../../../../../../../../../store/StoreProvider";

const DataUpdateAnnex = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  opportunities,
  contract,
}) => {
  const { user } = useContext(StoreContext);
  const [paymentType, setPaymentType] = useState(1);
  const [vatRateType, setVatRateType] = useState(1);
  const [roofType, setRoofType] = useState(1);
  const [cutMethod, setCutMethod] = useState(1);
  const [inverterMountingLocation, setInverterMountingLocation] = useState(1);
  const [instalationType, setInstalationType] = useState(1);
  const [propertyStatus, setPropertyStatus] = useState(1);
  const [powerConnection, setPowerConnection] = useState(0);
  const [electricitySuppiler, setElectricitySuppiler] = useState(1);
  const [dimensionAre, setDimensionAre] = useState(1);
  const [buildingReceived, setBuildingReceived] = useState(1);
  const [roofInstallation, setRoofInstallation] = useState(1);
  const [roofInsulation, setRoofInsulation] = useState(1);
  const [lightningProtection, setLightningProtection] = useState(0);
  const [roofTypeSelect, setRoofTypeSelect] = useState(1);
  const [energeticJoinChange, setEnergeticJoinChange] = useState(0);
  const [steelTileType, setSteelTileType] = useState(null);
  const [tileType, setTileType] = useState(null);
  const [otherTileType, setOtherTileType] = useState(null);
  const [roofDesign, setRoofDesign] = useState(1);
  const [additionalArrangements, setAdditionalArrangements] = useState(null);
  const [chooseProxyPeople, setChooseProxyPeople] = useState(1);
  const [meterInstallationPlace, setMeterInstallationPlace] = useState(0);

  const [isSended, setIsSended] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const { register, handleSubmit, reset, setValue, control } = useForm();

  const resetInputValue = () => {
    reset({
      pesel: "",
    });
  };

  const handleOnSubmitwithClose = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.post(`api/make-contract`, {
        ...data,
      });
      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  useEffect(() => {
    if (opportunities) {
      if (opportunities.company) {
        setValue("is_company", true);
      } else setValue("is_company", false);

      setValue("investor_name_1", opportunities.owner_details?.investor_name_1);
      setValue(
        "investor_pesel_1",
        opportunities.owner_details?.investor_pesel_1
      );
      setValue(
        "investor_phone_1",
        opportunities.owner_details?.investor_phone_1
      );
      setValue(
        "investor_id_card_1",
        opportunities.owner_details?.investor_id_card_1
      );
      setValue(
        "investor_email_1",
        opportunities.owner_details?.investor_email_1
      );

      setValue("investor_street", opportunities.owner_details?.street);
      setValue("investor_city", opportunities.owner_details?.city);
      setValue(
        "investor_building_number",
        opportunities.owner_details?.building_number
      );
      setValue("investor_post", opportunities.owner_details?.post);
      setValue("investor_post_code", opportunities.owner_details?.post_code);

      setValue("investor_name_2", opportunities.owner_details?.investor_name_2);
      setValue(
        "investor_pesel_2",
        opportunities.owner_details?.investor_pesel_2
      );
      setValue(
        "investor_phone_2",
        opportunities.owner_details?.investor_phone_2
      );
      setValue(
        "investor_id_card_2",
        opportunities.owner_details?.investor_id_card_2
      );
      setValue(
        "investor_email_2",
        opportunities.owner_details?.investor_email_2
      );
      setValue("investor_NIP_1", opportunities.owner_details?.investor_NIP_1);
      setValue("investor_krs", opportunities.owner_details?.investor_krs);
      setValue("investor_regon", opportunities.owner_details?.investor_regon);
      setValue(
        "coordinator_name",
        opportunities.owner_details?.coordinator_name ||
          opportunities.owner_details?.investor_name_2
      );
      setValue(
        "coordinator_phone",
        opportunities.owner_details?.coordinator_phone ||
          opportunities.owner_details?.investor_phone_2
      );

      setValue("opportunities_id", opportunities.id);
      setValue("property_street", opportunities.property_details?.street);
      setValue(
        "property_building_number",
        opportunities.property_details?.building_number
      );
      setValue("property_post_code", opportunities.property_details?.post_code);
      setValue("property_post", opportunities.property_details?.post);
      setValue("property_city", opportunities.property_details?.city);
      setValue("property_community", opportunities.property_details?.community);
      setValue("property_district", opportunities.property_details?.district);
      setValue(
        "property_status",
        opportunities.property_details?.property_status
      );
      setPropertyStatus(
        opportunities.property_details?.property_status
          ? Number(opportunities.property_details?.property_status)
          : 1
      );
      setValue(
        "plot_registration_number",
        opportunities.property_details?.plot_registration_number
      );
      setValue(
        "usable_area",
        opportunities.contract_terms_details?.usable_area
      );
      setValue(
        "vat_rate_type",
        opportunities.contract_terms_details?.vat_rate_type
      );
      setVatRateType(
        opportunities.contract_terms_details?.vat_rate_type
          ? Number(opportunities.contract_terms_details?.vat_rate_type)
          : 1
      );

      setValue(
        "realization_date",
        opportunities.contract_terms_details?.realization_date
          ? Number(opportunities.contract_terms_details?.realization_date)
          : 10
      );
      setValue(
        "payment_type",
        Number(opportunities.contract_terms_details?.payment_type)
      );
      setPaymentType(
        opportunities.contract_terms_details?.payment_type
          ? Number(opportunities.contract_terms_details?.payment_type)
          : 1
      );
      setValue(
        "additional_arrangements",
        opportunities.contract_terms_details?.additional_arrangements
      );
      setAdditionalArrangements(
        opportunities.contract_terms_details?.additional_arrangements
      );

      setValue(
        "connection_capacity_change",
        opportunities.energetic_join_details?.connection_capacity_change
      );
      setValue(
        "meter_installation_place",
        opportunities.energetic_join_details?.meter_installation_place
      );
      setMeterInstallationPlace(
        Number(opportunities.energetic_join_details?.meter_installation_place)
      );
      setValue(
        "contracted_power",
        opportunities.energetic_join_details?.contracted_power
      );
      setValue(
        "energetic_join_details_other",
        opportunities.energetic_join_details?.energetic_join_details_other
      );
      setValue(
        "counter_number",
        opportunities.energetic_join_details?.counter_number
      );
      setValue(
        "data_update",
        opportunities.energetic_join_details?.data_update
      );
      setValue(
        "electricity_supplier",
        Number(opportunities.energetic_join_details?.electricity_supplier)
      );
      setElectricitySuppiler(
        opportunities.energetic_join_details?.electricity_supplier
          ? Number(opportunities.energetic_join_details?.electricity_supplier)
          : 1
      );
      setValue(
        "electricity_supplier_other",
        opportunities.energetic_join_details?.electricity_supplier_other
      );
      setValue(
        "energetic_join_details_change",
        Number(
          opportunities.energetic_join_details?.energetic_join_details_change
        )
      );
      setEnergeticJoinChange(
        opportunities.energetic_join_details?.energetic_join_details_change
          ? Number(
              opportunities.energetic_join_details
                ?.energetic_join_details_change
            )
          : 0
      );
      setValue(
        "energetic_join_details",
        opportunities.energetic_join_details?.energetic_join_details
      );
      setValue(
        "energy_consumption_point",
        opportunities.energetic_join_details?.energy_consumption_point
      );
      setValue(
        "instalation_type",
        Number(opportunities.energetic_join_details?.instalation_type)
      );
      setInstalationType(
        opportunities.energetic_join_details?.instalation_type
          ? Number(opportunities.energetic_join_details?.instalation_type)
          : 0
      );
      setValue(
        "merge_counters",
        opportunities.energetic_join_details?.merge_counters
      );
      setValue(
        "metering_protection_value",
        opportunities.energetic_join_details?.metering_protection_value
      );
      setValue(
        "phaze_change",
        opportunities.energetic_join_details?.phaze_change
      );
      setValue(
        "power_connection",
        Number(opportunities.energetic_join_details?.power_connection)
      );
      setPowerConnection(
        opportunities.energetic_join_details?.power_connection
          ? Number(opportunities.energetic_join_details?.power_connection)
          : 1
      );
      setValue(
        "power_connection_power",
        opportunities.energetic_join_details?.power_connection_power
      );
      setValue(
        "rewriting_the_counter",
        opportunities.energetic_join_details?.rewriting_the_counter
      );
      setValue(
        "people_count",
        opportunities.energetic_join_details?.people_count
      );
      setChooseProxyPeople(
        Number(opportunities.energetic_join_details?.people_count)
      );
      setValue(
        "proxy_id_card_1",
        opportunities.energetic_join_details?.proxy_id_card_1
      );
      setValue(
        "proxy_id_card_2",
        opportunities.energetic_join_details?.proxy_id_card_2
      );
      setValue(
        "proxy_name_1",
        opportunities.energetic_join_details?.proxy_name_1
      );
      setValue(
        "proxy_name_2",
        opportunities.energetic_join_details?.proxy_name_2
      );
      setValue(
        "proxy_pesel_1",
        opportunities.energetic_join_details?.proxy_pesel_1
      );
      setValue(
        "proxy_pesel_2",
        opportunities.energetic_join_details?.proxy_pesel_2
      );
      setValue("proxy_street", opportunities.energetic_join_details?.street);
      setValue(
        "proxy_building_number",
        opportunities.energetic_join_details?.building_number
      );
      setValue("proxy_city", opportunities.energetic_join_details?.city);
      setValue(
        "proxy_post_code",
        opportunities.energetic_join_details?.post_code
      );
      setValue("proxy_post", opportunities.energetic_join_details?.post);

      setValue(
        "building_height",
        opportunities.assembly_details?.building_height
      );
      setValue(
        "building_length",
        opportunities.assembly_details?.building_length
      );
      setValue(
        "building_width",
        opportunities.assembly_details?.building_width
      );
      setValue(
        "building_received",
        Number(opportunities.assembly_details?.building_received)
      );
      setBuildingReceived(
        opportunities.assembly_details?.building_received
          ? Number(opportunities.assembly_details?.building_received)
          : 1
      );
      setValue("cut_method", opportunities.assembly_details?.cut_method);
      setCutMethod(Number(opportunities.assembly_details?.cut_method));
      setValue(
        "dimension_are",
        Number(opportunities.assembly_details?.dimension_are)
      );
      setDimensionAre(
        opportunities.assembly_details?.dimension_are
          ? Number(opportunities.assembly_details?.dimension_are)
          : 1
      );
      setValue(
        "excavator_entry",
        opportunities.assembly_details?.excavator_entry
      );
      setValue(
        "inverter_mounting_location",
        Number(opportunities.assembly_details?.inverter_mounting_location)
      );
      setInverterMountingLocation(
        Number(opportunities.assembly_details?.inverter_mounting_location)
      );
      setValue(
        "inverter_mounting_location_description_1",
        opportunities.assembly_details?.inverter_mounting_location_description_1
      );
      setValue(
        "inverter_mounting_location_description_2",
        opportunities.assembly_details?.inverter_mounting_location_description_2
      );
      setValue(
        "lightning_protection",
        Number(opportunities.assembly_details?.lightning_protection)
      );
      setLightningProtection(
        Number(opportunities.assembly_details?.lightning_protection)
      );
      setValue(
        "media_connections_energetic_join",
        Number(opportunities.assembly_details?.media_connections_energetic_join)
      );
      setValue(
        "media_connections_gas",
        Number(opportunities.assembly_details?.media_connections_gas)
      );
      setValue(
        "media_connections_optical_fiber",
        Number(opportunities.assembly_details?.media_connections_optical_fiber)
      );
      setValue(
        "media_connections_other",
        opportunities.assembly_details?.media_connections_other
      );
      setValue(
        "media_connections_rain_sawage",
        Number(opportunities.assembly_details?.media_connections_rain_sawage)
      );
      setValue(
        "media_connections_sewage_treatment_plan",
        Number(
          opportunities.assembly_details
            ?.media_connections_sewage_treatment_plan
        )
      );
      setValue(
        "media_connections_sewers",
        Number(opportunities.assembly_details?.media_connections_sewers)
      );
      setValue(
        "media_connections_tel_connection",
        Number(opportunities.assembly_details?.media_connections_tel_connection)
      );
      setValue(
        "media_connections_water",
        Number(opportunities.assembly_details?.media_connections_water)
      );
      setValue(
        "number_of_floors",
        opportunities.assembly_details?.number_of_floors
      );
      setValue("other_tile", opportunities.assembly_details?.other_tile);
      setOtherTileType(
        opportunities.assembly_details?.other_tile
          ? Number(opportunities.assembly_details?.other_tile)
          : null
      );
      setValue(
        "other_tile_other",
        opportunities.assembly_details?.other_tile_other
      );
      setValue("roof_angle", opportunities.assembly_details?.roof_angle);
      setValue("roof_design", opportunities.assembly_details?.roof_design);
      setRoofDesign(Number(opportunities.assembly_details?.roof_design));
      setValue(
        "roof_edge_length",
        opportunities.assembly_details?.roof_edge_length
      );
      setValue(
        "roof_installation",
        Number(opportunities.assembly_details?.roof_installation)
      );
      setRoofInstallation(
        opportunities.assembly_details?.roof_installation
          ? Number(opportunities.assembly_details?.roof_installation)
          : 1
      );
      setValue(
        "roof_installation_other",
        opportunities.assembly_details?.roof_installation_other
      );
      setValue(
        "roof_insulation",
        opportunities.assembly_details?.roof_insulation
      );

      setRoofInsulation(
        Number(opportunities.assembly_details?.roof_insulation)
      );
      setValue("roof_type", opportunities.assembly_details?.roof_type);
      setRoofTypeSelect(Number(opportunities.assembly_details?.roof_type));
      setValue(
        "roof_type_other",
        opportunities.assembly_details?.roof_type_other
      );
      setValue(
        "roof_type_information",
        Number(opportunities.assembly_details?.roof_type_information)
      );
      setRoofType(
        Number(opportunities.assembly_details?.roof_type_information)
      );

      setValue(
        "side_one_length",
        opportunities.assembly_details?.side_one_length
      );
      setValue(
        "side_two_length",
        opportunities.assembly_details?.side_two_length
      );
      setValue(
        "soil_type_substrate",
        opportunities.assembly_details?.soil_type_substrate
      );
      setValue(
        "soil_type_substrate_other",
        opportunities.assembly_details?.soil_type_substrate_other
      );
      setValue("steel_tile", opportunities.assembly_details?.steel_tile);
      setSteelTileType(
        opportunities.assembly_details?.steel_tile
          ? Number(opportunities.assembly_details?.steel_tile)
          : null
      );

      setValue("tile", opportunities.assembly_details?.tile);
      setTileType(
        opportunities.assembly_details?.tile
          ? Number(opportunities.assembly_details?.tile)
          : null
      );

      setValue(
        "tile_dimension",
        opportunities.assembly_details?.tile_dimension
      );
      setValue(
        "total_usable_area",
        opportunities.assembly_details?.total_usable_area
      );
      setValue("volume", opportunities.assembly_details?.volume);
      setValue(
        "dc_wire_length",
        Number(opportunities.assembly_details?.dc_wire_length)
      );
      setValue(
        "ac_wire_length",
        Number(opportunities.assembly_details?.ac_wire_length)
      );
      setValue(
        "cut_length",
        Number(opportunities.assembly_details?.cut_length)
      );

      setValue(
        "cube_dismantle_length",
        Number(opportunities.assembly_details?.cube_dismantle_length)
      );
    }
  }, [user, opportunities]);

  useEffect(() => {
    if (opportunities.owner_details) {
      if (
        opportunities?.owner_details.city &&
        opportunities?.owner_details.building_number &&
        opportunities?.owner_details.post_code &&
        opportunities?.owner_details.post &&
        opportunities?.owner_details.street
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street} ${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.city} ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.post}`
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.building_number &&
        opportunities?.owner_details?.post &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `${opportunities?.owner_details?.city} ${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.post} `
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.street &&
        opportunities?.owner_details?.post &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street} ${opportunities?.owner_details?.city}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.post}`
        );
      } else if (
        opportunities?.owner_details.city &&
        opportunities?.owner_details.building_number &&
        opportunities?.owner_details.post_code &&
        opportunities?.owner_details.street
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street} ${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.city}`
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.building_number &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `${opportunities?.owner_details?.building_number}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.city} `
        );
      } else if (
        opportunities?.owner_details?.city &&
        opportunities?.owner_details?.street &&
        opportunities?.owner_details?.post_code
      ) {
        setValue(
          "client_address",
          `ul. ${opportunities?.owner_details?.street}, ${opportunities?.owner_details?.post_code} ${opportunities?.owner_details?.city}`
        );
      } else {
        setValue("client_address", null);
      }
    }

    if (opportunities?.property_details) {
      if (
        opportunities?.property_details.city &&
        opportunities?.property_details.building_number &&
        opportunities?.property_details.post_code &&
        opportunities?.property_details.post &&
        opportunities?.property_details.street
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street} ${opportunities?.property_details?.building_number} ${opportunities?.property_details?.city}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.post}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.building_number &&
        opportunities?.property_details?.post &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `${opportunities?.property_details?.building_number} ${opportunities?.property_details?.city} ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.post}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.street &&
        opportunities?.property_details?.post &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street} ${opportunities?.property_details?.city}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.post}`
        );
      } else if (
        opportunities?.property_details.city &&
        opportunities?.property_details.building_number &&
        opportunities?.property_details.post_code &&
        opportunities?.property_details.street
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street} ${opportunities?.property_details?.building_number}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.city}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.building_number &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `${opportunities?.property_details?.post_code} ${opportunities?.property_details?.city} ${opportunities?.property_details?.building_number}`
        );
      } else if (
        opportunities?.property_details?.city &&
        opportunities?.property_details?.street &&
        opportunities?.property_details?.post_code
      ) {
        setValue(
          "montage_address",
          `ul. ${opportunities?.property_details?.street}, ${opportunities?.property_details?.post_code} ${opportunities?.property_details?.city}`
        );
      } else {
        setValue("montage_address", null);
      }
    }
  }, [opportunities.property_details, isModalOpen]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledCancelButton onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCancelButton>
        <input type="hidden" {...register("opportunity_id")} />
        <input type="hidden" {...register("offer_id")} />

        {opportunities.company ? (
          <>
            <StyledTitleWrapper>Dane firmy</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>Nazwa firmy</label>
                <input type="text" {...register("investor_name_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Email</label>
                <input type="text" {...register("investor_email_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Telefon</label>
                <input type="text" {...register("investor_phone_1")} readOnly />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>NIP</label>
                <input type="text" {...register("investor_NIP_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Numer KRS</label>
                <input type="text" {...register("investor_krs")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>REGON</label>
                <input type="text" {...register("investor_regon")} readOnly />
              </div>
            </StyledInput>
          </>
        ) : (
          <>
            <StyledTitleWrapper>Dane właściciela</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>Imię i nazwisko</label>
                <input type="text" {...register("investor_name_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Email</label>
                <input type="text" {...register("investor_email_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Telefon</label>
                <input type="text" {...register("investor_phone_1")} readOnly />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Seria i numer dowodu osobistego</label>
                <input
                  type="text"
                  {...register("investor_id_card_1")}
                  readOnly
                />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Pesel</label>
                <input type="text" {...register("investor_pesel_1")} readOnly />
              </div>
            </StyledInput>
          </>
        )}

        {opportunities.company ? (
          <StyledTitleWrapper>Adres firmy</StyledTitleWrapper>
        ) : (
          <StyledTitleWrapper>Adres właściciela</StyledTitleWrapper>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input type="text" {...register("investor_street")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("investor_building_number")}
              readOnly
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input type="text" {...register("investor_post_code")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input type="text" {...register("investor_post")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input type="text" {...register("investor_city")} readOnly />
          </div>
        </StyledInput>

        {opportunities.company ? (
          <StyledTitleWrapper>Dane właściciela</StyledTitleWrapper>
        ) : (
          <StyledTitleWrapper>Dane współwłaściciela</StyledTitleWrapper>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Imię i nazwisko</label>
            <input type="text" {...register("investor_name_2")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Telefon</label>
            <input type="text" {...register("investor_phone_2")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Seria i numer dowodu osobistego</label>
            <input type="text" {...register("investor_id_card_2")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Pesel</label>
            <input type="text" {...register("investor_pesel_2")} readOnly />
          </div>
        </StyledInput>

        {opportunities.company && (
          <>
            <StyledTitleWrapper>Dane koordynatora</StyledTitleWrapper>

            <StyledInput>
              <div className="input-group">
                <label>Imię i nazwisko</label>
                <input type="text" {...register("coordinator_name")} readOnly />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Telefon</label>
                <input
                  type="text"
                  {...register("coordinator_phone")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}

        <StyledTitleWrapper>Dane nieruchomości</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input type="text" {...register("property_street")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input
              type="text"
              {...register("property_building_number")}
              readOnly
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy</label>
            <input type="text" {...register("property_post_code")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Poczta</label>
            <input type="text" {...register("property_post")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input type="text" {...register("property_city")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Gmina</label>
            <input type="text" {...register("property_community")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Powiat</label>
            <input type="text" {...register("property_district")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Status nieruchomości</label>
            <input type="hidden" {...register("property_status")} />
            <StyledOptionWrapper>
              {propertyStatus === 1 && (
                <StyledOptionItem>Własność</StyledOptionItem>
              )}
              {propertyStatus === 2 && (
                <StyledOptionItem>Współwłasność</StyledOptionItem>
              )}
              {propertyStatus === 3 && (
                <StyledOptionItem>Inne</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Numer ewidencyjny działki</label>
            <input
              {...register("plot_registration_number")}
              type="text"
              readOnly
            />
          </div>
        </StyledInput>

        <input type="hidden" {...register("montage_address")} />
        <input type="hidden" {...register("client_address")} />
        <input type="hidden" {...register("contract_number")} />
        <input type="hidden" {...register("offer_cut_length")} />
        <input type="hidden" {...register("offer_cut_price")} />
        <input type="hidden" {...register("offer_wire_length")} />
        <input type="hidden" {...register("offer_wire_price")} />

        <StyledTitleWrapper>Dane przyłącza energetycznego:</StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Przyłącze energetyczne</label>
            <input type="hidden" {...register("power_connection")} />
            <StyledOptionWrapper>
              {powerConnection === 1 && (
                <StyledOptionItem>Napowietrzne</StyledOptionItem>
              )}
              {powerConnection === 2 && (
                <StyledOptionItem>Kablowe</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Miejsce zainstalowania licznika</label>
            <input type="hidden" {...register("meter_installation_place")} />
            <StyledOptionWrapper>
              {meterInstallationPlace === 0 && (
                <StyledOptionItem>Nie wybrano</StyledOptionItem>
              )}
              {meterInstallationPlace === 1 && (
                <StyledOptionItem>w ogrodzeniu</StyledOptionItem>
              )}
              {meterInstallationPlace === 2 && (
                <StyledOptionItem>w budynku</StyledOptionItem>
              )}
              {meterInstallationPlace === 3 && (
                <StyledOptionItem>na elewacji budynku</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Dostawca energii elektrycznej</label>
            <input type="hidden" {...register("electricity_supplier")} />

            <StyledOptionWrapper>
              {electricitySuppiler === 1 && (
                <StyledOptionItem>PGE</StyledOptionItem>
              )}
              {electricitySuppiler === 2 && (
                <StyledOptionItem>Tauron</StyledOptionItem>
              )}
              {electricitySuppiler === 3 && (
                <StyledOptionItem>Enea</StyledOptionItem>
              )}
              {electricitySuppiler === 4 && (
                <StyledOptionItem>Energa</StyledOptionItem>
              )}
              {electricitySuppiler === 5 && (
                <StyledOptionItem>Innogy</StyledOptionItem>
              )}
              {electricitySuppiler === 6 && (
                <StyledOptionItem>Inne</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>

        {Number(
          opportunities.client?.energetic_join_details?.electricity_supplier
        ) === 6 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Dostawca energii elektrycznej inny </label>
                <input
                  type="text"
                  {...register("electricity_suppiler_other")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Wartość zabezpieczenia przedlicznikowego (A)</label>
            <input
              type="text"
              {...register("metering_protection_value")}
              readOnly
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Moc przyłączeniowa (kW)</label>
            <input
              type="text"
              {...register("power_connection_power")}
              readOnly
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Moc umowna (kW)</label>
            <input type="text" {...register("contracted_power")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Nr PPE (Punkt Poboru Energii)</label>
            <input
              type="text"
              {...register("energy_consumption_point")}
              readOnly
            />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Nr Licznika</label>
            <input type="text" {...register("counter_number")} readOnly />
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Typ instalacji</label>
            <input type="hidden" {...register("instalation_type")} />
            <StyledOptionWrapper>
              {instalationType === 1 && (
                <StyledOptionItem>Instalacja 1-fazowa</StyledOptionItem>
              )}
              {instalationType === 2 && (
                <StyledOptionItem>Instalacja 3-fazowa</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Dane przyłącza energetycznego</label>
            <input
              type="hidden"
              {...register("energetic_join_details_change")}
            />
            <StyledOptionWrapper>
              {energeticJoinChange === 0 && (
                <StyledOptionItem>Nie ulegną zmianie</StyledOptionItem>
              )}
              {energeticJoinChange === 1 && (
                <StyledOptionItem>Wymagane zmiany</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>
        {energeticJoinChange !== 0 && (
          <>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <StyledCheckBoxInput
                    type="checkbox"
                    {...register("data_update")}
                  />{" "}
                  Aktualizacja danych
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="rewriting_the_counter"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przepisanie licznika
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="connection_capacity_change"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Zmiana mocy przyłączeniowej
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="merge_counters"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Scalenie liczników
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="phaze_change"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Zmiana faz
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>{" "}
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="energetic_join_details_other"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Inne
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledInput>
              <div className="input-group">
                <label>Opis:</label>
                <input
                  type="text"
                  {...register("energetic_join_details")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}

        <StyledTitleWrapper>Dane do pełnomocnictwa</StyledTitleWrapper>

        {chooseProxyPeople === 1 && opportunities.company && (
          <>
            Osoba reprezentująca
            <StyledInput>
              <div className="input-group">
                <label>Imię i nazwisko</label>
                <input type="text" {...register("proxy_name_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Seria i numer dowodu osobistego</label>
                <input type="text" {...register("proxy_id_card_1")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Pesel</label>
                <input type="text" {...register("proxy_pesel_1")} readOnly />
              </div>
            </StyledInput>
          </>
        )}

        {(chooseProxyPeople === 1 || chooseProxyPeople === 2) &&
          !opportunities.company && (
            <>
              Pierwszy nabywca z faktury
              <StyledInput>
                <div className="input-group">
                  <label>Imię i nazwisko</label>
                  <input type="text" {...register("proxy_name_1")} readOnly />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Seria i numer dowodu osobistego</label>
                  <input
                    type="text"
                    {...register("proxy_id_card_1")}
                    readOnly
                  />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Pesel</label>
                  <input type="text" {...register("proxy_pesel_1")} readOnly />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Ulica</label>
                  <input type="text" {...register("proxy_street")} readOnly />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Numer budynku</label>
                  <input
                    type="text"
                    {...register("proxy_building_number")}
                    readOnly
                  />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Kod pocztowy</label>
                  <input
                    type="text"
                    {...register("proxy_post_code")}
                    readOnly
                  />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Poczta</label>
                  <input type="text" {...register("proxy_post")} readOnly />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Miejscowość</label>
                  <input type="text" {...register("proxy_city")} readOnly />
                </div>
              </StyledInput>
            </>
          )}

        {chooseProxyPeople === 2 && (
          <>
            Drugi nabywca z faktury
            <StyledInput>
              <div className="input-group">
                <label>Imię i nazwisko</label>
                <input type="text" {...register("proxy_name_2")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Seria i numer dowodu osobistego</label>
                <input type="text" {...register("proxy_id_card_2")} readOnly />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Pesel</label>
                <input type="text" {...register("proxy_pesel_2")} readOnly />
              </div>
            </StyledInput>
          </>
        )}

        <StyledTitleWrapper>Ustalenia montażowe</StyledTitleWrapper>

        <StyledInput>
          <div className="input-group">
            <label>Miejsce montażu paneli</label>
            <input type="hidden" {...register("roof_installation")} />
            <StyledOptionWrapper>
              {roofInstallation === 1 && (
                <StyledOptionItem>Dom mieszkalny</StyledOptionItem>
              )}
              {roofInstallation === 2 && (
                <StyledOptionItem>Budynek gospodarczy</StyledOptionItem>
              )}
              {roofInstallation === 3 && (
                <StyledOptionItem>Instalacja naziemna</StyledOptionItem>
              )}
              {roofInstallation === 4 && (
                <StyledOptionItem>Inne</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>

        {roofInstallation !== 3 && (
          <>
            <StyledTitleWrapper>Wymiary budynku</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>
                  Czy istnieje możliwość dostarczenia projektu dachu
                </label>
                <StyledOptionWrapper>
                  {roofDesign === 1 && <StyledOptionItem>Tak</StyledOptionItem>}
                  {roofDesign === 2 && <StyledOptionItem>Nie</StyledOptionItem>}
                </StyledOptionWrapper>
                <input type="hidden" {...register("roof_design")} />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Długość budynku (m)</label>
                <input
                  type="number"
                  {...register("building_length")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Szerokość budynku (m)</label>
                <input type="number" {...register("building_width")} readOnly />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Wysokość do krawędzi dachu</label>
                <input
                  type="number"
                  {...register("building_height")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Długość krawędzi dachu (m)</label>
                <input
                  type="number"
                  {...register("roof_edge_length")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Kąt pochylenia dachu ( &deg; )</label>
                <input type="number" {...register("roof_angle")} readOnly />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Wymiary zostały </label>
                <input type="hidden" {...register("dimension_are")} />
                <StyledOptionWrapper>
                  {dimensionAre === 1 && (
                    <StyledOptionItem>Zmierzone</StyledOptionItem>
                  )}
                  {dimensionAre === 2 && (
                    <StyledOptionItem>Oszacowane</StyledOptionItem>
                  )}
                </StyledOptionWrapper>
              </div>
            </StyledInput>
          </>
        )}

        <StyledTitleWrapper>Dane montażowe</StyledTitleWrapper>

        <StyledInput>
          <div className="input-group">
            <label>Budynek odebrany</label>
            <input type="hidden" {...register("building_received")} />
            <StyledOptionWrapper>
              {buildingReceived === 1 && (
                <StyledOptionItem>Tak</StyledOptionItem>
              )}
              {buildingReceived === 2 && (
                <StyledOptionItem>Nie</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>
        {roofInstallation !== 3 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Izolacja dachu</label>
                <input type="hidden" {...register("roof_insulation")} />
                <StyledOptionWrapper>
                  {roofInsulation === 1 && (
                    <StyledOptionItem>Izolacja wierzchnia</StyledOptionItem>
                  )}
                  {roofInsulation === 2 && (
                    <StyledOptionItem>Izolacja spodnia</StyledOptionItem>
                  )}
                  {roofInsulation === 3 && (
                    <StyledOptionItem>Brak izolacji</StyledOptionItem>
                  )}
                </StyledOptionWrapper>
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Instalacja odgromowa</label>
                <input type="hidden" {...register("lightning_protection")} />
                <StyledOptionWrapper>
                  {lightningProtection === 1 && (
                    <StyledOptionItem>Nie istnieje</StyledOptionItem>
                  )}
                  {lightningProtection === 2 && (
                    <StyledOptionItem>Istnieje</StyledOptionItem>
                  )}
                </StyledOptionWrapper>
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Rodzaj dachu</label>
                <input type="hidden" {...register("roof_type")} />
                <StyledOptionWrapper>
                  {roofTypeSelect === 0 && (
                    <StyledOptionItem>Nie wybrano</StyledOptionItem>
                  )}
                  {roofTypeSelect === 1 && (
                    <StyledOptionItem>Dach jednospadowy</StyledOptionItem>
                  )}
                  {roofTypeSelect === 2 && (
                    <StyledOptionItem>Dach dwuspadowy</StyledOptionItem>
                  )}
                  {roofTypeSelect === 3 && (
                    <StyledOptionItem>Dach wielospadowy</StyledOptionItem>
                  )}
                  {roofTypeSelect === 4 && (
                    <StyledOptionItem>Płaski</StyledOptionItem>
                  )}
                </StyledOptionWrapper>
              </div>
            </StyledInput>
          </>
        )}
        <StyledInput>
          <div className="input-group">
            <label>Miejsce montażu falownika</label>
            <input type="hidden" {...register("inverter_mounting_location")} />
            <StyledOptionWrapper>
              {inverterMountingLocation === 1 && (
                <StyledOptionItem>W budynku</StyledOptionItem>
              )}
              {inverterMountingLocation === 2 && (
                <StyledOptionItem>Poza budynkiem</StyledOptionItem>
              )}
            </StyledOptionWrapper>
          </div>
        </StyledInput>

        {inverterMountingLocation === 1 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Opis miejsca montażu falownika</label>
                <input
                  type="text"
                  {...register("inverter_mounting_location_description_1")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>
                  Całkowita powierzcznia użytkowa (m<sup>2</sup>)
                </label>
                <input
                  type="text"
                  {...register("total_usable_area")}
                  readOnly
                />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>
                  Kubatura (m<sup>3</sup>)
                </label>
                <input type="text" {...register("volume")} readOnly />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>ilość kondygnacji</label>
                <input type="text" {...register("number_of_floors")} readOnly />
              </div>
            </StyledInput>
          </>
        )}

        {inverterMountingLocation === 2 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Opis miejsca montażu falownika</label>
                <input
                  type="text"
                  {...register("inverter_mounting_location_description_2")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}
        {roofInstallation !== 3 && (
          <>
            <StyledTitleWrapper> Rodzaje poszycia dachu</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>Rodzaj pokrycia dachowego</label>
                <input type="hidden" {...register("roof_type_information")} />

                <StyledOptionWrapper>
                  {roofType === 1 && (
                    <StyledOptionItem>Dachówka</StyledOptionItem>
                  )}
                  {roofType === 2 && (
                    <StyledOptionItem>Blacha</StyledOptionItem>
                  )}
                  {roofType === 3 && <StyledOptionItem>Inne</StyledOptionItem>}
                </StyledOptionWrapper>
              </div>
            </StyledInput>

            {roofType === 1 && (
              <>
                <StyledInput>
                  <div className="input-group">
                    <label>Dachówka</label>
                    <input type="hidden" {...register("tile")} />

                    <StyledOptionWrapper>
                      {tileType === 0 && (
                        <StyledOptionItem>Nie wybrano</StyledOptionItem>
                      )}
                      {tileType === 1 && (
                        <StyledOptionItem>Betonowa</StyledOptionItem>
                      )}
                      {tileType === 2 && (
                        <StyledOptionItem>Ceramiczna</StyledOptionItem>
                      )}
                      {tileType === 3 && (
                        <StyledOptionItem>Karpiówka</StyledOptionItem>
                      )}
                      {tileType === 4 && (
                        <StyledOptionItem>Inne</StyledOptionItem>
                      )}
                    </StyledOptionWrapper>
                  </div>
                </StyledInput>

                {tileType === 4 && (
                  <StyledInput>
                    <div className="input-group">
                      <label>Dachówka inne</label>
                      <input
                        type="number"
                        {...register("tile_other")}
                        readOnly
                      />
                    </div>
                  </StyledInput>
                )}

                <StyledInput>
                  <div className="input-group">
                    <label>Wymiary dachówki</label>
                    <input
                      type="number"
                      {...register("tile_dimension")}
                      readOnly
                    />
                  </div>
                </StyledInput>
              </>
            )}
            {roofType === 2 && (
              <>
                <StyledInput>
                  <div className="input-group">
                    <label>Blacha</label>
                    <input type="hidden" {...register("steel_tile")} />
                    <StyledOptionWrapper>
                      {steelTileType === 0 && (
                        <StyledOptionItem>Nie wybrano</StyledOptionItem>
                      )}
                      {steelTileType === 1 && (
                        <StyledOptionItem>Blachodachówka</StyledOptionItem>
                      )}
                      {steelTileType === 2 && (
                        <StyledOptionItem>Trapezowa</StyledOptionItem>
                      )}
                      {steelTileType === 3 && (
                        <StyledOptionItem>Płaska</StyledOptionItem>
                      )}
                      {steelTileType === 4 && (
                        <StyledOptionItem>Rąbek</StyledOptionItem>
                      )}
                      {steelTileType === 5 && (
                        <StyledOptionItem>Inna</StyledOptionItem>
                      )}
                    </StyledOptionWrapper>

                    {steelTileType === 5 && (
                      <StyledInput>
                        <div className="input-group">
                          <label>Blacha inne</label>
                          <input
                            type="number"
                            {...register("steel_tile_other")}
                            readOnly
                          />
                        </div>
                      </StyledInput>
                    )}
                  </div>
                </StyledInput>
              </>
            )}
            {roofType === 3 && (
              <>
                <StyledInput>
                  <div className="input-group">
                    <label>Inne</label>
                    <input type="hidden" {...register("other_tile")} />
                    <StyledOptionWrapper>
                      {otherTileType === 0 && (
                        <StyledOptionItem>Nie wybrano</StyledOptionItem>
                      )}
                      {otherTileType === 1 && (
                        <StyledOptionItem>Gont bitumiczny</StyledOptionItem>
                      )}
                      {otherTileType === 2 && (
                        <StyledOptionItem>Papa + deskowanie</StyledOptionItem>
                      )}
                      {otherTileType === 3 && (
                        <StyledOptionItem>Ondulina + płyta</StyledOptionItem>
                      )}
                      {otherTileType === 4 && (
                        <StyledOptionItem>Beton</StyledOptionItem>
                      )}
                      {otherTileType === 5 && (
                        <StyledOptionItem>Papa + beton</StyledOptionItem>
                      )}
                      {otherTileType === 6 && (
                        <StyledOptionItem>Membrana EPDM/PCV</StyledOptionItem>
                      )}
                      {otherTileType === 7 && (
                        <StyledOptionItem>Podsypka żwirowa</StyledOptionItem>
                      )}
                      {otherTileType === 8 && (
                        <StyledOptionItem>Inne</StyledOptionItem>
                      )}
                    </StyledOptionWrapper>
                  </div>
                </StyledInput>

                {otherTileType === 8 && (
                  <>
                    <StyledInput>
                      <div className="input-group">
                        <label>Inne</label>
                        <input
                          type="text"
                          {...register("other_tile_other")}
                          readOnly
                        />
                      </div>
                    </StyledInput>
                  </>
                )}
              </>
            )}
          </>
        )}

        {roofInstallation === 3 && (
          <>
            <StyledTitleWrapper>Instalacja na gruncie:</StyledTitleWrapper>
            <StyledInput>
              <div className="input-group">
                <label>Długość boku 1 miejsca pod instalacje</label>
                <input type="text" {...register("side_one_length")} />
              </div>
            </StyledInput>
            <StyledInput>
              <div className="input-group">
                <label>Długość boku 2 miejsca pod instalacje</label>
                <input type="text" {...register("side_two_length")} />
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Rodzaj podłoża</label>
                <select {...register("soil_type_substrate")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Beton</option>
                  <option value={2}>Grunt</option>
                </select>
              </div>
            </StyledInput>

            <StyledInput>
              <div className="input-group">
                <label>Rodzaj podłoża inne</label>
                <input type="text" {...register("soil_type_substrate_other")} />
              </div>
            </StyledInput>
          </>
        )}
        <StyledTitleWrapper>Przyłącze elektryczne </StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Długość przewodu DC (m)</label>
            <input type="number" {...register("dc_wire_length")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Długość przewodu AC (m)</label>
            <input type="number" {...register("ac_wire_length")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Długość przekopu (m)</label>
            <input type="number" {...register("cut_length")} readOnly />
          </div>
        </StyledInput>

        <StyledInput>
          <div className="input-group">
            <label>Sposób wykonania przekopu</label>
            <input type="hidden" {...register("cut_method")} />

            <StyledOptionWrapper>
              {cutMethod === 0 && (
                <StyledOptionItem>Nie wybrano</StyledOptionItem>
              )}
              {cutMethod === 1 && <StyledOptionItem>Ręcznie</StyledOptionItem>}
              {cutMethod === 2 && <StyledOptionItem>Koparką</StyledOptionItem>}
            </StyledOptionWrapper>
          </div>
        </StyledInput>

        {(cutMethod === 1 || cutMethod === 2) && (
          <>
            <StyledTitleWrapper>
              Istniejące przyłącza ziemne mediów w okolicy planowanej
              instalacji:
            </StyledTitleWrapper>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_water"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Woda
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_gas"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Gaz
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_energetic_join"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przyłącze elektryczne
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_sewers"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Kanalizacja/szambo
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_optical_fiber"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Światłowód
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_sewage_treatment_plan"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przydomowa oczyszczalnia scieków
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_tel_connection"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Przyłącze telekomunikacyjne
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <Controller
                    name="media_connections_rain_sawage"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <StyledCheckBoxInput
                        type="checkbox"
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  Kanalizacja deszczowa
                </StyledCheckBoxLabel>
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledInput>
              <div className="input-group">
                <label>Przyłącza ziemne mediów inne</label>
                <input
                  type="text"
                  {...register("media_connections_other")}
                  readOnly
                />
              </div>
            </StyledInput>
          </>
        )}
        {cutMethod === 2 && (
          <>
            <StyledInput>
              <div className="input-group">
                <label>Czy koparka może wjechać</label>
                <select {...register("excavator_entry")}>
                  <option value={0}>Wybierz...</option>
                  <option value={1}>Tak</option>
                  <option value={2}>Nie</option>
                </select>
              </div>
            </StyledInput>
          </>
        )}
        <StyledTitleWrapper>Rozbiórka kostki </StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Odcinek kostki do rozbiórki (mb)</label>
            <input
              type="number"
              {...register("cube_dismantle_length")}
              readOnly
            />
          </div>
        </StyledInput>

        <StyledTitleWrapper>Ustalenia terminu </StyledTitleWrapper>
        <StyledInput>
          <div className="input-group">
            <label>Termin montażu (tyg.)</label>
            <input type="text" {...register("realization_date")} readOnly />
          </div>
        </StyledInput>

        <StyledTitleWrapper>Ustalenia dodatkowe</StyledTitleWrapper>

        <StyledAdditionalArragementsWrapper>
          {additionalArrangements}
        </StyledAdditionalArragementsWrapper>

        <input type="hidden" {...register("additional_arrangements")} />

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            disabled={isSended}
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Generuj umowę
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledAdditionalArragementsWrapper = styled.div`
  font-size: 14px;
  padding: 10px 2px;
`;

const StyledOptionItem = styled.div`
  margin-left: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledOptionWrapper = styled.div`
  font-size: 14px;
  min-width: 0;
  border-bottom: 1px solid black;
`;

const StyledCancelButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 14px;
`;

const StyledForm = styled.form`
  max-width: 600px;
  min-width: 360px;
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    min-width: 0;
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

export default DataUpdateAnnex;
