import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import request from "../../../../../../../../../helpers/request";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Nazwa klienta jest wymagana")
    .min(3, "Nazwa klienta musi posiadać co najmniej 3 znaków")
    .max(255, "Nazwa klienta nie może być dłuższa niż 255 znaków"),
  NIP: yup
    .string()
    .matches(/^[0-9]+$/, "NIP musi składać się wyłącznie z cyfr 1-9")
    .min(10, "NIP musi posiadać co najmniej 10 znaków")
    .max(10, "NIP nie może być dłuższy niż 10 znaków")
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .nullable(true),

  city: yup.string().nullable(),
  postCode: yup.string().nullable(),
  street: yup.string().nullable(),
  building_number: yup.string().nullable(),
});

const CompanyForm = ({
  company,
  fetchData,
  showCompanyInfo,
  setShowCompanyInfo,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (company) {
      setValue("name", company.name);
      setValue("NIP", company.NIP);
      setValue("street", company.street);
      setValue("building_number", company.building_number);
      setValue("post_code", company.post_code);
      setValue("post", company.post);
      setValue("city", company.city);
    }
  }, [
    company?.name,
    company?.post_code,
    company?.post,
    company?.street,
    company?.building_number,
    company?.city,
    company?.NIP,
    company,
  ]);

  const handleUpdateCompany = async (data) => {
    try {
      if (company?.id) {
        const { status } = await request.patch(
          `/api/companies/offer-update/${company.id}`,
          {
            name: data.name,
            NIP: data.NIP,
            street: data.street,
            building_number: data.building_number,
            postCode: data.post_code,
            post: data.post,
            city: data.city,
          }
        );
        if (status === 201) {
          fetchData();
          setShowCompanyInfo(false);
        }
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  const handleOnClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowCompanyInfo(false);
  };
  return (
    <StyledCompanyInformationWrapper
      style={showCompanyInfo ? { display: "flex" } : { display: "none" }}
    >
      <StyledCompanyTitleWrapper>Dane Firmy: </StyledCompanyTitleWrapper>
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <StyledInput>
          <div className="input-group">
            <label>Nazwa Firmy:</label>
            <input type="text" {...register("name")} />
          </div>
          {errors?.name && <span className="error">{errors.name.message}</span>}{" "}
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>NIP:</label>
            <input type="text" {...register("NIP")} />
          </div>
          {errors?.NIP && <span className="error">{errors.NIP.message}</span>}{" "}
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Ulica</label>
            <input type="text" {...register("street")} />
          </div>
          {errors?.street && (
            <span className="error">{errors.street.message}</span>
          )}
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Numer budynku</label>
            <input type="text" {...register("building_number")} />
          </div>
          {errors?.building_number && (
            <span className="error">{errors.building_number.message}</span>
          )}
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Miejscowość</label>
            <input type="text" {...register("city")} />
          </div>
          {errors?.city && <span className="error">{errors.city.message}</span>}{" "}
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Kod pocztowy:</label>
            <input type="text" {...register("post_code")} />
          </div>
          {errors?.post_code && (
            <span className="error">{errors?.post_code.message}</span>
          )}{" "}
        </StyledInput>
        <StyledInput>
          <div className="input-group">
            <label>Poczta:</label>
            <input type="text" {...register("post")} />
          </div>
          {errors?.post && (
            <span className="error">{errors?.post.message}</span>
          )}{" "}
        </StyledInput>
        <StyledUpdateButtonWrapper>
          <StyledUpdateButton onClick={handleSubmit(handleUpdateCompany)}>
            Zapisz
          </StyledUpdateButton>
          <StyledCancelButton onClick={handleOnClose}>
            Anuluj
          </StyledCancelButton>
        </StyledUpdateButtonWrapper>{" "}
      </StyledForm>
    </StyledCompanyInformationWrapper>
  );
};

const StyledCancelButton = styled.button`
  width: 100%;
  background: #134771;
  color: #cadeee;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

const StyledInput = styled.div`
  min-width: 300px;
  margin-top: 25px;
  display: flex;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
  }
`;

const StyledUpdateButton = styled.button`
  width: 100%;
  background: #134771;
  color: #cadeee;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #053257;
  }
`;

const StyledUpdateButtonWrapper = styled.div`
  margin-top: auto;
  padding: 10px;
  display: flex;
  gap: 10px;
`;

const StyledCompanyTitleWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: #134771;
  padding: 10px;
`;
const StyledCompanyInformationWrapper = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  border-radius: 10px;
`;

export default CompanyForm;
