import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommentsList from "./CommentsList/CommentsList";
import AddComments from "./Components/AddComments";

const CommentsComponent = ({ note, fetchData }) => {
  const [isSended, setIsSended] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [commentsCountInfo, setCommentsCountInfo] = useState("");

  useEffect(() => {
    if (note?.comments) {
      if (note.comments.length === 1) {
        setCommentsCountInfo("1 komentarz");
      } else if (note.comments.length > 0 && note.comments.length < 5) {
        setCommentsCountInfo(`${note.comments.length} komentarze`);
      } else if (note.comments.length >= 5) {
        setCommentsCountInfo(`${note.comments.length} komentarzy`);
      }
    }
  }, [note]);

  const handleOnToggleComments = (e) => {
    setIsOpen((prev) => !prev);
  };

  const handleOnOpenComments = (e) => {
    setIsOpen(true);
  };

  return (
    <StyledWrapper>
      <CommentsInfo>
        {commentsCountInfo && (
          <CommentsCountWrapper>
            <CountButton onClick={handleOnToggleComments}>
              {commentsCountInfo}
            </CountButton>
          </CommentsCountWrapper>
        )}
      </CommentsInfo>
      <ButtonsWrapper>
        {" "}
        <CommentButton onClick={handleOnOpenComments}>
          <CommentIcon icon={faCommentAlt} /> Komentarz{" "}
        </CommentButton>
      </ButtonsWrapper>

      <CommentsContent isOpen={isOpen}>
        <AddComments
          note={note}
          fetchData={fetchData}
          setIsSended={setIsSended}
        />
        <CommentsList comments={note.comments} />
      </CommentsContent>
      {isSended && <StyledSendNoteWrapper></StyledSendNoteWrapper>}
    </StyledWrapper>
  );
};

const CountButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #134771;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CommentButton = styled.button`
  padding: 5px;
  width: 200px;
  border: none;
  outline: none;
  background: transparent;
  color: #134771;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: #cadeee;
  }
`;

const CommentIcon = styled(FontAwesomeIcon)`
  color: #134771;
  margin-right: 5px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cadeee;
  padding-top: 10px;
`;
const CommentsCountWrapper = styled.div`
  padding: 5px 0;
  color: #134771;
  font-size: 14px;
`;
const CommentsInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
`;
const CommentsContent = styled.div`
  display: ${(prop) => (prop.isOpen ? "block" : "none")};
`;
const StyledWrapper = styled.div`
  position: relative;
`;

const StyledSendNoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 222, 238, 0.6);
  border-radius: 10px;
`;

export default CommentsComponent;
