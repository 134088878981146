export default function buildCalendar(value) {
  const startMonth = value.clone().startOf("year").startOf("month");
  const endMonth = value.clone().endOf("year").endOf("month");

  const month = startMonth.clone().subtract(1, "month");

  const calendar = [];
  while (month.isBefore(endMonth, "month")) {
    calendar.push(month.add(1, "month").clone());
  }

  return calendar;
}
