import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation, Redirect } from "react-router";
import styled from "styled-components";
import request from "../../../helpers/request";
import UserAddNote from "./UserAddNote/UserAddNote";
import UserInfo from "./UserInfo/UserInfo";
import UserNoteList from "./UserNoteList/UserNoteList";
import UserSideBar from "./UserSideBar/UserSideBar";

const UserDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState([]);

  const fetchUser = async () => {
    try {
      const { data, status } = await request.get(`api/user/${id}`);
      if (status === 200) {
        setUser(data.data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      } else {
        history.push("/dashboard/");
      }
    }
  };

  useEffect(() => {
    setUser([]);
    fetchUser();
  }, []);

  return (
    <StyledWrapper>
      <StyledInfoWrapper>
        <UserInfo user={user} />
        <div className="main-wall">
          <UserAddNote fetchData={fetchUser} UserId={user.id} />
          <UserNoteList note={user.notes} fetchData={fetchUser} />
        </div>
      </StyledInfoWrapper>
      <UserSideBar todos={user.todo || []} fetchData={fetchUser} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  .main-wall {
    margin: 0 10px;
    flex-grow: 1;
    width: 100%;
  }
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
  }
`;
export default UserDetails;
