import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  NavLink,
} from "react-router-dom";
import styled from "styled-components";
import request from "../../helpers/request";
import { StoreContext } from "../../store/StoreProvider";
import HeatPumpContractApprove from "./HeatPumpContractApprove/HeatPumpContractApprove";
import PhotovoltaicContractApprove from "./PhotovoltaicContractApprove/PhotovoltaicContractApprove";

const ContractApprove = () => {
  const { voivodeships, setVoivodeships } = useContext(StoreContext);

  let { path } = useRouteMatch();

  const location = useLocation();
  const history = useHistory();

  const fetchVoivodeships = async () => {
    try {
      const { status, data } = await request.get("/api/voivodeship");
      if (status === 200) {
        await setVoivodeships(data);
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
  };

  useEffect(() => {
    if (voivodeships === undefined || voivodeships.length === 0) {
      fetchVoivodeships();
    }
  }, []);

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <StyledTitle>Akceptacja umów</StyledTitle>
      </StyledTitleWrapper>
      <StyledContactsNavList>
        <StyledContactsNavItem>
          <StyledNavLink
            to="/dashboard/contract-approve/photovoltaic"
            activeClassName="active"
          >
            <FontAwesomeWrapper style={{ background: "rgb(244, 118, 49)" }}>
              <FontAwesomeIcon icon={faClipboardCheck} />
            </FontAwesomeWrapper>
            Umowy fotowoltaika
          </StyledNavLink>
        </StyledContactsNavItem>
        <StyledContactsNavItem>
          <StyledNavLink
            exact
            to="/dashboard/contract-approve/heat-pump"
            activeClassName="active"
          >
            <FontAwesomeWrapper style={{ background: "rgb(102, 188, 70)" }}>
              <FontAwesomeIcon icon={faClipboardCheck} />
            </FontAwesomeWrapper>
            Umowy pompy ciepła
          </StyledNavLink>
        </StyledContactsNavItem>
      </StyledContactsNavList>

      <ContentWrapper>
        <Switch>
          <Route
            exact
            path={`${path}/photovoltaic`}
            component={PhotovoltaicContractApprove}
          />
          <Route
            exact
            path={`${path}/heat-pump`}
            component={HeatPumpContractApprove}
          />
          <Route exact path={`${path}/`}>
            <Redirect to={`${path}/photovoltaic`} />
          </Route>
        </Switch>
      </ContentWrapper>
    </StyledWrapper>
  );
};

const FontAwesomeWrapper = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 5px;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  font-size: 16px;
  background: white;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  &:hover {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
  &.active {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  }
`;

const StyledContactsNavList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 10px;
  margin-bottom: 10px;
`;

const StyledContactsNavItem = styled.li`
  list-style: none;
  flex-basis: 50%;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 40px;
  font-family: "ubuntu", sans-serif;
  color: black;
  margin-left: 10px;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  max-width: 1400px;
  min-width: 0;
  margin: 0 auto;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    h3 {
      font-weight: normal;
      font-size: 2rem;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
`;
export default ContractApprove;
