import React, { createContext, useState } from "react";

export const StoreContext = createContext(null);

const StoreProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const [uncompleteSaleOpportunities, setUncompleteSaleOpportunities] =
    useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [tags, setTags] = useState([]);
  const [lastOpportunities, setLastOpportunities] = useState([]);
  const [notes, setNotes] = useState([]);
  const [noteCoursorURL, setNoteCoursorURL] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [todoList, setTodoList] = useState([]);
  const [yourTaskSetting, setYourTaskSetting] = useState([]);
  const [taskTypeSetting, setTaskTypeSetting] = useState([]);
  const [salesFunnel, setSalesFunnel] = useState([]);
  const [taskStatusSetting, setTaskStatusSetting] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [taskType, setTaskType] = useState([]);
  const [yourTask, setYourTask] = useState([]);
  const [panels, setPanels] = useState([]);
  const [optimizers, setOptimizers] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [opportunityStatuses, setOpportunityStatuses] = useState([]);
  const [salesLeaders, setSalesLeaders] = useState([]);
  const [opportunitiesSummary, setOpportunitiesSummary] = useState([]);
  const [sources, setSources] = useState([]);
  const [opportunitiesCount, setOpportunitiesCount] = useState(0);
  const [assemblyBus, setAssemblyBus] = useState([]);
  const [isAsideNavOpen, setIsAsideNavOpen] = useState(true);
  const [offerApproveList, setOfferApproveList] = useState([]);
  const [contractApproveList, setContractApproveList] = useState([]);
  const [leads, setLeads] = useState([]);
  const [voivodeships, setVoivodeships] = useState([]);
  const [leadTypes, setLeadTypes] = useState([]);
  const [heatingTypes, setHeatingTypes] = useState([]);
  const [chargers, setChargers] = useState([]);
  const [chargerAccessories, setChargerAccessories] = useState([]);
  const [constructionType, setConstructionType] = useState([]);
  const [energyStorages, setEnergyStorages] = useState([]);
  const [labour, setLabour] = useState([]);
  const [transports, setTransports] = useState([]);
  const [installationsType, setInstallationsType] = useState([]);

  return (
    <StoreContext.Provider
      value={{
        user,
        tags,
        setTags,
        setUser,
        uncompleteSaleOpportunities,
        setUncompleteSaleOpportunities,
        users,
        setUsers,
        departments,
        setDepartments,
        teams,
        setTeams,
        lastOpportunities,
        setLastOpportunities,
        notes,
        setNotes,
        roles,
        setRoles,
        sources,
        setSources,
        isLogin,
        setIsLogin,
        todoList,
        setTodoList,
        yourTaskSetting,
        setYourTaskSetting,
        taskTypeSetting,
        setTaskTypeSetting,
        taskStatusSetting,
        setTaskStatusSetting,
        taskStatus,
        setTaskStatus,
        taskType,
        setTaskType,
        yourTask,
        setYourTask,
        panels,
        setPanels,
        optimizers,
        setOptimizers,
        inverters,
        setInverters,
        opportunityStatuses,
        setOpportunityStatuses,
        constructions,
        setConstructions,
        salesLeaders,
        setSalesLeaders,
        opportunitiesSummary,
        setOpportunitiesSummary,
        opportunitiesCount,
        setOpportunitiesCount,
        salesFunnel,
        setSalesFunnel,
        assemblyBus,
        setAssemblyBus,
        isAsideNavOpen,
        setIsAsideNavOpen,
        offerApproveList,
        setOfferApproveList,
        contractApproveList,
        setContractApproveList,
        leads,
        setLeads,
        voivodeships,
        setVoivodeships,
        leadTypes,
        setLeadTypes,
        heatingTypes,
        setHeatingTypes,
        noteCoursorURL,
        setNoteCoursorURL,
        chargers,
        setChargers,
        chargerAccessories,
        setChargerAccessories,
        constructionType,
        setConstructionType,
        energyStorages,
        setEnergyStorages,
        labour,
        setLabour,
        transports,
        setTransports,
        installationsType,
        setInstallationsType,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
