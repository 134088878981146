import React, { useState } from "react";
import styled from "styled-components";

const CreditCalculator = () => {
  const [bruttoPrice, setBruttoPrice] = useState(0);
  const [installment, setInstallment] = useState(12);
  const [checkedInstallment, setCheckedInstallment] = useState(12);
  const [alior, setAlior] = useState(0);
  const [santander, setSantander] = useState(0);

  const handleOnChangePrice = (e) => {
    if (e.target.value < 0) {
      setBruttoPrice(0);
    } else {
      setBruttoPrice(Number(e.target.value).toFixed());
    }
  };

  const handleOnChangeInstallment = (e) => {
    setInstallment(e.target.value);
  };

  const handleCalcLoanInstallment = () => {
    let functionInstallment = 12;
    if (installment < 12) {
      setInstallment(12);
      setCheckedInstallment(12);
      functionInstallment = 12;
    } else if (installment > 120) {
      setInstallment(120);
      setCheckedInstallment(120);
      functionInstallment = 120;
    } else {
      setCheckedInstallment(installment);
      functionInstallment = installment;
    }

    if (bruttoPrice > 0) {
      setAlior(
        (
          (bruttoPrice * 0.36) / 100 +
          bruttoPrice / functionInstallment
        ).toFixed(2)
      );
      if (functionInstallment >= 36 && functionInstallment < 60) {
        setSantander(
          (
            (bruttoPrice * 0.38) / 100 +
            bruttoPrice / functionInstallment
          ).toFixed(2)
        );
      } else if (functionInstallment >= 60 && functionInstallment <= 120) {
        setSantander(
          ((bruttoPrice * 0.38) / 100 + bruttoPrice / 60).toFixed(2)
        );
      }
    } else {
      setAlior(0);
      setSantander(0);
    }
  };

  return (
    <StyledWrapper>
      <StyledSecondWrapper>
        <StyledNameWrapper>
          <StyledName>Kalkulator kredytowy</StyledName>
        </StyledNameWrapper>

        <StyledCalculatorWrapper>
          <StyledInputWrapper>
            <StyledLabel>Wpisz kwotę brutto</StyledLabel>
            <StyledInput
              type="number"
              value={bruttoPrice}
              onChange={handleOnChangePrice}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <StyledLabel>Ilość rat</StyledLabel>
            <StyledInput
              type="number"
              value={installment}
              onChange={handleOnChangeInstallment}
            />
          </StyledInputWrapper>

          <StyledCalcButtonWrapper>
            <StyledCalcButton onClick={handleCalcLoanInstallment}>
              Oblicz
            </StyledCalcButton>
          </StyledCalcButtonWrapper>

          <StyledSectionTitleWrapper>
            <StyledSectionTitle>Wyliczenia kredytowe</StyledSectionTitle>
          </StyledSectionTitleWrapper>

          <StyledResultWrapper>
            <StyledBankName>Alior (12 - 120 rat)</StyledBankName>
            <StyledLoanPeriod>{checkedInstallment} rat</StyledLoanPeriod>
            <StyledRRSO>RRSO:7,5%</StyledRRSO>
            {checkedInstallment > 60 ? (
              <StyledRedSpan>Oprocentowanie zmienne</StyledRedSpan>
            ) : (
              <StyledRedSpan>Oprocentowanie stałe</StyledRedSpan>
            )}
            <StyledLoanInstallment>
              Rata kredytu: {alior ? `${alior} zł` : ""}
            </StyledLoanInstallment>
          </StyledResultWrapper>

          <StyledResultWrapper>
            <StyledBankName>Santander (36 - 120 rat)</StyledBankName>
            <StyledLoanPeriod>{checkedInstallment} rat</StyledLoanPeriod>
            <StyledRRSO>RRSO:8%</StyledRRSO>
            <StyledLoanInstallment>
              Rata kredytu: {santander ? `${santander} zł` : ""}
            </StyledLoanInstallment>
          </StyledResultWrapper>
        </StyledCalculatorWrapper>
      </StyledSecondWrapper>
    </StyledWrapper>
  );
};

const StyledCalcButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledCalcButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledRedSpan = styled.span`
  color: red;
  font-size: 14px;
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  outline: none;
  font-size: 16px;
`;

const StyledLoanInstallment = styled.div`
  color: rgb(244, 118, 49);
`;

const StyledRRSO = styled.div``;
const StyledLoanPeriod = styled.div``;

const StyledBankName = styled.div``;
const StyledResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

const StyledLabel = styled.label`
  color: rgb(244, 118, 49);
`;

const StyledSectionTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSectionTitle = styled.p`
  color: #134771;
  font-size: 16px;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const StyledCalculatorWrapper = styled.div``;

const StyledName = styled.p`
  color: #134771;
  font-size: 16px;
`;

const StyledNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const StyledSecondWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  min-width: 400px;
`;

const StyledWrapper = styled.div`
  padding: 10px;
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CreditCalculator;
