import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import request from "../../../../../helpers/request";
import styled from "styled-components";
import Switch from "../../../../../components/Switch";
import DeleteAlert from "./DeleteAlert";

const AssemblyItem = ({
  element,
  fetchDataAssembluBus,
  handleOpenEditStatusTask,
  setEditObject,
}) => {
  const { id, name, value } = element;

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOnEdit = () => {
    setEditObject(element);
    handleOpenEditStatusTask();
  };

  const handleOnDelete = async () => {
    const { status } = await request.delete(`/api/assembly-bus/${id}`);
    if (status === 201) {
      fetchDataAssembluBus();
      setIsAlertOpen(false);
    }
  };

  const handleOnOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const handleOnCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const handleOnSwitchChange = async () => {
    const { status } = await request.patch(`/api/assembly-bus/${id}`, {
      value: !value,
    });
    if (status === 201) {
      fetchDataAssembluBus();
    }
  };

  return (
    <StyledTaskStatusItem>
      <StyledDescription>
        <Switch handleOnChange={handleOnSwitchChange} checked={value} />
        <p>{name}</p>
      </StyledDescription>
      <StyledButtonGroup>
        <StyledButton onClick={handleOnOpenAlert}>
          <StyledTrashIcon icon={faTrashAlt} />{" "}
        </StyledButton>
        <StyledButton onClick={handleOnEdit}>
          <StyledEditIcon style={{ color: "royalBlue" }} icon={faEdit} />
        </StyledButton>
      </StyledButtonGroup>
      <DeleteAlert
        isAlertOpen={isAlertOpen}
        handleOnClose={handleOnCloseAlert}
        handleOnDelete={handleOnDelete}
      />
    </StyledTaskStatusItem>
  );
};

const StyledTaskStatusItem = styled.li`
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
`;

const StyledTrashIcon = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 20px;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  color: royalblue;
  font-size: 20px;
`;

const StyledDescription = styled.div`
  display: flex;
  flex: 1;
`;

const StyledButtonGroup = styled.div`
  display: flex;
`;

const StyledButton = styled.button`
  padding: 5px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export default AssemblyItem;
