import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../../../store/StoreProvider";

const OpportunitiesChangeStatus = ({
  statuses,
  isOpen,
  setIsOpen,
  handleChange,
  opportunities,
}) => {
  const { user } = useContext(StoreContext);

  const handleChangeStatus = (id) => {
    handleChange(id);
    setIsOpen(false);
  };

  return (
    <StyledStatusesWrapper
      style={isOpen ? { display: "flex" } : { display: "none" }}
    >
      {user?.role?.id === 1 ? (
        <>
          {statuses &&
            statuses
              .filter((status) => status.id !== opportunities.status)
              .map((status) => (
                <StyledStatusWrapper
                  key={status.id}
                  onClick={() => handleChangeStatus(status.id)}
                >
                  <StyledStatusSpan style={{ background: status.color }}>
                    {status.length !== 0 ? (
                      <FontAwesomeIcon
                        style={{ color: status.icon_color }}
                        icon={[status.prefix, status.icon]}
                      />
                    ) : null}
                  </StyledStatusSpan>
                  <StyledStatusText>{status.name}</StyledStatusText>
                </StyledStatusWrapper>
              ))}
        </>
      ) : (
        <>
          {opportunities.signing_a_contract === 1 && (
            <>
              {statuses &&
                statuses
                  .filter((status) => status.id === 3)
                  .map((status) => (
                    <StyledStatusWrapper
                      key={status.id}
                      onClick={() => handleChangeStatus(status.id)}
                    >
                      <StyledStatusSpan style={{ background: status.color }}>
                        {status.length !== 0 ? (
                          <FontAwesomeIcon
                            style={{ color: status.icon_color }}
                            icon={[status.prefix, status.icon]}
                          />
                        ) : null}
                      </StyledStatusSpan>
                      <StyledStatusText>{status.name}</StyledStatusText>
                    </StyledStatusWrapper>
                  ))}
            </>
          )}
        </>
      )}
    </StyledStatusesWrapper>
  );
};

const StyledStatusesWrapper = styled.div`
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid rgba(160, 160, 160, 0.4);
`;
const StyledStatusWrapper = styled.button`
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background: #eee;
  }
`;

const StyledStatusText = styled.p`
  font-size: 18px;
`;

const StyledStatusSpan = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  svg {
    font-size: 20px;
  }
`;
export default OpportunitiesChangeStatus;
