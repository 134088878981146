import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router";
import { StoreContext } from "../../../../../../../../../store/StoreProvider";
import request from "../../../../../../../../../helpers/request";

import CompanyForm from "../Components/CompanyForm";
import Modal from "../../../../../../../../../components/Modal";
import ClientForm from "../Components/ClientForm";

const validationSchema = yup.object({
  //   instalation_id: yup.string().required("Wybierz instalacje fotowoltaiczną"),
  //   construction_id: yup.string().required("Wybierz konstrukcje montażową"),
  //   investor_address: yup
  //     .string()
  //     .required("Adres wymagany sprawdź poprawność danych inwestora")
  //     .typeError("Adres wymagany sprawdź poprawność danych inwestora"),
  //   panel_count: yup
  //     .string()
  //     .required("Podaj ilość paneli fotowoltaicznych")
  //     .matches(/^[0-9]+$/, "Ilość paneli musi składać się wyłącznie z cyfr 0-9"),
  //   inverter_count: yup
  //     .string()
  //     .required("Podaj ilość falowników")
  //     .matches(
  //       /^[0-9]+$/,
  //       "Ilość falowników musi składać się wyłącznie z cyfr 0-9"
  //     ),
  //   construction_count: yup
  //     .string()
  //     .required("Podaj ilość konstrukcji montażowych")
  //     .matches(
  //       /^[0-9]+$/,
  //       "Ilosć konstrukcji musi składać się wyłącznie z cyfr 0-9"
  //     ),
  //   power: yup
  //     .number()
  //     .typeError("Podana wartość musin być liczbą")
  //     .required("Podaj ilość konstrukcji montażowych"),
  //   price_netto: yup
  //     .number()
  //     .typeError("Podana wartość musin być liczbą")
  //     .required("Suma netto jest wymagana"),
  //   price_vat: yup
  //     .number()
  //     .typeError("Podana wartość musin być liczbą")
  //     .required("Suma VAT jest Wymagana"),
  //   price_sum: yup
  //     .number()
  //     .typeError("Podana wartość musin być liczbą")
  //     .required("Suma razem jest wymagana"),
  //   offer_date: yup
  //     .number()
  //     .typeError("Podana wartość musin być liczbą")
  //     .required("Ważność oferty jest wymagana"),
  //   services: yup.array().of(
  //     yup.object().shape({
  //       name: yup.string().required("Nazwa jest wymagana."),
  //       price: yup
  //         .string()
  //         .matches(/^[0-9]+$/, "Cena musi składać się wyłącznie z cyfr 1-9")
  //         .transform((cv, ov) => {
  //           return ov === "" ? null : cv;
  //         })
  //         .nullable(true),
  //     })
  //   ),
});

const OfferHeatPump = ({
  isModalOpen,
  handleOnClose,
  fetchData,
  opportunities,
}) => {
  const { id, company, client } = opportunities;
  const { user, setOpportunities } = useContext(StoreContext);

  const [flushingSystem, setFlushingSystem] = useState(false);
  const [leaveExistingSource, setLeaveExistingSource] = useState(false);
  const [wirlessController, setWirlessController] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      offer_date: 7,
      heat_pump_id: "",
      heat_pump_brand: "",
      heat_pump_model: "",
      heat_pump_power: "",
      heat_pump_count: 1,
      hot_water_tank_id: 1,
      hot_water_tank_name: 1,
      hot_water_tank_capacity: 1,
      hot_water_tank_count: 1,
      flushing_system: false,
      leaving_existing_source: false,
      wirless_controller: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });

  const watchFieldArray = watch("services");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const resetInputValue = () => {
    setMargin(0);
    setVatRate(8);
    setHeatPumpId(null);
    setHotWaterTankId(null);
    setHotWaterTankValue(1);
    setHeatPumpValue(1);
    setLeaveExistingSource(false);
    setFlushingSystem(false);
    setWirlessController(false);
    setSpecialDiscountManagement(0);
    setSpecialDiscountManager(0);
    reset({
      margin: 0,
      offer_date: 7,
      description: "",
      vat: 8,
      heat_pump_id: "",
      heat_pump_brand: "",
      heat_pump_model: "",
      heat_pump_power: "",
      heat_pump_count: 1,
      hot_water_tank_id: 1,
      hot_water_tank_name: 1,
      hot_water_tank_capacity: 1,
      hot_water_tank_count: 1,
      special_discount_manager: 0,
      special_discount_management: 0,
      flushing_system: false,
      leaving_existing_source: false,
      wirless_controller: false,
    });
  };

  const [heatPumps, setHeatPumps] = useState([]);
  const [heatPumpId, setHeatPumpId] = useState(null);
  const [heatPumpValue, setHeatPumpValue] = useState(1);
  const [hotWaterTanks, setHotWaterTanks] = useState([]);
  const [hotWaterTankId, setHotWaterTankId] = useState(null);
  const [hotWaterTankValue, setHotWaterTankValue] = useState(1);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [showClientInfo, setShowClientInfo] = useState(false);
  const [specialDiscountManager, setSpecialDiscountManager] = useState(0);
  const [specialDiscountManagement, setSpecialDiscountManagement] = useState(0);
  const [vatRate, setVatRate] = useState(23);
  const [margin, setMargin] = useState(0);
  const [investorName, setInvestorName] = useState("");
  const [investorAdress, setInvestorAdress] = useState("");
  const [isSended, setIsSended] = useState(false);
  const [endDate, setEndDate] = useState(7);
  const sendData = async (data) => {
    setIsSended(true);
    try {
      const { status } = await request.post("/api/heat-pump-offer", {
        ...data,
      });

      if (status === 200) {
        fetchData();
        resetInputValue();
        handleOnClose();
      }
    } catch (e) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        history.push({
          pathname: "/login",
          state: { from: location },
        });
      }
    }
    setIsSended(false);
  };

  const handleOnSubmitwithClose = async (data) => {
    await sendData(data);
  };

  const handleOnCalcPower = () => {
    let netto = 0;
    if (heatPumpId > 0) {
      const heatPump = heatPumps.find((pump) => pump.id === Number(heatPumpId));
      setValue("heat_pump_id", heatPump.id);
      setValue("heat_pump_brand", heatPump.brand);
      setValue("heat_pump_model", heatPump.model);
      setValue("heat_pump_count", heatPumpValue);
      setValue("heat_pump_power", heatPump.power);
      setValue("heat_pump_type", heatPump.type);
      netto += Number(heatPump.price) * heatPumpValue;
    } else {
      setValue("heat_pump_id", "");
      setValue("heat_pump_brand", "");
      setValue("heat_pump_model", "");
      setValue("heat_pump_type", "");
      setValue("heat_pump_count", 0);
      setValue("heat_pump_power", 0);
    }
    if (hotWaterTankId > 0) {
      const hotWaterTank = hotWaterTanks.find(
        (tank) => tank.id === Number(hotWaterTankId)
      );

      setValue("hot_water_tank_id", hotWaterTank.id);
      setValue("hot_water_tank_name", hotWaterTank.name);
      setValue("hot_water_tank_capacity", hotWaterTank.capacity);
      setValue("hot_water_tank_count", hotWaterTankValue);
      netto += Number(hotWaterTank.price) * hotWaterTankValue;
    } else {
      setValue("hot_water_tank_id", "");
      setValue("hot_water_tank_name", "");
      setValue("hot_water_tank_capacity", 0);
      setValue("hot_water_tank_count", 0);
    }

    if (heatPumpId > 0 || hotWaterTankId > 0) {
      netto += 8000;
    }
    if (flushingSystem) {
      netto += 3000;
    }
    if (specialDiscountManagement) {
      netto -= Number(specialDiscountManagement);
    }
    if (specialDiscountManager) {
      netto -= Number(specialDiscountManager);
    }
    if (leaveExistingSource) {
      netto += 3000;
    }
    if (wirlessController) {
      netto += 600;
    }
    if (margin > 0) {
      netto += Number(margin);
    }

    let sumall;
    if (watchFieldArray) {
      sumall = watchFieldArray
        .map((item) => item.name.trim() && item.price)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    } else {
      sumall = 0;
    }

    if (sumall > 0) {
      netto += Number(sumall);
    }

    const vatPrice = (Number(netto) * Number(vatRate)) / 100;

    setValue("price_netto", Number(netto).toFixed(2));
    setValue("price_sum", Math.ceil(netto + Number(vatPrice)));
    setValue("price_vat", Math.ceil(vatPrice));
    setValue("margin", Number(margin));
  };

  useEffect(() => {
    if (company) {
      setValue("company_id", company.id);
      setValue("investor_name", ` ${company.name}`);
      setVatRate(23);
      setValue("vat", `23`);
      setInvestorName(`${company.name}`);

      if (
        company.city &&
        company.building_number &&
        company.post_code &&
        company.post &&
        company.street
      ) {
        setInvestorAdress(
          `ul. ${company?.street} ${company?.building_number}, ${company?.city} ${company?.post_code} ${company?.post}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street} ${company?.building_number}, ${company?.city} ${company?.post_code} ${company?.post}`
        );
      } else if (
        company?.city &&
        company?.building_number &&
        company?.post &&
        company?.post_code
      ) {
        setInvestorAdress(
          `${company?.city} ${company?.building_number}, ${company?.post_code} ${company?.post} `
        );
        setValue(
          "investor_address",
          `${company?.city} ${company?.building_number}, ${company?.post_code} ${company?.post} `
        );
      } else if (
        company?.city &&
        company?.street &&
        company?.post &&
        company?.post_code
      ) {
        setInvestorAdress(
          `ul. ${company?.street} ${company?.city}, ${company?.post_code} ${company?.post}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street} ${company?.city}, ${company?.post_code} ${company?.post}`
        );
      } else if (
        company.city &&
        company.building_number &&
        company.post_code &&
        company.street
      ) {
        setInvestorAdress(
          `ul. ${company?.street} ${company?.building_number}, ${company?.post_code} ${company?.city}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street} ${company?.building_number}, ${company?.post_code} ${company?.city}`
        );
      } else if (
        company?.city &&
        company?.building_number &&
        company?.post_code
      ) {
        setInvestorAdress(
          `${company?.building_number}, ${company?.post_code} ${company?.city} `
        );
        setValue(
          "investor_address",
          `${company?.building_number}, ${company?.post_code} ${company?.city} `
        );
      } else if (company?.city && company?.street && company?.post_code) {
        setInvestorAdress(
          `ul. ${company?.street}, ${company?.post_code} ${company?.city}`
        );
        setValue(
          "investor_address",
          `ul. ${company?.street}, ${company?.post_code} ${company?.city}`
        );
      } else {
        setValue("investor_address", null);
        setInvestorAdress("Sprawdź poprawność danych inwestora");
      }
    } else if (client) {
      setValue("client_id", client.id);
      setVatRate(8);
      setValue("vat", `8`);
      if (client.first_name && client.last_name) {
        setValue("investor_name", ` ${client.first_name} ${client.last_name}`);
        setInvestorName(`${client.first_name} ${client.last_name}`);
      } else {
        setValue("investor_name", ` ${client.first_name}`);
        setInvestorName(`${client.first_name}`);
      }
      if (
        client.city &&
        client.building_number &&
        client.postCode &&
        client.post &&
        client.street
      ) {
        setInvestorAdress(
          `ul. ${client?.street} ${client?.building_number}, ${client?.city} ${client?.postCode} ${client?.post}`
        );
        setValue(
          "investor_address",
          `ul. ${client?.street} ${client?.building_number}, ${client?.city} ${client?.postCode} ${client?.post}`
        );
      } else if (
        client?.city &&
        client?.building_number &&
        client?.post &&
        client?.postCode
      ) {
        setInvestorAdress(
          `${client?.city} ${client?.building_number}, ${client?.postCode} ${client?.post} `
        );
        setValue(
          "investor_address",
          `${client?.city} ${client?.building_number}, ${client?.postCode} ${client?.post} `
        );
      } else if (
        client?.city &&
        client?.street &&
        client?.post &&
        client?.postCode
      ) {
        setInvestorAdress(
          `ul. ${client?.street} ${client?.city}, ${client?.postCode} ${client?.post}`
        );
        setValue(
          "investor_address",
          `ul. ${client?.street} ${client?.city}, ${client?.postCode} ${client?.post}`
        );
      } else if (
        client.city &&
        client.building_number &&
        client.postCode &&
        client.street
      ) {
        setInvestorAdress(
          `ul. ${client?.street} ${client?.building_number}, ${client?.postCode} ${client?.city}`
        );
        setValue(
          "investor_address",
          `ul. ${client?.street} ${client?.building_number}, ${client?.postCode} ${client?.city}`
        );
      } else if (client?.city && client?.building_number && client?.postCode) {
        setInvestorAdress(
          `${client?.building_number}, ${client?.postCode} ${client?.city} `
        );
        setValue(
          "investor_address",
          `${client?.building_number}, ${client?.postCode} ${client?.city} `
        );
      } else if (client?.city && client?.street && client?.postCode) {
        setInvestorAdress(
          `ul. ${client?.street}, ${client?.postCode} ${client?.city}`
        );
        setValue(
          "investor_address",
          `ul. ${client?.street}, ${client?.postCode} ${client?.city}`
        );
      } else {
        setValue("investor_address", null);
        setInvestorAdress("Sprawdź poprawność danych inwestora");
      }
    }
  }, [
    client,
    client?.postCode,
    client?.street,
    client?.city,
    client?.building_number,
    company,
    company?.post_code,
    company?.street,
    company?.city,
    company?.building_number,
    isModalOpen,
  ]);

  useEffect(() => {
    setValue("opportunity_id", id);
  }, [id]);

  useEffect(() => {
    handleOnCalcPower();
  }, [
    vatRate,
    margin,
    JSON.stringify(watchFieldArray),
    watch,
    heatPumpId,
    heatPumpValue,
    hotWaterTankId,
    hotWaterTankValue,
    wirlessController,
    leaveExistingSource,
    flushingSystem,
    specialDiscountManagement,
    specialDiscountManager,
  ]);

  useEffect(() => {
    if (user.heat_pump?.length > 0) {
      setHeatPumps(user.heat_pump);
    }
    if (user.hot_water_tank?.length > 0) {
      setHotWaterTanks(user.hot_water_tank);
    }
  }, [user]);

  const handleOnChangeMargin = (e) => {
    if (e.target.value < 0) {
      setMargin(0);
    } else {
      setMargin(e.target.value);
    }
  };

  const handleOpenCompanyInfo = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (company) {
      setShowCompanyInfo(true);
    } else {
      setShowClientInfo(true);
    }
  };

  const handleOnChooseHeatPump = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value > 0) {
      setHeatPumpId(e.target.value);
    } else {
      setHeatPumpId(null);
    }
  };

  const handleOnHeatPumpValueChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value > 0) {
      setHeatPumpValue(e.target.value);
    } else {
      setHeatPumpValue(0);
    }
  };

  const handleOnChangeHotWaterTank = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value > 0) {
      setHotWaterTankId(e.target.value);
    } else {
      setHotWaterTankId(null);
    }
  };

  const handleOnChangeHotWaterTankValue = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (e.target.value > 0) {
      setHotWaterTankValue(e.target.value);
    } else {
      setHotWaterTankValue(0);
    }
  };

  const handleSetHeight = (e) => {
    e.target.style.height = "inherit";

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    e.target.style.height = `${height}px`;
  };

  const handleOnCheckedFlushingSystem = (e) => {
    setFlushingSystem(e.target.checked);
    setValue("flushing_system", e.target.checked);
  };
  const handleOnCheckedLeaveExistingSource = (e) => {
    setLeaveExistingSource(e.target.checked);
    setValue("leaving_existing_source", e.target.checked);
  };
  const handleOnCheckedWirlessController = (e) => {
    setWirlessController(e.target.checked);
    setValue("wirless_controller", e.target.checked);
  };

  const handleOnChangeDiscountManager = (e) => {
    setSpecialDiscountManager(Number(e.target.value));
    setValue("special_discount_manager", Number(e.target.value));
  };

  const handleOnChangeDiscountManagement = (e) => {
    setSpecialDiscountManagement(Number(e.target.value));
    setValue("special_discount_management", Number(e.target.value));
  };

  const handleOnChangeEndDate = (e) => {
    if (e.target.value < 0) {
      setEndDate(0);
      setValue("offer_date", 0);
    } else if (e.target.value > 7) {
      setEndDate(7);
      setValue("offer_date", 7);
    } else {
      setEndDate(Number(e.target.value).toFixed());
      setValue("offer_date", Number(e.target.value).toFixed());
    }
  };
  return (
    <Modal
      isModalOpen={isModalOpen}
      handleOnClose={handleOnClose}
      shouldBeCloseOnOutsideClick={false}
    >
      <StyledFormHeader>
        <StyledCloseButton onClick={handleOnClose} type="button">
          <FontAwesomeIcon icon={faTimes} />
        </StyledCloseButton>
      </StyledFormHeader>

      <StyledInvestorInformationWrapper>
        <StyledCopmanyInformationWrapper>
          <ClientForm
            client={client}
            fetchData={fetchData}
            showClientInfo={showClientInfo}
            setShowClientInfo={setShowClientInfo}
          />
          <CompanyForm
            company={company}
            fetchData={fetchData}
            showCompanyInfo={showCompanyInfo}
            setShowCompanyInfo={setShowCompanyInfo}
          />
        </StyledCopmanyInformationWrapper>
      </StyledInvestorInformationWrapper>
      <StyledForm
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        {/* {!user.offer_margin && (
          <div style={{ padding: "10px 0" }}>
            <p style={{ color: "red", fontSize: "14px" }}>
              W ustawieniach profilu proszę wpisać warośc marży na 1 kWp
            </p>
          </div>
        )} */}
        <StyledInvestorInfoWrapper>
          <StyledInvestorInfoTitleWrapper>
            <StyledInvestorInfoTitle>Dane do oferty:</StyledInvestorInfoTitle>
          </StyledInvestorInfoTitleWrapper>
          <StyledInvestorInfoContentWrapper>
            <StyledInvestorDetailsWrapper>
              <StyledInvestorNameWrapper>
                <StyledInvestorName title={investorName}>
                  Inwestor: {investorName}
                </StyledInvestorName>
                <input type="hidden" {...register("investor_name")} />
              </StyledInvestorNameWrapper>
              <StyledInvestorAdressWrapper>
                <StyledInvestorAdress title={investorAdress}>
                  Adres: {investorAdress}
                </StyledInvestorAdress>
                {errors?.investor_address && (
                  <span className="error">
                    {errors.investor_address.message}
                  </span>
                )}
                <input type="hidden" {...register("investor_address")} />
              </StyledInvestorAdressWrapper>
            </StyledInvestorDetailsWrapper>
            {!showCompanyInfo && (
              <StyledInfoButtonWrapper>
                <StyledInfoButton onClick={handleOpenCompanyInfo}>
                  Edytuj
                </StyledInfoButton>
              </StyledInfoButtonWrapper>
            )}
          </StyledInvestorInfoContentWrapper>
          <input type="hidden" {...register("opportunity_id")} />
        </StyledInvestorInfoWrapper>

        <StyledInstalationInfoWrapper>
          <StyledInvestorInfoTitleWrapper>
            <StyledInvestorInfoTitle>Dane Instalacji:</StyledInvestorInfoTitle>
          </StyledInvestorInfoTitleWrapper>

          <StyledInstalationWrapper>
            <StyledHeatPump>
              <StyledInput>
                <div className="input-group">
                  <label>Pompa ciepła:</label>
                  <select onChange={handleOnChooseHeatPump}>
                    <option value="">Wybierz...</option>
                    {heatPumps.map((heatPump) => {
                      return (
                        <option key={heatPump.id} value={heatPump.id}>
                          {heatPump.brand} {heatPump.model} {heatPump.power} kW{" "}
                          {heatPump.type}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </StyledInput>
              <input type="hidden" {...register("heat_pump_id")} />
              <input type="hidden" {...register("heat_pump_brand")} />
              <input type="hidden" {...register("heat_pump_model")} />
              <input type="hidden" {...register("heat_pump_power")} />
              <input type="hidden" {...register("heat_pump_type")} />
              {errors?.heat_pump_id && (
                <span className="error">{errors.instalation_id.message}</span>
              )}{" "}
            </StyledHeatPump>
            <StyledInstalationCountWrapper>
              <StyledInput>
                <div className="input-group">
                  <label>Ilość pomp ciepła</label>
                  <input
                    type="number"
                    value={heatPumpValue}
                    min={1}
                    onInput={handleOnHeatPumpValueChange}
                  />
                </div>{" "}
                <input type="hidden" {...register("heat_pump_count")} />
                {errors?.heat_pump_count && (
                  <span className="error">
                    {errors.heat_pump_count.message}
                  </span>
                )}
              </StyledInput>
            </StyledInstalationCountWrapper>
          </StyledInstalationWrapper>

          <StyledConstructionWrapper>
            <StyledConstruction>
              <StyledInput>
                <div className="input-group" style={{ flex: "1" }}>
                  <label>Zasobnik CWU:</label>
                  <select onChange={handleOnChangeHotWaterTank}>
                    <option value="">Wybierz...</option>
                    {hotWaterTanks.map((hotWaterTank) => {
                      return (
                        <option key={hotWaterTank.id} value={hotWaterTank.id}>
                          {hotWaterTank.name} {hotWaterTank.capacity} L
                        </option>
                      );
                    })}
                  </select>
                </div>{" "}
                <input type="hidden" {...register("hot_water_tank_id")} />
                <input type="hidden" {...register("hot_water_tank_name")} />
                <input type="hidden" {...register("hot_water_tank_capacity")} />
                {errors?.hot_water_tank_id && (
                  <span className="error">
                    {errors.hot_water_tank_id.message}
                  </span>
                )}
              </StyledInput>
            </StyledConstruction>
            <StyledConstructionCount>
              <StyledInput>
                <div className="input-group">
                  <label>Ilość zasobników</label>
                  <input
                    type="number"
                    value={hotWaterTankValue}
                    onInput={handleOnChangeHotWaterTankValue}
                  />
                  <input type="hidden" {...register("hot_water_tank_count")} />
                </div>
                {errors?.hot_water_tank_count && (
                  <span className="error">
                    {errors.hot_water_tank_count.message}
                  </span>
                )}
              </StyledInput>
            </StyledConstructionCount>
          </StyledConstructionWrapper>

          <StyledDetailsWrapper>
            <StyledSectionTitleWrapper>
              Usługi dodatkowe:
            </StyledSectionTitleWrapper>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <StyledCheckBoxInput
                    type="checkbox"
                    checked={flushingSystem}
                    onChange={handleOnCheckedFlushingSystem}
                  />
                  Płukanie układu
                </StyledCheckBoxLabel>
                <input type="hidden" {...register("flushing_system")} />
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <StyledCheckBoxInput
                    type="checkbox"
                    checked={leaveExistingSource}
                    onChange={handleOnCheckedLeaveExistingSource}
                  />
                  Pozostawienie istniejącego źródła ciepła
                </StyledCheckBoxLabel>
                <input type="hidden" {...register("leaving_existing_source")} />
              </StyledInputGroup>
            </StyledCheckBox>
            <StyledCheckBox>
              <StyledInputGroup>
                <StyledCheckBoxLabel>
                  <StyledCheckBoxInput
                    type="checkbox"
                    checked={wirlessController}
                    onChange={handleOnCheckedWirlessController}
                  />
                  Sterownik bezprzewodowy
                </StyledCheckBoxLabel>
                <input type="hidden" {...register("wirless_controller")} />
              </StyledInputGroup>
            </StyledCheckBox>

            {controlledFields.map((field, index) => {
              return (
                <StyledServicesOtherInput key={field.id}>
                  <StyledInputWrapper>
                    <StyledOtherServicesInputWrapper>
                      <input
                        type="text"
                        {...register(`services[${index}].name`)}
                      />
                    </StyledOtherServicesInputWrapper>{" "}
                    <StyledServicesInputWrapper>
                      <StyledServicesInputLabel>Cena</StyledServicesInputLabel>
                      <input
                        type="text"
                        {...register(`services[${index}].price`)}
                      />
                    </StyledServicesInputWrapper>
                    <StyledRemoveButtonWrapper>
                      <StyledRemoveButton onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faMinus} />
                      </StyledRemoveButton>
                    </StyledRemoveButtonWrapper>
                  </StyledInputWrapper>
                  <StyledErrorWrapper>
                    {errors?.services &&
                      errors?.services[index] &&
                      errors?.services[index].name && (
                        <span className="error">
                          {errors?.services[index].name.message}{" "}
                        </span>
                      )}
                    {errors?.services &&
                      errors?.services[index] &&
                      errors?.services[index].price && (
                        <span className="error">
                          {errors?.services[index].price.message}
                        </span>
                      )}
                  </StyledErrorWrapper>
                </StyledServicesOtherInput>
              );
            })}
            <StyledAddOtherServiceButton
              type="button"
              onClick={() => append({ name: "", price: "" })}
            >
              <FontAwesomeIcon icon={faPlus} />
            </StyledAddOtherServiceButton>
          </StyledDetailsWrapper>

          <StyledPriceWrapper>
            <StyledPriceLeftSide>
              <StyledInput>
                <div className="input-group">
                  <label>Cena instalacji (netto)</label>
                  <input type="text" {...register("price_netto")} readOnly />
                </div>
              </StyledInput>
              {errors?.price_netto && (
                <span className="error">{errors.price_netto.message}</span>
              )}

              <StyledInput>
                <div className="input-group">
                  <label>Stawka Vat</label>
                  <select
                    onChange={(e) => setVatRate(e.target.value)}
                    value={vatRate}
                  >
                    <option value="8">8%</option>
                    <option value="23">23%</option>
                  </select>
                </div>
              </StyledInput>
              <input type="hidden" {...register("vat")} />
              <input type="hidden" {...register("price_vat")} />
              {errors?.price_vat && (
                <span className="error">{errors.price_vat.message}</span>
              )}
            </StyledPriceLeftSide>
            <StyledPriceRightSide>
              <StyledInput>
                <div className="input-group">
                  <label>Marża</label>
                  <input
                    type="number"
                    min={0}
                    value={margin}
                    onChange={handleOnChangeMargin}
                  />
                  {/* <input type="hidden" {...register("special_discount")} /> */}
                  <input type="hidden" {...register("margin")} />
                </div>
              </StyledInput>
              <StyledInput>
                <div className="input-group">
                  <label>Rabat specjalny Dyrektor(netto)</label>
                  <input
                    min={0}
                    type="number"
                    value={specialDiscountManager}
                    onChange={handleOnChangeDiscountManager}
                  />
                </div>
                <input
                  type="hidden"
                  {...register("special_discount_manager")}
                />
              </StyledInput>

              <StyledInput>
                <div className="input-group">
                  <label>Rabat specjalny Zarząd(netto)</label>
                  <input
                    type="number"
                    min={0}
                    value={specialDiscountManagement}
                    onChange={handleOnChangeDiscountManagement}
                  />
                </div>
                <input
                  type="hidden"
                  {...register("special_discount_management")}
                />
              </StyledInput>

              <StyledBruttoPriceInput>
                <div className="input-group">
                  <StyledBruttoPriceLabel>
                    Cena końcowa instalacji (brutto)
                  </StyledBruttoPriceLabel>
                  <input type="text" {...register("price_sum")} readOnly />
                </div>
              </StyledBruttoPriceInput>
              {errors?.price_sum && (
                <span className="error">{errors.price_sum.message}</span>
              )}
            </StyledPriceRightSide>
          </StyledPriceWrapper>

          <StyledValidityWrapper>
            <StyledValidityLeftSide>
              {" "}
              <StyledInput>
                <div className="input-group">
                  <label>Ważność oferty (dni)</label>
                  <input
                    type="number"
                    onChange={handleOnChangeEndDate}
                    value={endDate}
                  />
                </div>
                <input type="hidden" {...register("offer_date")} />
                {errors?.offer_date && (
                  <span className="error">{errors.offer_date.message}</span>
                )}
              </StyledInput>
            </StyledValidityLeftSide>
          </StyledValidityWrapper>
          <StyledInput>
            <div className="input-group">
              <label>Opis:</label>
              <textarea
                {...register("description")}
                onKeyUp={handleSetHeight}
              ></textarea>
            </div>
          </StyledInput>
        </StyledInstalationInfoWrapper>

        <div className="buttons">
          <button type="button" className="close" onClick={handleOnClose}>
            Anuluj
          </button>
          <button
            disabled={isSended}
            type="button"
            className="add"
            onClick={handleSubmit(handleOnSubmitwithClose)}
          >
            Generuj
          </button>
        </div>
      </StyledForm>
    </Modal>
  );
};

const StyledCheckBoxInput = styled.input`
  margin-right: 10px;
`;

const StyledCheckBoxLabel = styled.label`
  display: flex;
  font-size: 14px;
`;

const StyledInputGroup = styled.div``;

const StyledCheckBox = styled.div`
  min-width: 320px;
  margin-top: 10px;
  display: flex;
`;

const StyledBruttoPriceInput = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    input {
      border: none;
      border-bottom: 2px solid black;
      width: 100%;
      outline: none;
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const StyledBruttoPriceLabel = styled.label`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 16px;
  font-weight: bold;
  color: rgb(244, 118, 49);
`;

const StyledErrorWrapper = styled.div``;

const StyledInputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const StyledRemoveButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  height: 19px;
  border-radius: 5px;
  width: 100%;
`;
const StyledRemoveButtonWrapper = styled.div`
  margin-top: 20px;
  width: 60px;
`;
const StyledSectionTitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #134771;
`;

const StyledServicesOtherInput = styled.div``;

const StyledOtherServicesInputWrapper = styled.div`
  flex: 1;
  margin-top: 20px;
  position: relative;
  & input {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
`;
const StyledAddOtherServiceButton = styled.button`
  margin-top: 10px;
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
`;

const StyledServicesInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;

const StyledServicesInputWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  & input {
    width: 60px;
  }
`;

const StyledServicesTitle = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const StyledServicesInput = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledOtherInputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 14px;
`;
const StyledOtherInputWrapper = styled.div`
  margin-top: 20px;
  position: relative;
`;

const StyledOtherInput = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledInfoButton = styled.button`
  background: #134771;
  color: #cadeee;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
  &:hover {
    background: #053257;
  }
`;

const StyledInfoButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledInvestorInfoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
`;
const StyledInvestorDetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const StyledValidityRightSide = styled.div`
  flex: 1;
  min-width: 0;
`;

const StyledValidityLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledValidityWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
`;
const StyledPriceRightSide = styled.div`
  flex: 1;
`;

const StyledPriceLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledDetailsRightSide = styled.div`
  flex: 1;
`;

const StyledDetailsLeftSide = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledDetailsWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledConstructionCount = styled.div`
  flex: 1;
`;

const StyledConstruction = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const StyledConstructionWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;
const StyledHeatPump = styled.div`
  flex: 1;
  margin-right: 10px;
`;
const StyledInstalationCountWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const StyledInstalationWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba(188, 188, 188, 0.4);
`;

const StyledInstalationInfoWrapper = styled.div``;

const StyledInvestorAdress = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

const StyledInvestorName = styled.p`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledInvestorAdressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-top: 10px;
`;
const StyledInvestorNameWrapper = styled.div`
  display: flex;
  min-width: 0;
  margin-top: 10px;
`;

const StyledInvestorInfoTitle = styled.p`
  color: #134771;
`;
const StyledInvestorInfoTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledInvestorInfoWrapper = styled.div`
  min-width: 0;
`;

const StyledCopmanyInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledInvestorInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledFormHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledCloseButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 1rem;
  cursor: pointer;
  color: #134771;
  &:hover {
    color: #053257;
  }
`;

const StyledForm = styled.form`
  max-width: 640px;
  min-width: 0;
  & .date,
  & .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .todo-input-group {
      width: 49%;

      position: relative;
      label {
        position: absolute;
        top: -16px;
        font-size: 14px;
      }

      select {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }

  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      border: none;
      padding: 0.8rem 1rem;
      margin: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 5px;
      &.close {
        color: #134771;
        background: transparent;
      }
      &.close:hover {
        color: #053257;
      }
      &.add {
        background: #134771;
        color: #cadeee;
      }
      &.add:hover {
        background: #053257;
      }
    }
  }
  span.error {
    font-size: 12px;
    color: red;
  }
`;

const StyledInput = styled.div`
  min-width: 300px;

  margin-top: 25px;
  display: flex;
  flex-direction: column;
  & .input-group {
    position: relative;
    flex: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    label {
      position: absolute;
      top: -15px;
      left: 0;
      font-size: 14px;
    }
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      outline: none;
      font-size: 14px;
    }
    select {
      width: 100%;
    }
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      padding: 10px;
      overflow-y: hidden;
      border-radius: 10px;
      outline: none;
    }
  }
`;

export default OfferHeatPump;
