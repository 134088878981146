import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const UserButton = ({ user, chooseUser, handleSetUser }) => {
  const [isChoose, setIsChoose] = useState(false);

  useEffect(() => {
    if (user.id === chooseUser) {
      setIsChoose(true);
    } else {
      setIsChoose(false);
    }
  }, [user, chooseUser]);

  return (
    <StyledUserButton
      style={
        isChoose ? { background: "#cadeee" } : { background: "transparent" }
      }
      key={user.id}
      onClick={(e) => {
        e.preventDefault();
        handleSetUser(user);
      }}
    >
      <StyledUserIcon icon={faUser} /> {user.name}
    </StyledUserButton>
  );
};

const StyledUserIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledUserButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 4px;
  position: relative;
  background: transparent;
  border: none;

  cursor: pointer;
  padding-left: 20px;
`;

export default UserButton;
