import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import StoreProvider from "./store/StoreProvider";
import OpportunityFilterProvider from "./store/OpportunityFilterProvider";
import YieldCalculatorProvider from "./store/YieldCalculatorProvider";
import PvCalculatorProvider from "./store/PvCalculatorProvider";
import PowerCalculatorProvider from "./store/PowerCalculatorProvider";
import AssemblyFaultsProvider from "./store/AssemblyFaultsProvider";
import LeadProvider from "./store/LeadProvider";
import PvCalculatorAdvancedProvider from "./store/PvCalculatorAdvancedProvider";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider>
        <OpportunityFilterProvider>
          <YieldCalculatorProvider>
            <PvCalculatorAdvancedProvider>
              <PvCalculatorProvider>
                <PowerCalculatorProvider>
                  <AssemblyFaultsProvider>
                    <LeadProvider>
                      <App />
                    </LeadProvider>
                  </AssemblyFaultsProvider>
                </PowerCalculatorProvider>
              </PvCalculatorProvider>
            </PvCalculatorAdvancedProvider>
          </YieldCalculatorProvider>
        </OpportunityFilterProvider>
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
